<div class="ms-MessageBar" role="alert" [ngClass]="{
    'ms-MessageBar--success': type == 'success' || type == 'completed',
    'ms-MessageBar--error': type == 'error',
    'ms-MessageBar--info': type == 'info',
    'ms-MessageBar--warning': type == 'warning'
  }">
  <i class="icon ms-Icon ms-Icon--Completed" *ngIf="type == 'completed'" title="Completed" aria-hidden="true"></i>
  <span class="icon {{ type }}" *ngIf="type == 'success'"><svg class="svg-icon" xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 15 15">
      <g transform="translate(-13 -3624)">
        <path class="a"
          d="M8.492,0a7.161,7.161,0,0,1,1.984.273,7.421,7.421,0,0,1,1.8.758,7.548,7.548,0,0,1,2.688,2.688,7.419,7.419,0,0,1,.758,1.8A7.164,7.164,0,0,1,15.992,7.5a7.247,7.247,0,0,1-.273,1.992,7.449,7.449,0,0,1-.758,1.789A7.375,7.375,0,0,1,13.789,12.8a7.561,7.561,0,0,1-1.516,1.172,7.858,7.858,0,0,1-1.8.758A7.357,7.357,0,0,1,8.492,15,7.442,7.442,0,0,1,6.5,14.734a7.9,7.9,0,0,1-1.789-.758,7.43,7.43,0,0,1-2.7-2.7,7.894,7.894,0,0,1-.758-1.789A7.443,7.443,0,0,1,.992,7.5a7.358,7.358,0,0,1,.266-1.984,7.857,7.857,0,0,1,.758-1.8A7.555,7.555,0,0,1,3.188,2.2,7.376,7.376,0,0,1,4.711,1.031,7.453,7.453,0,0,1,6.5.273,7.243,7.243,0,0,1,8.492,0Zm0,1a6.394,6.394,0,0,0-1.734.234A6.312,6.312,0,0,0,5.2,1.891,6.509,6.509,0,0,0,3.891,2.906,6.476,6.476,0,0,0,2.219,5.773,6.514,6.514,0,0,0,1.992,7.5a6.6,6.6,0,0,0,.227,1.734,6.736,6.736,0,0,0,.656,1.555A6.56,6.56,0,0,0,5.2,13.117a6.735,6.735,0,0,0,1.555.656A6.6,6.6,0,0,0,8.492,14a6.394,6.394,0,0,0,3.281-.883A6.6,6.6,0,0,0,13.086,12.1,6.513,6.513,0,0,0,14.1,10.789a6.312,6.312,0,0,0,.656-1.555A6.392,6.392,0,0,0,14.992,7.5a6.308,6.308,0,0,0-.234-1.727A6.148,6.148,0,0,0,14.1,4.219a6.561,6.561,0,0,0-2.328-2.328,6.148,6.148,0,0,0-1.555-.656A6.31,6.31,0,0,0,8.492,1Zm4.352,4.852L8.109,10.625,5.141,7.656l.7-.711L8.109,9.211l4.031-4.063Z"
          transform="translate(12.008 3624)" />
      </g>
    </svg></span>
  <span class="icon {{ type }}" *ngIf="type == 'error'"><svg class="svg-icon" xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 13.656 12.891">
      <path class="a"
        d="M7.508.109a1.448,1.448,0,0,0-.766.219A1.459,1.459,0,0,0,6.188.9L.859,10.789a1.486,1.486,0,0,0-.18.711,1.374,1.374,0,0,0,.125.578,1.5,1.5,0,0,0,.32.477,1.619,1.619,0,0,0,.477.328A1.453,1.453,0,0,0,2.18,13H12.836a1.453,1.453,0,0,0,.578-.117,1.6,1.6,0,0,0,.8-.8,1.453,1.453,0,0,0,.117-.578,1.485,1.485,0,0,0-.18-.711L8.828.9a1.459,1.459,0,0,0-.555-.57A1.448,1.448,0,0,0,7.508.109Zm0,9.641a.652.652,0,0,0-.281.063.956.956,0,0,0-.406.406.664.664,0,0,0,0,.562.857.857,0,0,0,.172.242.827.827,0,0,0,.234.164.664.664,0,0,0,.563,0,.775.775,0,0,0,.406-.406.664.664,0,0,0,0-.562.825.825,0,0,0-.164-.234.858.858,0,0,0-.242-.172A.652.652,0,0,0,7.508,9.75Zm0-8.641a.45.45,0,0,1,.273.086.95.95,0,0,1,.2.211,1.527,1.527,0,0,1,.148.273,2.79,2.79,0,0,0,.125.25q.117.227.4.742t.656,1.2q.383.687.828,1.5t.891,1.633q.445.82.867,1.594t.742,1.391q.32.617.516,1.023a1.924,1.924,0,0,1,.188.484.507.507,0,0,1-.5.5H2.18a.507.507,0,0,1-.5-.5A9.872,9.872,0,0,1,2.2,10.414q.281-.523.563-1.063.367-.687.727-1.375T4.2,6.594q.375-.711.75-1.406T5.7,3.773q.273-.5.547-.992T6.8,1.789q.062-.109.125-.211a1.406,1.406,0,0,1,.141-.2,1.064,1.064,0,0,1,.188-.187A.4.4,0,0,1,7.508,1.109ZM7.008,9h1V4h-1Z"
        transform="translate(-0.68 -0.109)" />
    </svg></span>
  <span class="icon {{ type }}" *ngIf="type == 'warning'"><svg class="svg-icon" viewBox="0 0 1024 1024" version="1.1"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M509.57653367 31.675733a477.866667 477.866667 0 1 0 0 955.733334 477.866667 477.866667 0 0 0 0-955.733334m0 51.2a427.144533 427.144533 0 0 1 426.666666 426.666667 427.144533 427.144533 0 0 1-426.666666 426.666667 427.144533 427.144533 0 0 1-426.666667-426.666667 427.144533 427.144533 0 0 1 426.666667-426.666667" />
      <path d="M483.97653367 241.2544a25.6 25.6 0 0 1 51.2 0v373.418667a25.6 25.6 0 0 1-51.2 0v-373.418667z" />
      <path d="M546.91839967 750.045867a37.341867 37.341867 0 1 1-74.683733 0 37.341867 37.341867 0 0 1 74.683733 0" />
    </svg></span>
  <span class="alert-message">{{ alert_message }} </span>
  <button type="button" class="close" [class]="type" data-dismiss="alert" aria-label="Close" (click)="closeAlert()">
    <i class="ms-Icon ms-Icon--ChromeClose" title="ChromeClose" aria-hidden="true"></i>
  </button>
</div>