import { Component, OnInit, OnDestroy, ChangeDetectorRef, ElementRef, AfterViewChecked } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Subject, Subscription } from 'rxjs';
import html2canvas from 'html2canvas';
import jspdf from 'jspdf';

import { DeviceDetectorService } from 'ngx-device-detector';
import { TranslateService } from '@ngx-translate/core';

import { getEntity, getEntityData, LoadingService, miscellaneousConst, unsubscribeCollection } from '@utility';

import { TestResultModel } from '../../../../model/test-result.model';
import { TestDetailsModel, TestListModel } from '../../../../model/common.model';

import { TestResultService } from '../../../../services/test-result/test-result.service';
import { AppInsightEvents, EventEmitterService, PropertyName, UserSessionService, decryptBackendResponse } from '@teams-auth';
import { TestResultHelperService } from '../../../../services/test-result/test-result-helper.service';
import { CommonService } from '../../../../services/common-service.service';

import { LocalSessionStorageService } from '../../../../services/local-session-storage.service'
import { takeUntil } from 'rxjs/operators';
import { Title } from '@angular/platform-browser';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'app-test-result',
  templateUrl: './test-result.component.html'
})
export class TestResultComponent implements OnInit, OnDestroy, AfterViewChecked {
  public examId: string;
  public examInstanceId: string;
  public resultDetails: TestResultModel;
  public testDetails: TestDetailsModel;
  public loaderMsg = [];
  public isExamFeedback = false;
  public activePage = 0;
  public pageStartingIndex = 0;
  public pageEndingIndex = 10;
  public miscellaneousConst = miscellaneousConst;
  public isTeamapp: string;
  public regUniqueName = '';
  public noPdf = true;
  public noPdfTab = true;
  public detailReport: string;
  private selectedExamData;
  public selectedTestInstanceDetail: TestListModel;
  private userVoucher: string;
  private userDetails: [];
  private activeUserVoucher: string;
  private eventUniqueName: string;
  private uniqueName: string;
  private notifier: Subject<boolean> = new Subject();
  public productType: string;

  private subscriptions: Subscription[] = [];
  context: string;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private testResultService: TestResultService,
    private testResultHelper: TestResultHelperService,
    private eventEmitter: EventEmitterService,
    public userSessionService: UserSessionService,
    private commonService: CommonService,
    public deviceDetectorService: DeviceDetectorService,
    private readonly translateService: TranslateService,
    private ref: ChangeDetectorRef,
    private localStorageService: LocalSessionStorageService,
    public loadingService: LoadingService,
    private elementRef: ElementRef,
    private titleService: Title
  ) {
    this.subscriptions[this.subscriptions.length] = this.route.params.subscribe((i) => {
      this.examId = this.route.snapshot.params.id;
        this.userVoucher = this.route.snapshot.params.userVoucher;
        this.uniqueName = this.route.snapshot.params.id;
        this.regUniqueName = i.regUniqueName;
        this.eventUniqueName = i.eventUniqueName;
        this.activeUserVoucher = i.activeUserVoucher;
    });
  }

  ngOnInit(): void {
    if(localStorage.getItem('context') === miscellaneousConst.cloudlab){
      const title = this.translateService.instant('exam.result.title') + ' - ' + 
        localStorage.getItem('current-pt-details-page-name') + ' | ' + this.translateService.instant('HOME.CLOUDLABS_PORTAL');
      this.titleService.setTitle(title);
    }
    this.eventEmitter.logEventInAppInsight(
      AppInsightEvents.PRACTICE_TEST_RESULT_SCREEN_VISIT,
      {
        [PropertyName.ENTITY_ID]: this.examInstanceId,
        [PropertyName.URL]: window.location.href,
      }
    );
    sessionStorage.removeItem('denysdovhan');
    sessionStorage.removeItem('dovhandenys');
    this.selectedExamData = history?.state?.getAttendeeData;
    this.selectedTestInstanceDetail = history?.state?.getSingleExamData;
    this.userDetails = history?.state.userDetails;
    this.detailReport = localStorage.getItem('detailReport');
    this.userSessionService.isDetailTable$.subscribe((e) => {
      if (e) {
        this.noPdfTab = false;
      }
    });
    this.isTeamapp = localStorage.getItem('isTeamapp');
    this.context = localStorage.getItem('context');
    this.loadTestResult();
  }

  ngAfterViewChecked(): void {
    // Commenting below code as I am able to remove unwanted li, keeping this code for future use | Sheikh

    // const tabViewElement = this.elementRef.nativeElement.querySelector('p-tabView');
    // const ulElement = tabViewElement?.querySelector('ul');
    // (ulElement as HTMLElement)?.setAttribute('aria-busy', 'true')

    // Removed li holding class named p-tabview-ink-bar, which is part of  <p-tabView> and this is unnecessary li | Sheikh
    const removeLi = this.elementRef.nativeElement.querySelector('.p-tabview-ink-bar')
    removeLi?.remove();

    // Added aria label for next and previous button in case of <p-paginator> by its class | Sheikh
    const nextElement = this.elementRef.nativeElement.querySelector('.p-paginator-next');
    (nextElement as HTMLElement)?.setAttribute('aria-label', 'Next Page')

    const prevElement = this.elementRef.nativeElement.querySelector('.p-paginator-prev');
    (prevElement as HTMLElement)?.setAttribute('aria-label', 'Previous Page')
  }
  goToPage(): void {
      this.userSessionService.practiceTest$.next(false);
      const examId = this.examId.toLowerCase();
      if (this.localStorageService.getCourseStorage() === 'true') {
        this.router.navigate([`${getEntity()}/${getEntityData()}/` + this.eventUniqueName + '/' + this.activeUserVoucher + '/test/practice-test/details/' + this.uniqueName + '/' + this.userVoucher]);
      } else {
        this.router.navigate([`${getEntity()}/practice-tests/details/` + examId + '/' + this.userVoucher ]);
      }
  }
  /**
   * @description: Load First Time Get Exam Result
   */
  private loadTestResult(): void {
    this.subscriptions[this.subscriptions.length] = this.route.params.subscribe(
      (i) => {
        this.examId = i.id;
        this.examInstanceId = i.testInstance;
        this.userSessionService.isDetailTable$.pipe(takeUntil(this.notifier)).subscribe((e) => {
          if (e || localStorage.getItem('detailReport') === 'true') {
            this.loaderMsg = ['common.spinner.viewReport'];
          } else {
            this.loaderMsg = ['common.spinner.testResult'];
          }
          this.loadingService.loadingOn(this.loaderMsg);
        });
        this.subscriptions[this.subscriptions.length] = this.testResultService
          .getExamResult(this.examInstanceId)
          .subscribe((encryptRes) => {
            this.eventEmitter.logEventInAppInsight(
              AppInsightEvents.PRACTICE_TEST_RESULT_SUCCESS,
              {
                [PropertyName.ENTITY_ID]: this.examInstanceId,
                [PropertyName.URL]: window.location.href,
              }
            );
            this.processTestResult(encryptRes);
          }, (error) => {
            const code = 'Error - 10008';
            this.eventEmitter.debugAlert(code, error.error);
            this.loadingService.loadingOff();
          });
      }, (error) => {
        const code = 'Error - 10009';
        this.eventEmitter.debugAlert(code, error.error);
        this.loadingService.loadingOff();
        this.eventEmitter.logEventInAppInsight(
          AppInsightEvents.PRACTICE_TEST_RESULT_FAILED,
          {
            [PropertyName.ENTITY_ID]: this.examInstanceId,
            [PropertyName.ERROR_MESSAGE]: error.error,
            [PropertyName.ERROR_DATA]: error,
            [PropertyName.URL]: window.location.href,
          }
        );
      });
  }

  private processTestResult(encryptRes): void {
    const res = decryptBackendResponse(encryptRes, this.userSessionService.getOriginalKeyPair().private);
    if ( typeof res === 'object'){
      this.resultDetails = res;
      this.productType = this.resultDetails.productType;
    } else {
      const code = 'Error - 10022';
      this.eventEmitter.debugAlert(code, res);
    }

    this.loadingService.loadingOff();
  }

  /**
   * @description: Change Timer Minute Display
   */
  public changeMinute(value: any): number {
    return this.testResultHelper.changeMinute(value);
  }
  /**
   * @description: Navigate Exam List
   */
  public completeExam(): void {
    this.isExamFeedback = true;
  }
  /**
   * @description: Get Feedback Response
   */
  public getFeedback(event): any {
    this.isExamFeedback = event;
  }
  /**
   * @description: Display Pagination
   */
  public paginate(event): any {
    this.pageStartingIndex = this.testResultHelper.paginate(event).pageStartingIndex;
    this.pageEndingIndex = this.testResultHelper.paginate(event).pageEndingIndex;
    this.activePage = this.testResultHelper.paginate(event).activePage;
    window.scrollTo(0, 0);
  }

  public getLanguage(ln): void {
    this.userSessionService.translateLanguage(ln);
  }

   /**
  * @description To print report
  */

    public downloadReport() {
      this.noPdf = false;
      this.loadingService.loadingOff();
      this.ref.detectChanges();
      this.loaderMsg = ["common.spinner.downloadReport"];
      setTimeout(() => {
        const data = document.getElementById('test-description-box');
        html2canvas(data).then(canvas => {

          // TODO - We need to move this code to common utility - Aakash
          // Few necessary setting options
          const imgWidth = 200;
          const pageHeight = 150;
          const imgHeight = canvas.height * imgWidth / canvas.width + 15;
          let heightLeft = imgHeight;

          const contentDataURL = canvas.toDataURL('image/png')
          const pdf = new jspdf('p', 'mm', 'a4'); // A4 size page of PDF
          let position = 0;
          pdf.addImage(contentDataURL, 'PNG', 5, position, imgWidth, imgHeight, undefined, 'FAST');
          heightLeft -= pageHeight;
          if (heightLeft >= 0) {
            position = heightLeft - imgHeight;
            pdf.addPage();
            pdf.addImage(contentDataURL,'JPEG', 5, -295, imgWidth, imgHeight);
            heightLeft -= pageHeight;
          }
          pdf.save(this.resultDetails?.title + ' - Report'); // Generated PDF
          setTimeout(() => {
            this.noPdf = true;
            this.loadingService.loadingOff();
            this.ref.detectChanges();
          }, 500);
        });
      }, 5000);

    }

  /**
   * @description Component lifecycle method, gets called when component destroys
   */
  ngOnDestroy(): void {
    unsubscribeCollection(this.subscriptions);
    this.notifier.next(true);
    this.notifier.complete();
  }
}
