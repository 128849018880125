<div *ngIf="!loading">
  <div class="tab-wrp">
    <div class="btn-group text-center mt-2 pb-4 mb-4" *ngIf="showSecondLayerTab">
      <teams-button aria-label="Azure Credentials TAB"
        *ngIf="(labDetail.DoNotSendCloudCredentials !== true || attendeLabDetails.DeploymentOutputValues?.length > 0) && mode !== operationModes.AZUREPASSUSERDETAILS && attendeLabDetails.CloudPlatformId === 1"
        [classList]="selectedTab === 'Azure' ? 'btn ts-btn-fluent-primary active' : 'btn ts-btn-fluent-white' "
        (buttonClick)="selectEnvironment('Azure')" [title]="'HEADING_AZURE_CREDENTIALS' | translate">
      </teams-button>
      <teams-button aria-label="MICROSOFT ACCOUNT CREDENTIALS TAB"
        *ngIf="(labDetail?.IsAzurePassActive && labDetail?.LicenseStatus) || mode === operationModes.AZUREPASSUSERDETAILS"
        [classList]="selectedTab === 'Ms' ? 'btn ts-btn-fluent-primary active' : 'btn ts-btn-fluent-white' "
        (buttonClick)="selectEnvironment('Ms')" [title]="'HEADING_MICROSOFT_ACCOUNT_CREDENTIALS' | translate">
      </teams-button>
      <teams-button aria-label="HEADING_AWS_CREDENTIALS TAB"
        *ngIf="(labDetail.DoNotSendCloudCredentials !== true || attendeLabDetails.DeploymentOutputValues?.length > 0) && attendeLabDetails.CloudPlatformId === 2"
        [classList]="selectedTab === 'Aws' ? 'btn ts-btn-fluent-primary active' : 'btn ts-btn-fluent-white' "
        (buttonClick)="selectEnvironment('Aws')" [title]="'HEADING_AWS_CREDENTIALS' | translate"></teams-button>
      <teams-button aria-label="GCP CREDENTIALS TAB"
        *ngIf="(labDetail.DoNotSendCloudCredentials !== true || attendeLabDetails.DeploymentOutputValues?.length > 0) && attendeLabDetails.CloudPlatformId === 3"
        [classList]="selectedTab === 'Gcp' ? 'btn ts-btn-fluent-primary active' : 'btn ts-btn-fluent-white' "
        (buttonClick)="selectEnvironment('Gcp')" [title]="'HEADING_GCP_CREDENTIALS' | translate"></teams-button>
      <teams-button aria-label="OCI CREDENTIALS TAB"
        *ngIf="(labDetail.DoNotSendCloudCredentials !== true || attendeLabDetails.DeploymentOutputValues?.length > 0) && attendeLabDetails.CloudPlatformId === 4"
        [classList]="selectedTab === 'Oci' ? 'btn ts-btn-fluent-primary active' : 'btn ts-btn-fluent-white' "
        (buttonClick)="selectEnvironment('Oci')" [title]="'HEADING_OCI_CREDENTIALS' | translate"></teams-button>
      <teams-button aria-label="SERVICE PRINCIPAL DETAILS TAB"
        *ngIf="attendeLabDetails.AADSPAppId && attendeLabDetails.CloudPlatformId === 1"
        [classList]="selectedTab === 'Service' ? 'btn ts-btn-fluent-primary active' : 'btn ts-btn-fluent-white' "
        (buttonClick)="selectEnvironment('Service')" [title]="'SERVICE_PRINCIPAL_DETAILS' | translate"></teams-button>
      <teams-button aria-label="LICENSES TAB" *ngIf="licenses === true"
        [classList]="selectedTab === 'Licenses' ? 'btn ts-btn-fluent-primary active' : 'btn ts-btn-fluent-white' "
        (buttonClick)="selectEnvironment('Licenses')" [title]="labDetail.LicenseLabel !== null ? labDetail.LicenseLabel : 'LICENSES' |
        translate"></teams-button>
      <teams-button aria-label="ACCESS KEY DETAILS TAB"
        *ngIf="attendeLabDetails.AADSPAppId && attendeLabDetails.CloudPlatformId === 2"
        [classList]="selectedTab === 'Access key details' ? 'btn ts-btn-fluent-primary active' : 'btn ts-btn-fluent-white' "
        (buttonClick)="selectEnvironment('Access key details')" [title]="'ACCESS_KEY_DETAILS' | translate">
      </teams-button>
    </div>
    <div class="content-wrp mt-3">
      <div class="tab-content" id="lab-environment-tab">
        <div class="guide-tabs" [class.showtab]="selectedTab === 'Azure'">
          <p *ngIf="labDetail.AllowExistingAD === true" class="pt-2 pb-4"
            [innerHTML]="'AZURE_LOGIN' | translate: {AADEmail: attendeLabDetails.AADEmail}"></p>
          <div class="row" *ngIf="labDetail.DoNotSendCloudCredentials !== true && labDetail.AllowExistingAD !== true">
            <div class="col-md-12 pt-2 pb-4" *ngIf="!attendeLabDetails?.CustomInstruction">
              <p id="instructions-azure-login"
                [innerHTML]="'INSTRUCTION_AZURE_LOGIN_RDP' | translate"></p>
              <!-- <p id="instructions-azure-login" *ngIf="attendeLabDetails?.CustomInstruction"
                [innerHTML]="attendeLabDetails.CustomInstruction"></p> -->
            </div>
            <div class="col-md-12">
              <table class="table" tabindex="0">
                <thead>
                  <tr>
                    <th>{{ 'FIELDS_LABEL' | translate}}</th>
                    <th>{{ 'VALUE_LABEL' | translate}}</th>
                    <th>{{ 'ACTION_LABEL' | translate}}</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>{{ 'USERNAME' | translate}}</td>
                    <td>
                      <teams-input [ariaLabel]="attendeLabDetails.AADEmail" 
                        [(ngModel)]="attendeLabDetails.AADEmail" [value]="attendeLabDetails.AADEmail"
                        [classList]="'form-control font-size-class'" [disabled]="true"></teams-input>
                    </td>
                    <td><a role="button" ngxClipboard tooltip="{{'COPY' | translate}} {{'USERNAME' | translate}}"
                        [cbContent]="attendeLabDetails.AADEmail" class="ml-4" href="javascript:void(0)"
                        attr.aria-label="{{'COPY' | translate}} username">
                        <i role="img" id="elementEnv" aria-hidden="true" aria-label="img" alt="" class="copyelmnt fa fa-clone"></i>
                      </a>
                    </td>
                  </tr>
                  <tr>
                    <td>{{ 'PASSWORD' | translate}}</td>
                    <td>
                      <teams-input [ariaLabel]="attendeLabDetails.TempPassword" 
                        [(ngModel)]="attendeLabDetails.TempPassword" [value]="attendeLabDetails.TempPassword"
                        [classList]="'form-control font-size-class'" [disabled]="true"></teams-input>
                    </td>
                    <td><a role="button" ngxClipboard tooltip="{{'COPY' | translate}} {{'PASSWORD_LABEL' | translate}}"
                        [cbContent]="attendeLabDetails.TempPassword" class="ml-4" href="javascript:void(0)"
                        attr.aria-label="{{'COPY' | translate}} password">
                        <i role="img" id="elementEnv" aria-hidden="true" aria-label="img" alt="" class="copyelmnt fa fa-clone"></i>
                      </a></td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          <div class="accroding-sec-2" *ngIf="attendeLabDetails.DeploymentOutputValues?.length > 1 && outputParameter">
            <div class="main-acco cred" [ngClass]="{'active-10': accordionToggleInfo[indexOfElement]}" tabindex="0"
              *ngFor="let rg of attendeLabDetails.DeploymentOutputValues; let indexOfElement=index;"
              [attr.aria-labelledby]="'rg-table' + indexOfElement" (keyup.enter)="toggleAccordion(indexOfElement)">
              <ng-container *ngIf="rg?.OutputValues?.length > 0 ">
                <teams-button [classList]="'accordion'" (buttonClick)="toggleAccordion(indexOfElement)" tabindex="-1">
                  <strong [id]="'rg-table' + indexOfElement">{{'RESOURCE_GROUP' | translate}} :
                    {{rg.ResourceGroupName}}</strong>
                  <span>
                    <i class="fa fa-angle-right accordion-icon"
                      [ngClass]="{'accordion-icon-active': accordionToggleInfo[indexOfElement]}" aria-hidden="true">
                    </i>
                  </span>
                </teams-button>
                <div class="panel" *ngIf="accordionToggleInfo[indexOfElement]">
                  <div class="row">
                    <div class="col-md-12">
                      <table class="table">
                        <thead>
                          <tr>
                            <th>{{ 'KEY_LABEL' | translate}}</th>
                            <th>{{ 'VALUE_LABEL' | translate}}</th>
                            <th>{{ 'ACTION_LABEL' | translate}}</th>
                          </tr>
                        </thead>
                        <tbody>
                          <ng-container *ngFor="let row of rg.OutputValues">
                            <tr *ngIf="row.ExcludeOutputParameter === false">
                              <td>{{row.Name | pascalCase}}</td>
                              <td>
                                <teams-input [ariaLabel]="row.Value" [(ngModel)]="row.Value" 
                                  [value]="row.Value.length" [classList]="'form-control font-size-class'" [disabled]="true" ></teams-input>
                              </td>
                              <td><a role="button" ngxClipboard
                                  [tooltip]="'CLICK_TO_COPY_FIELD' | translate :{field: row.Name}"
                                  [attr.aria-label]="'CLICK_TO_COPY_FIELD' | translate:{ field: row.Name}"
                                  [cbContent]="row.Value" class="ml-4" href="javascript:void(0)">
                                  <i role="img" id="elementEnv" aria-hidden="true" aria-label="img" alt=""
                                    class="copyelmnt fa fa-clone"></i>
                                </a>
                              </td>
                            </tr>
                          </ng-container>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </ng-container>
            </div>
          </div>
          <div *ngIf="attendeLabDetails.DeploymentOutputValues?.length === 1 && outputParameter">
            <div class="row" *ngFor="let rg of attendeLabDetails.DeploymentOutputValues">
              <ng-container *ngIf="rg?.OutputValues?.length > 0">
                <span id="rg-azure-table" role="heading" aria-level="2" *ngIf="!(labDetail.DoNotSendCloudCredentials === true)" class="col-md-12"
                  style="padding-bottom:10px;"><strong>{{'RESOURCE_GROUP' | translate}} :
                    {{rg.ResourceGroupName}}</strong></span>
                <div class="col-md-12">
                  <table class="table" aria-labelledby="rg-azure-table" tabindex="0">
                    <thead>
                      <tr>
                        <th>{{ 'KEY_LABEL' | translate}}</th>
                        <th>{{ 'VALUE_LABEL' | translate}}</th>
                        <th>{{ 'ACTION_LABEL' | translate}}</th>
                      </tr>
                    </thead>
                    <tbody>
                      <ng-container *ngFor="let row of rg.OutputValues">
                        <tr *ngIf="row.ExcludeOutputParameter === false">
                          <td>{{row.Name | pascalCase}}</td>
                          <td>
                              <teams-input [ariaLabel]="row.Value"  [(ngModel)]="row.Value" 
                              [value]="row.Value" [classList]="'form-control font-size-class'" [disabled]="true" ></teams-input>
                            </td>
                          <td><a role="button" ngxClipboard
                              [tooltip]="'CLICK_TO_COPY_FIELD' | translate :{field: row.Name}"
                              [attr.aria-label]="'CLICK_TO_COPY_FIELD' | translate:{ field: row.Name}"
                              [cbContent]="row.Value" class="ml-4" href="javascript:void(0)">
                              <i role="img" id="elementEnv" aria-hidden="true" aria-label="img" alt="" class="copyelmnt fa fa-clone"></i>
                            </a>
                          </td>
                        </tr>
                      </ng-container>
                    </tbody>
                  </table>
                </div>
              </ng-container>
            </div>
          </div>
          <div *ngIf="labDetail.EnableAccessOverHttp && minGuide === false" class="row">
            <div class="col-md-12 text-center">
              <div *ngFor="let vm of attendeLabDetails.VMLaunchURL; let i = index;"
                style="padding:10px;display:inline-block;">
                <teams-button aria-label="Launch url" role="button" [titleText]="'LAUNCH_VM_IN_BROWSER' | translate"
                  appDebounceClick (debounceClick)="openVMandGitDoc(vm)"
                  [classList]="'btn btn-sm ts-btn-fluent-primary launchButton'" *ngIf="vm.LaunchURL !== null"
                  ><i alt="" role="img" aria-label="img" aria-hidden="true" class="mr-1"
                    [ngClass]="vm.Type === 'Microsoft.Compute/virtualMachines' ? 'fas fa-desktop' : 'fas fa-server' "></i> 
                    {{ 'GO_TO' | translate}} {{vm.VMName}}</teams-button>
              </div>
              <div
                *ngIf="labDetail.ACIDeploymentType === 2 && !operationModeACI && attendeLabDetails?.VMLaunchURL?.length === 0"
                style="padding:10px;display:inline-block;">
                <teams-button aria-label="Launch url" [disabled]="deployACIBtnDisable" role="button"
                  [titleText]="'LAUNCH_VM_IN_BROWSER' | translate" (buttonClick)="deployACILab()" ><i
                    alt="" role="img" aria-label="img" aria-hidden="true" class="fa fa-arrow-right"></i>
                  {{'ACCESS_VM_OVER_HTTPS' | translate}}
                </teams-button>
              </div>
              <div
                *ngIf="operationModeACI === operationModes.INITIATION || operationModeACI === operationModes.GATHERINGINFO"
                class="lab-container p-4">
                <h4 class="mb-4 loadLab">
                  <ng-container *ngIf="!isCL">
                    <cloudlabs-vm-spinner></cloudlabs-vm-spinner>
                    &nbsp;&nbsp;
                  </ng-container>{{(operationModeACI === operationModes.GATHERINGINFO) ?
                  ("MESSAGE_INITIALIZING_ENVIRONMENT" | translate) : ("MESSAGE_PREPARING_ENVIRONMENT" | translate) }}
                </h4>
                <ng-container *ngIf="operationModeACI === operationModes.INITIATION">
                  <p>
                    <span>{{"ACI_IN_PROGRESS" | translate }} </span><span *ngIf="remainingMint"> 1 </span>
                    <countdown *ngIf="!remainingMint" #cd [config]="remainingDuration"
                      (event)="handleCountDown($event)"></countdown><span> {{"MINUTES" | translate:{singularPlural: remainingDuration.leftTime | pluralize} }}</span>
                  </p>
                  <div class="progress mb-3">
                    <div class="progress-bar" [ngStyle]="{'width': progressBar + '%'}">{{progressBar}} %</div>
                  </div>
                </ng-container>
              </div>
              <div *ngIf="operationModeACI === operationModes.FAILED" class="row">
                <div class="col-md-8 ">
                  <div class="alert alert-danger">
                    <h4 class="block" role="alert">
                      <i role="img" alt="" aria-label="img" aria-hidden="true" class="fas fa-times"></i>
                      &nbsp;
                      <span *ngIf="labDetail?.PartnerName !== 'VMware'"
                        [innerHTML]="'MESSAGE_FAILED' | translate: { Email: labDetail.ContactEmail }"></span>
                      <span *ngIf="labDetail?.PartnerName === 'VMware'"
                        [innerHTML]="'MESSAGE_FAILED_VMWARE' | translate: { Email: labDetail.ContactEmail }"></span>
                    </h4>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="row pb-4 lab_Urls">
            <div class="col-md-12">
              <div *ngIf="attendeLabDetails.LabGuidUrl !== null && attendeLabDetails.LabGuidUrl !== '' && type === null"
                style="padding: 5px 0">
                <span><strong>{{ 'LAB_GUIDE' | translate }} </strong><a role="link" aria-label="Labguide url"
                    href="{{attendeLabDetails.LabGuidUrl}}" target="_blank">{{"CLICK_HERE" | translate}}</a></span>
              </div>
              <div *ngIf="attendeLabDetails.LabInformationURL !=='' && attendeLabDetails.LabInformationURL !== null"
                class="" style="padding: 5px 0">
                <span><strong>{{ 'HELP_DOCUMEN_URL' | translate}} </strong><a role="link" aria-label="button"
                    href="{{attendeLabDetails.LabInformationURL}}" target="_blank">{{"CLICK_HERE" |
                    translate}}</a></span>
              </div>
              <div *ngIf="attendeLabDetails.PreGuideUrl !=='' && attendeLabDetails.PreGuideUrl !== null"
                style="padding: 5px 0;">
                <span>{{ 'INSTRUCTION_PREREQUISITES_FOR_LAB' | translate}} <a role="link" aria-label="button"
                    href="{{attendeLabDetails.PreGuideUrl}}" target="_blank">{{"CLICK_HERE" | translate}}</a></span>
              </div>
            </div>
          </div>
          <!-- <teams-button *ngIf="labDetail.AllowUserToDeleteODL === true && isLabDetailsPage && labDetail.UserLabExperienceTypes.length !== 0 && labDetail.UserLabExperienceTypes[0].Id !== 2"
            [classList]="'btn btn-sm ts-btn-fluent-red delete-lab'"
            (buttonClick)="deallocateEnvironment()"><i class="fa fa-trash"
              aria-hidden="true"></i><span>{{'BUTTON_DELETE_ON_DEMAND_LAB' | translate | uppercase}}</span>
          </teams-button> -->
        </div>
        <div class="guide-tabs" [class.showtab]="selectedTab === 'Ms'"
          *ngIf="mode===operationModes.AZUREPASSUSERDETAILS">
          <!--use System Created directory -->
          <div class="row" *ngIf="attendeLabDetails.AADEmail !== null">
            <div class="col-md-12 pt-2 pb-4">
              <h5 translate="HEADING_MICROSOFT_ACCOUNT_CREDENTIALS"></h5>
              <p id="azure-pass-sys"
                innerHTML="{{'AZURE_PASS_SYS_DIRECTORY' | translate : {TabName: labDetail.LicenseLabel} }}"></p>
            </div>
            <div class="col-md-12">
              <table class="table" aria-label="Microsoft Account Credentials table" aria-describedby="azure-pass-sys"
                tabindex="0">
                <thead>
                  <tr>
                    <th>{{ 'FIELDS_LABEL' | translate}}</th>
                    <th>{{ 'VALUE_LABEL' | translate}}</th>
                    <th>{{ 'ACTION_LABEL' | translate}}</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>{{ 'USERNAME' | translate}}</td>
                    <td><teams-input [ariaLabel]="attendeLabDetails.AADEmail"  
                        [(ngModel)]="attendeLabDetails.AADEmail" [value]="attendeLabDetails.AADEmail" [classList]="'form-control font-size-class'"
                        [disabled]="true" ></teams-input></td>
                    <td><a role="button" ngxClipboard tooltip="{{'COPY' | translate}} {{'USERNAME' | translate}}"
                        [cbContent]="attendeLabDetails.AADEmail" class="ml-4" href="javascript:void(0)"
                        attr.aria-label="{{'COPY' | translate}} Username">
                        <i role="img" id="elementEnv" aria-hidden="true" aria-label="img" alt="" class="copyelmnt fa fa-clone"></i>
                      </a>
                    </td>
                  </tr>
                  <tr>
                    <td>{{ 'PASSWORD' | translate}}</td>
                    <td> <teams-input [ariaLabel]="attendeLabDetails.TempPassword"  
                        [(ngModel)]="attendeLabDetails.TempPassword" [value]="attendeLabDetails.TempPassword"
                        [classList]="'form-control font-size-class'" [disabled]="true" ></teams-input></td>
                    <td><a role="button" ngxClipboard tooltip="{{'COPY' | translate}} {{'PASSWORD_LABEL' | translate}}"
                        [cbContent]="attendeLabDetails.TempPassword" class="ml-4" href="javascript:void(0)"
                        attr.aria-label="{{'COPY' | translate}} password">
                        <i role="img" id="elementEnv" aria-hidden="true" aria-label="img" alt="" class="copyelmnt fa fa-clone"></i>
                      </a></td>
                  </tr>
                </tbody>
              </table>
              <div *ngIf="attendeLabDetails.PreGuideUrl !=='' && attendeLabDetails.PreGuideUrl !== null"
                style="padding: 5px 0; padding-left: 15px;">
                <span>{{ 'INSTRUCTION_PREREQUISITES_FOR_LAB' | translate}} <a role="link" aria-label="button"
                    href="{{attendeLabDetails.PreGuideUrl}}" target="_blank">{{"CLICK_HERE" | translate}}</a></span>
              </div>

              <div style="padding: 5px 0; padding-left: 15px;">
                <span>{{ 'INSTRUCTION_AZURE_PASS_LAUNCH_LAB' | translate}}</span>
              </div>
            </div>
          </div>
          <!-- use System Created directory closed here -->
          <!--use user Created directory -->
          <div class="row"
            *ngIf="subscriptionError === null && attendeLabDetails.AADEmail === null && !attendeLabDetails?.ConfiguredAzurePassSubscription">
            <div class="col-md-12 pt-2 pb-4">
              <p id="azure-pass-sys"
                [innerHTML]="'AZURE_PASS_USER_DIRECTORY' | translate : {TabName: labDetail.LicenseLabel}"></p>
            </div>
            <div class="col-md-12 pt-2 pb-4">
              <p id="azure-pass-sys" innerHTML="{{'INSTRUCTION_AZURE_PASS_SUB_ONBOARDING' | translate}}"></p>
            </div>

            <!--Azure Pass Subscription Onboarding form-->
            <form name="FrmManageAzurePassSubscription" #f="ngForm" class="col-md-12 pb-4 subscription-form" role="form"
              (ngSubmit)="f.form.valid && submitAzurePassSubscription(f.value)" novalidate>
              <!--Directory Id-->
              <div class="form-group ml-0 mr-0 row pl-0">
                <label class="control-label pl-0 col-md-4" [innerHTML]="'DIRECTORY_TENANT_ID' | translate">
                  <span class="text-danger">*</span>
                </label>
                <div class="col-md-8 pl-0">
                  <teams-input  appWhiteSpaceValidator
                    [ngClassList]="(tenantId.touched || f.submitted) && tenantId?.errors?.required ? 'is-invalid' : ''"
                     [autocomplete]="'off'" name="TenantId" #tenantId="ngModel"
                    [value]="attendeLabDetails.TenantId" [ariaLabel]="attendeLabDetails.TenantId"
                    [(ngModel)]="attendeLabDetails.TenantId" [classList]="'form-control font-size-class'" [pattern]="'^[a-zA-Z0-9- ]*$'"
                    [maxlength]="'500'" [required]="true" ></teams-input>
                  <span
                    *ngIf="(tenantId.touched || f.submitted) && (tenantId?.errors?.required || tenantId.errors?.isEmpty)"
                    class="text-danger">* {{'MESSAGE_THIS_FIELD_REQUIRED' | translate}}</span>
                  <span *ngIf="tenantId.touched && tenantId?.errors?.pattern" class="text-danger">*
                    {{'ERROR_MESSAGE_DASH_SYMBOL' | translate}}</span>
                </div>
              </div>
              <!--Tenant Domain Name-->
              <div class="form-group ml-0 mr-0 row pl-0">
                <label class="control-label pl-0 col-md-4" [innerHTML]="'TENANT_DOMAIN_NAME' | translate">
                  <span class="text-danger">*</span>
                </label>
                <div class="col-md-8 pl-0">
                  <teams-input type="url" appWhiteSpaceValidator
                    [ngClass]="{ 'alert-danger' : (tenantDomainName.touched || f.submitted) && tenantDomainName?.errors?.required }"
                     name="TenantDomainName" #tenantDomainName="ngModel"
                    [value]="attendeLabDetails.TenantDomainName" [ariaLabel]="attendeLabDetails.TenantDomainName"
                    [(ngModel)]="attendeLabDetails.TenantDomainName" [autocomplete]="'off'" [classList]="'form-control font-size-class'"
                    [pattern]="'^[a-zA-Z0-9. ]*$'" [maxlength]="'500'" [required]="true" ></teams-input>
                  <span
                    *ngIf="(tenantDomainName.touched || f.submitted) && (tenantDomainName?.errors?.required || tenantDomainName.errors?.isEmpty)"
                    class="text-danger">* {{'MESSAGE_THIS_FIELD_REQUIRED' | translate}}</span>
                  <span *ngIf="tenantDomainName.touched && tenantDomainName?.errors?.pattern" class="text-danger">*
                    {{'ERROR_MESSAGE_DOT_SYMBOL' | translate}}</span>
                </div>
              </div>
              <!--Subscription Id-->
              <div class="form-group ml-0 mr-0 row pl-0">
                <label class="control-label pl-0 col-md-4" [innerHTML]="'SUBSCRIPTION_ID' | translate">
                  <span class="text-danger">*</span>
                </label>
                <div class="col-md-8 pl-0">
                  <teams-input  appWhiteSpaceValidator
                    [ngClassList]="(subscriptionId.touched || f.submitted) && subscriptionId?.errors?.required  ? 'alert-danger' : '' "
                     name="Guid" #subscriptionId="ngModel" [value]="attendeLabDetails.SubscriptionGuid"
                    [ariaLabel]="attendeLabDetails.SubscriptionGuid"
                    [(ngModel)]="attendeLabDetails.SubscriptionGuid" [autocomplete]="'off'" [classList]="'form-control font-size-class'" [required]="true" ></teams-input>
                  <span
                    *ngIf="(subscriptionId.touched || f.submitted) && (subscriptionId?.errors?.required || subscriptionId.errors?.isEmpty)"
                    class="text-danger">* {{'MESSAGE_THIS_FIELD_REQUIRED' | translate}}</span>
                </div>
              </div>
              <!--App Id-->
              <div class="form-group ml-0 mr-0 row pl-0">
                <label class="control-label pl-0 col-md-4" [innerHTML]=" 'USER NAME_APP_ID' | translate">
                  <span class="text-danger">*</span>
                </label>
                <div class="col-md-8 pl-0">
                  <teams-input appWhiteSpaceValidator 
                    [ngClassList]=" (appId.touched || f.submitted) && appId?.errors?.required  ? 'alert-danger' : '' "
                     name="AppId" #appId="ngModel" [value]="attendeLabDetails.AADSPAppId"
                    [ariaLabel]="attendeLabDetails.AADSPAppId" [(ngModel)]="attendeLabDetails.AADSPAppId"
                    [autocomplete]="'off'" [classList]="'form-control font-size-class'" [required]="true" ></teams-input>
                  <span *ngIf="(appId.touched || f.submitted) && (appId?.errors?.required || appId.errors?.isEmpty)"
                    class="text-danger">* {{'MESSAGE_THIS_FIELD_REQUIRED' | translate}}</span>
                </div>
              </div>
              <!--App Secret-->
              <div class="form-group ml-0 mr-0 row pl-0">
                <label class="control-label pl-0 col-md-4" [innerHTML]="'PASSWORD_APP_SECRET'| translate">
                  <span class="text-danger">*</span>
                </label>
                <div class="col-md-8 pl-0">
                  <teams-input type="password"
                    [ngClassList]="(appSecret.touched || f.submitted) && appSecret?.errors?.required ? 'alert-danger' : ''"
                    name="AppSecret" #appSecret="ngModel" [value]="attendeLabDetails.AADSPAppKey"
                    [ariaLabel]="attendeLabDetails.AADSPAppKey" [(ngModel)]="attendeLabDetails.AADSPAppKey"
                    [autocomplete]="'off'" [classList]="'form-control font-size-class'" [required]="true" ></teams-input>
                  <span *ngIf="(appSecret.touched || f.submitted) && appSecret?.errors?.required" class="text-danger">*
                    {{'MESSAGE_THIS_FIELD_REQUIRED' | translate}}</span>
                </div>
              </div>

              <div class="col-md-12 pl-0 pt-4" style="text-align: center;">
                <teams-button role="button" aria-label="'ADD_SUBSCRIPTION' | translate"
                  [classList]="'btn ts-btn-fluent-primary blue'">
                  <i alt="" role="img" aria-label="img" aria-hidden="true" class="fa fa-angle-double-right"></i>
                  {{ 'ADD_SUBSCRIPTION' | translate}}
                </teams-button>
              </div>

            </form>

            <div *ngIf="attendeLabDetails.PreGuideUrl !=='' && attendeLabDetails.PreGuideUrl !== null"
              style="padding: 5px 0; padding-left: 15px;" class="pb-4">
              <span>{{ 'INSTRUCTION_PREREQUISITES_FOR_LAB' | translate}} <a role="link" aria-label="button"
                  href="{{attendeLabDetails.PreGuideUrl}}" target="_blank">{{"CLICK_HERE" | translate}}</a></span>
            </div>
            <!--use user Created directory closed here-->
          </div>
          <!-- For user created directory-->
          <p style="padding: 5px 0; padding-left: 15px;"
            *ngIf="attendeLabDetails.AADEmail === null && attendeLabDetails.ConfiguredAzurePassSubscription"
            [innerHTML]="'AZURE_PASS_READY_MESSAGE'|translate"></p>

          <!-- launch button -->
          <div class="row mb-5 pb-5 pt-2"
            *ngIf="mode===operationModes.AZUREPASSUSERDETAILS && ((attendeLabDetails.AADEmail === null && attendeLabDetails.ConfiguredAzurePassSubscription) || labDetail.DoNotSendCloudCredentials !== true)">
            <div class="col-md-12 text-center">
              <teams-button role="button" [title]="'BUTTON_LAUNCH_LAB' | translate " attr.aria-label="Launch Lab"
                (buttonClick)="startLab()" [classList]="'btn btn-lg ts-btn-fluent-primary launchBtn'"
                style="margin: auto; display: block">
              </teams-button>
            </div>
          </div>
          <!-- error on subscription -->
          <div *ngIf="subscriptionError !== null" class="row">
            <div class="col-lg-8">
              <div class="alert alert-danger">
                <h4 class="block" role="alert">
                  <i role="img" alt="" aria-label="img" aria-hidden="true" class="fas fa-times"></i>
                  &nbsp;
                  <span *ngIf="labDetail?.PartnerName !== 'VMware'"
                    [innerHTML]="'MESSAGE_FAILED' | translate: { Email: labDetail.ContactEmail }"></span>
                  <span *ngIf="labDetail?.PartnerName === 'VMware'"
                    [innerHTML]="'MESSAGE_FAILED_VMWARE' | translate: { Email: labDetail.ContactEmail }"></span>
                </h4>
              </div>
            </div>
            <span class="col-lg-4 text-danger pl-3 pt-2 pb-4">{{subscriptionError}}</span>
          </div>
          <!-- Subscription error section closed -->
        </div>
        <!-- Ms Tab closed -->

        <div class="guide-tabs" [class.showtab]="selectedTab === 'Aws'">
          <!--   AWS   -->
          <div class="row" *ngIf="labDetail.DoNotSendCloudCredentials !== true">
            <div class="col-md-12 pt-2 pb-4">
              <p id="instruction-aws-login" *ngIf="!attendeLabDetails?.CustomInstruction"
                [innerHTML]="'INSTRUCTION_AWS_LOGIN_RDP' | translate"></p>
              <p id="instruction-aws-login" *ngIf="attendeLabDetails?.CustomInstruction"
                [innerHTML]="attendeLabDetails.CustomInstruction"></p>
            </div>
            <div class="col-md-12">
              <table class="table" aria-label="AWS login credentials table" aria-describedby="instruction-aws-login">
                <thead>
                  <tr>
                    <th>{{ 'FIELDS_LABEL' | translate}}</th>
                    <th>{{ 'VALUE_LABEL' | translate}}</th>
                    <th>{{ 'ACTION_LABEL' | translate}}</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>{{ 'SIGN-IN_LINK' | translate}}</td>
                    <td><teams-input [ariaLabel]="awsLink"   [(ngModel)]="awsLink"
                        [value]="awsLink" [classList]="'form-control font-size-class'" [disabled]="true" ></teams-input></td>
                    <td><a role="button" ngxClipboard tooltip="{{'COPY' | translate}} sign-in link"
                        [cbContent]="awsLink" class="ml-4" href="javascript:void(0)"
                        attr.aria-label="{{'COPY' | translate}} sign-in link">
                        <i role="img" id="elementEnv" aria-hidden="true" aria-label="img" alt="" class="copyelmnt fa fa-clone"></i>
                      </a></td>
                  </tr>
                  <tr>
                    <td>{{ 'USERNAME' | translate}}</td>
                    <td><teams-input [ariaLabel]="attendeLabDetails.AADEmail"  
                        [(ngModel)]="attendeLabDetails.AADEmail" [value]="attendeLabDetails.AADEmail" [classList]="'form-control font-size-class'"
                        [disabled]="true" ></teams-input></td>
                    <td><a role="button" ngxClipboard tooltip="{{'COPY' | translate}} {{'USERNAME' | translate}}"
                        [cbContent]="attendeLabDetails.AADEmail" class="ml-4" href="javascript:void(0)"
                        attr.aria-label="{{'COPY' | translate}} username">
                        <i role="img" id="elementEnv" aria-hidden="true" aria-label="img" alt="" class="copyelmnt fa fa-clone"></i>
                      </a></td>
                  </tr>
                  <tr>
                    <td>{{ 'PASSWORD' | translate}}</td>
                    <td> <teams-input [ariaLabel]="attendeLabDetails.TempPassword"  
                        [(ngModel)]="attendeLabDetails.TempPassword" [value]="attendeLabDetails.TempPassword"
                        [classList]="'form-control font-size-class'" [disabled]="true" ></teams-input></td>
                    <td><a role="button" ngxClipboard tooltip="{{'COPY' | translate}} {{'PASSWORD_LABEL' | translate}}"
                        [cbContent]="attendeLabDetails.TempPassword" class="ml-4" href="javascript:void(0)"
                        attr.aria-label="{{'COPY' | translate}} password">
                        <i role="img" id="elementEnv" aria-hidden="true" aria-label="img" alt="" class="copyelmnt fa fa-clone"></i>
                      </a></td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          <div class="row" *ngFor="let rg of attendeLabDetails.DeploymentOutputValues">
            <div class="col-md-12">
              <table class="table" aria-label="Resource Group Table" tabindex="0">
                <thead>
                  <tr>
                    <th>{{ 'KEY_LABEL' | translate}}</th>
                    <th>{{ 'VALUE_LABEL' | translate}}</th>
                    <th>{{ 'ACTION_LABEL' | translate}}</th>
                  </tr>
                </thead>
                <tbody>
                  <ng-container *ngFor="let row of rg.OutputValues">
                    <tr *ngIf="row?.ExcludeOutputParameter === false">
                      <td>{{row.Name | pascalCase}}</td>
                      <td><teams-input [ariaLabel]="row.Value"  [(ngModel)]="row.Value" 
                          [value]="row.Value" [classList]="'form-control font-size-class'" [disabled]="true" ></teams-input></td>
                      <td><a role="button" ngxClipboard tooltip="Click to copy value of {{row.Name}}"
                          [attr.aria-label]="'Click to copy value of ' + row.Name" [cbContent]="row.Value" class="ml-4"
                          href="javascript:void(0)">
                          <i role="img" id="elementEnv" aria-hidden="true" aria-label="img" alt="" class="copyelmnt fa fa-clone"></i>
                        </a>
                      </td>
                    </tr>
                  </ng-container>
                </tbody>
              </table>
            </div>
          </div>
          <div *ngIf="labDetail.EnableAccessOverHttp && minGuide === false" class="row">
            <div class="col-md-12 text-center">
              <div *ngFor="let vm of attendeLabDetails.VMLaunchURL; let i = index;"
                style="padding:10px;display:inline-block;">
                <teams-button [classList]="'btn button-height-1 ts-btn ts-btn-fluent ts-btn-fluent-primary ts-btn-width-default btn-sm'" aria-label="Launch url" role="button" [titleText]="'LAUNCH_VM_IN_BROWSER' | translate"
                  appDebounceClick (debounceClick)="openVMandGitDoc(vm)" *ngIf="vm.LaunchURL !== null"
                  style="width:200px;"><i alt="" role="img" aria-label="img" aria-hidden="true"
                    class="fa fa-arrow-right"></i> {{ 'GO_TO' | translate}} {{vm.VMName}}
                </teams-button>
              </div>
            </div>
          </div>
          <div class="row pb-4 lab_Urls">
            <div class="col-md-12">
              <div *ngIf="attendeLabDetails.LabGuidUrl !== null && attendeLabDetails.LabGuidUrl !== '' && type === null"
                style="padding: 5px 0">
                <span><strong>{{ 'LAB_GUIDE' | translate }} </strong><a role="link" aria-label="Labguide url"
                    href="{{attendeLabDetails.LabGuidUrl}}" target="_blank">{{"CLICK_HERE" | translate}}</a></span>
              </div>
              <div *ngIf="attendeLabDetails.LabInformationURL !=='' && attendeLabDetails.LabInformationURL !== null"
                class="" style="padding: 5px 0">
                <span><strong>{{ 'HELP_DOCUMEN_URL' | translate}} </strong><a role="link" aria-label="button"
                    href="{{attendeLabDetails.LabInformationURL}}" target="_blank">{{"CLICK_HERE" |
                    translate}}</a></span>
              </div>
              <div *ngIf="attendeLabDetails.PreGuideUrl !=='' && attendeLabDetails.PreGuideUrl !== null"
                style="padding: 5px 0; padding-left: 15px;">
                <span>{{ 'INSTRUCTION_PREREQUISITES_FOR_LAB' | translate}} <a role="link" aria-label="button"
                    href="{{attendeLabDetails.PreGuideUrl}}" target="_blank">{{"CLICK_HERE" | translate}}</a></span>
              </div>
            </div>
          </div>
          <!-- <teams-button *ngIf="labDetail.AllowUserToDeleteODL === true && isLabDetailsPage && labDetail.UserLabExperienceTypes.length > 0 && labDetail.UserLabExperienceTypes[0].Id !== 2"
            [classList]="'btn btn-sm ts-btn-fluent-red delete-lab'" (click)="deallocateEnvironment()"><i
              class="fa fa-trash" aria-hidden="true"></i><span>{{'BUTTON_DELETE_ON_DEMAND_LAB' | translate |
              uppercase}}</span></teams-button> -->
        </div>
        <!--GCP Credentials-->
        <div class="guide-tabs" [class.showtab]="selectedTab === 'Gcp'">
          <div class="row" *ngIf="labDetail.DoNotSendCloudCredentials !== true">
            <div class="col-md-12 pt-2 pb-4">
              <p id="instructions-gcp-login" *ngIf="!attendeLabDetails?.CustomInstruction"
                [innerHTML]="'INSTRUCTION_GCP_LOGIN_RDP' | translate:{gcpLink: gcpLink}"></p>
              <p id="instructions-gcp-login" *ngIf="attendeLabDetails?.CustomInstruction"
                [innerHTML]="attendeLabDetails.CustomInstruction"></p>
            </div>
            <div class="col-md-12">
              <table class="table" aria-label="GCP Credentials table" aria-describedby="instructions-gcp-login"
                tabindex="0">
                <thead>
                  <tr>
                    <th>{{ 'FIELDS_LABEL' | translate}}</th>
                    <th>{{ 'VALUE_LABEL' | translate}}</th>
                    <th>{{ 'ACTION_LABEL' | translate}}</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>{{ 'SIGN-IN_LINK' | translate}}</td>
                    <td><teams-input [ariaLabel]="gcpLink"   [(ngModel)]="gcpLink"
                        [value]="gcpLink" [classList]="'form-control font-size-class'" [disabled]="true" ></teams-input></td>
                    <td>
                      <a role="button" ngxClipboard [cbContent]="gcpLink" class="ml-4" tooltip="Click to copy Sign-in link"
                        attr.aria-label="Click to copy Sign-in link" href="javascript:void(0)"
                        title="{{'COPY' | translate}}">
                        <i role="img" id="elementEnv" aria-hidden="true" aria-label="img" alt="" class="copyelmnt fa fa-clone"></i>
                      </a>
                    </td>
                  </tr>
                  <tr>
                    <td>{{ 'USERNAME' | translate}}</td>
                    <td><teams-input [ariaLabel]="attendeLabDetails.AADEmail"  
                        [(ngModel)]="attendeLabDetails.AADEmail" [value]="attendeLabDetails.AADEmail" [classList]="'form-control font-size-class'"
                        [disabled]="true" ></teams-input></td>
                    <td><a role="button" ngxClipboard tooltip="{{'COPY' | translate}} {{'USERNAME' | translate}}"
                        [cbContent]="attendeLabDetails.AADEmail" class="ml-4" href="javascript:void(0)"
                        attr.aria-label="{{'COPY' | translate}} username">
                        <i role="img" id="elementEnv" aria-hidden="true" aria-label="img" alt="" class="copyelmnt fa fa-clone"></i>
                      </a>
                    </td>
                  </tr>
                  <tr>
                    <td>{{ 'PASSWORD' | translate}}</td>
                    <td> <teams-input [ariaLabel]="attendeLabDetails.TempPassword"  
                        [(ngModel)]="attendeLabDetails.TempPassword" [value]="attendeLabDetails.TempPassword"
                        [classList]="'form-control font-size-class'" [disabled]="true" ></teams-input>
                    </td>
                    <td><a role="button" ngxClipboard tooltip="{{'COPY' | translate}} {{'PASSWORD_LABEL' | translate}}"
                        [cbContent]="attendeLabDetails.TempPassword" class="ml-4" href="javascript:void(0)"
                        attr.aria-label="{{'COPY' | translate}}">
                        <i role="img" id="elementEnv" aria-hidden="true" aria-label="img" alt="" class="copyelmnt fa fa-clone"></i>
                      </a>
                    </td>
                  </tr>
                  <tr *ngIf="attendeLabDetails.TenantDomainName !== null">
                    <td>{{ 'ORGANIZATION' | translate}}</td>
                    <td> <teams-input [ariaLabel]="attendeLabDetails.TenantDomainName"  
                        [(ngModel)]="attendeLabDetails.TenantDomainName" [value]="attendeLabDetails.TenantDomainName"
                        [classList]="'form-control font-size-class'" [disabled]="true" ></teams-input></td>
                    <td><a role="button" ngxClipboard tooltip="{{'COPY' | translate}} {{'ORGANIZATION' | translate}}"
                        [cbContent]="attendeLabDetails.TenantDomainName" class="ml-4" href="javascript:void(0)"
                        attr.aria-label="{{'COPY' | translate}}">
                        <i role="img" id="elementEnv" aria-hidden="true" aria-label="img" alt="" class="copyelmnt fa fa-clone"></i>
                      </a></td>
                  </tr>
                  <tr *ngIf="attendeLabDetails.ResourceGroupName !== null">
                    <td>{{ 'PROJECT' | translate}}</td>
                    <td> <teams-input [ariaLabel]="attendeLabDetails.ResourceGroupName"  
                        [(ngModel)]="attendeLabDetails.ResourceGroupName" [value]="attendeLabDetails.ResourceGroupName"
                        [classList]="'form-control font-size-class'" [disabled]="true" ></teams-input></td>
                    <td><a role="button" ngxClipboard tooltip="{{'COPY' | translate}} {{'PROJECT_NAME' | translate}}"
                        [cbContent]="attendeLabDetails.ResourceGroupName" class="ml-4" href="javascript:void(0)"
                        attr.aria-label="{{'COPY' | translate}}">
                        <i role="img" id="elementEnv" aria-hidden="true" aria-label="img" alt="" class="copyelmnt fa fa-clone"></i>
                      </a></td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          <div class="row" *ngFor="let rg of attendeLabDetails.DeploymentOutputValues">
            <div class="col-md-12">
              <table class="table" aria-label="Resource Group Table">
                <thead>
                  <tr>
                    <th>{{ 'KEY_LABEL' | translate}}</th>
                    <th>{{ 'VALUE_LABEL' | translate}}</th>
                    <th>{{ 'ACTION_LABEL' | translate}}</th>
                  </tr>
                </thead>
                <tbody>
                  <ng-container *ngFor="let row of rg.OutputValues">
                    <tr *ngIf="row?.ExcludeOutputParameter === false">
                      <td>{{row.Name | pascalCase}}</td>
                      <td><teams-input [ariaLabel]="row.Value"  [(ngModel)]="row.Value" 
                          [value]="row.Value" [classList]="'form-control font-size-class'" [disabled]="true" ></teams-input></td>
                      <td><a role="button" ngxClipboard tooltip="{{'COPY' | translate}} + {{row.Name}}"
                          [attr.aria-label]="'Click to copy value of ' + row.Name" [cbContent]="row.Value" class="ml-4"
                          href="javascript:void(0)">
                          <i role="img" id="elementEnv" aria-hidden="true" aria-label="img" alt="" class="copyelmnt fa fa-clone"></i>
                        </a>
                      </td>
                    </tr>
                  </ng-container>
                </tbody>
              </table>
            </div>
          </div>
          <div *ngIf="labDetail.EnableAccessOverHttp && minGuide === false" class="row">
            <div class="col-md-12 text-center">
              <div *ngFor="let vm of attendeLabDetails.VMLaunchURL; let i = index;"
                style="padding:10px;display:inline-block;">
                <teams-button aria-label="Launch url" role="button" [titleText]="'LAUNCH_VM_IN_BROWSER' | translate"
                  (debounceClick)="openVMandGitDoc(vm)" appDebounceClick *ngIf="vm.LaunchURL !== null"
                  >
                  <i alt="" role="img" aria-label="img" aria-hidden="true" class="fa fa-arrow-right"></i>
                  {{ 'GO_TO' | translate}} {{vm.VMName}}
                </teams-button>
              </div>
            </div>
          </div>
          <div class="row pb-4 lab_Urls">
            <div class="col-md-12">
              <div *ngIf="attendeLabDetails.LabGuidUrl !== null && attendeLabDetails.LabGuidUrl !== '' && type === null"
                style="padding: 5px 0">
                <span><strong>{{ 'LAB_GUIDE' | translate }} </strong><a role="link" aria-label="Labguide url"
                    href="{{attendeLabDetails.LabGuidUrl}}" target="_blank">{{"CLICK_HERE" | translate}}</a></span>
              </div>
              <div *ngIf="attendeLabDetails.LabInformationURL !=='' && attendeLabDetails.LabInformationURL !== null"
                class="" style="padding: 5px 0">
                <span><strong>{{ 'HELP_DOCUMEN_URL' | translate}} </strong><a role="link" aria-label="button"
                    href="{{attendeLabDetails.LabInformationURL}}" target="_blank">{{"CLICK_HERE" |
                    translate}}</a></span>
              </div>
              <div *ngIf="attendeLabDetails.PreGuideUrl !=='' && attendeLabDetails.PreGuideUrl !== null"
                style="padding: 5px 0; padding-left: 15px;">
                <span>{{ 'INSTRUCTION_PREREQUISITES_FOR_LAB' | translate}} <a role="link" aria-label="button"
                    href="{{attendeLabDetails.PreGuideUrl}}" target="_blank">{{"CLICK_HERE" | translate}}</a></span>
              </div>
            </div>
          </div>
          <!-- <teams-button *ngIf="labDetail.AllowUserToDeleteODL === true && isLabDetailsPage"
            [classList]="'btn ts-btn-fluent-red delete-lab'" (buttonClick)="deallocateEnvironment()"><i
              class="fa fa-trash" aria-hidden="true"></i><span>{{'BUTTON_DELETE_ON_DEMAND_LAB' | translate |
              uppercase}}</span></teams-button>  -->
        </div>

        <!-- OCI Credentials -->
        <div class="guide-tabs" [class.showtab]="selectedTab === 'Oci'">
          <!--   OCI   -->
          <div class="row" *ngIf="labDetail.DoNotSendCloudCredentials !== true">
            <div class="col-md-12 pt-2 pb-4">
              <p id="instruction-oci-login" *ngIf="!attendeLabDetails?.CustomInstruction"
                [innerHTML]="'INSTRUCTION_OCI_LOGIN_RDP' | translate:{ociLink: ociLink}"></p>
              <p id="instruction-oci-login" *ngIf="attendeLabDetails?.CustomInstruction"
                [innerHTML]="attendeLabDetails.CustomInstruction"></p>
            </div>
            <div class="col-md-12">
              <table class="table" aria-label="OCI login credentials table" aria-describedby="instruction-oci-login">
                <thead>
                  <tr>
                    <th>{{ 'FIELDS_LABEL' | translate}}</th>
                    <th>{{ 'VALUE_LABEL' | translate}}</th>
                    <th>{{ 'ACTION_LABEL' | translate}}</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>{{ 'SIGN-IN_LINK' | translate}}</td>
                    <td><teams-input [ariaLabel]="ociLink"   [(ngModel)]="ociLink"
                        [value]="ociLink" [classList]="'form-control font-size-class'" [disabled]="true" ></teams-input></td>
                    <td><a role="button" ngxClipboard tooltip="{{'COPY' | translate}} sign-in link"
                        [cbContent]="ociLink" class="ml-4" href="javascript:void(0)"
                        attr.aria-label="{{'COPY' | translate}} sign-in link">
                        <i role="img" id="elementEnv" aria-hidden="true" aria-label="img" alt="" class="copyelmnt fa fa-clone"></i>
                      </a></td>
                  </tr>
                  <tr>
                    <td>{{ 'OCI_TENANT_NAME' | translate}}</td>
                    <td><teams-input [ariaLabel]="attendeLabDetails.TenantDomainName"  
                      [(ngModel)]="ociTenancyName" [value]="ociTenancyName" [classList]="'form-control font-size-class'"
                        [disabled]="true" ></teams-input></td>
                    <td><a role="button" ngxClipboard tooltip="{{'COPY' | translate}} {{'USERNAME' | translate}}"
                        [cbContent]="attendeLabDetails.TenantDomainName" class="ml-4" href="javascript:void(0)"
                        attr.aria-label="{{'COPY' | translate}} username">
                        <i role="img" id="elementEnv" aria-hidden="true" aria-label="img" alt="" class="copyelmnt fa fa-clone"></i>
                      </a></td>
                  </tr>
                  <tr>
                    <td>{{ 'OCI_DOMAIN_NAME' | translate}}</td>
                    <td><teams-input [ariaLabel]="attendeLabDetails.TenantDomainName"  
                        [(ngModel)]="attendeLabDetails.TenantDomainName" [value]="attendeLabDetails.TenantDomainName" [classList]="'form-control font-size-class'"
                        [disabled]="true" ></teams-input></td>
                    <td><a role="button" ngxClipboard tooltip="{{'COPY' | translate}} {{'USERNAME' | translate}}"
                        [cbContent]="attendeLabDetails.TenantDomainName" class="ml-4" href="javascript:void(0)"
                        attr.aria-label="{{'COPY' | translate}} username">
                        <i role="img" id="elementEnv" aria-hidden="true" aria-label="img" alt="" class="copyelmnt fa fa-clone"></i>
                      </a></td>
                  </tr>
                  <tr>
                    <td>{{ 'USERNAME' | translate}}</td>
                    <td><teams-input [ariaLabel]="attendeLabDetails.AADEmail"  
                        [(ngModel)]="attendeLabDetails.AADEmail" [value]="attendeLabDetails.AADEmail" [classList]="'form-control font-size-class'"
                        [disabled]="true" ></teams-input></td>
                    <td><a role="button" ngxClipboard tooltip="{{'COPY' | translate}} {{'USERNAME' | translate}}"
                        [cbContent]="attendeLabDetails.AADEmail" class="ml-4" href="javascript:void(0)"
                        attr.aria-label="{{'COPY' | translate}} username">
                        <i role="img" id="elementEnv" aria-hidden="true" aria-label="img" alt="" class="copyelmnt fa fa-clone"></i>
                      </a></td>
                  </tr>
                  <tr>
                    <td>{{ 'PASSWORD' | translate}}</td>
                    <td> <teams-input [ariaLabel]="attendeLabDetails.TempPassword"  
                        [(ngModel)]="attendeLabDetails.TempPassword" [value]="attendeLabDetails.TempPassword"
                        [classList]="'form-control font-size-class'" [disabled]="true" ></teams-input></td>
                    <td><a role="button" ngxClipboard tooltip="{{'COPY' | translate}} {{'PASSWORD_LABEL' | translate}}"
                        [cbContent]="attendeLabDetails.TempPassword" class="ml-4" href="javascript:void(0)"
                        attr.aria-label="{{'COPY' | translate}} password">
                        <i role="img" id="elementEnv" aria-hidden="true" aria-label="img" alt="" class="copyelmnt fa fa-clone"></i>
                      </a></td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          <div class="row" *ngFor="let rg of attendeLabDetails.DeploymentOutputValues">
            <span id="rg-oci-table" role="heading" aria-level="2" *ngIf="!(labDetail.DoNotSendCloudCredentials === true)" class="col-md-12"
                  style="padding-bottom:10px;"><strong>{{'OCI-COMPARTMENT' | translate}} :
                    {{rg.ResourceGroupName}}</strong></span>
            <div class="col-md-12">
              <table class="table" aria-label="Resource Group Table" tabindex="0">
                <thead>
                  <tr>
                    <th>{{ 'KEY_LABEL' | translate}}</th>
                    <th>{{ 'VALUE_LABEL' | translate}}</th>
                    <th>{{ 'ACTION_LABEL' | translate}}</th>
                  </tr>
                </thead>
                <tbody>
                  <ng-container *ngFor="let row of rg.OutputValues">
                    <tr *ngIf="row?.ExcludeOutputParameter === false">
                      <td>{{row.Name | pascalCase}}</td>
                      <td><teams-input [ariaLabel]="row.Value"  [(ngModel)]="row.Value" 
                          [value]="row.Value" [classList]="'form-control font-size-class'" [disabled]="true" ></teams-input></td>
                      <td><a role="button" ngxClipboard tooltip="Click to copy value of {{row.Name}}"
                          [attr.aria-label]="'Click to copy value of ' + row.Name" [cbContent]="row.Value" class="ml-4"
                          href="javascript:void(0)">
                          <i role="img" id="elementEnv" aria-hidden="true" aria-label="img" alt="" class="copyelmnt fa fa-clone"></i>
                        </a>
                      </td>
                    </tr>
                  </ng-container>
                </tbody>
              </table>
            </div>
          </div>
          <div *ngIf="labDetail.EnableAccessOverHttp && minGuide === false" class="row">
            <div class="col-md-12 text-center">
              <div *ngFor="let vm of attendeLabDetails.VMLaunchURL; let i = index;"
                style="padding:10px;display:inline-block;">
                <teams-button [classList]="'btn button-height-1 ts-btn ts-btn-fluent ts-btn-fluent-primary ts-btn-width-default btn-sm'" aria-label="Launch url" role="button" [titleText]="'LAUNCH_VM_IN_BROWSER' | translate"
                  appDebounceClick (debounceClick)="openVMandGitDoc(vm)" *ngIf="vm.LaunchURL !== null"
                  style="width:200px;"><i alt="" role="img" aria-label="img" aria-hidden="true"
                    class="fa fa-arrow-right"></i> {{ 'GO_TO' | translate}} {{vm.VMName}}
                </teams-button>
              </div>
            </div>
          </div>
          <div class="row pb-4 lab_Urls">
            <div class="col-md-12">
              <div *ngIf="attendeLabDetails.LabGuidUrl !== null && attendeLabDetails.LabGuidUrl !== '' && type === null"
                style="padding: 5px 0">
                <span><strong>{{ 'LAB_GUIDE' | translate }} </strong><a role="link" aria-label="Labguide url"
                    href="{{attendeLabDetails.LabGuidUrl}}" target="_blank">{{"CLICK_HERE" | translate}}</a></span>
              </div>
              <div *ngIf="attendeLabDetails.LabInformationURL !=='' && attendeLabDetails.LabInformationURL !== null"
                class="" style="padding: 5px 0">
                <span><strong>{{ 'HELP_DOCUMEN_URL' | translate}} </strong><a role="link" aria-label="button"
                    href="{{attendeLabDetails.LabInformationURL}}" target="_blank">{{"CLICK_HERE" |
                    translate}}</a></span>
              </div>
              <div *ngIf="attendeLabDetails.PreGuideUrl !=='' && attendeLabDetails.PreGuideUrl !== null"
                style="padding: 5px 0; padding-left: 15px;">
                <span>{{ 'INSTRUCTION_PREREQUISITES_FOR_LAB' | translate}} <a role="link" aria-label="button"
                    href="{{attendeLabDetails.PreGuideUrl}}" target="_blank">{{"CLICK_HERE" | translate}}</a></span>
              </div>
            </div>
          </div>
          <!-- <teams-button *ngIf="labDetail.AllowUserToDeleteODL === true && isLabDetailsPage && labDetail.UserLabExperienceTypes.length > 0 && labDetail.UserLabExperienceTypes[0].Id !== 2"
            [classList]="'btn btn-sm ts-btn-fluent-red delete-lab'" (click)="deallocateEnvironment()"><i
              class="fa fa-trash" aria-hidden="true"></i><span>{{'BUTTON_DELETE_ON_DEMAND_LAB' | translate |
              uppercase}}</span></teams-button> -->
        </div>
        <div class="guide-tabs" [class.showtab]="selectedTab === 'Licenses' && attendeLabDetails.Licenses.length > 0">
          <div class="row">
            <div class="col-md-12">
              <table class="table" aria-label="Licenses table" tabindex="0">
                <thead>
                  <tr>
                    <th>{{ 'KEY_LABEL' | translate}}</th>
                    <th>{{ 'VALUE_LABEL' | translate}}</th>
                    <th>{{ 'ACTION_LABEL' | translate}}</th>
                  </tr>
                </thead>
                <tbody>
                  <tr *ngFor="let row of attendeLabDetails.Licenses">
                    <td>{{row.LicenseTypeName}}</td>
                    <td><teams-input [ariaLabel]="row.AzurePasses"  [(ngModel)]="row.AzurePasses"
                         [value]="row.AzurePasses" [classList]="'form-control font-size-class'" [disabled]="true" ></teams-input></td>
                    <td><a role="button" ngxClipboard
                        [tooltip]="'CLICK_TO_COPY_FIELD' | translate :{field: row.LicenseTypeName}"
                        [attr.aria-label]="'Click to copy value of ' + row.LicenseTypeName"
                        [cbContent]="row.AzurePasses" class="ml-4" href="javascript:void(0)">
                        <i role="img" id="elementEnv" aria-hidden="true" aria-label="img" alt="" class="copyelmnt fa fa-clone"></i>
                      </a></td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
        <div class="guide-tabs"
          [class.showtab]="selectedTab === 'Resource' && attendeLabDetails.DeploymentOutputValues?.length > 0">
          <div class="row" *ngFor="let rg of attendeLabDetails.DeploymentOutputValues">
            <ng-container *ngIf="rg?.OutputValues?.length > 0">
              <span class="col-md-12" style="padding-bottom:10px;"><strong>{{'RESOURCE_GROUP' | translate}} :
                  {{rg.ResourceGroupName}}</strong></span>
              <div class="col-md-12">
                <table class="table" aria-label="Resource Group table" tabindex="0">
                  <thead>
                    <tr>
                      <th>{{ 'FIELDS_LABEL' | translate}}</th>
                      <th>{{ 'VALUE_LABEL' | translate}}</th>
                      <th>{{ 'ACTION_LABEL' | translate}}</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr *ngFor="let row of rg.OutputValues">
                      <td>{{row.Name | pascalCase}}</td>
                      <td><teams-input [ariaLabel]="row.Value"  [(ngModel)]="row.Value" 
                          [value]="row.Value" [classList]="'form-control font-size-class'" [disabled]="true" ></teams-input></td>
                      <td><a role="button" ngxClipboard [attr.aria-label]="'Click to copy value of ' + row.Name"
                          [cbContent]="row.Value" class="ml-4" href="javascript:void(0)" title="{{'COPY' | translate}}">
                          <i role="img" id="elementEnv" aria-hidden="true" aria-label="img" alt="" class="copyelmnt fa fa-clone"></i>
                        </a></td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </ng-container>
          </div>
          <div *ngIf="labDetail.EnableAccessOverHttp && minGuide === false" class="row">
            <div class="col-md-12 text-center">
              <div *ngFor="let vm of attendeLabDetails.VMLaunchURL; let i = index;"
                style="padding:10px;display:inline-block;">
                <teams-button aria-label="Launch url" role="button" [titleText]="'LAUNCH_VM_IN_BROWSER' | translate"
                  appDebounceClick (debounceClick)="openVMandGitDoc(vm)" *ngIf="vm.LaunchURL !== null"
                  ><i alt="" role="img" aria-label="img" aria-hidden="true"
                    class="fa fa-arrow-right"></i> {{ 'GO_TO' | translate}} {{vm.VMName}} </teams-button>
              </div>
            </div>
          </div>
        </div>
        <div class="guide-tabs" [class.showtab]="selectedTab === 'Service'" id="service" aria-labelledby="service">
          <div class="portlet light bordered">
            <div class="row">
              <div class="col-md-12">
                <table class="table" aria-label="Service Principal Table" tabindex="0">
                  <thead>
                    <tr>
                      <th>{{ 'FIELDS_LABEL' | translate}}</th>
                      <th>{{ 'VALUE_LABEL' | translate}}</th>
                      <th>{{ 'ACTION_LABEL' | translate}}</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>{{ 'SUBSCRIPTION_ID' | translate}}</td>
                      <td><teams-input [ariaLabel]="attendeLabDetails.SubscriptionGuid" 
                          [(ngModel)]="attendeLabDetails.SubscriptionGuid" 
                          [value]="attendeLabDetails.SubscriptionGuid" [classList]="'form-control font-size-class'" [disabled]="true" ></teams-input></td>
                      <td><a role="button" tooltip="{{'COPY' | translate}} {{ 'SUBSCRIPTION_ID' | translate}}" ngxClipboard aria-label="Click to copy Subscription Id"
                          [cbContent]="attendeLabDetails.SubscriptionGuid" class="ml-4" href="javascript:void(0)" attr.aria-label="{{'COPY' | translate}}">
                          <i role="img" id="elementEnv" aria-hidden="true" aria-label="img" alt="" class="copyelmnt fa fa-clone"></i>
                        </a>
                      </td>
                    </tr>
                    <tr>
                      <td>{{ 'TENANT_ID' | translate}}</td>
                      <td><teams-input  [ariaLabel]="attendeLabDetails.TenantId"
                          [(ngModel)]="attendeLabDetails.TenantId"  [value]="attendeLabDetails.TenantId"
                          [classList]="'form-control font-size-class'" [disabled]="true" ></teams-input></td>
                      <td> <a role="button" ngxClipboard aria-label="Click to copy Tenant Id"
                          [cbContent]="attendeLabDetails.TenantId" class="ml-4" href="javascript:void(0)"
                          tooltip="{{'COPY' | translate}} {{ 'TENANT_ID' | translate}}" attr.aria-label="{{'COPY' | translate}}">
                          <i role="img" id="elementEnv" aria-hidden="true" aria-label="img" alt="" class="copyelmnt fa fa-clone"></i>
                        </a></td>
                    </tr>
                    <tr>
                      <td>{{ 'APPLICATION_ID' | translate}}</td>
                      <td><teams-input [ariaLabel]="attendeLabDetails.AADSPAppId" 
                          [(ngModel)]="attendeLabDetails.AADSPAppId" 
                          [value]="attendeLabDetails.AADSPAppId" [classList]="'form-control font-size-class'" [disabled]="true" ></teams-input></td>
                      <td><a role="button" ngxClipboard aria-label="Click to copy Application Id"
                          [cbContent]="attendeLabDetails.AADSPAppId" class="ml-4" href="javascript:void(0)"
                          tooltip="{{'COPY' | translate}} {{ 'APPLICATION_ID' | translate}}" attr.aria-label="{{'COPY' | translate}}">
                          <i role="img" id="elementEnv" aria-hidden="true" aria-label="img" alt="" class="copyelmnt fa fa-clone"></i>
                        </a></td>
                    </tr>
                    <tr>
                      <td>{{ 'SECRET_KEY' | translate}}</td>
                      <td> <teams-input  [ariaLabel]="attendeLabDetails.AADSPAppKey"
                          [(ngModel)]="attendeLabDetails.AADSPAppKey" 
                          [value]="attendeLabDetails.AADSPAppKey" [classList]="'form-control font-size-class'" [disabled]="true" ></teams-input></td>
                      <td>
                        <a role="button" ngxClipboard aria-label="Click to copy Secret Key"
                          [cbContent]="attendeLabDetails.AADSPAppKey" class="ml-4" href="javascript:void(0)"
                          tooltip="{{'COPY' | translate}} {{ 'SECRET_KEY' | translate}}" attr.aria-label="{{'COPY' | translate}}">
                          <i role="img" id="elementEnv" aria-hidden="true" aria-label="img" alt="" class="copyelmnt fa fa-clone"></i>
                        </a>
                      </td>
                    </tr>
                    <tr>
                      <td>{{ 'DISPLAY_NAME' | translate}}</td>
                      <td> <teams-input [ariaLabel]="attendeLabDetails.AADSPDisplayName" 
                          [(ngModel)]="attendeLabDetails.AADSPDisplayName" 
                          [value]="attendeLabDetails.AADSPDisplayName" [classList]="'form-control font-size-class'" [disabled]="true" ></teams-input></td>
                      <td><a role="button" ngxClipboard aria-label="Click to copy Display Name"
                          [cbContent]="attendeLabDetails.AADSPDisplayName" class="ml-4" href="javascript:void(0)"
                          tooltip="{{'COPY' | translate}} {{ 'DISPLAY_NAME' | translate}}" attr.aria-label="{{'COPY' | translate}}">
                          <i role="img" id="elementEnv" aria-hidden="true" aria-label="img" alt="" class="copyelmnt fa fa-clone"></i>
                        </a></td>
                    </tr>
                    <tr *ngIf="attendeLabDetails?.AADSPNAppIdURI">
                      <td>{{ 'APPLICATION_ID_URI' | translate}}</td>
                      <td> <teams-input [ariaLabel]="attendeLabDetails.AADSPNAppIdURI" 
                          [(ngModel)]="attendeLabDetails.AADSPNAppIdURI" 
                          [value]="attendeLabDetails.AADSPNAppIdURI" [classList]="'form-control font-size-class'" [disabled]="true" ></teams-input></td>
                      <td><a role="button" ngxClipboard aria-label="Click to copy Application ID URI"
                          [cbContent]="attendeLabDetails.AADSPNAppIdURI" class="ml-4" href="javascript:void(0)"
                          tooltip="{{'COPY' | translate}} {{ 'APPLICATION_ID_URI' | translate}}" attr.aria-label="{{'COPY' | translate}}">
                          <i role="img" id="elementEnv" aria-hidden="true" aria-label="img" alt="" class="copyelmnt fa fa-clone"></i>
                        </a></td>
                    </tr>
                    <tr>
                      <td>{{ 'TENANT_DOMAIN_NAME' | translate}}</td>
                      <td> <teams-input [ariaLabel]="attendeLabDetails.TenantDomainName" 
                          [(ngModel)]="attendeLabDetails.TenantDomainName" 
                          [value]="attendeLabDetails.TenantDomainName" [classList]="'form-control font-size-class'" [disabled]="true" ></teams-input></td>
                      <td> <a role="button" ngxClipboard aria-label="Click to Copy Tenant Domain Name"
                          [cbContent]="attendeLabDetails.TenantDomainName" class="ml-4" href="javascript:void(0)"
                          tooltip="{{'COPY' | translate}} {{ 'TENANT_DOMAIN_NAME' | translate}}" attr.aria-label="{{'COPY' | translate}}">
                          <i role="img" id="elementEnv" aria-hidden="true" aria-label="img" alt="" class="copyelmnt fa fa-clone"></i>
                        </a></td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
        <div class="guide-tabs" [class.showtab]="selectedTab === 'Access key details'" id="Access-key-details"
          role="tabpanel" aria-labelledby="resource-tab">
          <div class="portlet light bordered">
            <div class="row">
              <div class="col-md-12">
                <table class="table" aria-label="Access key details table">
                  <thead>
                    <tr>
                      <th>{{ 'FIELDS_LABEL' | translate}}</th>
                      <th>{{ 'VALUE_LABEL' | translate}}</th>
                      <th>{{ 'ACTION_LABEL' | translate}}</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>{{ 'ACCESS_KEY' | translate}}</td>
                      <td> <teams-input  [ariaLabel]="attendeLabDetails.AADSPAppId"
                          [(ngModel)]="attendeLabDetails.AADSPAppId" 
                          [value]="attendeLabDetails.AADSPAppId" [classList]="'form-control font-size-class'" [disabled]="true" ></teams-input></td>
                      <td>
                        <a role="button" ngxClipboard aria-label="Click to copy access key"
                          [cbContent]="attendeLabDetails.AADSPAppId" class="ml-4" href="javascript:void(0)"
                          title="{{'COPY' | translate}}">
                          <i role="img" id="elementEnv" aria-hidden="true" aria-label="img" alt="" class="copyelmnt fa fa-clone"></i>
                        </a>
                      </td>
                    </tr>
                    <tr>
                      <td>{{ 'SECRET_KEY' | translate}}</td>
                      <td> <teams-input  [ariaLabel]="attendeLabDetails.AADSPAppKey"
                          [(ngModel)]="attendeLabDetails.AADSPAppKey" 
                          [value]="attendeLabDetails.AADSPAppKey" [classList]="'form-control font-size-class'" [disabled]="true" ></teams-input></td>
                      <td>
                        <a role="button" ngxClipboard aria-label="Click to Copy Secret Key"
                          [cbContent]="attendeLabDetails.AADSPAppKey" class="ml-4" href="javascript:void(0)"
                          title="{{'COPY' | translate}}">
                          <i role="img" id="elementEnv" aria-hidden="true" aria-label="img" alt="" class="copyelmnt fa fa-clone"></i>
                        </a>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="modal" role="dialog" appTrapFocus appAutoFocusModal aria-labelledby="modal-title"
  aria-describedby="modal-desc" *ngIf="modelShow" [class.validation]="modelShow" id="validation">
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header">
        <h4 id="modal-title" class="modal-title">{{modalData.title | translate}}</h4>
      </div>
      <div class="modal-body" aria-label="modal description">
        <p id="modal-desc">
          {{modalData.description | translate}}
        </p>
      </div>
      <div class="modal-footer">
        <teams-button *ngIf="modalData.showCancel === true" [classList]="'btn ts-btn-fluent-red modal-close-button btn-danger'"
          (buttonClick)=" modelShow=false">{{'CANCEL' | translate}}
        </teams-button>
        <teams-button *ngIf="modalData.type !== 'DEALLOCATE_LAB'"
          [classList]="'btn ts-btn-fluent-primary modal-ok-button'" (buttonClick)="modelShow = false">{{'OK' |
          translate}}
        </teams-button>
        <teams-button *ngIf="modalData.type === 'DEALLOCATE_LAB'"
          [classList]="'btn ts-btn-fluent-primary modal-ok-button'" (buttonClick)="confirmDeallocateEnvironment()">
          {{'OK' |
          translate}}</teams-button>
      </div>
    </div>
  </div>
</div>