import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { CommonService } from '../services/common-service.service';
import { Subscription } from 'rxjs';


@Injectable({
  providedIn: 'root',
})
export class PTCommaonHelperService {
  private subscriptions: Subscription[] = [];
  constructor(private router: Router, private commonService: CommonService) {}

  /**
   * @description: Call Create Instance api for free Exam
   */
  public callCreateInstance(examUniqueName, model): Promise<any> {
    return new Promise((resolve, reject) => {
      this.subscriptions[this.subscriptions.length] = this.commonService
        .createExamInstance(examUniqueName, model)
        .subscribe(
          (res) => {
            const response = {
              result: res,
              success: true,
            };
            resolve(response);
          },
          (error) => {
            const response = {
              error: error,
              success: false,
            };
            resolve(response);
          }
        );
    });
  }

  public getExamByExamInstance(examInstanceId): Promise<any> {
    return new Promise((resolve, reject) => {
      this.subscriptions[this.subscriptions.length] = this.commonService
        .getExamByExamInstance(examInstanceId)
        .subscribe(
          (res) => {
            const response = {
              result: res,
              success: true,
            };
            resolve(response);
          },
          (error) => {
            const response = {
              error: error,
              success: false,
            };
            resolve(response);
          }
        );
    });
  }

  public getExamForExternalUser(examId): Promise<any> {
    return new Promise((resolve, reject) => {
      this.subscriptions[this.subscriptions.length] = this.commonService
        .getExamForExternalUser(examId)
        .subscribe(
          (res) => {
            const response = {
              result: res,
              success: true,
            };
            resolve(response);
          },
          (error) => {
            const response = {
              error: error,
              success: false,
            };
            resolve(response);
          }
        );
    });
  }

  public getExam(isMobile): Promise<any> {
    return new Promise((resolve, reject) => {
      this.subscriptions[this.subscriptions.length] = this.commonService
        .getExam(isMobile)
        .subscribe(
          (res) => {
            const response = {
              result: res,
              success: true,
            };
            resolve(response);
          },
          (error) => {
            const response = {
              error: error,
              success: false,
            };
            resolve(response);
          }
        );
    });
  }

  public getExamById(examId): Promise<any> {
    return new Promise((resolve, reject) => {
      this.subscriptions[this.subscriptions.length] = this.commonService
        .getExamById(examId)
        .subscribe(
          (res) => {
            const response = {
              result: res,
              success: true,
            };
            resolve(response);
          },
          (error) => {
            const response = {
              error: error,
              success: false,
            };
            resolve(response);
          }
        );
    });
  }
}
