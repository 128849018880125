import { Component, Input, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';

import { EventEmitterService } from '@teams-auth';
import { GithubDataService, CommonService, unsubscribeCollection } from "@utility";
import { MarkdownService } from 'ngx-markdown';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'app-casestudy-overview',
  templateUrl: './casestudy-overview.component.html',
})
export class CasestudyOverviewComponent implements OnDestroy {
  public readMeData: any;
  private subscriptions: Subscription[] = [];
  @Input() public index: number;
  @Input() public tabsTitle: string;
  @Input() public tabsContent: any;
  @Input() public active: boolean;
  constructor(
    private readonly githubDataService: GithubDataService,
    private commonService: CommonService,
    private eventEmitter: EventEmitterService,
    public markdownService: MarkdownService
  ) {}

  /**
   * @description Lifecycle method to call when component initializes
   */
  /**
   * @description: Call when change data
   */
  ngOnChanges(): void {
    this.getHelpReadMeFromGit();
  }
  /**
   * @desription To fetch README.md file from github and append to component view
   */
  public getHelpReadMeFromGit(): void {
    this.readMeData = null;
    if (this.tabsContent !== null && this.tabsContent !== undefined) {
      this.subscriptions[this.subscriptions.length] = this.githubDataService
        .getGitHubData(this.tabsContent)
        .subscribe(
          (data) => {
            this.readMeData = data;
            this.commonService.getImageTag(this.tabsContent,this.markdownService);
            this.commonService.getLinks(this.markdownService);
          },
          (error) => {
            const code = 'Error - 30040';
            this.eventEmitter.debugAlert(code, error.error);
          }
        );
    }
  }
  /**
   * @description Component lifecycle method, gets called when component destroys
   */
  ngOnDestroy(): any {
    unsubscribeCollection(this.subscriptions);
  }
}
