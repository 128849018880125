import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ActivatedRoute } from '@angular/router';

import { API } from '../consts/api.const';
import { APIProd } from '../consts/api-prod.const';

import { BaseServiceService } from '../services/base-service.service';

import { apiUrl } from '@utility';
import { UserSessionService } from '@teams-auth';

@Injectable({
  providedIn: 'root',
})
export class PerformanceQuizService {
  private regUniqueName = '';
  private API = this.userSessionService.isProdUser ? APIProd : API;
  constructor(
    private baseService: BaseServiceService,
    private route: ActivatedRoute,
    public userSessionService: UserSessionService
  ) {
    this.route.queryParams.subscribe((params) => {
      this.regUniqueName = params.regUniqueName;
    });
  }

  public getLabDetails(sectionId): Observable<any> {
    let labDetailUrl = `${this.API.testData.getLabDetails}`.replace(
      '{section_GUID}',
      sectionId
    );
    labDetailUrl = apiUrl(this.regUniqueName, labDetailUrl);
    return this.baseService.get(labDetailUrl);
  }
}
