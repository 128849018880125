<div class="sec-2 spacing-sec-2" *ngIf="questions !== undefined">
    <div *ngIf="questions.questionType === 'Single Choice'">
        <div></div>
        <single-choice-result class="full-width" [question]="questions" [index]="index + 1">
        </single-choice-result>
    </div>
    <div *ngIf="questions.questionType === 'Multiple Choice'">
        <multiple-choice-result class="full-width" [question]="questions" [index]="index + 1">
        </multiple-choice-result>
    </div>
    <div class="select-sec" *ngIf="questions.questionType === 'Dropdown'">
        <drop-down-result class="full-width" [question]="questions" [index]="index + 1">
        </drop-down-result>
    </div>
    <div *ngIf="questions.questionType === 'Build Tree'">
        <build-tree-result class="full-width" [question]="questions" [index]="index + 1">
        </build-tree-result>
    </div>

    <div *ngIf="questions.questionType === 'Multiple Single Choice'">
        <multiple-single-choice-result class="full-width" [question]="questions" [index]="index + 1">
        </multiple-single-choice-result>
    </div>
    <div *ngIf="questions.questionType === 'Build List'">
        <build-list-result class="full-width" [question]="questions" [index]="index + 1">
        </build-list-result>
    </div>
    <div *ngIf="questions.questionType === 'Match Options'">
        <match-options-result class="full-width" [question]="questions" [index]="index + 1">
        </match-options-result>
    </div>
    <div *ngIf="questions.questionType === 'NumericInput'">
        <numeric-input-result class="full-width" [question]="questions" [index]="index + 1">
        </numeric-input-result>
    </div>
    <div *ngIf="questions.questionType === 'PerformanceTask'">
        <cloudlabs-performanc-task-result class="full-width" [question]="questions" [index]="index + 1"></cloudlabs-performanc-task-result>
    </div>
</div>