import { Component, EventEmitter, Input, OnChanges, Output } from '@angular/core';

import { QuestionHelperService } from '../../../services/question/question-helper.service';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'multiple-single-choice-question',
  templateUrl: './multiple-single-choice-question.component.html',
})
export class MultipleSingleChoiceQuestionComponent implements OnChanges {
  @Input() public question;
  @Input() userAnswer = [];
  @Input() versionKey: string
  private chooseOption: any = [];
  @Output() UserSelectAnswerOption = new EventEmitter();
  constructor(
    private questionHelper: QuestionHelperService
  ) { }

  /**
   * @description: Change Multiple Single Choice Question
   */
  ngOnChanges(): void {
    this.question?.options.forEach((element) => {
      element.checked = '';
    });
  }
  /**
   * @description: Save Multi-Single Choice
   */
  public onSingleChoiceSelect(event: any, key: any): void {
    if(this.question?.isVersioned) {
      this.question?.questionVersions.forEach((element) => {
        if(element.versionKey === this.versionKey) {
          element.userAnswers = this.questionHelper.onSingleChoiceSelect(this.question, this.chooseOption, event, key).questions.userAnswers;
          this.question = this.questionHelper.onSingleChoiceSelect(this.question, this.chooseOption, event, key).questions;
        }
      });
    } else {
      this.question = this.questionHelper.onSingleChoiceSelect(this.question, this.chooseOption, event, key).questions;
    }
    this.UserSelectAnswerOption.emit({ answer: this.question });
  }
  /**
   * @description: Load Selected User Answers
   */
  public selectUserAnswerOnLoad(key: any, value: any): boolean {
    if (this.question.userAnswers.length > 0) {
      const isAnswer = this.question.userAnswers.find(
        (i) => i.key === key && i.value === value
      );
      if (isAnswer) {
        return true;
      }
      return false;
    }
    return false
  }
}
