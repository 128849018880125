import { Pipe, PipeTransform } from '@angular/core';
import { detect } from 'detect-browser'

@Pipe({
  name: 'dialogRole'
})
export class DialogRolePipe implements PipeTransform {
  private browser = detect()

  transform(value: any):  any {
    if (this.browser.name === "edge" || this.browser.name==="edge-chromium"){
      return value + 'dialog'
    }
    else{
    return value;
  }
  }

}
