import { Component, Input, OnInit, OnDestroy, Output, EventEmitter } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';

import { miscellaneousConst, copyToClipboard, unsubscribeCollection } from '@utility';

import { PerformanceQuizService } from '../../services/performance-quiz.service';

import {
  VMModel,
  EventEmitterService,
  UserSessionService,
} from '@teams-auth';
// import { UserSessionService } from '@teams-auth';



@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'app-performance-quiz',
  templateUrl: './performance-quiz.component.html'
})
export class PerformanceQuizComponent implements OnInit, OnDestroy {
  private subscriptions: Subscription[] = [];
  public viewCredentials = false;
  public showCredentialsInformation = false;
  public showQuestionsDeatils = false;
  public progreessBar = true;
  public userName = '';
  public passWord = '';
  private labDetails = '';
  private incVal = 0;
  public outputParametersKeys = [];
  public outputParametersValues = [];
  private updatedTimerInterval: any;
  public serviceIsLoading = false;
  private regUniqueName = '';
  public performanceProgressChangeValue = 0;
  public runTimer = true;
  public isRetry = false;
  public azurePortalUrl;
  public selectedMenu = 'credIntruct';
  @Input() sectionType;
  public menuList = [
    { name: 'exam.section.performanceQuiz.credIntruct', id: 'credIntruct' },
    { name: 'common.question', id: 'question' },
    // { name: 'exam.section.performanceTask.allTask', id: 'alltask'}
];

  // @Input() getSectionId: string;
  @Output() progressChangeValueEmit = new EventEmitter();
  @Output() showQuestionEmit = new EventEmitter<boolean>();
  @Output() selectedIndexEmit = new EventEmitter();
  @Output() credGenrated = new EventEmitter();

  constructor(
    private performanceQuiz: PerformanceQuizService,
    private eventEmitter: EventEmitterService,
    private readonly userSessionService: UserSessionService,
    private route: ActivatedRoute,
    private readonly translateService: TranslateService,
  ) {
    this.route.queryParams.subscribe((params) => {
      this.regUniqueName = params.regUniqueName;
    });
    this.subscriptions[this.subscriptions.length] =
    this.userSessionService.sideNavigation$.subscribe((res) => {
      window.dispatchEvent(new Event('resize'));
    });
  }

  ngOnInit(): void {
    this.route.params.subscribe((i) => {
      this.serviceIsLoading = true;
    });
    clearInterval(this.updatedTimerInterval);
    this.azurePortalUrl = miscellaneousConst.azurePortalUrl;
    this.showCredentialsInfo();
    // this.progressTimerValue();
  }

  onCredGenerated(e: any) {
    this.credGenrated.emit(e);
  }
  /**
   * @description: Change Progress Time Value
   */
  private progressTimerValue(): void {
    if (this.incVal !== 90) {
      setTimeout(() => {
        this.incVal = ++this.incVal;
        this.progressTimerValue();
      }, 1660);
    }
    this.performanceProgressChangeValue = this.incVal;
  }

  public onMenuSelect(index: number): void {
    switch (this.menuList[index].id) {
      case 'credIntruct':
        this.showCredentialsInfo();
        break;
      case 'question':
        this.showQuestions();
        break;
    }
  }

  /**
   * @description: Show Credentials Information
   */
  public showCredentialsInfo(): void {
    this.showCredentialsInformation = true;
    this.showQuestionsDeatils = false;
    this.showQuestionEmit.emit(this.showQuestionsDeatils);
    // this.getLabDetails();
  }

  /**
   * @description: View Credentials Data
   */
  public viewCredentialsData(): void {
    this.viewCredentials = true;
  }
  /**
   * @description: Show Question Data
   */
  public showQuestions(): void {
    this.showQuestionsDeatils = true;
    this.showQuestionEmit.emit(this.showQuestionsDeatils);
    this.showCredentialsInformation = false;
  }
  /**
   * @description: Copy User Name  Element
   */
  public copyContent(copyElement): void {
    copyToClipboard(copyElement.value);
  }

  /**
   * @description Component lifecycle method, gets called when component destroys
   */
  ngOnDestroy(): void {
    clearInterval(this.updatedTimerInterval);
    unsubscribeCollection(this.subscriptions);
  }


}