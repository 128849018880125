<!-- <cloudlabs-header></cloudlabs-header> -->
<div class="survey-banner" [ngStyle]="{'background-image':'url('+ bannerImageURL +')'}">
    <h1>Microsoft Cloud Workshops</h1>
</div>
<div class="survey-form">
    <div class="banner-title">
        <h1>Microsoft Cloud Workshops</h1>
    </div>
    <div class="container ng-scope sv-form">
        <div class="row margin-bottom-40">
            <div class="col-md-12 col-sm-12">
                <div class="survey-page">
                    <form #surveyForm="ngForm" (ngSubmit)="onSubmit(surveyForm)">
                        <div class="row margin-bottom-30">
                            <div class="survey-head">
                                <h2 class="ng-binding">{{surveyDetails?.Name}}</h2>
                                <p class="ng-binding">{{surveyDetails?.Description}}</p>
                            </div>
                            <div class="col-md-12 col-sm-12 feedback-question ng-scope"
                                *ngFor="let question of surveyDetails?.Questions">
                                <q questiontype="rating" class="ng-isolate-scope">
                                    <h2 class="no-top-space ng-binding ng-scope">
                                        <label [for]="question.Question"><strong>{{question.Question}}</strong></label>
                                    </h2>
                                    <div class="star-rating" *ngIf="question?.Type=='rating'">
                                        <!-- <ng-container *ngFor="let op of reverseVal(question.Options)"> -->
                                        <!-- <input type="radio" [id]="op?.Id" [name]="question?.Id"
                                                ngModel [value]="op.Id" checked/>
                                            <label [for]="op.Id" [title]="op.Value">
                                              <i class="active fa fa-star" aria-hidden="true"></i> 
                                            </label> -->
                                        <!-- <teams-radio [name]="question?.Id" ngModel [value]="op.Id" [checked]="true" [labelClass]="'active fa fa-star'"></teams-radio> -->
                                        <!-- </ng-container>  -->
                                        <teams-star-rating class="star-rating rating" [totalStars]="question.Options">
                                        </teams-star-rating>
                                    </div>
                                    <div *ngIf="question?.Type=='radio'">
                                        <div class="md-radio radio-op ng-scope"
                                            *ngFor="let option of question?.Options">
                                            <!-- <input type="radio" [id]="option?.Id" [name]="question?.Id"
                                            ngModel
                                                class="md-radiobtn ng-pristine ng-untouched ng-valid ng-empty"
                                                ng-model="val" [value]="option?.Id">
                                            <label [for]="option?.Id" class="ng-binding">
                                                <span class="inc"></span>
                                                <span class="check"></span>
                                                <span class="box"></span>{{ option?.Description | slice:0:60 }}
                                            </label> -->
                                            <teams-radio ng-model="val" [value]="option?.Id"
                                                class="md-radiobtn ng-pristine ng-untouched ng-valid ng-empty"
                                                [labelClass]="'ng-binding'">
                                                <span class="inc"></span>
                                                <span class="check"></span>
                                                <span class="box"></span>{{ option?.Description | slice:0:60 }}
                                            </teams-radio>
                                        </div>
                                    </div>
                                    <div *ngIf="question?.Type === 'checkbox'">
                                        <div class="check-mark" *ngFor="let option of question?.Options">
                                            <!-- <input type="checkbox" [id]="option.Value"
                                             [value]="question?.Id" ngModel [name]="question?.Id"
                                                (change)="onItemChange(option.Id, $event.target.checked,question?.Id)">
                                            <label [for]="option.Value" >{{ option.Description | slice:0:60 }}</label> -->
                                            <teams-checkbox [id]="option.Value"
                                                (change)="onItemChange(option.Id, $event.target['checked'], question?.Id)"
                                                [label]="'option.Description' | slice:0:60 "></teams-checkbox>
                                        </div>
                                    </div>
                                    <div class="col-md-12 col-sm-12 p-0 textarea-sec ng-scope "
                                        *ngIf="question?.Type ==='textArea'">
                                        <span class="ng-binding ng-scope">{{question.textLen === undefined ? 0 :
                                            question.textLen}}/500</span>
                                        <textarea [name]="question?.Id" ngModel
                                            class="message ng-pristine ng-untouched ng-valid ng-scope ng-empty ng-valid-maxlength"
                                            rows="5" ng-model="message"
                                            (ngModelChange)="getQuestionData($event, question?.Id)" ng-trim="false"
                                            maxlength="500"></textarea>
                                    </div>
                                    <div class="col-md-12 col-sm-12 p-0" *ngIf="question?.Type === 'text'">
                                        <teams-input [classList]="'question-text'" [name]="question?.Id" ngModel
                                            [maxlength]="'100'"></teams-input>
                                    </div>
                                </q>
                            </div>
                            <div class="col-md-12 feedback-question">
                                <teams-button type="submit" [classList]="'btn ts-btn-fluent-primary blue'">
                                    {{ 'BUTTON_SUBMIT' | translate }}
                                </teams-button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>

    <cloudlabs-footer></cloudlabs-footer>