<div class="ngdialog ts-modal-dialog custom-width ts-confirm-modal-dialog">
    <div class="ngdialog-overlay"></div>
    <div class="ngdialog-content">
        <div class="ts-confirm-modal">
            <div class="analytics-panel">
                <div class="ts-modal-dialog-header">
                    <h5 class="app-font-family-base ts-modal-dialog-title" style="outline: 0px;">{{ 'actions.confirmation' | translate }}
                    </h5>
                    <i class="ms-Icon ms-Icon--ChromeClose" title="ChromeClose" aria-hidden="true" (click)="dialogeNo()"></i>
                </div>
                <div class="ts-modal-dialog-content">
                    <div class="ts-confirm-dialog-description">{{message}}</div>
                </div>
                <div class="ts-modal-dialog-footer">
                    <div class="ts-modal-dialog-footer-buttons">
                        <div class="ts-modal-button-div">
                            <button class="ts-btn ts-btn-fluent ts-btn-fluent-white  button-height-1 ts-btn-width-default" (click)="dialogeNo()">{{ 'actions.no' | translate }}</button>
                            <button class="ts-btn ts-btn-fluent-primary ts-btn-fluent button-height-1 ts-btn-width-default" (click)="dialogeYes()">{{ 'actions.yes' | translate }}</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>