import { Pipe, PipeTransform } from '@angular/core';
import * as moment from 'moment';
@Pipe({
  name: 'calculateDuration'
})
export class CalculateDurationPipe implements PipeTransform {

  transform(value: number): string {
    if (value) {
      const duration: moment.Duration = moment.duration(value, 'minutes');
      return `${duration.get('hours') > 0 ? duration.get('hours') + ' hours'  : ''} ${duration.get('minutes') + ' minutes'}`;
    }
    return '';
  }

}
