import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'removeHttp'
})
export class RemoveHttpPipe implements PipeTransform {

  transform(value: string, ...args: unknown[]): unknown {
    let newValue = value;
    if (value.startsWith('https://')) {
      newValue = value.replace('https://', '');
    } else if (value.startsWith('http://')) {
      newValue = value.replace('http://', '');
    }
    if (value.endsWith('/')) {
      newValue = newValue.slice(0, newValue.length - 1);
    }
    return newValue;
  }

}
