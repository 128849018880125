<div class="trial-details">


  <div class="row">
    <!--time-->
    <div class="col-xl-3 col-lg-5 col-sm-6 col-12 d-flex flex-start"
      *ngIf="vmUsage && vmUsage.RemainingUptime !== null">
      <img src="assets/images/clock_timer.svg" alt="clock_timer" class="logo-img">
      <div class="detail-column">
        <span>{{"DURATION" | translate}} {{"REMAINING" | translate | titlecase}} </span>
        <span class="body-text-heavy">
          {{hours | number:'2.0'}} {{ 'TRIAL_DURATION.HOURS' | translate:{singularPlural: hours | pluralize} | titlecase }},
          {{minutes | number:'2.0'}} {{ 'MINUTES' | translate:{singularPlural: minutes | pluralize} | titlecase }},
          {{seconds | number:'2.0'}} {{ 'TRIAL_DURATION.SECONDS' | translate:{singularPlural: seconds | pluralize} | titlecase }}
        </span>
      </div>
    </div>
    <!-- expiry -->
    <div class="col-xl-3 col-lg-4 col-sm-6 col-12 d-flex flex-start" *ngIf="labExpiryDuration">
      <img class="icon" src="assets/images/Calendar.png" alt="Calendar">
      <div class="detail-column">
        <span>{{"EXPIRY_ON" | translate}} </span>
        <span class="body-text-heavy" [innerHTML]="endDate">
        </span>
      </div>
    </div>

    <ng-container *ngIf="vmUsage?.RemainingUptime !== null">
      <!--status-->
      <div *ngIf="vmUsage?.RemainingUptime !== '00:00:00'" class="col-lg-2 col-sm-6 col-12 d-flex flex-start">
        <img class="icon" src="assets/images/Status.png" alt="Status">
        <div class="detail-column">
          <span>{{"STATUS" | translate}}</span>
          <span class="body-text-heavy">
            {{(isResourceStopped ? "PAUSED" : "RUNNING" )| translate}}</span>
        </div>
      </div>
    </ng-container>
  </div>

  <div class="env-note link-text-color mt-3" *ngIf="vmUsage?.RemainingUptime !== null">
    <i class="fas fa-exclamation-circle mx-2"></i>
    <span>
      {{"TRIAL_DELETE_NOTE"|translate}}
    </span>
  </div>

  <div class="env-note text-danger mt-3" *ngIf="expiryReached">
    <i class="fas fa-exclamation-circle mx-2"></i>
    <span [innerHTML]="'ENVIRONMENT_EXPIRY_NOTE' | translate: {supportEmail: supportEmail} ">
    </span>
  </div>
</div>