import { Component, Input, OnInit } from '@angular/core';

import { ResultHelperService } from '../../../services/result-helper.service';

@Component({
  selector: 'multiple-single-choice-result',
  templateUrl: './multiple-single-choice-result.component.html'
})
export class MultipleSingleChoiceResultComponent implements OnInit {
  @Input() public question;
  @Input() public index;
  constructor(
    private resultHelper: ResultHelperService
  ) { }

  ngOnInit(): void {
    this.onLoadData();
  }
  /**
   * @description: Load Data First Time
   */
  private onLoadData(): any {
    this.question = this.resultHelper.onMultiSingleChoiceLoadData(this.question).questions;
  }
}
