<div class="w-100">
    <ul class="singleresult-list singlequestion-list-result">
        <li>
            <div class="ans-question-box">
                <strong class="sql-number">{{index}}. </strong><strong class="sql-ques"
                    [innerHTML]="question?.question | targetBlank"></strong>
            </div>
        </li>
    </ul>
    {{question.userScore}}/{{question.questionScore}} {{ 'exam.result.points' | translate }}
</div>