import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { ErrorComponent } from './error/error.component';
import { LabguideComponent } from '@libs/common-lab/src/lib/labenvironment/components/labguide/labguide.component';
import { EnvironmentResolver } from '@libs/common-lab/src/resolver/environment.resolver';
import { LabResolver } from '@libs/common-lab/src/resolver/lab-resolver';

const routes: Routes = [
  {
    path: 'baselineevent/:uniqueIdentifier/:voucherCode',
    loadChildren: () =>
      import('./lab-cluster/lab-cluster.module').then(
        (m) => m.LabClusterModule
      ),
  },
  {
    path: 'baselineevent/:uniqueIdentifier',
    loadChildren: () => import('./auth/auth.module').then((m) => m.AuthModule),
  },
  {
    path: 'odl/:id',
    loadChildren: () => import('./auth/auth.module').then((m) => m.AuthModule),
  },
  {
    path: 'odl/:id/private_subscription',
    loadChildren: () =>
      import('./subscriptions/subscriptions.module').then(
        (m) => m.SubscriptionsModule
      ),
  },
  // {
  //   path: 'odl/:id/:voucherCode',
  //   loadChildren: () => import('./labs/labs.module').then((m) => m.LabsModule),
  // },
  {
    path: 'odl/:id/:voucherCode',
    loadChildren: () =>
      import('@libs/common-lab/src/lib/labs/labs.module').then(
        (m) => m.LabsModule
      ),
  },
  // {
  //   path: 'odl/environment/:id/:voucherCode/:vmurl',
  //   loadChildren: () =>
  //     import('./labenvironment/labenvironment.module').then(
  //       (m) => m.LabenvironmentModule
  //     ),
  // },
  {
    path: 'odl/environment/:id/:voucherCode/:vmurl',
    loadChildren: () =>
      import(
        '@libs/common-lab/src/lib/labenvironment/labenvironment.module'
      ).then((m) => m.LabenvironmentModule),
  },
  {
    path: 'odl/environment/:id/:voucherCode',
    loadChildren: () =>
      import(
        '@libs/common-lab/src/lib/labenvironment/labenvironment.module'
      ).then((m) => m.LabenvironmentModule),
  },
  {
    path: 'odl/labguide/:id/:voucherCode/:index',
    component: LabguideComponent,
    resolve: {
      lab: LabResolver,
      environment: EnvironmentResolver,
    },
  },
  {
    path: 'labguidepreview/:id',
    loadChildren: () =>
      import('./lab-guide-preview/lab-guide-preview.module').then(
        (m) => m.LabGuidePreviewModule
      ),
  },
  {
    path: 'labguideShortcut/:id',
    loadChildren: () =>
      import('./lab-guide-shortcut/lab-guide-shortcut.module').then(
        (m) => m.LabGuideShortcutModule
      ),
  },
  {
    path: 'home',
    loadChildren: () => import('./home/home.module').then((m) => m.HomeModule),
  },
  {
    path: 'survey',
    loadChildren: () => import('./survey-form/survey-form.module').then((m) => m.SurveyFormModule),
  },
  // { path: 'survey', component: SurveyFormComponent },
  { path: 'error', component: ErrorComponent },
  { path: '', redirectTo: '/home', pathMatch: 'full' },
  { path: '**', component: ErrorComponent },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      useHash: true,
      scrollPositionRestoration: 'enabled',
      relativeLinkResolution: 'legacy',
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
