import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'teams-text-area',
  templateUrl: './text-area.component.html',
  styleUrls: ['./text-area.component.scss'],
  // providers: [
  //   {
  //     provide: NG_VALUE_ACCESSOR,
  //     useExisting: forwardRef(() => TextAreaComponent),
  //     multi: true,
  //   },
  // ],
})
export class TextAreaComponent implements OnInit {
  @Input() value: string = '';
  @Input() id = '';
  @Input() name = '';
  @Input() isCorrectAnswer
  @Output() valueEmit = new EventEmitter();

  constructor() { }

  ngOnInit(): void {
    //
  }

  getValue() {
    this.valueEmit.emit(this.value)
  }

}
