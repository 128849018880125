<div class="outer-container">
  <div class="environment-heading">
    <span class="heading">
      <span>
        {{'ENVIRONMENT_DETAILS' | translate}}
      </span>
    </span>
    <span *ngIf="labDetail?.LabLaunchPageDescription !== ''" class="description"
      [innerHTML]="labDetail.LabLaunchPageDescription">
      <!-- {{'TRIAL_ENVIRONMENT_DESCRIPTION'|translate}} -->
    </span>
    <!-- <ul class="nav nav-pills" id="pills-tab" role="tablist">
            <li class="nav-pill" role="presentation">
                <a class="btn active ts-btn-fluent-primary" role="tab" data-toggle="pill"
                    href="#pills-environment">{{'ENVIRONMENT_DETAILS' | translate}}</a>
            </li>
            <li class="nav-pill" role="presentation">
                <a class="btn ts-btn-fluent-primary" role="tab" data-toggle="pill"
                    href="#pills-resources">{{'TAB_RESOURCES' | translate}}</a>
            </li>
        </ul> -->

    <!-- TODO Only showing this section for Yellowbrick for now -->

      <span class="right-content">
        <!-- <a *ngIf="videoUrl">
          <i class="fab fa-youtube pr-2"></i>
          <span translate="SIGNED_UP.INTRO_VIDEO"></span>
        </a>
        <span class="mx-2" *ngIf="videoUrl && docUrl">|</span>
        <a *ngIf="docUrl" href="{{docUrl}}" target="_blank">
          <span translate="DOCUMENTATION_GUIDE"></span>
          <i class="fas fa-external-link-alt link-text-color pl-2"></i>
        </a> -->
        <img class="icon calendar-width" src="assets/images/Calendar.png" alt="calender icon">
        <div class="ml-3 expiry-section">
          <span>{{"EXPIRY_ON" | translate}} </span>
          <span class="body-text-heavy" [innerHTML]="endDate">
          </span>
        </div>
      </span>

  </div>

  <div class="tab-content content-container" id="pills-tabContent">
    <!-- environment class="tab-pane fade show active" id="pills-environment" role="tabpanel" -->
    <div class="environment-table" *ngIf="dataArray.length>0">
      <div class="row mx-auto" *ngFor="let data of dataArray">
        <!-- <ng-template [ngIf]="!['SIGN-IN_LINK', 'USERNAME', 'PASSWORD' ].includes(data.key)"
                    [ngIfElse]="translated"> -->
        <span class="col-3">
          {{data.key | titlecase}}
        </span>
        <span class="col-8">
          <ng-container *ngIf="!isLink(data.value)">{{ data.value }}</ng-container>
          <a *ngIf="isLink(data.value)" href="{{ data.value }}" target="_blank">{{ data.value }}</a>
        </span>
        <span class="col-1 text-center">
          <a role="button" ngxClipboard [tooltip]="data.tooltipKey | translate :{field: data.key}"
            [attr.aria-label]="data.tooltipKey | translate:{ field: data.key}" [cbContent]="data.value" class=""
            href="javascript:void(0)">
            <i role="img" aria-hidden="true" aria-label="img" alt="" class="copyelmnt far fa-clone"></i>
          </a>
        </span>
        <!-- </ng-template> -->
        <!-- <ng-template #translated>
                    <span class="col-3">
                        {{data.key | translate}}
                    </span>
                    <span class="col-8">
                        {{data.value}}
                    </span>
                    <span class="col-1 text-center">
                        <a role="button" ngxClipboard
                            [tooltip]="data.tooltipKey | translate: {field: '{{data.key | translate}}'}"
                            [attr.aria-label]="data.tooltipKey | translate:{ field: data.key}" [cbContent]="data.value"
                            class="" href="javascript:void(0)">
                            <i role="img" aria-hidden="true" aria-label="img" alt="" class="copyelmnt far fa-clone"></i>
                        </a>
                    </span>
                </ng-template> -->
      </div>
    </div>
    <div *ngIf="dataArray.length===0">
      {{"NO_RECORDS_FOUND" | translate}}
    </div>
    <!-- resources -->
    <!-- <div class="tab-pane fade show" id="pills-resources" role="tabpanel">
            <cloudlabs-trial-resource [labDetail]="labDetail" [mode]="mode" [attendeLabDetails]="guideData"
                [voucherCode]="voucherCode"></cloudlabs-trial-resource>
        </div> -->
  </div>
</div>

  <div class="know-more mt-4" *ngIf="labAssetDetail?.length > 0">
    <h5 class="heading">
      Know more
    </h5>
    <div class="bottom-section row">
      <ng-container *ngFor="let data of labAssetDetail">
        <div class="guide-box box col-12 col-sm-5 col-lg-3 col-xl-2 shadow-sm" *ngIf="data.AssetName === 'Document'">
          <!-- <div class="shade_preview image_preview_container">
            <img src="assets/images/video-tile-background.svg" alt="video_tile" class="background-img">
          </div> -->
          <div class="">
            <img src="assets/images/documentation-icon.svg" alt="clock_timer"
            (click)="openDoc(data.AssetURL, data.TargetLocation)" class="icon-cursor documentation-icon mr-2">
            <span class="title">{{data.Name}}</span>
          </div>

          <div class="d-flex flex-column">

            <span class="description mt-3">
              {{ data?.AssetDescription }}
            </span>
            <a class="mt-auto position-absolute bottom-links" href="{{ data.AssetURL }}" [target]="data.TargetLocation">
              {{'GO_TO_DOCUMENTATION' | translate}}
            </a>
          </div>
        </div>

        <div class="video-box box col-12 col-sm-5 col-lg-3 col-xl-2" *ngIf="data.AssetName === 'Video'">
          <!-- <div class="image_preview_container">
            <img src="assets/images/video-tile-background.svg" alt="video_tile" class="background-img">
          </div> -->
          <div class="">
            <img src="assets/images/video-play-icon.svg" alt="clock_timer" (click)="watchVideo(data)"
            class="icon-cursor video-icon mr-2">
            <span class="title"> {{ data.Name }} </span>
          </div>
          <div class="d-flex flex-column">

            <span class="description mt-3">
              {{ data?.AssetDescription }}
            </span>
            <a class="mt-auto position-absolute bottom-links" (click)="watchVideo(data)">
              {{'WATCH_VIDEO' | translate}}
            </a>
          </div>
        </div>
      </ng-container>
    </div>
  </div>




<ng-container>
  <cloudlabs-dialoge-prompt [dialogClass]="dialogClass" [isDialog]="videoPopup"
    (popupResponse)="closeConfirmResponse($event)">
    <div header class="subheading" id="dialog-title">
      {{ videoPopupData?.Name }}
    </div>
    <div content class="col-md-12 video embed-responsive embed-responsive-16by9">
      <iframe allowFullScreen="allowFullScreen" title="course-video" class="embed-responsive-item" allow="autoplay"
        [src]="videoUrl | safe"></iframe>
    </div>
  </cloudlabs-dialoge-prompt>
</ng-container>
