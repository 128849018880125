import {
  Component,
  OnInit,
  Input,
  OnChanges,
  SimpleChanges,
  HostListener,
  ViewChild,
  Output,
  EventEmitter,
  Inject,
  ChangeDetectorRef,
  OnDestroy,
} from '@angular/core';
import * as moment from 'moment';
import {
  Lab,
  LabModes,
  OperationModes,
  LabAttendee,
  Deployment,
  UserLabType,
  CustomerType,
  ActionItems,
  AppType,
} from './../../../../modals/lab.model';
import { LabService } from './../../../../services/lab.service';
import { AprovalField } from './../../../../modals/form-field.model';
import { TranslateService } from '@ngx-translate/core';
import { Router, ActivatedRoute, NavigationStart } from '@angular/router';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { DurationDialogComponent } from '../../../shared/duration-dialog/duration-dialog.component';
import { ExtendDurationComponent } from './../../../shared/extend-duration/extend-duration.component';
import { CountdownComponent } from 'ngx-countdown';
import { EventService } from './../../../../services/event.services';
import { interval, Subject, Subscription } from 'rxjs';
import { startWith, takeUntil, takeWhile } from 'rxjs/operators';
import { EnvironmentDeleteDialogComponent } from '../../../shared/environment-delete-dialog/environment-delete-dialog.component';
import { MarkdownService } from 'ngx-markdown';
import {
  unsubscribeCollection,
  getTimeZone,
  miscellaneousConst,
  getEntity,
  getEntityData,
  videoUrlCheck,
  checkSingularPlural,
} from '@utility';
import { EventEmitterService } from '@teams-auth';
import { VmDialogComponent } from '../../../shared/vm-dialog/vm-dialog.component';
import { NotificationService } from '../../../../services/notification.service';
import { LabClusterService } from '../../../../services/lab-cluster.service';
import { EnvironmentDeallocateDialogComponent } from '../../../shared/environment-deallocate-dialog/environment-deallocate-dialog.component';
import {
  AppInsightEvents,
  PropertyName,
} from '@libs/teams-auth/src/lib/_consts/app-insight.const';
import { ExtendCreditComponent } from '../../../shared/extend-credit/extend-credit.component';

export const SCREEN_WIDTH = 1080;
@Component({
  selector: 'cloudlabs-lab',
  templateUrl: './lab.component.html',
  styleUrls: ['./lab.component.scss'],
  providers: [MarkdownService],
})
export class LabComponent implements OnInit, OnChanges, OnDestroy {
  @Input() lab: Lab;
  @Input() mode: string = LabModes.LAB;
  @Input() voucherCode: string;
  @Input() operationMode: string = OperationModes.GATHERINGINFO;
  @Output() menuHide = new EventEmitter();
  @Output() labStatus = new EventEmitter();
  @Output() extendLabAction = new EventEmitter();
  @Output() setCounter = new EventEmitter<number>();
  @Output() setEndDate = new EventEmitter<any>();
  @Output() labDeployedStatus = new EventEmitter<boolean>();
  public operationModes: any = OperationModes;
  public modalRef: BsModalRef;
  public modelRefDeallocate: BsModalRef;
  public guideType: string = null;
  public id: any;
  public labComplete = false;
  public labUserData: LabAttendee;
  public labUserStatus: LabAttendee;
  public loading: boolean;
  public guideData: Deployment;
  public VMLaunchURLs;
  public minimumRemainingTimeForLabExtension;
  public pollIntervalInMinutes = 1;
  public error: string;
  public isPolling = false;
  public polling: any;
  public stopTimer: boolean;
  public countDownTimer: any;
  public showDurationExtensionPopUp = false;
  public minutes: number;
  public hours: number;
  public day: number;
  public progressBar: any = 1;
  public remainingMint = false;
  public manageInterval: any;
  public showProgress = false;
  public enableduration = false;
  public canExtendFurther = true;
  public extendedDuration = 0;
  public initialDeployment: any;
  private subscriptions: Subscription[] = [];
  private labPolling;
  public showHours: any;
  public isMylearning = false;
  public AttendeeEndDate: boolean;
  public EndDate: any;
  public second: number;
  public timeZone: any;
  public remainingduration: any = {
    leftTime: 0,
    format: 'HH:mm:ss',
    notify: [3600],
  };
  public dropdownSettings: any = {};
  selectedAttributeID: any;
  labClustersLabData: any;
  extendedAttribute: any;
  selectedUserAttribute = [];
  launchButtonDisable = true;
  public isSharedTestDrive: boolean;
  public selectedRegion = '';
  environment;
  @ViewChild('cd', { static: false }) public countDownTime: CountdownComponent;
  private notifier = new Subject();
  isResourceStopped: boolean;
  vmUsage: any;
  vmType: number;
  isResourceUpdated: boolean;
  labExpiryDuration: any;
  videoUrl: any;
  public customerType = CustomerType;
  public workspaceURL = '';
  public approvalType = AprovalField;
  @Input() actionEvent: any;
  public isMobile = false;
  toggleFullScreenforCLPortal: boolean;
  public modelShow = false;
  public modalData: any;
  private templateValidation: any;
  public isLabValidation = false;
  private showValidationModel = false;
  private isComponentDestoyed = false;

  @HostListener('window:resize', ['$event'])
  onResize(event?) {
    this.isMobile = event.target.innerWidth <= SCREEN_WIDTH;
  }

  constructor(
    private LabSrv: LabService,
    private translateSrv: TranslateService,
    private route: Router,
    private modalService: BsModalService,
    private eventSrv: EventService,
    private aroute: ActivatedRoute,
    private cdr: ChangeDetectorRef,
    private router: Router,
    private eventEmitter: EventEmitterService,
    @Inject('environment')
    environment,
    private notificationService: NotificationService,
    private labClusterService: LabClusterService
  ) {
    this.environment = environment;
    this.id = this.aroute.snapshot.params.id;
    const context = localStorage.getItem('context');
    if (context && context === miscellaneousConst.cloudlab) {
      this.isMylearning = true;
    }
    this.subscriptions[this.subscriptions.length] =
      this.router.events.subscribe((event) => {
        if (event instanceof NavigationStart) {
          if (this.countDownTime && this.countDownTime.left) {
            this.onWindowClose('');
          }
        }
      });

    this.toggleFullScreenforCLPortal = false;
  }
  /**
   * onClose window
   * @param event
   */
  @HostListener('window:beforeunload', ['$event'])
  onWindowClose(event: any): void {
    localStorage.removeItem('startlab');
    const key = `lab_${this.lab.UniqueName}_${this.voucherCode}_timer`;
    const time = this.countDownTime?.left / 1000;
    localStorage.setItem(key, JSON.stringify(time < 120 ? 120 : time));
  }

  async ngOnInit() {
    this.isMobile = window.innerWidth <= SCREEN_WIDTH;
    this.minimumRemainingTimeForLabExtension =
      this.lab.AllowedExtendDurationByAttendee ||
      this.environment.extendingLabDurationInMinutes;
    this.subscriptions[this.subscriptions.length] =
      this.eventSrv.onExtendduration
        .pipe(takeUntil(this.notifier))
        .subscribe((res: any) => {
          this.extendedDuration += this.minimumRemainingTimeForLabExtension;
          sessionStorage.setItem(
            'extendedDuration',
            this.extendedDuration + ''
          );
          if (this.lab.MaxUserDurationExtension <= this.extendedDuration) {
            this.canExtendFurther = false;
            this.extendLabAction.emit(false);
          }
          this.subscriptions[this.subscriptions.length] =
            this.LabSrv.getLabEnvironment(this.id, this.voucherCode)
              .pipe(takeUntil(this.notifier))
              .subscribe((res: any) => {
                clearTimeout(this.countDownTimer);
                this.enableduration = true;
                setTimeout(() => {
                  this.findRemainingDuration(res.StartTime, res.Duration);
                }, 6000);
              });
        });

    // if(this.labStataicData?.ExtendedUserAttribute.length > 0){
    if (this.environment.appType === 'trial') {
      this.labClustersLabData = await this.labClusterService
        .getLabClusterDetails(this.aroute.snapshot.params.uniqueIdentifier)
        .toPromise();
      if (this.labClustersLabData.PartnerName === 'Yellowbrick') {
        if (
          this.labClustersLabData.ExtendedUserAttribute.length > 0 &&
          !this.labClustersLabData.ExtendedUserAttribute[0].ShowInLaunchLabPage
        ) {
          this.startLab();
        }
      } else {
        if (this.labClustersLabData?.ExtendedUserAttribute.length > 0) {
          this.extendedAttribute = JSON.parse(
            this.labClustersLabData?.ExtendedUserAttribute[0]?.AllowedValues
          );
          this.selectedAttributeID =
            this.labClustersLabData?.ExtendedUserAttribute[0]?.Id;
          if (this.extendedAttribute) {
            this.extendedAttribute.forEach((item) => {
              if (item.DefaultValue) {
                this.selectedUserAttribute.push(item.Name);
              }
            });
          }
        }
      }
    }
    if (this.lab?.DeploymentDuration <= 60) {
      this.remainingduration = {
        leftTime: 0,
        format: 'mm:ss',
        notify: [60],
      };
    }
    this.subscriptions[this.subscriptions.length] =
      this.eventSrv.onExtendDurationError
        .pipe(takeUntil(this.notifier))
        .subscribe((errorObject) => {
          this.canExtendFurther = false;
          if (
            errorObject?.error?.ErrorDetail.includes(
              'You can extend this lab only upto'
            )
          ) {
            this.showExtensionErrorPopUp();
          }
          this.extendLabAction.emit(false);
          this.updateLatestLabEnvironment();
        });
    this.subscriptions[this.subscriptions.length] =
      this.eventSrv.onExtendDurationCancel
        .pipe(takeUntil(this.notifier))
        .subscribe(() => {
          this.updateLatestLabEnvironment();
        });
    this.startLabAnomoyas();
    this.videoUrl = videoUrlCheck(this.lab.LinkToVideo, 'true', '1');
    const cancelduration = sessionStorage.getItem('cancelduration');
    if (cancelduration) {
      sessionStorage.removeItem('cancelduration');
    }
    const extendedDuration = sessionStorage.getItem('extendedDuration');
    if (extendedDuration) {
      sessionStorage.removeItem('extendedDuration');
    }
    if (this.lab?.DeploymentDuration !== null) {
      const key = `lab_${this.lab?.UniqueName}_${this.voucherCode}_timer`;
      const time = parseInt(localStorage.getItem(key)) || 0;
      this.timeCountdown(time);
    }
    if (
      this.mode === 'Anonymous' &&
      this.voucherCode &&
      this.voucherCode !== 'anonymous'
    ) {
      this.startLab();
    }
    if (
      this.mode === 'Anonymous' &&
      this.voucherCode &&
      this.voucherCode === 'anonymous' &&
      this.lab.ApprovalTypeId === AprovalField.AutoLaunch
    ) {
      this.startLab();
      // this.operationMode = OperationModes.GATHERINGINFO;
      // this.startProgress();
      // this.deployOnDemandLabForUser();
    }

    if (this.voucherCode !== 'anonymous') {
      try {
        this.labUserStatus = await this.LabSrv.getAttendeeLab(
          this.voucherCode
        ).toPromise();
      } catch (e) {
        const code = 'Error - 70012';
        this.eventEmitter.debugAlert(code, 'error getting lab');
      }
    }
    // const exDur = sessionStorage.getItem('extendedDuration');
    // if (exDur) {
    //   try {
    //     this.extendedDuration = Number(exDur);
    //     if (this.lab.MaxUserDurationExtension <= this.extendedDuration) {
    //       this.canExtendFurther = false;
    //     }
    //   } catch (e) {
    //     const code = 'Error - 70013';
    //     this.eventEmitter.debugAlert(code, 'Error Extending Lab duration');
    //   }
    // }
    if (!this.lab.EnableAzurePass) {
      this.isCloudLab();
    }
    this.checkLaunchButton();
    if (this.environment.appType === 'trial') {
      if (
        this.labClustersLabData.ExtendedUserAttribute.length === 0 &&
        this.lab?.Regions === null
      ) {
        this.operationMode = OperationModes.GATHERINGINFO;
        this.startLab();
        this.eventSrv.onCloseDialog.subscribe(() => {
          this.onDialogConfirmation();
        });
      }
    }
    this.createAttendeeActivityLog();
    if (
      this.lab.ApprovalTypeId ===
        AprovalField.ODLRegisterationActivationCodeRequiredAutoLaunch ||
      this.lab.ApprovalTypeId ===
        AprovalField.ODLRegisterationRequiredAutoLaunch
    ) {
      this.startLab();
      this.operationMode = OperationModes.INITIATION;
    }

    this.eventSrv.onDeallocateODL.subscribe((res) => {
      this.completeTheLab(this.initialDeployment.InternalId);
    });

    this.subscriptions[this.subscriptions.length] =
      this.eventSrv.actionClickEvent.subscribe((res) => {
        this.actionClickEvent(res);
      });

    this.subscriptions[this.subscriptions.length] =
      this.eventSrv.toggleHeader.subscribe(() => {
        this.toggleFullScreenforCLPortal = !this.toggleFullScreenforCLPortal;
      });
  }

  actionClickEvent(action) {
    if (action) {
      switch (action) {
        case ActionItems.DEALLOCATE: {
          this.checkValidation();
          break;
        }
        case ActionItems.RELOAD: {
          location.reload();
          break;
        }
        case ActionItems.EXTEND: {
          this.extendLabDuration();
          break;
        }
        case ActionItems.EXT_CREDIT: {
          this.extendCredit();
          break;
        }
      }
    }
  }

  private showExtensionErrorPopUp() {
    this.modalData = {
      title: 'EXT_NOT_ALLOWED',
      description: 'REACHOUT_TO_SUPPORT',
      type: 'EXTEND_LAB_ERROR',
      showCancel: false,
    };
    this.modelShow = true;
  }

  async checkValidation() {
    if (
      this.lab.EnableLabValidation === true &&
      !this.lab?.DisableValidationTab &&
      !this.lab?.IsSupportQuest
    ) {
      if (!this.showValidationModel) {
        this.showValidationModel = true;
        const data: any = await this.LabSrv.getValidationStatus(
          this.voucherCode
        ).toPromise();
        this.showValidationModel = false;
        this.templateValidation = data.length > 0 ? data[0] : null;
        this.isLabValidation =
          this.templateValidation?.TemplateValidationStatus?.length > 0
            ? this.templateValidation.TemplateValidationStatus.some(
                (item) => item.ConsolidatedStatus !== 'Succeeded'
              )
            : false;
        this.deallocateDialog();
      }
    } else {
      this.deallocateDialog();
    }
  }

  deallocateDialog() {
    this.modelRefDeallocate = this.modalService.show(
      EnvironmentDeallocateDialogComponent,
      {
        initialState: {
          title: this.translateSrv.instant('DANGER'),
          data: {
            description: this.translateSrv.instant('MESSAGE_DEALLOCATE'),
            successButtonText: this.translateSrv.instant('PROCEED_TO_DELETE'),
            failureButtonText: this.translateSrv.instant('CANCEL'),
            isLabValidation: this.isLabValidation,
            templateValidation: this.templateValidation,
            lab: this.lab,
            vCode: this.voucherCode,
          },
        },
        class: this.isLabValidation ? 'validation-model' : '',
      }
    );
  }

  private isCloudLab() {
    if (localStorage.getItem('context') === miscellaneousConst.cloudlab) {
      this.isMylearning = true;
      if (
        !this.isSharedTestDrive &&
        this.lab?.StatusId !== 9 &&
        this.lab?.StatusId !== 10 &&
        this.lab?.StatusId !== 11 &&
        this.lab?.Regions === null &&
        this.environment.appType !== 'trial'
      ) {
        this.operationMode = OperationModes.GATHERINGINFO;
        this.startLab();
      }
    }
  }
  checkLaunchButton() {
    if (
      this.lab?.Regions !== null &&
      this.labClustersLabData?.ExtendedUserAttribute.length > 0
    ) {
      if (
        ((!this.lab?.UserSelectedRegion ||
          this.lab?.UserSelectedRegion?.length === 0) &&
          this.lab?.Regions !== null) ||
        this.selectedUserAttribute.length === 0
      ) {
        this.launchButtonDisable = true;
      } else {
        this.launchButtonDisable = false;
      }
    } else if (
      this.lab?.Regions !== null &&
      (!this.lab?.UserSelectedRegion ||
        this.lab?.UserSelectedRegion?.length === 0) &&
      this.lab?.Regions !== null
    ) {
      this.launchButtonDisable = true;
    } else if (
      this.labClustersLabData?.ExtendedUserAttribute.length > 0 &&
      this.selectedUserAttribute.length === 0
    ) {
      this.launchButtonDisable = true;
    } else {
      this.launchButtonDisable = false;
    }
  }

  selectSource(data) {
    if (!this.selectedUserAttribute.includes(data.Name)) {
      this.selectedUserAttribute.push(data.Name);
    } else {
      this.selectedUserAttribute = this.selectedUserAttribute.filter(
        (item) => item !== data.Name
      );
    }
    this.checkLaunchButton();
  }

  startTrial() {
    if (this.selectedUserAttribute.length > 0) {
      const selectedAttribute = [];
      const clusterVoucher = this.aroute.snapshot.params.userVoucher;
      selectedAttribute.push({
        AttributeId: this.selectedAttributeID,
        AttributeValues: this.selectedUserAttribute.join(','),
      });
      this.LabSrv.saveExtendedUserAttribute(
        clusterVoucher,
        selectedAttribute
      ).subscribe((res: any) => {
        if (res.Status === 'Success') {
          this.startLab();
          this.eventSrv.onCloseDialog.subscribe(() => {
            this.onDialogConfirmation();
          });
        }
      });
    } else {
      this.startLab();
      this.eventSrv.onCloseDialog.subscribe(() => {
        this.onDialogConfirmation();
      });
    }
  }

  startP() {
    this.startProgress();
  }

  goBack() {
    if (getEntityData() == miscellaneousConst.navigation.learningPath) {
      const vcode = this.aroute.snapshot.params.clVcode;
      const id = this.aroute.snapshot.params.clid;
      this.router.navigate([
        getEntity() + `/` + getEntityData() + `/details/${id}/${vcode}`,
      ]);
    } else if (getEntityData() == miscellaneousConst.navigation.courses) {
      const vcode = this.aroute.snapshot.params.clVcode;
      const id = this.aroute.snapshot.params.clid;
      this.router.navigate([
        getEntity() + `/` + getEntityData() + `/details/${id}/${vcode}`,
      ]);
    } else {
      const vcode = this.aroute.snapshot.params.voucherCode;
      const id = this.aroute.snapshot.params.id;
      this.router.navigate([
        getEntity() + `/` + getEntityData() + `/details/${id}/${vcode}`,
      ]);
    }
  }

  async startLabAnomoyas() {
    const data = localStorage.getItem('startlab');
    if (data && this.lab.ApprovalTypeId === 1) {
      await this.getLabMode();
    }
  }

  timeCountdown(time) {
    if (time > 3600) {
      this.remainingduration = {
        leftTime: time,
        format: 'HH:mm:ss',
        notify: [3600],
      };
      this.showHours = true;
    } else if (time > 0 && time <= 3600) {
      this.remainingduration = {
        leftTime: time,
        format: 'mm:ss',
        notify: [60],
      };
      this.showHours = false;
    } else {
      this.remainingduration.leftTime = this.lab?.DeploymentDuration * 60;
      this.remainingduration.leftTime > 3600
        ? (this.showHours = true)
        : (this.showHours = false);
    }
  }
  /**
   * Start deployment duration
   */
  startProgress() {
    let data: any;
    const key = `lab_${this.lab.UniqueName}_${this.voucherCode}_timer`;
    const time = parseInt(localStorage.getItem(key)) || 0;
    if (time && time >= 120) {
      data = time;
    } else if (this.lab?.DeploymentDuration !== null) {
      data = this.lab?.DeploymentDuration * 60;
    } else {
      data = 120;
    }
    const calc = (data * 1000) / 100;
    if (this.labUserData?.InternalId) {
      const key = `lab_${this.lab.UniqueName}_${this.voucherCode}_${this.labUserData?.InternalId}`;
      if (localStorage.getItem(key)) {
        const value = parseInt(
          localStorage.getItem(
            `lab_${this.lab.UniqueName}_${this.voucherCode}_${this.labUserData.InternalId}`
          )
        );
        this.progressBar = value > 89 ? 89 : value;
      }
    }
    this.manageInterval = setInterval(() => this.manageProgress(), calc);
  }

  /**
   * Manage progress bar
   */
  manageProgress() {
    if (this.operationMode !== OperationModes.GATHERINGINFO) {
      if (this.progressBar === 89 || this.progressBar >= 100) {
        this.progressBar = 89;
        clearInterval(this.manageInterval);
      }
      if (this.countDownTime && this.countDownTime.left < 62000) {
        this.remainingMint = true;
        this.countDownTime.pause();
      }
      const duration = 1;
      this.progressBar += duration;
      if (this.labUserData?.InternalId) {
        localStorage.setItem(
          `lab_${this.lab.UniqueName}_${this.voucherCode}_${this.labUserData.InternalId}`,
          this.progressBar
        );
      }
    }
    this.cdr.detectChanges();
  }

  async ngOnChanges(changes: SimpleChanges) {
    if (
      changes.mode &&
      changes.mode.currentValue !== changes.mode.previousValue
    ) {
      if (changes.mode.currentValue === LabModes.LAB) {
        this.loading = true;
        await this.getLabMode();
        this.loading = false;
      }
    }
    if (this.selectedRegion !== '') {
      this.lab.UserSelectedRegion = this.lab.Regions?.find((data) => {
        return data.Id === +this.selectedRegion;
      });
    }
  }

  /**
   * get labs mode
   */
  async getLabMode() {
    if (this.voucherCode !== 'anonymous') {
      this.labUserData = await this.LabSrv.getAttendeeLab(
        this.voucherCode
      ).toPromise();
    }

    if (!this.labUserData && this.voucherCode !== 'anonymous') {
      this.route.navigate(['/']);
    } else if (
      this.labUserData.IsAzurePassActive &&
      this.labUserData.LicenseStatus &&
      !this.labUserData.IsDeploymentActive
    ) {
      this.grabAzurePassForUser();
    } else if (
      this.labUserData.IsAzurePassActive &&
      !this.labUserData.IsDeploymentActive
    ) {
      this.operationMode = OperationModes.GRABAZUREPASS;
    } else if (
      this.labUserData.ADSetupStatus &&
      this.labUserData.IsDeploymentActive
    ) {
      this.operationMode = OperationModes.GATHERINGINFO;
      this.startProgress();
      this.deployOnDemandLabForUser();
    } else if (
      !this.labUserData.IsDeploymentActive &&
      (this.labUserData.DeploymentStatus === 'Deleted' ||
        this.labUserData.DeploymentStatus === 'Deleting')
    ) {
      this.operationMode = OperationModes.DEPLOYED;
    } else if (
      !this.labUserData.ADSetupStatus &&
      this.labUserData.IsActive &&
      this.labUserData.UserStatus != 2 &&
      !this.labUserData.IsSharedTestDrive
    ) {
      // not yet init
      this.operationMode = OperationModes.UNINITIATED;
    } else if (
      this.labUserData.ADSetupStatus &&
      this.labUserData.IsActive &&
      this.labUserData.CurrentStatus === 'Failed'
    ) {
      this.operationMode = OperationModes.FAILED;
    } else if (
      this.labUserData.ADSetupStatus &&
      this.labUserData.IsActive &&
      this.labUserData.DeploymentStatus === null
    ) {
      this.operationMode = OperationModes.GATHERINGINFO;
      this.startLab();
      // this.startProgress();
      // this.deployOnDemandLabForUser();
    } else if (
      this.labUserData.IsActive &&
      this.labUserData.DeploymentStatus === null &&
      this.labUserData.IsSharedTestDrive
    ) {
      this.operationMode = OperationModes.GATHERINGINFO;
      // manage share lab polling
      this.startProgress();
      this.manageSharedLabPolling();
    } else {
      this.operationMode = OperationModes.EXPIRED;
    }
    this.labStatus.emit(this.operationMode);
  }

  /**
   * Start lab
   */
  async startLab(mode: string = OperationModes.GATHERINGINFO) {
    this.eventEmitter.logEventInAppInsight(
      AppInsightEvents.DEPLOYMENT_INITIATION,
      {
        [PropertyName.ENTITY_ID]: this.lab.UniqueName,
        [PropertyName.USER_ID]: this.voucherCode,
        [PropertyName.URL]: window.location.href,
      }
    );
    this.operationMode = mode;
    if (
      (this.lab.ApprovalTypeId === AprovalField.AnonymousUsers ||
        this.lab.ApprovalTypeId === AprovalField.AutoLaunch) &&
      this.lab?.StatusId === 5 &&
      this.voucherCode === 'anonymous'
    ) {
      this.startProgress();
      await this.deployOnDemandLabForUser();
      return;
    }
    try {
      this.labUserStatus = await this.LabSrv.getAttendeeLab(
        this.voucherCode
      ).toPromise();
      if (this.labUserStatus.UserStatus != 2) {
        const localTime = moment(new Date()).format('YYYY-MM-DD');
        const expiryDateToLocal = moment(this.lab.ExpiryDate).format(
          'YYYY-MM-DD'
        );
        if (localTime <= expiryDateToLocal) {
          const response = await this.LabSrv.checkLabActivationClosed(
            this.lab.InternalId,
            this.voucherCode
          ).toPromise();
          if (response) {
            this.operationMode = OperationModes.ACTIVATIONCLOSED;
          } else {
            this.startProgress();
            this.deployOnDemandLabForUser();
          }
        } else {
          // this.toastr.error(this.translateSrv.instant('ERROR_ODL_EXPIRED'));
        }
      } else {
        this.operationMode = OperationModes.EXPIRED;
      }
    } catch (e) {
      const code = 'Error - 70014';
      this.eventEmitter.debugAlert(code, 'Error getting Lab details');
    }
    this.cdr.detectChanges();
  }

  /**
   * check OnDemand Lab deploy status For User
   */
  async deployOnDemandLabForUser() {
    try {
      this.initialDeployment = await this.LabSrv.initialDeployment(
        this.lab.InternalId,
        this.voucherCode === 'anonymous' ? null : this.voucherCode,
        this.lab.UserSelectedRegion
      ).toPromise();

      if (!this.isComponentDestoyed) {
        if (this.environment.appType === AppType.ATTENDEE) {
          this.labDeployedStatus.emit(
            Boolean(this.initialDeployment.DeploymentStatus === 'Succeeded')
          );
        }
        if (
          this.initialDeployment.Result !== null &&
          this.initialDeployment.Result.ErrorMessage === 'Lab already completed'
        ) {
          this.operationMode = OperationModes.DEPLOYED;
          this.eventEmitter.logEventInAppInsight(
            AppInsightEvents.DEPLOYMENT_FAILED,
            {
              [PropertyName.ENTITY_ID]: this.lab.UniqueName,
              [PropertyName.USER_ID]: this.voucherCode,
              [PropertyName.ERROR_DATA]: this.initialDeployment.Result,
              [PropertyName.URL]: window.location.href,
            }
          );
        } else {
          // this.operationMode = OperationModes.INITIATION;
          this.guideData = this.initialDeployment;
          if (this.voucherCode !== 'anonymous') {
            if (!this.labUserData) {
              this.labUserData = await this.LabSrv.getAttendeeLab(
                this.voucherCode
              ).toPromise();
            }
            this.labUserData.Status = this.initialDeployment;
            this.buildWorkspaceURL();
          }
          this.VMLaunchURLs = this.initialDeployment.VMLaunchURL;
          if (
            this.voucherCode === null ||
            this.voucherCode === undefined ||
            this.voucherCode === 'anonymous'
          ) {
            // anonymous user
            const userLink = `/#/odl/${this.lab.UniqueName}/${this.initialDeployment.VoucherCode}`;
            localStorage.setItem('startlab', 'true');
            this.eventEmitter.logEventInAppInsight(
              AppInsightEvents.DEPLOYMENT_SUCCESS,
              {
                [PropertyName.ENTITY_ID]: this.lab.UniqueName,
                [PropertyName.USER_ID]: this.voucherCode,
                [PropertyName.URL]: window.location.href,
              }
            );
            window.open(userLink, '_self');
            window.location.reload();
          } else {
            if (
              this.initialDeployment.DeploymentStatus === 'Succeeded' &&
              this.environment.appType === 'trial' &&
              (!this.vmUsage || this.vmUsage.UptimeLimit === null)
            ) {
              this.loadResources();
            }
            if (
              this.initialDeployment.DeploymentStatus === 'Succeeded' &&
              this.initialDeployment.Status === 12
            ) {
              this.AttendeeEndDate =
                this.initialDeployment.EnableAttendeeEndTime;
              if (
                this.initialDeployment.EndDate != null &&
                this.initialDeployment.EnableAttendeeEndTime
              ) {
                if (this.initialDeployment.EndDate.includes('Z')) {
                  this.initialDeployment.EndDate =
                    this.initialDeployment.EndDate.slice(0, -1);
                }
                const checkDate = new Date(this.initialDeployment.EndDate);
                this.EndDate = moment(
                  new Date(
                    checkDate.getTime() -
                      checkDate.getTimezoneOffset() * 60 * 1000
                  )
                ).format('YYYY-MM-DD hh:mm:ss A Z');
                this.timeZone = getTimeZone();
                this.setEndDate.emit({
                  endDate: this.EndDate,
                  timeZone: this.timeZone,
                });
              }
              this.operationMode = OperationModes.INPROGRESS;
              const startTime = this.initialDeployment.StartTime;
              //   isDeploying = false;
              this.stopPolling();
              if (!this.lab.BlockExpiration) {
                this.findRemainingDuration(
                  this.labUserData?.Status.StartTime,
                  this.labUserData?.Status.Duration
                );
              }

              if (
                this.minutes < 0 &&
                !this.lab.EnableAzurePass &&
                this.environment.appType !== 'trial'
              ) {
                this.operationMode = OperationModes.DEALLOCATED;
              } else if (
                this.lab.UserLabExperienceTypes.length > 0 &&
                this.lab.UserLabExperienceTypes[0].Id ===
                  UserLabType.Databricks_IFrame
              ) {
                this.eventEmitter.logEventInAppInsight(
                  AppInsightEvents.DEPLOYMENT_SUCCESS,
                  {
                    [PropertyName.ENTITY_ID]: this.lab.UniqueName,
                    [PropertyName.USER_ID]: this.voucherCode,
                    [PropertyName.URL]: window.location.href,
                  }
                );
                if (this.environment.appType === AppType.ATTENDEE) {
                  const vcode = this.aroute.snapshot.params.clVcode;
                  const clid = this.aroute.snapshot.params.clid;
                  const id = clid ? `/${clid}/${vcode}/` : '/';
                  this.route.navigate([
                    getEntity() +
                      `/` +
                      getEntityData() +
                      `${id}${this.lab.UniqueName}/${this.voucherCode}/labenvironment`,
                  ]);
                } else {
                  this.route.navigate([
                    `odl/environment/${this.lab.UniqueName}/${this.voucherCode}`,
                  ]);
                }
              } else if (
                this.VMLaunchURLs.length > 0
                // && this.labUserData?.Status.IsGitDocAvailable
              ) {
                localStorage.removeItem(
                  `lab_${this.lab.UniqueName}_${this.voucherCode}_${this.labUserData.InternalId}`
                );
                localStorage.removeItem(
                  `lab_${this.lab.UniqueName}_${this.voucherCode}_timer`
                );
                const index = this.VMLaunchURLs.findIndex(
                  (vm) => vm.IsDefaultLaunchVm
                );
                const internalId =
                  index !== -1
                    ? this.VMLaunchURLs[index].InternalId
                    : this.VMLaunchURLs[0].InternalId;

                if (
                  this.router.url.includes(
                    miscellaneousConst.navigation.virtualMachine
                  )
                ) {
                  this.eventEmitter.logEventInAppInsight(
                    AppInsightEvents.DEPLOYMENT_SUCCESS,
                    {
                      [PropertyName.ENTITY_ID]: this.lab.UniqueName,
                      [PropertyName.USER_ID]: this.voucherCode,
                      [PropertyName.URL]: window.location.href,
                    }
                  );
                  this.router.navigate([
                    `${miscellaneousConst.navigation.virtualMachine}/${this.lab.UniqueName}/${this.voucherCode}/labenvironment/${internalId}`,
                  ]);
                } else if (
                  this.router.url.includes(
                    miscellaneousConst.navigation.template
                  )
                ) {
                  this.eventEmitter.logEventInAppInsight(
                    AppInsightEvents.DEPLOYMENT_SUCCESS,
                    {
                      [PropertyName.ENTITY_ID]: this.lab.UniqueName,
                      [PropertyName.USER_ID]: this.voucherCode,
                      [PropertyName.URL]: window.location.href,
                    }
                  );
                  this.router.navigate([
                    `${miscellaneousConst.navigation.myLab}/${miscellaneousConst.navigation.labs}/${miscellaneousConst.navigation.template}/${this.lab.UniqueName}/${this.voucherCode}/labenvironment/${internalId}`,
                  ]);
                } else if (
                  localStorage.getItem('context') ===
                  miscellaneousConst.cloudlab
                ) {
                  this.eventEmitter.logEventInAppInsight(
                    AppInsightEvents.DEPLOYMENT_SUCCESS,
                    {
                      [PropertyName.ENTITY_ID]: this.lab.UniqueName,
                      [PropertyName.USER_ID]: this.voucherCode,
                      [PropertyName.URL]: window.location.href,
                    }
                  );
                  const vcode = this.aroute.snapshot.params.clVcode;
                  const clid = this.aroute.snapshot.params.clid;
                  const id = clid ? `/${clid}/${vcode}/` : '/';
                  this.route.navigate([
                    getEntity() +
                      `/` +
                      getEntityData() +
                      `${id}${this.lab.UniqueName}/${this.voucherCode}/labenvironment/${internalId}`,
                  ]);
                } else if (this.environment.appType === 'trial') {
                  this.eventEmitter.logEventInAppInsight(
                    AppInsightEvents.DEPLOYMENT_SUCCESS,
                    {
                      [PropertyName.ENTITY_ID]: this.lab.UniqueName,
                      [PropertyName.USER_ID]: this.voucherCode,
                      [PropertyName.URL]: window.location.href,
                    }
                  );
                  const uniqueIdentifier =
                    this.aroute.snapshot.params.uniqueIdentifier;
                  const userVoucher = this.aroute.snapshot.params.userVoucher;
                  this.route.navigate([
                    `trials/${uniqueIdentifier}/${userVoucher}/labs/${this.lab.UniqueName}/${this.voucherCode}`,
                  ]);
                } else {
                  this.eventEmitter.logEventInAppInsight(
                    AppInsightEvents.DEPLOYMENT_SUCCESS,
                    {
                      [PropertyName.ENTITY_ID]: this.lab.UniqueName,
                      [PropertyName.USER_ID]: this.voucherCode,
                      [PropertyName.URL]: window.location.href,
                    }
                  );
                  this.route.navigate([
                    `odl/environment/${this.lab.UniqueName}/${this.voucherCode}/${internalId}`,
                  ]);
                }
              } else if (
                this.labUserData.Status?.IsGitDocAvailable &&
                this.lab.ACIDeploymentType === null
              ) {
                localStorage.removeItem(
                  `lab_${this.lab.UniqueName}_${this.voucherCode}_${this.labUserData.InternalId}`
                );
                localStorage.removeItem(
                  `lab_${this.lab.UniqueName}_${this.voucherCode}_timer`
                );
                if (
                  localStorage.getItem('context') ===
                  miscellaneousConst.cloudlab
                ) {
                  this.eventEmitter.logEventInAppInsight(
                    AppInsightEvents.DEPLOYMENT_SUCCESS,
                    {
                      [PropertyName.ENTITY_ID]: this.lab.UniqueName,
                      [PropertyName.USER_ID]: this.voucherCode,
                      [PropertyName.URL]: window.location.href,
                    }
                  );
                  const vcode = this.aroute.snapshot.params.clVcode;
                  const clid = this.aroute.snapshot.params.clid;
                  const id = clid ? `/${clid}/${vcode}/` : '/';
                  this.route.navigate([
                    getEntity() +
                      `/` +
                      getEntityData() +
                      `${id}${this.lab.UniqueName}/${this.voucherCode}/1`,
                  ]);
                } else if (this.environment.appType === 'trial') {
                  this.eventEmitter.logEventInAppInsight(
                    AppInsightEvents.DEPLOYMENT_SUCCESS,
                    {
                      [PropertyName.ENTITY_ID]: this.lab.UniqueName,
                      [PropertyName.USER_ID]: this.voucherCode,
                      [PropertyName.URL]: window.location.href,
                    }
                  );
                  const uniqueIdentifier =
                    this.aroute.snapshot.params.uniqueIdentifier;
                  const userVoucher = this.aroute.snapshot.params.userVoucher;
                  this.route.navigate([
                    `trials/${uniqueIdentifier}/${userVoucher}/labs/${this.lab.UniqueName}/${this.voucherCode}`,
                  ]);
                } else {
                  this.eventEmitter.logEventInAppInsight(
                    AppInsightEvents.DEPLOYMENT_SUCCESS,
                    {
                      [PropertyName.ENTITY_ID]: this.lab.UniqueName,
                      [PropertyName.USER_ID]: this.voucherCode,
                      [PropertyName.URL]: window.location.href,
                    }
                  );
                  this.route.navigate([
                    `odl/labguide/${this.lab.UniqueName}/${this.voucherCode}/1`,
                  ]);
                }
              }
            } else if (
              this.initialDeployment.DeploymentStatus ===
              'Policy failed after deployment succeeded'
            ) {
              this.operationMode = OperationModes.POLICYFAILEDAFTERDEPLOYMENT;
              // isDeploying = false;
            } else if (
              this.initialDeployment.DeploymentStatus === 'Failed' ||
              this.initialDeployment.Status === 13
            ) {
              this.operationMode = OperationModes.FAILED;
              this.eventEmitter.logEventInAppInsight(
                AppInsightEvents.DEPLOYMENT_FAILED,
                {
                  [PropertyName.ENTITY_ID]: this.lab.UniqueName,
                  [PropertyName.USER_ID]: this.voucherCode,
                  [PropertyName.ERROR_DATA]: this.initialDeployment,
                  [PropertyName.URL]: window.location.href,
                }
              );
            } else if (this.initialDeployment.DeploymentStatus === 'Deleting') {
              this.operationMode = OperationModes.DEALLOCATING;
            } else if (this.initialDeployment.DeploymentStatus === 'Deleted') {
              this.operationMode = 'Deallocated';
            } else {
              this.startPolling();
              this.operationMode = OperationModes.INITIATION;
              // if (
              //   response.DeploymentStatus === null
              // ) {
              //   this.operationMode = OperationModes.GATHERINGINFO;
              // }
            }
          }
        }
        this.labStatus.emit(this.operationMode);
      }
    } catch (error) {
      this.error = error?.error?.ErrorMessage;
      this.operationMode = OperationModes.FAILED;
      const code = 'Error - 70015';
      this.eventEmitter.debugAlert(code, error?.error?.ErrorMessage);
      this.eventEmitter.logEventInAppInsight(
        AppInsightEvents.DEPLOYMENT_FAILED,
        {
          [PropertyName.ENTITY_ID]: this.lab.UniqueName,
          [PropertyName.USER_ID]: this.voucherCode,
          [PropertyName.ERROR_DATA]: error,
          [PropertyName.URL]: window.location.href,
        }
      );
    }
  }

  private buildWorkspaceURL(): void {
    if (
      (this.lab.UserLabExperienceTypes.length === 0 ||
        (this.lab.UserLabExperienceTypes.length === 1 &&
          this.lab.UserLabExperienceTypes[0].Id === 1)) &&
      (this.labUserData.Status.CloudPlatformId === 1 ||
        this.labUserData.Status.CloudPlatformId === 2) &&
      this.environment.customer === CustomerType.DATABRICKS
    ) {
      this.workspaceURL = `<a href="#/odl/environment/${this.id}/${
        this.voucherCode
      }" target="_blank">${this.translateSrv.instant(
        'CLICK_TO_AUTO_SIGN'
      )}<i class="fa fa-external-link ext-icon" aria-hidden="true"></i></a>`;
    }
  }

  /**
   * get remaining lab duration
   * @param startTime
   * @param actualDuration
   */
  findRemainingDuration(startTime, actualDuration) {
    const start = moment.utc(startTime, 'YYYY-MM-DD HH:mm:ss');
    const current = moment.utc(new Date(), 'HH:mm');
    const duration = moment.duration(current.diff(start));
    actualDuration = moment.duration(actualDuration, 'minutes');
    this.labExpiryDuration = actualDuration.subtract(duration);
    const remainingDuration = this.labExpiryDuration;
    this.setCounter.emit(parseInt(remainingDuration.asSeconds()));
    this.day = parseInt(remainingDuration.asDays());
    this.hours = remainingDuration.get('hours');
    if (this.day > 0) {
      const daysHours = this.day * 24;
      this.hours = daysHours + this.hours;
    }
    this.minutes = remainingDuration.get('minutes');
    this.second = remainingDuration.get('seconds');
    if (
      this.hours == 0 &&
      this.minutes == 0 &&
      this.environment.appType !== 'trial'
    ) {
      this.operationMode = 'Deallocated';
      this.labStatus.emit(this.operationMode);
    } else if (this.environment.appType !== 'trial') {
      this.countdown();
    }
  }

  /**
   * start countdown timer
   */
  async countdown() {
    if (this.countDownTimer) {
      clearTimeout(this.countDownTimer);
    }
    this.countDownTimer = setTimeout(async () => {
      const cancelduration = sessionStorage.getItem('cancelduration');
      this.minutes--;
      if (this.minutes < 0) {
        this.minutes = 59;
        this.hours--;
        if (this.hours === 0 && this.minutes < 0) {
          this.stopTimerFunc();
        }
      }
      if (this.hours == 0 && this.minutes == 0) {
        const isCompleted = await this.isLabCompleted();
        if (!isCompleted) {
          this.updateLatestLabEnvironment();
        } else {
          localStorage.removeItem('startlab');
          sessionStorage.removeItem('cancelduration');
          clearTimeout(this.countDownTimer);
          this.modelRefDeallocate.hide();
          this.operationMode = OperationModes.DEALLOCATED;
          if (!this.labComplete) {
            this.modalRef = this.modalService.show(DurationDialogComponent, {
              initialState: {
                title: this.translateSrv.instant('ON_DEMAND_LAB_NOTIFICATION'),
                data: {
                  description: this.translateSrv.instant(
                    'ERROR_TIMEOUT_DURATION'
                  ),
                  successButtonText: this.translateSrv.instant('OK'),
                },
              },
            });
          }
        }
      }
      if (this.hours == -1) {
        localStorage.removeItem('startlab');
        sessionStorage.removeItem('cancelduration');
        clearTimeout(this.countDownTimer);
        this.operationMode = OperationModes.DEALLOCATED;
      }
      // Auto Popup to extend the lab duration by attendee
      const canExtendLab =
        this.lab.AllowUserToExtendLab &&
        this.guideData.DeploymentStatus !== null &&
        this.canExtendFurther === true &&
        this.operationMode !== OperationModes.DEALLOCATED;
      const meetsMinimumTimeForExtension =
        this.hours === 0 &&
        this.minutes < this.minimumRemainingTimeForLabExtension;

      if (!this.lab.SkipExtendLimitValidation) {
        if (
          cancelduration === null &&
          meetsMinimumTimeForExtension &&
          canExtendLab
        ) {
          this.enableAndExtendLab(meetsMinimumTimeForExtension && cancelduration === null);
        }
      } else {
        if (canExtendLab) {
          this.enableAndExtendLab(meetsMinimumTimeForExtension && cancelduration === null);
        }
      }

      this.countdown();
      this.labStatus.emit(this.operationMode);
    }, 60000);

    if (
      this.initialDeployment?.AllowEnvironmentDeleteNotification === true &&
      this.hours === 0 &&
      this.minutes === 30 &&
      this.aroute.snapshot.params.voucherCode
    ) {
      this.modalRef = this.modalService.show(EnvironmentDeleteDialogComponent, {
        initialState: {
          title: this.translateSrv.instant('ALERT'),
          data: {
            description: this.translateSrv.instant(
              'LAB_DELETE_WARNING_MESSAGE'
            ),
            successButtonText: this.translateSrv.instant('OK'),
          },
        },
      });
    }
  }

  enableAndExtendLab(shouldExtendFurther) {
    this.enableduration = true;
    this.extendLabAction.emit(true);
    this.eventSrv.onCloaseDuration();
    if (shouldExtendFurther) {
      this.extendLabDuration();
    }
  }

  /**
   * stop countdown interval
   */
  stopTimerFunc() {
    // if (this.stopTimer) {
    clearTimeout(this.countDownTimer);
    // this.minutes = 0;
    // this.hours = 0;
    // this.operationMode = OperationModes.DEALLOCATED;
    // this.stopTimer = false;
    // }
  }

  /**
   * Start polling interval
   */
  startPolling() {
    if (!this.isPolling) {
      this.isPolling = true;
      this.polling = setInterval(() => {
        this.deployOnDemandLabForUser();
      }, this.pollIntervalInMinutes * 60000);
    }
  }

  createAttendeeActivityLog() {
    this.labPolling = setInterval(async () => {
      try {
        this.subscriptions[this.subscriptions.length] =
          this.LabSrv.getAttendeeLab(this.voucherCode).subscribe(
            (res) => {
              if (
                res.DeploymentStatus == 'Deleted' ||
                res.DeploymentStatus == 'Deleting'
              ) {
                this.labComplete = true;
                this.operationMode = 'Deallocated';
                this.labStatus.emit(this.operationMode);
              }
              if(this.day ===0 && this.hours === 0 && this.minutes <= 2){
                this.eventSrv.extendduration();
              }
            },
            (error) => {
              const code = 'Error - 50022';
              this.eventEmitter.debugAlert(code, error);
            }
          );
      } catch (e) {
        const code = 'Error - 70016';
        this.eventEmitter.debugAlert(code, 'error deleting lab');
      }
    }, 60000);
  }

  /**
   * Stop polling interval
   */
  stopPolling() {
    if (this.isPolling) {
      clearInterval(this.polling);
      this.isPolling = false;
    }
  }

  refresh() {
    window.location.reload();
  }

  /**
   * Extend lab duration by user
   */
  extendLabDuration() {
    this.modalRef = this.modalService.show(ExtendDurationComponent, {
      initialState: {
        title: this.translateSrv.instant('EXTEND_LAB_DURATION'),
        data: {
          description:
            this.translateSrv.instant('EXTEND_LAB_DURATION_DESCRIPTION') +
            ' ' +
            this.minimumRemainingTimeForLabExtension +
            ' ' +
            this.translateSrv.instant('MINUTES', {
              singularPlural: checkSingularPlural(
                this.minimumRemainingTimeForLabExtension
              ),
            }),
          successButtonText: this.translateSrv.instant('OK'),
          failureButtonText: this.translateSrv.instant('CANCEL'),
          v_code: this.voucherCode,
          duration: this.minimumRemainingTimeForLabExtension,
          approvalRequired:this.lab.DisableDurationAutoApprove,
          unit:
          this.minimumRemainingTimeForLabExtension +
          ' ' +
          this.translateSrv.instant('MINUTES', {
            singularPlural: checkSingularPlural(
              this.minimumRemainingTimeForLabExtension
            ),
          }),
          maxUnit:
          this.lab.MaxUserDurationExtension +
          ' ' +
          this.translateSrv.instant('MINUTES', {
            singularPlural: checkSingularPlural(
              this.lab.MaxUserDurationExtension
            ),
          }),
        },
      },
    });
  }

  grabAzurePass() {
    this.subscriptions[this.subscriptions.length] = this.LabSrv.onGrabAzurePass(
      this.lab.InternalId,
      this.voucherCode
    ).subscribe(
      (response: any) => {
        if (response.data) {
          this.operationMode = 'ActivationClosed';
        } else {
          this.grabAzurePassForUser();
        }
      },
      (error) => {
        const code = 'Error - 50023';
        this.eventEmitter.debugAlert(code, error);
      }
    );
  }

  grabAzurePassForUser() {
    this.operationMode = this.operationModes.AZUREPASSINITIATION;
    this.subscriptions[this.subscriptions.length] = this.LabSrv.grabAzurePass(
      this.lab.InternalId,
      this.voucherCode
    ).subscribe(
      (response: any) => {
        if (response.Result === null) {
          this.operationMode = this.operationModes.AZUREPASSUSERDETAILS;
          this.labUserData.Status = response;
          this.labUserData.Status.CloudPlatformId = 1;
          this.guideData = this.labUserData.Status;
        } else {
          this.error = response.Result.ErrorMessage;
          this.operationMode = this.operationModes.FAILED;
        }
      },
      (error) => {
        const code = 'Error - 50024';
        this.eventEmitter.debugAlert(code, error);
      }
    );
  }

  async completeTheLab(event: string) {
    // this.deployOnDemandLabForUser();
    this.operationMode = OperationModes.DEALLOCATING;
    try {
      const res = await this.LabSrv.completeTestDrive(event).toPromise();
      if (res === true) {
        this.labComplete = true;
        this.menuHide.emit(false);
        this.operationMode = OperationModes.DEALLOCATED;
      } else {
        this.operationMode = OperationModes.INPROGRESS;
      }
      this.labStatus.emit(this.operationMode);
    } catch (e) {
      this.operationMode = OperationModes.INPROGRESS;
      const code = 'Error - 70017';
      this.eventEmitter.debugAlert(code, 'Error Deallocating VM');
    }
  }

  regionSelected(event: Event) {
    const id = +(event.target as HTMLInputElement).value;
    this.lab.UserSelectedRegion = this.lab.Regions?.find((data) => {
      return data.Id === id;
    });
    this.checkLaunchButton();
  }

  handleCountDown(event) {
    if (event.action === 'notify') {
      if (event.text == '01:00:00') {
        this.timeCountdown(60 * 60 - 1);
      } else if (this.countDownTime) {
        this.countDownTime.pause();
        this.remainingMint = true;
      }
    }
  }
  updateLatestLabEnvironment() {
    this.subscriptions[this.subscriptions.length] =
      this.LabSrv.getLabEnvironment(this.id, this.voucherCode)
        .pipe(takeUntil(this.notifier))
        .subscribe((res: any) => {
          if (this.lab.Duration !== res.Duration) {
            clearTimeout(this.countDownTimer);
            this.enableduration = true;
            setTimeout(() => {
              this.findRemainingDuration(res.StartTime, res.Duration);
            }, 6000);
          }
        });
  }
  isLabCompleted(): Promise<boolean> {
    return new Promise<boolean>((resolve, reject) => {
      this.subscriptions[this.subscriptions.length] =
        this.LabSrv.getLabEnvironment(this.id, this.voucherCode)
          .pipe(takeUntil(this.notifier))
          .subscribe((res: any) => {
            const start = moment.utc(res.StartTime, 'YYYY-MM-DD HH:mm:ss');
            const current = moment.utc(new Date(), 'HH:mm');
            const duration = moment.duration(current.diff(start));
            res.Duration = moment.duration(res.Duration, 'minutes');
            const remainingDuration = res.Duration.subtract(duration);
            // tslint:disable-next-line:radix
            const day = parseInt(remainingDuration.asDays());
            let hours = remainingDuration.get('hours');
            if (day > 0) {
              const daysHours = day * 24;
              hours = daysHours + hours;
            }
            const minutes = remainingDuration.get('minutes');
            if (hours <= 0 && minutes <= 0) {
              resolve(true);
            } else {
              resolve(false);
            }
          });
    });
  }

  /**
   * Manage the polling for the shared lab instance
   */
  manageSharedLabPolling() {
    const { IsSharedTestDrive } = this.labUserData;
    if (IsSharedTestDrive) {
      this.operationMode = this.operationModes.INITIATION;
      const key = `shared_lab_${this.lab.UniqueName}_${this.voucherCode}`;
      let localTrigger: number = +localStorage.getItem(key) || 0;
      this.subscriptions[this.subscriptions.length] = interval(1 * 60 * 1000)
        .pipe(
          startWith(0),
          takeWhile(() => !this.isSharedTestDrive && localTrigger <= 5)
        )
        .subscribe(async () => {
          const { DeploymentStatus } = await this.LabSrv.getAttendeeLab(
            this.voucherCode
          ).toPromise();
          if (localTrigger === 5 && DeploymentStatus === null) {
            this.stopPolling();
            this.operationMode = this.operationModes.UNINITIATED;
            this.isSharedTestDrive = true;
          } else if (DeploymentStatus !== null) {
            this.startLab(this.operationModes.INITIATION);
            localStorage.removeItem(key);
            localTrigger = 6;
          }
          localStorage.setItem(key, (localTrigger++).toString());
        });
    }
  }
  startAzureLab() {
    this.startLab();
  }

  /* get Resource List for Trial App */
  async loadResources(operation: 'start' | 'stop' | null = null) {
    // this.isVMLoading = true;
    this.vmUsage = (
      await this.LabSrv.GetVMUsage(this.voucherCode).toPromise()
    )[0];
    this.isResourceUpdated = true;
    if (this.vmUsage?.ResourceList?.length > 0) {
      this.isResourceStopped =
        this.vmUsage.ResourceList.filter((element) =>
          [
            'deallocating',
            'deallocated',
            'stopped',
            'stopping',
            'vm deallocated',
          ].includes(element.Status.toLowerCase())
        ).length > 0;
    }
    if (
      (operation === 'start' && this.isResourceStopped) ||
      (operation === 'stop' && !this.isResourceStopped)
    ) {
      setTimeout(() => {
        this.loadResources(operation);
      }, 60000);
    }
  }

  virtualMachineOperations(operation: any) {
    let action = '';
    this.vmType = operation;
    switch (operation) {
      case '7':
        action = this.translateSrv.instant('START');
        break;
      case '8':
        action = this.translateSrv.instant('PAUSE');
        break;
    }
    this.openConfirmationModel(action);
  }

  openConfirmationModel(action) {
    this.modalRef = this.modalService.show(VmDialogComponent, {
      initialState: {
        title: this.translateSrv.instant('VIRTUAL_MACHINE_OPERATION_TITLE'),
        data: {
          description:
            this.translateSrv.instant('TRIAL_DESCRIPTION_VM_OPERATION') +
            ' ' +
            String(action).toLowerCase() +
            ' ' +
            this.translateSrv.instant('DESCRIPTION_VMACHINE_OPERATION') +
            // dataRow.ResourceName +
            this.translateSrv.instant('DESCRIPTION_VM_OPERATION_QUESTION'),
          failureButtonText: this.translateSrv.instant('CANCEL'),
          successButtonText: this.translateSrv.instant('OK'),
        },
      },
    });
  }

  /**
   * Diallog confirmation
   */
  async onDialogConfirmation() {
    if (this.vmUsage.ResourceList.length > 0) {
      const apiCall = this.vmUsage.ResourceList.map((item) => {
        return this.LabSrv.virtualMachineOperations(
          this.vmType,
          this.guideData.CloudPlatformId,
          this.vmUsage.SubscriptionId,
          item.ResourceGroupName,
          item.ResourceName,
          this.voucherCode
        ).toPromise();
      });
      const data: any = await Promise.all(apiCall);
      // this.refreshAlert();
      if (data) {
        if (data.every((item) => item.IsSuccess)) {
          switch (this.vmType.toString()) {
            case '7':
              this.notificationService.success(
                this.translateSrv.instant('MESSAGE_VM_START'),
                null
              );
              this.loadResources('start');
              break;
            case '8':
              this.notificationService.success(
                this.translateSrv.instant('MESSAGE_VM_STOP'),
                null
              );
              this.loadResources('stop');
              break;
          }
          this.LabSrv.reloadVMs.next();
        } else {
          const code = 'Error - 70027';
          this.notificationService.error(
            data.map((item) => item.ErrorMessage).join(', '),
            null
          );
          this.eventEmitter.debugAlert(
            code,
            data.map((item) => item.ErrorMessage).join(', ')
          );
        }
      }
    }
  }

  manageTimestamp(value: string): string {
    const dur: any = moment.duration(value);
    return dur.format('hh:mm', { trim: false });
  }

  /**
   * @description: To convert video url to embed urls
   */

  public videoUrlCheck(videoUrl: string): string {
    return videoUrlCheck(videoUrl, 'true');
  }

  private extendCredit() {
    this.modalRef = this.modalService.show(ExtendCreditComponent, {
      initialState: {
        title: this.translateSrv.instant('EXT_CREDIT_TITLE'),
        data: {
          successButtonText: this.translateSrv.instant('OK'),
          failureButtonText: this.translateSrv.instant('CANCEL'),
          v_code: this.voucherCode,
          approvalRequired:this.lab.DisableCreditAutoApprove,
          unit:`${this.lab.AllowedCreditLimit}$`,
          maxUnit:`${this.lab.MaxCreditLimit}$`,
        },
      },
    });
  }

  ngOnDestroy() {
    this.notifier.next();
    this.notifier.complete();
    clearInterval(this.labPolling);
    clearInterval(this.polling);
    clearInterval(this.manageInterval);
    if (this.countDownTimer) {
      clearTimeout(this.countDownTimer);
      this.countDownTimer = null;
    }
    this.isComponentDestoyed = true;
    if (this.modalRef) {
      this.modalRef.hide();
    }
    unsubscribeCollection(this.subscriptions);
  }
}
