import { ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { DurationTimeModel } from '../../model/common.model';
import { timesInDaysHoursMinutes } from '../../utils/common-utilities.util';

@Component({
  selector: 'cloudlabs-duration-time',
  templateUrl: './duration-time.component.html'
})
export class DurationTimeComponent implements OnInit {
  @Input() companyName: string;
  @Input() context: string;
  days: string;
  hours: string;
  minutes: string;
  @Input() durationValue: number;

  constructor(
    private cdr: ChangeDetectorRef
  ) { }

  ngOnInit(): void {
    if (this.durationValue) {
      this.transform(this.durationValue)
      this.cdr.detectChanges();
    }
  }

  transform(value: number) {
    const time: DurationTimeModel = timesInDaysHoursMinutes(value)
    this.days = time?.day
    this.hours = time?.hour
    this.minutes = time?.minute
    this.cdr.detectChanges();
  }

}
