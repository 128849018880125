import { Resolve, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Observable, of } from 'rxjs';
import { Injectable } from '@angular/core';
import { catchError } from 'rxjs/internal/operators';
import { LabService } from './../services/lab.service';
import { EventEmitterService } from '@teams-auth';

@Injectable({ providedIn: 'root' })
export class VmurlResolver implements Resolve<any> {
  constructor(private labSrv: LabService, private eventEmitter: EventEmitterService) { }

  resolve(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<any> {
    const vmurl = route.params.vmurl;
    if(!vmurl){
      return of(null);
    }
    return this.labSrv.vmlaunchUrl(route.params.vmurl, route.queryParams.vmguid ? route.queryParams.vmguid : '').pipe(
      catchError(error => {
        const code = 'Error - 70007';
        this.eventEmitter.debugAlert(code, error.error);
        return of(null);
      })
    );
  }
}
