import { Component, OnInit, Inject } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import {EventService} from '../../../services/event.services';

@Component({
  selector: 'cloudlabs-theme',
  templateUrl: './theme.component.html',
  styleUrls: ['./theme.component.scss']
})
export class ThemeComponent implements OnInit {
  public themeName = 'blue';
  public settingBar = false;
  constructor(
    @Inject(DOCUMENT) private document,
    private eventSrv: EventService
  ) { }

  ngOnInit(): void {
    const theme = localStorage.getItem('theme');
    if (theme) {
      this.themeName = theme;
    }
    this.document.getElementById('theme').setAttribute('href', './assets/theme/' + this.themeName + '-theme.css');
    this.eventSrv.themeId.subscribe((themeId) => {
      let themeColor: string;
      switch (themeId?.toString()) {
        case '1':
          themeColor = 'blue';
          break;
        case '2':
          themeColor = 'red';
          break;
        case '3':
          themeColor = 'green';
          break;
        default:
          themeColor = 'blue';
      }
      this.toggleTheme(themeColor);
    });
  }


  openThemes(){
    this.settingBar = true;
  }

  close(){
    this.settingBar = false;
  }

  /**
   * Select Theme
   * @param data
   */
  toggleTheme(data) {
    localStorage.setItem('theme', data);
    this.themeName = data;
    this.document.getElementById('theme').setAttribute('href', `./assets/theme/${this.themeName}-theme.css`);
    this.settingBar = false;
  }

}
