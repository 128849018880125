import { Component, EventEmitter, Input, Output } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
// import { AuthService } from '@teams-auth';

interface LanguageFlag {
  value: string;
  label: string;
  flag: string;
  active?: boolean;
}

@Component({
  selector: 'cloudlabs-util-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent {
  config: any;
  @Input() companyName: string;
  @Input() companyLogo: string;
  @Input() supportLink: string;
  @Input() contactLink: string;
  @Input() showLang = true;
  @Input() userDetails = true;
  @Input() logOut = true;
  @Input() userEmail: string;
  @Output() private logoutResponse = new EventEmitter();
  @Input() isTrialHeader = false;
  @Input() isBackgroundBlue = false;

  @Input() isMicrosoftPartner = false;

  @Input() showHeaderDesign = true;

  @Input() hrefURL = '';
  @Input() isYellowbrick = false;

  selectOrigin: string;
  languages: LanguageFlag[] = [
    {
      value: 'en',
      label: 'English',
      flag: './assets/media/svg/flags/226-united-states.svg',
    }
  ];
  public selectedLanguage: string;

  constructor(private readonly translateService: TranslateService) {
    this.getLanguage();
  }

  public logout(): void {
    this.logoutResponse.emit(true);
  }

  public getLanguage(): void {
    this.translateService.addLangs(['en']); //'TODO: add spanish later
    this.translateService.setDefaultLang('en');
    const selectedLang =
      localStorage.getItem('language-value') ||
      this.translateService.getDefaultLang();
    if (selectedLang === 'en') {
      this.selectedLanguage = 'English';
      this.translateService.use('en');
    }
    // else {
    //   this.selectedLanguage = 'Espanol';
    //   this.translateService.use('es');
    // }
  }

  public translateLanguage(e) {
    this.translateService.use(e.val);
    localStorage.setItem('language-value', e.val);
    this.selectedLanguage = e.label;
  }

  navigateToSupport(supportLink: string): void {
    window.open(supportLink, '_blank');
  }
}
