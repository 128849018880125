<ngx-remote-desktop (click)="changeFocusToVM()"
(keydown.enter)="changeFocusToVM()" *ngIf="!loader && manager !== undefined" role="application" 
[tabIndex]="manager.getState() === remoteDesktopStates.CONNECTED ? 0 : -1"
[attr.aria-label]="hidingRdpAriaLabel" [blockCopyPaste]="blockCopyPaste"
[manager]="manager" [isInsideEnv]="isInsideEnv" [cloudPlateFormId]="cloudPlateFormId" (vmRightClick)="sendExternalDataToClient()"
(vmState)="changeVMState($event)">
  <!-- Override connection state messages -->
  <ngx-remote-desktop-connecting-message>
  </ngx-remote-desktop-connecting-message>
</ngx-remote-desktop>

<div  *ngIf="manager === undefined && !vmLoad" class="col-md-9 col-sm-10 mx-auto mt-5 vm-message-section">

  <div class="container mt-3" [ngClass]="{'container-padding': steps[2].state === 'active' || steps[0].status === 'failed'}">
    <h3 class="mt-2 text-center"><b>{{'ENVIRONMENT_STATUS' | translate}}</b></h3>
    <div class="row">
      <div class="col-12 p-3">
        <div class="stepper">
          <div *ngFor="let step of steps; let i = index" class="step w-100" [ngClass]="{
            'step-active': step.state === 'active',
            'step-completed': step.state === 'completed',
            'step-pending': step.state === 'pending'
          }">
            <div class="step-number-wrapper">
              <div class="main-round mb-1">
                <span *ngIf="step.state === 'active'"></span>
                <i class="fas fa-check-circle" *ngIf="step.state === 'completed'"></i>
              </div>
              <!-- <div class="step-number">{{ i + 1 }}</div> -->
            </div>
            <div class="step-title w-100">
              <div *ngIf="i + 1 === 1" class="d-flex align-items-center justify-content-between mb-3 step-content">
                <div class="d-flex align-items-start">
                  <!-- <i class="fas fa-desktop pt-1"></i> -->
                  <img src="assets/images/Vm-icon.svg" alt="logo">
                  <div class="ml-3">
                    <b class="m-0">{{'VIRTUAL_MACHINE' | translate}}</b>
                    <p class="text-muted m-0 mr-3" *ngIf="step.description">
                      {{step.description | translate}}
                    </p>
                  </div>
                </div>
                <div class="action-section text-end">
                  <div class="d-flex align-items-center text-primary" *ngIf="!step.status">
                    <div>
                      <span class="spinner-border spinner-border-sm mr-2"></span>
                      {{'CHECKING_STATUS' | translate}}
                    </div>
                  </div>
                  <teams-button *ngIf="step.status === 'stop'" aria-label="reconnect" (buttonClick)="startVM()"
                    [classList]="'btn ts-btn-fluent-primary btn-primary mr-0 d-block'" [index]="'start'" 
                    [processingTitle]="btnLoadingService.getButtonText()" [showLoading]="btnLoadingService.getLoding()"
                    [title]="'START_VM_LABEL' | translate">
                  </teams-button>
                  <countdown *ngIf="step.status === 'timer'" [config]="config" (event)="handleEvent($event)"></countdown>
                  <span class="text-success" *ngIf="step.status === 'running'">{{'RUNNING' | translate}}</span>
                  <span class="text-danger" *ngIf="step.status === 'failed'">{{ step?.statusLabel | translate}}</span>
                </div>
              </div>

              <div *ngIf="i + 1 === 2" class="d-flex justify-content-between w-100 mb-3 step-content">
                <div class="d-flex align-items-start">
                  <!-- <i class="fas fa-plug pt-1"></i> -->
                  <img src="assets/images/Web-Connect-icon.svg" alt="logo">
                  <div class="ml-3">
                    <b class="m-0">{{'WEB_CONNECT_GATEWAY' | translate}}</b>
                    <p class="text-muted m-0 mr-3" *ngIf="step.description">
                      {{step.description | translate}}
                    </p>
                  </div>
                </div>
                <div class="action-section">
                  <span *ngIf="step.state === 'pending' && steps[0].status !== 'failed'">{{'PENDING_CAPITAL' | translate}}</span>
                  <div class="d-flex align-items-center text-primary" *ngIf="step.state === 'active' && !step.status">
                    <div>
                      <span class="spinner-border spinner-border-sm mr-2"></span>
                      {{'CHECKING_STATUS' | translate}}
                    </div>
                  </div>
                  <span class="text-success" *ngIf="step.status === 'running'">{{'RUNNING' | translate}}</span>
                  <span class="text-danger" *ngIf="step.status === 'failed'">{{ step?.statusLabel | translate}}</span>
                  <span class="text-danger" *ngIf="steps[0].status === 'failed'">{{'CANNOT_PROCEED' | translate}}</span>
                  <countdown *ngIf="step.status === 'timer'" [config]="containerConfig" (event)="handleContainerEvent($event)"></countdown>
                  <teams-button *ngIf="step.status === 'stop'" aria-label="reconnect" (buttonClick)="startContainer()"
                    [classList]="'btn ts-btn-fluent-primary btn-primary mr-0 d-block'"  [index]="'container'" 
                    [processingTitle]="btnLoadingService.getButtonText()" [showLoading]="btnLoadingService.getLoding()"
                    [title]="'START' | translate">
                  </teams-button>
                </div>
              </div>

              <div *ngIf="i + 1 === 3" class="d-flex justify-content-between w-100 mb-3 step-content">
                <div class="d-flex align-items-start">
                  <!-- <i class="fas fa-cube pt-1"></i> -->
                  <img src="assets/images/Local-Connection-icon.svg" alt="logo">
                  <div class="ml-3">
                    <b class="m-0">{{'LOCAL_CONNECTION_TO_VM' | translate}}</b>
                    <p class="text-danger m-0 mr-3" *ngIf="step.status === 'failed'">
                      {{ 'FAILED_TO_CONNECT' | translate}}
                    </p>
                    <p class="text-muted m-0 mr-3" *ngIf="step.description">
                      {{step.description | translate}}
                    </p>
                  </div>
                </div>
                <div class="action-section">
                  <span *ngIf="step.state === 'pending' && (steps[0].status !== 'failed' && steps[1].status !== 'failed')">{{'PENDING_CAPITAL' | translate}}</span>
                  <!-- <ng-container *ngIf="step.state === 'active' && !step.status"> -->
                  <div class="d-flex align-items-center text-primary" *ngIf="step.state === 'active' && !step.status">
                    <div>
                      <span class="spinner-border spinner-border-sm mr-2"></span>
                      {{'CHECKING_STATUS' | translate}}
                    </div>
                  </div>
                  <!-- </ng-container> -->
                  <!-- <span class="text-primary" *ngIf="step.state === 'active' && !step.status">{{'CHECKING_STATUS' | translate}}</span> -->
                  <span class="text-success" *ngIf="step.status === 'running'">{{'RUNNING' | translate}}</span>
                  <!-- <span class="text-danger" *ngIf="step.status === 'failed'">{{'FAILED_LABEL' | translate}}</span> -->
                  <span class="text-danger" *ngIf="steps[0].status === 'failed' || steps[1].status === 'failed'">
                    {{'CANNOT_PROCEED' | translate}}
                  </span>
                  <countdown *ngIf="step.status === 'timer'" [config]="rdpConfig" (event)="handleRDPEvent($event)"></countdown>
                  <teams-button *ngIf="step.status === 'failed'" aria-label="reconnect" (buttonClick)="isReconnectButton = true; getAuthToken()"
                    [classList]="'btn ts-btn-fluent-primary btn-primary mr-0 d-block'" [index]="'reconnect'" 
                    [processingTitle]="btnLoadingService.getButtonText()" [showLoading]="btnLoadingService.getLoding()"
                    [title]="'BUTTON_RECONNECT' | translate">
                  </teams-button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="error-section" *ngIf="steps[0].status === 'failed'">
          <div class="error-content">
            <div class="error-label text-muted mt-3">{{'ERROR' | translate}}:</div>
            <span [innerHTML]="vmErrorMessage | translate: { Email: lab.ContactEmail }"></span>
          </div>
        </div>

        <div class="error-section" *ngIf="steps[1].status === 'failed'">
          <div class="error-content">
            <div class="error-label text-muted mt-3">{{'ERROR' | translate}}:</div>
            <div>
              <span> {{'WEB_CONNECT_FAILED_MSG' | translate}} </span>
              <a class="privacy-link ts-text-primary underline" [attr.aria-label]=" ('common.OPEN_IN_NEW_TAB' | translate) + ('FOOTER.PRIVACY_POLICY' | translate)"
                href="mailto:{{lab.ContactEmail}}">{{lab.ContactEmail}}
                <!-- <a href=\"mailto:{{Email}}\">{{Email}}</a> -->
                <!-- <i class="fas fa-external-link-alt fa-1x ts-text-primary pl-2" aria-hidden="true"></i> -->
              </a>
            </div>
          </div>
          <div class="error-content">
            <div class="error-label text-muted mt-3">{{'ALTERNATE_CONNECTION_METHOD' | translate}}</div>
            <span>{{'ALTERNATE_CONNECTION_DESCRIPTION' | translate}} </span>
            <div class="mt-3">
              <a class="privacy-link ts-text-primary underline" [attr.aria-label]=" ('common.OPEN_IN_NEW_TAB' | translate) + ('RDP_DOCUMENT_LINK' | translate)"
                [href]="rdpDocLink" target="_blank" translate="RDP_DOCUMENT_LINK">
                <!-- <i class="fas fa-external-link-alt fa-1x ts-text-primary pl-2" aria-hidden="true"></i> -->
              </a>
            </div>
          </div>
          <div class="error-content mt-3">
            <div class="row mt-3 d-flex justify-content-between">
              <!-- <a class="col-md-4 privacy-link ts-text-primary underline" [attr.aria-label]=" ('common.OPEN_IN_NEW_TAB' | translate) + ('DOWNLOAD_RDP_SSH' | translate)"
                  [href]="rdpDocLink" target="_blank" translate="DOWNLOAD_RDP_SSH"></a> -->
              <div class="col-md-6" *ngIf="!isSplitWindow">
                <a class="privacy-link ts-text-primary underline" [attr.aria-label]=" ('common.OPEN_IN_NEW_TAB' | translate) + ('SHOW_CONNECTION_INFO' | translate)"
                  href="javascript:void(0)" translate="SHOW_CONNECTION_INFO" (click)="openEnvironmentTab()"></a>
              </div>
              <div class="col-md-6" [ngClass]="{'text-right': !isSplitWindow}">
                <a class="privacy-link ts-text-primary underline"
                  href="mailto:{{lab.ContactEmail}}" translate="CONTACT_SUPPORT"></a>
              </div>
            </div>
          </div>
        </div>

        <div class="error-section" *ngIf="steps[2].status === 'failed'">
          <div class="error-content">
            <div class="error-label text-muted mt-3">{{'LOCAL_BROWSER_CONNECTION_ISSUES' | translate}}</div>
            <span [innerHTML]="'LOCAL_BROWSER_CONNECTION_ISSUES_DESCRIPTION' | translate"></span>
            <div class="mt-3 ml-3">
              <div class="mt-3">
                <b>1.&nbsp;</b> <span [innerHTML]="'VPN_CONNECTION' | translate"></span>
              </div>
              <div class="mt-3">
                <b>2.&nbsp;</b> <span [innerHTML]="'MULTIPLE_SESSIONS' | translate"></span>
              </div>
              <div class="mt-3">
                <b>3.&nbsp;</b> <span [innerHTML]="'NETWORK_FIREWALL_RESTRICTIONS' | translate"></span>
              </div>
            </div>
            <div class="mt-3" [innerHTML]="'DOCUMENTATION_TO_LEARN' | translate: { DocLink: rdpDocLink }"></div>
            <div class="error-content mt-3">
              <div class="error-label text-muted mt-3">{{'ALTERNATE_CONNECTION_METHOD' | translate}}</div>
              <span>{{'ALTERNATE_CONNECTION_DESCRIPTION' | translate}} </span>
              <div class="mt-3">
                <a class="privacy-link ts-text-primary underline" [attr.aria-label]=" ('common.OPEN_IN_NEW_TAB' | translate) + ('RDP_DOCUMENT_LINK' | translate)"
                  [href]="rdpDocLink" target="_blank" translate="RDP_DOCUMENT_LINK">
                  <!-- <i class="fas fa-external-link-alt fa-1x ts-text-primary pl-2" aria-hidden="true"></i> -->
                </a>
              </div>
            </div>
            <div class="error-content mt-3">
              <div class="row mt-3 d-flex justify-content-between">
                <!-- <a class="col-md-4 privacy-link ts-text-primary underline" [attr.aria-label]=" ('common.OPEN_IN_NEW_TAB' | translate) + ('DOWNLOAD_RDP_SSH' | translate)"
                  [href]="rdpDocLink" target="_blank" translate="DOWNLOAD_RDP_SSH"></a> -->
                <div class="col-md-6" *ngIf="!isSplitWindow">
                  <a class="privacy-link ts-text-primary underline" [attr.aria-label]=" ('common.OPEN_IN_NEW_TAB' | translate) + ('SHOW_CONNECTION_INFO' | translate)"
                    href="javascript:void(0)" translate="SHOW_CONNECTION_INFO" (click)="openEnvironmentTab()"></a>
                </div>
                <div class="col-md-6" [ngClass]="{'text-right': !isSplitWindow}">
                  <a class="privacy-link ts-text-primary underline"
                    href="mailto:{{lab.ContactEmail}}" translate="CONTACT_SUPPORT"></a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

  <!-- <div *ngIf="!loader && manager === undefined && !isInsideEnv" class="offset-sm-3 text-center align-items-center col-6 alert alert-info mt-4">
    {{"VM_NOT_CONNECTED" | translate}}
    <div class="mt-5">
      <teams-button *ngIf="credGenerated" aria-label="reconnect" [title]="'BUTTON_RECONNECT' | translate" (buttonClick)="subscribeVmDetailsChange()" [classList]="'btn ts-btn-fluent-primary btn-primary ngx-remote-desktop-message-body-btn mb-2'">
      </teams-button>
    </div>
  </div> -->
<!-- Narrator starting alert message -->
<div *ngIf="narratorAlertMessage && isInsideEnv" style="font-size: 0px; width: 0px;">
  <span role="alert">Starting Narrator Please wait..</span>
</div>

<div class="vex-splash-screen vex-splash_2" *ngIf="loader">
  <div class="wrapper">
    <div class="ball-scale-multiple">
      <div></div>
      <div></div>
      <div></div>
    </div>
  </div>
</div>