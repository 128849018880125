<ul class="nav nav-pills float-left">

    <ng-container *ngFor="let menu of menuList;let i=index;">
        <li [ngClass]="{ 'nav-bottom-border': menu.id !== selectedMenu}" class="nav-item padd-top-29 mr-4" [class.active-link]="menu.id === selectedMenu">
            <a class="pt-0" (click)="onMenuSelect(i)">
                <span> {{  menu.name | translate }}  </span>
            </a>
        </li>
    </ng-container>

</ul>