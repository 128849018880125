<!--For Practice test only-->

<ng-container *ngIf="isPracticeTest">
    <label [for]="randomValue" class="checkBox pt">
        <input type="checkbox" [attr.aria-label]="ariaLabel" [id]="randomValue" [value]="value" [class]="inputClass"
            [checked]="checked" [disabled]="disabled" (change)="updateChanges($event.target.checked)"
            [ngClass]="ngClassInput" [tabindex]="tabindex">
        <span *ngIf="isInnerHtml === false" [for]="randomValue" [class]="labelClass"
            [ngClass]="ngClassLabel">{{label}}</span>
        <span *ngIf="isInnerHtml === true" [for]="randomValue" [class]="labelClass" [innerHtml]="label"
            [ngClass]="ngClassLabel"></span>
    </label>
</ng-container>

<!--For other place-->

<ng-container *ngIf="!isPracticeTest">
    <input type="checkbox" class="not-pt" [class]="inputClass" [ngClass]="ngClassInput" [attr.aria-label]="ariaLabel"
        [id]="randomValue" [value]="value" [class]="" [checked]="checked" [disabled]="disabled"
        (change)="updateChanges($event.target.checked)" [tabindex]="tabindex">

    <label *ngIf="isInnerHtml === false" [for]="randomValue" [class]="labelClass"
        [ngClass]="ngClassLabel">{{label}}</label>
    <label *ngIf="isInnerHtml === true" [for]="randomValue" [class]="labelClass" [innerHtml]="label"
        [ngClass]="ngClassLabel"></label>
</ng-container>