import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class BaseServiceService {
  constructor(private http: HttpClient) { }
  get<T>(url: string): Observable<T> {
    return this.http.get<T>(url);
  }

  getWithText(url: string): Observable<any> {
    const headers = new HttpHeaders().set('Content-Type', 'text/plain; charset=utf-8');
    return this.http.get(url, { headers, responseType: 'text'});
  }

  getWithResponse<T>(url: string): Observable<any> {
    return this.http.get<T>(url, { observe: 'response' });
  }

  post<T>(url: string, data: any): Observable<T> {
    return this.http.post<T>(url, data);
  }
  postwithobject<T>(path: string, body: object = {}): Observable<any> {
    return this.http.post<T>(`${path}`, body);
  }

  put<T>(url: string, data: any): Observable<T> {
    return this.http.put<T>(url, data);
  }
  putwithobject<T>(path: string, body: object = {}): Observable<any> {
    return this.http.patch<T>(`${path}`, body);
  }
  delete<T>(url: string): Observable<T> {
    return this.http.delete<T>(url);
  }
}
