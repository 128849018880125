<div>
    <!-- <ul class="singlequestion-list" id="main-landmark"> -->
    <ul class="singlequestion-list">
        <li>
            <span class="sql-title">{{ 'exam.questions.questionSection' | translate }}</span>
            <div class="sql-ques case-study-sql-ques">
                <h4 role="heading" aria-level="2" class="" [innerHTML]="question?.question | targetBlank"></h4>
                <span *ngIf="question?.note !== null"><strong>{{ 'exam.questions.note' | translate }}: </strong> <span [innerHTML]="question?.note"></span></span>
            </div>
            <span *ngIf="question?.instruction !== null"><strong>{{ 'exam.questions.instruction' | translate }}:</strong> <span
          [innerHTML]="question?.instruction"></span></span>
            <span class="sql-title">{{ 'exam.questions.answerSection' | translate }}</span>
            <div class="ans-swap-box">
                <ul class="swap-left-list-box">
                    <li draggable [dragEnabled]="!item.disabled" *ngFor="let item of options" [dragData]="item" [ngClass]="{'disableItem':item.disabled }">
                        {{item.value}}
                    </li>
                </ul>
                <ul class="question-label borderleft">
                    <li *ngFor="let item of question.labels;let i=index;" droppable (onDrop)="onListValueDrop($event,item)">
                        <strong>
              <span>
                <i class="ms-Icon ms-Icon--SkypeMinus"
                  *ngIf="item.option.length > miscellaneousConst?.answeredArrayMinLength" title="minus"
                  aria-hidden="true"></i>
                <i class="ms-Icon ms-Icon--FullCircleMask"
                  *ngIf="item.option.length === miscellaneousConst?.answeredArrayMinLength" title="FullCircleMask"
                  aria-hidden="true"></i>
              </span>
              {{item.value}}</strong>
                        <ul class="swap-right-inner-list-box">
                            <ng-container *ngIf="item.option.length > miscellaneousConst?.answeredArrayMinLength">
                                <li *ngFor="let itemOption of item.option">
                                    <span> <i class="ms-Icon ms-Icon--FullCircleMask" title="FullCircleMask" aria-hidden="true"></i>
                  </span>
                                    <div class='sril-title'>{{itemOption.value}}</div>
                                </li>
                            </ng-container>
                            <ng-container *ngIf="item.option.length === miscellaneousConst?.answeredArrayMinLength">
                                <li>{{ 'exam.questions.dropAnswer' | translate }}</li>
                            </ng-container>
                        </ul>
                    </li>
                </ul>
            </div>
        </li>
    </ul>
</div>