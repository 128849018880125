import { Directive,OnInit,ElementRef } from '@angular/core';

@Directive({
  selector: '[appVmRole]'
})
export class VmRoleDirective implements OnInit {

  constructor( private el: ElementRef ) { }

  ngOnInit() {
      setTimeout(() => {
        (this.el?.nativeElement as HTMLElement).setAttribute('role', 'alert');
        (this.el?.nativeElement as HTMLElement).setAttribute('aria-live', 'assertive');
      }, 500);
  }
}
