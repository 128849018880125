import {Directive, ElementRef, HostListener} from '@angular/core';

@Directive({
  selector: '[appRemoveFocusOnPress]'
})
export class RemoveFocusOnPressDirective {

  constructor(private elRef: ElementRef) {}

  @HostListener('click') onClick() {
    this.elRef.nativeElement.blur();
  }
  @HostListener('keyup', ['$event']) onKeyDown($event: KeyboardEvent) {
    if ($event.key === 'Enter') {
      this.elRef.nativeElement.blur();
    }
  }

}
