<div class="w-100">
    <ul class="singleresult-list singlequestion-list-result">
        <!--No question title for dropdown type questions | No question and answer headings | Sheikh-->
        <li>
            <div *ngIf="question.userAnswers.length>0">
                <strong class="sql-number">{{index}}. </strong>
                <ng-container *ngIf="!checkValue(question.answers)">
                    <div [innerHTML]="dropdownStrList" class="drop-down-margin"></div>
                    <div [innerHTML]="dropdownStrList1"></div>
                </ng-container>
                <ng-container *ngIf="checkValue(question.answers)">
                    <div [innerHTML]="dropdownStrList" class="drop-down-margin"></div>
                </ng-container>
            </div>
            <div *ngIf="question.userAnswers.length===0">
                <strong class="sql-number">{{index}}. </strong>
                <div class="dropdown-result case-study-sql-ques" [innerHTML]="dropdownStrList1"></div>
            </div>
        </li>
    </ul>
    {{question.userScore}}/{{question.questionScore}} {{ 'exam.result.points' | translate }}
</div>