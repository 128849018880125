import { Component, OnInit, ChangeDetectorRef, Input, Inject } from '@angular/core';
import { setting } from '../../../modals/setting.model';
import { CustomerType } from '@libs/common-lab/src/modals/lab.model';

@Component({
  selector: 'cloudlabs-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {
  @Input() enableFooter;
  @Input() labData;
  public appSetting: setting;
  public loading = true;
  public year: string;
  public customerType = CustomerType;
  constructor(
    @Inject('environment') public environment
  ) {

  }

  ngOnInit(): void {
    this.year = new Date().getFullYear().toString();
    setTimeout(() => {
      this.getSetting();
    }, 500);

  }

  /**
   * get app setting
   */

  getSetting() {
    const haveSetting = localStorage.getItem('setting');
    if (haveSetting) {
      this.appSetting = JSON.parse(haveSetting);
    }
    this.loading = false;
  }

  get copyRight(): any {
    if (this.appSetting) {
      return this.appSetting.Data.Copyright;
    } else {
      return '';
    }
  }

  get poweredByURLText(): any {
    if (this.appSetting) {
      return this.appSetting.Data.PoweredByURLText;
    } else {
      return '';
    }
  }
  get poweredByURL(): any {
    if (this.appSetting) {
      return this.appSetting.Data.PoweredByURL;
    } else {
      return '';
    }
  }
  get contactEmail(): any {
    if (this.appSetting) {
      return this.appSetting.Data.ContactEmail;
    } else {
      return '';
    }
  }
  get SupportEmail(): any {
    if (this.appSetting) {
      return this.appSetting.Data.SupportEmail;
    } else {
      return '';
    }
  }
  get contactPhone(): any {
    if (this.appSetting) {
      return this.appSetting.Data.ContactPhone;
    } else {
      return '';
    }
  }
  get policyLink(): any {
    if (this.appSetting) {
      return this.appSetting.Data.PrivacyPolicyLink;
    } else {
      return '';
    }
  }
  get terms(): any {
    if (this.appSetting) {
      return this.appSetting.Data.TermsOfServiceLink;
    } else {
      return '';
    }
  }

}
