import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';

export class CustomValidators {
  static checkMax(limit: number){
    return (control: AbstractControl): { [key: string]: boolean } | null => {
      const val = control.value;
      if (val) {
        if ((val as string).length > limit) {
          return {limitExhausted: true};
        } else {
          return null;
        }
      }
      return null;
    };
  }

  static isWorkEmailValidator(
    control: AbstractControl
  ): { [key: string]: boolean } | null {
    const email = control.value;
    if (email === null || email === undefined) {
      return null;
    }
    const inValidEmailsDomains = [
      'gmail',
      'yahoo',
      'hotmail',
      'live',
      'outlook',
      'msn',
      'aol',
      'zohomail',
      'protonmail',
      'tutanota',
      'yandex',
      'mail',
      'gmx',
      'googlemail',
      'me',
      'icloud'
    ];
    const emailParts = email.toString().split('@');
    if (emailParts.length > 1) {
      const emailFormat = emailParts[1].split('.');
      if (emailFormat.length > 1) {
        if (inValidEmailsDomains.includes(emailFormat[0])) {
          return { workEmail: true };
        }
      }
    }
    return null;
  }

  static isNotEmpty(control: AbstractControl): { [key: string]: boolean } | null {
    const val = control.value;
    if (val === null || val === undefined) {
      return { required: true };
    } else {
      const sanitisedVal = (val as string).trim();
      if (sanitisedVal === '') {
        return { required: true };
      } else {
        return null;
      }
    }
  }

  static loggedUser(email: string) {
    return (control: AbstractControl): { [key: string]: boolean } | null => {
      const val = control.value;
      if (val) {
        if (val.toLowerCase() === email.toLowerCase()) {
          return { loggedUser: true };
        } else {
          return null;
        }
      } else {
        return null;
      }
    };
  }

  static notZeroValidator(control: AbstractControl): { [key: string]: boolean } | null {
    const val = control.value;
    if (val === null || val === undefined || val === 0) {
      return { required: true };
    } else {
      return null
    }
  }

  static isValiduserName(
    control: AbstractControl
  ): { [key: string]: boolean } | null {
    const userName = control.value;
    if (userName === null || userName === undefined) {
      return null;
    }
    const inValidUserNames = [
      '1',
      '123',
      'a',
      'actuser',
      'adm',
      'admin',
      'admin1',
      'admin2',
      'administrator',
      'aspnet',
      'backup',
      'console',
      'david',
      'guest',
      'john',
      'owner',
      'root',
      'server',
      'sql',
      'support_388945a0',
      'support',
      'sys',
      'test',
      'test1',
      'test2',
      'test3',
      'user',
      'user1',
      'user2',
    ];
    if (inValidUserNames.includes(userName) || userName.charAt(userName.length - 1) === '.') {
      return { invalidUserName: true };
    } else if (userName.length > 20) {
      return { userNameLength: true };
    }
    return null;
  }

  static isValidPassword(
    control: AbstractControl
  ): { [key: string]: boolean } | null {
    const password = control.value;
    if (password === null || password === undefined) {
      return null;
    }
    const inValidPasswords = [
      'abc@123',
      'iloveyou!',
      'P@$$w0rd',
      'P@ssw0rd',
      'P@ssword123',
      'Pa$$word',
      'pass@word1',
      'Password!',
      'Password1',
      'Password22',
    ];
    if (inValidPasswords.includes(password)) {
      return { invalidPassword: true };
    }
    return null;
  }

  static endDateAfterStartDateValidator(startDateField: string, endDateField: string): ValidatorFn {
    
    return (formGroup: AbstractControl): ValidationErrors | null => {
      if (!formGroup.get(startDateField)?.value || !formGroup.get(endDateField)?.value) {
        return null;
      }
      const sdate = formGroup.get(startDateField)?.value
      const edate = formGroup.get(endDateField)?.value
      const startDate = new Date(sdate.year, sdate.month - 1, sdate.day);
      const endDate = new Date(edate.year, edate.month - 1, edate.day);

      const differenceInTime = endDate.getTime() - startDate.getTime();
      const differenceInDays = differenceInTime / (1000 * 3600 * 24);

      if (differenceInDays < 1) {
        formGroup.get(endDateField).setErrors({ endDateBeforeStartDate: true });
        return { endDateBeforeStartDate: true };
      } else {
        // formGroup.get(endDateField).setErrors(null);
        return null;
      }
    };
  }

}
