import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  Inject,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Title } from '@angular/platform-browser';
import { Subscription } from 'rxjs';

import {
  ActionItems,
  ActionListItem,
  CustomerType,
  Lab,
  LabModes,
  OperationModes,
} from '../../../../modals/lab.model';
import { AprovalField } from '../../../../modals/form-field.model';

import { EventService } from '../../../../services/event.services';
import { LabService } from '../../../../services/lab.service';
import { UtilityService } from '../../../../services/utility.service';

import { miscellaneousConst, unsubscribeCollection } from '@utility';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'cloudlabs-main',
  templateUrl: './main.component.html',
  styleUrls: ['./main.component.scss'],
})
export class MainComponent implements OnInit, AfterViewInit, OnDestroy {
  public isSidenav = false;
  public lab: Lab;
  public isHideMenu = true;
  public attendee: any;
  public voucherCode: string;
  public mode: string = LabModes.LAB;
  public labModes: any = LabModes;
  public operationMode: string = OperationModes.INITIATION;
  public hideoverview = true;
  public hidevideo = true;
  public enableHeader = false;
  public enableFooter = false;
  public currentView = 'LAB';
  public isMylearning = false;
  private subscriptions: Subscription[] = [];
  /** for selected not selected screen reader messages */
  overViewSelected = false;
  labViewSelected = true;
  videoViewSelected = false;
  environment;
  public counter = 0;
  public actionList: Array<ActionListItem> = [];
  public actionEvent: any;
  public endDateData: any;
  public updatedLabStatus = false;

  constructor(
    private eventSrv: EventService,
    private aroute: ActivatedRoute,
    private labSrv: LabService,
    private titleService: Title,
    private util: UtilityService,
    private route: Router,
    private translateSrv: TranslateService,
    private cdr: ChangeDetectorRef,
    @Inject('environment')
    environment
  ) {
    this.environment = environment;
    if (localStorage.getItem('context') === miscellaneousConst.cloudlab) {
      this.isMylearning = true;
    }
    if (this.environment.appType !== 'trial') {
      if (!this.isMylearning) {
        this.titleService.setTitle('CloudLabs On Demand lab');
      }
    }
  }

  async ngOnInit() {
    this.lab = this.aroute.snapshot.data.lab;
    this.lab.Title = this.lab.DisplayName
      ? this.lab.DisplayName
      : this.lab.Title;
    if (this.isMylearning) {
      this.titleService.setTitle(
        this.lab.Title +
          ' | ' +
          this.translateSrv.instant('HOME.CLOUDLABS_PORTAL')
      );
    }
    // if(this.environment.appType === 'trial'){
    //   this.titleService.setTitle(`${this.lab.PartnerName} Trial`)
    // }
    this.voucherCode = this.aroute.snapshot.params.voucherCode;
    this.attendee = this.aroute.snapshot.data.labattendee;
    if (
      this.lab?.ApprovalTypeId != 1 &&
      this.lab?.ApprovalTypeId != 11 &&
      this.lab?.ApprovalTypeId != 13
    ) {
      if (
        this.attendee?.DeploymentStatus == 'Deleted' ||
        this.attendee?.DeploymentStatus == 'Deleting'
      ) {
        this.isHideMenu = false;
      }
    }
    this.subscriptions[this.subscriptions.length] =
      this.eventSrv.openSideNav.subscribe((res) => {
        this.isSidenav = !this.isSidenav;
      });
    // assign data get from resolvers
    this.eventSrv.setThemeId(this.lab?.ThemeId);
    if (this.lab?.EnableCustomLabDetailsPage === false) {
      this.enableHeader = true;
      this.enableFooter = true;
    }

    // check mode of lab
    if (
      [AprovalField.AnonymousUsers, AprovalField.AutoLaunch].includes(
        this.lab?.ApprovalTypeId
      )
    ) {
      if (this.lab.StatusId === 5) {
        this.mode = LabModes.ANONYMOUS;
        this.operationMode = OperationModes.UNINITIATED;
      } else if (this.lab.StatusId === 6) {
        this.mode = LabModes.INFORMATION;
        this.operationMode = OperationModes.INITIATION;
      } else if (this.voucherCode !== null || this.voucherCode !== undefined) {
        this.mode = LabModes.LAB;
      }
    }
    this.subscriptions[this.subscriptions.length] =
      this.labSrv.labDetailsInES.subscribe((labDetails) => {
        if (labDetails) {
          this.lab = labDetails;
          this.lab.Title = this.lab.DisplayName
            ? this.lab.DisplayName
            : this.lab.Title;
        }
      });
    if (this.lab?.ThemeId) {
      this.eventSrv.setThemeId(this.lab.ThemeId);
    }
    if (this.lab?.LinkToVideo !== null && this.lab?.LinkToVideo !== '') {
      this.hidevideo = false;
    }
    this.subscriptions[this.subscriptions.length] =
      this.eventSrv.languageChanged.subscribe((lang) => {
        this.languageChanged(lang);
      });
    this.createActionList();
  }

  ngAfterViewInit(): void {
    this.util.switchLanguageToDefault(this.lab);
  }

  actionClickEvent(action) {
    this.actionEvent = action;
    this.eventSrv.actionClickEvent.next(action);
  }

  private createActionList() {
    this.actionList = [
      {
        name: 'RELOAD_INSTANCE',
        icon: 'fa fa-refresh',
        action: ActionItems.RELOAD,
      },
    ];
    this.checkDeallocate();
  }

  checkDeallocate() {
    const data = this.actionList.find(
      (item) => item.action === ActionItems.DEALLOCATE
    );
    if (
      this.lab.AllowUserToDeleteODL &&
      ![
        'Deallocated',
        'Deployed',
        'Deallocating',
        'Initiation',
        'UnInitiated',
        'GatheringInfo',
      ].includes(this.operationMode) &&
      !data
    ) {
      this.actionList.push({
        name: 'DELETE_LAB',
        icon: 'fa fa-trash',
        action: ActionItems.DEALLOCATE,
      });
      if (this.lab.AllowUserToExtendCredit) {
        this.actionList.push({
          name: 'EXT_CREDIT',
          icon: 'fas fa-dollar-sign',
          action: ActionItems.EXT_CREDIT,
        });
      }
    }
    if (
      ['Deallocated', 'Deployed', 'Deallocating'].includes(this.operationMode)
    ) {
      this.actionList = this.actionList.filter(
        (item) => item.action === ActionItems.RELOAD
      );
    }
  }

  updateOperationMode(event) {
    this.operationMode = event;
    this.checkDeallocate();
  }

  extendLabAction(event) {
    if (this.actionList[0].action !== ActionItems.EXTEND && event) {
      this.actionList.splice(0, 0, {
        name: 'EXTEND_LAB_DURATION',
        icon: 'fa fa-hourglass-start',
        action: ActionItems.EXTEND,
      });
    }
    if (
      this.actionList[0].action === ActionItems.EXTEND &&
      !event &&
      !this.lab.SkipExtendLimitValidation
    ) {
      this.actionList.splice(0, 1);
    }
  }

  /**
   * collapse sidenav
   */
  closeSidenav() {
    this.isSidenav = !this.isSidenav;
  }

  isCompleteLab(event) {
    this.isHideMenu = event;
  }

  languageChanged(language) {
    this.labSrv.getDetailsInLang(this.aroute.snapshot.params.id, language);
  }
  setView(view: string) {
    this.currentView = view;
    if (view == 'OVERVIEW') {
      this.overViewSelected = true;
      this.labViewSelected = false;
      this.videoViewSelected = false;
    } else if (view == 'LAB') {
      this.overViewSelected = false;
      this.labViewSelected = true;
      this.videoViewSelected = false;
    } else if (view == 'VIDEO') {
      this.overViewSelected = false;
      this.labViewSelected = false;
      this.videoViewSelected = true;
    }
    setTimeout(() => {
      window.dispatchEvent(new Event('resize-tabs'));
    }, 200);
  }

  ngOnDestroy() {
    unsubscribeCollection(this.subscriptions);
  }

  public setCounter(remainingTime: number): void {
    this.counter = remainingTime;
  }

  setEndDate(endDateData) {
    this.endDateData = endDateData;
  }

  public updateLabStatus(labStatus: boolean): void {
    this.updatedLabStatus = labStatus;
  }
}
