<div>
    <ul class="singlequestion-list">
    <!-- <ul class="singlequestion-list" id="main-landmark"> -->
        <li>
            <span class="sql-title">{{ 'exam.questions.questionSection' | translate }}</span>
            <div class="sql-ques case-study-sql-ques">
                <h4 role="heading" aria-level="2" class="" [innerHTML]="question?.question | targetBlank"></h4>
                <span *ngIf="question?.note !== null"><strong>{{ 'exam.questions.note' | translate }}: </strong> <span [innerHTML]="question?.note"></span></span>
            </div>
            <span *ngIf="question?.instruction !== null"><strong>{{ 'exam.questions.instruction' | translate }}:</strong>
        <span [innerHTML]="question?.instruction"></span></span>
            <span class="sql-title">{{ 'exam.questions.answerSection' | translate }}</span>
            <div class="ans-swap-box">
                <!--Left Section-->
                <ul class="swap-left-list-box">
                    <li draggable [dragEnabled]="!item.disabled" *ngFor="let item of options" (click)="selectedLeftOptions(item.key)" [dragData]="item" [ngClass]="{'disableItem':item.disabled, 'selectedLeftSideItem': item.key === selectedLeftValue }">
                        {{item.value}}
                    </li>
                </ul>
                <!--Middle Section-->
                <div class="swap-arrow-box">
                    <div class="sab-round-box" (click)="leftToRightMove()"><a class="icon-cursor"><i
                class="ms-Icon ms-Icon--ChevronRight" title="ChevronRight" aria-hidden="true"></i></a></div>
                    <div class="sab-round-box" (click)="rightToLeftMove()"><a class="icon-cursor"><i
                class="ms-Icon ms-Icon--ChevronLeft" title="ChevronLeft" aria-hidden="true"></i></a></div>
                </div>
                <!--Right Section-->
                <ul class="swap-right-list-box" droppable (onDrop)="onListValueDrop($event)">
                    <ng-container *ngIf="dropOptions.length > miscellaneousConst?.answeredArrayMinLength">
                        <li *ngFor="let item of dropOptions;let i=index;" [ngClass]="{'selectedRightSideItem': item.key === selectedRightValue }" (click)="selectedRightOptions(item.key)">
                            {{item.value}}
                        </li>
                    </ng-container>
                    <ng-container *ngIf="dropOptions.length === miscellaneousConst?.answeredArrayMinLength">
                        <li>{{ 'exam.questions.dropAnswer' | translate }}</li>
                    </ng-container>
                </ul>
                <!--Last Section-->
                <div class="swap-updown-arrow-box">
                    <div class="swap-updown-arrow" (click)="upListValue()"><a class="icon-cursor"><i
                class="ms-Icon ms-Icon--ChevronUp" title="ChevronUp" aria-hidden="true"></i></a></div>
                    <div class="swap-updown-arrow" (click)="downListValue()"><a class="icon-cursor"><i
                class="ms-Icon ms-Icon--ChevronDown" title="ChevronDown" aria-hidden="true"></i></a></div>
                </div>
            </div>
        </li>
    </ul>
</div>