import { Directive, OnInit, ElementRef } from '@angular/core';
import { detect } from 'detect-browser'

@Directive({
  selector: '[appRoleDialog]'
})
export class RoleDialogDirective implements OnInit {

  private browser = detect()
  constructor( private el: ElementRef ) { }

  ngOnInit() {
    if (this.browser.name === "edge" || this.browser.name==="edge-chromium"){
      const ariaLabel = (this.el.nativeElement as HTMLElement).getAttribute('aria-label');
      (this.el.nativeElement as HTMLElement).setAttribute('aria-label', ariaLabel + ' dialog');
      setTimeout(() => {
        (this.el?.nativeElement as HTMLElement).setAttribute('aria-live', 'assertive');
        (this.el?.nativeElement as HTMLElement).setAttribute('role', 'alertdialog')
      }, 5000);
    }
  }
}
