import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { NavigationExtras, Router } from '@angular/router';
import { DeviceDetectorService } from 'ngx-device-detector';
import { Subscription } from 'rxjs';

import { QuestionModel, SectionModel, ExamInstaceDetailsModel } from '../../model/common.model';

import { AppInsightEvents, EventEmitterService, PropertyName, UserSessionService } from '@teams-auth';
import { LocalSessionStorageService } from '../../services/local-session-storage.service';
import { CommonService } from '../../services/common-service.service';
import { TestSectionReviewHelperService } from '../../services/test-section-review/test-section-review-helper.service';

import { LoadingService, miscellaneousConst, formatter, getEntity, changeMinute, isExternalFlow, unsubscribeCollection, getEntityData } from '@utility';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'app-header',
  templateUrl: './header.component.html',
})
export class HeaderComponent implements OnInit, OnDestroy {

  public miscellaneousConst = miscellaneousConst;
  @Input() isQuestionSectionPerfmQuiz: false;
  @Input() isQuestionSectionPerfmTask: false;
  @Input() selectedIndex: number;
  @Input() questionList: QuestionModel[] = [];
  @Input() sectionType: any = '';
  @Input() totalSectionCount: number;
  @Input() leftSectionCount: number;
  @Input() examInstaceDetails: ExamInstaceDetailsModel;
  @Input() timer: number;
  @Input() sectionList: SectionModel[] = [];
  @Input() sectionId: string;
  @Input() examId: string;
  @Input() examInstanceId: string;
  @Input() sectionIndex: number;
  @Input() finishTestConfimDialoge: boolean;
  @Input() regUniqueName: '';
  @Input() progressChangeValue = 0;
  @Input() sideMenuSelectedOption: boolean;
  @Input() tabTitle: string;
  @Output() finishTestConfimDialogeEmit = new EventEmitter();

  examResult: boolean;
  public isTeamapp: string;
  public sideNav: boolean;
  public context: string;
  public isMobile: boolean;
  public dialogClass: string;
  private stopWatch;
  public loaderMsg = [];
  private subscriptions: Subscription[] = [];
  public taskCount: number;
  public progressChangeValueTask;
  public progressChangeValueTask2;

  constructor(
    private router: Router,
    public userSessionService: UserSessionService,
    public deviceDetectorService: DeviceDetectorService,
    private localStorageService: LocalSessionStorageService,
    private commonService: CommonService,
    public loadingService: LoadingService,
    private testSectionReviewHelper: TestSectionReviewHelperService,
    private eventEmitter: EventEmitterService,
  ) { }

  ngOnInit(): void {
    this.isTeamapp = localStorage.getItem('isTeamapp');
    this.context = localStorage.getItem('context');
    this.isMobile = this.deviceDetectorService.isMobile();
    this.userSessionService.sideNavigation$.subscribe((e) => {
      this.sideNav = e;
    })
    this.subscriptions[this.subscriptions.length] = this.commonService.finishTest.subscribe(() => {
      this.finishTestDialoge();
    })
    this.subscriptions[this.subscriptions.length] = this.commonService.taskCount.subscribe((res) => {
      this.taskCount = res;
    })
    this.subscriptions[this.subscriptions.length] = this.commonService.progressValue.subscribe((res) => {
      this.progressChangeValueTask = res;
    })
  }

  /**
   * @description: Change Timer Minutes Display
   */
  public changeMinute(value: any): any {
    const timer = 0
    if (value) {
      return changeMinute(value);
    }
    else {
      return changeMinute(timer);
    }
  }
  public closeConfirmResponse(event): void {
    this.finishTestConfimDialoge = event;
  }
  public finishTestDialoge(): any {
    this.examResult = false;
    this.finishTestConfimDialoge = true;
  }
  dialogeNo() {
    this.finishTestConfimDialoge = false;
    this.examResult = false;
  }
  dialogeYes() {
    this.examResult = true;
    this.finishTestConfimDialoge = false;
    this.dialogClass = 'ts-dialog-fluent ts-dialog-fluent-confirmation';
  }

  public getResult(): void {
    this.loaderMsg = ["common.spinner.testResult"];
    this.loadingService.loadingOn(this.loaderMsg);
    this.examResult = false;
    this.stopWatch = sessionStorage.getItem('dovhandenys');
    const model = {
      elapsedTime: formatter(this.stopWatch),
    };
    this.subscriptions[this.subscriptions.length] = this.commonService
      .endExamInstance(this.examInstanceId, model)
      .subscribe(
        (res) => {
          this.testSectionReviewHelper.navigateExamResult(this.regUniqueName, this.examId, this.examInstanceId);
        },
        (error) => {
          const code = 'Error - 30039';
          this.eventEmitter.debugAlert(code, error.error.message);
        }
      );
  }
  public closeTestResultResponse(event): void {
    this.examResult = event.result;
  }
  /**
   * @description: Move to  Section Review Page
   */
  public getFinishSection(): void {
    const navigationExtras: NavigationExtras = {
      queryParams: { regUniqueName: this.regUniqueName },
    };
    const nextSectionId = this.sectionList.findIndex(
      (i) => i.examSectionUniqueName === this.sectionId
    );
    this.eventEmitter.logEventInAppInsight(
      AppInsightEvents.PRACTICE_TEST_REVIEW_SECTION_SCREEN_VISIT,
      {
        [PropertyName.ENTITY_ID]: this.examInstanceId,
        [PropertyName.URL]: window.location.href,
      }
    );
    if (
      isExternalFlow(this.regUniqueName) &&
      this.sectionList.length > 1
    ) {
      if (localStorage.getItem('context') === 'CloudLabs') {
        if (this.localStorageService.getCourseStorage() === 'false') {
          this.router.navigate(
            [
              `/${getEntity()}/${miscellaneousConst.navigation.practiceTests}/${miscellaneousConst.navigation.tests}/${this.examId}/${miscellaneousConst.navigation.testInstance}/${this.examInstanceId}/${miscellaneousConst.navigation.section}/${this.sectionList[nextSectionId].examSectionUniqueName}/${miscellaneousConst.navigation.review}`,
            ],
            navigationExtras
          );
        }
        else if (this.localStorageService.getCourseStorage() === 'true') {
          this.router.navigate(
            [
              `/${getEntity()}/${getEntityData()}/${miscellaneousConst.navigation.test}/${miscellaneousConst.navigation.practiceTest}/${miscellaneousConst.navigation.tests}/${this.examId}/${miscellaneousConst.navigation.testInstance}/${this.examInstanceId}/${miscellaneousConst.navigation.section}/${this.sectionList[nextSectionId].examSectionUniqueName}/${miscellaneousConst.navigation.review}`,
            ],
            navigationExtras
          );
        }
      } else if (localStorage.getItem('context') === 'PracticeTest') {
        this.router.navigate(
          [
            `/${miscellaneousConst.navigation.tests}/${this.examId}/${miscellaneousConst.navigation.testInstance}/${this.examInstanceId}/${miscellaneousConst.navigation.section}/${this.sectionList[nextSectionId].examSectionUniqueName}/${miscellaneousConst.navigation.review}`,
          ],
          navigationExtras
        );
      }
    } else if (
      this.sectionList.length > 1 &&
      this.sectionIndex !== this.sectionList.length
    ) {
      if (localStorage.getItem('context') === 'CloudLabs') {
        if (this.localStorageService.getCourseStorage() === 'false') {
          this.router.navigate([
            `/${getEntity()}/${miscellaneousConst.navigation.practiceTests}/${miscellaneousConst.navigation.tests}/${this.examId}/${miscellaneousConst.navigation.testInstance}/${this.examInstanceId}/${miscellaneousConst.navigation.section}/${this.sectionList[nextSectionId].examSectionUniqueName}/${miscellaneousConst.navigation.review}`,
          ]);
        }
        else if (this.localStorageService.getCourseStorage() === 'true') {
          this.router.navigate([
            `/${getEntity()}/${getEntityData()}/${miscellaneousConst.navigation.test}/${miscellaneousConst.navigation.practiceTest}/${miscellaneousConst.navigation.tests}/${this.examId}/${miscellaneousConst.navigation.testInstance}/${this.examInstanceId}/${miscellaneousConst.navigation.section}/${this.sectionList[nextSectionId].examSectionUniqueName}/${miscellaneousConst.navigation.review}`,
          ]);
        }
      } else if (localStorage.getItem('context') === 'PracticeTest') {
        this.router.navigate([
          `/${miscellaneousConst.navigation.tests}/${this.examId}/${miscellaneousConst.navigation.testInstance}/${this.examInstanceId}/${miscellaneousConst.navigation.section}/${this.sectionList[nextSectionId].examSectionUniqueName}/${miscellaneousConst.navigation.review}`,
        ]);
      }
    } else {
      if (isExternalFlow(this.regUniqueName)) {
        if (localStorage.getItem('context') === 'PracticeTest') {
          this.router.navigate([
            `/${miscellaneousConst.navigation.tests}/${this.examId}/${miscellaneousConst.navigation.testInstance}/${this.examInstanceId}/${miscellaneousConst.navigation.section}/${this.sectionList[nextSectionId].examSectionUniqueName}/${miscellaneousConst.navigation.review}`,
          ],
            navigationExtras
          );
        }
      }
      else {
        if (localStorage.getItem('context') === 'CloudLabs') {
          if (this.localStorageService.getCourseStorage() === 'false') {
            this.router.navigate([
              `/${getEntity()}/${miscellaneousConst.navigation.practiceTests}/${miscellaneousConst.navigation.tests}/${this.examId}/${miscellaneousConst.navigation.testInstance}/${this.examInstanceId}/${miscellaneousConst.navigation.section}/${this.sectionList[nextSectionId].examSectionUniqueName}/${miscellaneousConst.navigation.review}`,
            ]);
          }
          else if (this.localStorageService.getCourseStorage() === 'true') {
            this.router.navigate([
              `/${getEntity()}/${getEntityData()}/${miscellaneousConst.navigation.test}/${miscellaneousConst.navigation.practiceTest}/${miscellaneousConst.navigation.tests}/${this.examId}/${miscellaneousConst.navigation.testInstance}/${this.examInstanceId}/${miscellaneousConst.navigation.section}/${this.sectionList[nextSectionId].examSectionUniqueName}/${miscellaneousConst.navigation.review}`,
            ]);
          }
        } else if (localStorage.getItem('context') === 'PracticeTest') {
          this.router.navigate([
            `/${miscellaneousConst.navigation.tests}/${this.examId}/${miscellaneousConst.navigation.testInstance}/${this.examInstanceId}/${miscellaneousConst.navigation.section}/${this.sectionList[nextSectionId].examSectionUniqueName}/${miscellaneousConst.navigation.review}`,
          ]);
        }
      }
    }
  }

  public getLanguage(ln): void {
    this.userSessionService.translateLanguage(ln);
  }

  /**
   * @description: Check Header Title to Section Change
   */
  public checkSectionHeaderTitle(): boolean {
    if (this.sectionType ===
      miscellaneousConst.performanceQuizSectionType &&
      this.isQuestionSectionPerfmQuiz) {
      return true;
    } else if (this.sectionType ===
      miscellaneousConst.caseStudySectionType && this.sideMenuSelectedOption) {
      return true;
    } else if (this.sectionType ===
      miscellaneousConst.freeFlowSectionType) {
      return true;
    } else if (this.sectionType ===
      miscellaneousConst.performanceQuizwithVM &&
      this.isQuestionSectionPerfmQuiz) {
      return true;
    } else {
      return false;
    }
  }
  public checkSectionHeaderTask(): boolean {
    if (this.sectionType === miscellaneousConst.performanceTask && this.isQuestionSectionPerfmTask) {
      if (this.taskCount != null) {
        const totalQuestion = 100 / this.questionList.length;
        this.progressChangeValueTask2 = (this.taskCount + 1) * totalQuestion;
      } else {
        this.progressChangeValueTask2 = 0;
      }
      return true
    }
    else {
      return false;
    }
  }

  ngOnDestroy(): void {
    unsubscribeCollection(this.subscriptions);
  }
}
