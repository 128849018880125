<div role="dialog" [attr.aria-label]="title | dialogRole">
    <div class="modal-content" appTrapFocus appRestartDialog appAutoFocusModal appRefocusPreviousEl>  
    <div class="modal-header" >
      <h4 class="modal-title" id="vm-dialog-head"> {{ title }} </h4>
      </div>
      <div class="modal-body" [innerHTML]=" 'LAB_DELETE_WARNING_MESSAGE' | translate">
        
      </div>
      <div class="modal-footer">
        <teams-button aria-hidden="true" [classList]="'btn ts-btn-fluent-primary modal-ok-button'" (buttonClick)="modalRef.hide();">{{data.successButtonText}}</teams-button>
      </div>
    </div>
  </div>