import { Component, OnInit } from '@angular/core';
import { EventService } from './../../../services/event.services';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { Router } from '@angular/router';

@Component({
  selector: 'cloudlabs-duration-dialog',
  templateUrl: './duration-dialog.component.html',
  styleUrls: ['./duration-dialog.component.scss'],
})
export class DurationDialogComponent {
  public title: string;
  public data: any;
  constructor(
    public modalRef: BsModalRef,
    public eventSrv: EventService,
    public route: Router
  ) {}

  public onConfirm(): void {
    // this.route.navigate(['/']);
    this.modalRef.hide();
  }
}
