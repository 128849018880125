/* eslint-disable no-useless-escape */
import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ElementRef,
  HostListener,
  Inject,
  OnDestroy,
  OnInit,
  Renderer2,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import * as moment from 'moment';
import {
  ActionItems,
  ActionListItem,
  AppType,
  CustomerType,
  Deployment,
  Lab,
} from './../../../../modals/lab.model';
import { EventService } from './../../../../services/event.services';
import { ExtendDurationComponent } from './../../../shared/extend-duration/extend-duration.component';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { TranslateService } from '@ngx-translate/core';
import { LabService } from './../../../../services/lab.service';
import { takeUntil } from 'rxjs/operators';
import { Subject, Subscription } from 'rxjs';
import { Title } from '@angular/platform-browser';
import { NotificationService } from '../../../../services/notification.service';
import {
  copyToClipboard,
  emptySelection,
  miscellaneousConst,
  unsubscribeCollection,
  getEntity,
  getEntityData,
  getPopupWidthHeightBasedOnImage,
  checkSingularPlural,
  getTimeZone,
  toggleScreen,
} from '@utility';
import { UtilityService } from '../../../../services/utility.service';
import { MultiWindowService } from 'ngx-multi-window';
import { EventTypeEnum } from '../../../../modals/eventTypeEnum';
import { EnvironmentDeleteDialogComponent } from '../../../shared/environment-delete-dialog/environment-delete-dialog.component';
import { EventEmitterService } from '@teams-auth';
import { ExtendCreditComponent } from '../../../shared/extend-credit/extend-credit.component';

export const SCREEN_WIDTH = 1080;
@Component({
  selector: 'cloudlabs-labguide',
  templateUrl: './labguide.component.html',
  styleUrls: ['./labguide.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class LabguideComponent implements OnInit, AfterViewInit, OnDestroy {
  private notifier = new Subject();
  public lab: Lab;
  public guideData: Deployment;
  public vCode: string;
  public id: string;
  public index: any;
  public minutes: number;
  public hours: number;
  public day: number;
  public countDownTimer: any;
  public stopTimer: boolean;
  public isComplete = false;
  public enableHeader = false;
  public enableFooter = false;
  public enableduration = false;
  public onpageLoad = true;
  public modalRef: BsModalRef;
  public initialDeployment: any;
  public operationModes: string;
  // public minimumRemainingTimeForLabExtension = 25;
  public minimumRemainingTimeForLabExtension;
  public sidebarHeaderHeight = 0;
  public labTitle = '';
  public lang: string;
  public canExtendFurther = true;
  public internalId = '';
  public splitKey: string;
  recipients: string[] = [];
  private subscriptions: Subscription[] = [];
  private labPolling;
  isMylearning = false;

  showImagePopup = false;
  imagePopupElement: string;
  imagePopupStyle: { width: string; height: string };
  isSplitWindow = false;
  public counter = 0;

  @ViewChild('sidebarHeader') sidebarHeader: ElementRef;
  @ViewChild('docGuide') el: ElementRef;
  public workspaceURL = '';
  public modelShow = false;
  public modalData: any;
  public actionList: Array<ActionListItem> = [];
  public endDateData = null;
  public isMobile = false;
  public showResourcesTab = true;
  public toggleFullScreenforCLPortal: boolean;
  public showFullScreen: boolean;
  public templateValidation: any;
  public isLabValidation = false;

  constructor(
    private aroute: ActivatedRoute,
    private modalService: BsModalService,
    private translateSrv: TranslateService,
    private notificationService: NotificationService,
    private eventSrv: EventService,
    private labSrv: LabService,
    private renderer: Renderer2,
    private titleService: Title,
    private router: Router,
    private util: UtilityService,
    private multiWindowService: MultiWindowService,
    private cdr: ChangeDetectorRef,
    private eventEmitter: EventEmitterService,
    @Inject('environment') public environment
  ) {
    if (localStorage.getItem('context') === miscellaneousConst.cloudlab) {
      this.isMylearning = true;
    }
    // force route reload whenever params change;
    // this.router.routeReuseStrategy.shouldReuseRoute = () => false;
    this.lab = this.aroute.snapshot.data.lab;
    this.minimumRemainingTimeForLabExtension =
      this.lab.AllowedExtendDurationByAttendee ||
      this.environment.extendingLabDurationInMinutes;
    this.lab.Title = this.lab.DisplayName
      ? this.lab.DisplayName
      : this.lab.Title;
    if (!this.isMylearning) {
      this.titleService.setTitle('CloudLabs On Demand lab');
    } else {
      this.titleService.setTitle(
        this.lab.Title +
          ' | ' +
          this.translateSrv.instant('HOME.CLOUDLABS_PORTAL')
      );
    }
    this.eventSrv.setThemeId(this.lab.ThemeId);
    this.guideData = this.aroute.snapshot.data.environment;
    this.vCode = this.aroute.snapshot.params.voucherCode;
    this.id = this.aroute.snapshot.params.id;
    this.index = this.aroute.snapshot.params.index;
    this.internalId = this.aroute.snapshot.queryParams?.intId;
    // set the split window key
    this.splitKey =
      this.internalId !== '' && this.internalId !== undefined
        ? `windowsplit-${this.vCode}-${this.internalId}`
        : `windowsplit-${this.vCode}`;

    this.toggleFullScreenforCLPortal = false;
  }

  /**
   * onClose window
   * @param event
   */
  @HostListener('window:beforeunload', ['$event'])
  onWindowClose(event: any): void {
    localStorage.removeItem(this.splitKey);
    parent.postMessage('true', location.origin);
  }

  @HostListener('document:click', ['$event'])
  async handleClick($event) {
    // if ($event.target.id === 'onconfirm-vm') {
    // parent.postMessage('vmstatus', location.origin);
    // }
    if ($event.target.id === 'pasteInsideVM') {
      ($event.target as HTMLElement).style.pointerEvents = 'none';
      emptySelection();
      const brRegex = /<br\s*[\/]?>/gi;
      const copyData =
        $event.target.innerText || $event.target.firstChild.innerText;
      const txtdata = copyData.replace(brRegex, '\r\n').trim();
      this.sendMessageToRecipients(EventTypeEnum.PasteInVM, txtdata);
      setTimeout(() => {
        ($event.target as HTMLElement).style.pointerEvents = 'unset';
      }, 1000);
    } else if (
      $event.target?.id === 'copyelement' ||
      ($event.target as HTMLElement)?.classList.contains('copyelement')
    ) {
      emptySelection();
      const brRegex = /<br\s*[\/]?>/gi;
      const copyData =
        $event.target.innerText || $event.target.firstChild.innerText;
      const data = copyData.replace(brRegex, '\r\n');
      parent.postMessage(data, location.origin);
      await copyToClipboard(data);
      this.notificationService.success(
        this.translateSrv.instant('MESSAGE_COPIED'),
        null
      );
    }
    if ($event.target?.classList[0] === 'copyelmnt') {
      const text = await navigator.clipboard.readText();
      parent.postMessage(text, location.origin);
    }
    if (($event.target as HTMLElement)?.classList.contains('img-click')) {
      this.processImageBeforePopup($event);
    }
  }

  @HostListener('copy', ['$event'])
  async onCopy(e: ClipboardEvent) {
    const successful = document.execCommand('copy');
    if (successful) {
      const texts: any = await navigator.clipboard.readText();
      parent.postMessage(texts, location.origin);
    }
  }

  @HostListener('window:keydown', ['$event'])
  async onkeyPress($event: any) {
    if (($event.ctrlKey || $event.metaKey) && $event.keyCode == 67) {
      const successful = document.execCommand('copy');
      if (successful) {
        const text = await navigator.clipboard.readText();
        parent.postMessage(text, location.origin);
      }
    }
    if ($event.key == 'Enter' && $event.keyCode == 13) {
      if (
        $event.target?.id === 'copyelement' ||
        ($event.target as HTMLElement)?.classList.contains('copyelement')
      ) {
        emptySelection();
        const brRegex = /<br\s*[\/]?>/gi;
        const copyData =
          $event.target.innerText || $event.target.firstChild.innerText;
        const data = copyData.replace(brRegex, '\r\n');
        parent.postMessage(data, location.origin);
        await copyToClipboard(data);
        this.notificationService.success(
          this.translateSrv.instant('MESSAGE_COPIED'),
          null
        );
      }
      if ($event.target?.classList[0] === 'copyelmnt') {
        const text = await navigator.clipboard.readText();
        parent.postMessage(text, location.origin);
      }
      if (($event.target as HTMLElement)?.classList.contains('img-click')) {
        this.processImageBeforePopup($event);
      }
    }
  }

  /**
   * On resize window
   * @param event
   */
  @HostListener('window:resize', ['$event'])
  onResize(event?) {
    this.isMobile = event.target.innerWidth <= SCREEN_WIDTH;
    setTimeout(() => {
      this.calculateHeight();
    }, 500);
  }

  async ngOnInit() {
    this.isMobile = window.innerWidth <= SCREEN_WIDTH;
    if (
      localStorage.getItem(this.splitKey) ||
      localStorage.getItem(`windowsplit-${this.vCode}`)
    ) {
      this.isSplitWindow = true;
      toggleScreen(true);
      this.eventSrv.emmitToggleHeader();
    }
    this.setRecelientsID();
    this.subscriptions[this.subscriptions.length] =
      this.eventSrv.toggleHeader.subscribe(() => {
        this.enableHeader = !this.enableHeader;
        this.enableFooter = !this.enableFooter;
        this.sendMessageToRecipients(
          EventTypeEnum.ToggleHeader,
          this.enableHeader
        );
        this.cdr.detectChanges();
        if (this.enableFooter === true) {
          setTimeout(() => {
            this.calculateHeight();
          }, 1000);
        } else {
          this.calculateHeight();
        }
        this.toggleFullScreenforCLPortal = !this.toggleFullScreenforCLPortal;
      });
    this.lang = localStorage.getItem('language') || 'en';
    this.subscriptions[this.subscriptions.length] =
      this.eventSrv.onExtendduration.subscribe((res: any) => {
        this.subscriptions[this.subscriptions.length] = this.labSrv
          .getLabEnvironment(this.id, this.vCode)
          .subscribe((res: any) => {
            clearTimeout(this.countDownTimer);
            if (
              this.actionList[0].action === ActionItems.EXTEND &&
              !this.lab.SkipExtendLimitValidation
            ) {
              this.enableduration = false;
              this.actionList.splice(0, 1);
            }
            setTimeout(() => {
              this.findRemainingDuration(res.StartTime, res.Duration);
            }, 6000);
          });
      });

    this.eventSrv.onExtendDurationCancel
      .pipe(takeUntil(this.notifier))
      .subscribe(() => {
        this.updateLatestLabEnvironment();
      });

    this.eventSrv.onExtendDurationError
      .pipe(takeUntil(this.notifier))
      .subscribe((errorObject) => {
        this.canExtendFurther = false;
        if (
          this.actionList[0].action === ActionItems.EXTEND &&
          !this.lab.SkipExtendLimitValidation
        ) {
          this.actionList.splice(0, 1);
        }
        if (
          errorObject?.error?.ErrorDetail.includes(
            'You can extend this lab only upto'
          )
        ) {
          this.showExtensionErrorPopUp();
        }
        this.updateLatestLabEnvironment();
      });

    const cancelduration = sessionStorage.getItem('cancelduration');
    if (cancelduration) {
      sessionStorage.removeItem('cancelduration');
    }
    if (this.lab?.EnableCustomLabDetailsPage === false) {
      this.enableHeader = true;
      this.enableFooter = true;
    }
    if (this.guideData === null || this.guideData.DeploymentStatus == null) {
      this.isComplete = true;
    }
    this.findRemainingDuration(
      this.guideData.StartTime,
      this.guideData.Duration
    );
    this.labSrv.labDetailsInES.subscribe((labDetails) => {
      if (labDetails) {
        this.lab = labDetails;
      }
    });
    setTimeout(() => {
      this.onpageLoad = false;
      this.calculateHeight();
      this.cdr.detectChanges();
    }, 6000);

    this.labSrv.guideTitle.pipe(takeUntil(this.notifier)).subscribe((title) => {
      if (title !== '') {
        this.labTitle = title;
      }
    });
    this.eventSrv.languageChanged.subscribe((lang) => {
      this.changedLanguage(lang);
    });
    const key = `toggleScreen_${this.vCode}`;
    if (localStorage.getItem(key)) {
      this.enableHeader = JSON.parse(localStorage.getItem(key));
      this.enableFooter = JSON.parse(localStorage.getItem(key));
    }
    this.initialDeployment = await this.labSrv
      .initialDeployment(
        this.lab.InternalId,
        this.vCode === 'anonymous' ? null : this.vCode,
        this.lab.UserSelectedRegion
      )
      .toPromise();
    this.showResourcesTab = this.initialDeployment.ShowResourcesTab;
    if (this.environment.appType === AppType.ATTENDEE) {
      this.showFullScreen = Boolean(
        this.initialDeployment.DeploymentStatus === 'Succeeded'
      );
    }
    //check lab idle
    if (
      this.initialDeployment.EnableAttendeeEndTime &&
      this.initialDeployment.EndDate != null
    ) {
      if (this.initialDeployment.EndDate.includes('Z')) {
        this.initialDeployment.EndDate = this.initialDeployment.EndDate.slice(
          0,
          -1
        );
      }
      const checkDate = new Date(this.initialDeployment.EndDate);
      const endDate = moment(
        new Date(
          checkDate.getTime() - checkDate.getTimezoneOffset() * 60 * 1000
        )
      ).format('YYYY-MM-DD hh:mm:ss A Z');
      this.endDateData = { endDate: endDate, timeZone: getTimeZone() };
    }
    this.createAttendeeActivityLog();
    this.subscriptions[this.subscriptions.length] = this.multiWindowService
      .onMessage()
      .subscribe((message) => {
        if (message.event === EventTypeEnum.UpdateURL) {
          if (message.data) {
            this.labSrv.updateInternalId.next(message.data);
          }
        }
      });
  }

  ngAfterViewInit(): void {
    this.util.switchLanguageToDefault(this.lab);
    if (
      (this.lab.UserLabExperienceTypes.length === 0 ||
        (this.lab.UserLabExperienceTypes.length === 1 &&
          this.lab.UserLabExperienceTypes[0].Id === 1)) &&
      (this.guideData.CloudPlatformId === 1 ||
        this.guideData.CloudPlatformId === 2) &&
      this.environment.customer === CustomerType.DATABRICKS
    ) {
      this.workspaceURL = `<a href="#/odl/environment/${this.id}/${
        this.vCode
      }" target="_blank">${this.translateSrv.instant(
        'CLICK_TO_AUTO_SIGN'
      )}<i class="fa fa-external-link ext-icon" aria-hidden="true"></i></a>`;
    }
    this.createActionList();
  }

  /** Extend lab duration */
  extendLabDuration() {
    this.modalRef = this.modalService.show(ExtendDurationComponent, {
      initialState: {
        title: this.translateSrv.instant('EXTEND_LAB_DURATION'),
        data: {
          description:
            this.translateSrv.instant('EXTEND_LAB_DURATION_DESCRIPTION') +
            ' ' +
            this.minimumRemainingTimeForLabExtension +
            ' ' +
            this.translateSrv.instant('MINUTES', {
              singularPlural: checkSingularPlural(
                this.minimumRemainingTimeForLabExtension
              ),
            }),
          successButtonText: this.translateSrv.instant('OK'),
          failureButtonText: this.translateSrv.instant('CANCEL'),
          v_code: this.vCode,
          duration: this.minimumRemainingTimeForLabExtension,
          approvalRequired:this.lab.DisableDurationAutoApprove,
          unit:
          this.minimumRemainingTimeForLabExtension +
          ' ' +
          this.translateSrv.instant('MINUTES', {
            singularPlural: checkSingularPlural(
              this.minimumRemainingTimeForLabExtension
            ),
          }),
          maxUnit:
          this.lab.MaxUserDurationExtension +
          ' ' +
          this.translateSrv.instant('MINUTES', {
            singularPlural: checkSingularPlural(
              this.lab.MaxUserDurationExtension
            ),
          }),
        },
      },
    });
  }

  goBack() {
    if (
      getEntityData() == miscellaneousConst.navigation.courses ||
      getEntityData() == miscellaneousConst.navigation.learningPath
    ) {
      const vcode = this.aroute.snapshot.params.clVcode;
      const id = this.aroute.snapshot.params.clid;
      this.router.navigate([
        getEntity() + `/` + getEntityData() + `/details/${id}/${vcode}`,
      ]);
    } else {
      const vcode = this.aroute.snapshot.params.voucherCode;
      const id = this.aroute.snapshot.params.id;
      this.router.navigate([
        getEntity() + `/` + getEntityData() + `/details/${id}/${vcode}`,
      ]);
    }
  }

  deleteLab() {
    this.joinWIndow();
  }

  public closeImagePopup(event): void {
    this.showImagePopup = event;
  }

  private processImageBeforePopup($event) {
    if ($event.shiftKey) {
      // If Shift key is pressed, open the image in a new tab
      const imageSrc = ($event.target as HTMLElement).getAttribute('src');
      if (imageSrc) {
        const newTabAnchor = document.createElement('a');
        newTabAnchor.href = imageSrc;
        newTabAnchor.target = '_blank';
        newTabAnchor.click();
      }
    } else {
      this.openImagePopup($event.target as HTMLElement);
    }
  }

  private openImagePopup(imageElement: HTMLElement) {
    const image = imageElement.outerHTML.replace(/(width|height)="[^"]*"/g, '');
    this.imagePopupElement = image;

    // Set the calculated width and height as inline styles for the popup
    const calculatedWidthHeight = getPopupWidthHeightBasedOnImage(imageElement);
    this.imagePopupStyle = {
      width: `${calculatedWidthHeight.width}px`,
      height: `${calculatedWidthHeight.height}px`,
    };

    this.showImagePopup = true;
  }

  async completeTheLab(event: string) {
    this.operationModes = 'Deallocating';
    this.isComplete = true;
    try {
      const res = await this.labSrv.completeTestDrive(event).toPromise();
      if (res === true) {
        this.operationModes = 'Deallocated';
        this.createActionList();
      } else {
        this.isComplete = false;
        this.operationModes = 'Initiation';
      }
    } catch (e) {
      const code = 'Error - 70010';
      this.eventEmitter.debugAlert(code, 'Error Deallocating VM');
      this.isComplete = false;
      this.operationModes = 'Initiation';
    }
  }

  updateLatestLabEnvironment() {
    this.labSrv
      .getLabEnvironment(this.id, this.vCode)
      .pipe(takeUntil(this.notifier))
      .subscribe(
        (res: any) => {
          clearTimeout(this.countDownTimer);
          this.enableduration = true;
          if (this.actionList[0].action !== ActionItems.EXTEND) {
            this.actionList.splice(0, 0, {
              name: 'EXTEND_LAB_DURATION',
              icon: 'fa fa-hourglass-start',
              action: ActionItems.EXTEND,
            });
          }
          setTimeout(() => {
            this.findRemainingDuration(res.StartTime, res.Duration);
          }, 6000);
        },
        (error) => {
          const code = 'Error - 50020';
          this.eventEmitter.debugAlert(code, error);
        }
      );
  }

  /**
   * Join window
   */
  joinWIndow(): void {
    parent.postMessage('true', location.origin);
  }

  /**
   * get remaining lab durtion
   * @param startTime
   * @param actualDuration
   */
  findRemainingDuration(startTime, actualDuration) {
    const start = moment.utc(startTime, 'YYYY-MM-DD HH:mm:ss');
    const current = moment.utc(new Date(), 'HH:mm');
    const duration = moment.duration(current.diff(start));
    actualDuration = moment.duration(actualDuration, 'minutes');
    const remainingDuration = actualDuration.subtract(duration);
    this.counter = parseInt(remainingDuration.asSeconds());
    this.day = parseInt(remainingDuration.asDays());
    this.hours = remainingDuration.get('hours');
    if (this.day > 0) {
      const daysHours = this.day * 24;
      this.hours = daysHours + this.hours;
    }
    this.minutes =
      remainingDuration.get('minutes') > 0
        ? remainingDuration.get('minutes')
        : 0;
    if ((this.minutes == 0 && this.hours == 0) || this.minutes < 0) {
      if (this.index !== 1) {
        parent.postMessage('true', location.origin);
      }
      sessionStorage.removeItem('cancelduration');
      this.stopTimerFunc();
      this.isComplete = true;
      this.operationModes = 'Deployed';
      clearTimeout(this.countDownTimer);
    } else {
      this.operationModes = 'Initiation';
      this.countdown();
    }
  }

  countdown() {
    if (this.countDownTimer) {
      clearTimeout(this.countDownTimer);
    }
    this.countDownTimer = setTimeout(() => {
      const cancelduration = sessionStorage.getItem('cancelduration');
      this.minutes = this.minutes > 0 ? this.minutes - 1 : 0;
      if (this.minutes < 0) {
        this.minutes = 59;
        this.hours = this.hours > 0 ? this.hours - 1 : 0;
        if (this.hours === 0 && this.minutes < 0) {
          this.stopTimerFunc();
        }
      }
      if ((this.minutes == 0 && this.hours == 0) || this.minutes < 0) {
        this.minutes = 0;
        this.hours = 0;
        if (this.index !== 1) {
          parent.postMessage('true', location.origin);
        }
        sessionStorage.removeItem('cancelduration');
        this.stopTimerFunc();
        this.actionList = this.actionList.filter(
          (item) => item.action === ActionItems.RELOAD
        );
        this.isComplete = true;
        this.operationModes = 'Deallocated';
        this.modelShow = false;
      }
      const canExtendLab =
        this.lab.AllowUserToExtendLab &&
        !this.isComplete &&
        this.canExtendFurther;
      const meetsMinimumTimeForExtension =
        this.hours === 0 &&
        this.minutes < this.minimumRemainingTimeForLabExtension;
      const shouldExtend = canExtendLab && meetsMinimumTimeForExtension;

      if (!this.lab.SkipExtendLimitValidation) {
        if (cancelduration === null && shouldExtend) {
          this.enableAndExtendLab(shouldExtend && cancelduration === null);
        }
      } else {
        if (canExtendLab) {
          this.enableAndExtendLab(meetsMinimumTimeForExtension && cancelduration === null);
        }
      }
      this.countdown();
    }, 60000);
    if (
      this.initialDeployment?.AllowEnvironmentDeleteNotification === true &&
      this.hours === 0 &&
      this.minutes === 30 &&
      this.aroute.snapshot.params.voucherCode
    ) {
      this.modalRef = this.modalService.show(EnvironmentDeleteDialogComponent, {
        initialState: {
          title: this.translateSrv.instant('ALERT'),
          data: {
            description: this.translateSrv.instant(
              'LAB_DELETE_WARNING_MESSAGE'
            ),
            successButtonText: this.translateSrv.instant('OK'),
          },
        },
      });
    }
    this.cdr.detectChanges();
  }

  /**
   * stop timer interval
   */
  stopTimerFunc() {
    // if (this.stopTimer) {
    clearTimeout(this.countDownTimer);
    // }
  }

  private enableAndExtendLab(shouldExtendFurther) {
    this.enableduration = true;
    if (this.actionList[0].action !== ActionItems.EXTEND) {
      this.actionList.splice(0, 0, {
        name: 'EXTEND_LAB_DURATION',
        icon: 'fa fa-hourglass-start',
        action: ActionItems.EXTEND,
      });
    }
    this.eventSrv.onCloaseDuration();
    if (shouldExtendFurther) {
      this.extendLabDuration();
    }
  }

  changedLanguage(language) {
    this.lang = language;
    this.labSrv.getDetailsInLang(this.aroute.snapshot.params.id, this.lang);
  }

  calculateHeight() {
    if (this.sidebarHeader && this.sidebarHeader.nativeElement) {
      this.sidebarHeaderHeight = this.sidebarHeader.nativeElement.offsetHeight;
      const footerElm = document.getElementById('footer');
      let footerHeight = footerElm ? footerElm.offsetHeight : 0;
      if (this.enableHeader) {
        footerHeight += 60;
        this.sidebarHeaderHeight += 28;
      }
      if (!this.enableFooter && this.environment.appType === AppType.LAB) {
        footerHeight = 60;
      }
      this.renderer.setProperty(
        this.el.nativeElement,
        'style',
        `--sidebar-header: ${this.sidebarHeaderHeight}px; --footer-height: ${footerHeight}px`
      );
    } else if (this.isSplitWindow) {
      this.renderer.setProperty(
        this.el.nativeElement,
        'style',
        `--sidebar-header: ${28}px; --footer-height: ${30}px`
      );
    }
    this.cdr.detectChanges();
  }

  private setRecelientsID() {
    const windows = this.multiWindowService.getKnownWindows();
    for (const i of windows) {
      if (!i.self) {
        this.recipients.push(i.id);
      }
    }
  }

  private sendMessageToRecipients(eventType: EventTypeEnum, message: any) {
    for (const id of this.recipients) {
      this.multiWindowService
        .sendMessage(id, eventType, message)
        .subscribe((messageId) => {
          //
        });
    }
  }
  createAttendeeActivityLog() {
    this.labPolling = setInterval(() => {
      try {
        this.subscriptions[this.subscriptions.length] = this.labSrv
          .getAttendeeLab(this.vCode)
          .subscribe(
            (res) => {
              if (
                res.DeploymentStatus == 'Deleted' ||
                res.DeploymentStatus == 'Deleting'
              ) {
                this.isComplete = true;
                this.operationModes = 'Deallocated';
              }
              if(this.day ===0 && this.hours === 0 && this.minutes <= 2){
                this.eventSrv.extendduration();
              }
            },
            (error) => {
              const code = 'Error - 50021';
              this.eventEmitter.debugAlert(code, error);
            }
          );
      } catch (e) {
        const code = 'Error - 70011';
        this.eventEmitter.debugAlert(code, 'Error Deleting VM');
      }
    }, 60000);
  }

  actionClickEvent(action) {
    switch (action) {
      case ActionItems.DEALLOCATE: {
        this.showDeallocateLabConfirmationPopUp();
        break;
      }
      case ActionItems.RELOAD: {
        location.reload();
        break;
      }
      case ActionItems.EXTEND: {
        this.extendLabDuration();
        break;
      }
      case ActionItems.EXT_CREDIT: {
        this.extendCredit();
        break;
      }
    }
  }

  loadValidations() {
    if (this.isLabValidation) {
      this.eventSrv.onLoadValidation.next();
    }
  }

  private async showDeallocateLabConfirmationPopUp() {
    if (
      this.lab.EnableLabValidation === true &&
      !this.lab?.DisableValidationTab &&
      !this.lab?.IsSupportQuest
    ) {
      const data: any = await this.labSrv
        .getValidationStatus(this.vCode)
        .toPromise();
      this.templateValidation = data.length > 0 ? data[0] : null;
      this.isLabValidation =
        this.templateValidation?.TemplateValidationStatus?.length > 0
          ? this.templateValidation.TemplateValidationStatus.some(
              (item) => item.ConsolidatedStatus !== 'Succeeded'
            )
          : false;
      if (this.isLabValidation) {
        this.modalData = {
          title: 'DANGER',
          description: 'DELETE_VALIDATION_DESCRIPTION',
          type: 'DEALLOCATE_LAB',
          showCancel: true,
        };
      } else {
        this.showDeleteLabPopup();
      }
    } else {
      this.showDeleteLabPopup();
    }
    if (!this.modelShow) {
      this.modelShow = true;
    }
  }

  showDeleteLabPopup() {
    this.modalData = {
      title: 'DANGER',
      description: 'MESSAGE_DEALLOCATE',
      type: 'DEALLOCATE_LAB',
      showCancel: true,
    };
  }

  private showExtensionErrorPopUp() {
    this.modalData = {
      title: 'EXT_NOT_ALLOWED',
      description: 'REACHOUT_TO_SUPPORT',
      type: 'EXTEND_LAB_ERROR',
      showCancel: false,
    };
    this.modelShow = true;
  }

  private createActionList() {
    if (this.isSplitWindow) {
      this.actionList = [];
      return;
    }
    this.actionList = [
      {
        name: 'RELOAD_INSTANCE',
        icon: 'fa fa-refresh',
        action: ActionItems.RELOAD,
      },
    ];
    if (
      this.lab.AllowUserToDeleteODL &&
      !['Deallocated', 'Deployed', 'Deallocating'].includes(this.operationModes)
    ) {
      this.actionList.push({
        name: 'DELETE_LAB',
        icon: 'fa fa-trash',
        action: ActionItems.DEALLOCATE,
      });
    }
    if (
      this.lab.AllowUserToExtendCredit &&
      !['Deallocated', 'Deployed', 'Deallocating'].includes(this.operationModes)
    ) {
      this.actionList.push({
        name: 'EXT_CREDIT',
        icon: 'fas fa-dollar-sign',
        action: ActionItems.EXT_CREDIT,
      });
    }
  }

  public deallocateLab() {
    this.modelShow = false;
    this.completeTheLab(this.initialDeployment.InternalId);
  }

  private extendCredit() {
    this.modalRef = this.modalService.show(ExtendCreditComponent, {
      initialState: {
        title: this.translateSrv.instant('EXT_CREDIT_TITLE'),
        data: {
          successButtonText: this.translateSrv.instant('OK'),
          failureButtonText: this.translateSrv.instant('CANCEL'),
          v_code: this.vCode,
          approvalRequired:this.lab.DisableCreditAutoApprove,
          unit:`${this.lab.AllowedCreditLimit}$`,
          maxUnit:`${this.lab.MaxCreditLimit}$`,
        },
      },
    });
  }

  ngOnDestroy() {
    this.notifier.next();
    this.notifier.complete();
    clearInterval(this.labPolling);
    if (this.countDownTimer) {
      clearTimeout(this.countDownTimer);
    }
    if (this.modalRef) {
      this.modalRef.hide();
    }
    unsubscribeCollection(this.subscriptions);
  }
}
