import { Injectable } from '@angular/core';
import { NavigationExtras, Router } from '@angular/router';

import { getEntityData, miscellaneousConst } from '@utility';

import { LocalSessionStorageService } from '../../services/local-session-storage.service';

@Injectable({
  providedIn: 'root'
})
export class TestDetailsHelperService {

  constructor(private router: Router, private localStorageService: LocalSessionStorageService) { }
  /**
   * @description: Find InProgress Question
   */
  public findInProgressQuestion(
    regUniqueName, userHasActiveExam, createdExamInstaceDetails,
    examId, examInstanceUniqueName, inProgressSectionofInstance, questionId): void {
    if (
      regUniqueName !== undefined &&
      regUniqueName !== ''
    ) {
      let examUniqueName = '';
      if (
        userHasActiveExam.examInstanceUniqueName !== undefined &&
        userHasActiveExam.examInstanceUniqueName !== null
      ) {
        examUniqueName = userHasActiveExam
          .examInstanceUniqueName;
      }
      else if (
        createdExamInstaceDetails.examInstanceUniqueName !==
        undefined &&
        createdExamInstaceDetails.examInstanceUniqueName !== null
      ) {
        examUniqueName = createdExamInstaceDetails.examInstanceUniqueName;
      }
      const navigationExtras: NavigationExtras = {
        queryParams: { regUniqueName: regUniqueName },
      };
      if (localStorage.getItem('context') === 'CloudLabs') {
        if (this.localStorageService.getCourseStorage() === 'false') {
          this.router.navigate(
            [
              `/${miscellaneousConst.navigation.myLearning}/${miscellaneousConst.navigation.practiceTests}/${miscellaneousConst.navigation.tests}/${examId}/${miscellaneousConst.navigation.testInstance}/${examUniqueName}/${miscellaneousConst.navigation.sections}/${inProgressSectionofInstance.examSectionUniqueName}/${miscellaneousConst.navigation.questions}/${questionId}`,
            ],
            navigationExtras
          );
        } else if (this.localStorageService.getCourseStorage() === 'true') {
          this.router.navigate(
            [
              `/${miscellaneousConst.navigation.myLearning}/${getEntityData()}/${miscellaneousConst.navigation.test}/${miscellaneousConst.navigation.practiceTest}/${miscellaneousConst.navigation.tests}/${examId}/${miscellaneousConst.navigation.testInstance}/${examUniqueName}/${miscellaneousConst.navigation.sections}/${inProgressSectionofInstance.examSectionUniqueName}/${miscellaneousConst.navigation.questions}/${questionId}`,
            ],
            navigationExtras
          );
        }
      }  else if (localStorage.getItem('context') === 'PracticeTest') { 
        this.router.navigate(
          [
            `/${miscellaneousConst.navigation.tests}/${examId}/${miscellaneousConst.navigation.testInstance}/${examUniqueName}/${miscellaneousConst.navigation.sections}/${inProgressSectionofInstance.examSectionUniqueName}/${miscellaneousConst.navigation.questions}/${questionId}`,
          ],
          navigationExtras
        );
      }
    } else {
      if (localStorage.getItem('context') === 'CloudLabs') {
        if (this.localStorageService.getCourseStorage() === 'false') {
          this.router.navigate(
            [
              `/${miscellaneousConst.navigation.myLearning}/${miscellaneousConst.navigation.practiceTests}/${miscellaneousConst.navigation.tests}/${examId}/${miscellaneousConst.navigation.testInstance}/${examInstanceUniqueName}/${miscellaneousConst.navigation.sections}/${inProgressSectionofInstance.examSectionUniqueName}/${miscellaneousConst.navigation.questions}/${questionId}`,
            ]  );
        } else if (this.localStorageService.getCourseStorage() === 'true') {
          this.router.navigate(
            [
              `/${miscellaneousConst.navigation.myLearning}/${getEntityData()}/${miscellaneousConst.navigation.test}/${miscellaneousConst.navigation.practiceTest}/${miscellaneousConst.navigation.tests}/${examId}/${miscellaneousConst.navigation.testInstance}/${examInstanceUniqueName}/${miscellaneousConst.navigation.sections}/${inProgressSectionofInstance.examSectionUniqueName}/${miscellaneousConst.navigation.questions}/${questionId}`,
            ]  );
        }
      }  else if (localStorage.getItem('context') === 'PracticeTest') { 
        this.router.navigate(
          [
            `/${miscellaneousConst.navigation.tests}/${examId}/${miscellaneousConst.navigation.testInstance}/${examInstanceUniqueName}/${miscellaneousConst.navigation.sections}/${inProgressSectionofInstance.examSectionUniqueName}/${miscellaneousConst.navigation.questions}/${questionId}`,
          ]);
      }
    }
  }
  /**
   * @description: First Section Of First Question
   */
  public firstSectionOfFirstQuestion(regUniqueName, userHasActiveExam, createdExamInstaceDetails): any {
    let examUniqueName = '';
    if (
      userHasActiveExam.examInstanceUniqueName !== undefined &&
      userHasActiveExam.examInstanceUniqueName !== null
    ) {
      examUniqueName = userHasActiveExam.examInstanceUniqueName;
    } else if (
      createdExamInstaceDetails.examInstanceUniqueName !==
      undefined &&
      createdExamInstaceDetails.examInstanceUniqueName !== null
    ) {
      examUniqueName = createdExamInstaceDetails.examInstanceUniqueName;
    }
    const navigationExtras: NavigationExtras = {
      queryParams: { regUniqueName: regUniqueName },
    };
    return { navigationExtrasValue: navigationExtras, examUniqueNameValue: examUniqueName };
  }
}
