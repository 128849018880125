import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ActivatedRoute } from '@angular/router';

import { API } from '../../consts/api.const';
import { APIProd } from '../../consts/api-prod.const';

import { BaseServiceService } from '../../services/base-service.service';

import {
  apiUrl
} from '@utility';
import { UserSessionService } from '@teams-auth';

@Injectable({
  providedIn: 'root',
})
export class TestResultService {

  regUniqueName = '';
  private API = this.userSessionService.isProdUser ? APIProd : API

  constructor(
    private baseService: BaseServiceService, private route: ActivatedRoute,
    public userSessionService: UserSessionService,
  ) {
    this.route.queryParams.subscribe(params => {
      this.regUniqueName = params.regUniqueName;
    });
  }
  public getExamResult(examInstances): Observable<any> {
    let examResultUrl = `${this.API.testData.getExamResult}`.replace('{examInstance_GUID}', examInstances);
    examResultUrl = apiUrl(this.regUniqueName, examResultUrl);
    return this.baseService.getWithText(examResultUrl);
  }
  public sendFeedback(examId, examInstanceId, model): Observable<any> {
    let sendFeedbackUrl = `${this.API.testData.sendFeedback}`.replace('{exam_guid}', examId)
      .replace('{instance_unique_name}', examInstanceId);
    sendFeedbackUrl = apiUrl(this.regUniqueName, sendFeedbackUrl);
    return this.baseService.put(sendFeedbackUrl, model);
  }
}
