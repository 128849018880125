import { Component, EventEmitter, Input, Output, OnChanges, ChangeDetectorRef, ViewChild, AfterViewInit } from '@angular/core';

@Component({
  selector: 'cloudlabs-version-dropdown',
  templateUrl: './version-dropdown.component.html',
  styleUrls: ['./version-dropdown.component.scss']
})
export class VersionDropdownComponent implements OnChanges, AfterViewInit {
  @Input() optionArray;
  @Input() selectedValue;
  @Input() width;
  @Input() ddlSource;
  @Input() public question;
  @Output() selectedValueEmit = new EventEmitter();
  @ViewChild('btnDropdown') btnDropdown ;
  private abc;
  constructor(private cdr: ChangeDetectorRef) { 
    //
  }
  ngOnChanges(): void {
    this.selectedValue = this.question?.versionDisplayText
    if(this.btnDropdown !== undefined) {
      this.btnDropdown.nativeElement.innerText = this.selectedValue;
      this.cdr.detectChanges()
    }
  }

  ngAfterViewInit() : void {
    this.btnDropdown.nativeElement.innerText = this.selectedValue
  }

  public dropdownSelection(value): void {
    this.selectedValueEmit.emit(value);
  }

}
