import { Component, Input, OnInit } from '@angular/core';

import { ResultHelperService } from '../../../services/result-helper.service';

@Component({
  selector: 'numeric-input-result',
  templateUrl: './numeric-input-result.component.html'
})
export class NumericInputResultComponent implements OnInit {
  @Input() question;
  @Input() sectionId;
  @Input() examId;
  @Input() index;
  keyName: any;
  numericValue = '';
  numericInput: any;
  numericInputCorrect: any;
  setNumericInputValue: any = [];
  examQuestion = '';
  examQuestionCorrectAnswer = '';
  constructor(
    private resultHelper: ResultHelperService) { }

  ngOnInit(): void { }
  // tslint:disable-next-line: use-lifecycle-interface
  ngOnChanges(): void {
    this.examQuestion = this.question.question;
    this.examQuestionCorrectAnswer = this.question.question;
    this.onLoadData();
  }
  /**
   * @description: Load Data First Time
   */
  private onLoadData(): void {
    if (this.question !== undefined) {
      this.numericInput = this.resultHelper.
        onNumericInputLoadData(this.question, this.keyName, this.numericInput,
          this.examQuestion, this.examQuestionCorrectAnswer, this.numericInputCorrect).numericInputValue;
      this.numericInputCorrect = this.resultHelper.
        onNumericInputLoadData(this.question, this.keyName, this.numericInput,
          this.examQuestion, this.examQuestionCorrectAnswer, this.numericInputCorrect).numericInputCorrectValue;
    }
  }
  /**
   * @description: Check Value Is User Selected or not
   */
  public checkValue(data): any {
    if (this.question.userAnswers.find(o => data.some(i => o.value === i.value))) {
      return true;
    } else {
      return false;
    }
  }
}
