<div>
    <ul class="multiresult-list multiquestion-list-result">
        <li>
            <div class="ans-question-box case-study-sql-ques">
                <strong class="sql-number">{{index}}. </strong><strong class="sql-ques"
                [innerHTML]="question?.question | targetBlank"></strong>
            </div>
            <div class="mql-ans-box">
                <table>
                    <tr *ngFor="let label of question.labels;let i=index;">
                        <td class="multisection-question-options-width pr-2">{{label.value}}</td>
                        <td>
                            <div class="radio-button-box" *ngIf="question.userAnswers.length>0">
                                <div class="mql-option-box" [attr.inert]="true" *ngFor="let option of label.options"
                                    [ngClass]="option.correct?'sql-option-box-success':!option.correct && option.checked?'sql-option-box-failure':''">
                                    <teams-radio class="pe-none" [name]="label.key+index" id="radio{{option.key}}{{i}}{{index}}"
                                        [value]="option.key" [checked]="option.checked"
                                        [ngClass]="option.correct?'ts-radio-fluent-success':!option.correct && option.checked ?'ts-radio-fluent-failure':''"
                                        [class.ts-radio-fluent-primary]="!option.checked" [label]="option.value">
                                    </teams-radio>
                                    <!-- <label for="radio{{option.key}}{{i}}{{index}}"><span>{{option.value}}</span></label> -->
                                </div>
                            </div>
                            <div class="radio-button-box" *ngIf="question.userAnswers.length===0">
                                <div class="mql-option-box" [attr.inert]="true" *ngFor="let option of label.options"
                                    [ngClass]="option.notAttempt===true?'notAttempt':''">
                                    <teams-radio class="pe-none" [name]="label.key+index" id="radio{{option.key}}{{i}}{{index}}"
                                        [value]="option.key" [label]="option.value">
                                    </teams-radio>
                                    <!-- <label for="radio{{option.key}}{{i}}{{index}}"><span>{{option.value}}</span></label> -->
                                </div>
                            </div>
                        </td>
                    </tr>
                </table>
            </div>
        </li>
    </ul>
    {{question.userScore}}/{{question.questionScore}} {{ 'exam.result.points' | translate }}
</div>