import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root',
})
export class TestQuestionHelperService {
  // public questionList: any = [];
  // public question: any = [];
  // public selectedIndex = 0;
  // progressChangeValue = 0;
  // public serviceIsLoading = false;
  // public timer = 0;
  constructor(private router: Router) { }

  // public getSectionQuestion(resQue, router, questionId): any {
  //   questionList = resQue;
  //   let findIndexOfProgressQuestion = this.questionList.findIndex(
  //     (i) => i.inProgress === true
  //   );
  //   const ind = ++findIndexOfProgressQuestion;
  //   if (
  //     findIndexOfProgressQuestion !== -1 &&
  //     findIndexOfProgressQuestion !== this.questionList.length &&
  //     router.url.indexOf('review') === -1
  //   ) {
  //     this.question = this.findQuestion(
  //       this.questionList[ind].questionUniqueName
  //     );
  //     this.selectedIndex = this.questionList.findIndex(
  //       (i) =>
  //         i.questionUniqueName === this.questionList[ind].questionUniqueName
  //     );
  //     const singleQustionProgress = 100 / this.questionList.length;
  //     const m = singleQustionProgress * (this.selectedIndex + 1);
  //     this.progressChangeValue = m;
  //     this.serviceIsLoading = false;
  //   } else if (router.url.indexOf('review') === -1) {
  //     this.question = this.questionList[0];
  //     this.serviceIsLoading = false;
  //     this.selectedIndex = this.questionList.findIndex(
  //       (i) => i.questionUniqueName === this.question.questionUniqueName
  //     );
  //     const totalQuestion = 100 / this.questionList.length;
  //     const m = totalQuestion * (this.selectedIndex + 1);
  //     this.progressChangeValue = m;
  //   } else if (router.url.indexOf('review') !== -1) {
  //     this.question = this.findQuestion(questionId);
  //     this.serviceIsLoading = false;
  //     this.selectedIndex = this.questionList.findIndex(
  //       (i) => i.questionUniqueName === questionId
  //     );
  //     const totalQuestion = 100 / this.questionList.length;
  //     const m = totalQuestion * (this.selectedIndex + 1);
  //     this.progressChangeValue = m;
  //   }
  //   return [this.question, this.selectedIndex, this.progressChangeValue, this.questionList];
  // }

  // public getTimer(minutesToComplete, stopWatch): any {
  //   const examTimeInMinutes = minutesToComplete;
  //   const examTimeInSecond = examTimeInMinutes * 60;
  //   this.timer = examTimeInSecond * 1000;
  //   this.timer = this.timer - stopWatch * 1000;
  //   return this.timer;
  // }

  /**
   * @description : Match Question
   */
  public findQuestion(questionList, questionId: any): any {
    const getQuestion = questionList.find(
      (i) => i.questionUniqueName === questionId
    );
    return getQuestion;
  }
  /**
   * @description: Redirect Current Question
   */
  public redirectCurrentQuestion(questionList, selectedIndex, question, progressChangeValue, serviceIsLoading, questionId): any {
    let findIndexOfProgressQuestion = questionList.findIndex(
      (i) => i.inProgress === true
    );
    const ind = ++findIndexOfProgressQuestion;
    if (
      findIndexOfProgressQuestion !== -1 &&
      findIndexOfProgressQuestion !== questionList.length &&
      this.router.url.indexOf('review') === -1
    ) {
      question = this.findQuestion(questionList,
        questionList[ind].questionUniqueName
      );
      selectedIndex = questionList.findIndex(
        (i) =>
          i.questionUniqueName === questionList[ind].questionUniqueName
      );
      const singleQustionProgress = 100 / questionList.length;
      const m = singleQustionProgress * (selectedIndex + 1);
      progressChangeValue = m;
      serviceIsLoading = false;
    } else if (this.router.url.indexOf('review') === -1) {
      question = questionList[0];
      serviceIsLoading = false;
      selectedIndex = questionList.findIndex(
        (i) => i.questionUniqueName === question.questionUniqueName
      );
      const totalQuestion = 100 / questionList.length;
      const m = totalQuestion * (selectedIndex + 1);
      progressChangeValue = m;
    } else if (this.router.url.indexOf('review') !== -1) {
      question = this.findQuestion(questionList, questionId);
      serviceIsLoading = false;
      selectedIndex = questionList.findIndex(
        (i) => i.questionUniqueName === questionId
      );
      const totalQuestion = 100 / questionList.length;
      const m = totalQuestion * (selectedIndex + 1);
      progressChangeValue = m;
    }
    return {
      loading: serviceIsLoading, progressChange: progressChangeValue, selectedIndexs: selectedIndex, questions: question
    };
  }
}
