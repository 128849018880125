import { Component, Input, OnInit } from '@angular/core';

import { ResultHelperService } from '../../../services/result-helper.service';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'build-list-result',
  templateUrl: './build-list-result.component.html',
  styleUrls: ['./build-list-result.component.scss'],
})
export class BuildListResultComponent implements OnInit {
  @Input() public question;
  @Input() public index;
  public options: Array<any> = [];
  public dropOptions: any = [];
  public correctOptions: any = [];
  constructor(private resultHelper: ResultHelperService) { }

  ngOnInit(): void {
    this.onLoadData();
  }
  /**
   * @description: Load Data First Time
   */
  private onLoadData(): void {
    if (this.question !== undefined) {
      this.correctOptions = this.resultHelper.
        onBuildLoadData(this.question, this.correctOptions, this.options, this.dropOptions).correctOptionsValue;
      this.question = this.resultHelper.
        onBuildLoadData(this.question, this.correctOptions, this.options, this.dropOptions).questions;
      this.options = this.resultHelper.
        onBuildLoadData(this.question, this.correctOptions, this.options, this.dropOptions).optionsValue;
      this.dropOptions = this.resultHelper.
        onBuildLoadData(this.question, this.correctOptions, this.options, this.dropOptions).dropOptionsValue;
    }
  }
}
