<div class="w-100">
    <ul class="singleresult-list singlequestion-list-result">
        <li>
            <!-- <span class="sql-title">Question Section</span> -->
            <div class="ans-question-box case-study-sql-ques">
                <strong class="sql-number">{{index}}. </strong><strong class="sql-ques"
                    [innerHTML]="question?.question | targetBlank"></strong>
            </div>
            <!-- <span class="sql-title">Answer Section</span> -->
            <div class="sql-ans-box" *ngIf="question.userAnswers.length >0">
                <div class="sql-option-box" *ngFor="let option of question?.options" [attr.aria-label]="(option.checked ? 'Checked ' : 'Not checked ') + option.value + (option.answer===1 ? ' Correct Answer' : '') + (option.answer===2 ? ' Incorrect Answer' : '')"
                    [ngClass]="option.answer===1?'sql-option-box-success':option.answer===2?'sql-option-box-failure':''">
                    <!-- [class.ts-radio-fluent-primary]="!option.checked" -->
                    <teams-radio class="pe-none" [attr.inert]="true" [value]='option.key' [name]="name" [checked]="option.checked"
                        [ngClass]="option.answer===1?'ts-radio-fluent-success':option.answer===2?'ts-radio-fluent-failure':''"
                         [label]="option.value"
                        [labelClass]="'ts-radio-fluent-text icon-cursor'"></teams-radio>
                    <!-- <label [for]="option.key" class="icon-cursor"><span class="ts-radio-fluent-text">{{option.value}}</span></label> -->
                </div>
            </div>
            <div class="sql-ans-box" *ngIf="question.userAnswers.length ===0">
                <div class="sql-option-box" *ngFor="let option of question?.options" [attr.aria-label]="(option.checked ? 'Checked ' : 'Not checked ') + option.value + (option.attempt===1 ? ' Correct Answer' : '')"
                    [ngClass]="option.attempt===1?'notAttempt':''">
                    <teams-radio class="pe-none" [attr.inert]="true"  [value]='option.key' [name]="name" [label]="option.value"
                        [labelClass]="'ts-radio-fluent-text icon-cursor'"></teams-radio>
                    <!-- <label [for]="option.key" class="icon-cursor"><span class="ts-radio-fluent-text">{{option.value}}</span></label> -->
                </div>
            </div>
        </li>
    </ul>
    {{question.userScore}}/{{question.questionScore}} {{ 'exam.result.points' | translate }}
</div>