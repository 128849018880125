<div class="w-100">
    <ul class="singleresult-list singlequestion-list-result">
        <li>
            <div class="ans-question-box case-study-sql-ques">
                <strong class="sql-number">{{index}}. </strong><strong class="sql-ques" [innerHTML]="question?.question | targetBlank"></strong>
            </div>
            <div class="ans-swap-box">
                <!--Left section-->
                <div class="col-md-2 col-12">
                    <div class="row">
                        <ul class="swap-left-list-box swap-middle-list-box">

                            <li *ngFor="let item of options" [ngClass]="{'disableItem':item.disabled }">
                                {{item.value}}
                            </li>
        
                        </ul>
                    </div>
                </div>
                <!--Answer mid section-->
                <ng-container *ngIf="question.userAnswers.length >0">
                    <div class="col-md-6 d-flex align-items-center ml-3">
                        <ul class="swap-left-list-box swap-middle-list-box w-100">
                            <ng-container *ngFor="let item of question.labels;let i=index;">
                                <div class="col-md-11 d-flex align-items-center mb-3">
                                    <div class="col-md-5">
                                        <li *ngIf="item.option!==null" [ngClass]="item.answer===1?'sql-option-box-success':item.answer===2?'sql-option-box-failure':''">
                                            {{item.option.value}}
                                        </li>
                                        <li *ngIf="item.option===null">
                                        </li>
                                    </div>
                                    <div class="col-md-7 swap-right-list-text-box text-break ml-2">
                                        {{item.value}}
                                    </div>
                                    
                                </div>
                            </ng-container>
                        </ul>
                    </div>
                    <!--Correct answer section-->
                    <div class="col-md-6 d-flex align-items-center ml-3">
                        <ul class="swap-left-list-box swap-middle-list-box w-100">
                            <ng-container *ngFor="let item of questionLabels;let i=index;">
                                <div class="col-md-11 d-flex align-items-center pl-0 mb-3">
                                    <div class="col-md-5 pl-0">
                                        <li *ngIf="item?.option!==null" class="match-option-li">
                                            {{item?.option?.value}}
                                        </li>
                                        <li *ngIf="item?.option===null">
                                        </li>
                                    </div>
                                    <div class="col-md-5 swap-right-list-text-box text-break">
                                        {{item.value}}
                                    </div>
                                </div>
                            </ng-container>
                        </ul>
                    </div>

                </ng-container>

                <ng-container *ngIf="question.userAnswers.length===0">
                    <div class="col-md-6 d-flex align-items-center ml-3">
                        <ul class="swap-left-list-box swap-middle-list-box w-100">
                            <ng-container *ngFor="let item of questionLabels;let i=index;">
                                <div class="col-md-11 d-flex align-items-center pl-0 mb-3">
                                    <div class="col-md-5 pl-0">
                                        <li *ngIf="item?.attempt!==null" class="match-option-li">
                                            {{item?.attempt?.value}}
                                        </li>
                                        <li *ngIf="item?.attempt===null">
                                        </li>
                                    </div>
                                    <div class="col-md-5 swap-right-list-text-box text-break">
                                        {{item.value}}
                                    </div>
                                </div>
                            </ng-container>
                        </ul>
                    </div>
                </ng-container>
            </div>
        </li>
    </ul>
    {{question.userScore}}/{{question.questionScore}} {{ 'exam.result.points' | translate }}
</div>
