import {
  Component,
  EventEmitter,
  Inject,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { EventService } from '../../../services/event.services';
import { User } from '../../../modals/user.model';
import { TranslateCacheService } from 'ngx-translate-cache';
import { Observable, Subject } from 'rxjs';
import 'rxjs/add/observable/timer';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/take';
import { Language } from '../../../modals/language.modal';
import { UtilityService } from '../../../services/utility.service';
import { LabCluster } from '../../../modals/lab-cluster.modal';
import { detect } from 'detect-browser';
import {
  AppType,
  CustomerType,
  ActionListItem,
  Lab,
} from '../../../modals/lab.model';
import { getEntityData, getEntity, miscellaneousConst } from '@utility';
import { Router, ActivatedRoute } from '@angular/router';

@Component({
  selector: 'cloudlabs-refined-header',
  templateUrl: './refined-header.component.html',
  styleUrls: ['./refined-header.component.scss'],
})
export class RefinedHeaderComponent implements OnInit, OnDestroy {
  @Output() openSideNavbar = new EventEmitter<string>();
  @Output() actionClickEvent = new EventEmitter<string>();
  @Output() vmChanged = new EventEmitter();
  @Input() menu: boolean;
  @Input() customLogo: string;
  @Input() lab: Lab | LabCluster | any;
  @Input() showTitle = true;
  @Input() isMylearning: boolean;
  @Input() set actionList(actList: Array<ActionListItem>) {
    this.updatedActionList = actList;
  }

  //Counter Accepts Duration in Seconds 1 hour = 3600
  @Input() set counter(seconds: number) {
    this.timerInSeconds = seconds;
    this.countDown = Observable.timer(0, 1000)
      .take(this.timerInSeconds)
      .map(() => --this.timerInSeconds);
  }
  @Input() set attendeeEndDate(endDateData: any) {
    this.endDateDetails = endDateData;
  }
  @Input() set modeOfOperation(status: string) {
    if (status === 'Deallocated' || status === 'Deallocating') {
      this.timerInSeconds = 0;
    }
  }
  @Input() vmDetail: any;
  @Input() set labLaunchedState(state: boolean) {
    this.labLaunched = state;
  }
  @Input() isSplitWindow = false;
  @Input() showMore = false;
  public currentVm: any;
  public showLanguage: string;
  public labuser: User;
  public browser = detect();
  public languages: Language[];
  public languageAriaLabel: string;
  public IsEdge: boolean;
  public countDown: Observable<any>;
  public timerInSeconds = 0;
  public appType = AppType;
  public customerType = CustomerType;
  public updatedActionList: Array<ActionListItem> = [];
  public endDateDetails = null;
  private notifier = new Subject();
  public labLaunched = false;
  public currCopyOption = false;

  constructor(
    public translate: TranslateService,
    public translateCacheService: TranslateCacheService,
    public eventServ: EventService,
    private util: UtilityService,
    private router: Router,
    private aroute: ActivatedRoute,
    @Inject('environment') public environment
  ) {
    this.getUserDetail();
  }

  async ngOnInit() {
    this.languages = await this.util.getLanguages().toPromise();
    this.languageAriaLabel = this.languages.find(
      (i) => i.Code == (localStorage.getItem('language') || 'en')
    ).Name;
    if (this.vmDetail) {
      this.currentVm = this.vmDetail.find(
        (i) => i.InternalId == this.aroute.snapshot.params.vmurl
      );
    }
    this.showLanguage = localStorage.getItem('language') || 'en';
    this.translate.use(this.showLanguage);
    this.translateCacheService.init();
    this.eventServ.languageChanged.subscribe((lang) => {
      this.showLanguage = lang;
    });
    this.switchLanguage(this.showLanguage);
    if (this.browser.name.includes('edge')) {
      this.IsEdge = true;
    } else {
      this.IsEdge = false;
    }
  }

  switchVm(vmData) {
    if (vmData.InternalId !== this.currentVm.InternalId) {
      this.currentVm = vmData;
      this.vmChanged.emit(vmData);
    }
  }

  switchCopyOption() {
    this.eventServ.copyMode.next(this.currCopyOption);
  }

  /**
   * Change language switcher
   * @param language
   */
  public switchLanguage(language) {
    document.getElementsByTagName('html')[0].setAttribute('lang', language);
    this.languageAriaLabel = this.languages.find(
      (i) => i.Code == language
    ).Name;
    this.util.switchLanguage(language);
    if (this.lab) {
      const key = `lab_language_${this.lab.UniqueName}`;
      localStorage.setItem(key, language);
    }
  }

  getUserDetail() {
    const user = localStorage.getItem('labuser');
    if (user) {
      this.labuser = JSON.parse(user);
    }
  }

  openSideNav() {
    this.eventServ.onMenuClick();
  }

  ngOnDestroy() {
    this.notifier.next();
    this.notifier.complete();
  }

  actionClick(action) {
    this.actionClickEvent.emit(action);
  }

  goBack() {
    if (this.router.url.includes(miscellaneousConst.navigation.virtualMachine)) {
      this.router.navigate([miscellaneousConst.navigation.virtualMachine]);
    } else if (this.router.url.includes(miscellaneousConst.navigation.template)) {
      const id = this.aroute.snapshot.params.id;
      this.router.navigate(['my-lab/labs', id, miscellaneousConst.navigation.template ]);
    }
    else if (
      getEntityData() == miscellaneousConst.navigation.courses ||
      getEntityData() == miscellaneousConst.navigation.learningPath
    ) {
      const vcode = this.aroute.snapshot.params.clVcode;
      const id = this.aroute.snapshot.params.clid;
      this.router.navigate([
        getEntity() + `/` + getEntityData() + `/details/${id}/${vcode}`,
      ]);
    } else {
      const vcode = this.aroute.snapshot.params.voucherCode;
      const id = this.aroute.snapshot.params.id;
      this.router.navigate([
        getEntity() + `/` + getEntityData() + `/details/${id}/${vcode}`,
      ]);
    }
  }
}
