<div id="main-landmark" tabindex="-1" [ngClass]="{
    'isSideNav': (!sideNav && context === 'CloudLabs'),
    'pt-header-teams' :(context === 'CloudLabs'),
    'header-fix': (context === 'PracticeTest')
    }">
    <div class="sqp-title-time-lang-but-row" *ngIf="regUniqueName === undefined || regUniqueName === ''">
        <div class="sqptt-title" *ngIf="!userSessionService.isTeamsAppUser && context === 'PracticeTest'">
            <img class="team-icon" src="../../assets/images/cloudlogo.png" alt="logo">
            <h2 class="ts-title-bar position-relative">{{ 'branding.cloudlabs' | translate }}</h2>
        </div>
        <h1 class="sqptt-title sqptt-title-mobile" *ngIf="checkSectionHeaderTitle() && questionList.length && sectionType !== miscellaneousConst.performanceTask">
            <span *ngIf="!isMobile">{{ 'common.question' | translate }}</span> {{ selectedIndex + miscellaneousConst?.arrayOneValue }} {{ 'common.of' | translate }} {{ questionList.length }}
        </h1>

        <div class="sqptt-title" *ngIf="!isQuestionSectionPerfmQuiz && sectionType ===
    miscellaneousConst.performanceQuizSectionType">
            {{ 'exam.section.performanceQuiz.header.infoInstruct' | translate }}
        </div>

        <div class="sqptt-title" *ngIf="checkSectionHeaderTask() && sectionType ===
    miscellaneousConst.performanceTask">
    <span *ngIf="!isMobile">{{ 'exam.section.performanceTask.task' | translate }}</span> {{ taskCount === null || taskCount === undefined ? 0 : taskCount + 1 }} ( {{ 'common.of' | translate }} {{ questionList.length }})
        </div>

        <div class="sqptt-title" *ngIf="!sideMenuSelectedOption && sectionType ===
    miscellaneousConst.caseStudySectionType">
            {{tabTitle}}
        </div>
        <div class="sqptt-right-box">
            <div class="sqpttr-time-remaining" *ngIf="totalSectionCount>
        miscellaneousConst?.arrayOneValue">
                <strong>{{ 'common.section' | translate }}: </strong>
                <span>{{ leftSectionCount }}/{{ totalSectionCount }}</span>
            </div>
            <div class="sqpttr-time-remaining" *ngIf="!isMobile">
                <span><strong>{{ 'exam.section.sectionReview.header.timeRemaining' | translate
        }}: </strong></span>
                <span>{{ changeMinute(timer) }}</span>
            </div>
            <div class="sqpttr-time-remaining pr-0 mr-0" *ngIf="isMobile">
                <span>{{ changeMinute(timer) }}</span>
            </div>
            <div *ngIf="!isMobile" class="sqpttr-language">
                <strong>{{ 'common.language' | translate }}: </strong>
                <span>{{ examInstaceDetails?.language }}</span>
            </div>
            <teams-button [classList]="'btn ts-btn-fluent-green ts-btn-fluent button-height-1 ts-btn-width-default ml-4'" [title]="'actions.reviewSection' | translate" (buttonClick)="getFinishSection()" *ngIf="sectionList?.length > miscellaneousConst?.arrayOneValue && sectionType !== miscellaneousConst.performanceTask">
            </teams-button>
            <teams-button [classList]="'btn ts-btn-fluent-green ts-btn-fluent button-height-1 ts-btn-width-default ml-3'" [title]="'actions.reviewAnswer' | translate" (buttonClick)="getFinishSection()" *ngIf="(sectionList?.length === miscellaneousConst?.answeredArrayMinLength ||sectionList?.length === miscellaneousConst?.arrayOneValue) && sectionType !== miscellaneousConst.performanceTask">
            </teams-button>
            <teams-button [classList]="'btn ts-btn-fluent-green ts-btn-fluent button-height-1 ts-btn-width-default ml-3'" [title]="'actions.finishTest' | translate" (buttonClick)="finishTestDialoge()" *ngIf="sectionType === miscellaneousConst.performanceTask">
            </teams-button>
        </div>
    </div>
    <div class="header-progress">
        <div class="chart-row" [ngClass]="{'mb-3 mt-2': this.sectionType !== miscellaneousConst.performanceQuizwithVM && this.sectionType !== miscellaneousConst.performanceQuizSectionType}" *ngIf="checkSectionHeaderTitle()">
            <span [style.width.%]="progressChangeValue"></span>
        </div>
        <div class="chart-row" *ngIf="checkSectionHeaderTask()">
            <span [style.width.%]="progressChangeValueTask2"></span>
        </div>
    </div>
</div>

<!--External flow header-->
<div class="header-fix" *ngIf="regUniqueName !== undefined &&
  regUniqueName !== ''">
    <div class="headerExternalFlow">
        <div class="sqptt-title" *ngIf="!userSessionService.isTeamsAppUser">
            <img class="team-icon" src="../../assets/images/cloudlogo.png" alt="logo">
            <h2 class="ts-title-bar position-relative">{{ 'branding.cloudlabs' | translate }}</h2>
        </div>

        <!-- Change in desing to make it consistent | Sheikh-->

        <div class="sqptt-title" *ngIf="checkSectionHeaderTitle()">
            {{ 'common.question' | translate }} {{ selectedIndex + miscellaneousConst?.arrayOneValue }} ( {{ 'common.of' | translate }} {{ questionList.length }})
        </div>
        <div class="sqptt-title" *ngIf="!isQuestionSectionPerfmQuiz && sectionType ===
    miscellaneousConst.performanceQuizSectionType">
            {{ 'exam.section.performanceQuiz.header.infoInstruct' | translate }}
        </div>
        <div class="sqptt-title" *ngIf="checkSectionHeaderTask() && sectionType ===
    miscellaneousConst.performanceTask">
    <span *ngIf="!isMobile">{{ 'exam.section.performanceTask.task' | translate }}</span> {{ taskCount === null || taskCount === undefined ? 0 : taskCount + 1 }} ( {{ 'common.of' | translate }} {{ questionList.length }})
        </div>

        <div class="sqptt-title" *ngIf="!sideMenuSelectedOption && sectionType ===
    miscellaneousConst.caseStudySectionType">
            {{tabTitle}}
        </div>
        <div class="sqptt-right-box">
            <div class="sqpttr-time-remaining" *ngIf="totalSectionCount>
      miscellaneousConst?.arrayOneValue">
                <strong>{{ 'common.section' | translate }}: </strong>
                <span>{{ leftSectionCount }}/{{ totalSectionCount }}</span>
            </div>
            <div class="sqpttr-time-remaining">
                <strong>{{ 'exam.section.sectionReview.header.timeRemaining' | translate
        }}: </strong>
                <span>{{ changeMinute(timer) }}</span>
            </div>
            <div class="sqpttr-language">
                <strong>{{ 'common.language' | translate }}: </strong>
                <span>{{ examInstaceDetails?.language }}</span>
            </div>
            <teams-button [classList]="'ts-btn-fluent-green ts-btn-fluent button-height-1 ts-btn-width-default ml-4'" [title]="'actions.reviewSection' | translate" (buttonClick)="getFinishSection()" *ngIf="sectionList?.length > miscellaneousConst?.arrayOneValue && sectionType !== miscellaneousConst.performanceTask">
            </teams-button>
            <teams-button [classList]="'ts-btn-fluent-green ts-btn-fluent button-height-1 ts-btn-width-default ml-3'" [title]="'actions.reviewAnswer' | translate" (buttonClick)="getFinishSection()" *ngIf="(sectionList?.length === miscellaneousConst?.answeredArrayMinLength ||sectionList?.length === miscellaneousConst?.arrayOneValue) && sectionType !== miscellaneousConst.performanceTask">
            </teams-button>
            <teams-button [classList]="'ts-btn-fluent-green ts-btn-fluent button-height-1 ts-btn-width-default ml-3'" [title]="'actions.finishTest' | translate" (buttonClick)="finishTestDialoge()" *ngIf="sectionType === miscellaneousConst.performanceTask">
            </teams-button>
        </div>
    </div>

    <div class="header-progress">
        <div class="chart-row" [ngClass]="{'mb-3 mt-2': this.sectionType !== miscellaneousConst.performanceQuizwithVM && this.sectionType !== miscellaneousConst.performanceQuizSectionType}" *ngIf="checkSectionHeaderTitle()">
            <span [style.width.%]="progressChangeValue"></span>
        </div>
        <div class="chart-row" *ngIf="checkSectionHeaderTask()">
            <span [style.width.%]="progressChangeValueTask2"></span>
        </div>
    </div>
</div>

<app-dialoge-prompt [dialogClass]="dialogClass" [isDialog]="finishTestConfimDialoge" (popupResponse)="closeConfirmResponse($event)">
    <div header class="subheading" id="dialog-title">
        {{'common.popup.confirmation' | translate}}
    </div>
    <p content class="dialog-headingText">{{'message.confirmQuestionMessage' | translate}}</p>
    <hr footer />
    <teams-button footer [classList]="'ts-btn ts-btn-fluent ts-btn-fluent-white button-height-1 ts-btn-width-default mr-4'" (buttonClick)="dialogeNo()" [title]="'actions.no' | translate">
    </teams-button>
    <teams-button footer [classList]="'ts-btn ts-btn-fluent-primary ts-btn-fluent button-height-1 ts-btn-width-default mr-4 mt-4'" (buttonClick)="dialogeYes()" [title]="'actions.yes' | translate">
    </teams-button>
</app-dialoge-prompt>
<app-dialoge-prompt [dialogClass]="dialogClass" [isDialog]="examResult" (popupResponse)="closeTestResultResponse($event)" [closable]="false">
    <div header class="subheading" id="dialog-title">
        {{'common.popup.examCompleted' | translate}}
    </div>
    <p content class="dialog-headingText">{{ 'message.getResultMessage' | translate }}</p>
    <hr footer />
    <teams-button footer [classList]="'ts-btn-fluent-primary ts-btn-fluent button-height-1 ts-btn-width-default'" (buttonClick)="getResult()" [title]="'actions.getResult' | translate ">
    </teams-button>
</app-dialoge-prompt>