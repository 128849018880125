<div *ngIf="loadingService.loading$ | async" class="overlay d-flex flex-column"
    [ngClass]="{'content-loader' : appType === 'attendee', 'content-loader-full-screen' : appType === 'parent-consent'}">
    <div class="text-center">
        <div class="lds-ring">
            <div></div>
            <div></div>
            <div></div>
            <div></div>
        </div>
        <div *ngIf="loadingMessage" role="alert">{{ loadingMessage }}</div>
        <!-- <div *ngIf="(loadingService?.loadingMsg$ | async) !== undefined && (loadingService?.loadingMsg$ | async)?.length > 0">
            <ng-container *ngFor="let item of (loadingService?.loadingMsg$ | async)">
                <span role="alert" [attr.aria-label]="item | translate">{{ item | translate }} &nbsp;</span> 
            </ng-container>
        </div> -->
    </div>
</div>