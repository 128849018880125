<ng-container *ngIf="sectionType === miscellaneousConst.performanceQuizSectionType || sectionType === miscellaneousConst.performanceQuizwithVM">
    <div class="credentials-title-dec-box">
        <div class="ctdb-dec-box performanceQuiz-dec-box">
            <label class="logHeading mb-3">{{ 'exam.section.performanceQuiz.loginCred' | translate }}</label>
            <div class="subLogHeading" *ngIf="viewCredentials">
                {{ 'exam.section.performanceQuiz.credTitle' | translate }}
                <a href={{azurePortalUrl}} target="_blank">{{ 'exam.section.performanceQuiz.msAzure' | translate
                    }}</a>
            </div>
            <div>
                <teams-button *ngIf="isRetry" [title]="'actions.retry' | translate" (buttonClick)="getLabDetails()" [classList]="'ts-btn-fluent-primary button-height-1 ts-btn-width-default'">
                </teams-button>
            </div>
            <div class="progress-bar-title mb-2" *ngIf="!viewCredentials && progreessBar">
                {{ 'exam.section.performanceQuiz.creatingCred' | translate }} - {{ performanceProgressChangeValue }} %
            </div>
            <div *ngIf="viewCredentials">
                <div class="user-name-password">
                    <div>
                        <label>{{ 'exam.section.performanceQuiz.userName' | translate }}</label>
                    </div>
                    <div>
                        <span>
                            <teams-input readonly [value]="userName" #userNameinput [title]="userName">
                            </teams-input>
                            <i class="ms-Icon ms-Icon--Copy" title="Click to Copy" aria-hidden="true"
                                (click)="copyContent(userNameinput)"></i>
                        </span>
                    </div>
                    <div>
                        <label>{{ 'exam.section.performanceQuiz.password' | translate }}</label>
                    </div>
                    <div>
                        <span>
                            <teams-input readonly [value]="passWord" #password [title]="passWord"></teams-input>
                            <i class="ms-Icon ms-Icon--Copy" title="Click to Copy" aria-hidden="true"
                                (click)="copyContent(password)"></i>
                        </span>
                    </div>
                    <div *ngFor="
                  let outputParams of outputParametersKeys;
                  let index = index
                ">
                        <div>
                            <label>{{ outputParams }}</label>
                        </div>
                        <div>
                            <span>
                                <teams-input readonly [value]="outputParametersValues[index]" #userNameinput
                                    [title]="outputParametersValues[index]"></teams-input>
                                <i class="ms-Icon ms-Icon--Copy" title="Click to Copy" aria-hidden="true"
                                    (click)="copyContent(userNameinput)"></i>
                            </span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</ng-container>
<!-- Design changed for performance task | Sheikh-->
<ng-container *ngIf="sectionType === miscellaneousConst.performanceTask">
    <div class="credentials-title-dec-box">
        <div>
            <div class="ctdb-dec-box performanceTask-dec-box">
            <label class="logHeading mb-3">{{ 'exam.section.performanceQuiz.loginCred' | translate }}</label>
            <div class="subLogHeading" *ngIf="viewCredentials">
                {{ 'exam.section.performanceQuiz.credTitle' | translate }}
                <a href={{azurePortalUrl}} target="_blank">{{ 'exam.section.performanceQuiz.msAzure' | translate
                    }}</a>
            </div>
            <div>
                <teams-button *ngIf="isRetry" [title]="'actions.retry' | translate" (buttonClick)="getLabDetails()" [classList]="'ts-btn-fluent-primary button-height-1 ts-btn-width-default'">
                </teams-button>
            </div>
            <div class="progress-bar-title mb-2" *ngIf="!viewCredentials && progreessBar">
                {{ 'exam.section.performanceQuiz.creatingCred' | translate }} - {{ performanceProgressChangeValue }} %
            </div>
            </div>
            <div *ngIf="viewCredentials" class="ctdb-dec-box performanceTask-dec-box credbox-task">
                <table class="cred-box-new-design ml-5 mb-5">
                    <tr>
                        <td class="mr-3">
                            <label class="txt-grey">{{ 'exam.section.performanceQuiz.userName' | translate }}</label>
                        </td>
                        <td>
                            <teams-input [disabled]="true" readonly [value]="userName" #userNameinput [title]="userName">
                            </teams-input>
                        </td>
                        <td class="mr-3">
                            <i class="ms-Icon ms-Icon--Copy" title="Click to Copy" aria-hidden="true"
                        (click)="copyContent(userNameinput)"></i>
                        </td>
                    </tr>
                    <tr>
                        <td class="mr-3">
                            <label class="txt-grey">{{ 'exam.section.performanceQuiz.password' | translate }}</label>
                        </td>
                        <td>
                            <teams-input [disabled]="true" readonly [value]="passWord" #password [title]="passWord"></teams-input>
                        </td>
                        <td class="mr-3">
                            <i class="ms-Icon ms-Icon--Copy" title="Click to Copy" aria-hidden="true"
                                    (click)="copyContent(password)"></i>
                        </td>
                    </tr>
                    <tr *ngFor="
                    let outputParams of outputParametersKeys;
                    let index = index">
                    <td class="mr-3">
                        <label class="txt-grey">{{ outputParams }}</label>
                    </td>
                    <td>
                        <teams-input [disabled]="true" readonly [value]="outputParametersValues[index]" #userNameinput
                                    [title]="outputParametersValues[index]"></teams-input>
                    </td>
                    <td class="mr-3">
                        <i class="ms-Icon ms-Icon--Copy" title="Click to Copy" aria-hidden="true"
                                    (click)="copyContent(userNameinput)"></i>
                    </td>

                    </tr>
                  </table>
            </div>
        </div>
    </div>
</ng-container>