import { Component, EventEmitter, Input, OnChanges, Output } from '@angular/core';
import * as _ from 'lodash';

import { miscellaneousConst } from '@utility';

declare const fabric: any;

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'multiple-choice-question',
  templateUrl: './multiple-choice-question.component.html',
  styleUrls:['./multiple-choice-question.component.scss']
})
export class MultipleChoiceQuestionComponent implements OnChanges {
  @Input() public question;
  @Input() userAnswer = [];
  @Input() sectionType = '';
  @Input() versionKey: string;
  @Input() isInlineQuestion = false;
  @Input() questionStyle
  @Input() groupQuestRemainingRetries
  @Output() UserSelectAnswerOption = new EventEmitter();
  public maxNo = false;
  public maxAnswer = 0;

  public miscellaneousConst = miscellaneousConst;
  @Input() set isCorrectAnswerMultilpeChoice (value){
    if (value?.type === 'ungrouped') {
      
      this.question?.options.forEach(element => {
        value?.userAnswers.forEach(el => {
            if(value?.correctAnswers.length > 0) {
              value?.correctAnswers.forEach(correctAnswer => {
                if (value?.remainingRetries === 0 && (correctAnswer.key === element.key)) {
                  element.isCorrect = true
                }
                
              });
            } else
            if((value?.questionUniqueName === this.question?.questionUniqueName) && (el.key === element.key) && value?.isCorrect !== undefined) {
              element.isCorrect = el?.isCorrect
            }
        });
      })
    }
    else if (value?.type === 'grouped') {
      this.question?.options.forEach(option => {
        value?.mergedArrayGroupe.forEach(mergedArr => {
          if (mergedArr?.QuestionUniqueName === this.question?.questionUniqueName) {
            if (mergedArr.correctAnswers.length > 0) {
              const filteredUserAnswers = this.question?.options.filter(opt => 
                mergedArr.correctAnswers.some(correctAnswer => correctAnswer.key === opt.key)
              );
              filteredUserAnswers.forEach(filteredUserAnswer => {
                if (filteredUserAnswer.key === option.key && this.groupQuestRemainingRetries === 0 && mergedArr?.QuestionUniqueName === this.question?.questionUniqueName) {
                  option.isCorrect = true
                }
              });
            } else {
              mergedArr.userAnswers.forEach(userAns => {
                if ((mergedArr?.QuestionUniqueName === this.question?.questionUniqueName) && (userAns?.key === option?.key)) {
                  option.isCorrect = userAns?.isCorrect
                }
              });
            }
          }
        })
      })
    }
  };

  ngOnInit(): void {
    if (this.isInlineQuestion) {
      this.question?.options.forEach(element => {
        this.question.userAnswers.forEach((answer:any)=>{
            if (this.question?.correctAnswers.length > 0) {
              this.question?.correctAnswers.forEach(correctAnswer => {
                if (this.questionStyle === 'ungroupedQuest' && this.question?.remainingRetries === 0 && (correctAnswer?.key === element.key)) {
                  element.isCorrect = true
                } else if (this.questionStyle === 'groupedQuest' && this.groupQuestRemainingRetries === 0 && (correctAnswer?.key === element.key)) {
                    element.isCorrect = true
                  }
              });
            } 
            else if ((answer.key === element.key)) {
                element.isCorrect = answer.isCorrect
              }
          })
      });
    }
  }
  /**
   * @description: Change Multiple Choice Question Value
   */
  ngOnChanges(): void {
    this.maxNo = false;
    this.question.options.forEach((element) => {
      element.checked = false;
      this.maxNo = this.question.maxAnswers === 0 ? true : false;
    });
    if (this.question.userAnswers?.length > 0) {
      this.selectedUserAnswer();
    }
    const CheckBoxElements = document.querySelectorAll('.ms-CheckBox');
    // tslint:disable-next-line: prefer-for-of
    // for (let i = 0; i < CheckBoxElements.length; i++) {
    _.each(CheckBoxElements, (sub, i) => {
      if (!_.isUndefined(sub)) {
        // tslint:disable-next-line:no-string-literal
        // tslint:disable-next-line:no-unused-expression
        new fabric['CheckBox'](CheckBoxElements[i]);
      }
    });
  }
  /**
   * @description: Load Selected User Answers
   */
  private selectedUserAnswer(): void {
    const answer = this.question?.options.filter((i) =>
      this.question.userAnswers.some((o) => i.key === o.key)
    );
    answer.forEach((element) => {
      element.checked = true;
    });
    this.maxNo = this.question.userAnswers.length === this.question.maxAnswers
      ? true : false;
  }
  /**
   * @description: Save Multiple Choice Answers
   */
  public selectMultipleChoice(maxAnswer: boolean): void {
    const userAnswers = [];
    const answer = this.question.options.filter(i => i.checked === true);
    if (answer.length > 0) {
      answer.forEach(e => {
        userAnswers.push({
          key: e.key,
          value: e.value
        });
      });
      if(this.question?.isVersioned) {
        this.question?.questionVersions.forEach((element) => {
          if(element.versionKey === this.versionKey) {
            element.userAnswers = userAnswers
            this.question.userAnswers = userAnswers
          }
        });
      } else {
        this.question.userAnswers = userAnswers;
      }
      this.UserSelectAnswerOption.emit(this.question);
    } else if (answer.length === 0) {
      this.question.userAnswers = [];
      this.UserSelectAnswerOption.emit(this.question);
    }
    this.maxNo = answer.length === this.question.maxAnswers
      ? true
      : false;
  }
}
