<!-- <div class="row">
  <div class="col-md-6" style="margin-top: 3px">
    <div
      class="caption-subject"
      style="font-size: 1.25rem"
      role="heading"
      aria-level="2"
      aria-label="TAB VIRTUAL MACHINES"
    >
      <i class="fa fa-desktop"></i> {{ "TAB_VIRTUAL_MACHINES" | translate }}
    </div>
  </div>
  <div  id="refresh-all-btn" class="col-md-6 text-right">
    <div
      role="button"
      class="btn btn-sm btn-primary refresh"
      tabindex="0"
      (click)="loadVirtualMachines()"
      (keyup.enter)="loadVirtualMachines()"
      attr.aria-label="{{ 'REFRESH_ALL_VM' | translate }}"
    >
      <span
        style="font: normal normal normal 14px/1"
        class="fa fa-refresh vm-action-buttons mr-2 refresh-vm"
        [ngClass]="{ rotate: isVMLoading }"
      >
        <span *ngIf="!docActions"> </span>
      </span>
      <span>{{ "BUTTON_REFRESH" | translate }}</span>
    </div>
  </div>
</div> -->
<div class="portlet-body">
  <div class="table-responsive">
    <div style="font-size: 1rem" role="heading" aria-level="3"
      *ngIf="attendeLabDetails.ComputeVMUsage && showUpTimeLimit">
      {{ "UPTIME_LIMIT" | translate }}{{ " (hh:mm) : " + upTimeLimit }}
    </div>
    <table aria-label="Lab Resource Table" class="table" tabindex="0">
      <tr>
        <th>{{ "NAME" | translate }}</th>
        <th>{{ "STATUS" | translate }}</th>
        <th class="hide-column">{{ "DNS_NAME" | translate }}</th>
        <!-- <th *ngIf="attendeLabDetails.ComputeVMUsage">
          {{ "UP_TIME" | translate }}{{ " " }}(hh:mm)
        </th> -->
        <th *ngIf="attendeLabDetails.EnableResourceOperations">
          {{ "ACTIONS" | translate }}
        </th>
      </tr>
      <tbody *ngIf="v_machine">
        <tr *ngFor="let row of v_machine">
          <td>
            {{ row.ResourceName }}
          </td>
          <td>
            <span *ngIf="row.Status === '' || row.Status === null">{{
              "UPDATING_STATUS" | translate
              }}</span>
            <span *ngIf="row.Status !== '' && row.Status !== null">{{
              row.Status
              }}</span>
            <span *ngIf="
                row.ActiveVMDuration !== null &&
                row.VMUpTime !== null &&
                row.ActiveVMDuration <= row.VMUpTime &&
                (row.Status === 'VM deallocated' ||
                  row.Status === 'Deallocating' ||
                  row.Status === 'VM deallocating')
              ">-&nbsp;{{ "RUNTIME_LIMIT_EXHAUSTED_STATUS" | translate }}</span>
          </td>
          <td *ngIf="!docActions" class="hide-column" style="word-break: break-word">
            {{ row.DNSName }}
          </td>
          <!-- <td *ngIf="attendeLabDetails.ComputeVMUsage">
            {{ getUpTime(row) }}
          </td> -->
          <td class="text-left vm-buttons" header-class="'text-left'"
            *ngIf="attendeLabDetails.EnableResourceOperations">
            <teams-button role="button" aria-label="Start" [tooltip]="'START' | translate" id="{{ 'START' | translate }}{{ row.ResourceGroupName }}{{
                row.ResourceName
              }}" (buttonClick)="virtualMachineOperations(row, '7')"
              [classList]="'btn btn-circle btn-icon-only ts-btn-fluent-primary'" [disabled]="
                row.Status === 'VM running' ||
                (attendeLabDetails.ComputeVMUsage === true &&
                  row.VMRunningHours !== null &&
                  row.VMUpTimeLimitHours !== null &&
                  (row.VMUpTimeLimitHours !== 0 ||
                    row.VMUpTimeLimitMinutes !== 0) &&
                  row.VMRunningMinutes !== null &&
                  row.VMUpTimeLimitMinutes !== null &&
                  row.VMRunningHours >= row.VMUpTimeLimitHours &&
                  row.VMRunningMinutes >= row.VMUpTimeLimitMinutes)
              ">
              <i role="img" alt="" aria-label="img" aria-hidden="true" class="fa fa-play"></i>
            </teams-button>
            <teams-button role="button" aria-label="Restart" [tooltip]="'RESTART' | translate" id="{{ 'RESTART' | translate }}{{ row.ResourceGroupName }}{{
                row.ResourceName
              }}" (buttonClick)="virtualMachineOperations(row, '9')"
              [classList]="'btn btn-circle btn-icon-only ts-btn-fluent-primary'" [disabled]="
                row.Status !== 'VM running' ||
                (row.Status !== null &&
                  attendeLabDetails.ComputeVMUsage === true &&
                  row.VMRunningHours !== null &&
                  row.VMUpTimeLimitHours !== null &&
                  (row.VMUpTimeLimitHours !== 0 ||
                    row.VMUpTimeLimitMinutes !== 0) &&
                  row.VMRunningMinutes !== null &&
                  row.VMUpTimeLimitMinutes !== null &&
                  row.VMRunningHours >= row.VMUpTimeLimitHours &&
                  row.VMRunningMinutes >= row.VMUpTimeLimitMinutes)
              ">
              <i role="img" alt="" aria-label="img" aria-hidden="true" class="fa fa-refresh"></i>
            </teams-button>
            <teams-button role="button" attr.aria-label="'DEALLOCATE' | translate" [tooltip]="'DEALLOCATE' | translate"
              id="deallocate{{ row.ResourceGroupName }}{{ row.ResourceName }}"
              (buttonClick)="virtualMachineOperations(row, '8')"
              [classList]="'btn btn-circle btn-icon-only ts-btn-fluent-primary'" [disabled]="
                row.Status === 'VM deallocated' ||
                row.Status === 'VM deallocating' ||
                (row.Status !== null &&
                  attendeLabDetails.ComputeVMUsage === true &&
                  row.VMRunningHours !== null &&
                  row.VMUpTimeLimitHours !== null &&
                  (row.VMUpTimeLimitHours !== 0 ||
                    row.VMUpTimeLimitMinutes !== 0) &&
                  row.VMRunningMinutes !== null &&
                  row.VMUpTimeLimitMinutes !== null &&
                  row.VMRunningHours >= row.VMUpTimeLimitHours &&
                  row.VMRunningMinutes >= row.VMUpTimeLimitMinutes)
              ">
              <i role="img" alt="" aria-label="img" aria-hidden="true" class="fa fa-stop"></i>
            </teams-button>
            <teams-button role="button" aria-label="Launch Lab" tooltip="{{ 'LAUNCH_VM_IN_BROWSER' | translate }}"
              (buttonClick)="openVMLaunchURL(row)" [classList]="'btn btn-circle btn-icon-only ts-btn-fluent-primary'"
              *ngIf="row.VMLaunchURL !== null && showVMLaunchButton" [disabled]="
                row.Status !== 'VM running' || 
                (row.Status !== null &&
                  attendeLabDetails.ComputeVMUsage === true &&
                  row.VMRunningHours !== null &&
                  row.VMUpTimeLimitHours !== null &&
                  (row.VMUpTimeLimitHours !== 0 ||
                    row.VMUpTimeLimitMinutes !== 0) &&
                  row.VMRunningMinutes !== null &&
                  row.VMUpTimeLimitMinutes !== null &&
                  row.VMRunningHours >= row.VMUpTimeLimitHours &&
                  row.VMRunningMinutes >= row.VMUpTimeLimitMinutes)
              ">
              <i alt="" role="img" aria-label="img" aria-hidden="true" class="fa fa-external-link"></i>
            </teams-button>

            <teams-button aria-label="Launch url" role="button"
              tooltip="{{ 'GO_TO' | translate }} {{ row?.ResourceName }}" (buttonClick)="openVMandGitDoc(row)" *ngIf="
                showLaunchButton &&
                row?.VMLaunchURL !== null &&
                row?.VMLaunchURL !== '' &&
                this?.internalId !== ''
              " [disabled]="
                row?.InternalId === this.internalId ||
                row.Status !== 'VM running' ||
                (row.Status !== null &&
                  attendeLabDetails.ComputeVMUsage === true &&
                  row.VMRunningHours !== null &&
                  row.VMUpTimeLimitHours !== null &&
                  (row.VMUpTimeLimitHours !== 0 ||
                    row.VMUpTimeLimitMinutes !== 0) &&
                  row.VMRunningMinutes !== null &&
                  row.VMUpTimeLimitMinutes !== null &&
                  row.VMRunningHours >= row.VMUpTimeLimitHours &&
                  row.VMRunningMinutes >= row.VMUpTimeLimitMinutes)
              ">
              <i alt="" role="img" aria-label="img" aria-hidden="true" class="fa fa-external-link"></i>
            </teams-button>
          </td>
        </tr>
      </tbody>
      <tr *ngIf="!isVMLoading && v_machine?.length === 0">
        <td colspan="3" translate="NO_RECORDS_FOUND" style="border-top: 0"></td>
      </tr>
    </table>
  </div>
</div>
<div class="modal" *ngIf="modelShow" [class.validation]="modelShow" id="validation" appTrapFocus>
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header">
        <h4 class="modal-title" aria-label="modal title">
          {{ modalData.title | translate }}
        </h4>
        <teams-button aria-label="Close" role="button" [classList]="'close'" (buttonClick)="modelShow = false">
          &times;
        </teams-button>
      </div>
      <div class="modal-body" aria-label="modal description">
        <p>
          {{ modalData.description | translate }}
        </p>
      </div>
      <div class="modal-footer">
        <teams-button aria-label="Confirm" (buttonClick)="modelShow = false">
          {{ "OK" | translate }}
        </teams-button>
      </div>
    </div>
  </div>
</div>