/* eslint-disable @nrwl/nx/enforce-module-boundaries */
import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class ApiService {
  private baseUrl;
  token = localStorage.getItem('msal.idtoken');
  constructor(
    private http: HttpClient,
    @Inject('environment')
    environment
  ) {
    this.baseUrl = environment.apiUrl;
  }

  getProfile() {
    const token = localStorage.getItem('msal.idtoken');
    const headers = {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    };
    return this.http
      .get<any>(`${this.baseUrl}/AECUser/attendeeprofile`, { headers: headers })
      .toPromise();
  }
}
