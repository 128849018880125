import { AfterViewInit,Directive,ElementRef,OnDestroy, OnInit } from '@angular/core';

@Directive({
  selector: '[appLabFocus]'
})
export class LabFocusDirective implements OnInit {
  constructor(private el: ElementRef) {
   }

   public elements:any
   ngOnInit() {
     setTimeout(() => {
      
      (this.el?.nativeElement as HTMLElement).setAttribute('aria-live', 'polite');
      setTimeout(() => {
        (this.el?.nativeElement as HTMLElement).setAttribute('aria-live', 'off');
      }, 800);
     }, 2000);
  }
}
