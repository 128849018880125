import { Component, Inject, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { LoadingService } from '../services/loading.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'cloudlabs-loading',
  templateUrl: './loading.component.html',
  styleUrls: ['./loading.component.scss'],
})
export class LoadingComponent implements OnInit {
  appType: any;
  @Input() context: string;
  loadingMessage = '';
  constructor(
    public loadingService: LoadingService,
    private router: Router,
    private translate: TranslateService,
    @Inject('environment')
    environment
  ) {
    this.appType = environment.appType;
    if (this.router.url.includes('request-consent')) {
      this.appType = 'parent-consent';
    }
  }

  ngOnInit(): void {
    this.loadingService?.loadingMsg$.subscribe(loadingMsg => {
      if(loadingMsg !== undefined && loadingMsg.length > 0) {
        this.loadingMessage = '';
        for(const message of loadingMsg) {
          this.loadingMessage += this.translate.instant(message) + ' ';
        }
      }
    })
  }
}
