import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ClipboardModule } from 'ngx-clipboard';
import { RouterModule } from '@angular/router';
import { SharedModule } from './shared/shared.module';
import { LabsModule } from './labs/labs.module';
import { LabClusterService } from '../services/lab-cluster.service';
import { LabenvironmentModule } from './labenvironment/labenvironment.module';
import { BaseRegistrationComponent } from './base-registration/base-registration.component';
import { NotificationService } from '../services/notification.service';
import { FormsModule } from '@angular/forms';
@NgModule({
  declarations: [BaseRegistrationComponent],
  imports: [
    CommonModule,
    RouterModule,
    ClipboardModule,
    SharedModule,
    LabsModule,
    LabenvironmentModule,
    FormsModule
  ],
  exports: [
    SharedModule,
    LabsModule,
    LabenvironmentModule,
    BaseRegistrationComponent
  ],
  providers: [LabClusterService, NotificationService],
})
export class CommonLabModule {}
