<div>
    <!-- <ul class="multiquestion-list" id="main-landmark"> -->
        <ul class="multiquestion-list">
        <li>
            <span class="mql-title caption">{{ 'exam.questions.questionSection' | translate }}</span>
            <div class="sql-ques case-study-sql-ques">
                <h4 role="heading" aria-level="2" class="" [innerHTML]="question?.question | targetBlank"></h4>
                <span class="question-notes" *ngIf="question?.note !== null"><strong>{{ 'exam.questions.note' | translate }}: </strong> <span
                        [innerHTML]="question?.note"></span></span>
                <span *ngIf="question?.instruction !== null"><strong>{{ 'exam.questions.instruction' | translate
                        }}:</strong> <span [innerHTML]="question?.instruction"></span></span>
            </div>
            <span class="mql-title caption">{{ 'exam.questions.answerSection' | translate }}</span>
            <div class="mql-ans-box">
                <table>
                    <tr *ngFor="let label of question.labels; let i = index">
                        <td class="multisection-question-options-width pr-2">{{ label.value }}</td>
                        <td>
                            <div class="mql-option-box" *ngFor="let option of question.options">
                                <teams-radio [name]="label.key"
                                    id="radio{{ option.key }}{{ i }}" [value]="option.key"
                                    [checked]="selectUserAnswerOnLoad(label.key, option.key)"
                                    (click)="onSingleChoiceSelect($event, label.key)" [label]="option.value"
                                    [labelClass]="'icon-cursor ts-radio-fluent-text'">
                                </teams-radio>
                            </div>
                        </td>
                    </tr>
                </table>
            </div>
        </li>
    </ul>
</div>