<div class="sec-2" tabindex="-1" id="main-questions" *ngIf="question" [ngClass]="{'spacing-sec-2':!isInlineQuestion}">
    <div class="p-4 bg-white" [ngClass]="{'mb-5': question.questionType === 'Dropdown'}" *ngIf="question?.isVersioned">
        {{displayCaption}}
        <cloudlabs-version-dropdown [optionArray]="question?.questionVersions" [question]="question" [selectedValue]="selectedVersionLang" (selectedValueEmit)="setVersions($event)"></cloudlabs-version-dropdown>
    </div>
    <div *ngIf="question.questionType === 'Single Choice'" [ngClass]="{'mt-5': (this.regUniqueName !== undefined &&
    this.regUniqueName !== '')}">
        <div></div>
        <single-choice-question [isCorrectAnswerSingleChoice]="isCorrectAnswer" [isInlineQuestion]="isInlineQuestion" [groupQuestRemainingRetries]="groupQuestRemainingRetries" [questionStyle]="questionStyle" [sectionType]="sectionType" [question]="question" [versionKey]="versionKey" (UserSelectAnswerOption)="userSelectAnswerOption($event)" [userAnswer]="this.userAnswer" [isRetryClicked]="isRetryClicked" [isSubmitClicked]="isSubmitClicked">
        </single-choice-question>
    </div>
    <div *ngIf="question.questionType === 'Multiple Choice'" [ngClass]="{'mt-5': (this.regUniqueName !== undefined &&
    this.regUniqueName !== '')}">
        <multiple-choice-question [isCorrectAnswerMultilpeChoice]="isCorrectAnswer" [isInlineQuestion]="isInlineQuestion" [groupQuestRemainingRetries]="groupQuestRemainingRetries" [questionStyle]="questionStyle" [sectionType]="sectionType" [question]="question" [versionKey]="versionKey" (UserSelectAnswerOption)="userSelectAnswerOption($event)" [userAnswer]="this.userAnswer">
        </multiple-choice-question>
    </div>
    <div *ngIf="question.questionType === 'Dropdown'" class="select-sec" [ngClass]="{'mt-5': (this.regUniqueName !== undefined &&
    this.regUniqueName !== '')}">
        <drop-down-question [question]="question" [versionKey]="versionKey" (UserSelectAnswerOption)="userSelectAnswerOption($event)" [userAnswer]="this.userAnswer">
        </drop-down-question>
    </div>
    <div *ngIf="question.questionType === 'Build Tree'" [ngClass]="{'mt-5': (this.regUniqueName !== undefined &&
    this.regUniqueName !== '')}">
        <build-tree-question [question]="question" [versionKey]="versionKey" (UserSelectAnswerOption)="userSelectAnswerOption($event)" [userAnswer]="this.userAnswer">
        </build-tree-question>
    </div>

    <div *ngIf="question.questionType === 'Multiple Single Choice'" [ngClass]="{'mt-5': (this.regUniqueName !== undefined &&
    this.regUniqueName !== '')}">
        <multiple-single-choice-question [question]="question" [versionKey]="versionKey" (UserSelectAnswerOption)="userSelectAnswerOption($event)" [userAnswer]="this.userAnswer">
        </multiple-single-choice-question>
    </div>
    <div *ngIf="question.questionType === 'Build List'" [ngClass]="{'mt-5': (this.regUniqueName !== undefined &&
    this.regUniqueName !== '')}">
        <build-list-question [question]="question" [versionKey]="versionKey" (UserSelectAnswerOption)="userSelectAnswerOption($event)" [userAnswer]="this.userAnswer">
        </build-list-question>
    </div>
    <div *ngIf="question.questionType === 'Match Options'" [ngClass]="{'mt-5': (this.regUniqueName !== undefined &&
    this.regUniqueName !== '')}">
        <match-options-question [question]="question" [versionKey]="versionKey" (UserSelectAnswerOption)="userSelectAnswerOption($event)" [userAnswer]="this.userAnswer">
        </match-options-question>
    </div>
    <div *ngIf="question.questionType === 'NumericInput'" [ngClass]="{'mt-5': (this.regUniqueName !== undefined &&
    this.regUniqueName !== '')}">
        <numeric-input-question [question]="question" [versionKey]="versionKey" (UserSelectAnswerOption)="userSelectAnswerOption($event)" [userAnswer]="this.userAnswer">
        </numeric-input-question>
    </div>
    <div *ngIf="question.questionType === 'TextInput'" [ngClass]="{'mt-5': (this.regUniqueName !== undefined &&
    this.regUniqueName !== '')}">
        <text-input-question [isCorrectAnswerTextInput]="isCorrectAnswer" [question]="question" [versionKey]="versionKey" (UserSelectAnswerOption)="userSelectAnswerOption($event)" [userAnswer]="this.userAnswer" [isRetryClicked]="isRetryClicked" [isSubmitClicked]="isSubmitClicked"></text-input-question>
    </div>
</div>