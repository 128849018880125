import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class TaskListService {
  public tasks = []
  constructor() { }

  addTask(val){
    this.tasks.push(val)
    this.getTask()
  }

  getTask(){
    return [...new Set(this.tasks)];    
  }
}
