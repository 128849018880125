import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
} from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { CaptchaService } from '../captcha.service';

@Component({
  selector: 'cloudlabs-captcha',
  templateUrl: './captcha.component.html',
  styleUrls: ['./captcha.component.scss'],
})
export class CaptchaComponent implements OnChanges {
  @Input() config: any = {};
  @Output() captchaCode = new EventEmitter();
  captch_input: any = null;
  code: any = null;
  resultCode: any = null;
  captchaText = new FormControl('', Validators.required);
  validCaptcha = false;
  constructor(
    private captchService: CaptchaService,
    private cdr: ChangeDetectorRef
  ) {
    captchService.formSubmitted$.subscribe((res) => {
      if (res === true) {
        this.captchaText.markAllAsTouched();
      }
    });
  }
  ngOnChanges() {
    if (this.config) {
      if (!this.config.font || !this.config.font.size) {
        this.config['font']['size'] = '40px';
      }
      if (!this.config.font || !this.config.font.family) {
        this.config['font']['family'] = 'Arial';
      }
      if (!this.config.strokeColor) {
        this.config['strokeColor'] = '#f20c6c';
      }
      if (!this.config.length) {
        this.config['length'] = 6;
      }
      if (!this.config.cssClass) {
        this.config['cssClass'] = '';
      }

      if (!this.config.type) {
        this.config['type'] = 1;
      }

      if (!this.config.back || !this.config.back.stroke) {
        this.config['back']['stroke'] = '';
      }
      if (!this.config.back || !this.config.back.solid) {
        this.config['back']['solid'] = '#f2efd2';
      }

      this.createCaptcha();
    }
  }
  createCaptcha() {
    switch (this.config.type) {
      case 1: // only alpha numaric degits to type
        // eslint-disable-next-line no-case-declarations
        const char =
          Math.random().toString(24).substring(2, this.config.length) +
          Math.random().toString(24).substring(2, 4);
        this.code = this.resultCode = char.toUpperCase();
        break;
      case 2: // solve the calculation
        const num1 = Math.floor(Math.random() * 99);
        const num2 = Math.floor(Math.random() * 9);
        const operators = ['+', '-'];
        const operator = operators[Math.floor(Math.random() * operators.length)];
        this.code = num1 + operator + num2 + '=?';
        this.resultCode = operator == '+' ? num1 + num2 : num1 - num2;
        break;
    }
  }

  playCaptcha() {
    const msg = new SpeechSynthesisUtterance(this.code.split('').join(' '));
    msg.pitch = 0.1;
    window.speechSynthesis.speak(msg);
  }

  checkCaptcha() {
    if (this.captchaText.value != this.resultCode) {
      this.captchService.setCaptchaStatus(false);
      this.validCaptcha = false;
      this.captchaCode.emit(false);
      if (this.captchaText.value) {
        this.createCaptcha();
      }
    } else {
      this.captchService.setCaptchaStatus(true);
      this.validCaptcha = true;
      this.captchaCode.emit(true);
    }
    this.cdr.detectChanges();
  }
}
