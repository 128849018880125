<div appTrapFocus appAutoFocusModal appRefocusPreviousEl role="dialog" aria-labelledby="duration-head"
  aria-describedby="duration-desc">
  <div class="modal-header" id="duration-head">
    <h4 class="modal-title pull-left"> {{ title }} </h4>
    <!--    <button type="button" class="close pull-right" aria-label="Close" (click)="modalRef.hide()">-->
    <!--      <span aria-hidden="true">&times;</span>-->
    <!--    </button>-->
  </div>
  <div class="modal-body" id="duration-desc">
    <p>{{data.description}}</p>
  </div>
  <div class="modal-footer">
    <teams-button [classList]="'btn ts-btn-fluent-primary modal-ok-button'" (buttonClick)="onConfirm()"
      [title]="data.successButtonText"></teams-button>
  </div>
</div>