import { Injectable } from '@angular/core';

import { ActiveSubTabModel, ActiveTabModel, CloseSubTabModel } from '../models/case-study.model';

@Injectable({
  providedIn: 'root'
})
export class CaseStudyHelperService {

  /**
   * @description: Close Sub Tab
   */
  public closeSubTab(tabs, selectedTab, subTabs): CloseSubTabModel {
    if (tabs.length > 0) {
      tabs.find((i, ind) => i.title === selectedTab.title).open = !selectedTab.open;
      if (subTabs?.length > 0) {
        subTabs.forEach(element => {
          element.active = false;
        });
      }
      return { tabsValue: tabs, subTabsValue: subTabs };
    }
    //below code to make this function return value in all states, might need to change later
    else return { tabsValue: undefined, subTabsValue: undefined}
  }
  /**
   * @description: Currently Active Tab
   */
  public activeTabs(tabs, tab, showQuestions): ActiveTabModel {
    tabs.forEach(element => {
      element.active = false;
    });
    showQuestions = false;

    tabs.find(i => i.title === tab.title).active = true;
    tabs.filter(i => i.title !== tab.title).forEach(e => {
      e.open = false;
      e.subTabs.forEach(element => {
        element.active = false;
      });
    });
    return { tabsValue: tabs, showQuestionsValue: showQuestions };
  }
  /**
   * @description: Currently Active Sub Tab
   */
  public activeSubTabs(subTabs, subTab, showQuestions): ActiveSubTabModel {
    subTabs?.forEach(element => {
      element.active = false;
    });
    showQuestions = false;
    subTabs.find(i => i.title === subTab.title).active = true;
    return { subTabsValue: subTabs, showQuestionsValue: showQuestions };
  }

}
