import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
  name: 'formatTime',
})
export class FormatTimePipe implements PipeTransform {
  transform(value: number): string {
    const days: number = Math.floor(value / 3600 / 24);
    const hours: number = Math.floor((value / 3600) % 24);
    const minutes: number = Math.floor((value % 3600) / 60);
    let dayString = '';
    if (days > 0) {
      dayString = days < 10 ? '0' + days : days.toString();
      dayString += 'd : ';
    }
    return (
      dayString +
      ('00' + hours).slice(-2) +
      'h : ' +
      ('00' + minutes).slice(-2) +
      'm : ' +
      ('00' + Math.floor(value - minutes * 60)).slice(-2) +
      's'
    );
  }
}
