import { ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { CommonService } from '@questions-ui';
import { EventEmitterService, UserSessionService } from '@teams-auth';
import { ButtonLoadingService } from '@teams-ui-angular';
import { NotificationService, unsubscribeCollection } from '@utility';
import { Subscription } from 'rxjs';

@Component({
  selector: 'cloudlabs-inline-question',
  templateUrl: './inline-question.component.html',
  styleUrls: ['./inline-question.component.scss']
})
export class InlineQuestionComponent implements OnInit {
  @Input() inlineQuestionTag: any;
  @Input() pageNumber: any;
  @Input() questionData: any;
  @Input() questionStyle: any;
  @Input() setFullWidth = false;
  @Input() questIndex: number;
  @Input() remainingRetries: number;
  private subscriptions: Subscription[] = [];
  private voucherCode: string;
  public ungroupedQuestions: any
  private groupedUserAnswer:any = []
  public isInlineQuestion;
  public savedUngroupedData;
  public feedBackExplanation: string
  public savedGroupedData: any;
  public isRetryGroup = false;
  public isRetryUngroup = false;
  public btnGroupShow: boolean;
  public btnUngroupShow: boolean;
  public isRetryClicked = false
  public isSubmitClicked = false

  constructor(
    private eventEmitter: EventEmitterService,
    private commonService: CommonService,
    private aroute: ActivatedRoute,
    private notificationSrv: NotificationService,
    public btnLoadingService: ButtonLoadingService,
    private translateSrv: TranslateService,
    public userSessionService: UserSessionService,
    private cdr: ChangeDetectorRef,
  ) {
      this.subscriptions[this.subscriptions.length] = this.aroute.params.subscribe((i) => {
        this.voucherCode = i.voucherCode
      });
  }

  ngOnInit(): void {
    this.isInlineQuestion = true
      this.subscriptions[this.subscriptions.length] = this.userSessionService.isQuestionLoaded$.subscribe((questLoaded: boolean) => {
        if (questLoaded) {
          this.btnGroupShow = this.questionData?.isCorrect
          this.btnUngroupShow = this.questionData?.isCorrect
          if (this.questionData?.userAnswersHistory?.length > 0) {
            this.isRetryUngroup = true 
            this.isRetryClicked = false
            this.isSubmitClicked = true
          }
          this.questionData?.questions?.forEach(question => {
            if (question.userAnswersHistory?.length > 0) {
              this.isRetryGroup = true
              this.isRetryClicked = false
              this.isSubmitClicked = true
            }
          });
          if (this.questionData) {
            unsubscribeCollection(this.subscriptions);
          }
          
        }
      })
  }

    public saveUngroupAnswer(questionUniqueName: string, btnContext: string): void {
      this.btnLoadingService.indexValue = this.questIndex
      if (btnContext === 'submit') {
        if (this.questionData?.userAnswers.length > 0) {
          this.btnLoadingService.setLoding(true, this.translateSrv.instant('BUTTON_SUBMITTING'));
            const data = {
              QuestionUniqueName: questionUniqueName,
              userAnswer: this.questionData?.userAnswers
            }
            this.commonService.updateUngroupedAnswer(this.voucherCode, data).subscribe((updateRes: any) => {
              if (updateRes?.status === 'Success') {
                
                this.isRetryUngroup = true
                this.isRetryClicked = false
                this.isSubmitClicked = true
                this.questionData.remainingRetries = updateRes?.userAnswerUpdateResponseDetail?.remainingRetries
                this.questionData.userScore = updateRes?.userAnswerUpdateResponseDetail.userScore
                this.btnUngroupShow = updateRes?.userAnswerUpdateResponseDetail?.isCorrect
                this.cdr.detectChanges()
                this.btnLoadingService.buttonLodingOff();
                this.feedBackExplanation = updateRes?.userAnswerUpdateResponseDetail?.feedback
                this.savedUngroupedData = {
                  type: 'ungrouped',
                  questionUniqueName: questionUniqueName,
                  isCorrect: updateRes?.userAnswerUpdateResponseDetail?.isCorrect,
                  answerKey: this.questionData?.userAnswers,
                  correctAnswers: updateRes?.userAnswerUpdateResponseDetail?.correctAnswers,
                  remainingRetries: updateRes?.userAnswerUpdateResponseDetail?.remainingRetries,
                  userAnswers: updateRes?.userAnswerUpdateResponseDetail?.userAnswers
                }
              } else {
                //Error
                this.btnLoadingService.buttonLodingOff();
                const code = 'Error - 30049';
                this.notificationSrv.error(updateRes.message, null)
                this.eventEmitter.debugAlert(code, updateRes.message);
              }
            })
          }
      } else if (btnContext === 'retry') {
        this.btnLoadingService.setLoding(true, '');
        this.isRetryClicked = true
        this.isRetryUngroup = false
        this.isSubmitClicked = false
        this.questionData.userAnswers = []
        this.questionData.userAnswersHistory = []
        this.btnLoadingService.buttonLodingOff();
      }
      
    }

    public saveGroupAnswer(questionUniqueName: string, btnContext: string): void {
      this.btnLoadingService.indexValue = this.questIndex
      if (btnContext === 'submit') {
        if (this.isUserAnsweredAll(this.questionData?.questions)) {
          this.btnLoadingService.setLoding(true, this.translateSrv.instant('BUTTON_SUBMITTING'));
          const UserAnswers = this.groupedUserAnswer
          const data = {
            UserAnswers
          }
          this.commonService.updateGroupedAnswer(this.voucherCode, data).subscribe((updateRes: any) => {
            if (updateRes?.status === 'Success') {
              this.isRetryGroup = true
              this.isRetryClicked = false
              this.isSubmitClicked = true
              this.questionData.remainingRetries = updateRes?.remainingRetries
              this.feedBackExplanation = updateRes?.userAnswerUpdateResponseDetails?.feedback
              this.questionData.userScore = updateRes?.userScore
              this.btnLoadingService.buttonLodingOff();
              this.btnGroupShow = updateRes?.isCorrect
              this.cdr.detectChanges()
              this.btnLoadingService.buttonLodingOff();
              this.questionData?.questions.forEach(que => {
                updateRes?.userAnswerUpdateResponseDetails?.forEach(feedbackElement => {
                  if (que?.questionUniqueName === feedbackElement.questionUniqueName) {
                    que.feedback = feedbackElement.feedback
                    que.isCorrect = feedbackElement.isCorrect
                  }
                });
              });
              const arrayGroup = this.filterArr(this.groupedUserAnswer, updateRes?.userAnswerUpdateResponseDetails)
              
              this.savedGroupedData = {
                type: 'grouped',
                questionUniqueName: this.groupedUserAnswer,
                isCorrect: updateRes?.userAnswerUpdateResponseDetails[0]?.isCorrect,
                userAnswerUpdateResponseDetails: updateRes?.userAnswerUpdateResponseDetails,
                correctAnswers: updateRes?.userAnswerUpdateResponseDetails?.correctAnswers,
                remainingRetries: updateRes?.remainingRetries,
                mergedArrayGroupe: arrayGroup
              }
            } else {
              //Error
              this.btnLoadingService.buttonLodingOff();
              const code = 'Error - 30050';
              this.notificationSrv.error(updateRes.message, null)
              this.eventEmitter.debugAlert(code, updateRes.message);
            }
          })
        }
      }else if (btnContext === 'retry') {
        this.btnLoadingService.setLoding(true, '');
        this.isRetryClicked = true
        this.isRetryGroup = false
        this.isSubmitClicked = false
        this.questionData.userAnswers = []
        this.groupedUserAnswer = []
        this.questionData.questions.forEach(question => {
          question.userAnswers = []
          question.userAnswersHistory = []
        });
        this.btnLoadingService.buttonLodingOff();
      }
      
        
    }

    filterArr(arrayOne, arrayTwo) {
      return arrayOne = arrayOne.map((arr1)=>{

        const mergedArr = arrayTwo.find((arr2)=>arr2.questionUniqueName==arr1.QuestionUniqueName)
        if(mergedArr){
          arr1 = {...arr1,...mergedArr}
        }
        return arr1
      })
    }

    isUserAnsweredAll(questions): boolean {
      return questions?.every(question => question.userAnswers?.length > 0)
    }

    /**
   * @description: Update Exam Question Instance
   */
  public userSelectAnswerOption(event): void {
    // Removing settimeout as creating issue in saving data for text input | sheikh
    // setTimeout(() => { 
      // event?.options.forEach(checkedOption => {
        const data = {
          QuestionUniqueName: event.questionUniqueName,
          userAnswer: event.userAnswers
        }
        const index = this.groupedUserAnswer.findIndex((e)=>e.QuestionUniqueName==event.questionUniqueName)
        if(index>-1){
          this.groupedUserAnswer[index].userAnswer = event.userAnswers
        } else {
          this.groupedUserAnswer.push(data)
        }
      
    // }, 1000)
  }
}
