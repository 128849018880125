<div *ngIf="isDialog" class="badge-section">
  <span class="secondary-badge"> {{overallStatus?.notValidatedCount}} {{ "NOT_VALIDATED" | translate }}</span>
  <span class="primary-badge text-primary"> {{overallStatus?.pendingCount}} {{ "PENDING_CAPITAL" | translate }}</span>
  <span class="success-badge"> {{overallStatus?.successCount}} {{ "SUCCESS_CAPITAL" | translate}}</span>
  <span class="failed-badge"> {{overallStatus?.failedCount}} {{ "FAILED" | translate }}</span>
</div>

<ng-container *ngIf="(!loading || isRefreshing) && !isInlineValidation">
  <div class="row" *ngIf="templateValidation?.TemplateValidationStatus?.length > 0 && !isDialog">
    <div class="col-lg-9"></div>
    <div class="col-lg-3 refresh-btn">
      <div style="float: right; padding-bottom: 15px">
        <button role="button" class="btn btn-sm ts-btn-fluent-primary refresh" tabindex="0"
          attr.aria-label="{{ 'REFRESH_LAB_VALIDATION' | translate }}" (click)="getLabValidationData()"
          (keyup.enter)="getLabValidationData()">
          <span style="font: normal normal normal 14px/1" class="fas fa-sync-alt vm-action-buttons mr-2 refresh-vm"
            [ngClass]="{ rotate: isRefreshing }" role="button" title="{{ 'REFRESH_LAB_VALIDATION' | translate }}">
            <span *ngIf="!docActions"> </span>
          </span>
          <span>{{ "BUTTON_REFRESH" | translate }}</span>
        </button>
      </div>
    </div>
  </div>
  <div *ngIf="!templateValidation ||
      (templateValidation?.TemplateValidationStatus?.length === 0 &&
      !isRefreshing)
    ">
    <div class="no-modules alert" [ngClass]="{
      'alert-custom alert-light-primary process-azurepass' : isMylearning,
      'alert-info' : !isMylearning
    }">
      <strong translate="NO_VALIDATION_FOUND"></strong>
    </div>
  </div>
  <div *ngIf="
      templateValidation?.TemplateValidationStatus?.length > 0 && !isRefreshing
    " class="portlet box default">
    <div class="row">
      <div class="col-lg-12 mx-auto">
        <div [id]="'accordionExample' + (isDialog ? 'dialog' : '')" class="accordion shadow">
          <div class="card" *ngFor="let module of templateValidation?.TemplateValidationStatus">
            <div [id]="'headingOne' + module.ModuleNumber" class="card-header bg-white shadow-sm border-0">
              <div style="font-size: 1rem" role="heading" aria-level="2" class="mb-0 font-weight-bold labTitle">
                <!-- <a href="#" data-toggle="collapse" data-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne" class="d-block position-relative text-dark text-uppercase collapsible-link py-2">{{module.ModuleNumber}}. {{module.Name}}</a>-->
                <a role="button" href="javascript:void(0)" data-toggle="collapse"
                  [attr.data-target]="'#collapseOne' + module.ModuleNumber + (isDialog ? 'dialog' : '')" aria-expanded="true"
                  [attr.aria-controls]="'collapseOne' + module.ModuleNumber + (isDialog ? 'dialog' : '')" class="
                    labTitle-anchor
                    d-block
                    position-relative
                    text-dark text-uppercase
                    labTitle-anchor
                    collapsible-link
                    py-2
                  ">{{ module.ModuleNumber }}. {{ module.Name }}</a>
                <div class="actionLInks">
                  <a class="actionLInks-anchor mr-3" role="button" title="{{ 'VALIDATION_SUCCESS' | translate }}"
                    *ngIf="module.ConsolidatedStatus == 'Succeeded'"><i role="img" aria-label="img" aria-hidden="true"
                      class="fa fa-check-square" style="color: forestgreen"></i></a>
                  <a class="actionLInks-anchor" role="button" href="javascript:void(0)"
                    (click)="validateModule(module, 'validateAll')" *ngIf="module.ConsolidatedStatus != 'Succeeded'"
                    title="{{ 'VALIDATION_PENDING' | translate }}"><i class="fa fa-exclamation-triangle"
                      style="color: #f2784b"></i>
                  </a>
                </div>
              </div>
            </div>
            <div [id]="'collapseOne' + module.ModuleNumber + (isDialog ? 'dialog' : '')" [attr.aria-labelledby]="'headingOne' + module.ModuleNumber"
              [attr.data-parent]="'#accordionExample' + (isDialog ? 'dialog' : '')" class="collapse show">
              <div class="card-body p-0">
                <div class="row" style="text-align: center; font-weight: bold; padding: 10px"
                  *ngIf="module.Exercises?.Type == 'lab'" translate="MESSAGE_NO_LABS"></div>
                <div *ngFor="let exercise of module?.Exercises">
                  <div class="row" *ngIf="exercise.Type === 'lab'">
                    <div class="table-responsive col-md-12">
                      <p style="background-color: #eee" class="text-left mb-0 exercise-name pt-3 pb-2">
                        <b role="heading" aria-level="3">{{ exercise.Name }}</b>
                      </p>
                      <table class="table lab-validation-table" [attr.aria-label]="
                          'Lab Validation table for ' + exercise.Name
                        " tabindex="0">
                        <tr style="background-color: #eee">
                          <th>{{ "VALIDATION_STEP" | translate }}</th>
                          <th class="hide-column">
                            {{ "MESSAGE" | translate }}
                          </th>
                          <th>{{ "STATUS" | translate }}</th>
                          <th>{{ "ACTIONS" | translate }}</th>
                        </tr>
                        <tbody>
                          <tr *ngFor="let row of exercise.Steps; let i = index">
                            <td>
                              {{ row.Name }}
                            </td>
                            <td class="hide-column">
                              {{ row.Message }}
                            </td>
                            <td class="status_val d-flex align-items-center">
                              <span *ngIf="row.Status == 'Succeeded'" title="{{ row.Message }}"
                                class="text-white label bg-success" style="float: left">{{ "SUCCESS_CAPITAL" | translate
                                }}</span>
                              <span *ngIf="row.Status == 'Failed'" class="text-white label bg-danger"
                                style="float: left">{{ "FAILED" | translate }}</span>
                              <span *ngIf="row.Status == 'Not Validated'" title="{{ row.Message }}" class="
                                  text-white
                                  label
                                  bg-info bg-not-validated
                                " style="float: left">{{ "NOT_VALIDATED" | translate }}</span>
                              <span *ngIf="row.Status == 'Validation Pending'" title="{{ row.Message }}"
                                class="text-white label bg-primary bg-pending" style="float: left">{{ "PENDING_CAPITAL"
                                | translate }}</span>
                              <span *ngIf="row.Status == 'In Progress'" class="
                                  text-white
                                  label
                                  bg-primary bg-in-progress
                                " style="float: left">{{ "IN_PROGRESS" | translate }}</span>
                              <span *ngIf="row.Status == 'Failed'" 
                                tabindex="0" 
                                class="info-tooltip" 
                                triggers="click"
                                [autoClose]="true"
                                [ngbTooltip]="row.Message"
                                #tooltip3="ngbTooltip"
                                container="body"
                                [attr.aria-label]="row.Message">
                                <i class="fas fa-info-circle"></i>
                              </span>
                            </td>
                            <td style="text-align: left">
                              <div *ngIf="
                                  row.Status == 'Failed' ||
                                  row.Status == 'Not Validated'
                                ">
                                <!-- <button role="button" tabindex="0" class="btnValidate btn ts-btn-fluent-outline" (keydown.enter)="
                                    validateManualStep(module, row)
                                  " (click)="validateManualStep(module, row)"
                                  title="{{ 'VALIDATE_STEP' | translate }}">
                                  <span class="mr-1 align-middle spinner-border spinner-border-sm"
                                role="status"></span><i class="fa fa-check"></i><span
                                    style="margin-left: 5px; text-wrap: nowrap;">{{
                                    "BUTTON_VALIDATE" | translate
                                    }}</span></button> -->
                                
                                <teams-button [classList]="'btn ts-btn-fluent-primary text-nowrap'" (buttonClick)="validateManualStep(module, row)"
                                  [index]="row.StepId" [processingTitle]="btnLoadingService.getButtonText()" [showLoading]="btnLoadingService.getLoding()"
                                  [title]="'BUTTON_VALIDATE'| translate">
                                </teams-button>
                              </div>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                      <div class="no-validation" *ngIf="exercise.Steps && exercise.Steps.length === 0">
                        <p>{{ "NO_VALIDATIONS_FOUND" | translate }}</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</ng-container>

<ng-container *ngIf="isInlineValidation && inlineValData">
  <div class="inline-validation-section mt-3">
    <div class="refresh-btn">
    </div>
      <div class="inline-validation " >
        <div class="d-flex justify-content-between mb-3">
          <div class="sql-title">{{'VALIDATION_CHECK' | translate}}</div>
          <div class="sql-title" *ngIf="inlineValData?.StepScore > 0 && !isLabPreview">{{inlineValData?.ScoreEarned}}/{{inlineValData?.StepScore}} {{'POINTS' | translate}}</div>
        </div>
        <span>
          {{ inlineValData.StepName }}
        </span>
        <hr *ngIf="!isLabPreview">
        <div class="d-flex justify-content-between align-items-center">
          <div *ngIf="inlineValData.Status !== 'Not Validated' && !isLabPreview">{{'STATUS' | translate}}: 
              <ng-container *ngIf="inlineValData.Status === 'Failed'">
                <span class="text-danger">{{"FAILED" | translate}}</span> &nbsp;&nbsp;
                <a class="actionLInks-anchor mr-3" role="button" href="javascript:void(0)"
                  (click)="openVlidationModel()"> {{'SEE_WHY' | translate}}</a>&nbsp;&nbsp;
              </ng-container>
              <span class="text-success" *ngIf="inlineValData.Status === 'Succeeded'"> <b>{{ "SUCCESS_CAPITAL" | translate}}</b> </span>
              <b class="text-primary" *ngIf="inlineValData.Status === 'Validation Pending'">{{ "PENDING_CAPITAL" | translate}}</b>
              <b class="text-primary" *ngIf="inlineValData.Status === 'In Progress'">{{ "IN_PROGRESS" | translate}}</b>
          </div>
          <div *ngIf="inlineValData.Status === 'Not Validated'"></div>
          <div class="d-flex align-items-center mt-2 float-right" *ngIf="!isLabPreview">
            <ng-container *ngIf="inlineValData.Status === 'Failed' || inlineValData.Status === 'Not Validated'" >
              
              <!-- <ng-container *ngIf="inlineValData.Status === 'Failed'">
                <span class="text-danger">{{"FAILED" | translate}}</span> &nbsp;&nbsp;
                <a class="actionLInks-anchor mr-3" role="button" href="javascript:void(0)"
                  (click)="openVlidationModel()"> {{'SEE_WHY' | translate}}</a>&nbsp;&nbsp;
              </ng-container> -->
              
              <teams-button
                [classList]="'btn ts-btn-fluent-primary'" (buttonClick)="validateManualStep('module', inlineValData)"
                [title]="inlineValData.Status === 'Failed' ? ('RETRY_VALIDATION' | translate) : ('BUTTON_VALIDATE' | translate)"
                [index]="inlineValData.StepId" [processingTitle]="btnLoadingService.getButtonText()" [showLoading]="btnLoadingService.getLoding()">
              </teams-button>
            </ng-container>
            
            <!-- <span class="text-success" *ngIf="inlineValData.Status === 'Succeeded'"> <b>{{ "SUCCESS_CAPITAL" | translate}}</b> </span> -->
            
            <div class="text-primary d-flex align-items-center" *ngIf="inlineValData.Status === 'Validation Pending' || 
              inlineValData.Status === 'In Progress'"> 
              <!-- <b *ngIf="inlineValData.Status === 'Validation Pending'">{{ "PENDING_CAPITAL" | translate}}</b>
              <b *ngIf="inlineValData.Status === 'In Progress'">{{ "IN_PROGRESS" | translate}}</b> -->
              <div class="ml-3">
                <teams-button
                  [classList]="'btn ts-btn-fluent-primary'" (buttonClick)="refreshvalidation()"
                  [title]="'BUTTON_REFRESH' | translate"
                  [index]="inlineValData.StepId" [processingTitle]="btnLoadingService.getButtonText()" [showLoading]="btnLoadingService.getLoding()">
                </teams-button>
              </div>
            </div>
          </div>

          <!-- <div class="d-flex align-items-center mt-2 float-right" *ngIf="!isLabPreview">
            <ng-container *ngIf="inlineValData.Status === 'Failed' || inlineValData.Status === 'Not Validated'" >
              
              <ng-container *ngIf="inlineValData.Status === 'Failed'">
                <span class="text-danger">{{"FAILED" | translate}}</span> &nbsp;&nbsp;
                <a class="actionLInks-anchor mr-3" role="button" href="javascript:void(0)"
                  (click)="openVlidationModel()"> {{'SEE_WHY' | translate}}</a>&nbsp;&nbsp;
              </ng-container>
              
              <teams-button
                [classList]="'btn ts-btn-fluent-primary'" (buttonClick)="validateManualStep('module', inlineValData)"
                [title]="inlineValData.Status === 'Failed' ? ('RETRY_VALIDATION' | translate) : ('BUTTON_VALIDATE' | translate)"
                [index]="inlineValData.StepId" [processingTitle]="btnLoadingService.getButtonText()" [showLoading]="btnLoadingService.getLoding()">
              </teams-button>
            </ng-container>
            
            <span class="text-success" *ngIf="inlineValData.Status === 'Succeeded'"> <b>{{ "SUCCESS_CAPITAL" | translate}}</b> </span>
            
            <div class="text-primary d-flex align-items-center" *ngIf="inlineValData.Status === 'Validation Pending' || 
              inlineValData.Status === 'In Progress'"> 
              <b *ngIf="inlineValData.Status === 'Validation Pending'">{{ "PENDING_CAPITAL" | translate}}</b>
              <b *ngIf="inlineValData.Status === 'In Progress'">{{ "IN_PROGRESS" | translate}}</b>
              <div class="ml-3">
                <teams-button
                  [classList]="'btn ts-btn-fluent-primary'" (buttonClick)="refreshvalidation()"
                  [title]="'BUTTON_REFRESH' | translate"
                  [index]="inlineValData.StepId" [processingTitle]="btnLoadingService.getButtonText()" [showLoading]="btnLoadingService.getLoding()">
                </teams-button>
              </div>
            </div>
          </div> -->
        </div>
        
      </div>
  </div>
</ng-container>

<!--   validate Manual Step start    -->
<div class="modal modelShow modulevalidation" role="dialog" *ngIf="modelShow" id="validation" appTrapFocus
  appAutoFocusModal appRefocusPreviousEl aria-labelledby="lab-val-input-head">
  <div class="modal-dialog input-validation-model">
    <div class="modal-content">
      <div class="modal-header" id="lab-val-input-head">
        <h4 class="modal-title">{{ "PARAMETER" | translate }}</h4>
      </div>
      <div class="modal-body" aria-label="modal description">
        <h6 class="attendee-input-title">
          {{ "PARAMETER_INPUT_MESSAGE" | translate }}
        </h6>
        <div *ngFor="let attendeeParameter of attendeeParameters" class="attendee-par-input-container d-flex flex-wrap align-items-center">
          <div class="mr-3">{{attendeeParameter.Name}}</div>
          <!-- <div class="col-md-12 col-lg-4">
            <teams-input [ngClassList]="showValidationError[attendeeParameter.Name] ? 'error-2': ''" [classList]="'attendee-par-input'" [value]="attendeeParameter.Name" [disabled]="true"></teams-input>
          </div> -->
          <div>
            <teams-input [ngClassList]="showValidationError[attendeeParameter.Name] ?
                'error-field' : '' " [placeholder]="'VALUE_LABEL' | translate" [(ngModel)]="attendeeParameter.Value"
              [classList]="'attendee-par-input modal-input'" (change)="onChange($event, attendeeParameter)"
              [value]="attendeeParameter['Value']"></teams-input>
          </div>
          <div class="ml-4 mt-2 error-2" *ngIf="showValidationError[attendeeParameter.Name]">
            {{ "MESSAGE_THIS_FIELD_REQUIRED" | translate }}
          </div>
        </div>
      </div>
      <div class="modal-footer">
        <teams-button [classList]="'btn ts-btn-fluent-red modal-close-button'" (buttonClick)="modelShow = false; btnLoadingService.buttonLodingOff();"
          data-dismiss="modal">
          {{ "CANCEL" | translate }}
        </teams-button>
        <teams-button [classList]="'btn ts-btn-fluent-primary modal-ok-button'" (buttonClick)="validateSubmit()">
          {{ "BUTTON_SUBMIT" | translate }}
        </teams-button>
      </div>
    </div>
  </div>
</div>
<!--   validate Manual Step End    -->


<div class="modal modelShow modulevalidation" role="dialog" *ngIf="validationModel" id="validation" appTrapFocus
  appAutoFocusModal appRefocusPreviousEl aria-labelledby="lab-val-input-head">
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header" id="lab-val-input-head">
        <h4 class="modal-title">{{'VALIDATION_FAILED' | translate}}</h4>
      </div>
      <div class="modal-body" aria-label="modal description">
        <div class="validation-failed">
          <p>{{modelData.Name}}</p>
          <!-- <div class="text-danger"><b>Validition failed, Please recheck the step again.</b></div> -->
          <div class="text-danger">{{modelData.Message}}</div>
        </div>
      </div>
      <div class="modal-footer">
        <teams-button [classList]="'btn ts-btn-fluent-primary modal-ok-button'" (buttonClick)="validationModel = false"
          data-dismiss="modal">
          {{ "OK" | translate }}
        </teams-button>
      </div>
    </div>
  </div>
</div>
