<ul class="cip-left-list">
    <li (click)="showQuestionData()" *ngIf="subtab === miscellaneousConst?.answeredArrayMinLength"> <a [ngClass]="showQuestions?'active':''"> {{
      'common.questions' | translate
      }}</a>
    </li>
    <ng-container *ngFor="let tab of tabs;let ind=index;">
        <li (click)="activeTabs(tab,ind);$event.stopPropagation(); openSubTab(tab);">
            <a [ngClass]="tab?.active?'active':''"> <span
          *ngIf="tab?.subTabs.length > miscellaneousConst?.answeredArrayMinLength">
          <i class="ms-Icon ms-Icon--CalculatorSubtract float-left" *ngIf="tab.open" title="minus">
          </i><span class="ml-2" *ngIf="tab.open">{{tab.title}}</span>
          <i class="ms-Icon ms-Icon--CalculatorAddition float-left" *ngIf="!tab.open" title="Addition"></i><span
            class="ml-2" *ngIf="!tab.open">{{tab.title}}</span>
        </span><span *ngIf="tab?.subTabs.length === miscellaneousConst?.answeredArrayMinLength"> {{tab.title}}
        </span></a>
            <ul class="cip-sub-left-list">
                <app-casestudy-side-menu [tabs]="tab?.subTabs" *ngIf="tab?.open" (tabIndexEmit)="getTabIndex($event)" [subtab]="1"></app-casestudy-side-menu>
            </ul>
        </li>
    </ng-container>
</ul>
