import {
  Resolve,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
} from '@angular/router';
import { Observable, of } from 'rxjs';
import { Injectable } from '@angular/core';
import { catchError } from 'rxjs/internal/operators';
import { Lab } from '../modals/lab.model';
import { LabService } from '../services/lab.service';
import { EventEmitterService } from '@teams-auth';

@Injectable({ providedIn: 'root' })
export class AttendeeLabResolver implements Resolve<any> {
  constructor(private labSrv: LabService, private eventEmitter: EventEmitterService) {}

  resolve(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<Lab> {
    if (route.params.voucherCode !== 'anonymous') {
      return this.labSrv.getAttendeeLab(route.params.voucherCode).pipe(
        catchError((error) => {
          const code = 'Error - 70001';
          this.eventEmitter.debugAlert(code, error.error);
          return of(null);
        })
      );
    } else {
      const code = 'Error - 70002';
      this.eventEmitter.debugAlert(code, "Error getting Labs")
      return of(null);
    }
  }
}
