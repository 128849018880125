import { Injectable } from '@angular/core';
import {Subject} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class NotesService {
  static counter = 0;
  notesToBeShown = [];
  notesObserver = new Subject();

  constructor() {
    // this.notesObserver.next(this.notesToBeShown);
  }

  addNote(note: string, innerHTML:string=null) {
    NotesService.counter++;
    this.notesToBeShown.push({
      id: NotesService.counter,
      text: note,
      innerHTML:innerHTML
    });
    this.notesObserver.next(this.notesToBeShown);
  }

  removeNote(id: string) {
    this.notesToBeShown = this.notesToBeShown.filter((note) => {
      return note.id !== id;
    });
    this.notesObserver.next(this.notesToBeShown);
  }

  clearAllNotes() {
    this.notesToBeShown = [];
    this.notesObserver.next(this.notesToBeShown);
    NotesService.counter = 0;
  }
}
