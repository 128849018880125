import { Component } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { EventEmitterService } from '@teams-auth';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { EventService } from '../../../../services/event.services';
import { LabService } from '../../../../services/lab.service';
import { SurveyThankyouDialogComponent } from '../survey-thankyou-dialog/survey-thankyou-dialog.component';

@Component({
  selector: 'cloudlabs-survey-dialog',
  templateUrl: './survey-dialog.component.html',
  styleUrls: ['./survey-dialog.component.scss'],
})
export class SurveyDialogComponent {
  public title: string;
  public data: any;
  public res: any;
  public id: any;
  public modal = false;
  constructor(
    private labSrv: LabService,
    public modalRef: BsModalRef,
    private modalService: BsModalService,
    public eventSrv: EventService,
    private translateSrv: TranslateService,
    private eventEmitter: EventEmitterService
  ) {}

  public onConfirm(): void {
    this.modalRef.hide();
    this.labSrv.addSurvetFormData(this.id, this.res).subscribe((res) => {
      if (res) {
        this.modalRef = this.modalService.show(SurveyThankyouDialogComponent, {
          initialState: {
            data: {
              description: this.translateSrv.instant(
                'THANKS_SURVEY_DESCRIPTION'
              ),
              successButtonText: this.translateSrv.instant('OK'),
            },
          },
        });
      }
    }, (error) => {
      const code = 'Error - 50029';
      this.eventEmitter.debugAlert(code, error);
    });
  }

  public onCancel(): void {
    this.modalRef.hide();
  }
}
