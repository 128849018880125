<div class="m-0 mb-4 p-4 inline-question-container" [ngClass]="{'width-100':setFullWidth}" *ngIf="questionData">
    <ng-container>
        <!-- Ungrouped Questions -->
        <ng-container
            *ngIf="questionStyle === 'ungroupedQuest' && (questionData?.questionType === 'Single Choice' || questionData?.questionType === 'Multiple Choice' || questionData?.questionType === 'TextInput')">
            <div class="d-flex flex-column">
                <div class="d-flex justify-content-between">
                    <span class="sql-title text-uppercase">{{'common.question' | translate}}</span>
                    <span *ngIf="questionData?.questionScore > 1" class="sql-title">{{questionData?.userScore}}/{{questionData?.questionScore}} 
                        {{'POINTS' | translate}}</span>
                        <span *ngIf="questionData?.questionScore === 1" class="sql-title">{{questionData?.userScore}}/{{questionData?.questionScore}}
                            {{'POINT' | translate}}</span>
                </div>
                <div class="d-inline-flex">
                    <!-- <div class="mt-2 mr-3 numberClassInlineQuestion">
                    </div> -->
                    <app-question [isCorrectAnswer]="savedUngroupedData" [isInlineQuestion]="isInlineQuestion"
                        [question]="questionData" [inlineQuestionData]="questionData" [questionStyle]="questionStyle" (UserSelectAnswerOption)="userSelectAnswerOption($event)"
                        [userAnswer]="this.questionData?.userAnswers" [isRetryClicked]="isRetryClicked" [isSubmitClicked]="isSubmitClicked">
                    </app-question>
                </div>

                <div class="p-4" *ngIf="feedBackExplanation" [ngClass]="{'success-explanation':savedUngroupedData?.isCorrect,'error-explanation':!savedUngroupedData?.isCorrect}">
                    <span style="text-decoration: underline">{{'exam.result.explanation' | translate}}:</span>
                    <p class="mb-0 mt-2">{{feedBackExplanation}}</p>
                </div>
                <hr class="w-100" [ngClass]="{'mt-0':!feedBackExplanation}">
                <ng-container *ngIf="!btnUngroupShow">
                    <ng-container *ngIf="(questionData?.remainingRetries > 0 && questionData?.totalRetries !== 0) || (questionData?.totalRetries === 0)">
                        <div class="d-flex flex-row-reverse">
                            <teams-button *ngIf="!isRetryUngroup"
                                [classList]="'btn ts-btn-fluent-primary'"
                                (buttonClick)="saveUngroupAnswer(questionData?.questionUniqueName, 'submit')"
                                [title]="'actions.submit' | translate"
                                [showLoading]="btnLoadingService.getLoding()"
                                [index]="questIndex"
                                ></teams-button>
                            <teams-button *ngIf="isRetryUngroup"
                                [classList]="'btn ts-btn-fluent-primary'"
                                (buttonClick)="saveUngroupAnswer(questionData?.questionUniqueName, 'retry')"
                                [title]="'actions.retry' | translate"
                                [showLoading]="btnLoadingService.getLoding()"
                                [index]="questIndex"
                                ></teams-button>
                        </div>
                    </ng-container>
                </ng-container>
            </div>

        </ng-container>
        <!-- Grouped Questions -->

        <ng-container *ngIf="questionStyle === 'groupedQuest'">
            <div class="d-flex justify-content-between">
                <span class="sql-title text-uppercase">{{'common.questions' | translate}}</span>
                <span *ngIf="questionData?.groupScore > 1" class="sql-title">{{questionData?.userScore}}/{{questionData?.groupScore}} 
                    {{'POINTS' | translate}}</span>
                    <span *ngIf="questionData?.groupScore === 1" class="sql-title">{{questionData?.userScore}}/{{questionData?.groupScore}} 
                        {{'POINT' | translate}}</span>
            </div>
            <ng-container *ngFor="let groupedQuestion of questionData?.questions; let i = index">
                <div class="d-flex">
                    <div class="mt-2 mr-3">
                        {{i+1}}.
                    </div>
                    <app-question class="mb-4" [isCorrectAnswer]="savedGroupedData"
                        *ngIf="groupedQuestion?.questionType === 'Single Choice' || groupedQuestion?.questionType === 'Multiple Choice' || groupedQuestion?.questionType === 'TextInput'"
                        [isInlineQuestion]="isInlineQuestion" [groupQuestRemainingRetries]="questionData?.remainingRetries" [questionStyle]="questionStyle" [question]="groupedQuestion" [inlineQuestionData]="groupedQuestion" 
                        (UserSelectAnswerOption)="userSelectAnswerOption($event)"
                        [userAnswer]="this.groupedQuestion?.userAnswers" [isRetryClicked]="isRetryClicked" [isSubmitClicked]="isSubmitClicked">
                    </app-question>
                </div>
                <div *ngIf="savedGroupedData" class="p-4" [ngClass]="{'success-explanation':groupedQuestion.isCorrect,'error-explanation':!groupedQuestion.isCorrect}">
                    {{'exam.result.explanation' | translate}}
                    {{groupedQuestion?.feedback}}
                </div>
            </ng-container>

            <div *ngIf="feedBackExplanation" class="p-4" [ngClass]="{'success-explanation':savedGroupedData?.isCorrect,'error-explanation':!savedGroupedData?.isCorrect}">
                {{'exam.result.explanation' | translate}}
                {{feedBackExplanation}}
            </div>
            <div class="d-flex justify-content-between">
                <span>{{'TOTAL_POINTS' | translate}}</span>
                <span>
                    <span class="text-danger subheading-bolder">{{questionData?.userScore}}</span> of {{questionData?.groupScore}}</span>
                
            </div>
            <progress id="prgress" [ngClass]="questionData?.userScore === questionData?.groupScore ? 'progress-green': 'progress-red'" class="w-100" [value]="questionData?.userScore" [max]="questionData?.groupScore" [style]="{ height: '15px' }"> 32% </progress>

            <hr [ngClass]="{'mt-0':!feedBackExplanation}">
            <ng-container *ngIf="!btnGroupShow">
                <ng-container *ngIf="(questionData?.remainingRetries > 0 && questionData?.totalRetries !== 0) || (questionData?.totalRetries === 0)">
                    <div class="d-flex flex-row-reverse">
                        <teams-button *ngIf="!isRetryGroup"
                            [classList]="'btn ts-btn-fluent-primary'"
                            (buttonClick)="saveGroupAnswer(questionData?.questionUniqueName, 'submit')"
                            [title]="'actions.submit' | translate"
                            [showLoading]="btnLoadingService.getLoding()"
                            [index]="questIndex"
                            ></teams-button>
                        <teams-button *ngIf="isRetryGroup"
                            [classList]="'btn ts-btn-fluent-primary'"
                            (buttonClick)="saveGroupAnswer(questionData?.questionUniqueName, 'retry')"
                            [title]="'actions.retry' | translate"
                            [showLoading]="btnLoadingService.getLoding()"
                            [index]="questIndex"
                            ></teams-button>
                    </div>
                </ng-container>
            </ng-container>
        </ng-container>
    </ng-container>
</div>