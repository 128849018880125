import {
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  OnChanges,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { UserSessionService } from '@teams-auth';

import { QuestionHelperService } from '../../../services/question/question-helper.service';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'numeric-input-question',
  templateUrl: './numeric-input-question.component.html',
})
export class NumericInputQuestionComponent implements OnInit, OnChanges {
  @Input() question;
  @Input() userAnswer = [];
  @Input() versionKey: string
  @ViewChild('numericInput') numericSelectorElement: ElementRef;
  @Output() UserSelectAnswerOption = new EventEmitter();
  keyName: any;
  numericValue = '';
  numericInput: any;
  setNumericInputValue: any = [];
  examQuestion = '';
  constructor(
    private sanitizer: DomSanitizer,
    private questionHelper: QuestionHelperService,
    private userSessionService: UserSessionService,
  ) { }

  ngOnInit(): void { 
    this.examQuestion = this.question.question;
    this.onLoadData();
  }

  /**
   * @description: Change Numeric Input Value
   */
  ngOnChanges(): void {
    this.userSessionService.isReset$.subscribe((e) => {
      if (e) {
        this.examQuestion = this.question.question;
        this.onLoadData();
        this.userSessionService.isReset$.next(false);
      }
    })
  }
  /**
   * @description: Load Data First Time
   */
  private onLoadData(): void {
    if (this.question !== undefined) {
      this.examQuestion = this.questionHelper.onNumericInputLoadData(this.question, this.examQuestion, this.keyName).examQuestionValue;
      this.keyName = this.questionHelper.onNumericInputLoadData(this.question, this.examQuestion, this.keyName).keyValue;
      this.numericInput = this.sanitizer.bypassSecurityTrustHtml(
        this.examQuestion
      );
    }
  }
  /**
   * @description: Save Value
   */
  @HostListener('change', ['$event']) onChange(event): void {
    const answer = [{ key: this.keyName, value: event.target.value }];
    if(this.question?.isVersioned) {
      this.question?.questionVersions.forEach((element) => {
        if(element.versionKey === this.versionKey) {
          element.userAnswers = answer
          this.question.versionKey = this.versionKey
          this.question.userAnswers = answer
        }
      });
    } else {
      this.question.userAnswers = answer;
    }
    this.UserSelectAnswerOption.emit({ answer: this.question });
  }
}
