import { ChangeDetectorRef, Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output } from '@angular/core';
import { ActivatedRoute, NavigationExtras, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import * as _ from 'lodash';
import { TranslateService } from '@ngx-translate/core';

import { getEntity, getEntityData, LoadingService, miscellaneousConst } from '@utility';

import { TestDetailsModel, QuestionModel, SectionModel, ExamInstaceDetailsModel } from '../../../../model/common.model';

import { TestSectionReviewHelperService } from '../../../../services/test-section-review/test-section-review-helper.service';
import { CommonService } from '../../../../services/common-service.service';


import {
  AuthService,
  EventEmitterService,
  UserSessionService,
  decryptBackendResponse
} from '@teams-auth';
import { DeviceDetectorService } from 'ngx-device-detector';
import { LocalSessionStorageService } from '../../../../services/local-session-storage.service';
import {
  unsubscribeCollection,
  formatter,
  changeMinute,
  changeSecond,
  isExternalFlow,
} from '@utility';
import { Title } from '@angular/platform-browser';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'app-test-section-review',
  templateUrl: './test-section-review.component.html',
})
export class TestSectionReviewComponent implements OnInit, OnChanges, OnDestroy {

  @Input() finishTestConfimDialoge = false;
  @Output() finishTestConfimDialogeEmit = new EventEmitter();
  @Output() viewInstructionEmit = new EventEmitter();

  public questions: QuestionModel[] = [];
  private examId: string;
  public sectionId: string;
  private examInstanceId: string;
  public examInstanceDetails: ExamInstaceDetailsModel;
  public sectionList: SectionModel[] = [];
  public loaderMsg = [];
  public examResult = false;
  public sectionIndexinExamInstance = -1;
  public testDetails: TestDetailsModel;
  public timer;
  private stopWatch;
  private finalGetStopWatch;
  private updatedTimerInterval: any;
  private subscriptions: Subscription[] = [];
  public showConfirmSection = false;
  public skipped: QuestionModel[] = [];
  public answered: QuestionModel[] = [];
  public sectionIndex = 0;
  public regUniqueName = '';
  private isCourseFlow;
  public cloudlabs: string;
  public sideNav: boolean;

  public viewInstruction = false;
  public miscellaneousConst = miscellaneousConst;

  private sectionNumber: number;

  isShow = false;
  public isTeamapp: string;
  public dialogClass: string;
  context: string;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private testSectionReviewHelper: TestSectionReviewHelperService,
    private eventEmitter: EventEmitterService,
    public userSessionService: UserSessionService,
    private commonService: CommonService,
    private readonly translateService: TranslateService,
    private ref: ChangeDetectorRef,
    public auth: AuthService,
    public deviceDetectorService: DeviceDetectorService,
    private localStorageService: LocalSessionStorageService,
    public loadingService: LoadingService,
    private titleService: Title
  ) {
    this.route.queryParams.subscribe((params) => {
      this.regUniqueName = params.regUniqueName;
    });
  }

  ngOnInit(): void {
    if(localStorage.getItem('context') === miscellaneousConst.cloudlab){
      const title = this.translateService.instant('exam.section.sectionReview.review') + ' - ' + 
        localStorage.getItem('current-pt-details-page-name') + ' | ' + this.translateService.instant('HOME.CLOUDLABS_PORTAL');
      this.titleService.setTitle(title);
    }
    this.cloudlabs = localStorage.getItem('context');
    this.isTeamapp = localStorage.getItem('isTeamapp');
    this.loadData();
    this.userSessionService.coursePt$.subscribe((e) => {
      this.isCourseFlow = e;
    });
    this.userSessionService.sideNavigation$.subscribe((e) => {
      this.sideNav = e;
    });
    this.commonService.finishTest.subscribe((res)=>{
      this.finishTestDialoge()
    })
  }

  ngOnChanges(): void {
    this.timer = sessionStorage.getItem('denysdovhan');
  }

  /**
   * @description : When page is load first time
   */
  private loadData(): void {
    this.sectionList = [];
    this.subscriptions[this.subscriptions.length] = this.route.params.subscribe(
      (i) => {
        this.sectionId = i.sectionId;
        this.examId = i.id;
        this.examInstanceId = i.testInstance;
        if (this.examInstanceId !== undefined) {
          /* To consider: getSectionlength here is undefined */
          this.userSessionService.curruntSection$.subscribe((e) => {
            this.sectionNumber = e;
          })
          if (this.userSessionService.getSectionLength() === 1 || (this.sectionNumber === this.userSessionService.getSectionLength())) {
            this.loaderMsg = ["common.spinner.reviewPage"];
          } else if (this.userSessionService.getSectionLength() > 1) {
            this.loaderMsg = ["common.spinner.nextSection"];
          } else {
            this.loaderMsg = ["common.spinner.reviewPage"];
          }
          this.loadingService.loadingOn(this.loaderMsg);
          this.getExamByExamInstance();
        }
        this.getSectionData();
      }
    );
  }

  private getSectionData(): void {
    this.isShow = true;
  }
  /**
   * @description: Replace Dropdown and NumericInput with ----
   */
  private replaceToDash() {
    this.testSectionReviewHelper.replaceToDash(this.questions);
  }

  /**
   * @description: Get Section By Question
   */
  private getSectionQuestion() {
    const res = this.questions;
    this.skipped = this.questions.filter(
      (i) => i.userAnswers.length === 0
    );
    this.answered = this.questions.filter(
      (i) => i.userAnswers.length > 0
    );
    this.replaceToDash();
    this.loadingService.loadingOff();
  }

  /**
   * @description: Get Exam By Exam Instance
   */
  private getExamByExamInstance(): any {
    this.subscriptions[this.subscriptions.length] = this.commonService
      .getExamByExamInstance(this.examInstanceId)
      .subscribe(
        (res) => {
          this.examInstanceDetails = res;
          this.sectionList = res.sections;
          this.sectionIndexinExamInstance = this.sectionList.findIndex(
            (i) => i.examSectionUniqueName === this.sectionId
          );
          if (res.elapsedTime !== '') {
            // convert Elapsed time to secs
            this.finalGetStopWatch = this.changeSecond(res.elapsedTime)
          }
          this.timerClock();
        },
        (error) => {
          const code = 'Error - 30035';
          this.eventEmitter.debugAlert(code, error.error);
        }
      );
  }
  /**
   * @description : Change Timer Seconds Display
   */
  private changeSecond(value: any): number {
    return changeSecond(value);
  }

  /**
   * @description : Change Timer Minutes Display
   */
  public changeMinute(value: any): any {
    const timer = 0
    if(value){
      return changeMinute(value);
    }
    else{
      return changeMinute(timer)
    }
  }

  /**
   * @description : Time Clock Handling
   */
  private timerClock(): void {
    this.updatedTimerInterval = setInterval(async () => {
      const getTime = parseInt(sessionStorage.getItem('denysdovhan'), 10)
      const finalGetTime = getTime - 1000;
      const getStopWatch = parseInt(sessionStorage.getItem('dovhandenys'), 10)
      this.finalGetStopWatch = getStopWatch + 1;
      sessionStorage.setItem("denysdovhan", JSON.stringify(finalGetTime));
      sessionStorage.setItem("dovhandenys", JSON.stringify(this.finalGetStopWatch));
      this.timer = sessionStorage.getItem('denysdovhan');
      this.stopWatch = sessionStorage.getItem('dovhandenys');
      this.ref.detectChanges();
      if (parseInt(this.timer) <= 0) {
        this.getResult();
      }
      if (this.stopWatch % 25 === 0) {
        const model = {
          elapsedTime: formatter(this.stopWatch),
        };
        this.subscriptions[this.subscriptions.length] = this.commonService
          .updateExamPeriodically(this.examInstanceId, model)
          .subscribe(
            // eslint-disable-next-line @typescript-eslint/no-empty-function
            () => { /* TODO document why this arrow function is empty */ },
            (error) => {
              const errorMessage: string = error.error.message;
              if (!errorMessage?.startsWith(miscellaneousConst?.elapsedTime)) {
                const code = 'Error - 30036';
                this.eventEmitter.debugAlert(code, error.error.message);
              }
            }
          );
      }
    }, 1000);
  }

  /**
   * @description: Show Finish Section Dialoge
   */
  public finishSection(): void {
    this.showConfirmSection = true;
  }

  /**
   * @description: If user agrees to complete section
   */
  public completedSection(): void {
    this.showConfirmSection = false;
    // End Exam Section On Confirmation
    const model = {
      elapsedTime: formatter(this.stopWatch),
    };
    this.subscriptions[
      this.subscriptions.length
    ] = this.commonService.completeExamInstanceSection(this.sectionId, model).subscribe(
      (res) => {
        this.navigateNextSection();
      },
      (error) => {
        const code = 'Error - 30037';
        this.eventEmitter.debugAlert(code, error.error.message);
      }
    );
  }

  /**
   * @description: Navigate Next Section
   */

  private navigateNextSection(): void {
    let nextSectionId = this.sectionList.findIndex(
      (i) => i.examSectionUniqueName === this.sectionId
    );
    this.sectionIndex = ++nextSectionId;
    this.subscriptions[
      this.subscriptions.length
    // ] = this.commonService.getSectionQuestions(this.sectionId).subscribe(
    ] = this.commonService.getSectionQuestions(this.sectionList[this.sectionIndex].examSectionUniqueName).subscribe(
      (encryptRes) => {
        const res = decryptBackendResponse(encryptRes, this.userSessionService.getOriginalKeyPair().private);
        const questions = res;
        this.testSectionReviewHelper.navigateExamQuestion(this.regUniqueName, this.sectionIndex,
          this.examId, this.examInstanceId, this.sectionList[this.sectionIndex].examSectionUniqueName, questions[0].questionUniqueName,
          this.sectionList, questions);
      },
      (error) => {
        const code = 'Error - 30038';
        this.eventEmitter.debugAlert(code, error.error.message);
      }
    );
  }
  /**
   * @description: When user is on last section and finish the test
   */
  public finishTest(): void {
    this.examResult = true;
    this.showConfirmSection = false;
    clearInterval(this.updatedTimerInterval);
  }

  /**
   * @description: Confim Dialog click Yes
   */
  public dialogeYes() {
    clearInterval(this.updatedTimerInterval);
    this.examResult = true;
    this.finishTestConfimDialoge = false;
    this.dialogClass = 'ts-dialog-fluent ts-dialog-fluent-confirmation';
  }
  /**
   * @description: Confim Dialog click No
   */
  public dialogeNo() {
    this.finishTestConfimDialoge = false;
    this.examResult = false;
  }

  /**
   * @description: Close Confimation Popup
   */
  public closeConfirmResponse(event): void {
    this.finishTestConfimDialoge = event;
  }

  /**
   * @description: Display finish test dialoge
   */

  public finishTestDialoge() {
    this.examResult = false;
    this.finishTestConfimDialoge = true;
    this.finishTestConfimDialogeEmit.emit(this.finishTestConfimDialoge);
  }
  /**
   * @description : Navigate Result Page
   */
  public getResult(): void {
    this.loaderMsg = ["common.spinner.testResult"];
    this.loadingService.loadingOn(this.loaderMsg);
    this.examResult = false;
    clearInterval(this.updatedTimerInterval);
    const model = {
      elapsedTime: formatter(this.stopWatch),
    };
    this.subscriptions[this.subscriptions.length] = this.commonService
      .endExamInstance(this.examInstanceId, model)
      .subscribe(
        (res) => {
          this.testSectionReviewHelper.navigateExamResult(this.regUniqueName, this.examId, this.examInstanceId);
        },
        (error) => {
          const code = 'Error - 30039';
          this.eventEmitter.debugAlert(code, error.error.message);
        }
      );
  }
  /**
   * @description: Is Section Review Allowed or not
   */
  public getIsSectionReview() {
    return this.testSectionReviewHelper.getIsSectionReview(this.sectionList, this.sectionId);
  }
  /**
   * @description: Show Instruction Dialog
   */
  public getInstruction(): void {
    this.viewInstruction = true;
  }
  /**
   * @description: check Any Section Instruction
   */
  public getIsSectionInstruction() {
    return this.testSectionReviewHelper.getIsSectionInstruction(this.sectionList, this.sectionId);
  }
  /**
   * @description: Navigate To Review Page
   */
  public navigateToReviewPage(questDetail): void {
    if (isExternalFlow(this.regUniqueName)) {
      this.processForExternalFlow(questDetail);
    } else {
      this.processForNotExternalFlow(questDetail);
    }
  }

  private processForNotExternalFlow(questDetail) {
    if (localStorage.getItem('context') === 'CloudLabs') {
      if (this.localStorageService.getCourseStorage() === 'false') {
        this.router.navigate([
          `/${getEntity()}/${miscellaneousConst.navigation.practiceTests}/${miscellaneousConst.navigation.tests}/${this.examId}/${miscellaneousConst.navigation.testInstance}/${this.examInstanceId}/${miscellaneousConst.navigation.sections}/${this.sectionId}/${miscellaneousConst.navigation.questions}/${questDetail.questionUniqueName}/${miscellaneousConst.navigation.review}`,
        ]);
      }
      else if (this.localStorageService.getCourseStorage() === 'true') {
        this.router.navigate([
          `/${getEntity()}/${getEntityData()}/${miscellaneousConst.navigation.test}/${miscellaneousConst.navigation.practiceTest}/${miscellaneousConst.navigation.tests}/${this.examId}/${miscellaneousConst.navigation.testInstance}/${this.examInstanceId}/${miscellaneousConst.navigation.sections}/${this.sectionId}/${miscellaneousConst.navigation.questions}/${questDetail.questionUniqueName}/${miscellaneousConst.navigation.review}`,
        ]);
      }
    } else if (localStorage.getItem('context') === 'PracticeTest') {
      this.router.navigate([
        `/${miscellaneousConst.navigation.tests}/${this.examId}/${miscellaneousConst.navigation.testInstance}/${this.examInstanceId}/${miscellaneousConst.navigation.sections}/${this.sectionId}/${miscellaneousConst.navigation.questions}/${questDetail.questionUniqueName}/${miscellaneousConst.navigation.review}`,
      ]);
    }
  }

  private processForExternalFlow(questDetail) {
    const navigationExtras: NavigationExtras = {
      queryParams: { regUniqueName: this.regUniqueName },
    };
    if (localStorage.getItem('context') === 'CloudLabs') {
      if (this.localStorageService.getCourseStorage() === 'false') {
        this.router.navigate(
          [
            `/${getEntity()}/${miscellaneousConst.navigation.practiceTests}/${miscellaneousConst.navigation.tests}/${this.examId}/${miscellaneousConst.navigation.testInstance}/${this.examInstanceId}/${miscellaneousConst.navigation.sections}/${this.sectionId}/${miscellaneousConst.navigation.questions}/${questDetail.questionUniqueName}/${miscellaneousConst.navigation.review}`,
          ],
          navigationExtras
        );
      }
      else if (this.localStorageService.getCourseStorage() === 'true') {
        this.router.navigate(
          [
            `/${getEntity()}/${getEntityData()}/${miscellaneousConst.navigation.test}/${miscellaneousConst.navigation.practiceTest}/${miscellaneousConst.navigation.tests}/${this.examId}/${miscellaneousConst.navigation.testInstance}/${this.examInstanceId}/${miscellaneousConst.navigation.sections}/${this.sectionId}/${miscellaneousConst.navigation.questions}/${questDetail.questionUniqueName}/${miscellaneousConst.navigation.review}`,
          ],
          navigationExtras
        );
      }

    } else if (localStorage.getItem('context') === 'PracticeTest') {
      this.router.navigate(
        [
          `/${miscellaneousConst.navigation.tests}/${this.examId}/${miscellaneousConst.navigation.testInstance}/${this.examInstanceId}/${miscellaneousConst.navigation.sections}/${this.sectionId}/${miscellaneousConst.navigation.questions}/${questDetail.questionUniqueName}/${miscellaneousConst.navigation.review}`,
        ],
        navigationExtras
      );
    }
  }

  public getQuestion(e) {
    this.questions = e;
    this.getSectionQuestion();
  }

  public getLanguage(ln): void {
    this.userSessionService.translateLanguage(ln);
  }

  /**
   * @description: Close Test Result
   */
  public closeTestResultResponse(event): void {
    this.examResult = event.result;
  }
  /**
   * @description: Close Test Section
   */
  public closeTestSectionReviewPopup(event): void {
    this.showConfirmSection = event.endSection;
  }

  /**
   * @description: Close Help
   */
   public closeHelpPopup(event) {
    this.viewInstruction = event;
    this.viewInstructionEmit.emit(this.viewInstruction);
  }

  /**
   * @description: Emitted Is View Instruction
   */
   public isInstruction(e) {
    this.viewInstruction = e;
  }

  /**
   * @description Cancel test and redirect to test list page
   */
   cancelTest(): void {
    const lang = this.userSessionService.getDefaultLanguage();
    this.translateService.use(lang.value);
    if (localStorage.getItem('context') === 'CloudLabs') {
      if (
        this.localStorageService.getCurrentPTDetailsPagePath() !== ''
      ) {
        this.router.navigate([
          this.localStorageService.getCurrentPTDetailsPagePath(),
        ]);
        this.localStorageService.setPTDetailsPageEmpty();
      }
    }
    else if (localStorage.getItem('context') === 'PracticeTest') {
      this.router.navigate([`/`]);
    }
     
  }

  /**
   * @description Component lifecycle method, gets called when component destroys
   */
  ngOnDestroy(): void {
    clearInterval(this.updatedTimerInterval);
    unsubscribeCollection(this.subscriptions);
  }

}
