
const CLOUD_LABS_BASE_URL = 'https://cloudlabs-practice-tests-api-qa.azurewebsites.net/';
const PRACTICE_TEST_BASE_URL =  'https://cloudlabs-practice-tests-api-qa.azurewebsites.net/';

const BASE_URL = localStorage.getItem('context') === 'CloudLabs' ?
CLOUD_LABS_BASE_URL:
PRACTICE_TEST_BASE_URL;

export const API = {
  testData: {
    test: `${BASE_URL}users/exams`,
    testById: `${BASE_URL}exams/{id}`,
    getSingleExam: `${BASE_URL}users/exams/{examUniqueName}/{examRegistrationAssignmentUniqueName}`,
    createExamInstance: `${BASE_URL}exams/{exam_GUID}/examInstances`,
    getExamByExamInstance: `${BASE_URL}exams/examInstances/{examInstance_GUID}`,
    getAllExamQuestions: `${BASE_URL}exams/sections/{section_GUID}`,
    getExternalExamDetails: `${BASE_URL}externals/{exam_GUID}`,
    createExternalExamInstance: `${BASE_URL}externals/{exam_GUID}/examInstances`,
    getLabDetails: `${BASE_URL}exams/sections/{section_GUID}/labDetails`,

    // Update exam elapsed time periodically
    updatePeriodically: `${BASE_URL}exams/examInstances/{examInstance_GUID}`,
    getExamResult: `${BASE_URL}exams/examInstances/{examInstance_GUID}/result`,
    updateExamQuestion: `${BASE_URL}exams/questions/{question_GUID}`,

    // End exam instance
    endExamInstance: `${BASE_URL}exams/examInstances/{examInstance_GUID}/end`,

    // change question  inProgress status
    updateExamQuestionProgress: `${BASE_URL}exams/questions/{question_GUID}/inProgress`,

    // Update Section Progress
    updateExamInstanceSectionProgress: `${BASE_URL}exams/sections/{section_GUID}/inProgress`,
    completeExamInstanceSection: `${BASE_URL}exams/sections/{section_GUID}/submit`,

    encryption: `${BASE_URL}testactions`,

    // FeedBack
    sendFeedback: `${BASE_URL}exams/{exam_guid}/examInstances/{instance_unique_name}/review`,

    // Get Voucher Code for download page
    getVoucherCodeDownloadApp: `${BASE_URL}examregistrations/{registrations}/assignments/{assignments}`,

    // Users' completed exams
    completedExams: `${BASE_URL}users/examinstances`,

    // Post suggested question
    suggestQuestions: `${BASE_URL}users/suggestQuestions`,
    
    // Get suggested question
    getsSuggestedQuestions: `${BASE_URL}users/suggestQuestions`,
    
    // Redeem Voucher
    redeemVoucher: `${BASE_URL}exams/redeemcloudlabsvoucher/{code}`,
    
    // Comprehensive Report
    resultStatistics: `${BASE_URL}exams/examInstances/{examInstanceUniqueName}/resultStatistics`,
    
    //Lab guide preview
    labguideQuestion: `${BASE_URL}api/labguide/questions/{voucherCode}/{pageNumber}`,
    
    // Update Ungrouped Answer
    updateUngroupedAnswer : `${BASE_URL}api/labguide/UpdateUserAnswer/{voucherCode}`,

    // Update Grouped Answer
    updateGroupedAnswer : `${BASE_URL}api/labguide/UpdateGroupedUserAnswer/{voucherCode}`
  },
};
