import {
  Component,
  Input,
  OnChanges,
  OnInit,
  forwardRef,
} from '@angular/core';
import { NG_VALUE_ACCESSOR, ControlValueAccessor } from '@angular/forms';
import { Router } from '@angular/router';
@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'teams-radio',
  templateUrl: './radio.component.html',
  styleUrls: ['./radio.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => RadioComponent),
      multi: true,
    },
  ],
})
export class RadioComponent implements OnInit, OnChanges, ControlValueAccessor {
  /**
   * Holds the current value of the input
   */
  @Input() value: any = '';
  @Input() id = '';
  @Input() checked: boolean;
  @Input() name = '';
  @Input() classlist = 'ts-radio-fluent';
  @Input() ngClass = '';
  @Input() label = '';
  @Input() labelClass = '';
  @Input() disabled: boolean;
  @Input() ngClassLabel = '';
  @Input() isInlineQuest = false
  randomValue = '';
  public isPracticeTest = false;

  constructor(
    private route: Router
  ) {}

  ngOnInit(): void {
    if (this.route.url.includes('practice-tests') || this.route.url.includes('practice-test') || this.route.url.includes('tests')) {
      this.isPracticeTest = true;
    } else {
      this.isPracticeTest = false;
    }
    
    this.classlist = this.classlist.concat(' ', this.ngClass);
    this.labelClass = this.labelClass.concat(' ', this.ngClassLabel);
    this.randomValue = this.randomString();
  }

  ngOnChanges(): void {
    if (this.isInlineQuest) {
      this.classlist = this.classlist.concat(' ', this.ngClass);
    }
    
  }

  /**
   * Invoked when the model has been changed
   */
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  onChange: (_: any) => void = (_: any) => { };

  /**
   * Invoked when the model has been touched
   */
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  onTouched: () => void = () => {};

  /**
   * Method that is invoked on an update of a model.
   */
  updateChanges() {
    this.onChange(this.value);
  }

  ///////////////
  // OVERRIDES //
  ///////////////

  /**
   * Writes a new item to the element.
   * @param value the value
   */
  writeValue(value: number | boolean): void {
    this.value = value;
    if (typeof value === 'boolean') {
      this.checked = value;
    }
    this.updateChanges();
  }

  /**
   * Registers a callback function that should be called when the control's value changes in the UI.
   * @param fn
   */
  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  /**
   * Registers a callback function that should be called when the control receives a blur event.
   * @param fn
   */
  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }
  randomString() {
    const length = 6;
    const chars = 'abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ';
    let result = '';
    for (let i = length; i > 0; --i)
      result += chars[Math.floor(Math.random() * chars.length)];
    return result;
  }
}
