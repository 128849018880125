<!--Start Credentials & Instructions Section-->
<div class="performance-quiz performance-task-div">
    <!-- <div class="col-12 mt-3 ml-3">

        <cloudlabs-menu [menuList]="menuList" [selectedMenu]="selectedMenu" (selectedValueEmit)="onMenuSelect($event)">
        </cloudlabs-menu>

    </div>
    <div class="cip-right-box width-100" [class.d-none]="!showCredentialsInformation">
        <cloudlabs-credential></cloudlabs-credential>
    </div>
    <div class="cip-right-box width-100" *ngIf="showQuestionsDeatils">
        <div>
            <cloudlabs-task-list [questionList]="questionList" ></cloudlabs-task-list>
        </div>
    </div> -->
    <!--New Design, Keeping old design for now as comment-->
    <div id="accordion">
        <div class="card card-new-drop view-more-card performance-task cred-challenges">
          <div class="card-header question-card" id="headingOne">
            <div class="row list-title">
              <div class="col-md-8 float-left list-content subheading">
                {{'exam.section.performanceQuiz.credIntruct' | translate}}
              </div>
              <div class="col-md-4 text-right">
                <span class="material-icons mr-4 warning-msg-icon not-clicakble-cursor" data-toggle="collapse" data-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne" (click)="toggle('credCard')" >{{collapseCards.credCard}}</span>
              </div>
            </div>
          </div>
      
          <div id="collapseOne" class="collapse show" aria-labelledby="headingOne" data-parent="#accordion">
            <div class="card-body body-marging">
                <div class="cip-right-box cred-list width-100" [class.d-none]="!showCredentialsInformation">
                    <cloudlabs-credential [sectionType]="sectionType"></cloudlabs-credential>
                </div>
            </div>
          </div>
        </div>
        <div class="card card-new-drop view-more-card performance-task cred-challenges">
          <div class="card-header question-card" id="headingTwo">
            <div class="row list-title">
              <div class="col-md-8 float-left list-content subheading">
                {{'exam.section.performanceTask.allTask' | translate}} ({{questionList.length}})
              </div>
              <div class="col-md-4 text-right icons-task">
                <span class="material-icons mr-4 warning-msg-icon not-clicakble-cursor" data-toggle="collapse" data-target="#collapseTwo" aria-expanded="true" aria-controls="collapseTwo" (click)="toggle('challengCard')" >{{collapseCards.challengCard}}</span>
              </div>
            </div>
          </div>
          <div id="collapseTwo" class="collapse" aria-labelledby="headingTwo" data-parent="#accordion">
            <div class="card-body">
                <div class="cip-right-box task-list width-100">
                    <div>
                        <cloudlabs-task-list [questionList]="questionList" ></cloudlabs-task-list>
                    </div>
                </div>
            </div>
          </div>
        </div>
      </div>
</div>




<!--End Credentials & Instructions Section-->