<div>
    <!-- <ul class="singlequestion-list" id="main-landmark"
        [ngClass]="sectionType === miscellaneousConst.performanceQuizwithVM ? 'width-110' : ''"> -->
    <ul class="singlequestion-list"
        [ngClass]="{'width-110':sectionType === miscellaneousConst.performanceQuizwithVM, 'margin-botom-0':isInlineQuestion}">
        <li [ngClass]="{'p-0':isInlineQuestion}">
            <span *ngIf="!isInlineQuestion" class="sql-title">{{ 'exam.questions.questionSection' | translate }}</span>
            <div class="sql-ques case-study-sql-ques" [class.mb-0]="isInlineQuestion">
                <h4 *ngIf="!isInlineQuestion" role="heading" aria-level="2" class="" [innerHTML]="question?.question | targetBlank"></h4>
                <div class="subheading" *ngIf="isInlineQuestion" role="heading" aria-level="2" class="mt-2" [innerHTML]="question?.question | targetBlank"></div>
                <!-- <h6 *ngIf="isInlineQuestion" role="heading" aria-level="2" class="mt-2" [innerHTML]="question?.question | targetBlank"></h6> -->
                <span *ngIf="question?.note !== null && !isInlineQuestion"><strong>{{ 'exam.questions.note' | translate }}: </strong> <span
                        [innerHTML]="question?.note"></span></span>
                <br>
                <span *ngIf="question?.instruction !== null && !isInlineQuestion"><strong>{{ 'exam.questions.instruction' | translate
                        }}:</strong> <span class="ml-2" [innerHTML]="question?.instruction"></span></span>
            </div>
            <span *ngIf="!isInlineQuestion" class="sql-title">{{ 'exam.questions.answerSection' | translate }}</span>
            <div class="sql-ans-box">
                <ng-container *ngIf="!isInlineQuestion">
                    <div class="sql-option-box"
                        [ngClass]="sectionType === miscellaneousConst.performanceQuizwithVM ? 'col-md-8' : ''"
                        *ngFor="let option of question?.options">
                        <teams-checkbox [inputClass]="'ts-checkbox-fluent'" [value]='option.key' [id]="option.key"
                            [(ngModel)]="option.checked" [disabled]="!option.checked && maxNo" [checked]="option.checked"
                            (change)="selectMultipleChoice($event.target['checked'])"
                            [labelClass]="'icon-cursor ts-checkbox-fluent-text'" [label]="option.value"></teams-checkbox>
                    </div>
                </ng-container>
                <ng-container *ngIf="isInlineQuestion">
                    <div class="sql-option-box"
                        [ngClass]="sectionType === miscellaneousConst.performanceQuizwithVM ? 'col-md-8' : ''"
                        *ngFor="let option of question?.options"
                        [ngClass]="option.isCorrect===true?'sql-option-box-success':option.isCorrect===false?'sql-option-box-failure':''">
                        <teams-checkbox [inputClass]="'ts-checkbox-fluent'" [value]='option.key' [id]="option.key"
                            [(ngModel)]="option.checked" [disabled]="!option.checked && maxNo" [checked]="option.checked"
                            (change)="selectMultipleChoice($event.target['checked'])"
                            [labelClass]="'icon-cursor ts-checkbox-fluent-text'" [label]="option.value"
                            [ngClassInput]="option.isCorrect===true ? 'ts-checkbox-fluent-success' : option.isCorrect===false ? 'ts-checkbox-fluent-failure' : '' "></teams-checkbox>
                    </div>

                </ng-container>
            </div>
        </li>
    </ul>
</div>
