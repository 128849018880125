import { AfterViewInit, Component, Inject, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { AppConfigService } from './services/app.config.services';
import { NavigationEnd, Router } from '@angular/router';
import { TranslateCacheService } from 'ngx-translate-cache';
import { AppInsightsService, UserSessionService } from '@teams-auth';
import { setStyleColours } from '@utility';
import { CustomerType } from '@libs/common-lab/src/modals/lab.model';
import { setting } from '@libs/common-lab/src/modals/setting.model';
import { environment } from '../environments/environment';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit, AfterViewInit {
  public cookies = false;
  public loading = true;
  public id: any;
  themeColor: string;
  constructor(
    private translate: TranslateService,
    private appConfigServ: AppConfigService,
    private router: Router,
    private translateCacheService: TranslateCacheService,
    private appInsightsService: AppInsightsService,
    public userSessionService: UserSessionService,
    @Inject('environment') environment
  ) {
    if (environment.customer === CustomerType.DATABRICKS) {
      this.appendDBCookieScript();
    }
    if (navigator.cookieEnabled === true) {
      this.cookies = true;
    }
    this.loadAppSettings();
    this.router.events.forEach((item) => {
      if (item instanceof NavigationEnd) {
        this.appInsightsService.logPageView('page', item.url);
      }
    });
  }

  ngOnInit(): void {
    this.userSessionService.isProdUser = environment.production ? true : false;
  }

  ngAfterViewInit() {
    let lang = this.getQueryParam('lang') || localStorage.getItem('language');
    if (
      lang &&
      ['en', 'es', 'fr', 'ja', 'ko', 'zh-Hans', 'zh-Hant','pt'].includes(lang)
    ) {
      localStorage.setItem('language', lang);
    } else {
      localStorage.setItem('language', 'en');
    }
    if (!lang) {
      lang = 'en';
    }
    this.translate.use(lang);
    this.translateCacheService.init();
    setTimeout(() => {
      document.getElementById('vex-splash-screen').style.display = 'none';
    }, 500);
    const theme = localStorage.getItem('theme');
    if (theme === 'blue') {
      this.themeColor = '#2299DD';
    } else if (theme === 'green') {
      this.themeColor = '#084e27';
    } else if (theme === 'orange' || theme === 'red') {
      this.themeColor = '#ec7211';
    }
    const ele = document.getElementsByTagName('body')[0];
    ele.classList.add('theme-blue');
    setStyleColours(1);
  }

  /**
   * Get app setting
   */
  loadAppSettings() {
    const haveSetting = localStorage.getItem('setting');
    if (!haveSetting) {
      this.appConfigServ.load().then((res: setting[]) => {
        if (res) {
          localStorage.setItem('setting', JSON.stringify(res));
        }
      });
    }
  }

  getQueryParam(name) {
    const q = window.location.href.match(
      new RegExp('[?&]' + name + '=([^&#]*)')
    );
    return q && q[1];
  }

  private appendDBCookieScript(): void {
    let script;

    script = document.createElement('script');
    script.src =
      'https://cdn.cookielaw.org/consent/92466579-1717-44d3-809d-a05fb02843ed/OtAutoBlock.js';
    script.type = 'text/javascript';
    document.head.appendChild(script);

    script = document.createElement('script');
    script.setAttribute('data-document-language', 'true');
    script.setAttribute(
      'data-domain-script',
      '92466579-1717-44d3-809d-a05fb02843ed'
    );
    script.setAttribute('charset', 'UTF-8');
    script.src = 'https://cdn.cookielaw.org/scripttemplates/otSDKStub.js';
    script.type = 'text/javascript';
    document.head.appendChild(script);

    script = document.createElement('script');
    script.src =
      'https://www.databricks.com/wp-content/plugins/databricks/js/onetrust.js?ver=1.0.0';
    script.type = 'text/javascript';
    document.head.appendChild(script);

    script = document.createElement('noscript');
    let frame = document.createElement('iframe');
    frame.src = 'https://www.googletagmanager.com/ns.html?id=GTM-TWTKQQ';
    frame.height = '0';
    frame.width = '0';
    frame.style.display = 'none';
    frame.style.visibility = 'hidden';
    script.appendChild(frame);
    document.body.appendChild(script);
  }
}
