import { Injectable } from '@angular/core';
import {
  Router, Resolve,
  RouterStateSnapshot,
  ActivatedRouteSnapshot
} from '@angular/router';
import { EventEmitterService } from '@teams-auth';
import { ApiService } from '../services/api.service';

@Injectable({
  providedIn: 'root'
})
export class ProfileResolver implements Resolve<boolean> {
  constructor(
    private apiSrv: ApiService,
    private eventEmitter: EventEmitterService
    ) {
  }
  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    return this.apiSrv.getProfile().then()
    .catch(e=>{
      const code = 'Error - 70006';
      this.eventEmitter.debugAlert(code, e.error);
    });
  }
}
