import { Injectable, Renderer2, RendererFactory2 } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import * as _ from 'lodash';

@Injectable({
  providedIn: 'root'
})
export class QuestionHelperService {
  private renderer: Renderer2;
  constructor(
    rendererFactory: RendererFactory2,
    private sanitizer: DomSanitizer) {
    this.renderer = rendererFactory.createRenderer(null, null);
  }

  // build tree question
  /**
   * @description: Load Selected User Answers
   */
  public selectedUserAnswers(question): any {
    question.userAnswers.forEach((e) => {
      question.labels
        .filter((i) => i.key === e.key)
        .forEach((ele) => {
          ele.option.push(
            question.options.find((i) => i.key === e.value)
          );
        });
      question.options
        .filter((i) => i.key === e.value)
        .forEach((element) => {
          element.disabled = true;
          element.keys = element.key;
        });
    });
  }

  /**
   * @description: Drop List Value
   */
  public onBuildTreeValueDrop(question, options, e: any, key: any): void {
    const questionDraggedLabel = question.labels.find(
      (i) => i.key === key.key
    );
    if (questionDraggedLabel !== undefined) {
      const newObject: any = Object.assign({}, questionDraggedLabel);
      newObject.option.push(e.dragData);
    }
    this.draggedOptionDisable(options, e);
  }


  // build list
  /**
   * @description: Drop List Data
   */
  public onBuildListValueDrop(question, e: any, dropOptions, options): void {
    const questionDraggedLabel = question.options.find(
      (i) => i.key === e.dragData.key
    );
    if (questionDraggedLabel !== undefined) {
      dropOptions.push(e.dragData);
    }
    this.draggedOptionDisable(options, e);
  }
  /**
   * @description: Move Left  to Right
   */
  public leftToRightMoveBuildList(selectedLeftValue, options, dropOptions): void {
    if (selectedLeftValue !== '') {
      const selectedValue = options.find(
        (i) => i.key === selectedLeftValue
      );

      if (selectedValue) {
        const isExist = dropOptions.some((e)=>e.key===selectedLeftValue)
        if(!isExist){
          dropOptions.push(selectedValue);
        }
      }
      if (selectedValue !== undefined) {
        selectedValue.disabled = true;
      }
    }
  }
  /**
   * @description: Move Right to Left
   */
  public rightToLeftMoveBuildList(selectedRightValue, dropOptions, options): any {
    if (selectedRightValue !== '') {
      dropOptions = dropOptions.filter(
        (i) => i.key !== selectedRightValue
      );
      const selectedOptionValue = options.find(
        (i) => i.key === selectedRightValue
      );
      if (selectedOptionValue !== undefined) {
        selectedOptionValue.disabled = false;
      }
      // this.saveBuildList();
      selectedRightValue = '';
      return { dropOptionsValue: dropOptions };
    }
  }

  /**
   * @description: Move Data to Up
   */
  public upListValueBuildList(selectedRightValue, dropOptions): void {
    if (selectedRightValue !== '') {
      const selectedValue = dropOptions.find(
        (i) => i.key === selectedRightValue
      );
      if (selectedValue !== undefined) {
        const findIndexOfSelectedValue = dropOptions.findIndex(
          (i) => i.key === selectedRightValue
        );
        const findIndexOfPrevValue = dropOptions.findIndex(
          (i, ind) => ind === findIndexOfSelectedValue - 1
        );
        const findPrevValue = dropOptions.find(
          (i, ind) => ind === findIndexOfPrevValue
        );
        if (findPrevValue !== undefined) {
          this.insertAndShift(
            dropOptions,
            findIndexOfSelectedValue,
            findIndexOfPrevValue
          );
        }
      }
    }
  }
  /**
   * @description: Move Data to Down
   */
  public downListValueBuildList(selectedRightValue, dropOptions): void {
    if (selectedRightValue !== '') {
      const selectedValue = dropOptions.find(
        (i) => i.key === selectedRightValue
      );
      if (selectedValue !== undefined) {
        const findIndexOfSelectedValue = dropOptions.findIndex(
          (i) => i.key === selectedRightValue
        );
        const findIndexOfPrevValue = dropOptions.findIndex(
          (i, ind) => ind === findIndexOfSelectedValue + 1
        );
        const findPrevValue = dropOptions.find(
          (i, ind) => ind === findIndexOfPrevValue
        );
        if (findPrevValue !== undefined) {
          this.insertAndShift(
            dropOptions,
            findIndexOfSelectedValue,
            findIndexOfPrevValue
          );
        }
      }
    }
  }

  // match option
  /**
   * @description: Load Selected User Answers
   */
  public selectedUserAnswerMatchOption(question): any {
    question.userAnswers.forEach((e) => {
      question.labels.find(
        (i) => i.key === e.key
      ).option = question.options.find((i) => i.key === e.value);
      const disbleSelectedQuestion = question.options.find(
        (i) => i.key === e.value
      );
      // tslint:disable-next-line: no-unused-expression
      disbleSelectedQuestion
        ? (disbleSelectedQuestion.disabled = true)
        // tslint:disable-next-line: no-unused-expression
        : disbleSelectedQuestion;
    });
  }

  /**
   * @description: Drop List Value
   */
  public onMachOptionListValueDrop(selectedValue, question, options, e: any, key: any): void {
    selectedValue = e.dragData;
    const questionDraggedLabel = question.labels.find(i => i.key === key.key);
    if (questionDraggedLabel !== undefined) {
      questionDraggedLabel.option = e.dragData;
    }
    const DraggedOption = options.find((i) => i.key === e.dragData.key);
    if (DraggedOption !== undefined) {
      DraggedOption.disabled = true;
      selectedValue = '';
    }
  }
  /**
   * @description: Move Left  to Right
   */
  public leftToRightMoveMatchOption(question, options, selectedValue): void {
    const elementWithFirstBlankOption = question.labels.find(
      (i) => i.option == null
    );
    const selectedOptionsValue = options.find(
      (i) => i.key === selectedValue.key
    );
    if (
      elementWithFirstBlankOption !== undefined &&
      selectedOptionsValue !== undefined
    ) {
      // To check if seclected value already exist
      const isExist = selectedOptionsValue.disabled
        if(!isExist){
          elementWithFirstBlankOption.option = selectedOptionsValue;
          selectedOptionsValue.disabled = true;
          selectedValue = '';
        }
      
    }
    
  }
  /**
   * @description: Move Right to Left
   */
  public rightToLeftMoveMatchOption(question, options, selectedKey): void {
    if (selectedKey !== '') {
      const selectedLabelValue = question.labels.find(
        (i) => i.key === selectedKey.key
      );
      const selectedValue = options.find(
        (i) => i.key === selectedKey.option.key
      );

      if (selectedValue !== undefined && selectedLabelValue !== undefined) {
        selectedValue.disabled = false;
        selectedLabelValue.option = null;
      }
      selectedKey = '';
    }
  }
  /**
   * @description: Move Data to Up
   */
  public upListValueMatchOption(question, selectedKey): void {
    if (selectedKey !== '') {
      const questionDraggedLabel = question.labels.find(
        (i) => i.key === selectedKey.key
      );
      if (questionDraggedLabel !== undefined) {
        const tempSelectedValue = questionDraggedLabel.option;
        const findIndexOfSelectedValue = question.labels.findIndex(
          (i) => i.key === selectedKey.key
        );
        const findIndexOfPrevValue = question.labels.findIndex(
          (i, ind) => ind === findIndexOfSelectedValue - 1
        );
        const findPrevValue = question.labels.find(
          (i, ind) => ind === findIndexOfPrevValue
        );
        if (findPrevValue !== undefined) {
          questionDraggedLabel.option = findPrevValue.option;
          findPrevValue.option = tempSelectedValue;
          selectedKey = '';
        }
      }
    }
  }
  /**
   * @description: Move Data to Down
   */
  public downListValueMatchOption(question, selectedKey): void {
    if (selectedKey !== '') {
      const questionDraggedLabel = question.labels.find(
        (i) => i.key === selectedKey.key
      );
      if (questionDraggedLabel !== undefined) {
        const tempSelectedValue = questionDraggedLabel.option;
        const findIndexOfSelectedValue = question.labels.findIndex(
          (i) => i.key === selectedKey.key
        );
        const findIndexOfPrevValue = question.labels.findIndex(
          (i, ind) => ind === findIndexOfSelectedValue + 1
        );
        const findPrevValue = question.labels.find(
          (i, ind) => ind === findIndexOfPrevValue
        );
        if (findPrevValue !== undefined) {
          questionDraggedLabel.option = findPrevValue.option;
          findPrevValue.option = tempSelectedValue;
          selectedKey = '';
        }
      }
    }
  }
  // multi single choice
  /**
   * @description: Save Multi-Single Choice
   */
  public onSingleChoiceSelect(question, chooseOption, event, key): any {
    let questionSelectLabel = question.labels.find((i) => i.key === key);
    let setNewObject = chooseOption.find((i) => i.key === key);
    const findIndexSetObject = chooseOption.findIndex(
      (i) => i.key === key
    );
    if (setNewObject !== undefined && setNewObject.option !== '') {
      const newObject: any = Object.assign({}, setNewObject);
      newObject.option = event.target.value;
      setNewObject = newObject;
      chooseOption.splice(findIndexSetObject, 1);
      chooseOption.splice(findIndexSetObject, 0, setNewObject);
    } else {
      const newObject: any = Object.assign({}, questionSelectLabel);
      newObject.option = event.target.value;
      questionSelectLabel = newObject;
      chooseOption.push(questionSelectLabel);
    }
    const c = chooseOption.filter(i => question.labels.some(o => i.key === o.key));
    let addAnswerValue = question.userAnswers.find(i => i.key === key);
    const findIndexPushObject = question.userAnswers.findIndex(i => i.key === key);
    if (addAnswerValue === undefined) {
      question.userAnswers.push({
        key: c.find((i) => i.key === key).key,
        value: c.find((i) => i.key === key).option,
      });
    } else {
      const newObject: any = Object.assign({}, addAnswerValue);
      newObject.value = event.target.value;
      addAnswerValue = newObject;
      question.userAnswers.splice(findIndexPushObject, 1);
      question.userAnswers.splice(findIndexPushObject, 0, addAnswerValue);
    }
    return { questions: question };
  }
  // dropdown
  /**
   * @description: DropDown Bind Value
   */
  public onDropDownLoadData(question): any {
    if (question !== undefined) {
      const DropDowns = question.options.reduce((i, j) => {
        i[j.key] = [...(i[j.key] || []), j];
        return i;
      }, {});
      const result = Object.keys(DropDowns).map((key) => {
        return [key, DropDowns[key]];
      });
      _.each(result, (sub, index) => {
        if (!_.isUndefined(sub)) {
          const p: any = this.sanitizer.bypassSecurityTrustHtml(
            this.createDropDown(result[index])
          );
          const html = p.changingThisBreaksApplicationSecurity;
          question.question = question.question.replace(
            '[[' + result[index][0] + ']]',
            html
          );
        }
      });
      return { questions: question, results: result };
    }
  }
  /**
   * @description: Create Drop Down
   */
  private createDropDown(options: any): any {
    const parentDiv = this.renderer.createElement('div');
    const div = this.renderer.createElement('div');
    this.renderer.setAttribute(div, 'class', 'dropdown-div');
    const button = this.renderer.createElement('button');
    this.renderer.setAttribute(button, 'class', 'btn select ts-dropdown-fluent-toggle position-relative d-flex align-items-center prac-test-ddl');
    this.renderer.setAttribute(button, 'type', 'button');
    this.renderer.setAttribute(button, 'data-toggle', 'dropdown');
    this.renderer.setAttribute(button, 'data-hover', 'dropdown');
    this.renderer.setAttribute(button, 'id', options[0]);
    this.renderer.setAttribute(button, 'aria-haspopup', 'true');
    this.renderer.setAttribute(button, 'aria-expanded', 'false');
    const span = this.renderer.createElement('span');
    this.renderer.setAttribute(span, 'class', 'fabric-icon-text-pos-1');
    this.renderer.setAttribute(span, 'aria-label', 'Select to add answer');
    this.renderer.appendChild(button, span);
    this.renderer.appendChild(div, button);
    const ul = this.renderer.createElement('ul');
    this.renderer.setAttribute(ul, 'class', 'dropdown-menu');
    this.renderer.setAttribute(ul, 'aria-labelledby', options[0]);
    const li = this.renderer.createElement('li');
    options[1].forEach((element) => {
      const dropdownOption = this.renderer.createElement('li');
      this.renderer.setAttribute(dropdownOption, 'value', element.value);
      this.renderer.setAttribute(dropdownOption, 'role', 'option');
      this.renderer.setAttribute(dropdownOption, 'tabindex', '0');
      this.renderer.setAttribute(dropdownOption, 'id', options[0]);
      this.renderer.appendChild(dropdownOption, document.createTextNode(element.value));
      this.renderer.appendChild(ul, dropdownOption);
    });

    this.renderer.listen(li, 'click', (event) => {
      this.OnDropDownClick(event, document.getElementById(options[0]));
    });
    this.renderer.appendChild(div, ul);
    this.renderer.appendChild(parentDiv, div);
    return parentDiv.innerHTML;
  }

  /**
   * @description: Drop Down Click Event
   */
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  private OnDropDownClick(event, id): any {
  }
  // Numeric Input
  /**
   * @description: Numeric Input Bind Value
   */
  public onNumericInputLoadData(question, examQuestion, keyName): any {
    if (question !== undefined) {
      if (question.question.includes('[[')) {
        const splitKey = question.question.split('[[')[1];
        const splitkey2 = splitKey.split(']]');
        keyName = splitkey2[0];
        const p = this.sanitizer.bypassSecurityTrustHtml(this.createNumericInput(question));
        // tslint:disable-next-line: no-string-literal
        const html = p['changingThisBreaksApplicationSecurity'];
        examQuestion = examQuestion.replace(
          '[[' + keyName + ']]',
          html
        );
        return { examQuestionValue: examQuestion, keyValue: keyName };
      }
    }
  }

  createNumericInput(question): any {
    const div = this.renderer.createElement('div');
    const inputType = this.renderer.createElement('input');
    this.renderer.setAttribute(inputType, 'type', 'number');
    this.renderer.setAttribute(inputType, 'class', 'ts-textbox-fluent');
    inputType.setAttribute('aria-label', 'Numeric input field')
    if (question.userAnswers.length > 0) {
      question.userAnswers.forEach((element) => {
        this.renderer.setAttribute(inputType, 'value', element.value);
      });
    }

    // eslint-disable-next-line @typescript-eslint/no-empty-function
    this.renderer.listen(inputType, 'change', (event) => {
    });
    this.renderer.appendChild(div, inputType);
    return div.innerHTML;
  }

  // common code
  /**
   * @description: Dragged Options Disable
   */
  private draggedOptionDisable(options, e): any {
    const DraggedOption = options.find((i) => i.key === e.dragData.key);
    if (DraggedOption !== undefined) {
      DraggedOption.disabled = true;
    }
  }
  /**
   * @description: Shift Value
   */
  private insertAndShift(arr: any, from: any, to: any): void {
    const cutOut = arr.splice(from, 1)[0];
    arr.splice(to, 0, cutOut);
  }
}
