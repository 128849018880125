import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LabsRoutingModule } from './labs-routing.module';
import { MainComponent } from './components/main/main.component';
import { LabOverviewComponent } from './components/lab-overview/lab-overview.component';
import { LabVideoComponent } from './components/lab-video/lab-video.component';
import { LabComponent } from './components/lab/lab.component';
import { CookieService } from 'ngx-cookie-service';
import { CountdownModule } from 'ngx-countdown';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SharedModule } from '../shared/shared.module';
import { BsModalService } from 'ngx-bootstrap/modal';
import { TeamsUiModule } from '@teams-ui-angular';
import { TrialDetailsComponent } from './components/trial-details/trial-details.component';
import { UtilityModule } from '@utility';
import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';
@NgModule({
  declarations: [
    MainComponent,
    LabOverviewComponent,
    LabVideoComponent,
    LabComponent,
    TrialDetailsComponent,
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    LabsRoutingModule,
    CountdownModule,
    SharedModule,
    NgMultiSelectDropDownModule.forRoot(),
    TeamsUiModule,
    UtilityModule
  ],
  providers: [CookieService, BsModalService],
})
export class LabsModule {}
