<div class="Question-footer" [ngClass]="{ 
  'case-study-footer': (sectionType !== miscellaneousConst.freeFlowSectionType && 
                  sectionType !== miscellaneousConst.performanceQuizSectionType && 
                  sectionType !== miscellaneousConst.performanceQuizwithVM ) &&
                  sectionType !== miscellaneousConst.performanceTask ,
        'vm-footer'  :(sectionType === miscellaneousConst.performanceQuizwithVM || sectionType ===  miscellaneousConst.performanceTask), 
        'pt-footer-non-teams' :(cloudlabs === 'CloudLabs' || deviceDetectorService.isMobile()),
        'pt-footer-teams': (cloudlabs === 'CloudLabs' && auth.isTeamsAppUser()),
        'isNotSideNav': (sideNav && cloudlabs === 'CloudLabs' && !deviceDetectorService.isMobile()),
        'isSideNav': (!sideNav && cloudlabs === 'CloudLabs')
}">

    <ng-template #webView>
        <div class="float-left">
            <teams-button [classList]="'btn ts-btn-fluent-white ts-btn-fluent button-height-1 ts-btn-width-small mt-3 mb-3 footer-reset-button'" [title]=" 'actions.reset' | translate" *ngIf="sectionType !== miscellaneousConst.performanceTask" title="{{ 'exam.questions.resetTitle' | translate }}" (buttonClick)="getResetQuestion()">
            </teams-button>
            <teams-button [classList]="'btn ts-btn-fluent-white ts-btn-fluent button-height-1 ts-btn-width-small mt-3 mb-3 ml-4 footer-help-button'" [title]="'actions.help' | translate" (buttonClick)="getInstruction()" *ngIf="testDetails?.instruction !== null || getIsSectionInstruction()">
            </teams-button>
        </div>
        <div class="float-right">
            <teams-button [classList]="'btn ts-btn-fluent-white ts-btn-fluent button-height-1 ts-btn-width-default mt-3 mb-3 mr-4 footer-prev-button'" [title]="'actions.previousQuestion' | translate " (buttonClick)="getPreviousQuestion()" *ngIf="selectedIndex > 0 && getIsSectionReview() && sectionType !== miscellaneousConst.performanceTask">
            </teams-button>
            <teams-button [classList]="'btn ts-btn-fluent-green ts-btn-fluent button-height-1 ts-btn-width-default mr-4 mt-3 mb-3'" [ngClass]="
      !(selectedIndex > 0 && getIsSectionReview()) ? 'footer-prev-button' : ''
    " (buttonClick)="getFinishSection()" [title]="'actions.reviewSection' | translate" *ngIf="selectedIndex === questionList.length - 1 && sectionList.length > 1 && sectionType !== miscellaneousConst.performanceTask">
            </teams-button>
            <teams-button [classList]="'btn ts-btn-fluent-primary ts-btn-fluent button-height-1 ts-btn-width-default mt-3 mb-3 mr-4 footer-prev-button'" [ngClass]="
      !(selectedIndex > 0 && getIsSectionReview() )? 'footer-prev-button' : ''
    " (buttonClick)="getNextQuestion()" [title]="'actions.nextQuestion' | translate" *ngIf="selectedIndex !== questionList.length - 1 && sectionType !== miscellaneousConst.performanceTask">
            </teams-button>
            <teams-button [classList]="'btn ts-btn-fluent-green ts-btn-fluent button-height-1 ts-btn-width-default mt-3 mb-3 mr-4 footer-prev-button'"
    (buttonClick)="finishTestDialoge()" [title]="'actions.finishTest' | translate" *ngIf="sectionType === miscellaneousConst.performanceTask">
            </teams-button>
            <teams-button [classList]="'btn ts-btn-fluent-green ts-btn-fluent button-height-1 ts-btn-width-default mr-4 footer-prev-button mt-3 mb-3'" [ngClass]="
      !(selectedIndex > 0 && getIsSectionReview() )? 'footer-prev-button' : ''
    " (buttonClick)="getFinishSection()" *ngIf="
      selectedIndex === questionList.length - 1 && sectionList.length === 1 && sectionType !== miscellaneousConst.performanceTask
    " [title]="'actions.reviewAnswer' | translate">
            </teams-button>
        </div>
    </ng-template>
    <ng-template #mobileView>
        <div class="float-left col-sm-12 pl-0">
            <teams-button [classList]="'btn ts-btn-fluent-white ts-btn-fluent button-height-1 ts-btn-width-small mt-3 mb-3 footer-reset-button float-left'" [title]="'actions.reset' | translate" (buttonClick)="getResetQuestion()">
            </teams-button>
            <teams-button [classList]="'btn ts-btn-fluent-white ts-btn-fluent button-height-1 ts-btn-width-small mt-3 mb-3 ml-4 footer-help-button float-right'" [title]="'actions.help' | translate " (buttonClick)="getInstruction()" *ngIf="testDetails?.instruction !== null || getIsSectionInstruction()">
            </teams-button>
        </div>
        <div class="float-right col-sm-12 pl-0">
            <teams-button [classList]="'btn ts-btn-fluent-white ts-btn-fluent button-height-1 ts-btn-width-default mt-3 mb-3 footer-prev-button float-left pl-0 pr-0'" [title]="'actions.previousQuestion' | translate" (buttonClick)="getPreviousQuestion()" *ngIf="selectedIndex > 0 && getIsSectionReview()">
            </teams-button>
            <teams-button [classList]="'btn ts-btn-fluent-green ts-btn-fluent button-height-1 ts-btn-width-default mt-3 mb-3 float-right'" [ngClass]="
      !(selectedIndex > 0 && getIsSectionReview()) ? 'footer-prev-button': ''
    " (buttonClick)="getFinishSection()" [title]="'actions.reviewSection' | translate" *ngIf="selectedIndex === questionList.length - 1 && sectionList.length > 1">
            </teams-button>
            <teams-button [classList]="'btn ts-btn-fluent-primary ts-btn-fluent button-height-1 ts-btn-width-default mt-3 mb-3 footer-prev-button float-right pl-0 pr-0'" [ngClass]="
      !(selectedIndex > 0 && getIsSectionReview()) ? 'footer-prev-button' : ''
    " (buttonClick)="getNextQuestion()" [title]="'actions.nextQuestion' | translate " *ngIf="selectedIndex !== questionList.length - 1">
            </teams-button>
            <teams-button [classList]="'btn ts-btn-fluent-green ts-btn-fluent button-height-1 ts-btn-width-default footer-prev-button mt-3 mb-3 float-right'" [ngClass]="
      !(selectedIndex > 0 && getIsSectionReview()) ? 'footer-prev-button' : ''
    " (buttonClick)="getFinishSection()" *ngIf="
      selectedIndex === questionList.length - 1 && sectionList.length === 1
    " [title]="'actions.reviewAnswer' | translate ">
            </teams-button>
        </div>
    </ng-template>
    <div *ngIf="!deviceDetectorService.isMobile()">
        <ng-template [ngTemplateOutlet]="webView"></ng-template>
    </div>
    <div *ngIf="deviceDetectorService.isMobile()">
        <ng-template [ngTemplateOutlet]="mobileView"></ng-template>
    </div>
</div>
<app-help *ngIf="viewInstruction" [sectionList]="sectionList" [testDetails]="testDetails" [leftSectionCount]="leftSectionCount" [viewInstruction]="viewInstruction" (questionEmit)="getQuestions($event)" (viewInstructionEmit)="isInstruction($event)" (helpResponse)="closeHelpPopup($event)"></app-help>