import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

import { QuestionModel } from '../model/common.model';

import { CommonService } from '../services/common-service.service';
import { UserSessionService } from '@teams-auth';
import { EventEmitterService } from '@teams-auth';

import { decryptBackendResponse } from '@teams-auth';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'app-section-question',
  template: `
  `,
  styles: [
  ]
})
export class SectionQuestionComponent implements OnInit {
  public questionList: QuestionModel[] = [];
  public quest: QuestionModel[] = [];
  @Output() questionListEmit = new EventEmitter();
  @Input() sectionId;
  @Input() questionRes;


  constructor(
    private commonService: CommonService,
    private userSessionService: UserSessionService,
    private eventEmitter: EventEmitterService,
  ) { }

  ngOnInit(): void {
    this.getSectionQuestions();
  }

  getSectionQuestions(): any {
    this.commonService.getSectionQuestions(this.sectionId).subscribe(
      (encryptRes) => {
        const resQue = decryptBackendResponse(encryptRes, this.userSessionService.getOriginalKeyPair().private)
        this.questionList = resQue;
        this.quest = resQue;
        this.questionListEmit.emit(resQue);
        this.questionRes = resQue;
        this.userSessionService.questionApi$.next(true)
      },
      (error) => {
        const code = 'Error - 30041';
        this.eventEmitter.debugAlert(code, error.error);
      }
    );
  }

}
