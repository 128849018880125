<div>
    <ul class="singleresult-list singlequestion-list-result">
        <li>
            <!-- <span class="sql-title">Question Section</span> -->
            <div class="ans-question-box case-study-sql-ques">
                <strong class="sql-number">{{index}}. </strong><strong class="sql-ques" [innerHTML]="question?.question | targetBlank"></strong>
            </div>
            <!-- <span><b>Note:</b>  Each Correct answer carries 1 mark</span> -->
            <!-- <span class="sql-title">Answer Section</span> -->
            <div class="ans-swap-box">
                <ul class="swap-left-list-box build-list-result-left-box">
                    <li *ngFor="let item of options" [ngClass]="{'disableItem':item.disabled }">
                        {{item.value}}
                    </li>
                </ul>

                <ng-container *ngIf="question.userAnswers.length>0">
                    <ul class="swap-right-list-box build-list-result-left-box">
                        <ng-container *ngIf="dropOptions.length > 0">
                            <li *ngFor="let item of dropOptions;let i=index;" [ngClass]="item.answer===1?'sql-option-box-success':item.answer===2?'sql-option-box-failure':''">
                                {{item.value}}
                            </li>
                        </ng-container>
                        <ng-container *ngIf="dropOptions.length===0">
                            <li></li>
                        </ng-container>
                    </ul>

                    <ul class="swap-right-list-box build-list-result-left-box">
                        <ng-container *ngIf="correctOptions.length > 0">
                            <li *ngFor="let item of correctOptions;let i=index;" class="build-list-option">
                                {{item.value}}
                            </li>
                        </ng-container>
                    </ul>
                </ng-container>
                <ng-container *ngIf="question.userAnswers.length===0">

                    <ul class="swap-right-list-box build-list-result-left-box">
                        <ng-container *ngIf="correctOptions.length > 0">
                            <li *ngFor="let item of correctOptions;let i=index;" class="build-list-option">
                                {{item.value}}
                            </li>
                        </ng-container>
                    </ul>
                </ng-container>
            </div>
        </li>
    </ul>
    {{question.userScore}}/{{question.questionScore}} {{ 'exam.result.points' | translate }}
</div>