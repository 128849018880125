<div>
    <div class="singlequestion-list">
        <div class="pt-dropdown-div">
          <!--No question title for dropdown type questions | No question and answer headings | Sheikh-->
            <div class="sql-ques">
                <span *ngIf="question?.note !== null"><strong>{{ 'exam.questions.note' | translate }}: </strong> <span [innerHTML]="question?.note"></span></span>
            </div>
            <span *ngIf="question?.instruction !== null"><strong>{{ 'exam.questions.instruction' | translate }}:</strong> <span
          [innerHTML]="question?.instruction"></span></span>
            <div class="dropdown-quest case-study-sql-ques" [innerHTML]="dropdownStrList"></div>
          </div>
      </div>
</div>

