import { EventEmitter, Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { ActivatedRoute } from '@angular/router';

import { API } from '../consts/api.const';
import { APIProd } from '../consts/api-prod.const';

import { PollRequestModel, PollResponseModel } from '../models/common.model';

import { BaseServiceService } from '../services/base-service.service';

import { miscellaneousConst, apiUrl, isExternalFlow } from '@utility';

import { UserSessionService } from '@teams-auth';

@Injectable({
  providedIn: 'root',
})
export class CommonService {
  private regUniqueName = '';
  finishTest = new EventEmitter();
  taskCount = new EventEmitter();
  progressValue = new EventEmitter();
  credStatus = new Subject();

  private API = this.userSessionService.isProdUser ? APIProd : API;

  constructor(
    private baseService: BaseServiceService,
    private route: ActivatedRoute,
    public userSessionService: UserSessionService
  ) {
    this.route.queryParams.subscribe((params) => {
      this.regUniqueName = params.regUniqueName;
    });
  }

  public getExam(isMobile: boolean): Observable<any> {
    const testUrl =
      this.API.testData.test + miscellaneousConst.mobileQueryParam + isMobile;
    return this.baseService.get(testUrl);
  }

  onFinishTest() {
    this.finishTest.next();
  }

  OntaskCount(val) {
    this.taskCount.emit(val);
  }
  OnprogressValue(val) {
    this.progressValue.emit(val);
  }
  public getSingleExam(ptGuid, ptUserGuid): Observable<any> {
    let getSingleExamUrl = `${this.API.testData.getSingleExam}`
      .replace('{examUniqueName}', ptGuid)
      .replace('{examRegistrationAssignmentUniqueName}', ptUserGuid);
    getSingleExamUrl = apiUrl(this.regUniqueName, getSingleExamUrl);
    return this.baseService.get(getSingleExamUrl);
  }

  public getCompletedExam(): Observable<any> {
    const testUrl = this.API.testData.completedExams;
    return this.baseService.get(testUrl);
  }

  public getExamForExternalUser(examId): Observable<any> {
    const getExternalExamDetailsUrl =
      `${this.API.testData.getExternalExamDetails}`.replace(
        '{exam_GUID}',
        examId
      );
    const testByIdUrl = `${this.API.testData.testById}`.replace('{id}', examId);
    if (isExternalFlow(this.regUniqueName)) {
      return this.baseService.get(
        getExternalExamDetailsUrl + '?regUniqueName=' + this.regUniqueName
      );
    }
    return this.baseService.get(testByIdUrl);
  }

  public createExternalExamInstance(examId): Observable<any> {
    let createExternalExamInstanceUrl =
      `${this.API.testData.createExternalExamInstance}`.replace(
        '{exam_GUID}',
        examId
      );
    createExternalExamInstanceUrl = apiUrl(
      this.regUniqueName,
      createExternalExamInstanceUrl
    );
    return this.baseService.postwithobject(createExternalExamInstanceUrl);
  }

  public getExamByExamInstance(examInstances): Observable<any> {
    let examByExamInstanceUrl =
      `${this.API.testData.getExamByExamInstance}`.replace(
        '{examInstance_GUID}',
        examInstances
      );
    examByExamInstanceUrl = apiUrl(this.regUniqueName, examByExamInstanceUrl);
    return this.baseService.get(examByExamInstanceUrl);
  }
  public updateExamQuestionInstance(questionId, data): Observable<any> {
    let updateExamQuestionUrl =
      `${this.API.testData.updateExamQuestion}`.replace(
        '{question_GUID}',
        questionId
      );
    updateExamQuestionUrl = apiUrl(this.regUniqueName, updateExamQuestionUrl);
    return this.baseService.putwithobject(updateExamQuestionUrl, data);
  }
  public updateExamPeriodically(examInstances, data): Observable<any> {
    let updateExamPeriodicallyUrl =
      `${this.API.testData.updatePeriodically}`.replace(
        '{examInstance_GUID}',
        examInstances
      );
    updateExamPeriodicallyUrl = apiUrl(
      this.regUniqueName,
      updateExamPeriodicallyUrl
    );
    return this.baseService.putwithobject(updateExamPeriodicallyUrl, data);
  }
  public endExamInstance(examInstances, data): Observable<any> {
    let endExamInstanceUrl = `${this.API.testData.endExamInstance}`.replace(
      '{examInstance_GUID}',
      examInstances
    );
    endExamInstanceUrl = apiUrl(this.regUniqueName, endExamInstanceUrl);
    return this.baseService.putwithobject(endExamInstanceUrl, data);
  }
  public updateExamQuestionProgress(questionId): Observable<PollResponseModel> {
    let updateExamQuestionProgressUrl =
      `${this.API.testData.updateExamQuestionProgress}`.replace(
        '{question_GUID}',
        questionId
      );
    updateExamQuestionProgressUrl = apiUrl(
      this.regUniqueName,
      updateExamQuestionProgressUrl
    );
    return this.baseService.putwithobject<PollRequestModel>(
      updateExamQuestionProgressUrl
    );
  }

  public getExamById(id): Observable<any> {
    let testByIdUrl = `${this.API.testData.testById}`.replace('{id}', id);
    testByIdUrl = apiUrl(this.regUniqueName, testByIdUrl);
    return this.baseService.get(testByIdUrl);
  }
  public getSectionQuestions(sectionId): Observable<any> {
    let sectionQuestionUrl = `${this.API.testData.getAllExamQuestions}`.replace(
      '{section_GUID}',
      sectionId
    );
    sectionQuestionUrl = apiUrl(this.regUniqueName, sectionQuestionUrl);
    return this.baseService.getWithText(sectionQuestionUrl);
  }
  public updateExamInstanceSectionProgress(sectionId, data): Observable<any> {
    let inProgressUrl =
      `${this.API.testData.updateExamInstanceSectionProgress}`.replace(
        '{section_GUID}',
        sectionId
      );
    inProgressUrl = apiUrl(this.regUniqueName, inProgressUrl);
    return this.baseService.putwithobject(inProgressUrl, data);
  }
  public completeExamInstanceSection(sectionId, data): Observable<any> {
    let completeExamUrl =
      `${this.API.testData.completeExamInstanceSection}`.replace(
        '{section_GUID}',
        sectionId
      );
    completeExamUrl = apiUrl(this.regUniqueName, completeExamUrl);
    return this.baseService.putwithobject(completeExamUrl, data);
  }

  public createExamInstance(examId, data): Observable<any> {
    let examInstanceUrl = `${this.API.testData.createExamInstance}`.replace(
      '{exam_GUID}',
      examId
    );
    examInstanceUrl = apiUrl(this.regUniqueName, examInstanceUrl);
    return this.baseService.postwithobject(examInstanceUrl, data);
  }

  /**
   * @description: Post suggested question api call
   */

  public suggestQuestions(data): Observable<any> {
    let suggestQuestionsUrl = `${this.API.testData.suggestQuestions}`;
    suggestQuestionsUrl = apiUrl(this.regUniqueName, suggestQuestionsUrl);
    return this.baseService.postwithobject(suggestQuestionsUrl, data);
  }

  /**
   * @description: Get suggested question api call
   */

  public getsSuggestedQuestions(): Observable<any> {
    let getsSuggestedQuestionsUrl = `${this.API.testData.getsSuggestedQuestions}`;
    getsSuggestedQuestionsUrl = apiUrl(
      this.regUniqueName,
      getsSuggestedQuestionsUrl
    );
    return this.baseService.get(getsSuggestedQuestionsUrl);
  }

  /**
   * @description: Get Voucher Code api call download app
   */
  public getVoucherCode(registrations, assignments): Observable<any> {
    const voucherUrl = `${this.API.testData.getVoucherCodeDownloadApp}`
      .replace('{registrations}', registrations)
      .replace('{assignments}', assignments);
    return this.baseService.get(voucherUrl);
  }

  /**
   * @description: Get Comprehensive Report
   */
  public getresultStatistics(examInstanceUniqueName): Observable<any> {
    let resultStatisticsUrl = `${this.API.testData.resultStatistics}`.replace(
      '{examInstanceUniqueName}',
      examInstanceUniqueName
    );
    resultStatisticsUrl = apiUrl(this.regUniqueName, resultStatisticsUrl);
    return this.baseService.get(resultStatisticsUrl);
  }

  /**
   * @description:  Redeem Voucher api call
   */
  public redeemVoucher(code): Observable<any> {
    const redeemVoucherUrl = `${this.API.testData.redeemVoucher}`.replace(
      '{code}',
      code
    );
    return this.baseService.get(redeemVoucherUrl);
  }

  public getlabguideQuestion(voucherCode, pageNumber): Observable<any> {
    let labguideQuestionUrl = `${this.API.testData.labguideQuestion}`
    .replace('{voucherCode}',voucherCode)
    .replace('{pageNumber}', pageNumber);
    return this.baseService.get(labguideQuestionUrl)
  }

  public updateUngroupedAnswer(voucherCode, data): Observable<any> {
    let updateUngroupedAnswerUrl = `${this.API.testData.updateUngroupedAnswer}`
    .replace('{voucherCode}',voucherCode)
    return this.baseService.putwithobject(updateUngroupedAnswerUrl, data)
    // return this.baseService.putwithobject(completeExamUrl, data);
  }

  public updateGroupedAnswer(voucherCode, data): Observable<any> {
    let updateGroupedAnswerUrl = `${this.API.testData.updateGroupedAnswer}`
    .replace('{voucherCode}',voucherCode)
    return this.baseService.putwithobject(updateGroupedAnswerUrl, data)
  }
}