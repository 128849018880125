import { Component, Input, OnInit } from '@angular/core';

import { ResultHelperService } from '../../../services/result-helper.service';

@Component({
  selector: 'single-choice-result',
  templateUrl: './single-choice-result.component.html',
})
export class SingleChoiceResultComponent implements OnInit {
  @Input() question;
  @Input() examId;
  @Input() index;
  characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  name = '';
  constructor(private resultHelper: ResultHelperService) { }

  ngOnInit(): void { }

  /**
   * @description: Change Single Choice Value
   */
  ngOnChanges(): void {
    this.name = this.generateString(10);
    this.question?.options.forEach((element) => {
      element.checked = '';
      element.answer = 0;
      element.attempt = 0;
    });
    if (this.question.userAnswers.length === 0) {
      this.question.options.forEach((element) => {
        const ans = this.question.answers.find((i) => i.key === element.key);
        element.attempt = ans ? 1 : 0;
      });
    }
    if (this.question.userAnswers.length > 0) {
      this.loadUserSelectedAnswers();
    }
  }
  /**
   * @description: Load User Selected Answers
   */
  private loadUserSelectedAnswers(): any {
    this.resultHelper.loadUserSelectedSingleChoiceAnswers(this.question);
  }
  /**
   * @description: Generate String
   */
  public generateString(length: any): string {
    let result = ' ';
    const charactersLength = this.characters.length;
    for (let i = 0; i < length; i++) {
      result += this.characters.charAt(
        Math.floor(Math.random() * charactersLength)
      );
    }

    return result;
  }
}
