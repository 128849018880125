import {
  Component,
  Input,
  Output,
  EventEmitter,
  OnChanges,
  OnDestroy,
} from '@angular/core';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import {
  FormGroup,
  FormBuilder,
  Validators,
  AbstractControl,
  FormControl,
  ValidatorFn,
  ValidationErrors,
  Validator,
} from '@angular/forms';
import { DeviceDetectorService } from 'ngx-device-detector';

import { SendFeedbackModel } from '../../model/test-result.model';

import { EventEmitterService } from '@teams-auth';
import { TestResultService } from '../../services/test-result/test-result.service';
import { UserSessionService } from '@teams-auth';

import { CustomValidators, LoadingService, unsubscribeCollection } from '@utility';
import { LocalSessionStorageService } from '../../services/local-session-storage.service';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'app-feedback',
  templateUrl: './feedback.component.html',
})
export class FeedbackComponent implements OnChanges, OnDestroy {
  public feedbackForm: FormGroup;
  @Input() isExamFeedback: boolean;
  @Input() examId: any;
  @Input() examInstanceId: any;
  rating = 0;
  public experienceRating = 0;
  public feedback = '';
  public questionsQualityRating = 0;
  public showFeedback = false;
  private subscriptions: Subscription[] = [];
  public submitted = false;
  public loaderMsgPopup: string[];
  private isCourseFlow;
  public dialogClass: string;

  @Output() feedbackResponse = new EventEmitter();
  constructor(
    private testResultService: TestResultService,
    private eventEmitter: EventEmitterService,
    private router: Router,
    private readonly translateService: TranslateService,
    private formBuilder: FormBuilder,
    public deviceDetectorService: DeviceDetectorService,
    public userSessionService: UserSessionService,
    private localStorageService: LocalSessionStorageService,
    private loadingService: LoadingService
  ) {}

  ngOnChanges(): void {
    this.showFeedback = this.isExamFeedback;
    this.clearFeedbackForm();
    this.initializeForm();
    this.userSessionService.coursePt$.subscribe((e) => {
      this.isCourseFlow = e;
    });
  }

  /**
   * @description: Initializing a feedback form using reactive forms.
   */

  private initializeForm(): void {
    this.feedbackForm = this.formBuilder.group({
      experienceRating: [null, Validators.compose([Validators.required, CustomValidators.notZeroValidator])],
      questionsQualityRating: [null, Validators.compose([Validators.required, CustomValidators.notZeroValidator])],
      feedback: '',
    });
  }

  // convenience getter for easy access to form fields

  get formControls() {
    return this.feedbackForm.controls;
  }

  /**
   * @description: call api to send feedback
   */
  public sendFeedback(): any {
    this.submitted = true;
    if (this.feedbackForm.invalid) {
      return;
    }
    const model: SendFeedbackModel = {
      experienceRating: parseFloat(this.feedbackForm.value.experienceRating),
      questionsQualityRating: parseFloat(
        this.feedbackForm.value.questionsQualityRating
      ),
      feedback: this.feedbackForm.value.feedback,
    };
    this.loaderMsgPopup = ['common.spinner.feedbackSaving'];
    this.loadingService.loadingOn(this.loaderMsgPopup);
    this.subscriptions[this.subscriptions.length] = this.testResultService
      .sendFeedback(this.examId, this.examInstanceId, model)
      .subscribe(
        (res) => {
          this.loadingService.loadingOff();
          this.showFeedback = false;
          this.isExamFeedback = this.showFeedback;
          this.feedbackResponse.emit(this.isExamFeedback);
          this.eventEmitter.successHandler(
            this.translateService.instant('common.message.feedbackSuccess'),
            1000
          );
          setTimeout(() => {
            const lang = this.userSessionService.getDefaultLanguage();
            this.translateService.use(lang.value);
            if (localStorage.getItem('context') === 'CloudLabs') {
              // if (this.localStorageService.getCourseStorage() === 'false') {
              //   this.router.navigate([`/${getEntity()}/${miscellaneousConst.navigation.practiceTests}`]);
              //   }
              //   else if (this.localStorageService.getCourseStorage() === 'true') {
              //     this.router.navigate([`/${getEntity()}/${getEntityData()}`]);
              //   }

              if (
                this.localStorageService.getCurrentPTDetailsPagePath() !== ''
              ) {
                this.router.navigate([
                  this.localStorageService.getCurrentPTDetailsPagePath(),
                ]);
                this.localStorageService.setPTDetailsPageEmpty();
              }
            } else if (localStorage.getItem('context') === 'PracticeTest') {
              this.router.navigate(['/']);
            }
          }, 1000);
        },
        (error) => {
          this.loadingService.loadingOff();
          const code = 'Error - 10011';
          this.eventEmitter.debugAlert(code, error.error);
        }
      );
  }
  /**
   * @description: Cancel Feedback
   */
  public cancelFeedback(): any {
    this.setLanguage();
    // No need to change this flag | Sheikh
    // this.showFeedback = false;
    this.isExamFeedback = this.showFeedback;
    this.feedbackResponse.emit(this.isExamFeedback);
    setTimeout(() => {
      if (localStorage.getItem('context') === 'CloudLabs') {
        // if (this.localStorageService.getCourseStorage() === 'false') {
        //   this.router.navigate([`/${getEntity()}/${miscellaneousConst.navigation.practiceTests}`]);
        //   }
        //   else if (this.localStorageService.getCourseStorage() === 'true') {
        //     this.router.navigate([`/${getEntity()}/${getEntityData()}`]);
        //   }
        if (this.localStorageService.getCurrentPTDetailsPagePath() !== '') {
          this.router.navigate([
            this.localStorageService.getCurrentPTDetailsPagePath(),
          ]);
          this.localStorageService.setPTDetailsPageEmpty();
        }
      } else if (localStorage.getItem('context') === 'PracticeTest') {
        this.router.navigate(['/']);
      }
    }, 1000);
  }
  /**
   * @description: Clear Feedback Form
   */
  private clearFeedbackForm(): void {
    this.experienceRating = 0;
    this.feedback = '';
    this.questionsQualityRating = 0;
  }

  public setLanguage(): void {
    const lang = this.userSessionService.getDefaultLanguage();
    this.translateService.use(lang.value);
  }
  /**
   * @description Component lifecycle method, gets called when component destroys
   */
  ngOnDestroy(): void {
    unsubscribeCollection(this.subscriptions);
  }
  onChangeval(controlName, value) {
    // this.feedbackForm.controls[controlName].reset();
    this.feedbackForm.controls[controlName].setValue(value);
  }

  toControl(absCtrl: AbstractControl): FormControl {
    const ctrl = absCtrl as FormControl;
    // if(!ctrl) throw;
    return ctrl;
  }

  /**
   * @description: Close Help
   */
  public closeFeedback(event): any {
    // this.viewInstruction = event.help;
    // this.helpResponse.emit(this.viewInstruction);
  }
}
