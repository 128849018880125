import { Injectable } from '@angular/core';
import {BehaviorSubject} from 'rxjs';
@Injectable({
  providedIn: 'root',
})
export class CaptchaService {
  
  captchSource = new BehaviorSubject(null);
  captchStatus = this.captchSource.asObservable();
  formSubmitted = new BehaviorSubject(null);
  formSubmitted$ = this.formSubmitted.asObservable();

  setCaptchaStatus(code) {
    this.captchSource.next(code);
  }

}