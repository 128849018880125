import { Inject, Injectable } from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Language} from '../modals/language.modal';
import {TranslateService} from '@ngx-translate/core';
// import {TranslateCacheService} from 'ngx-translate-cache';
import {EventService} from './event.services';
import {Lab} from '../modals/lab.model';
import {LabCluster} from '../modals/lab-cluster.modal';
import { Location } from '@angular/common';
import { miscellaneousConst } from '@utility';
@Injectable({
  providedIn: 'root'
})
export class UtilityService {
  apiUrl: any;
  headers;


  constructor(
    private httpClient: HttpClient,
    private translate: TranslateService,
    // public translateCacheService: TranslateCacheService,
    public eventServ: EventService,
    private location: Location,
    @Inject('environment')
    environment
  ) {
    if(localStorage.getItem('context') === miscellaneousConst.cloudlab){
      this.apiUrl = environment.apiUrl;
    }
    else{
      this.apiUrl = environment.APIURL;
    }
    this.headers = new HttpHeaders({
      'Content-Type': 'application/x-www-form-urlencoded'
    });
  }
  
  getLanguages() {
    return this.httpClient.get<Language[]>(`${this.apiUrl}Option/Languages`);
  }

  switchLanguage(language: string) {
    this.translate.use(language);
    // this.translateCacheService.init();
    localStorage.setItem('language', language);
    this.eventServ.languageIsChanged(language);
    setTimeout(() => {
      window.dispatchEvent(new Event('resize-tabs'));
    }, 1000);

  }

  switchLanguageToDefault(lab: Lab | LabCluster) {
    const key = `lab_language_${lab?.UniqueName}`;
    const lang = localStorage.getItem(key);
    if (!lang) {
      if (lab?.LanguageCode) {
        this.switchLanguage(lab.LanguageCode);
        localStorage.setItem(key, lab.LanguageCode);
      }
    }
  }
}
