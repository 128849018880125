export const encryptionAPIsConst = {
  sections: '/sections',
  result: '/result',
  shouldNotInclude: {
    inProgress: 'inProgress',
    submit: 'submit',
    labDetails: 'labDetails',
  },
  keyIndex0: 0,
  keyIndex1: 256,
  keyIndex2: 512,
};
