<div class="captcha-container {{ config.cssClass }}">
  <!-- <canvas id="captcahCanvas" width="405" height="80"></canvas> -->
  <div class="captcha-text mb-3">
    <h4 class="m-0 font-weight-normal">{{ resultCode }}</h4>
    <span
      ><a
        href="javascript:void(0)"
        class="cpt-btn reload"
        (click)="createCaptcha(); checkCaptcha();"
        >&#x21bb;</a
      ></span
    >
  </div>
  <div class="captcha-actions form-group">
    <teams-input
      type="text"
      [classList]="'form-control'"
      [placeholder]=" 'ENTER_CAPTCHA' | translate"
      [ngClassList]="{
        'is-invalid':
          (captchaText.touched && captchaText.errors) ||
          (captchaText.touched && !validCaptcha)
      }"
      [formControl]="captchaText"
      (focusout)="checkCaptcha()"
    ></teams-input>
    <div
      *ngIf="captchaText.touched && captchaText.errors?.required"
      class="d-block invalid-feedback"
    >
      {{ 'MESSAGE_REQUIRED_CAPTCHA' | translate }}
    </div>
    <div
      *ngIf="
        captchaText.touched &&
        validCaptcha === false &&
        captchaText.errors === null
      "
      class="d-block invalid-feedback"
    >
      {{ 'MESSAGE_INVALID_CAPTCHA' | translate }}
    </div>
  </div>
</div>
