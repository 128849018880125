<ngx-loading-bar [height]="'4px'" [color]="themeColor"></ngx-loading-bar>

<div class="d-flex flex-column flex-grow-1 position-relative" *ngIf="cookies">
  <router-outlet></router-outlet>
</div>
<!-- <app-footer *ngIf="enableFooter && cookies && !loading" class="appfooter"></app-footer> -->


<div class="col-md-6  mx-auto mt-5" *ngIf="!cookies">
  <div class="card">
    <div  class="alert alert-warning mb-0">
      <h4  class="block">
        <i  aria-hidden="true" aria-label="img" class="fa fa-exclamation-triangle" role="img"></i>
         &nbsp; Cookies Required </h4>
      <p aria-label="cookies Message">Cookies are not enabled on your browser. Please enable cookies in your browser preferences to continue.</p>
    </div>
</div>
</div>


<!--Change theme widget -->
<div class="adjustTheme">
  <cloudlabs-theme></cloudlabs-theme>
</div>
