<div class="w-100">
    <ul class="singleresult-list singlequestion-list-result">
        <li>
            <div *ngIf="question.userAnswers.length >0">
                <ng-container *ngIf="!checkValue(question.answers)">
                    <div>
                        <strong class="sql-number">{{index}}. </strong>
                        <div [innerHTML]="numericInput"></div>
                    </div>
                    <div [innerHTML]="numericInputCorrect"></div>
                </ng-container>
                <ng-container *ngIf="checkValue(question.answers)">
                    <strong class="sql-number">{{index}}. </strong>
                    <div [innerHTML]="numericInput"></div>
                </ng-container>
            </div>
            <div *ngIf="question.userAnswers.length===0">
                <strong class="sql-number">{{index}}. </strong>
                <div [innerHTML]="numericInputCorrect"></div>
            </div>
        </li>
    </ul>
    {{question.userScore}}/{{question.questionScore}} {{ 'exam.result.points' | translate }}
</div>