import { Injectable } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

// import { API } from '@shared/const/api.const';

// import { BaseServiceService } from '@shared/services/base-service.service';

@Injectable({
  providedIn: 'root'
})
export class TestSectionReviewService {
  private regUniqueName = '';
  constructor(
    // private baseService: BaseServiceService, 
    private route: ActivatedRoute) {
    this.route.queryParams.subscribe(params => {
      this.regUniqueName = params.regUniqueName;
    });
  }
}
