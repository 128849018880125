import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { UserSessionService } from '@teams-auth';

@Component({
  selector: 'cloudlabs-menu',
  templateUrl: './menu.component.html',
})
export class MenuComponent implements OnInit {
  @Input() selectedMenu = '';
  @Input() menuList = [];
  @Output() selectedValueEmit = new EventEmitter();

  constructor(
    public userSessionService: UserSessionService,
    public router: Router
  ) {}

  ngOnInit(): void {}

  public onMenuSelect(index): void {
    this.selectedMenu = this.menuList[index].id;
    this.selectedValueEmit.emit(index);
  }
}
