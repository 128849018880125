import { Directive, OnDestroy, OnInit } from '@angular/core';

@Directive({
  selector: '[appRefocusPreviousEl]'
})
export class RefocusPreviousElDirective implements OnInit, OnDestroy {

  private previousFocusedElement: Element;

  constructor() { }

  ngOnInit() {
    this.previousFocusedElement = document.activeElement;
  }

  ngOnDestroy() {
    (this.previousFocusedElement as HTMLElement).focus();
  }

}
