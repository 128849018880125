import { Component } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { Subject } from 'rxjs';
import { EventService } from '../../../services/event.services';

@Component({
  selector: 'cloudlabs-environment-delete-dialog',
  templateUrl: './environment-delete-dialog.component.html',
  styleUrls: ['./environment-delete-dialog.component.scss'],
})
export class EnvironmentDeleteDialogComponent {
  public title: string;
  public data: any;
  public edgePopUpHeading: boolean;
  public onClose: Subject<boolean>;
  constructor(public modalRef: BsModalRef, public eventSrv: EventService) {}
}
