import { Injectable } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

// import { API } from '../../../../../../../../libs/questions-ui/src/lib/consts/api.const';

// import { BaseServiceService } from '../../../../../../../../libs/questions-ui/src/lib/services/base-service.service';

// import {
//   apiUrl
// } from '../../../../../../../../libs/questions-ui/src/lib/utils/common-utilities.util';
@Injectable({
  providedIn: 'root'
})
export class TestDetailsService {
  private regUniqueName = '';
  constructor(
    // private baseService: BaseServiceService,
     private route: ActivatedRoute) {
    this.route.queryParams.subscribe(params => {
      this.regUniqueName = params.regUniqueName;
    });
  }
}
