import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { EventEmitterService, UserSessionService, VMModel } from '@teams-auth';
import { miscellaneousConst, copyToClipboard, unsubscribeCollection } from '@utility';
import { Subscription } from 'rxjs';
import { PerformanceQuizService } from '../../services/performance-quiz.service';
import { ActivatedRoute } from '@angular/router';
import { CommonService } from '../../services/common-service.service';

@Component({
  selector: 'cloudlabs-credential',
  templateUrl: './credential.component.html',
  styleUrls: ['./credential.component.scss']
})
export class CredentialComponent implements OnInit, OnDestroy {

  private subscriptions: Subscription[] = [];
  private incVal = 0;
  public outputParametersKeys = [];
  public outputParametersValues = [];
  private updatedTimerInterval: any;
  public progreessBar = true;
  public userName = '';
  public passWord = '';
  public performanceProgressChangeValue = 0;
  public runTimer = true;
  public isRetry = false;
  public azurePortalUrl;
  public viewCredentials = false;
  private labDetails = '';
  public isCredGenerated = false;
  @Input() sectionType;
  @Output() credGenerated = new EventEmitter();
  // @Input() getSectionId: string;
  private getSectionId: string;
  public miscellaneousConst = miscellaneousConst;

  constructor(
    private performanceQuiz: PerformanceQuizService,
    private readonly userSessionService: UserSessionService,
    private eventEmitter: EventEmitterService,
    private readonly translateService: TranslateService,
    private route: ActivatedRoute,
    private commonSrc: CommonService
  ) {
    this.route.params.subscribe((i) => {
      this.getSectionId = i.sectionId;
    });
    // console.log('Cred', this.sectionType)
   }

  ngOnInit(): void {
    this.progressTimerValue();
    this.getLabDetails();
    clearInterval(this.updatedTimerInterval);
    this.azurePortalUrl = miscellaneousConst.azurePortalUrl;
  }

  /**
   * @description: Change Progress Time Value
   */
   private progressTimerValue(): void {
    if (this.incVal !== 90) {
      setTimeout(() => {
        this.incVal = ++this.incVal;
        this.progressTimerValue();
      }, 1660);
    }
    this.performanceProgressChangeValue = this.incVal;
  }

  /**
   * @description: Get Lab Details
   */
   public getLabDetails(): void {
    this.subscriptions[
      this.subscriptions.length
    ] = this.performanceQuiz.getLabDetails(this.getSectionId).subscribe(
      (res) => {
        this.labDetails = res;
        if (res.content.deploymentStatus === miscellaneousConst.labStatusDeploying) {
          this.commonSrc.credStatus.next(miscellaneousConst.labStatusDeploying);
          setTimeout(() => {
            this.getLabDetails();
          }, 30000);
        } else if (this.viewCredentials === false && res.content.deploymentStatus === miscellaneousConst.labStatusSucceeded) {
          this.commonSrc.credStatus.next(miscellaneousConst.labStatusSucceeded);
          this.isCredGenerated = true;
          this.credGenerated.emit(this.isCredGenerated);
          const vmDetails: VMModel = new VMModel();
          if (res.content.guacamoleServerUrl && res.content.rdpUri) {
            vmDetails.guacamoleServerUrl = res.content.guacamoleServerUrl;
            vmDetails.rdpUri = res.content.rdpUri;
            this.userSessionService.vmDetails$.next(vmDetails);
          }

          this.userName = res.content.userName;
          this.passWord = res.content.password;
          this.outputParametersKeys = Object.keys(res.content.outputParameters);
          this.outputParametersValues = Object.values(
            res.content.outputParameters
          );
          this.isRetry = false;
          this.viewCredentials = true;
        } else if (
          res.content.deploymentStatus === miscellaneousConst.labStatusDeleting ||
          res.content.deploymentStatus === miscellaneousConst.labStatusDeleted
        ) {
          this.commonSrc.credStatus.next(res.content.deploymentStatus);
          this.progreessBar = false;
          this.eventEmitter.errorHandler(
            this.translateService.instant('common.message.credFailed')
          );
        }
      },
      (error) => {
        this.commonSrc.credStatus.next('failedEnvironment');
        this.isRetry = true;
        const code = 'Error - 10006';
        this.progreessBar = false;
        this.eventEmitter.errorHandler(error.error.message)
        this.eventEmitter.debugAlert(code, error.error.message)
      }
    );
  }

  /**
   * @description: Copy User Name  Element
   */
   public copyContent(copyElement): void {
    copyToClipboard(copyElement.value);
  }

  ngOnDestroy(): void {
    clearInterval(this.updatedTimerInterval);
    unsubscribeCollection(this.subscriptions);
  }

}
