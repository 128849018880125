import { Directive,ElementRef,OnInit } from '@angular/core';

@Directive({
  selector: '[appRemoveFocusOkButton]'
})
export class RemoveFocusOkButtonDirective implements OnInit {

  constructor( private el: ElementRef ) { }
  
  ngOnInit(){
    setTimeout(() => {
      (this.el?.nativeElement as HTMLElement).setAttribute('aria-hidden', 'false');
    },500);
  }

}
