<div class="card">
  <img class="card-img-top" src="{{ lession.image }}" alt="Card image cap" />
  <div class="progress" style="height: 16px;">
    <div
      class="progress-bar"
      role="progressbar"
      [style.width.%]="lession.progress"
      aria-valuenow="25"
      aria-valuemin="0"
      aria-valuemax="100"
    ></div>
  </div>
  <div class="card-body">
    <div class="card-title mb-0">
      {{ lession.title }}<span class="float-right">{{ lession.time }}</span>
    </div>
    <p class="card-text">{{ lession.desc }}</p>
    <p *ngIf="lession.duration" class="card-text mb-1">
      Duration : {{ lession.duration }}
    </p>
    <div *ngIf="!lession.duration" class="flex-container mb-3">
      <div>120 <br />Minute</div>
      <div>42<br />Questions</div>
      <div>70%<br />Pass</div>
    </div>
    <span class="tag">{{ lession.tag }}</span>
  </div>
</div>
