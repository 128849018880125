/* eslint-disable no-useless-escape */
import { HttpClient, HttpHeaders } from '@angular/common/http';
import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  HostListener,
  Inject,
  Input,
  OnChanges,
  OnDestroy,
  Output,
  SimpleChanges,
} from '@angular/core';
import { WebSocketTunnel } from '@illgrenoble/guacamole-common-js';
import {
  debounceTime,
  distinctUntilChanged,
  take,
  takeUntil,
  throttleTime,
} from 'rxjs/operators';
import { Subject, Subscription } from 'rxjs';

import { UserSessionService, EventEmitterService } from '@teams-auth';
import { RemoteDesktopManager } from '../remote';
import {
  copyToClipboard,
  emptySelection,
  unsubscribeCollection,
} from '@utility';
import { TranslateService } from '@ngx-translate/core';
import { NotificationService } from '../../services/notification.service';
import { LabService } from '../../services/lab.service';
import { RdpDialogComponent } from '../labenvironment/components/environment/rdp-dialog/rdp-dialog.component';
import { ContainerAction, Lab } from '../../modals/lab.model';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { EventService, RDPDialogStatus } from '../../services/event.services';
import { Router } from '@angular/router';
import { detect } from 'detect-browser';
import { CountdownEvent } from 'ngx-countdown';
import { ButtonLoadingService } from '@teams-ui-angular';
import {
  AppInsightEvents,
  RDPOperation,
  PropertyName,
} from '@libs/teams-auth/src/lib/_consts/app-insight.const';
@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'app-vm',
  templateUrl: './vm.component.html',
  styleUrls: ['./vm.component.scss'],
})
export class VmComponent implements OnDestroy, OnChanges {
  @Input() lab: Lab;
  @Input() isInsideEnv = false;
  @Input() isMylearning = true;
  @Input() vCode: string;
  private notifier = new Subject();
  resizeSubsciption: Subscription;
  private managerSubscriptions: Subscription[] = [];
  reloadVMs = new Subject();
  vmFocus: boolean;
  loader: boolean;
  @Input() cloudPlateFormId: number;
  public manager: RemoteDesktopManager;
  webSocketTunnels;
  // static values need to change accordingly
  guacamoleServerUrl = '';
  rdpUri = '';
  token;
  headers = new HttpHeaders({
    'Content-Type': 'application/x-www-form-urlencoded',
  });
  modelShow = false;
  public hidingRdpAriaLabel: string;
  public narratorAlertMessage = false;
  environment: any;
  public modalRef: BsModalRef;
  public remoteConnectProcessStatus = '';
  showRefreshButton: boolean;
  vmServiceSubscription: Subscription;
  tokenSubscription: Subscription;
  vmConnectionService: Subscription;
  public remoteDesktopStates = RemoteDesktopManager.STATE;
  @Input() credGenerated: boolean;
  private browser = detect();
  @Output() private checkVmStatus = new EventEmitter();
  isFileTransferMode = null;
  @Output() stepAction = new EventEmitter();
  @Output() managerDetail = new EventEmitter();
  @Input() blockCopyPaste = false;
  @Input() currentVMdetail: any;
  @Input() isSplitWindow: boolean;
  @Input() steps = [
    { status: null, state: 'active', description: null, statusLabel:  null },
    { status: null, state: 'pending', description: null, statusLabel:  null },
    { status: null, state: 'pending', description: null, statusLabel:  null },
  ];
  isSwitchVm = false;
  config = { leftTime: 300, format: 'mm:ss' };
  containerConfig = { leftTime: 180, format: 'mm:ss' };
  rdpConfig = { leftTime: 180, format: 'mm:ss' };
  vmUsageInterval = null;
  containerInterval = null;
  rdpInterval = null;
  isRdpInterval = false;
  vmLoad = false;
  @Input() vmErrorMessage: string;
  public rdpDocLink = 'https://learner-docs.cloudlabs.ai/Learner/Troubleshooting/RDP';
  public isReconnectButton = false;
  isResize = false;
  public osInfo: string;

  @HostListener('window:focus', ['$event'])
  onFocus(event: any): void {
    if (this.blockCopyPaste) {
      this.eventSrv.splitWindowCopy.subscribe((res) => {
        if (this.isFileTransferMode && this.manager && !res) {
          this.manager.setEmptyRemoteClipboard('');
        } else if (res) {
          this.manager.setEmptyRemoteClipboard(res);
        }
      });
    }
  }
  /**
   * Copy element on document
   * @param $event
   */
  @HostListener('window:click', ['$event'])
  async handleClick($event) {
    if ($event.target.type === 'text' && this.vmFocus && this.manager) {
      this.manager.setFocused(false);
      return;
    }
    if ($event.target.id === 'pasteInsideVM') {
      ($event.target as HTMLElement).style.pointerEvents = 'none';
      emptySelection();
      const brRegex = /<br\s*[\/]?>/gi;
      const copyData =
        $event.target.innerText || $event.target.firstChild.innerText;
      const txtdata = copyData.replace(brRegex, '\r\n').trim();
      if (this.manager) {
        this.manager.sendRemoteClipboardData(txtdata);
      }
      this.pasteInsideVM($event, txtdata);
    } else if (
      $event.target.id === 'copyelement' ||
      ($event.target as HTMLElement).classList.contains('copyelement')
    ) {
      emptySelection();
      const brRegex = /<br\s*[\/]?>/gi;
      const copyData =
        $event.target.innerText || $event.target.firstChild.innerText;
      const txtdata = copyData.replace(brRegex, '\r\n').trim();
      if (this.manager) {
        this.manager.sendRemoteClipboardData(txtdata);
      }
      await copyToClipboard(txtdata);
      this.notificationService.success(
        this.translateSrv.instant('MESSAGE_COPIED'),
        null
      );
      (document.activeElement as any).blur();
    } else if (
      $event.target.id === 'elementEnv' ||
      !this.isFileTransferMode ||
      ($event.target as HTMLElement).classList.contains('copyelmnt')
    ) {
      await this.sendClipboardToClient();
    }
    if (this.vmFocus && document.getElementById('vm-display')) {
      document.getElementById('vm-display').focus();
    }
  }

  async pasteInsideVM(event, txtdata) {
    const keycodes = txtdata.split('');
    keycodes?.forEach((element, index, array) => {
      const isLast = index === array.length - 1;
      setTimeout(() => {
        this.manager.getClient().sendKeyEvent(1, element.charCodeAt());
        this.manager.getClient().sendKeyEvent(0, element.charCodeAt());
        if (event && isLast) {
          (event.target as HTMLElement).style.pointerEvents = 'unset';
        }
      }, 500);
    });
  }

  /**
   * Hotlistner on the copy command
   * @param e
   */
  @HostListener('copy', ['$event'])
  async onCopy(e: ClipboardEvent) {
    const successful = document.execCommand('copy');
    if (successful) {
      const texts: any = await navigator.clipboard.readText();
    }
  }

  /**
   * Hotlistner no keydown
   * @param $event
   */
  @HostListener('window:keydown', ['$event'])
  async onkeyPress($event: any) {
    if (($event.ctrlKey || $event.metaKey) && $event.keyCode == 67) {
      const successful = document.execCommand('copy');
      if (successful && navigator.clipboard !== undefined) {
        const text = await navigator.clipboard.readText();
      }
    }
    if ($event.key == 'Enter' && $event.keyCode == 13) {
      if (
        $event.target?.id === 'copyelement' ||
        ($event.target as HTMLElement)?.classList.contains('copyelement')
      ) {
        emptySelection();
        const brRegex = /<br\s*[\/]?>/gi;
        const copyData =
          $event.target.innerText || $event.target.firstChild.innerText;
        const data = copyData.replace(brRegex, '\r\n').trim();
        await copyToClipboard(data);
      }
      if ($event.target?.classList[0] === 'copyelmnt') {
        const text = await navigator.clipboard.readText();
      }
    }
  }

  /**
   * On resize window
   * @param event
   */
  @HostListener('window:resize', ['$event'])
  onResize(event?) {
    this.resizeVM();
  }
  /**
   * On press keyboard events
   * @param $event
   */
  @HostListener('window:keydown', ['$event'])
  @HostListener('window:keyup', ['$event'])
  async onkeyDown($event: KeyboardEvent) {
    if (this.manager && !this.isFileTransferMode && !this.blockCopyPaste) {
      this.sendExternalDataToClient();
    }
    // This solution is for, If focus on vm and when we change the focus using tab key then it affects inside the vm and outside the vm
    if (
      ($event as KeyboardEvent).key === 'Tab' &&
      this.vmFocus &&
      this.manager
    ) {
      $event.preventDefault();
      const skipToMainContent = document.querySelector('.display-div');
      if (skipToMainContent) {
        skipToMainContent.classList.remove('display-div');
        skipToMainContent.classList.add('hide-div');
      }
    }

    // this.idleCounter = 0;
    if ($event.altKey && $event.code === 'Backquote') {
      this.manager.onKeyboardReset.next(true);
      this.manager.setFocused(false);
      this.narratorAlertMessage = false;
      setTimeout(() => {
        this.setRdpLabel();
      });
    }

    const key = $event.which || $event.keyCode; // keyCode detection
    let ctrl = $event.ctrlKey ? $event.ctrlKey : key === 17 ? true : false; // ctrl detection
    if (!ctrl) ctrl = $event.metaKey; // command key for mac

    if (key === 86 && ctrl) {
      // Ctrl + V Pressed
    } else if (key === 67 && ctrl) {
      // Ctrl + C Pressed
      document ? document.execCommand('copy') : false;
    }
  }
  constructor(
    private httpClient: HttpClient,
    private readonly userSessionService: UserSessionService,
    private eventEmitter: EventEmitterService,
    private notificationService: NotificationService,
    private translateSrv: TranslateService,
    private labService: LabService,
    private modalService: BsModalService,
    private eventSrv: EventService,
    private router: Router,
    private cdr: ChangeDetectorRef,
    public btnLoadingService: ButtonLoadingService,
    @Inject('environment')
    environment
  ) {
    this.setRdpLabel();
    if (router.url.includes('my-learning')) {
      this.isMylearning = true;
    }
    this.environment = environment;
    this.subscribeVmDetailsChange();
    this.eventSrv.rdpDialogStatus
      .pipe(takeUntil(this.notifier))
      .subscribe((status) => {
        if (status === RDPDialogStatus.CONFIRM_CLOSED) {
          this.narratorAlertMessage = true;
          this.openNarratorAndFocusVM();
        }
        if (status === RDPDialogStatus.CLOSED) {
          this.handleFocusNoButton();
        }
      });
    this.onscroll();
    eventSrv.pasteInsideVm.subscribe((res) => {
      this.pasteInsideVM('', res);
    });
    eventSrv.copyEvent.subscribe((res) => {
      this.copyToClient();
    });
    eventSrv.copyMode.subscribe((res) => {
      this.isFileTransferMode = res;
    });
    eventSrv.splitWindowCopy.subscribe((res) => {
      if (res && this.manager) {
        this.manager.sendRemoteClipboardData(res);
      }
    });
    this.labService.vmOperation.subscribe(res => {
      clearInterval(this.rdpInterval);
      this.rdpInterval = null;
    });
    this.detectOS();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.blockCopyPaste && this.blockCopyPaste) {
      this.isFileTransferMode = true;
    }
  }

  onscroll() {
    document.addEventListener(
      'scroll',
      (e) => {
        const vmConnected = this.manager ? this.manager.isConnected() : '';
        if (
          this.vmFocus &&
          vmConnected &&
          document.getElementById('vm-display')
        ) {
          document.getElementById('vm-display').focus();
        }
      },
      true
    );
  }

  setRdpLabel() {
    if(this.osInfo === 'Mac OS') {
      this.hidingRdpAriaLabel = this.translateSrv.instant('VM_FOCUS_MESSAGE', {key1: 'Option', key2: '\\`'});
    } else {
      this.hidingRdpAriaLabel = this.translateSrv.instant('VM_FOCUS_MESSAGE', {key1: 'Alt', key2: '`'});
    }
  }

  private detectOS():void {
    const userAgent = window.navigator.userAgent;
    if (/Windows/.test(userAgent)) {
      this.osInfo = 'Windows';
    } else if (/Macintosh/.test(userAgent)) {
      this.osInfo = 'Mac OS';
    } else if (/Android/.test(userAgent)) {
      this.osInfo = 'Android';
    } else if (/Linux/.test(userAgent)) {
      this.osInfo = 'Linux';
    } else if (/iOS/.test(userAgent)) {
      this.osInfo = 'iOS';
    } else {
      this.osInfo = 'Unknown';
    }
  }

  public subscribeVmDetailsChange(): void {
    this.vmServiceSubscription && this.vmServiceSubscription.unsubscribe();
    if (window.location.href.includes('practice-tests')) {
      // For PT changes
      this.vmServiceSubscription = this.userSessionService.vmDetails$
        // .pipe(takeUntil(this.notifier), throttleTime(2000))
        .pipe(takeUntil(this.notifier))
        .subscribe(
          (data) => {
            if (data !== undefined && data !== null) {
              this.guacamoleServerUrl = data.guacamoleServerUrl;
              this.rdpUri = data.rdpUri;
              this.getAuthToken();
            }
          },
          (error) => {
            const code = 'Error - 10012';
            this.eventEmitter.debugAlert(code, error);
          }
        );
    } else {
      // for labs
      this.vmServiceSubscription = this.userSessionService.vmDetails$
        .pipe(takeUntil(this.notifier), throttleTime(2000))
        // .pipe(takeUntil(this.notifier))
        .subscribe(
          (data) => {
            if (data !== undefined && data !== null) {
              this.guacamoleServerUrl = data.guacamoleServerUrl;
              this.rdpUri = data.rdpUri;
              if(data.isSwitchVm && this.manager && this.manager.getClient()) {
                // this.loader = true;
                this.isSwitchVm = true;
                // this.manager.getClient().disconnect();
              }
              this.getAuthToken();
              this.setRDPInterval();
            }
          },
          (error) => {
            const code = 'Error - 10012';
            this.eventEmitter.debugAlert(code, error);
          }
        );
    }
  }

  async resizeVM() {
    // this.steps[2].status = null;
    // this.updateStepsData(2, 'active');
    this.isResize = true;
    const state = await this.manager?.isConnected();
    if (state === true) {
      await this.manager.getClient().disconnect();
      const state2 = await this.manager?.isConnected();
      if (state2 === false) {
        await this.handleConnect();
      } else {
        // this.updateStepsData(2, 'active', 'failed');
      }
      this.isResize = false;
    } else {
      // this.updateStepsData(2, 'active', 'failed');
      this.isResize = false;
    }
  }

  /**
   * creating guacaomole remote remote desktop connection
   */
  initializeGuacamoleSession() {
    this.resizeSubsciption && this.resizeSubsciption.unsubscribe();
    unsubscribeCollection(this.managerSubscriptions);
    this.managerSubscriptions = [];
    const tunnelUrl = this.guacamoleServerUrl.replace('https://', '');
    const webSocketTunnel = new WebSocketTunnel(
      'wss://' + tunnelUrl + '/websocket-tunnel'
    );
    webSocketTunnel.receiveTimeout = 300000; // set limit to 5 min
    this.webSocketTunnels = webSocketTunnel;
    this.manager = new RemoteDesktopManager(webSocketTunnel);
    this.eventEmitter.logEventInAppInsight(
      AppInsightEvents.RDP_OVER_HTTPS_SUCCESS,
      {
        [PropertyName.ENTITY_ID]: this.lab.UniqueName,
        [PropertyName.USER_ID]: this.vCode,
        [PropertyName.URL]: window.location.href,
      }
    );
    this.handleConnect();
    this.managerSubscriptions[this.managerSubscriptions.length] =
      this.manager.onReconnect
        // .pipe(takeUntil(this.notifier))
        .subscribe((reconnect) => this.handleConnect());
    this.managerSubscriptions[this.managerSubscriptions.length] =
      this.manager.onStateChange
        .pipe(
          distinctUntilChanged(),
          debounceTime(1000),
          takeUntil(this.notifier)
        )
        .subscribe(
          (state) => {
            this.labService.reloadVMs.next();
            this.reloadVMs.next();
          },
          (error) => {
            const code = 'Error - 10013';
            this.eventEmitter.debugAlert(code, error.error);
            this.eventEmitter.logEventInAppInsight(
              AppInsightEvents.RDP_OVER_HTTPS_FAILED,
              {
                [PropertyName.ENTITY_ID]: this.lab.UniqueName,
                [PropertyName.USER_ID]: this.vCode,
                [PropertyName.ERROR_DATA]: error,
                [PropertyName.URL]: window.location.href,
                [PropertyName.OPERATION]:RDPOperation.GUACAMOLE_SESSION_FAILURE
              }
            );
          }
        );
    // manage clipboard of VM
    this.managerSubscriptions[this.managerSubscriptions.length] =
    this.manager.onRemoteClipboardData.pipe(takeUntil(this.notifier)).subscribe(
      async (res: any) => {
        if (res) {
          try {
            if (!this.blockCopyPaste) {
              await copyToClipboard(res);
            }
          } catch (err) {
            const code = 'Error - 10014';
            this.eventEmitter.debugAlert(code, err);
          }
        }
      },
      (error) => {
        const code = 'Error - 10015';
        this.eventEmitter.debugAlert(code, error);
      }
    );
    this.managerDetail.emit(this.manager);

    this.managerSubscriptions[this.managerSubscriptions.length] =
      this.manager.onFocused.pipe(takeUntil(this.notifier)).subscribe(
        async (res: any) => {
          this.vmFocus = res;
          if (this.vmFocus) {
            emptySelection();
            // document ? document.execCommand('copy') : false;
          }
        },
        (error) => {
          const code = 'Error - 10016';
          this.eventEmitter.debugAlert(code, error);
        }
      );
    // });
    this.resizeSubsciption = this.labService.resizeEvent
      .pipe(takeUntil(this.notifier))
      .subscribe((res) => {
        if (res) {
          this.resizeVM();
        }
      });
  }

  setRDPInterval() {
    if(!this.rdpInterval) {
      this.isRdpInterval = true;
      this.rdpInterval = setInterval(() => {
        this.getAuthToken();
      }, 15000);
      // setTimeout(() => {
      //   this.isRdpInterval = false;
      //   clearInterval(this.rdpInterval);
      //   this.rdpInterval = null;
      // }, 60000 * 2);
    }
  }

  /**
   * Get authtoken
   */

   async getAuthToken() {
    this.btnLoadingService.indexValue = 'reconnect';
      this.btnLoadingService.setLoding(
        true,
        this.translateSrv.instant(`LOADING`)
      );
    this.tokenSubscription && this.tokenSubscription.unsubscribe();
    const url = this.guacamoleServerUrl + '/api/tokens';
    const body = new URLSearchParams();
    body.set('username', 'USERNAME');
    body.set('password', 'PASSWORD');
    if (!this.manager || (this.manager && !this.manager.isConnected())) {
      /* API changes here */
      this.tokenSubscription = this.httpClient
        .post<any>(`${url}`, body.toString(), { headers: this.headers })
        // .pipe(timeout(60000), retry(0))
        .pipe(takeUntil(this.notifier), debounceTime(500))
        .subscribe(
          (data) => {
            if (data && data !== undefined) {
              this.createQuickConnectSession(data.authToken);
              this.token = data.authToken;
            }
          },
          async (error) => {
            if(!this.isRdpInterval) {
              this.updateStepsData(2, null, 'failed');
            } else {
              this.updateStepsData(2, null, 'timer', 'LOCAL_CONNECTION_ESTABLISHED');
            }
            if(this.isReconnectButton) {
              this.stepAction.emit();
              this.isReconnectButton = false;
            }
            this.btnLoadingService.buttonLodingOff();
            // this.checkVmStatus.emit();
            const code = 'Error - 10017';
            this.eventEmitter.debugAlert(code, error);
            this.labService.vmStateUpdate.next(false);
            this.labService.reloadVMs.next();
            this.eventEmitter.logEventInAppInsight(
              AppInsightEvents.RDP_OVER_HTTPS_FAILED,
              {
                [PropertyName.ENTITY_ID]: this.lab.UniqueName,
                [PropertyName.ERROR_DATA]: error,
                [PropertyName.USER_ID]: this.vCode,
                [PropertyName.URL]: window.location.href,
                [PropertyName.OPERATION]:RDPOperation.TOKEN_FETCH_FAILURE
              }
            );
          }
        );
    }
  }

  /**
   *
   * @param authToken Create connection
   */
  createQuickConnectSession(authToken) {
    const url =
      this.guacamoleServerUrl +
      '/api/session/ext/quickconnect/create?token=' +
      authToken;
    const body = new URLSearchParams();
    body.set('uri', this.rdpUri);
    // this.labService
    /* API changes here */
    this.vmConnectionService && this.vmConnectionService.unsubscribe();
    this.vmConnectionService = this.httpClient
      .post<any>(`${url}`, body.toString(), { headers: this.headers })
      .subscribe(
        (res) => {
          if (res.identifier == '0') {
            this.manager = undefined;
            this.initializeGuacamoleSession();
            this.btnLoadingService.buttonLodingOff();
            this.steps[2].status = null;
            clearInterval(this.rdpInterval);
            this.rdpInterval = null;
          }
        },
        (error) => {
          if(!this.isRdpInterval) {
            this.updateStepsData(2, null, 'failed');
          }
          this.btnLoadingService.buttonLodingOff();
          const code = 'Error - 10018';
          this.eventEmitter.debugAlert(code, error);
          this.eventEmitter.logEventInAppInsight(
            AppInsightEvents.RDP_OVER_HTTPS_FAILED,
            {
              [PropertyName.ENTITY_ID]: this.lab.UniqueName,
              [PropertyName.ERROR_DATA]: error,
              [PropertyName.USER_ID]: this.vCode,
              [PropertyName.URL]: window.location.href,
              [PropertyName.OPERATION]:RDPOperation.QUICK_CONNECT_SESSION_FAILURE
            }
          );
        }
      );
  }

  /**
   * Connection remotly
   */
  handleConnect() {
    this.updateStepsData(2, 'completed', 'running');
    this.loader = true;
    setTimeout(() => {
      const state = this.manager.isConnected();
      if (state === false) {
        const elmnt = document.getElementById('iframeBlock');
        if (elmnt) {
          this.manager.connect({
            hostname: 'labvmqizpwuo2epd22.eastus.cloudapp.azure.com',
            port: 3389,
            GUAC_IMAGE: ['image/png', 'image/jpeg', 'image/webp'],
            GUAC_AUDIO: ['audio/L16'],
            GUAC_DPI: 96,
            GUAC_WIDTH: elmnt.offsetWidth,
            GUAC_HEIGHT: elmnt.offsetHeight,
            token: this.token,
            GUAC_DATA_SOURCE: 'quickconnect',
            GUAC_ID: 0,
            GUAC_TYPE: 'c',
          });
        }
      }
      this.manager.setFocused(false);
      this.loader = false;
      this.vmLoad = true;
      this.cdr.detectChanges();
    }, 1500);
    this.cdr.detectChanges();
  }

  changeVMState(state) {
    if([this.remoteDesktopStates.DISCONNECTED, this.remoteDesktopStates.CLIENT_ERROR,
      this.remoteDesktopStates.TUNNEL_ERROR].includes(state) && !this.isSwitchVm) {
        if(!this.isResize ) {
          this.manager = undefined;
          this.eventEmitter.logEventInAppInsight(
            AppInsightEvents.RDP_OVER_HTTPS_FAILED,
            {
              [PropertyName.ENTITY_ID]: this.lab.UniqueName,
              [PropertyName.USER_ID]: this.vCode,
              [PropertyName.ERROR_DATA]: this.remoteDesktopStates,
              [PropertyName.URL]: window.location.href,
              [PropertyName.OPERATION]:RDPOperation.VM_DISCONNECTED
            }
          );
          this.vmLoad = false;
          if(this.steps[1].state === 'completed') {
            this.updateStepsData(2, 'active', 'failed');
          }
          // this.stepAction.emit();
        }
      }
  }

  startVM() {
    this.btnLoadingService.indexValue = 'start';
      this.btnLoadingService.setLoding(
        true,
        this.translateSrv.instant(`LOADING`)
      );
    this.labService.virtualMachineOperations(
      7,
      this.cloudPlateFormId,
      this.currentVMdetail.SubscriptionId,
      this.currentVMdetail.ResourceGroupName,
      this.currentVMdetail.ResourceName,
      this.currentVMdetail.vCode
    ).subscribe(res => {
      if (res && res.IsSuccess === true) {
        this.updateStepsData(0, null, 'timer', 'START_VM_DESCRIPTION');
        this.vmUsageInterval = setInterval(() => {
          this.getVMStatus();
        }, 60000);
        this.btnLoadingService.buttonLodingOff();
        this.eventEmitter.logEventInAppInsight(
          AppInsightEvents.VIRTUAL_MACHINE_START,
          {
            [PropertyName.ENTITY_ID]: this.lab.UniqueName,
            [PropertyName.USER_ID]: this.vCode,
            [PropertyName.URL]: window.location.href,
          }
        );
      } else {
        this.updateStepsData(0, null, 'stop', 'VM_NOT_RUNNING_DESCRIPTION');
        this.notificationService.error(res.ErrorMessage, null)
      }
    },
    (err) => {
      this.updateStepsData(0, null, 'failed', null, 'FAILED_LABEL');
      this.vmErrorMessage = 'UNABLE_TO_START_VM';
      const code = 'Error - 50080';
      this.eventEmitter.debugAlert(code, err.error);
      this.btnLoadingService.buttonLodingOff();
      this.eventEmitter.logEventInAppInsight(
        AppInsightEvents.VIRTUAL_MACHINE_FAILED,
        {
          [PropertyName.ENTITY_ID]: this.lab.UniqueName,
          [PropertyName.USER_ID]: this.vCode,
          [PropertyName.URL]: window.location.href,
          [PropertyName.ERROR_DATA]: err,
        }
      );
    });
  }

  getVMStatus() {
    this.labService.GetVirtualMachine(this.currentVMdetail.vCode).subscribe((res: any) => {
      if(res && res.length > 0) {
        const vm = res.filter((item) => 
          this.currentVMdetail.VMName === item.ResourceName &&
          this.currentVMdetail.ResourceGroupName === item.ResourceGroupName &&
          this.currentVMdetail.SubscriptionId === item.SubscriptionId 
          // && this.currentVMdetail.VMDNSName === item.DNSName
        );
        if(vm[0].Status.toLowerCase() === 'vm running' || vm[0].Status.toLowerCase() === 'running') {
          clearInterval(this.vmUsageInterval);
          this.updateStepsData(0, 'completed', 'running', null);
          this.stepAction.emit('container');
        } else if(['vm deallocated', 'stopped', 'terminated'].includes(vm[0].Status.toLowerCase())) {
          this.updateStepsData(0, null, 'stop', 'VM_NOT_RUNNING_DESCRIPTION');
        }
      }
    });
  }

  startContainer() {
    this.btnLoadingService.indexValue = 'container';
    this.btnLoadingService.setLoding(
      true,
      this.translateSrv.instant(`LOADING`)
    );
    this.labService.containerOperation(this.currentVMdetail.vCode, ContainerAction.Start, this.currentVMdetail.InternalId).subscribe(res => {
      this.updateStepsData(1, null, 'timer', 'START_CONTAINER_DESCRIPTION');
      this.containerInterval = setInterval(() => {
        this.loadContainerStatus();
      }, 60000);
      this.btnLoadingService.buttonLodingOff();
      this.eventEmitter.logEventInAppInsight(
        AppInsightEvents.CONTAINER_START,
        {
          [PropertyName.ENTITY_ID]: this.lab.UniqueName,
          [PropertyName.USER_ID]: this.vCode,
          [PropertyName.URL]: window.location.href,
        }
      );      
    }, (err) => {
      this.updateStepsData(1, null, 'failed', null, 'FAILED_LABEL');
      const code = 'Error - 50081';
      this.eventEmitter.debugAlert(code, err.error);
      this.btnLoadingService.buttonLodingOff();
      this.eventEmitter.logEventInAppInsight(
        AppInsightEvents.CONTAINER_FAILED,
        {
          [PropertyName.ENTITY_ID]: this.lab.UniqueName,
          [PropertyName.USER_ID]: this.vCode,
          [PropertyName.URL]: window.location.href,
          [PropertyName.ERROR_DATA]: err,
        }
      );
    });
  }

  loadContainerStatus() {
    this.labService.containerStatus(this.vCode, this.currentVMdetail.InternalId).subscribe(res => {
      if(res === 'Running') {
        this.updateStepsData(1, 'completed', 'running');
        this.updateStepsData(2, 'active');
        clearInterval(this.containerInterval);
        this.stepAction.emit('remoteConnect');
      }
    },
    (err) => {
      this.updateStepsData(1, null, 'failed', null, 'FAILED_LABEL');
      clearInterval(this.containerInterval);
      const code = 'Error - 50082';
      this.eventEmitter.debugAlert(code, err.error);
    });
  }

  updateStepsData(stepIndex: number, state?: string, status?: string, description?: string, statusLabel?: string) {
    if(state) {
      this.steps[stepIndex].state = state;
    }
    if(status) {
      this.steps[stepIndex].status = status;
    }
    // if(description) {
      this.steps[stepIndex].description = description;
    // }
    this.steps[stepIndex].statusLabel = statusLabel;
    this.cdr.detectChanges();
  }

  handleEvent(event: CountdownEvent) {
    if (event.action === 'done') {
      this.updateStepsData(0, null, 'failed', null, 'FAILED_LABEL');
      this.vmErrorMessage = 'UNABLE_TO_START_VM';
      clearInterval(this.vmUsageInterval);
    }
  }

  handleContainerEvent(event: CountdownEvent) {
    if (event.action === 'done') {
      this.updateStepsData(1, null, 'failed', null, 'FAILED_LABEL');
      clearInterval(this.containerInterval);
    }
  }

  handleRDPEvent(event: CountdownEvent) {
    if (event.action === 'done') {
      this.isRdpInterval = false;
      clearInterval(this.rdpInterval);
      this.rdpInterval = null;
      this.updateStepsData(2, null, 'failed', null);
    }
  }


  openEnvironmentTab() {
    this.eventSrv.changeTab.next();
  }

  copyToClient() {
    const selectedText = window.getSelection().toString();
    if (
      selectedText !== '' &&
      this.manager !== null &&
      this.manager !== undefined
    ) {
      this.manager.sendRemoteClipboardData(selectedText);

      if (navigator.clipboard && navigator.clipboard.writeText) {
        navigator.clipboard.writeText(selectedText);
      }
    }
  }

  async sendExternalDataToClient() {
    try {
      const text = await navigator.clipboard.readText();
      this.manager.onRemoteClipboardData
        .pipe(take(1))
        .subscribe(async (res: any) => {
          if (res && res !== text) {
            this.manager.sendRemoteClipboardData(text);
          }
        });
    } catch (error) {
      console.error('Clipboard operation failed:', error);
    }
  }

  async sendClipboardToClient() {
    let text = '';
    try {
      text = await navigator.clipboard.readText();
    } catch (err) {
      if (!this.vmFocus) {
        this.copyToClient();
      }
    }
    if (this.manager && text != '') {
      this.manager.sendRemoteClipboardData(text);
    }
  }

  openNarratorAndFocusVM() {
    // (this.el.nativeElement as HTMLElement).setAttribute('aria-live','assertive');
    this.manager.setFocused(true);
    this.modelShow = false;
    this.hidingRdpAriaLabel = '';
    sessionStorage.setItem('isNarratorRunning', 'true');
    setTimeout(() => {
      this.openNarratorInVM();
    });
  }

  handleFocusNoButton() {
    sessionStorage.setItem('isNarratorRunning', 'false');
    this.focusInVM();
  }

  focusInVM() {
    this.manager.setFocused(true);
    this.modelShow = false;
  }

  openNarratorInVM() {
    // opens start menu in Remote Desktop
    this.manager.getClient().sendKeyEvent(1, 65507);
    this.manager.getClient().sendKeyEvent(1, 65307);
    this.manager.getClient().sendKeyEvent(0, 65507);
    this.manager.getClient().sendKeyEvent(0, 65307);
    setTimeout(() => {
      if (
        this.browser.name === 'edge' ||
        this.browser.name === 'edge-chromium'
      ) {
        this.manager.getClient().sendKeyEvent(1, 110);
        this.manager.getClient().sendKeyEvent(0, 110);
        this.manager.getClient().sendKeyEvent(1, 118);
        this.manager.getClient().sendKeyEvent(0, 118);
        this.manager.getClient().sendKeyEvent(1, 100);
        this.manager.getClient().sendKeyEvent(0, 100);
        this.manager.getClient().sendKeyEvent(1, 97);
        this.manager.getClient().sendKeyEvent(0, 97);
      } else {
        // types 'narrator' in search box in Remote Desktop
        this.manager.getClient().sendKeyEvent(1, 110);
        this.manager.getClient().sendKeyEvent(0, 110);
        this.manager.getClient().sendKeyEvent(1, 97);
        this.manager.getClient().sendKeyEvent(0, 97);
        this.manager.getClient().sendKeyEvent(1, 114);
        this.manager.getClient().sendKeyEvent(0, 114);
        this.manager.getClient().sendKeyEvent(1, 114);
        this.manager.getClient().sendKeyEvent(0, 114);
        this.manager.getClient().sendKeyEvent(1, 97);
        this.manager.getClient().sendKeyEvent(0, 97);
        this.manager.getClient().sendKeyEvent(1, 116);
        this.manager.getClient().sendKeyEvent(0, 116);
        this.manager.getClient().sendKeyEvent(1, 111);
        this.manager.getClient().sendKeyEvent(0, 111);
        this.manager.getClient().sendKeyEvent(1, 114);
        this.manager.getClient().sendKeyEvent(0, 114);
      }
      setTimeout(() => {
        // imitates 'enter' in Remote Desktop
        this.manager.getClient().sendKeyEvent(1, 65293);
        this.manager.getClient().sendKeyEvent(0, 65293);
      }, 4000);
    }, 4000);
  }

  changeFocusToVM() {
    // this.focusInVM();
    const isFocused = this.manager.getIsFocused();
    if (!isFocused) {
      const isNarratorRunning = sessionStorage.getItem('isNarratorRunning');
      if (isNarratorRunning === 'true' || isNarratorRunning === 'false') {
        this.focusInVM();
      } else {
        //this.modelShow = true;
        if (
          this.environment.partnerNameNarratorDialog ===
            this.lab?.PartnerName &&
          this.environment.appType !== 'exam'
        ) {
          this.modalRef = this.modalService.show(RdpDialogComponent, {
            initialState: {
              title: this.translateSrv.instant('OPEN_NARRATOR'),
              data: {
                description: this.translateSrv.instant('NARRATOR_DIALOG'),
                failureButtonText: this.translateSrv.instant('No'),
                successButtonText: this.translateSrv.instant('Yes'),
              },
            },
          });
        } else {
          this.focusInVM();
        }
      }
    }
  }
  ngOnDestroy(): void {
    this.notifier.next();
    this.notifier.complete();
    this.resizeSubsciption && this.resizeSubsciption.unsubscribe();
    this.vmServiceSubscription && this.vmServiceSubscription.unsubscribe();
    this.vmConnectionService && this.vmConnectionService.unsubscribe();
    unsubscribeCollection(this.managerSubscriptions);
    clearInterval(this.containerInterval);
    clearInterval(this.rdpInterval);
  }
}
