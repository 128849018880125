<!--Start Credentials & Instructions Section-->
<div class="performance-quiz">
    <div class="mt-3 ml-3">

        <cloudlabs-menu [menuList]="menuList" [selectedMenu]="selectedMenu" (selectedValueEmit)="onMenuSelect($event)">
        </cloudlabs-menu>

    </div>
    <div class="cip-right-box width-100" [class.d-none]="!showCredentialsInformation">
        <cloudlabs-credential [sectionType]="sectionType" (credGenerated)="onCredGenerated($event)"></cloudlabs-credential>
    </div>
</div>




<!--End Credentials & Instructions Section-->