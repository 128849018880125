import { Component, HostListener, Input } from '@angular/core';

@Component({
  selector: 'cloudlabs-skip-to-main-content',
  templateUrl: './skip-to-main-content.component.html',
  styleUrls: ['./skip-to-main-content.component.scss'],
})
export class SkipToMainContentComponent {
  @Input() mainDiv: string;
  display = false;
  isFirstClick = true;
  showSkipLink = true;
  isCliked = false;

  constructor() {
    //
  }

  /**
   * On press keyboard events
   * @param $event
   */
  @HostListener('window:keydown', ['$event'])
  @HostListener('window:keyup', ['$event'])
  async onkeyDown($event: any) {
    const firstElement = document.querySelector('body :first-child');
    if (($event as any).key === 'Tab' && this.isFirstClick && !this.isCliked) {
      this.display = true;
      this.isFirstClick = false;
      setTimeout(() => {
        document.getElementById('skip-content').focus();
      }, 100);
    } else if ($event?.shiftKey && $event?.key === 'Tab') {
      if (document.activeElement === firstElement) {
        // Check if the first element is focused
        this.display = true;
      }
    } else {
      this.display = false;
    }
    const firstFocusableElement = document.querySelector('[tabindex="1"]');

    if (document.activeElement === firstFocusableElement) {
      this.display = true;
    }
  }

  @HostListener('focusout', ['$event.target.value'])
  onBlur(value) {
    // ...
    this.display = false;
  }

  @HostListener('document:click', ['$event'])
  DocumentClick(event: Event) {
    this.isCliked = true;
  }

  focusFormDiv(): void {
    let element = document?.getElementById(this.mainDiv);
    if (!element) {
      element = document?.getElementById('main-landmark0');
    }
    element.focus();
    this.display = false;
  }
}
