<div [class]="outerDivClass">
    <button [ngStyle]="{width: cssWidth + '%'}" class="btn select ts-dropdown-fluent-toggle {{classList}}" type="button" data-toggle="dropdown" data-hover="dropdown" aria-haspopup="true" aria-expanded="false">
    <div class="pl-0 float-left">
      <span class="language-text-pos-1">{{selectedValue}}</span>
    </div>
  </button>
    <ul class="dropdown-menu mt-1">
        <li [class]="listItemClass" (click)="dropdownSelection(option.value, option.label)" *ngFor="let option of optionArray">
            {{option.label}}
        </li>
    </ul>
</div>