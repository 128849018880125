<ng-container *ngIf="showFeedback">
    <app-dialoge-prompt appTrapFocus appAutoFocusModal appRefocusPreviousEl [dialogClass]="dialogClass" [isDialog]="showFeedback" class="resultpopup" [style]="{width: deviceDetectorService.isMobile() ? '100vw': '50vw'}"[closable]="false">
        <ng-container header>
            <h2 class="mb-3">{{ 'common.popup.feedback' | translate }}</h2>
        </ng-container>
        <ng-container content>
            <form [formGroup]="feedbackForm">
                <p class="mb-3">{{'message.feedbackContain' | translate}}</p>
                <div class="row mt-3 mb-3 form-group resultpopup">
                    <div class="col-md-12 exam-rating mb-2 ms-input-label required">{{'message.feedbackExperienceString' | translate}}
                    </div>
                    <fieldset class="col-md-12 rating" [ngClass]="{ 'text-danger': submitted && formControls.experienceRating.errors }">
                      <legend class="sr-only">PT Experience</legend>
                      <teams-star-rating [classList]="'modal-input'" formControlName="experienceRating" class="rating" [ariaLabel]="'message.feedbackExperienceString' | translate"
                        [totalStars]="5" [ngClass]="{ 'text-danger': submitted && formControls.experienceRating.errors }" [ariaDescribedBy]="'rating-required'"></teams-star-rating>
                    </fieldset>
                    <div *ngIf="submitted && formControls.experienceRating.errors" class="text-danger col-md-12 mb-3 float-left">
                        <div id="rating-required" *ngIf="formControls.experienceRating.errors.required">{{'message.rating' | translate}}</div>
                    </div>
                </div>
                <div class="row mt-3 mb-3 form-group resultpopup">
                    <div class="col-md-12 exam-rating mb-2 ms-input-label required">
                        {{'message.feedbackQualityString' | translate}}
                    </div>
                    <fieldset class="col-md-12">
                      <legend class="sr-only">Question Quality Experience</legend>
                        <teams-star-rating formControlName="questionsQualityRating" class="rating" [ariaDescribedBy]="'quality-required'"
                         [ariaLabel]="'message.feedbackQualityString' | translate"
                        [totalStars]="5" [ngClass]="{ 'text-danger': submitted && formControls.questionsQualityRating.errors }">
                        </teams-star-rating>
                    </fieldset>
                    <div *ngIf="submitted && formControls.questionsQualityRating.errors" class="text-danger col-md-12 mb-3 float-left">
                        <div id="quality-required" *ngIf="formControls.questionsQualityRating.errors.required">{{'message.rating' | translate}}</div>
                    </div>
                </div>
                <div class="row mt-3 mb-3 form-group resultpopup">
                    <div class="col-md-12 exam-rating mb-2">
                        {{'message.feedbackText' | translate}}
                    </div>
        
                    <div class="col-md-12">
                        <teams-input [ariaLabel]="'Feedback suggestion'"  [type]="'textarea'" formControlName='feedback' [classList]="'ts-textarea-fluent mt-1'">
                        </teams-input>
                    </div>
                </div>
        
                <hr />
            </form>
        </ng-container>
        <ng-container footer>
            <teams-button [classList]="'btn ts-btn-fluent-white modal-close-button ts-btn-fluent button-height-1  ml-2 float-left'" (buttonClick)="cancelFeedback()" [title]="'actions.skip' | translate">
            </teams-button>
            <teams-button [classList]="'btn ts-btn-fluent-primary modal-ok-button ts-btn-fluent button-height-1 ts-btn-width-default ml-4 float-right'" (buttonClick)="sendFeedback()" [title]="'actions.submitFeedback' | translate">
            </teams-button>
        </ng-container>
    </app-dialoge-prompt>
</ng-container>
