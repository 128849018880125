import { ChangeDetectorRef, Component, Input, OnDestroy, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { AppInsightEvents, EventEmitterService, PropertyName, UserSessionService } from '@teams-auth';
import { getLinkTitle, unsubscribeCollection } from '@utility';
import { Subscription } from 'rxjs';

import { CommonService } from '../../services/common-service.service';

@Component({
  selector: 'cloudlabs-comprehensive-report',
  templateUrl: './comprehensive-report.component.html',
  styleUrls: ['./comprehensive-report.component.scss']
})
export class ComprehensiveReportComponent implements OnInit, OnDestroy {

  @Input() examInstanceId;
  @Input() isPdf;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  data: any;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  options: any;
  public moduleResults;
  public productResults;
  public overallPerformance;
  public comprehensiveReport;
  private subscriptions: Subscription[] = [];
  public show = [];
  private activeIndex = null;
  private chartLabels = [];
  public context: string;

  constructor(
    private commonService: CommonService,
    private eventEmitter: EventEmitterService,
    private ref: ChangeDetectorRef,
    private translateService: TranslateService,
    public userSessionService: UserSessionService,
  ) {
    this.subscriptions[this.subscriptions.length] = this.translateService.onLangChange.subscribe(() => {
      this.overallPerformanceChart(this.overallPerformance)
    });
    this.context = (localStorage.getItem('context') === 'PracticeTest') ? 'PracticeTest': 'CloudLabs'
  }

  ngOnInit(): void {
    this.eventEmitter.logEventInAppInsight(
      AppInsightEvents.PRACTICE_TEST_REPORT_SCREEN_VISIT,
      {
        [PropertyName.ENTITY_ID]: this.examInstanceId,
        [PropertyName.URL]: window.location.href,
      }
    );
    this.subscriptions[this.subscriptions.length] = this.commonService
      .getresultStatistics(this.examInstanceId)
      .subscribe((report) => {
        this.comprehensiveReport = report;
        this.moduleResults = report.content.moduleResults;
        this.productResults = report.content.productResults;
        this.overallPerformance = report.content.overallPerformance
        this.overallPerformanceChart(this.overallPerformance);
        this.eventEmitter.logEventInAppInsight(
          AppInsightEvents.PRACTICE_TEST_REPORT_SUCCESS,
          {
            [PropertyName.ENTITY_ID]: this.examInstanceId,
            [PropertyName.URL]: window.location.href,
          }
        );
      }, (error) => {
        const code = 'Error - 10008';
        this.eventEmitter.debugAlert(code, error.error);
        this.eventEmitter.logEventInAppInsight(
          AppInsightEvents.PRACTICE_TEST_REPORT_FAILED,
          {
            [PropertyName.ENTITY_ID]: this.examInstanceId,
            [PropertyName.ERROR_MESSAGE]: error.error,
            [PropertyName.ERROR_DATA]: error,
            [PropertyName.URL]: window.location.href,
          }
        );
      });
      this.ref.detectChanges();
  }

  overallPerformanceChart(content): void {
    this.chartLabels = [
      this.translateService.instant('exam.result.report.chart.correctAnswer'),
      this.translateService.instant('exam.result.report.chart.wrongAnswer'),
      this.translateService.instant('exam.result.report.chart.unanswered')
    ]
    this.data = {
      labels: this.chartLabels,
      datasets: [
        {
          data: [content.correctPercentage, content.wrongPercentage, content.unAnswered],
          backgroundColor: [
            "#7eba0b",
            "#cc4a31",
            "#ebeaea"
          ],
          hoverBackgroundColor: [
            "#7eba0b",
            "#cc4a31",
            "#ebeaea"
          ]
        },
      ]
    };
    this.options = {
      options: {
        plugins: {
          legend: {
            position: 'bottom',
          },
           datalabels: {
              display: true,
              align: 'bottom',
              backgroundColor: '#ccc',
              borderRadius: 3,
              font: {
                 size: 18,
              }
           },
           title: {
            display: false,
            text: 'Chart.js Doughnut Chart'
          }
        }
      }
    }
  }

  getLinkTitle(link: string) {
    return getLinkTitle(link);
  }

  /**
   * @description Toggle a View more/less button
   */

  toggle(key): void {
    this.activeIndex = key;
    this.show[key] = !this.show[key];
    this.ref.detectChanges();
  }

  ngOnDestroy(): void {
    unsubscribeCollection(this.subscriptions);
  }

}
