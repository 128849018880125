import {
  Component,
  EventEmitter,
  forwardRef,
  Input,
  OnInit,
  Output,
} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
  selector: 'teams-single-select-option',
  templateUrl: './single-select-option.component.html',
  styleUrls: ['./single-select-option.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => SingleSelectOptionComponent),
      multi: true,
    },
  ],
})
export class SingleSelectOptionComponent
  implements OnInit, ControlValueAccessor
{
  /* array list of option values */
  @Input() optionArray: any = [];
  @Input() selectedValue: any;
  @Input() selectClass = '';
  @Input() ngSelectClass = '';
  @Input() optionClass = '';
  /* default label, if any */
  @Input() defaultLabel = '';
  /* pass the key values that will be used as the "label" and "value" of the list */
  @Input() valueKey = 'value';
  @Input() labelKey = 'label';
  /* set if needed to enable the filter option for array */
  @Input() filter = false;

  @Output() selectedValueEmit = new EventEmitter();

  constructor() {}

  ngOnInit(): void {
    if (this.optionArray !== '' && this.optionArray) {
      this.optionArray = this.optionArray.map((item) => ({
        value: item[this.valueKey],
        label: item[this.labelKey],
      }));
    }
  }
  
  emitValue(event): void {}

  /**
   * Invoked when the model has been changed
   */
  onChange: (_: any) => void = (_: any) => {};

  /**
   * Invoked when the model has been touched
   */
  onTouched: () => void = () => {};

  /**
   * Method that is invoked on an update of a model.
   */
  updateChanges(value: string) {
    this.onChange(value);
    this.selectedValueEmit.emit(value);
  }

  ///////////////
  // OVERRIDES //
  ///////////////

  /**
   * Writes a new item to the element.
   * @param value the value
   */
  writeValue(value): void {}

  /**
   * Registers a callback function that should be called when the control's value changes in the UI.
   * @param fn
   */
  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  /**
   * Registers a callback function that should be called when the control receives a blur event.
   * @param fn
   */
  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }
}
