import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';

import { UserSessionService } from './_services/user-session.service';
import { EventEmitterService } from './_services/event-emitter.service';
import { AuthService } from './_services/auth.service';
import { AppInsightsService } from './_services/app-insights.service';
import { AuthGuard } from './auth.guard';
import { EntityAuthGuard } from './entity-auth.guard';

@NgModule({
  imports: [CommonModule, TranslateModule],
  providers: [
    UserSessionService,
    EventEmitterService,
    AuthService,
    AppInsightsService,
    AuthGuard,
    EntityAuthGuard
  ],
})
export class TeamsAuthModule {}
