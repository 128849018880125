export enum AppsColorEnum {
  'Blue' = 1,
  'Orange' = 2,
  'Green' = 3,
  'Default' = 4,
  'Yellow' = 5
}
export const appsColorsConst = {
  /* blue theme */
  1: {
    primaryColor: '#0072c9',
    linkColor: '#0072c9',
    primaryHoverColor: '#106ebe',
    primaryLightColor: '#E0F0FE',
    primaryFocusColor: '#004578',
    primaryLightHoverColor: '#3385ce',
    primaryLightFocusColor: '#80baee',
    primaryLightDisableColor: '#B2CE81',
    bodyTextLightColor: '#5E6278',
    whiteColor: '#ffffff',
    backgroundImage: 'assets/images/rightdata_bg_image.jpg',
    bottomBorder: '1px',
    hslValue: 206
  },
  /* Orange theme*/
  2: {
    primaryColor: '#ec7211',
    linkColor: '#ec7211',
    primaryHoverColor: '#ba4802',
    primaryLightColor: '#fbeee6',
    primaryFocusColor: '#aa4101',
    primaryLightHoverColor: '#e1854d ',
    primaryLightFocusColor: '#e59767',
    primaryLightDisableColor: '#EEBB93',
    bodyTextLightColor: '#5E6278',
    whiteColor: '#ffffff',
    backgroundImage: 'assets/images/rightdata_bg_image.jpg',
    bottomBorder: '1px',
    hslValue: 27
  },
  /* green theme */
  3: {
    primaryColor: '#00B336',
    linkColor: '#00B336',
    primaryHoverColor: '#01C73B',
    primaryLightColor: '#04C03D',
    primaryFocusColor: '#02a348',
    primaryLightHoverColor: '#96C83D',
    primaryLightFocusColor: '#94C73A',
    primaryLightDisableColor: '#B2CE81',
    bodyTextLightColor: '#5E6278',
    whiteColor: '#ffffff',
    backgroundImage: 'assets/images/veeam_bg_image.jpg',
    bottomBorder: '1px',
    hslValue: 138
  },
  /* default teams App theme */
  4: {
    primaryColor: '#6264a7',
    linkColor: '#6264a7',
    primaryHoverColor: '#494b83',
    primaryLightColor: '#b2b5ff',
    primaryFocusColor: '#33344a',
    primaryLightHoverColor: '#8486be',
    primaryLightFocusColor: '#595a91',
    bodyTextLightColor: '#616161',
    whiteColor: '#ffffff',
    backgroundImage: 'assets/images/rightdata_bg_image.jpg',
    bottomBorder: '5px',
    hslValue: 238
  },
  /* yellow theme */
  5: {
    primaryColor: '#FFDB2C',
    linkColor: '#F29C11',
    primaryHoverColor: '#edca24',
    primaryLightColor: '#f5da5d',
    primaryFocusColor: '#edc405',
    primaryLightHoverColor: '#c9b249',
    primaryLightFocusColor: '#c9ae34',
    primaryLightDisableColor: '#d1c694',
    bodyTextLightColor: '#c4c0af',
    whiteColor: '#000',
    backgroundImage: 'assets/images/background_image_alien_1.svg',
    bottomBorder: '1px',
    hslValue: 49
  },
};
