<div class="w-100">
    <ul class="singleresult-list singlequestion-list-result">
        <li>
            <div class="ans-question-box case-study-sql-ques">
                <strong class="sql-number">{{index}}. </strong><strong class="sql-ques" [innerHTML]="question?.question | targetBlank"></strong>
            </div>
            <div class="ans-swap-box">
                <ul class="swap-left-list-box">
                    <li *ngFor="let item of options" [ngClass]="{'disableItem':item.disabled }">
                        {{item.value}}
                    </li>
                </ul>
                <ng-container *ngIf="question.userAnswers.length >0">

                    <ul class="swap-box-width">
                        <li *ngFor="let item of question.labels;let i=index;">
                            <strong class="mb-2 mt-2">{{item.value}}</strong>
                            <ul class="swap-right-list-box">
                                <ng-container *ngIf="item.option.length > 0">
                                    <li *ngFor="let itemOption of item.option" [ngClass]="itemOption.answer===1?'sql-option-box-success':itemOption.answer===2?'sql-option-box-failure':''">
                                        {{itemOption.value}}
                                    </li>
                                </ng-container>
                                <ng-container *ngIf="item.option.length===0">
                                    <li></li>
                                </ng-container>
                            </ul>
                        </li>
                    </ul>

                    <ul class="swap-box-width">
                        <li *ngFor="let item of questionLabels;let i=index;">
                            <strong class="mb-2 mt-2">{{item.value}}</strong>
                            <ul class="swap-right-list-box">
                                <ng-container *ngIf="item.options.length > 0">
                                    <li *ngFor="let itemOption of item.options" class="build-list-option">
                                        {{itemOption.value}}
                                    </li>
                                </ng-container>
                                <ng-container *ngIf="item.options.length===0">
                                    <li></li>
                                </ng-container>
                            </ul>
                        </li>
                    </ul>
                </ng-container>
                <ng-container *ngIf="question.userAnswers.length ===0">

                    <ul class="swap-box-width">
                        <li *ngFor="let item of questionLabels;let i=index;">
                            <strong class="mb-2 mt-2">{{item.value}}</strong>
                            <ul class="swap-right-list-box">
                                <ng-container *ngIf="item.attempt.length > 0">
                                    <li *ngFor="let itemOption of item.attempt" class="build-list-option">
                                        {{itemOption.value}}
                                    </li>
                                </ng-container>
                                <ng-container *ngIf="item.attempt.length===0">
                                    <li></li>
                                </ng-container>
                            </ul>
                        </li>
                    </ul>
                </ng-container>
            </div>
        </li>
    </ul>
    {{question.userScore}}/{{question.questionScore}} {{ 'exam.result.points' | translate }}
</div>