import { Component } from '@angular/core';

@Component({
    selector: 'ngx-remote-desktop-connecting-message',
    host: { class: 'ngx-remote-desktop-message'},
    template: `
            <div id="vex-splash-screen">
                <div class="wrapper">
                    <div class="ball-scale-multiple">
                    <div></div><div></div><div></div>
                    </div>
                </div>
            </div>
    `
})
export class ConnectingMessageComponent {
}
