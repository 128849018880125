<div class="view-language-list-box mobile-view-lang col- col-md-12 col-sm-1" [ngClass]="{
    'isSideNav': (!sideNav && context === 'CloudLabs'),
    'pt-header-teams' :(context === 'CloudLabs')
    }" *ngIf="!showConfirmSection && !examResult">
    <div class="app-logo" *ngIf="!userSessionService.isTeamsAppUser && context === 'PracticeTest' && (loadingService.loading$ | async) === false">
        <img class="team-icon" src="../../../../assets/images/cloudlogo.png" alt="logo">
        <h2 class="ts-title-bar position-relative">{{ 'branding.cloudlabs' | translate }}</h2>
    </div>
        <div class="vllb-box margin-right-auto">
            <strong role="heading" aria-level="1">{{ 'exam.section.sectionReview.title' | translate }}</strong>
            <span *ngIf="sectionList.length > 1">{{ sectionIndexinExamInstance + miscellaneousConst?.arrayOneValue }}</span>
        </div>
        <div class="vllb-box" *ngIf="sectionList.length > 1">
            <strong>{{ 'exam.section.sectionReview.header.section' | translate }}:</strong>
            <span>{{ sectionIndexinExamInstance + miscellaneousConst?.arrayOneValue }}/{{ sectionList.length }}</span>
        </div>
    <div class="vllb-box">
        <strong>{{ 'exam.section.sectionReview.header.timeRemaining' | translate }}:</strong>
        <span>{{ changeMinute(timer) }}</span>
    </div>
    <div class="vllb-box">
        <strong>{{ 'common.language' | translate }}:</strong>
        <span>{{ examInstanceDetails?.language }}</span>
    </div>
    <div class="btn-flex">
        <teams-button *ngIf="!userSessionService.isClouldLabsUser" [classList]="'btn ts-btn-fluent-red ts-btn-fluent button-height-1 ts-btn-width-default ml-4 mr-4'" (click)="cancelTest()" [title]="'actions.cancelTest' | translate">
        </teams-button>
        <teams-button [classList]="'btn ts-btn-fluent-green ts-btn-fluent button-height-1 ts-btn-width-default'" (buttonClick)="finishSection()" [title]="'actions.finishSection' | translate " *ngIf="
          sectionList.length -  miscellaneousConst?.arrayOneValue &&
          sectionList.length !== sectionIndexinExamInstance +  miscellaneousConst?.arrayOneValue
        ">
        </teams-button>
        <teams-button [classList]="'btn ts-btn-fluent-green ts-btn-fluent button-height-1 ts-btn-width-default'" (buttonClick)="finishTestDialoge()" [title]="'actions.finishTest' | translate" *ngIf="sectionList.length === sectionIndexinExamInstance +  miscellaneousConst?.arrayOneValue">
        </teams-button>
    </div>
</div>

<div class="test-description-box-section-review " tabindex="-1" id="main-landmark" *ngIf="!showConfirmSection && !examResult">
    <div class="title-time-row">
        <div class="ttr-title-tag-butn-box">
            <h3 *ngIf="testDetails?.title" role="heading" aria-level="2" class="test-title-width">{{ testDetails?.title }}</h3>
            <ul class="tag-list">
                <li class="caption" *ngFor="let t of testDetails?.tags">{{ t }}</li>
            </ul>

        </div>
        <ul class="ttr-time-list">
            <li class="success">
                <strong class="big-heading">
                    <span
                        *ngIf="answered.length < miscellaneousConst?.arrayGreaterValue && answered.length > miscellaneousConst?.answeredArrayMinLength">{{miscellaneousConst?.answeredArrayMinLength}}</span>{{
                    answered.length }}</strong>
                <span>{{ 'exam.section.sectionReview.attempted' | translate }}</span>
            </li>
            <li class="failure">
                <strong class="big-heading">
                    <span
                        *ngIf="skipped.length < miscellaneousConst?.arrayGreaterValue && skipped.length > miscellaneousConst?.answeredArrayMinLength">{{miscellaneousConst?.answeredArrayMinLength}}</span>{{
                    skipped.length }}
                </strong>
                <span>{{ 'exam.section.sectionReview.skipped' | translate }}</span>
            </li>
        </ul>
    </div>
    <!--Table-->
    <table class="table responsive-table" role="table" aria-label="Section review">
        <thead>
          <tr>
            <th scope="col" class="col-sm-1 questionth subheading">#</th>
            <th scope="col" class="col-sm-6 questionth subheading">{{ 'common.questions' | translate }}</th>
            <th scope="col" class="col-sm-2 answeredth subheading text-center" id="badgesth">{{ 'exam.section.sectionReview.attempted' | translate }}</th>
            <th scope="col" class="col-sm-3 actionreviewth subheading text-center" *ngIf="getIsSectionReview()">{{ 'actions.action' | translate }}</th>
          </tr>
        </thead>
        <tbody>
            <tr *ngFor="let que of questions let i = index" class="question-section">
                    <td class="col-sm-1">{{i + 1}}</td>
                    <td class="col-sm-6 mt-3 mb-3" [innerHTML]="que.question"></td>
                    <td class="col-sm-2 mt-2 mb-3">
                        <div class="ms-SectionBar-icon text-center">
                            <i *ngIf="que.userAnswers.length > miscellaneousConst?.answeredArrayMinLength" role="text" aria-label="Answered" class="ms-Icon ms-Icon--Accept" title="{{ 'exam.section.sectionReview.answered' | translate }}"></i>
                            <i *ngIf="que.userAnswers.length === miscellaneousConst?.answeredArrayMinLength" role="text" aria-label="Unanswered" class="ms-Icon ms-Icon--ChromeClose" title="{{ 'exam.section.sectionReview.unanswered' | translate }}"></i>
                        </div>
                    </td>
                    <td class="col-sm-3 mt-3 mb-3 text-center" *ngIf="getIsSectionReview()">
                        <teams-button [ariaLabel]="que.question + 'Review'" [classList]="'btn ts-btn-fluent-primary ts-btn-fluent button-height-1 ts-btn-width-default'" (buttonClick)="navigateToReviewPage(que)" [title]=" 'exam.section.sectionReview.review' | translate">
                        </teams-button>
                    </td>
                    <tr>
            </tr>
        </tbody>
      </table>
    <teams-button [classList]="'btn ts-btn-fluent-green ts-btn-fluent button-height-1 ts-btn-width-default float-right mt-3 mb-3'" (buttonClick)="finishSection()" [title]="'actions.finishSection' | translate " *ngIf="
          sectionList.length -  miscellaneousConst?.arrayOneValue &&
          sectionList.length !== sectionIndexinExamInstance +  miscellaneousConst?.arrayOneValue
        ">
    </teams-button>
    <teams-button [classList]="'btn ts-btn-fluent-green ts-btn-fluent button-height-1 ts-btn-width-default float-right mt-3 mb-3'" (buttonClick)="finishTestDialoge()" [title]="'actions.finishTest' | translate" *ngIf="sectionList.length === sectionIndexinExamInstance +  miscellaneousConst?.arrayOneValue">
    </teams-button>
</div>
<!-- Added "Question-footer" after changes require from Alwin/Murli for section review white BG and fixed footer | Sheikh | Bug -1854-->
<ng-container *ngIf="testDetails?.instruction !== null || getIsSectionInstruction()">
    <div class="Question-footer" [ngClass]="{
        'pt-footer-non-teams' :(cloudlabs === 'CloudLabs' || deviceDetectorService.isMobile()),
    'pt-footer-teams': (cloudlabs === 'CloudLabs' && auth.isTeamsAppUser()),
    'isNotSideNav': (sideNav && cloudlabs === 'CloudLabs' && !deviceDetectorService.isMobile()),
    'isSideNav': (!sideNav && cloudlabs === 'CloudLabs')
    }" *ngIf="!showConfirmSection && !examResult && (loadingService.loading$ | async) === false">
        <teams-button [classList]="'btn ts-btn-fluent-white ts-btn-fluent button-height-1 ts-btn-width-small mt-3 mb-3 footer-help-button'" (buttonClick)='getInstruction()' [title]="'actions.help' | translate" *ngIf="testDetails?.instruction!== null || getIsSectionInstruction()"></teams-button>
    </div>
</ng-container>
<app-help *ngIf="viewInstruction" [leftSectionCount]="sectionIndexinExamInstance + miscellaneousConst?.arrayOneValue" [sectionList]="sectionList" [testDetails]="testDetails" [viewInstruction]="viewInstruction" (viewInstructionEmit)="isInstruction($event)"
    (helpResponse)="closeHelpPopup($event)"></app-help>

    <ng-container *ngIf="showConfirmSection">
        <app-dialoge-prompt appTrapFocus appAutoFocusModal appRefocusPreviousEl [dialogClass]="dialogClass" [isDialog]="showConfirmSection" (popupResponse)="closeTestSectionReviewPopup($event)">
            <div header class="subheading">
                {{'common.popup.completeSection' | translate}}
            </div>
            <p content class="dialog-headingText"> {{ 'message.confimSectionMessage' | translate }}</p>
            <hr aria-hidden="true" footer />
            <teams-button footer [classList]="'btn ts-btn-fluent-white modal-close-button ts-btn-fluent button-height-1 mr-4'" (buttonClick)="showConfirmSection = false" [title]="'actions.back' | translate">
            </teams-button>
            <teams-button footer [classList]="'btn ts-btn-fluent-green modal-ok-button ts-btn-fluent button-height-1'" (buttonClick)="completedSection()" [title]="'actions.finishSection' | translate" *ngIf="
              sectionList.length -  miscellaneousConst?.arrayOneValue &&
              sectionList.length !== sectionIndexinExamInstance +  miscellaneousConst?.arrayOneValue
            ">
            </teams-button>
            <teams-button footer [classList]="'btn ts-btn-fluent-green modal-ok-button ts-btn-fluent button-height-1'" (buttonClick)="finishTest()" [title]="'actions.finishTest' | translate" *ngIf="sectionList.length === sectionIndexinExamInstance +  miscellaneousConst?.arrayOneValue">
            </teams-button>
        </app-dialoge-prompt>
    </ng-container>

<div class="visually-hidden" tabindex="-1" *ngIf="examResult">
    Taking to Thank you for test section
</div>
<ng-container *ngIf="examResult">
    <app-dialoge-prompt appTrapFocus appAutoFocusModal appRefocusPreviousEl [dialogClass]="dialogClass" [isDialog]="examResult" (popupResponse)="closeTestResultResponse($event)" [closable]="false">
        <div header class="subheading" id="dialog-title">
            {{'common.popup.examCompleted' | translate}}
        </div>
        <p content class="dialog-headingText">{{ 'message.getResultMessage' | translate }}</p>
        <hr aria-hidden="true" footer />
        <teams-button footer [classList]="'btn ts-btn-fluent-primary modal-ok-button ts-btn-fluent button-height-1 ts-btn-width-default'" (buttonClick)="getResult()" [title]="'actions.getResult' | translate ">
        </teams-button>
    </app-dialoge-prompt>
</ng-container>
<ng-container *ngIf="finishTestConfimDialoge">
    <app-dialoge-prompt appTrapFocus appAutoFocusModal appRefocusPreviousEl [dialogClass]="dialogClass" [isDialog]="finishTestConfimDialoge" (popupResponse)="closeConfirmResponse($event)">
        <div header class="subheading" id="dialog-title">
            {{'common.popup.confirmation' | translate}}
        </div>
        <p content class="dialog-headingText">{{'message.confirmQuestionMessage' | translate}}</p>
        <hr aria-hidden="true" footer />
        <teams-button footer [classList]="'btn ts-btn ts-btn-fluent ts-btn-fluent-white button-height-1 modal-close-button ts-btn-width-default mr-4'" (buttonClick)="dialogeNo()" [title]="'actions.no' | translate">
        </teams-button>
        <teams-button footer [classList]="'btn ts-btn ts-btn-fluent-primary ts-btn-fluent button-height-1 modal-ok-button ts-btn-width-default mr-4'" (buttonClick)="dialogeYes()" [title]="'actions.yes' | translate">
        </teams-button>
    </app-dialoge-prompt>
</ng-container>
<app-section-question *ngIf="isShow" [sectionId]="sectionId" [questionRes]="questions" (questionListEmit)="getQuestion($event)"></app-section-question>
