<p-dialog [maskStyleClass]="headerClass" header="{{ headerValue | translate }}" [(visible)]="openDialog"
    [style]="{width: deviceDetectorService.isMobile() ? '100vw': '50vw'}" [baseZIndex]="10000" [modal]="true"
    (onHide)="closePopup()" [closable]="closable" appendTo="body">
    <ng-template pTemplate="header">
        <ng-content select="[header]"></ng-content>
    </ng-template>
    <ng-template pTemplate="content">
        <ng-content select="[content]">
        </ng-content>
        <ng-content select=".overlay spinner-height"></ng-content>
    </ng-template>
    <ng-template pTemplate="footer">
        <ng-content select="[footer]"></ng-content>
    </ng-template>
</p-dialog>