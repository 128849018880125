import { Inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { LabCluster, LabClusterEntity } from '../modals/lab-cluster.modal';
import { Observable, Subject, of } from 'rxjs';
import { catchError, map, mergeMap } from 'rxjs/operators';

@Injectable()
export class LabClusterService {
  environment;

  labCluster: LabCluster;
  labClusterEntities: LabClusterEntity[];
  getLabCluster = new Subject<LabCluster>();
  getLabClusterEntities = new Subject<LabClusterEntity[]>();

  constructor(
    private httpClient: HttpClient,
    @Inject('environment')
    environment
  ) {
    this.environment = environment;
  }
  getLabClusterFromServer(uniqueIdentifier: string) {
    this.httpClient
      .get(
        this.environment.APIURL +
          'LabCluster/GetPublicLabCluster?uniqueIdentifier=' +
          uniqueIdentifier
      )
      .subscribe(
        (data) => {
          this.labCluster = data as LabClusterEntity;
          this.getLabCluster.next(this.labCluster);
        },
        (error) => {
          console.log(error);
        }
      );
  }
  getPracticeTest(mockExamId: any, voucher: any) {
    return this.httpClient.get<LabClusterEntity[]>(
      this.environment.APIURL +
        'practicetest/GetPracticeTest?guid=' +
        mockExamId +
        '&inviteLink=' +
        voucher
    );
  }
  getLabClusterEntitiesFromServer(voucherCode: string) {
    return this.httpClient.get<LabClusterEntity[]>(
      this.environment.APIURL +
        'LabCluster/GetBaseLineEventUserStatus?voucher=' +
        voucherCode
    );
  }
  getLabClusterDetails(uniqueIdentifier: string): Observable<LabCluster> {
    return this.httpClient.get<LabCluster>(
      this.environment.APIURL +
        'LabCluster/GetPublicLabCluster?uniqueIdentifier=' +
        uniqueIdentifier
    );
  }

  getRetryLabDetails(voucher: any, uniqueName: any) {
    return this.httpClient.get<LabCluster>(
      this.environment.APIURL +
        'LabCluster/CheckUserEligibleForRetry?labClusterVoucher=' +
        voucher +
        '&eventUniqueName=' +
        uniqueName
    );
  }

  getRetryLab(voucher: any, uniqueName: any) {
    return this.httpClient.get<LabCluster>(
      this.environment.APIURL +
        'LabCluster/RetryRegistration?labClusterVoucher=' +
        voucher +
        '&eventUniqueName=' +
        uniqueName
    );
  }

  private getIpAddress(ipifyUrl: string): Observable<string> {
    return this.httpClient.get(ipifyUrl).pipe(
      map((response) => response['ip']),
      catchError((error) => {
        console.error('Error fetching IP address:', error);
        return of('');
      })
    );
  }

  private getHutkValue(): string {
    const hutkCookieName = 'hubspotutk';
    const cookies = document.cookie.split(';');
    for (const cookie of cookies) {
      const [name, value] = cookie.trim().split('=');
      if (name === hutkCookieName) {
        return value;
      }
    }
    return '';
  }

  /**
   * All the info here is specific to Yellowbrick Hubpot integration
   * We would have to make it dynamic if more partners come with hubspot integration
   */
  public callHubSpotFormApi(formValues: any) {
    const portalId = '8366986';
    const formGuid = 'b6e314d2-2240-4e10-84c4-e1bf65142fed';
    const url = `https://api.hsforms.com/submissions/v3/integration/submit/${portalId}/${formGuid}`;
    const YOUR_TOKEN = 'pat-na1-fc8a435f-f811-491c-a343-2b380c388d15';

    const ipifyUrl = 'https://api.ipify.org?format=json';

    const hutkValue = this.getHutkValue();

    const body = {
      submittedAt: Date.now(),
      fields: [
        {
          name: 'firstname',
          value: formValues.FirstName,
        },
        {
          name: 'lastname',
          value: formValues.LastName,
        },
        {
          name: 'email',
          value: formValues.Email,
        },
        {
          name: 'company',
          value: formValues.Organization,
        },
        {
          name: 'how_did_you_hear_about_us_',
          value: formValues.hearAbout,
        }
      ],
      legalConsentOptions: {
        consent: {
          consentToProcess: true,
          text: 'I agree that Yellowbrick may use my contact information to keep me updated about the trial and other Yellowbrick updates. You can unsubscribe by sending an email request to sales@yellowbrick.com',
          communications: [
            {
              value: true,
              subscriptionTypeId: 999,
              text: 'I agree that Yellowbrick may use my contact information to keep me updated about the trial and other Yellowbrick updates. You can unsubscribe by sending an email request to sales@yellowbrick.com',
            },
          ],
        },
      },
      context: {
        pageUri: 'cloudlabs.yellowbrick.com',
        pageName: 'Sandbox Trial Sign-up',
        sfdcCampaignId: '701Ho000000SBZpIAO', // Add sfdcCampaignId field
        ipAddress: '<client IP Address>',
      },
    };

    return this.getIpAddress(ipifyUrl).pipe(
      map((ipAddressValue) => {
        body.context = {
          ...body.context,
          ipAddress: ipAddressValue,
        };
        if (hutkValue !== '') {
          body.context['hutk'] = hutkValue;
        }
        return body;
      }),
      mergeMap((modifiedBody) => {
        const headers = {
          'Access-Control-Allow-Origin': '*',
          'Content-Type': 'application/json',
          Authorization: `Bearer ${YOUR_TOKEN}`,
        };

        return this.httpClient.post(url, modifiedBody, { headers });
      }),
      catchError((error) => {
        console.error('Error submitting Yellowbrick hubpot form:', error);
        return of(null);
      })
    );
  }
}
