import { Component, Input } from '@angular/core';
import { NotesService } from '../../../../services/notes.service';

@Component({
  selector: 'cloudlabs-note',
  templateUrl: './note.component.html',
  styleUrls: ['./note.component.scss'],
})
export class NoteComponent {
  @Input() note;
  @Input() htmlContent:string = '';

  constructor(private notesService: NotesService) {}

  clearNote() {
    this.notesService.removeNote(this.note.id);
  }
}
