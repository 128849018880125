import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { MainComponent } from './components/main/main.component';
import { LabResolver } from '../../resolver/lab-resolver';
import { AttendeeLabResolver } from '../../resolver/attende-lab.resolver';

const routes: Routes = [
  {
    path: '',
    component: MainComponent,
    resolve: {
      lab: LabResolver,
      labattendee: AttendeeLabResolver,
    },
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class LabsRoutingModule {}
