import { Injectable, Renderer2, RendererFactory2 } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import * as _ from 'lodash';

@Injectable({
  providedIn: 'root'
})
export class ResultHelperService {
  private renderer: Renderer2;
  private rend: Renderer2;

  constructor(
    rendererFactory: RendererFactory2,
    private sanitizer: DomSanitizer) {
    this.renderer = rendererFactory.createRenderer(null, null);
    this.rend = rendererFactory.createRenderer(null, null);
  }

  // build list
  /**
   * @description: Load Data First Time
   */
  public onBuildLoadData(question, correctOptions, options, dropOptions): any {
    if (question !== undefined) {
      options = question.options;
      const questionOptions = question.options.map((i) => ({
        key: i.key,
        value: i.value,
      }));
      question.answers.forEach((element) => {
        element.value = questionOptions.find(
          (i) => i.key === element.key
        ).value;
      });
      correctOptions = question.answers;
      question.options.forEach((element) => {
        element.disabled = false;
        element.answer = 0;
      });
    }
    if (question.userAnswers.length > 0) {
      dropOptions = this.userSelectedBuildListAnswers(dropOptions, question, options).dropOptionsValue;
      question = this.userSelectedBuildListAnswers(dropOptions, question, options).questions;
      options = this.userSelectedBuildListAnswers(dropOptions, question, options).optionsValue;
    }
    return { correctOptionsValue: correctOptions, questions: question, optionsValue: options, dropOptionsValue: dropOptions };
  }
  /**
   * @description: Load User Selected Answers
   */
  private userSelectedBuildListAnswers(dropOptions, question, options): any {
    dropOptions = question.userAnswers;
    const rightAnswer = dropOptions.filter((i, ind) =>
      question.answers.some((o, inds) => i.key === o.key && ind === inds)
    );
    rightAnswer.forEach((element) => {
      element.answer = 1;
    });
    const wrongAnswer = dropOptions.filter(
      (i, ind) =>
        !question.answers.some(
          (o, inds) => i.key === o.key && ind === inds
        )
    );
    wrongAnswer.forEach((element) => {
      element.answer = 0;
    });
    const userAnswer = wrongAnswer.filter((i) =>
      question?.userAnswers.some((o) => i.key === o.key)
    );
    userAnswer.forEach((element) => {
      element.answer = 2;
    });
    options
      .filter((i) => question.userAnswers.some((o) => i.key === o.key))
      .forEach((element) => {
        element.disabled = true;
      });
    return { dropOptionsValue: dropOptions, questions: question, optionsValue: options };
  }

  // build tree
  /**
   * @description: Load User Selected Answers
   */
  public userSelectedBuildTreeAnswers(questionLabels, question, options): any {
    questionLabels.forEach((element) => {
      const labelValue = question.answers.filter(
        (i) => i.key === element.key
      );
      if (labelValue.length > 0) {
        labelValue.forEach((e) => {
          const optionValue = options.find((i) => i.key === e.value);
          element.options.push(optionValue);
        });
      }
    });
    question.options.forEach((element) => {
      element.disabled = false;
      element.answer = 0;
    });
    question.labels.forEach(element => {
      element.option = question.options.filter(i => question.userAnswers.some(o => i.key === o.value && o.key === element.key));
      element.option.filter(i => question.answers.some(o => i.key === o.value)).forEach(ele => {
        ele.answer = 1;
      });
      element.option.filter(i => question.userAnswers.some(o => i.key === o.value && o.isCorrect === false)).forEach(ele => {
        ele.answer = 2;
      });
    });

    question.options
      .filter((i) => question.userAnswers.some((o) => i.key === o.value))
      .forEach((element) => {
        element.disabled = true;
        element.keys = element.key;
      });
  }

  // match option
  /**
   * @description: Load User Selected Answers
   */
  public userSelectedMatchOptionAnswers(question, questionLabels): any {
    question.userAnswers.forEach(e => {
      question.labels.find(
        (i) => i.key === e.key
      ).option = question.options.find(i => i.key === e.value);
      const matchUserAnswer = question.options.find((i) => i.key === e.value);
      matchUserAnswer.disabled = true;
    });
    question.labels.forEach(element => {
      if (element.option !== null) {
        const correct = question.answers.filter(i => question.userAnswers.some
          (o => i.key === element.key && o.key === element.key && i.value === o.value));
        if (correct.length > 0) {
          element.answer = 1;
        } else {
          element.answer = 2;
        }
      }
    });
    questionLabels = question.labels.map((i) => ({
      key: i.key,
      value: i.value,
    }));
    question.answers.forEach((element) => {
      const correctAnswer = questionLabels.find(
        (i) => i.key === element.key
      );
      correctAnswer.option = question.options.find(
        (i) => i.key === element.value
      );
    });
    return { questionLabelsValue: questionLabels };
  }
  /**
   * @description: Load Answers
   */
  public loadMatchOperationAnswers(questionLabels, question): any {
    questionLabels = question.labels.map((i) => ({
      key: i.key,
      value: i.value,
    }));
    question.answers.forEach((element) => {
      const correctAnswer = questionLabels.find(
        (i) => i.key === element.key
      );
      correctAnswer.option = question.options.find(
        (i) => i.key === element.value
      );
      correctAnswer.attempt = question.options.find(
        (i) => i.key === element.value
      );
    });
    return { questionLabelValue: questionLabels };
  }
  // single choice
  /**
   * @description: Load User Selected Answers
   */
  public loadUserSelectedSingleChoiceAnswers(question): any {
    question.options.forEach((element) => {
      const ans = question.userAnswers.find(
        (i) => i.key === element.key
      );
      element.checked = ans ? true : false;
    });

    const rightAnswer = question?.options.filter((i) =>
      question.answers.some((o) => i.key === o.key)
    );
    rightAnswer.forEach((element) => {
      element.answer = 1;
    });
    const wrongAnswer = question?.options.filter(
      (i) => !question.answers.some((o) => i.key === o.key)
    );
    wrongAnswer.forEach((element) => {
      element.answer = 0;
    });
    const userAnswer = wrongAnswer.filter((i) =>
      question?.userAnswers.some((o) => i.key === o.key)
    );
    userAnswer.forEach((element) => {
      element.answer = 2;
    });
  }

  // multiple choice
  /**
   * @description: Load User Selected Answers
   */
  public userSelectedMultipleChoiceAnswers(question): any {
    const rightAnswer = question?.options.filter((i) =>
      question.answers.some((o) => i.key === o.key)
    );
    rightAnswer.forEach((element) => {
      element.answer = 1;
    });
    const wrongAnswer = question?.options.filter(
      (i) => !question.answers.some((o) => i.key === o.key)
    );
    wrongAnswer.forEach((element) => {
      element.answer = 0;
    });
    const userAnswer = wrongAnswer.filter((i) =>
      question?.userAnswers.some((o) => i.key === o.key)
    );
    userAnswer.forEach((element) => {
      element.answer = 2;
    });
    const selectedAnswer = question.options.filter((i) =>
      question.userAnswers.some((o) => i.key === o.key)
    );
    selectedAnswer.forEach((element) => {
      element.checked = true;
    });
  }

  // multi single choice
  /**
   * @description: Load Data First Time
   */
  public onMultiSingleChoiceLoadData(question): any {
    if (question.userAnswers.length > 0) {
      const newLables = [];
      const labels = JSON.parse(JSON.stringify(question?.labels));
      labels.forEach((element) => {
        let options = JSON.parse(JSON.stringify(question?.options));
        options.forEach(e => {
          const checked = question?.userAnswers.find(i => e.key === i.value && element.key === i.key) ? true : false;
          e.checked = checked;
          const answersCorrect = question?.answers.find(i => e.key === i.value && element.key === i.key) ? true : false;
          e.correct = answersCorrect;
        });
        element.options = options;
        newLables.push(newLables);
        options = new Object([]);
      });
      question.labels = labels;
      return { questions: question };
    }
    if (question.userAnswers.length === 0) {
      const newLables = [];
      const labels = JSON.parse(JSON.stringify(question?.labels));
      labels.forEach((element) => {
        let options = JSON.parse(JSON.stringify(question?.options));
        options.forEach(e => {
          const notAttempt = question?.answers.find(i => e.key === i.value && element.key === i.key) ? true : false;
          e.notAttempt = notAttempt;
        });
        element.options = options;
        newLables.push(newLables);
        options = new Object([]);
      });
      question.labels = labels;
      return { questions: question };
    }
  }
  // dropdown
  /**
   * @description: Drop Down Load Data
   */
  public onDropDownLoadData(question, index, optionList, correctDropDownList, dropdownStrList1, dropdownStrList): any {
    if (question !== undefined) {
      dropdownStrList = this.userSelectedDropDown(question, index, dropdownStrList).dropdownUserList;
      dropdownStrList1 = this.rightAnswerDropDown
        (question, optionList, correctDropDownList, index, dropdownStrList1).dropdownRightAnswerList;
      return { dropdownStrListValue: dropdownStrList, dropdownStrList1Value: dropdownStrList1 };
    }
  }
  /**
   * @description: User Selected Drop Down Value
   */
  private userSelectedDropDown(question, index, dropdownStrList): any {
    const DropDowns = question.options.reduce((ind, jnd) => {
      ind[jnd.key] = [...(ind[jnd.key] || []), jnd];
      return ind;
    }, {});
    const result = Object.keys(DropDowns).map((key) => {
      return [key, DropDowns[key]];
    });
    _.each(result, (sub, i) => {
      if (!_.isUndefined(sub)) {
        const p = this.sanitizer.bypassSecurityTrustHtml(
          this.createDropDown(result[i], index)
        );
        // tslint:disable-next-line: no-string-literal
        const html = p['changingThisBreaksApplicationSecurity'];
        question.question = question.question.replace(
          '[[' + result[i][0] + ']]',
          html
        );
      }
    });
    dropdownStrList = this.sanitizer.bypassSecurityTrustHtml(
      question.question
    );

    setTimeout(() => {
      _.each(result, (sub, i) => {
        if (!_.isUndefined(sub)) {
          const v = result[i];
          if (question.userAnswers.length > 0) {
            const selectedOption = question.userAnswers.find(
              (i) => i.key === v[0]
            );
            const button = document.getElementById(result[i][0] + '' + index);
            const dropdown = button.getElementsByClassName('fabric-icon-text-pos-1')[0];

            if (dropdown !== null && selectedOption) {
              dropdown.innerHTML = selectedOption.value;
              const rightAnswer = question.answers.find(
                (o) => o.value === selectedOption.value && o.key === result[i][0]
              );
              if (rightAnswer !== undefined) {
                this.renderer.setAttribute(button, 'class', 'btn select ts-dropdown-fluent-toggle ts-dropdown-fluent-success position-relative d-flex align-items-center prac-test-ddl');
              } else {
                this.renderer.setAttribute(button, 'class', 'btn select ts-dropdown-fluent-toggle ts-dropdown-fluent-failure position-relative d-flex align-items-center prac-test-ddl');
              }
            } else {
              this.renderer.setAttribute(button, 'class', 'btn select ts-dropdown-fluent-toggle ts-dropdown-fluent-failure position-relative d-flex align-items-center prac-test-ddl');
            }
          }
        }
      });
    }, 100);
    return { dropdownUserList: dropdownStrList };
  }
  /**
   * @description: Create Drop Down
   */
  private createDropDown(options: any, index): any {
    const parentDiv = this.renderer.createElement('div');
    const div = this.renderer.createElement('div');
    this.renderer.setAttribute(div, 'class', 'dropdown-div');
    const button = this.renderer.createElement('button');
    this.renderer.setAttribute(button, 'class', 'btn select ts-dropdown-fluent-toggle position-relative d-flex align-items-center prac-test-ddl');
    this.renderer.setAttribute(button, 'type', 'button');
    this.renderer.setAttribute(button, 'data-toggle', 'dropdown');
    this.renderer.setAttribute(button, 'data-hover', 'dropdown');
    this.renderer.setAttribute(button, 'id', options[0] + '' + index);
    this.renderer.setAttribute(button, 'disabled', 'true');
    const span = this.renderer.createElement('span');
    this.renderer.setAttribute(span, 'class', 'fabric-icon-text-pos-1');
    this.renderer.appendChild(button, span);
    this.renderer.appendChild(div, button);
    this.renderer.appendChild(parentDiv, div);
    return parentDiv.innerHTML;
  }
  /**
   * @description: Right Answer Drop Down Value
   */
  private rightAnswerDropDown(question, optionList, correctDropDownList, index, dropdownStrList1): any {
    const DropDowns1 = optionList.reduce((i, j) => {
      i[j.key] = [...(i[j.key] || []), j];
      return i;
    }, {});
    const resultRightAnswer = Object.keys(DropDowns1).map((key) => {
      return [key, DropDowns1[key]];
    });
    const result1 = Object.keys(DropDowns1).map((keys) => {
      const k = keys.substr(0, 8);
      const ke = keys.replace(k, 'DropDowns');
      return [ke, DropDowns1[keys]];
    });
    _.each(result1, (sub, ind) => {
      if (!_.isUndefined(sub)) {
        const p1 = this.sanitizer.bypassSecurityTrustHtml(
          this.createDropDown(result1[ind], index)
        );
        // tslint:disable-next-line: no-string-literal
        const html1 = p1['changingThisBreaksApplicationSecurity'];
        correctDropDownList = correctDropDownList.replace(
          '[[' + resultRightAnswer[ind][0] + ']]',
          html1
        );
      }
    });
    dropdownStrList1 = this.sanitizer.bypassSecurityTrustHtml(
      correctDropDownList
    );
    setTimeout(() => {
      _.each(result1, (sub, ins) => {
        if (!_.isUndefined(sub)) {
          const dropdownKey = result1[ins];
          if (question.answers.length > 0) {
            const qa = question.answers.map((keys) => {
              // tslint:disable-next-line: no-string-literal
              const k = keys['key'].substr(0, 8);
              // tslint:disable-next-line: no-string-literal
              const ke = keys['key'].replace(k, 'DropDowns');
              // tslint:disable-next-line: no-string-literal
              keys['keys'] = ke;
              return keys;
            });
            const selectedOption1 = qa.find((i) => i.keys === dropdownKey[0]);
            if (document.getElementById(result1[ins][0] + '' + index) !== null) {
              const button1 = document.getElementById(result1[ins][0] + '' + index);
              if (button1 !== null && selectedOption1) {
                const dropdown1 = button1.getElementsByClassName('fabric-icon-text-pos-1')[0];
                if (dropdown1 !== null) {
                  dropdown1.innerHTML = selectedOption1.value;
                  this.rend.setAttribute(button1, 'class', 'btn select ts-dropdown-fluent-toggle ts-dropdown-fluent-success position-relative d-flex align-items-center prac-test-ddl');
                }
              }
            }
          }
        }
      });
    }, 100);
    return { dropdownRightAnswerList: dropdownStrList1 };
  }

  // numeric input
  /**
   * @description: Numeric Input Load Data
   */
  public onNumericInputLoadData(question, keyName, numericInput, examQuestion, examQuestionCorrectAnswer, numericInputCorrect): any {
    if (question !== undefined) {
      if (question.question.includes('[[')) {
        const splitKey = question.question.split('[[')[1];
        const splitkey2 = splitKey.split(']]');
        keyName = splitkey2[0];
        const p = this.sanitizer.bypassSecurityTrustHtml(this.createNumericInput(question));
        // tslint:disable-next-line: no-string-literal
        const html = p['changingThisBreaksApplicationSecurity'];
        examQuestion = examQuestion.replace(
          '[[' + keyName + ']]',
          html
        );
        numericInput = this.sanitizer.bypassSecurityTrustHtml(
          examQuestion
        );
        const p1 = this.sanitizer.bypassSecurityTrustHtml(
          this.createNumericInputCorrectAnswer(question)
        );
        // tslint:disable-next-line: no-string-literal
        const html1 = p1['changingThisBreaksApplicationSecurity'];
        examQuestionCorrectAnswer = examQuestionCorrectAnswer.replace(
          '[[' + keyName + ']]',
          html1
        );
        numericInputCorrect = this.sanitizer.bypassSecurityTrustHtml(
          examQuestionCorrectAnswer
        );
      }
      return { numericInputValue: numericInput, numericInputCorrectValue: numericInputCorrect };
    }
  }
  /**
   * @description: Create Numeric Input  Dropdown
   */
  private createNumericInput(question): any {
    const div = this.renderer.createElement('div');
    const inputType = this.renderer.createElement('input');
    this.renderer.setAttribute(inputType, 'class', 'ts-textbox-fluent');
    this.renderer.setAttribute(inputType, 'type', 'number');
    this.renderer.setAttribute(inputType, 'disabled', 'true');
    if (question.userAnswers.length > 0) {
      question.userAnswers.forEach((element) => {
        inputType.setAttribute('aria-label', element.value)
        this.renderer.setAttribute(inputType, 'value', element.value);
        const rightAnswer = question.answers.find(
          (o) => o.value === inputType.value
        );
        if (rightAnswer !== undefined) {
          this.renderer.setAttribute(inputType, 'class', 'ts-textbox-fluent');
          this.renderer.setAttribute(inputType, 'class', 'ts-textbox-fluent ts-textbox-fluent-success');
        } else {
          this.renderer.setAttribute(inputType, 'class', 'ts-textbox-fluent');
          this.renderer.setAttribute(inputType, 'class', 'ts-textbox-fluent ts-textbox-fluent-failure');
        }
      });
    }
    this.renderer.listen(inputType, 'change', (event) => { });
    this.renderer.appendChild(div, inputType);
    return div.innerHTML;
  }
  /**
   * @description: Create Numeric Input Correct Dropdown
   */
  private createNumericInputCorrectAnswer(question): any {
    const div = this.renderer.createElement('div');
    const inputType = this.renderer.createElement('input');
    this.renderer.setAttribute(inputType, 'class', 'ts-textbox-fluent');
    this.renderer.setAttribute(inputType, 'type', 'number');
    this.renderer.setAttribute(inputType, 'disabled', 'true');
    // if (question.userAnswers.length > 0) {
    question.answers.forEach((element) => {
      inputType.setAttribute('aria-label', element.value)
      this.renderer.setAttribute(inputType, 'value', element.value);
      this.renderer.setAttribute(inputType, 'class', 'ts-textbox-fluent');
      this.renderer.setAttribute(inputType, 'class', 'ts-textbox-fluent ts-textbox-fluent-success');
    });
    this.renderer.listen(inputType, 'change', (event) => { });
    this.renderer.appendChild(div, inputType);
    return div.innerHTML;
  }
}
