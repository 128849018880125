import { Component, OnInit } from '@angular/core';
import { detect } from 'detect-browser';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { Subject } from 'rxjs';
import { EventService } from '../../../services/event.services';

@Component({
  selector: 'cloudlabs-lab-validation-dialog',
  templateUrl: './lab-validation-dialog.component.html',
  styleUrls: ['./lab-validation-dialog.component.scss']
})
export class LabValidationDialogComponent implements OnInit {
  public title: string;
  public browser = detect();
  public data: any;
  public edgePopUpHeading: boolean;
  public onClose: Subject<boolean>;
  constructor(
    public modalRef: BsModalRef,
    public eventSrv: EventService
  ) { }
  ngOnInit(): void {
    if(this.browser.name.includes('edge'))
    {
      this.edgePopUpHeading = true;
    }else{
      this.edgePopUpHeading = false;
    }
  }

  public onConfirm(): void {
    this.eventSrv.emmitValidationDialogConfirmClosed();
    this.modalRef.hide();
  }

  public onCancel(): void {
    this.modalRef.hide();
  }
}
