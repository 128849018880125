import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { videoUrlCheck } from '@utility';

@Component({
  selector: 'cloudlabs-video',
  templateUrl: './video.component.html',
  styleUrls: ['./video.component.scss']
})
export class VideoComponent implements OnInit {
  @Input() videoTag: string;
  @Input() videoLink: string;
  @Input() videoWidth: string;
  @Input() videoHeight: string;
  isVMLab = false;

  constructor(
    private route: Router,
  ) { }

  ngOnInit(): void {
    if (this.route.url.includes('labenvironment')) {
      this.isVMLab = true;
    } else {
      this.isVMLab = false;
    }
  }

  /**
   * @description: To convert video url to embed urls
   */

  public videoUrlCheck(videoUrl: string): string {
    return videoUrlCheck(videoUrl, 'false');
  }

}
