import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'timeTransform'
})
export class TimeTransformPipe implements PipeTransform {

  transform(value: number, progress: number): string {
    let secs = value / 1000;
    let mins = secs / 60;
    secs = secs % 60;
    if (value < 6000) {
      secs = 0;
      mins = 1;
    }
    return 'Your On Demand Lab will be ready in ' + Math.floor(mins) + ' minute and ' + secs + ' seconds and progress is ' + progress + ' percentage.';
  }

}
