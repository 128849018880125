import {ChangeDetectorRef, Component, EventEmitter, Inject, Input, NgZone, OnInit, Output, ViewChild} from '@angular/core';
import {LabService} from './../../../services/lab.service';
import {ClipboardService} from 'ngx-clipboard';
import {ToastrService} from 'ngx-toastr';
import {TranslateService} from '@ngx-translate/core';
import {ActivatedRoute, Router} from '@angular/router';
import {Lab, OperationModes} from '../../../modals/lab.model';
import {EventService} from './../../../services/event.services';
import {CountdownComponent} from 'ngx-countdown';
import {NotificationService} from '../../../services/notification.service';
import { RegistrationService } from '../../../services/registration.service';
import { getEntity, getEntityData, miscellaneousConst } from '@utility';
import { EventEmitterService } from '@teams-auth';

@Component({
  selector: 'cloudlabs-lab-environment',
  templateUrl: './lab-environment.component.html',
  styleUrls: ['./lab-environment.component.scss']
})
export class LabEnvironmentComponent implements OnInit {
  @Input() labDetail: Lab;
  @Input() minGuide: boolean;
  @Input() voucherCode: string;
  @Input() type: string;
  @Input() mode: string;
  @Output() deallocateLab = new EventEmitter<string>();
  @Output() startingLab = new EventEmitter<string>();
  public attendeLabDetails: any;
  public loading = false;
  public docActions = false;
  public selectedTab = 'Azure';
  public awsLink = '';
  public gcpLink = '';
  public ociLink = '';
  public ociTenancyName = '';
  public licenses = false;
  public deploymentOutput = false;
  public showSecondLayerTab = true;
  public accordionToggleInfo = {1: true};
  public modelShow = false;
  public modalData: any;
  public outputParameter = false;
  public operationModeACI: OperationModes;
  public isPolling = false;
  public polling: any;
  public operationModes = OperationModes;
  private pollIntervalsInMinutes = 1;
  public remainingMint = false;
  public remainingDuration: any = { leftTime: 0, format: 'mm:ss', notify: [60] };
  public progressBar: any = 1;
  public manageInterval: any;
  public isCL = false;
  public deployACIBtnDisable = false;
  @ViewChild('cd', { static: false }) public countDownTime: CountdownComponent;
  isLabDetailsPage = true;
  public disableDeployRPDLabButton = false;
  subscriptionError: string = null;
  environment: any;

  constructor(
    private LabSrv: LabService,
    private regService: RegistrationService,
    private _clipboardService: ClipboardService,
    private toastr: ToastrService,
    private notificationService: NotificationService,
    private translateSrv: TranslateService,
    private eventSrv: EventService,
    private route: Router,
    private ngZone: NgZone,
    private aroute: ActivatedRoute,
    private cdr: ChangeDetectorRef,
    private eventEmitter: EventEmitterService,
    @Inject('environment')
    environment
  ) {
    this.environment = environment;
   }

  async ngOnInit() {
    this.loading = true;
    if(localStorage.getItem('context') === miscellaneousConst.cloudlab){
      this.isCL = true
    }
    if (this.labDetail.DoNotSendCloudCredentials === true) {
      this.selectedTab = '';
    }
    this._clipboardService.copyResponse$.subscribe(re => {
      if (re.isSuccess) {
        // @ts-ignore
        ((re.event?.offsetParent as HTMLElement)?.childNodes[0] as HTMLElement)?.focus();
        // @ts-ignore
        ((re.event?.offsetParent as HTMLElement)?.childNodes[0] as HTMLElement)?.blur();
        this.notificationService.success(this.translateSrv.instant('MESSAGE_COPIED'), null);
      }
    });
    if (this.mode === 'AzurePassUserDetails'){
      await this.grabAzurePassForUser();
    }else{
      await this.getEnvironment();
    }
    this.setAccordionToggleInfo();
    const urlParts = this.route.url.split('/');
    // if ((urlParts.includes('environment')) || (urlParts.includes('labguide'))) {
    //   this.isLabDetailsPage = false;
    // }
    let paraLength = 0;
    if(this.attendeLabDetails?.DeploymentOutputValues){
    for(const dOpVal of this.attendeLabDetails.DeploymentOutputValues){
      for(const opVal of dOpVal.OutputValues){
        if(opVal.ExcludeOutputParameter === false){
          paraLength+=1
        }
      }
      if(paraLength === 0){
        this.outputParameter = false
      }
      else{
        this.outputParameter = true
      }
      paraLength = 0
    }
  }
  }

  /**
   * get vm environment
   */
  async getEnvironment() {
    const data = await this.LabSrv.getLabEnvironment(
      this.labDetail.UniqueName,
      this.voucherCode
    ).toPromise();
    this.attendeLabDetails = data;
    this.setShouldShowSecondLayerOfTabs();
    if (this.labDetail.DoNotSendCloudCredentials !== true ||  this.labDetail.AllowExistingAD === true ||
      ((this.attendeLabDetails.LabGuidUrl !== null && this.attendeLabDetails.LabGuidUrl !== '') ||
        (this.attendeLabDetails.LabInformationURL !== '' && this.attendeLabDetails.LabInformationURL != null) ||
        (this.attendeLabDetails.PreGuideUrl !== '' && this.attendeLabDetails.PreGuideUrl != null))) {
      if (this.attendeLabDetails.CloudPlatformId === 2) {
        this.selectedTab = 'Aws';
      } else if (this.attendeLabDetails.CloudPlatformId === 3) {
        this.selectedTab = 'Gcp';
      } else if (this.attendeLabDetails.CloudPlatformId === 4) {
        this.selectedTab = 'Oci';
        await this.getOCITenanyName(this.attendeLabDetails.AADId);
      } else if (this.attendeLabDetails.CloudPlatformId === 1) {
        this.selectedTab = 'Azure';
      }
    } else {
      if (this.attendeLabDetails.CloudPlatformId === 2 && this.attendeLabDetails.DeploymentOutputValues?.length > 0) {
        this.selectedTab = 'Aws';
      } else if (this.attendeLabDetails.CloudPlatformId === 3 && this.attendeLabDetails.DeploymentOutputValues?.length > 0) {
        this.selectedTab = 'Gcp';
      } else if (this.attendeLabDetails.CloudPlatformId === 4 && this.attendeLabDetails.DeploymentOutputValues?.length > 0) {
          this.selectedTab = 'Oci';
         await this.getOCITenanyName(this.attendeLabDetails.AADId);
      } else if (this.attendeLabDetails.CloudPlatformId === 1 && this.attendeLabDetails.DeploymentOutputValues?.length > 0) {
        this.selectedTab = 'Azure';
      } else if (this.attendeLabDetails.AADSPAppId) {
        if (this.attendeLabDetails.CloudPlatformId === 2) {
          this.selectedTab = 'Service';
        } else if (this.attendeLabDetails.CloudPlatformId === 3) {
          this.selectedTab = 'Access key details';
        } else if (this.attendeLabDetails.CloudPlatformId === 1 || this.attendeLabDetails.CloudPlatformId === 4) {
          this.selectedTab = 'Environment details';
        }
      } else if (this.attendeLabDetails.Licenses !== null && this.attendeLabDetails.Licenses.length > 0) {
        this.selectedTab = 'Licenses';
      } else {
        this.selectedTab = '';
      }
    }
    this.awsLink = `https://${this.attendeLabDetails.AADDomain}.signin.aws.amazon.com/console/`;
    this.gcpLink = `https://console.cloud.google.com/home/dashboard?project=${this.attendeLabDetails.ResourceGroupName}`;
    this.ociLink = `https://cloud.oracle.com/?tenant=${this.ociTenancyName}&domain=${this.attendeLabDetails.AADDomain}&region=${this.attendeLabDetails.ResourceGroupRegion}`;
    if(this.attendeLabDetails){
      if(this.attendeLabDetails.Licenses !== null && this.attendeLabDetails.Licenses.length > 0){
        this.licenses = true;
      }
      if(this.attendeLabDetails.DeploymentOutputValues !== null && this.attendeLabDetails.DeploymentOutputValues.length > 0 ){
        this.deploymentOutput = true;
      }
    }
    setTimeout(() => {
      this.loading = false;
    }, 200);
  }

  async getOCITenanyName(aadId) {
    const data = await this.LabSrv.getOciTenancyNameByAadId(
      aadId
    ).toPromise();
    this.ociTenancyName = data.toString();
  }

  async grabAzurePassForUser() {
    this.LabSrv.grabAzurePass(this.labDetail.InternalId, this.voucherCode).subscribe((response: any) => {
      if (response.Result === null) {
        this.attendeLabDetails = response;
        if(this.attendeLabDetails.Licenses !== null && this.attendeLabDetails.Licenses.length > 0 ){
          this.licenses = true;
        }
        if(this.attendeLabDetails?.DeploymentOutputValues !== null && this.attendeLabDetails.DeploymentOutputValues.length > 0){
          this.deploymentOutput = true;
        }
        this.selectedTab = 'Ms';
        this.showSecondLayerTab = true;
      }
      setTimeout(() => {
        this.loading = false;
      }, 200);

    }, (error) => {
      const code = 'Error - 50025';
      this.eventEmitter.debugAlert(code, error);
    });
  }

  /**
   * run vm in new tab
   * @param item
   */
 async openVMandGitDoc(item) {
  const vmName = item.Type === 'virtualMachines/hyperVm' ? '' : item.VMName;
   try {
      const containerCurrentStatus = await this.LabSrv.getContainerStatus(item.CloudDeploymentId, item.ContainerStatus, vmName).toPromise();
      const vmData = await this.LabSrv.getVirtualMachineStatus(
        item.SubscriptionId,
        item.ResourceGroupName,
        item.VMName,
        this.voucherCode,
        item.Type === "virtualMachines/hyperVm" ? true : false
      ).toPromise();
      if (
        (containerCurrentStatus === 'Stopped' ||
        vmData.Status === 'VM deallocated' ||
        vmData.Status === 'VM stopped' || vmData.Status === 'Off' ||
        vmData.Status === 'Saved' || vmData.Status === 'Paused') &&
        vmData.Status !== 'VM deallocating'
        ) {
        if ((vmData.Status === 'VM deallocated' ||
          vmData.Status === 'VM stopped' || vmData.Status === 'Off' ||
          vmData.Status === 'Saved' || vmData.Status === 'Paused'
        ) && containerCurrentStatus !== 'Stopped') {
          this.startVM(vmData, item.Type === "virtualMachines/hyperVm");
        } else if ((vmData.Status === 'VM deallocated' ||
          vmData.Status === 'VM stopped' || vmData.Status === 'Off' ||
          vmData.Status === 'Saved' || vmData.Status === 'Paused'
        ) && containerCurrentStatus === 'Stopped') {
          this.startVM(vmData, item.Type === "virtualMachines/hyperVm");
          this.startContainer(item.CloudDeploymentId);
        } else if ((vmData.Status !== 'VM deallocated' ||
          vmData.Status !== 'VM stopped' || vmData.Status !== 'Off' ||
          vmData.Status !== 'Saved' || vmData.Status !== 'Paused'
        ) && containerCurrentStatus === 'Stopped') {
          this.startContainer(item.CloudDeploymentId);
        }
        this.modalData = {
          title: item.Type === "virtualMachines/hyperVm" ? 'STARTED_HYPERVM_MODAL_TITLE' : 'STARTED_VM_MODAL_TITLE',
          description: item.Type === "virtualMachines/hyperVm" ? 'STARTED_HYPERVM_MODAL_DESCRIPTION' : 'STARTED_VM_MODAL_DESCRIPTION',
        };
        this.modelShow = true;
      } else if ((vmData.Status === 'VM running' || vmData.Status === 'Running' ) && containerCurrentStatus === 'Running') {
          let url
          if(localStorage.getItem('context') === miscellaneousConst.cloudlab){
            const vcode = this.aroute.snapshot.params.clVcode;
            const clid = this.aroute.snapshot.params.clid
            const id = clid ? `/${clid}/${vcode}/` : '/'
            url = this.route.serializeUrl(
              this.route.createUrlTree([getEntity()+`/`+getEntityData()+`${id}${this.labDetail.UniqueName}/${this.voucherCode}/labenvironment/${item.InternalId}${item.Type === 'virtualMachines/hyperVm' ? `?vmguid=${item.VMGuid}` : ''}`])
            );
          }
          else{
            url = this.route.serializeUrl(
              this.route.createUrlTree([`#/odl/environment/${this.labDetail.UniqueName}/${this.voucherCode}/${item.InternalId}${item.Type === 'virtualMachines/hyperVm' ? `?vmguid=${item.VMGuid}` : ''}`])
            );
          }
          window.open(decodeURIComponent(url), '_blank');
      } else {
        this.modalData = {
          title: item.Type === "virtualMachines/hyperVm" ? 'HYPERVM_NOT_READY_MODAL_TITLE' : 'VM_NOT_READY_MODAL_TITLE',
          description: item.Type === "virtualMachines/hyperVm" ? 'HYPERVM_NOT_READY_MODAL_DESCRIPTION' : 'VM_NOT_READY_MODAL_DESCRIPTION',
        };
        this.modelShow = true;
      }
   } catch (e) {
      if (e.error.ErrorDetail === 'Sorry! User has reached the limit of run time for the Virtual Machine. Please contact instructor/support team for additional information.') {
        this.modalData = {
          title: 'OOPS',
          description: 'VM_START_UPTIME_LIMIT_REACHED_MODEL_DESCRIPTION'
        };
        this.modelShow = true;
      } else if (e.status === 500 && e.url.includes('GetVirtualMachineStatus')) {
        this.notificationService.error(e.error.ErrorDetail, null);
      }       
      else {
        // manage toast message
        const { Status, ErrorMessage } = e.error;
        if (Status && ErrorMessage && Status === 'Error' && (ErrorMessage !== null || ErrorMessage !== '')) {
          this.toastr.error(`<p role="alert">${ErrorMessage}</p>`, null, {
            enableHtml: true,
            timeOut: 3000
          });
        }
      }
      const code = 'Error - 70021';
      this.eventEmitter.debugAlert(code, e.error);
    }
  }

  /**
   * selected environment tab to show
   * @param item
   */
  selectEnvironment(item) {
    this.selectedTab = item;
  }

  private setShouldShowSecondLayerOfTabs() {
    let counter = 0;
    if (this.attendeLabDetails.DeploymentOutputValues?.length > 0 ||
      (this.attendeLabDetails.AADDomain && this.labDetail.AllowExistingAD !== true) ||
      ((this.attendeLabDetails.LabGuidUrl !== null && this.attendeLabDetails.LabGuidUrl !== '') ||
        (this.attendeLabDetails.LabInformationURL !== '' && this.attendeLabDetails.LabInformationURL != null) ||
        (this.attendeLabDetails.PreGuideUrl !== '' && this.attendeLabDetails.PreGuideUrl != null))) {
      counter += 1;
    }
    if (this.attendeLabDetails.AADSPAppId) {
      counter += 1;
    }
    if (this.attendeLabDetails.Licenses !== null && this.attendeLabDetails.Licenses.length > 0) {
      counter += 1;
    }
    if (counter > 1) {
      this.showSecondLayerTab = true;
    } else {
      this.showSecondLayerTab = false;
    }
  }
  toggleAccordion(index: number) {
    this.accordionToggleInfo[index] = !this.accordionToggleInfo[index];
  }
  setAccordionToggleInfo() {
    for (const dov in this.attendeLabDetails?.DeploymentOutputValues) {
      this.accordionToggleInfo[dov] = false;
    }
  }

  private async startVM(vmData: any, isHypervm?: boolean) {
    let data: any;
    if (isHypervm) {
      data = await this.LabSrv.hyperVmOperation(
        'StartVm',
        vmData.ResourceId,
        this.voucherCode
      ).toPromise();
    } else {
      data = await this.LabSrv.virtualMachineOperations(
        7,
        this.attendeLabDetails.CloudPlatformId,
        vmData.SubscriptionId,
        vmData.ResourceGroupName,
        vmData.ResourceName,
        this.voucherCode
      ).toPromise();
    }
    if (data.IsSuccess) {
      this.notificationService.success(this.translateSrv.instant('MESSAGE_VM_START'), null);
      await this.getEnvironment();
    } else {
      if (data.ErrorMessage === 'Sorry! User has reached the limit of run time for the Virtual Machine. Please contact instructor/support team for additional information.') {
        this.modalData = {
          title: 'OOPS',
          description: 'VM_START_UPTIME_LIMIT_REACHED_MODEL_DESCRIPTION'
        };
        this.modelShow = true;
      } else {
        // manage toast message
        const { Status, ErrorMessage } = data.error;
        if (Status && ErrorMessage && Status === 'Error' && (ErrorMessage !== null || ErrorMessage !== '')) {
          this.toastr.error(`<p role="alert">${ErrorMessage}</p>`, null, {
            enableHtml: true,
            timeOut: 3000
          });
        }
      }
      const code = 'Error - 70022';
      this.eventEmitter.debugAlert(code, data.error);
    }
  }

  private startContainer(cloudDeploymentId: string) {
    this.LabSrv.controlContainerAction(cloudDeploymentId, 'start');
  }

  deployACILab() {
    this.operationModeACI = OperationModes.GATHERINGINFO;
    this.startProgress();
    this.deployACI();
  }

  public async deployACI() {
    this.deployACIBtnDisable = true;
    try {
      const response: any = await this.LabSrv.getACIDeployment(this.voucherCode).toPromise();

      if (response.Status === 'Succeeded') {
        this.stopPolling();
        this.attendeLabDetails.VMLaunchURL = response.Data;
        this.operationModeACI = OperationModes.INPROGRESS;
        clearInterval(this.manageInterval);
        this.progressBar = 1;
        if (this.countDownTime) {
          this.countDownTime.stop();
        }
      } else if (response.Status === 'Deploying') {
        this.operationModeACI = OperationModes.INITIATION;
        this.startPolling();
      } else if (response.Status === 'Failed') {
        this.operationModeACI = OperationModes.FAILED;
        this.stopPolling();
      }
      this.deployACIBtnDisable = false;
    } catch (e) {
      this.deployACIBtnDisable = false;
      const code = 'Error - 70024';
      this.eventEmitter.debugAlert(code, "Error Initiating Deployment");
    }
  }

  startPolling() {
    if (!this.isPolling) {
      this.isPolling = true;
      this.polling = setInterval(() => {
          this.deployACI();
        },
        this.pollIntervalsInMinutes * 60000
      );
    }
  }
  stopPolling() {
    if (this.isPolling) {
      clearInterval(this.polling);
      this.isPolling = false;
    }
  }
  startProgress(){
    this.remainingDuration.leftTime = 5 * 60;
    const calc = (300 * 1000) / 100;
    this.manageInterval = setInterval(() => this.manageProgress(), calc );
  }

  manageProgress(){
    if (this.progressBar === 89 ){
      clearInterval(this.manageInterval);
    }
    if (this.countDownTime?.left < 62000){
      this.remainingMint = true;
      this.countDownTime.pause();
    }
    const duration = 1;
    this.progressBar += duration;
    this.cdr.detectChanges()
  }

  deallocateEnvironment() {
    if (this.attendeLabDetails !== undefined &&
      this.attendeLabDetails !== null &&
      this.attendeLabDetails.InternalId !== undefined &&
      this.attendeLabDetails.InternalId !== null) {
      this.modalData = {
        title: 'DANGER',
        description: 'MESSAGE_DEALLOCATE',
        type: 'DEALLOCATE_LAB',
        showCancel: true
      };
      this.modelShow = true;
    }
  }

  async confirmDeallocateEnvironment() {
    this.modelShow = false;
    this.deallocateLab.emit(this.attendeLabDetails.InternalId);
  }

  handleCountDown(event) {
    if (event.action === 'notify') {
      this.remainingMint = true;
      if (this.countDownTime) {
        this.countDownTime.pause();
      }
    }
  }
  submitAzurePassSubscription(formValue) {
    formValue.Id=null;
    formValue.TenantId = formValue.TenantId.replace(/\s/g, '');
    formValue.TenantDomainName = formValue.TenantDomainName.replace(/\s/g, '');
    formValue.AppId = formValue.AppId.replace(/\s/g, '');
    formValue.Guid = formValue.Guid.replace(/\s/g, '');
    this.regService.addSubscriptionForAzurePass(formValue,this.labDetail.InternalId, this.voucherCode).subscribe(
      res => {

          this.ngZone.run(()=>{
            this.attendeLabDetails = res;
            if (res.Result?.Status === 'Failed' || res.Result?.Status === 'Error') {
              this.subscriptionError = res.Result?.ErrorMessage;
              this.showSecondLayerTab = false;
            }
          });
      },
      err => {
        const code = 'Error - 70023';
        this.eventEmitter.debugAlert(code, err);
      }
    );
  }
  startLab(){
    this.startingLab.emit();
  }
}
