/* eslint-disable no-useless-escape */
import {
  Component,
  AfterViewInit,
  Input,
  ElementRef,
  ViewChild,
  HostListener,
} from '@angular/core';
import { LabService } from '../../../../../services/lab.service';
import { TranslateService } from '@ngx-translate/core';
import { copyToClipboard } from '@utility';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'cloudlabs-databricks-layout',
  templateUrl: './databricks-layout.component.html',
  styleUrls: ['./databricks-layout.component.scss'],
})
export class DatabricksLayoutComponent implements AfterViewInit {
  @ViewChild('iframe') iframe!: ElementRef<HTMLElement>;

  @Input() vCode: string;
  @Input() isDraggable: boolean;

  @HostListener('document:click', ['$event'])
  async handleClick($event) {
    if (
      $event.target.id === 'copyelement' ||
      ($event.target as HTMLElement).classList.contains('copyelement')
    ) {
      const brRegex = /<br\s*[\/]?>/gi;
      const copyData =
        $event.target.innerText || $event.target.firstChild.innerText;
      const txtdata = copyData.replace(brRegex, '\r\n');
      await copyToClipboard(txtdata);

      this.tostrSrv.success(
        '<p role="alert">' +
          this.translateSrv.instant('MESSAGE_COPIED') +
          '</p>',
        null,
        {
          enableHtml: true,
          timeOut: 3000,
        }
      );
    }
  }

  @HostListener('window:keydown', ['$event'])
  async onkeyPress($event) {
    if ($event.key == 'Enter' && $event.keyCode == 13) {
      if (
        $event.target.id === 'copyelement' ||
        ($event.target as HTMLElement).classList.contains('copyelement')
      ) {
        const brRegex = /<br\s*[\/]?>/gi;
        const copyData =
          $event.target.innerText || $event.target.firstChild.innerText;
        const txtdata = copyData.replace(brRegex, '\r\n');
        await copyToClipboard(txtdata);

        this.tostrSrv.success(
          '<p role="alert">' +
            this.translateSrv.instant('MESSAGE_COPIED') +
            '</p>',
          null,
          {
            enableHtml: true,
            timeOut: 3000,
          }
        );
      }
    }
  }

  constructor(
    private _labService: LabService,
    private tostrSrv: ToastrService,
    private translateSrv: TranslateService
  ) {
    this.isDraggable = false;
  }

  ngAfterViewInit(): void {
    this._labService
      .getDatabricksFrameForm({ voucherCode: this.vCode })
      .subscribe((result) => {
        setTimeout(() => {
          this.setIframeReady(this.iframe, result.HtmlResponse);
        }, 100);
      });
  }

  private generateBlobUrl(htmlContent: string): string {
    const blob = new Blob([htmlContent], { type: 'text/html' });
    return URL.createObjectURL(blob);
  }

    private setIframeReady(iframe: ElementRef, iframeCode: string): void {
      const win: Window = iframe.nativeElement.contentWindow;
      const doc: Document = win.document;
      doc.open();
      doc.write(iframeCode);
      doc.close();
    }
}
