import {Component, OnInit} from '@angular/core';
import { detect } from 'detect-browser';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { Subject } from 'rxjs';
import { EventService } from '../../../services/event.services';

@Component({
  selector: 'cloudlabs-vm-dialog',
  templateUrl: './vm-dialog.component.html',
  styleUrls: ['./vm-dialog.component.scss']
})
export class VmDialogComponent implements OnInit {
  public title: string;
  public browser = detect();
  public edgePopUpHeading: boolean;
  public data: any;
  public onClose: Subject<boolean>;
  constructor(
    public modalRef: BsModalRef,
    public eventSrv: EventService
  ) { }

  ngOnInit(): void {
    if(this.browser.name.includes('edge'))
    {
      this.edgePopUpHeading = true;
    }else{
      this.edgePopUpHeading = false;
    }
  }

  public onConfirm(): void {
    this.eventSrv.onCloseVM();
    this.modalRef.hide();
  }

  public onCancel(): void {
    this.modalRef.hide();
  }
}
