import {Component, OnInit, Input, OnChanges, SimpleChanges} from '@angular/core';
import { Lab } from './../../../../modals/lab.model';
import {LabService} from '../../../../services/lab.service';

@Component({
  selector: 'cloudlabs-lab-overview',
  templateUrl: './lab-overview.component.html',
  styleUrls: ['./lab-overview.component.scss']
})
export class LabOverviewComponent implements OnInit, OnChanges {
  @Input() lab: Lab;
  public minutes: any;
  public hours: any;
  public labDescription = '';
  constructor(private labSrv: LabService) { }

  ngOnInit(): void {
    if (this.lab?.Duration){
      this.timeConvert(this.lab.Duration);
    }
    this.labDescription = this.lab?.LabLaunchPageDescription ? this.lab?.LabLaunchPageDescription : this.lab?.Description;
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.lab) {
      this.labDescription = this.lab?.LabLaunchPageDescription ? this.lab?.LabLaunchPageDescription : this.lab?.Description;
    }
  }

  timeConvert(n){
    this.minutes = n%60;
    this.hours = (n - this.minutes) / 60;
  }

}
