import { Component, Input, OnInit } from '@angular/core';

import { ResultHelperService } from '../../../services/result-helper.service';

@Component({
  selector: 'multiple-choice-result',
  templateUrl: './multiple-choice-result.component.html',
})
export class MultipleChoiceResultComponent implements OnInit {
  @Input() public question;
  @Input() public index;
  constructor(private resultHelper: ResultHelperService) { }

  ngOnInit(): void {
    this.loadData();
  }
  /**
   * @description: Load Data First Time
   */
  private loadData(): any {
    this.question?.options.forEach((element) => {
      element.checked = false;
      element.answer = 0;
      element.attempt = 0;
    });
    if (this.question.userAnswers.length === 0) {
      this.loadAnswers();
    }
    if (this.question.userAnswers.length > 0) {
      this.userSelectedAnswers();
    }
  }
  /**
   * @description: Load User Selected Answers
   */
  private userSelectedAnswers(): any {
    this.resultHelper.userSelectedMultipleChoiceAnswers(this.question);
  }
  /**
   * @description: Load Answers
   */
  private loadAnswers(): any {
    const answer = this.question?.options.filter((i) =>
      this.question.answers.some((o) => i.key === o.key)
    );
    answer.forEach((element) => {
      element.attempt = 1;
    });
  }
}
