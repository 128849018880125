import { Component, OnInit } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { EventService } from './.././../../services/event.services';
import { Router, ActivatedRoute } from '@angular/router';
import { LabService } from './../../../services/lab.service';
import { EventEmitterService } from '@teams-auth';
import { TranslateService } from '@ngx-translate/core';
import { NotificationService } from '@utility';
import {
  ExtendCreditStatus,
  ExtendRequestStatusObject,
} from '../../../modals/lab.model';

@Component({
  selector: 'cloudlabs-extend-duration',
  templateUrl: './extend-duration.component.html',
  styleUrls: ['./extend-duration.component.scss'],
})
export class ExtendDurationComponent implements OnInit {
  public title: string;
  public data: any;
  public loading: boolean;
  public extendCreditStauts = ExtendCreditStatus;
  public statusDetails: ExtendRequestStatusObject;

  constructor(
    public modalRef: BsModalRef,
    public eventSrv: EventService,
    public route: Router,
    public labSrv: LabService,
    public aroute: ActivatedRoute,
    private eventEmitter: EventEmitterService,
    public translateSrv: TranslateService,
    private notificationService: NotificationService
  ) {}

  ngOnInit(): void {
    if (this.data?.approvalRequired) {
      this.loading = true;
      this.labSrv
        .getRequestStatus({
          voucherCode: this.data?.v_code,
          requestType: 'duration',
        })
        .subscribe(
          (result: ExtendRequestStatusObject) => {
            this.statusDetails = result;
            if (
              this.statusDetails.ErrorKey ===
              ExtendCreditStatus.REQUEST_APPROVED
            ) {
              this.eventSrv.extendduration();
            }
            this.loading = false;
          },
          () => {
            this.loading = false;
          }
        );
    }
    this.eventSrv.closeduration.subscribe((res) => {
      this.modalRef.hide();
    });
  }

  private showConfirmationMessage(status: boolean, message: string) {
    if (status) {
      this.notificationService.success(message, '');
    } else {
      this.notificationService.error(message, '');
    }
  }

  async onConfirm() {
    this.modalRef.hide();
    try {
      this.labSrv
        .extendLabDurationByAttendee(this.data.v_code, this.data.duration)
        .subscribe(
          (res: any) => {
            if (res && res.Status === 'Success') {
              setTimeout(() => {
                this.eventSrv.extendduration();
              }, 1000);
              sessionStorage.setItem('duration', this.data.v_code);
              if (this.data?.approvalRequired) {
                this.showConfirmationMessage(
                  true,
                  this.translateSrv.instant('EXT_CREDIT_SUCC', {
                    type: this.translateSrv.instant('DURATION'),
                  })
                );
              } else {
                this.showConfirmationMessage(
                  true,
                  this.translateSrv.instant(
                    'EXTEND_LAB_DURATION_SUCCESS_MESSAGE',
                    {
                      type: this.translateSrv.instant('DURATION'),
                    }
                  )
                );
              }
            } else {
              if (res.ErrorKey === 'MAX_LIMIT_REACHED') {
                this.showConfirmationMessage(
                  false,
                  this.translateSrv.instant('MAX_LIMIT_REACHED', {
                    type: this.translateSrv.instant('DURATION'),
                    unit: this.data?.maxUnit,
                  })
                );
              } else {
                this.showConfirmationMessage(false, res.ErrorMessage);
              }
            }
          },
          (err) => {
            this.eventSrv.extendDurationError(err);
            sessionStorage.setItem('cancelduration', this.data.v_code);
            sessionStorage.removeItem('duration');
            if (this.data?.approvalRequired) {
              this.showConfirmationMessage(
                false,
                this.translateSrv.instant('EXT_CREDIT_FAIL', {
                  type: this.translateSrv.instant('DURATION'),
                })
              );
            } else {
              this.showConfirmationMessage(
                false,
                this.translateSrv.instant(
                  'EXTEND_LAB_DURATION_FAILURE_MESSAGE',
                  {
                    type: this.translateSrv.instant('DURATION'),
                  }
                )
              );
            }
            const code = 'Error - 70019';
            this.eventEmitter.debugAlert(code, err.error.ErrorDetail);
          }
        );
    } catch (err) {
      if (
        err?.error?.ErrorDetail ==
          'You are not allowed to extend the lab now. Please contact CloudLabs support team' ||
        err?.error?.ErrorDetail ==
          `You can extend this lab only upto ${
            this.data.duration || 15
          } minutes.`
      ) {
        sessionStorage.setItem('cancelduration', this.data.v_code);
        sessionStorage.removeItem('duration');
      }
      const code = 'Error - 70020';
      if (this.data?.approvalRequired) {
        this.showConfirmationMessage(
          false,
          this.translateSrv.instant('EXT_CREDIT_FAIL', {
            type: this.translateSrv.instant('DURATION'),
          })
        );
      } else {
        this.showConfirmationMessage(
          false,
          this.translateSrv.instant('EXTEND_LAB_DURATION_FAILURE_MESSAGE', {
            type: this.translateSrv.instant('DURATION'),
          })
        );
      }
      this.eventEmitter.debugAlert(code, err.error.ErrorDetail);
    }
  }

  onCancel() {
    this.modalRef.hide();
    this.eventSrv.extendDurationCancel();
    sessionStorage.setItem('cancelduration', this.data.v_code);
    sessionStorage.removeItem('duration');
  }
}
