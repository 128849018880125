<div id="resource-container">
  <div class="square-box heading-outer">
    <!--Virtual Machine section-->
    <span class="resources-heading" resources-heading *ngIf="vmUsage">
      <div class="d-flex heading-box pl-3">
        <div class="heading">
          <div *ngIf="attendeLabDetails.CloudPlatformId !== 2" class="caption-subject" role="heading" aria-level="2"
            aria-label="TAB VIRTUAL MACHINES">
            {{ 'TAB_VIRTUAL_MACHINES' | translate }}
          </div>
          <div *ngIf="attendeLabDetails.CloudPlatformId === 2" class="caption-subject" role="heading" aria-level="2"
            aria-label="EC2 INSTANCES">
            {{ 'EC2_INSTANCES' | translate }}
          </div>
        </div>
        <p role="heading" aria-level="3" *ngIf="attendeLabDetails.ComputeVMUsage && showUpTimeLimit">
          {{ 'MAXIMUM_UPTIME_LIMIT' | translate
          }}{{ ' (hh:mm) : ' + upTimeLimit }}
        </p>
        <p role="heading" aria-level="3" *ngIf="showUpTimeLimit">
          {{ 'REMAINING_UPTIME' | translate }}
          {{ ' (hh:mm) : ' + remaining_UpTime }}
          <span id="vm-tooltip" class="info-tooltip" [ngClass]="{ timeReached: remaining_UpTime === '00:00' }"
            tooltip="{{ VMtooltipText | translate }}">
            <i class="fas fa-info-circle" aria-hidden="true"></i>
          </span>
        </p>
      </div>
    </span>
    <!--Refresh button-->
    <div id="refresh-all-btn" role="button" class="btn btn-sm ts-btn-fluent-outline refresh text-right ml-auto"
      tabindex="0" (click)="loadResources()" (keyup.enter)="loadResources()"
      attr.aria-label="{{ 'REFRESH_ALL_VM' | translate }}">
      <span style="font: normal normal normal 14px/1" class="fas fa-sync-alt vm-action-buttons mr-1 refresh-vm"
        [ngClass]="{ rotate: isVMLoading }">
        <span *ngIf="!docActions"> </span>
      </span>
      <span>{{ 'BUTTON_REFRESH' | translate }}</span>
    </div>
  </div>
  <div class="vm-group square-box">
    <!-- virtual box section -->
    <div class="portlet-body">
      <div class="table-responsive">
        <table aria-label="Lab Resource Table" class="table" tabindex="0">
          <tr>
            <th>{{ 'NAME' | translate }}</th>
            <th>{{ 'STATUS' | translate }}</th>
            <th class="hide-column">{{ 'DNS_NAME' | translate }}</th>
            <!-- <th *ngIf="attendeLabDetails.ComputeVMUsage">
                {{ "UP_TIME" | translate }}{{ " " }}(hh:mm)
              </th> -->
            <th *ngIf="attendeLabDetails.EnableResourceOperations">
              {{ 'ACTIONS' | translate }}
            </th>
          </tr>
          <tbody *ngIf="vmUsage">
            <tr *ngFor="let row of vmUsage.ResourceList">
              <ng-container *ngIf="!row.IsDynamicRG">
                <td>
                  {{ row.ResourceName }}
                </td>
                <td>
                  <span *ngIf="row.Status === '' || row.Status === null">{{
                    'UPDATING_STATUS' | translate
                    }}</span>
                  <span *ngIf="row.Status !== '' && row.Status !== null">{{
                    row.Status
                    }}</span>
                  <span *ngIf="
                      vmUsage.UptimeLimit != null &&
                      vmUsage.RemainingUptime != null &&
                      vmUsage.RemainingUptime === '00:00:00' &&
                      [
                        'Terminated',
                        'Terminating',
                        'VM deallocated',
                        'VM deallocating',
                        'Deallocating'
                      ].includes(row.Status)
                    ">-&nbsp;{{
                    'RUNTIME_LIMIT_EXHAUSTED_STATUS' | translate
                    }}</span>
                </td>

                <td *ngIf="!docActions" class="hide-column" style="word-break: break-word">
                  {{ row.DNSName }}
                </td>
                <!-- <td *ngIf="attendeLabDetails.ComputeVMUsage">
                  {{ getUpTime(row) }}
                </td> -->
                <td class="text-left vm-buttons" header-class="'text-left'"
                  *ngIf="attendeLabDetails.EnableResourceOperations">
                  <teams-button role="button" aria-label="Start" [tooltip]="'START' | translate" id="{{ 'START' | translate }}
                    {{ row.ResourceGroupName }}
                    {{ row.ResourceName }}" (buttonClick)="virtualMachineOperations(row, '7')" [classList]="
                      'btn btn-circle btn-icon-only btn-sm ts-btn-fluent-primary btn-primary mr-2 mb-2'
                    " [disabled]="
                      ['VM running', 'Running'].includes(row.Status) ||
                      (attendeLabDetails.ComputeVMUsage === true &&
                        vmUsage.UptimeLimit != null &&
                        vmUsage.RemainingUptime != null &&
                        vmUsage.RemainingUptime == '00:00:00')
                    ">
                    <i role="img" alt="" aria-label="img" aria-hidden="true" class="fa fa-play"></i>
                  </teams-button>
                  <teams-button role="button" aria-label="Restart" [tooltip]="'RESTART' | translate" id="{{ 'RESTART' | translate }}{{ row.ResourceGroupName }}{{
                      row.ResourceName
                    }}" (buttonClick)="virtualMachineOperations(row, '9')" [classList]="
                      'btn btn-circle btn-icon-only ts-btn-fluent-primary btn-primary btn-sm mr-2 mb-2'
                    " [disabled]="
                      !['VM running', 'Running'].includes(row.Status) ||
                      (row.Status !== null &&
                        attendeLabDetails.ComputeVMUsage === true &&
                        vmUsage.UptimeLimit !== null &&
                        vmUsage.RemainingUptime !== null &&
                        vmUsage.RemainingUptime == '00:00:00')
                    ">
                    <i role="img" alt="" aria-label="img" aria-hidden="true" class="fas fa-sync-alt"></i>
                  </teams-button>
                  <teams-button role="button" aria-label="Deallocate" [tooltip]="'DEALLOCATE' | translate" id="deallocate{{ row.ResourceGroupName }}{{
                      row.ResourceName
                    }}" (buttonClick)="virtualMachineOperations(row, '8')" [classList]="
                      'btn btn-circle btn-icon-only ts-btn-fluent-primary btn-primary btn-sm mr-2 mb-2'
                    " [disabled]="
                      [
                        'Terminated',
                        'Terminating',
                        'VM deallocated',
                        'VM deallocating'
                      ].includes(row.Status) ||
                      (row.Status !== null &&
                        attendeLabDetails.ComputeVMUsage === true &&
                        vmUsage.UptimeLimit !== null &&
                        vmUsage.RemainingUptime !== null &&
                        vmUsage.RemainingUptime === '00:00:00')
                    ">
                    <i role="img" alt="" aria-label="img" aria-hidden="true" class="fa fa-stop"></i>
                  </teams-button>
                  <!-- <teams-button role="button" aria-label="Launch Lab" tooltip="{{ 'LAUNCH_VM_IN_BROWSER' | translate }}"
                    (buttonClick)="openVMLaunchURL(row)"
                    [classList]="'btn btn-circle btn-icon-only ts-btn-fluent-primary btn-primary btn-sm mr-2 mb-2'"
                    *ngIf="row.VMLaunchURL && showVMLaunchButton" [disabled]="
                      !['VM running', 'Running'].includes(row.Status) ||
                      (row.Status !== null &&
                        attendeLabDetails.ComputeVMUsage === true &&
                        vmUsage.UptimeLimit !== null &&
                        vmUsage.RemainingUptime !== null &&
                        vmUsage.RemainingUptime === '00:00:00' )
                    ">
                    <i alt="" role="img" aria-label="img" aria-hidden="true" class="fas fa-external-link-alt"></i>
                  </teams-button> -->

                  <!-- <teams-button aria-label="Launch url" role="button"
                    tooltip="{{ 'GO_TO' | translate }} {{ row?.ResourceName }}" (buttonClick)="openVMandGitDoc(row)"
                    *ngIf="
                      showLaunchButton &&
                      vmUsage?.ResourceURI !== null &&
                      vmUsage?.ResourceURI !== '' &&
                      this?.internalId !== ''
                    " [disabled]="
                      row?.InternalId === this.internalId ||
                      !['VM running', 'Running'].includes(row.Status) ||
                      (row.Status !== null &&
                        attendeLabDetails.ComputeVMUsage === true &&
                        vmUsage.UptimeLimit !== null &&
                        vmUsage.RemainingUptime !== null &&
                        vmUsage.RemainingUptime === '00:00:00')
                    " [classList]="'btn btn-circle btn-icon-only ts-btn-fluent-primary btn-sm mr-2 mb-2 '">
                    <i alt="" role="img" aria-label="img" aria-hidden="true" class="fas fa-external-link-alt"></i>
                  </teams-button> -->
                </td>
              </ng-container>
            </tr>
          </tbody>
          <tr *ngIf="!isVMLoading && vmUsage?.ResourceType === null">
            <td colspan="3" translate="NO_RECORDS_FOUND" style="border-top: 0"></td>
          </tr>
        </table>
      </div>
    </div>
    <div class="modal" *ngIf="modelShow" [class.validation]="modelShow" id="validation" appTrapFocus>
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header">
            <h4 class="modal-title" aria-label="modal title">
              {{ modalData.title | translate }}
            </h4>
            <teams-button aria-label="Close" role="button" [classList]="'close'" (buttonClick)="modelShow = false">
              &times;
            </teams-button>
          </div>
          <div class="modal-body" aria-label="modal description">
            <p>
              {{ modalData.description | translate }}
            </p>
          </div>
          <div class="modal-footer">
            <teams-button aria-label="Confirm" (buttonClick)="modelShow = false">
              {{ 'OK' | translate }}
            </teams-button>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- VM labs section closed -->

  <!-- sql Pool section started -->
  <!-- <div id="sqlPool-section" class="square-box" *ngIf="showSqlPool">
    <div class="heading-box">
      <h3 class="heading">{{'SYNAPSE_SQL_POOL' |translate}}</h3>
      <p role="heading" aria-level="3" *ngIf="show_sqlPooltime">
        {{'MAXIMUM_UPTIME_LIMIT'|translate}}{{ " (hh:mm) : " + sqlPool_uptimeLimit }}
      </p>
      <p role="heading" aria-level="3" *ngIf="show_sqlPooltime">
        {{'REMAINING_UPTIME'|translate}} {{ " (hh:mm) : " + sqlPool_remainingTime }}
        <span id="sql-tooltip" class="info-tooltip" [ngClass]="{ timeReached: sqlPool_remainingTime === '00:00' }"
          tooltip="{{ SQLtooltipText | translate }}" tabindex="0" attr.aria-label="{{ SQLtooltipText | translate }}"><i
            class="fas fa-info-circle"></i></span>
      </p>
    </div>
    <div class="sql-group">
      <div class="portlet-body">
        <div class="table-responsive">
          <table aria-label="Sql Pool Table" class="table" tabindex="0">
            <tr>
              <th>{{ "NAME" | translate }}</th>
              <th>{{ "STATUS" | translate }}</th>
            </tr>
            <tr let *ngFor="let row of sqlResourceList">
              <td>
                {{ row.ResourceName }}
              </td>
              <td>
                <span *ngIf="row.Status === '' || row.Status === null">
                  {{ "UPDATING_STATUS" | translate }}
                </span>
                <span *ngIf="row.Status !== '' && row.Status !== null">
                  {{ row.Status }}
                </span>
              </td>
            </tr>
          </table>
        </div>
      </div>
    </div>
  </div> -->
  <!-- sql Pool section closed -->

  <!-- Section if no rsources are available -->
  <div id="noResources" class="square-box" *ngIf="!isVMLoading && !vmUsage">
    <p>{{ 'NO_ACTIVE_RESOURCES' | translate }}</p>
  </div>
</div>