export interface FormField {
  Id: number;
  FieldId: number;
  FieldName: string;
  EventId: number;
  IsVisible: boolean;
}

export enum FormFieldIds {
  FIRSTNAME = 100,
  LASTNAME = 101,
  EMAIL = 102,
  ORG = 103,
  COUNTRY = 104,
  JOBTITLE = 105,
  PHONE = 106,
  STATE = 107,
  CAPTCHA = 108
}

export enum AprovalField {
  AnonymousUsers = 1,
  SignupRequire = 2,
  EmailAproval = 3,
  InviteCode = 4,
  SignupEmailValid = 5,
  ActivationCode = 11,
  Checkvalidation = 12,
  AutoLaunch = 13,
  QueueSupport = 14,
  ODLRegisterationRequiredAutoLaunch = 15,
  ODLRegisterationActivationCodeRequiredAutoLaunch = 16
}
