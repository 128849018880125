import {
  AfterViewInit,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
} from '@angular/core';

@Component({
  selector: 'cloudlabs-dialoge-prompt',
  templateUrl: './dialoge-prompt.component.html',
  styleUrls: ['./dialoge-prompt.component.scss'],
})
export class DialogePromptComponent implements OnChanges, AfterViewInit {
  @Input() public headerText = '';
  @Input() public isDialog: boolean;
  @Input() public dialogClass: string;
  @Input() public closable = true;
  @Input() public popupWidth = '60vw';
  @Input() public popupHeight = 'auto';
  @Input() public isDraggable: boolean;
  @Input() baseZIndex = 10000;
  @Input() isTablePopup = false;

  @Output() private popupResponse = new EventEmitter();

  public openDialog = false;
  public headerValue: any;
  public headerClass = '';

  ngAfterViewInit(): void {
    const crossIcon = document.querySelector('.p-dialog-header-close');
    if (crossIcon) {
      crossIcon.removeAttribute('tabindex');
      crossIcon.setAttribute('aria-label', 'Close');
      crossIcon.classList.add('modal-close-button');
    }
    const list = document.getElementsByClassName(
      'p-dialog p-component p-dialog-resizable'
    );
    if (list.length > 0) {
      list[0].setAttribute('aria-describedby', 'dialog-title');
    }
  }

  ngOnChanges(): void {
    this.openPopup();
  }

  /**
   * @description: open and change dialog configuration
   */
  private openPopup(): void {
    this.headerValue = this.headerText;
    this.openDialog = this.isDialog;
    this.headerClass = this.dialogClass;
  }

  /**
   * @description: close Popup
   */
  public closePopup(): void {
    this.isDialog = false;
    this.popupResponse.emit(this.isDialog);
  }
}
