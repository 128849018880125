<cloudlabs-skip-to-main-content *ngIf="!isMylearning" [mainDiv]="'main-landmark'"></cloudlabs-skip-to-main-content>
<ng-container *ngIf="lab">
  <cloudlabs-refined-header *ngIf="environment.appType !== 'trial'"
    [customLogo]="lab.PartnerheaderLogo ? lab.PartnerheaderLogo : lab?.PartnerLogo" [lab]="lab"
    [labLaunchedState]="updatedLabStatus" [menu]="isHideMenu" [showTitle]="!lab?.BannerImage || isMylearning"
    [counter]="counter" [actionList]="actionList" (actionClickEvent)="actionClickEvent($event)"
    [attendeeEndDate]="endDateData" [modeOfOperation]="operationMode" [isMylearning]="isMylearning">
  </cloudlabs-refined-header>
  <ng-container *ngIf="!isMylearning">
    <!-- <cloudlabs-header *ngIf="enableHeader && environment.appType !== 'trial'" [lab]="lab"
      [customLogo]="lab.PartnerheaderLogo ? lab.PartnerheaderLogo : lab.PartnerLogo" [menu]="isHideMenu"
      (languageChangeEvent)="languageChanged($event)"></cloudlabs-header> -->

    <div *ngIf="[labModes.LAB, labModes.ANONYMOUS].includes(mode)" [class.sideNavBar]="isSidenav === true"
      [ngClass]="{'trial-header-space': environment.appType === 'trial'}">
      <nav class="fixed-top one" role="navigation" [attr.inert]='!isSidenav ? true: null' aria-label="Table of content">
        <div class="space-class">
          <ul class="nav nav-pills mb-5" [attr.aria-hidden]="isSidenav? false: true" id="pills-tab" role="tablist">
            <li class="nav-item" role="presentation">
              <a role="tab" [attr.aria-selected]="overViewSelected" [attr.tabindex]="isSidenav ? '0' : '-1'"
                class="bg-Hover" id="pills-overview-tab" data-toggle="pill" href="#pills-overview"
                aria-controls="pills-overview" (click)="setView('OVERVIEW')">{{ "OVERVIEW" | translate }}</a>
            </li>
            <li class="nav-item" *ngIf="!hidevideo" role="presentation">
              <a role="tab" [attr.aria-selected]="videoViewSelected" [attr.tabindex]="isSidenav ? '0' : '-1'"
                class="bg-Hover" id="pills-video-tab" data-toggle="pill" href="#pills-video" aria-controls="pills-video"
                (click)="setView('VIDEO')">{{"VIDEO" | translate}}</a>
            </li>
            <li class="nav-item" role="presentation">
              <a role="tab" [attr.aria-selected]="labViewSelected" [attr.tabindex]="isSidenav ? '0' : '-1'"
                class="bg-Hover active" id="pills-lab-tab" data-toggle="pill" href="#pills-lab" aria-controls="pills-lab"
                (click)="setView('LAB')">{{"LAB" | translate}}</a>
            </li>
          </ul>
          <teams-button [attr.tabindex]="isSidenav ? '0' : '-1'" [ariaLabel]="'Collapse'"
            [attr.inert]="!isSidenav? true : null" [ariaDescribedby]="'sideBarDescription'"
            [classList]="'btn closeSidenav'" (buttonClick)="closeSidenav()" style="width:95%; text-align:right;">
            <span aria-hidden="true">&#171;</span>
            {{"COLLAPSE" | translate}}</teams-button>
        </div>
      </nav>
      <div role="main">
        <div class="tab-content two">
          <div class="col-md-12" *ngIf=" environment?.appType === 'lab'"
            [ngClass]="{'main-title': lab?.BannerImage, 'main-title-no-banner': !lab?.BannerImage}"
            [ngStyle]="{'background-image': lab?.BannerImage ? 'url(\''+ lab?.BannerImage +'\'' : ''}">
            <div *ngIf="lab?.BannerImage">
              <h1>{{lab?.DisplayName || lab?.Title}}</h1>
            </div>
          </div>
        </div>
        <div style="display: none" id="sideBarDescription">
          <p *ngIf="isSidenav">{{'SIDEBAR_OPEN_MESSAGE' | translate}}</p>
          <p *ngIf="!isSidenav">{{'SIDEBAR_CLOSED_MESSAGE' | translate}}</p>
        </div>

        <div class="container-fluid">
          <div class="two row px-5 pt-1">

            <div class="col-md-12">
              <div class="tab-content pb-3" id="pills-tabContent">
                <div class="tab-pane fade show" id="pills-overview" role="tabpanel" aria-labelledby="pills-overview-tab">
                  <cloudlabs-lab-overview [lab]="lab"></cloudlabs-lab-overview>
                </div>
                <div *ngIf="lab.LinkToVideo" class="tab-pane fade show" id="pills-video" role="tabpanel"
                  aria-labelledby="pills-video-tab">
                  <cloudlabs-lab-video [lab]="lab" [view]="currentView"></cloudlabs-lab-video>
                </div>
                <div class="tab-pane fade show active" id="pills-lab" role="tabpanel" aria-labelledby="pills-lab-tab">
                  <cloudlabs-lab [lab]="lab" [voucherCode]="this.voucherCode" [mode]="mode"
                    (setCounter)="setCounter($event)" (menuHide)="isCompleteLab($event)" [actionEvent]="actionEvent"
                    [operationMode]="operationMode" (labStatus)="updateOperationMode($event)"
                    (labDeployedStatus)="updateLabStatus($event)" (extendLabAction)="extendLabAction($event)"
                    (setEndDate)="setEndDate($event)">
                  </cloudlabs-lab>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <cloudlabs-footer *ngIf="enableFooter && environment.appType !== 'trial'" class="appfooter" [labData]="lab">
    </cloudlabs-footer>
  </ng-container>
  <cloudlabs-lab *ngIf="isMylearning" [lab]="lab" [voucherCode]="this.voucherCode" [mode]="mode"
    (setCounter)="setCounter($event)" (menuHide)="isCompleteLab($event)" [operationMode]="operationMode"
    [actionEvent]="actionEvent" (labStatus)="updateOperationMode($event)" (labDeployedStatus)="updateLabStatus($event)"
    (extendLabAction)="extendLabAction($event)" (setEndDate)="setEndDate($event)">
  </cloudlabs-lab>
</ng-container>
