import {
    ChangeDetectionStrategy,
    Component,
    ElementRef,
    Input,
    OnDestroy,
    OnInit,
    Output,
    Renderer2,
    ViewChild,
    AfterViewChecked,
} from '@angular/core';
import { Client, Display, Keyboard, Mouse } from '@illgrenoble/guacamole-common-js';
import { BehaviorSubject, Subscription } from 'rxjs';

import { RemoteDesktopManager } from '../services';

@Component({
    // eslint-disable-next-line @angular-eslint/component-selector
    selector: 'ngx-remote-desktop-display',
    // eslint-disable-next-line @angular-eslint/no-host-metadata-property
    host: { class: 'ngx-remote-desktop-viewport' },
    template: `
        <div class="ngx-remote-desktop-display" id="vm-display" tabindex="-1" #display>
        </div>
    `,
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class DisplayComponent implements OnInit, OnDestroy, AfterViewChecked {

    /**
     * Emit the mouse move events to any subscribers
     */
    @Output()
    // eslint-disable-next-line @angular-eslint/no-output-on-prefix
    public onMouseMove = new BehaviorSubject(null);

    /**
     * Remote desktop manager
     */
    @Input()
    public manager: RemoteDesktopManager;

    @ViewChild('display')
     display: ElementRef;

    /**
     * Remote desktop keyboard
     */
    public keyboard: Keyboard;

    /**
     * Remote desktop mouse
     */
    public mouse: Mouse;

    /**
     * Subscriptions
     */
    public subscriptions: Subscription[] = [];

    constructor(public viewport: ElementRef, public renderer: Renderer2) {
    }

    /**
     * Create the display canvas when initialising the component
     */
    ngOnInit(): void {
       setTimeout(() => {
        this.createDisplayCanvas();
        this.bindSubscriptions();
       },100);

    }

    /**
     * Unbind all display input listeners when destroying the component
     */
    ngOnDestroy(): void {
        this.removeDisplay();
        this.removeDisplayInputListeners();
        this.unbindSubscriptions();
    }

    ngAfterViewChecked(): void {
        this.setDisplayScale();
    }

    /**
     * Bind all subscriptions
     */
    public bindSubscriptions(): void {
      if (this.manager) {
        this.subscriptions.push(this.manager.onKeyboardReset.subscribe(_ => this.resetKeyboard()));
        this.subscriptions.push(this.manager.onFocused.subscribe(this.handleFocused.bind(this)));
      }
    }

    /**
     * Unbind all subscriptions
     */
    public unbindSubscriptions(): void {
        this.subscriptions.forEach(subscription => subscription.unsubscribe());
    }

    /**
     * Bind input listeners if display is focused, otherwise, unbind
     */
    public handleFocused(newFocused: boolean): void {
        if (newFocused) {
            this.bindDisplayInputListeners();
        } else {
            this.removeDisplayInputListeners();
        }
    }

    /**
     * Release all the keyboards when the window loses focus
     * @param event
     */
    // @HostListener('window:blur', ['$event'])
    // public onWindowBlur(event: any): void {
    //     this.resetKeyboard();
    // }

    /**
     * Resize the display scale when the window is resized
     * @param event
     */
    // @HostListener('window:resize', ['$event'])
    // public onWindowResize(event: any): void {
    //     this.setDisplayScale();
    // }

    /**
     * Create the remote desktop display and bind the event handlers
     */
    public createDisplayCanvas(): void {
        this.createDisplay();
        this.createDisplayInputs();
        this.bindDisplayInputListeners();
    }

    /**
     * Get the remote desktop display and set the scale
     */
    public setDisplayScale() {
        const display = this.getDisplay();
        const scale = this.calculateDisplayScale(display);
        // display.scale(scale);
    }

    /**
     * Get the remote desktop display
     */
    public getDisplay(): Display {
        return this.manager && this.manager.getClient().getDisplay();
    }

    /**
     * Get the remote desktop client
     */
    public getClient(): Client {
        return this.manager &&this.manager.getClient();
    }

    /**
     * Calculate the scale for the display
     */
    public calculateDisplayScale(display: Display): number {
        const viewportElement = this.viewport.nativeElement;
        const scale = Math.min(viewportElement.clientWidth / display.getWidth(),
            viewportElement.clientHeight / display.getHeight());
        return scale;
    }

    /**
     * Assign the display to the client
     */
    public createDisplay(): void {
        const element = this.display.nativeElement;
        const display = this.getDisplay();
        this.renderer.appendChild(element, display.getElement());
    }

    /**
     * Remove the display
     */
    public removeDisplay(): void {
        const element = this.display.nativeElement;
        const display = this.getDisplay();
        this.renderer.removeChild(element, display.getElement());
    }

    /**
     * Bind input listeners for keyboard and mouse
     */
    public bindDisplayInputListeners(): void {
        this.removeDisplayInputListeners();
        this.mouse.onmousedown = this.mouse.onmouseup = this.mouse.onmousemove = this.handleMouseState.bind(this);
        this.keyboard.onkeyup = this.handleKeyUp.bind(this);
        this.keyboard.onkeydown = this.handleKeyDown.bind(this);
    }

    /**
     * Remove all input listeners
     */
    public removeDisplayInputListeners(): void {
        if (this.keyboard) {
            this.keyboard.onkeydown = null;
            this.keyboard.onkeyup = this.handleDummyKeyEvent.bind(this);
        }
        if (this.mouse) {
            this.mouse.onmousedown = this.mouse.onmouseup = this.mouse.onmousemove = null;
        }
    }

    /**
     * Create the keyboard and mouse inputs
     */
    public createDisplayInputs(): void {
        const display = this.display.nativeElement.children[0];
        this.mouse = new Mouse(display);
        this.keyboard = new Keyboard(window.document);
    }

    /**
     * Send mouse events to the remote desktop
     * @param mouseState
     */
    public handleMouseState(mouseState: any): void {
        const display = this.getDisplay();
        const scale = display.getScale();
        const scaledState = new Mouse.State(
            mouseState.x / scale,
            mouseState.y / scale,
            mouseState.left,
            mouseState.middle,
            mouseState.right,
            mouseState.up,
            mouseState.down);
        this.getClient().sendMouseState(scaledState);
        this.onMouseMove.next(mouseState);
    }

    /**
     * Resetting the keyboard will release all keys
     */
    public resetKeyboard(): void {
        if (this.keyboard) {
            this.keyboard.reset();
        }
    }

    /**
     * Send key down event to the remote desktop
     * @param key
     */
    public handleKeyDown(key: number): void {
        this.getClient().sendKeyEvent(1, key);
    }

    /**
     * Send key up event to the remote desktop
     * @param key
     */
    public handleKeyUp(key: number): void {
      this.getClient().sendKeyEvent(0, key);
    }

    private handleDummyKeyEvent() {
        return false;
    }
}
