import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';

@Component({
  selector: 'cloudlabs-survey-thankyou-dialog',
  templateUrl: './survey-thankyou-dialog.component.html',
  styleUrls: ['./survey-thankyou-dialog.component.scss']
})
export class SurveyThankyouDialogComponent implements OnInit {
  public title: string;
  public data: any;
  constructor(
    public modalRef: BsModalRef,
    private modalService: BsModalService,
    private route: Router
  ) { }

  ngOnInit(): void {
  }

  onConfirm(){
    this.modalRef.hide();
    this.route.navigate(['home'])
  }

}
