import { Injectable } from '@angular/core';
import {
  Router,
  CanActivate,
  ActivatedRouteSnapshot,
} from '@angular/router';
import { getEntity } from '@utility';


@Injectable({ providedIn: 'root' })
export class EntityAuthGuard implements CanActivate {

  constructor(private router: Router) {
  }


  canActivate(route: ActivatedRouteSnapshot) {
    let entity = JSON.parse(localStorage.getItem('entity'));
    entity = entity.filter(item => !!item);
    entity.forEach((_el, index) => {
        entity[index] = entity[index].replace(/ /g, '-').toLocaleLowerCase();
    });
    
    if (entity.includes(route.routeConfig.path)){
        return true;
    }
    const entityData = entity[0].replace(/ /g, '-').toLocaleLowerCase();
    this.router.navigate([`/${getEntity()}/${entityData}`]);
    return false;
  }

}
