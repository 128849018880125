import {Directive, ElementRef, HostListener, Input, OnInit} from '@angular/core';
import {element} from 'protractor';

@Directive({
  selector: '[appArrowKeysAccessible]'
})
export class ArrowKeysAccessibleDirective implements OnInit{

  @Input('appArrowKeysAccessible') givenClassName = 'appArrowKeysAccessible-tab';

  constructor(private el: ElementRef) { }

  @HostListener('keydown', ['$event']) onKeyDown($event: KeyboardEvent) {
    if ($event.key === 'ArrowRight') {
      this.focusNextElement();
    } else if ($event.key === 'ArrowLeft') {
      this.focusPreviousElement();
    }
  }

  ngOnInit(): void {
    if (!(this.el.nativeElement as HTMLElement).classList.contains(this.givenClassName)) {
      (this.el.nativeElement as HTMLElement).classList.add(this.givenClassName);
    }
  }
  focusNextElement() {
    const elArr = document.getElementsByClassName(this.givenClassName);
    const arr = Array.from(elArr);
    const onlyVisibleTabs = this.filterOutNotShowingTabs(arr as HTMLElement[]);
    const index = onlyVisibleTabs.indexOf(this.el.nativeElement);
    if (index >= onlyVisibleTabs.length - 1) {
      (onlyVisibleTabs[0] as HTMLElement)?.focus();
    } else {
      (onlyVisibleTabs[index + 1] as HTMLElement)?.focus();
    }
  }

  focusPreviousElement() {
    const elArr = document.getElementsByClassName(this.givenClassName);
    const arr = Array.from(elArr);
    const onlyVisibleTabs = this.filterOutNotShowingTabs(arr as HTMLElement[]);
    const index = onlyVisibleTabs.indexOf(this.el.nativeElement);
    if (index <= 0) {
      (onlyVisibleTabs[onlyVisibleTabs.length - 1] as HTMLElement)?.focus();
    } else {
      (onlyVisibleTabs[index - 1] as HTMLElement)?.focus();
    }
  }

  filterOutNotShowingTabs(arr: HTMLElement[]) {
    return arr.filter((elem) => {
      return (elem.parentElement as HTMLElement).style.display !== 'none';
    });
  }

}
