import { Component, Input, OnInit } from '@angular/core';

import { ResultHelperService } from '../../../services/result-helper.service';

@Component({
  selector: 'match-options-result',
  templateUrl: './match-options-result.component.html'
})
export class MatchOptionsResultComponent implements OnInit {
  @Input() public question;
  @Input() public index;
  public options: Array<any> = [];
  public questionLabels: any = [];

  constructor(private resultHelper: ResultHelperService) { }

  ngOnInit(): void {
    this.onLoadData();
  }
  /**
   * @description: Load Data First Time
   */
  private onLoadData(): void {
    if (this.question !== undefined) {
      this.question.labels.forEach((element) => {
        element.option = null;
        element.answer = 0;
      });
      this.options = this.question.options;
      this.options.forEach((element) => {
        element.disabled = false;
      });
    }
    if (this.question.userAnswers.length > 0) {
      this.userSelectedAnswers();
    }
    if (this.question.userAnswers.length === 0) {
      this.loadAnswers();
    }
  }
  /**
   * @description: Load User Selected Answers
   */
  private userSelectedAnswers(): any {
    this.questionLabels = this.resultHelper.userSelectedMatchOptionAnswers(this.question, this.questionLabels).questionLabelsValue;
  }
  /**
   * @description: Load Answers
   */
  private loadAnswers(): any {
    this.questionLabels = this.resultHelper.loadMatchOperationAnswers(this.questionLabels, this.question).questionLabelValue;
  }
}
