import { Component, EventEmitter, Input, OnInit, Output, OnChanges } from '@angular/core';
import { Subscription } from 'rxjs';
import { ActivatedRoute, NavigationExtras, Router } from '@angular/router';
import * as _ from 'lodash';
import { DeviceDetectorService } from 'ngx-device-detector';
import { TranslateService } from '@ngx-translate/core';

import { LoadingService, miscellaneousConst, formatter, isExternalFlow, getEntity, getEntityData } from '@utility';

import {
  TestDetailsModel,
  QuestionModel,
  SectionModel,
} from '../../model/common.model';

import { CommonService } from '../../services/common-service.service';
import { AuthService, EventEmitterService, UserSessionService } from '@teams-auth';
import { LocalSessionStorageService } from '../../services/local-session-storage.service';


@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'app-footer',
  templateUrl: './footer.component.html',
})
export class FooterComponent implements OnInit {
  private subscriptions: Subscription[] = [];
  public testDetails: TestDetailsModel;
  public loaderMsg = [];
  private regUniqueName = '';
  public viewInstruction = false;
  public miscellaneousConst = miscellaneousConst;
  public cloudlabs: string;
  public sideNav: boolean;
  @Input() examId: string;
  @Input() question: QuestionModel;
  @Input() sectionList: SectionModel[] = [];
  @Input() selectedIndex: number;
  @Input() questionList: QuestionModel[] = [];
  @Input() sectionId: any;
  @Input() examInstanceId: any;
  @Input() sectionIndex: number;
  @Input() stopWatch: number;
  @Input() progressChangeValue: number;
  @Input() finishTestConfimDialoge: any;
  @Input() sectionType: any;
  @Input() leftSectionCount: number;
  @Output() progressChangeValueEmit = new EventEmitter();
  @Output() selectedIndexEmit = new EventEmitter();
  @Output() questionEmit = new EventEmitter();
  @Output() viewInstructionEmit = new EventEmitter();
  @Output() finishTestConfimDialogeEmit = new EventEmitter();

  constructor(
    private eventEmitter: EventEmitterService,
    private commonService: CommonService,
    private route: ActivatedRoute,
    private router: Router,
    public deviceDetectorService: DeviceDetectorService,
    private readonly translateService: TranslateService,
    public auth: AuthService,
    public userSessionService: UserSessionService,
    private localStorageService: LocalSessionStorageService,
    private loadingService: LoadingService
  ) {
    this.route.queryParams.subscribe((params) => {
      this.regUniqueName = params.regUniqueName;
    });
  }

  ngOnInit(): void {
    this.cloudlabs = localStorage.getItem('context');
    this.userSessionService.sideNavigation$.subscribe((e) => {
      this.sideNav = e;
    })
  }
  /**
   * @description: Logic Of Reset Question
   */
  public getResetQuestion(): void {
    this.question.userAnswers = [];
    if(this.question?.isVersioned) {
      this.question?.questionVersions.forEach((element: any) => {
        element.userAnswers = []
      });
    }
    const model = {
      elapsedTime: formatter(this.stopWatch),
      ReviewLater: true,
      UserAnswer: this.question.userAnswers,
    };
    this.subscriptions[this.subscriptions.length] = this.commonService
      .updateExamQuestionInstance(this.question.questionUniqueName, model)
      .subscribe(
        (res) => {
          this.userSessionService.isReset$.next(true);
        },
        (error) => {
          const errorMessage: string = error.error.message;

          if (!errorMessage.startsWith(miscellaneousConst.elapsedTime)) {
            const code = 'Error - 30015';
            this.eventEmitter.debugAlert(code, error.error.message);
          }
        }
      );
  }
  /**
   * @description: Check View Instruction or not
   */
  public getInstruction(): void {
    this.viewInstruction = true;
  }
  /**
   * @description: Check Is Section Instruction or not
   */
  public getIsSectionInstruction(): any {
    if (this.sectionList.length > 0) {
      const sectionInstruction = this.sectionList.filter(
        (i) => i.instruction !== null
      );
      if (sectionInstruction.length > 0) {
        return true;
      } else {
        return false;
      }
    } else {
      return false;
    }
  }
  /**
   * @description: Logic Of Previous Question
   */
  public getPreviousQuestion(): void {
    const el = document.getElementById('main-questions')
    el?.animate({ scrollTop: 0 });
    el?.focus();
    if (this.selectedIndex > 0) {
      const ind = --this.selectedIndex;
      this.selectedIndexEmit.emit(ind);
      if (this.selectedIndex !== -1) {
        this.loadingService.loadingOn();
        this.question = this.findQuestion(
          this.questionList[ind].questionUniqueName
        );
        const totalQuestion = 100 / this.questionList.length;
        const progressCalculate = this.progressChangeValue - totalQuestion;
        this.progressChangeValue = progressCalculate;
        this.progressChangeValueEmit.emit(this.progressChangeValue);
        this.questionEmit.emit(this.question);
          this.loadingService.loadingOff();
      }
    }
  }

  finishTestDialoge(){
    this.commonService.onFinishTest();
  }
  /**
   * @description: Check Is Section Review or not
   */
  public getIsSectionReview(): any {
    if (this.sectionList.length > 0) {
      const sectionReviewAllowed = this.sectionList.find(
        (i) =>
          i.examSectionUniqueName === this.sectionId && i.reviewAllowed === true
      );
      return !_.isNil(sectionReviewAllowed);
    } else {
      return false;
    }
  }
  /**
   * @description: Logic Of Finish Section
   */

  public getFinishSection(): void {
    const navigationExtras: NavigationExtras = {
      queryParams: { regUniqueName: this.regUniqueName },
    };
    const nextSectionId = this.sectionList.findIndex(
      (i) => i.examSectionUniqueName === this.sectionId
    );
    // this.sectionIndex = ++nextSectionId;

    if (isExternalFlow(this.regUniqueName) && this.sectionList.length > 1) {
      if (localStorage.getItem('context') === 'CloudLabs') {
        if (this.localStorageService.getCourseStorage() === 'false') {
          this.router.navigate(
            [
              `/${getEntity()}/${miscellaneousConst.navigation.practiceTests}/${miscellaneousConst.navigation.tests}/${this.examId}/${miscellaneousConst.navigation.testInstance}/${this.examInstanceId}/${miscellaneousConst.navigation.section}/${this.sectionList[nextSectionId].examSectionUniqueName}/${miscellaneousConst.navigation.review}`,
            ],
            navigationExtras
          );
        } else if (this.localStorageService.getCourseStorage() === 'true') {
          this.router.navigate(
            [
              `/${getEntity()}/${getEntityData()}/${miscellaneousConst.navigation.test}/${miscellaneousConst.navigation.practiceTest}/${miscellaneousConst.navigation.tests}/${this.examId}/${miscellaneousConst.navigation.testInstance}/${this.examInstanceId}/${miscellaneousConst.navigation.section}/${this.sectionList[nextSectionId].examSectionUniqueName}/${miscellaneousConst.navigation.review}`,
            ],
            navigationExtras
          );
        }
      } else if (localStorage.getItem('context') === 'PracticeTest') {
        this.router.navigate(
          [
            `/${miscellaneousConst.navigation.tests}/${this.examId}/${miscellaneousConst.navigation.testInstance}/${this.examInstanceId}/${miscellaneousConst.navigation.section}/${this.sectionList[nextSectionId].examSectionUniqueName}/${miscellaneousConst.navigation.review}`,
          ],
          navigationExtras
        );
      }
    } else if (
      this.sectionList.length > 1 &&
      this.sectionIndex !== this.sectionList.length
    ) {
      if (localStorage.getItem('context') === 'CloudLabs') {
        if (this.localStorageService.getCourseStorage() === 'false') {
          this.router.navigate([
            `/${getEntity()}/${miscellaneousConst.navigation.practiceTests}/${miscellaneousConst.navigation.tests}/${this.examId}/${miscellaneousConst.navigation.testInstance}/${this.examInstanceId}/${miscellaneousConst.navigation.section}/${this.sectionList[nextSectionId].examSectionUniqueName}/${miscellaneousConst.navigation.review}`,
          ]);
        }
        else if (this.localStorageService.getCourseStorage() === 'true') {
          this.router.navigate([
            `/${getEntity()}/${getEntityData()}/${miscellaneousConst.navigation.test}/${miscellaneousConst.navigation.practiceTest}/${miscellaneousConst.navigation.tests}/${this.examId}/${miscellaneousConst.navigation.testInstance}/${this.examInstanceId}/${miscellaneousConst.navigation.section}/${this.sectionList[nextSectionId].examSectionUniqueName}/${miscellaneousConst.navigation.review}`,
          ]);
        }
        
      } else if (localStorage.getItem('context') === 'PracticeTest') {
        this.router.navigate([
          `/${miscellaneousConst.navigation.tests}/${this.examId}/${miscellaneousConst.navigation.testInstance}/${this.examInstanceId}/${miscellaneousConst.navigation.section}/${this.sectionList[nextSectionId].examSectionUniqueName}/${miscellaneousConst.navigation.review}`,
        ]);
      }
    } else {
      if (isExternalFlow(this.regUniqueName)) {
        if (localStorage.getItem('context') === 'PracticeTest') {
          this.router.navigate([
            `${miscellaneousConst.navigation.tests}/${this.examId}/${miscellaneousConst.navigation.testInstance}/${this.examInstanceId}/${miscellaneousConst.navigation.section}/${this.sectionList[nextSectionId].examSectionUniqueName}/${miscellaneousConst.navigation.review}`,
          ],
            navigationExtras
          );
      }
      } else
      if (localStorage.getItem('context') === 'CloudLabs') {
        if (this.localStorageService.getCourseStorage() === 'false') {
          this.router.navigate([
            `/${getEntity()}/${miscellaneousConst.navigation.practiceTests}/${miscellaneousConst.navigation.tests}/${this.examId}/${miscellaneousConst.navigation.testInstance}/${this.examInstanceId}/${miscellaneousConst.navigation.section}/${this.sectionList[nextSectionId].examSectionUniqueName}/${miscellaneousConst.navigation.review}`,
          ]);
        }
        else if (this.localStorageService.getCourseStorage() === 'true') {
          this.router.navigate([
            `/${getEntity()}/${getEntityData()}/${miscellaneousConst.navigation.test}/${miscellaneousConst.navigation.practiceTest}/${miscellaneousConst.navigation.tests}/${this.examId}/${miscellaneousConst.navigation.testInstance}/${this.examInstanceId}/${miscellaneousConst.navigation.section}/${this.sectionList[nextSectionId].examSectionUniqueName}/${miscellaneousConst.navigation.review}`,
          ]);
        }
      } 
      else if (localStorage.getItem('context') === 'PracticeTest') {
        this.router.navigate([
          `${miscellaneousConst.navigation.tests}/${this.examId}/${miscellaneousConst.navigation.testInstance}/${this.examInstanceId}/${miscellaneousConst.navigation.section}/${this.sectionList[nextSectionId].examSectionUniqueName}/${miscellaneousConst.navigation.review}`,
        ]);
      }
    }
  }
  /**
   * @description: Logic Of Next Question
   */
  public getNextQuestion(): void {
    const el = document.getElementById('main-questions')
    el?.animate({ scrollTop: 0 });
    el?.focus();
    if (this.selectedIndex !== this.questionList.length) {
      const ind = ++this.selectedIndex;
      this.selectedIndexEmit.emit(ind);
      if (this.selectedIndex !== -1) {
        this.loaderMsg = ['common.spinner.nextQuest'];
        this.loadingService.loadingOn(this.loaderMsg);
        this.subscriptions[this.subscriptions.length] = this.commonService
          .updateExamQuestionProgress(this.question.questionUniqueName)
          .subscribe(
            (res) => {
              this.question = this.findQuestion(
                this.questionList[ind].questionUniqueName
              );
              const totalQuestion = 100 / this.questionList.length;
              const progressCalculate =
                this.progressChangeValue + totalQuestion;
              this.progressChangeValue = progressCalculate;
              this.progressChangeValueEmit.emit(this.progressChangeValue);
              this.questionEmit.emit(this.question);
                this.loadingService.loadingOff();
            },
            (error) => {
              const errorMessage: string = error.error.message;

              if (!errorMessage.startsWith(miscellaneousConst.elapsedTime)) {
                const code = 'Error - 30016';
                this.eventEmitter.debugAlert(code, error.error.message);
              }
              this.loadingService.loadingOff()
              const code = 'Error - 30016';
              this.eventEmitter.debugAlert(code, error.error);
            }
          );
      }
    }
  }
  /**
   * @description: Logic Of Find Question
   */
  private findQuestion(questionId: any): any {
    const getQuestion = this.questionList.find(
      (i) => i.questionUniqueName === questionId
    );
    return getQuestion;
  }
  /**
   * @description: Emitted Question Value
   */
  public getQuestions(e): any {
    this.question = e;
  }
  /**
   * @description: Emitted Is View Instruction
   */
  public isInstruction(e): any {
    this.viewInstruction = e;
  }

  /**
   * @description: Close Help
   */
  public closeHelpPopup(event): any {
    this.viewInstruction = event;
    this.viewInstructionEmit.emit(this.viewInstruction);
  }
}
