import {Component, OnInit, Input, ViewChild, OnChanges, SimpleChanges, Inject} from '@angular/core';
import { Lab } from '../../../../modals/lab.model';
import {LabService} from '../../../../services/lab.service';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { DOCUMENT } from '@angular/common';

@Component({
  selector: 'cloudlabs-lab-video',
  templateUrl: './lab-video.component.html',
  styleUrls: ['./lab-video.component.scss']
})
export class LabVideoComponent implements OnInit, OnChanges {
  @Input() lab: Lab;
  @Input() anoymous = false;
  @Input() view: string;
  videoUrl: string = null;
  safeUrl: SafeResourceUrl;

  constructor(private labService: LabService, private sanitiser: DomSanitizer, @Inject(DOCUMENT) private document) { }

  ngOnInit(): void {
    if (this.lab.LinkToVideo && this.lab.LinkToVideo !== null && this.lab.LinkToVideo !== '') {
      if (this.lab.LinkToVideo.includes('vimeo')) {
        const videoId = this.labService.getVideoId(this.lab.LinkToVideo);
        this.videoUrl = `https://player.vimeo.com/video/${videoId}`;
      } else if (this.lab.LinkToVideo.includes('youtube')) {
        const videoId = this.labService.getVideoId(this.lab.LinkToVideo);
        this.videoUrl = `https://www.youtube.com/embed/${videoId}?rel=0&controls=0&hd=1&showinfo=0&enablejsapi=1`;
      }
      this.safeUrl = this.sanitiser.bypassSecurityTrustResourceUrl(this.videoUrl);
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.view?.currentValue !== 'VIDEO') {
      this.document.getElementById('video').contentWindow.postMessage('{"event":"command","func":"' + 'pauseVideo' + '","args":""}', '*');
    }
  }
}
