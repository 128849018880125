import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'cloudlabs-toggle-dropdown',
  templateUrl: './toggle-dropdown.component.html',
  styleUrls: ['./toggle-dropdown.component.scss']
})
export class ToggleDropdownComponent implements OnInit {
  
  public show = [];
  // private activeIndex = null;
  @Input() i ;
  @Output() toggleClick = new EventEmitter();
  @Input() activeIndex ;
  @Input() show_val ;
  @Output() activeClick = new EventEmitter();
  constructor() { }
  
  ngOnInit(): void { }

  toggle(key): void {
    if(this.activeIndex != null){
      this.show[this.activeIndex]= false
    }
    if(this.activeIndex == key){
      this.show[this.activeIndex]= false
      this.activeIndex = null
      this.show = []
    }else{
      this.activeIndex = key;
      this.show[key] = true;
    }
    this.toggleClick.emit(this.show);
    this.activeClick.emit(this.activeIndex);
  }
}
