import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

import { TabModel } from '@libs/questions-ui/src/lib/model/common.model';

@Component({
  selector: 'app-case-study',
  templateUrl: './case-study.component.html',
})
export class CaseStudyComponent implements OnInit {
  @Input() public tabs: TabModel[] = [];
  @Output() sideMenuOptionChange = new EventEmitter();
  @Input() showQuestions: boolean;
  public active: boolean;
  public index = 0;
  public activeTitle = '';
  public activeContent = '';
  constructor() {}

  ngOnInit(): void {}
  
  public getTabIndex(event): void {
    this.index = event.index;
    this.activeContent = event.tabs[this.index]?.content;
    this.activeTitle = event.tabs[this.index]?.title;
    this.active = event.tabs[this.index]?.active;
  }
  /**
   * @description: Emit User Selectd Answers
   */
  public sectionQuesion(event): void {
    this.showQuestions = event.que;
    const tabTitle = event.title;
    this.sideMenuOptionChange.emit({
      que: this.showQuestions,
      title: tabTitle,
    });
  }
}
