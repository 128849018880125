<p-dropdown [options]="optionArray" styleClass="primeng-primary-style-dropdown"
    [placeholder]="defaultLabel !== ''? defaultLabel: 'select value' " optionValue="value" optionLabel="label"
    [filter]="filter" filterBy="label" [(ngModel)]="selectedValue" resetFilterOnHide="true"
    (onChange)="updateChanges($event.value)" (onBlur)="onTouched()">

    <ng-template pTemplate="selectedItem">
        <div *ngIf="selectedValue || selectedValue !== ''">
            <div>{{selectedValue}}</div>
        </div>
    </ng-template>

    <ng-template let-option pTemplate="item">
        <div class="option-item">
            <div class="selection-color">{{option.label}}</div>
        </div>
    </ng-template>
</p-dropdown>