import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'teams-tabs-panel',
  templateUrl: './tabs-panel.component.html',
  styleUrls: ['./tabs-panel.component.scss'],
})
export class TabsPanelComponent implements OnInit {
  @Input() tabs: Array<{ id: number | string; title: string }> = [];
  @Input() selectedTab: string | number;
  @Output() currentTab = new EventEmitter();

  ngOnInit(): void {}
  onSelectTab(tab: number | string) {
    this.selectedTab = tab;
    this.currentTab.emit(tab);
  }
}
