<div role="dialog"  [attr.aria-label]="title | dialogRole">
  <div class="modal-content" appTrapFocus appRestartDialog appRefocusPreviousEl >
  <div class="modal-header" >
      <h4 class="modal-title" id="vm-dialog-head"> {{ title }} </h4>
<!--          <button aria-label="Close" type="button" class="close modal-close-button" (click)="modulemodelShow = false">&times;</button>-->
    </div>
    <div class="modal-body">
        {{data.description}}
    </div>
    <div class="modal-footer">
      <teams-button [classList]="'btn ts-btn-fluent-white modal-close-button btn-secondary'" (buttonClick)="modalRef.hide(); onCancel()" [title]="data.failureButtonText"></teams-button>
      <teams-button appRemoveFocusOkButton aria-hidden="true" [classList]="'btn ts-btn-fluent-primary modal-ok-button'" (buttonClick)="onConfirm()" [title]="data.successButtonText"></teams-button>
    </div>
  </div>
</div>
