import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'cloudlabs-performanc-task-result',
  templateUrl: './performanc-task-result.component.html'
})
export class PerformancTaskResultComponent implements OnInit {

  @Input() question;
  @Input() examId;
  @Input() index;
  constructor() { }

  ngOnInit(): void {
  }

}
