import { Component, EventEmitter, Input, Output, OnChanges, ChangeDetectorRef } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { UserSessionService } from '@teams-auth';
import { QuestionModel } from '../../model/common.model';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'app-question',
  templateUrl: './question.component.html',
})
export class QuestionComponent implements OnChanges {
  @Input() public question: QuestionModel;
  @Input() public examId;
  @Input() userAnswer = [];
  @Input() sidemenuSelectedOption;
  @Input() questionList;
  @Input() sectionType = '';
  @Input() isInlineQuestion = false;
  @Input() isCorrectAnswer;
  @Input() inlineQuestionData: QuestionModel
  @Input() groupQuestRemainingRetries;
  @Input() questionStyle;
  @Input() isRetryClicked;
  @Input() isSubmitClicked;
  @Output() UserSelectAnswerOption = new EventEmitter();
  public regUniqueName: '';
  public selectedVersionLang: string;
  public versionKey: string;
  public displayCaption: string;
  @Output() VersionKey = new EventEmitter();

  constructor( private route: ActivatedRoute,
    private cdr: ChangeDetectorRef,
    private userSessionService: UserSessionService,
    ) { 
    this.route.queryParams.subscribe((params) => {
      this.regUniqueName = params.regUniqueName;
    });
  }


  /**
   * @description: Change Question Value
   */

  
  ngOnChanges(): void {
    if (this.versionKey && this.question?.isVersioned && this.question.userAnswers.length === 0) {
      this.saveVersionPreference()
    }
    if (this.question) {
      this.question.userAnswers = this.userAnswer;
      this.versionKey = this.question?.versionKey
      this.VersionKey.emit(this.versionKey)
    }
    this.selectedVersionLang = this.question?.versionDisplayText
    this.displayCaption = this.question?.versionDisplayCaption
    this.cdr.detectChanges()
    // TODO - commenting for now, need to check later
    // if (!this.question || this.question.length === 0) {
    //   const code = 'Error - 10021';
    //   this.eventEmitter.debugAlert(code, 'Error - Question List is empty!');
    // }
  }

  /**
   * @description: Save Version Preference For Language
   */

  private saveVersionPreference() {
    this.question.questionVersions.forEach((element: QuestionModel) => {
      if (element?.versionKey === this.versionKey) {
        this.setVersions(element)
      }
    });
  }

  /**
   * @description: Emit User Selectd Answers
   */
  public userSelectAnswerOption(event): void {
      this.UserSelectAnswerOption.emit(this.question);
  }

  public setVersions(event): void {
    this.question.options = event?.options
    this.question.question = event?.question
    this.question.note = event?.note
    this.question.userAnswers = event?.userAnswers
    this.selectedVersionLang = event?.versionDisplayText
    this.question.instruction = event?.instruction
    this.question.labels = event?.labels
    this.userSessionService.isReset$.next(true);
    this.versionKey = event?.versionKey
    this.question.versionKey = event?.versionKey
    this.question.versionDisplayText = event?.versionDisplayText
    this.VersionKey.emit(this.versionKey)
    this.question.versionDisplayCaption = event?.versionDisplayCaption
    this.displayCaption = event?.versionDisplayCaption
    this.question.questionUniqueName = event?.questionUniqueName
    this.cdr.detectChanges()
  }
}
