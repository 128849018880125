import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AttendeeLabResolver } from '../../resolver/attende-lab.resolver';
import { EnvironmentResolver } from '../../resolver/environment.resolver';
import { VmurlResolver } from '../../resolver/vmurl.resolver';
import { LabResolver } from '../../resolver/lab-resolver';
import { EnvironmentComponent } from './components/environment/environment.component';

const routes: Routes = [
  {
    path: '',
    component: EnvironmentComponent,
    resolve: {
      lab: LabResolver,
      labattendee : AttendeeLabResolver,
      environment : EnvironmentResolver,
      Vmurl : VmurlResolver
    },
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class LabenvironmentRoutingModule { }
