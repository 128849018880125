import { Component, Input } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

/**
 * Message component for showing error or success messages for when the connection
 * state changes
 */
@Component({
    selector: 'ngx-remote-desktop-message',
    template: `
        <div class="ngx-remote-desktop-message">
            <div class="ngx-remote-desktop-message-title"
                [class.ngx-remote-desktop-message-title-success]="type === 'success'"
                [class.ngx-remote-desktop-message-title-error]="type === 'error'">
                <div class="alert col-sm-10 col-md-9 col-lg-6 mx-auto mt-5" tabindex="0"
                [ngClass]="{
                    'alert-custom alert-light-primary' : isMylearning,
                    'alert-info' : !isMylearning
                  }">
                    <div appVmRole>
                        <div role="alert" aria-live="assertive" aria-atomic="true"  [attr.aria-label]="title">
                            <h4>{{ title }}</h4>
                        </div>
                        <p *ngIf="message" [innerHTML]="message"></p>
                    </div>
                </div>
            </div>
            <div class="ngx-remote-desktop-message-body text-center">
                <ng-content></ng-content>
            </div>
        </div>
    `
})
export class MessageComponent {

    /**
     * Title of the message to display
     */
    @Input()
    public title: string;
    /**
     * Content of the message to display
     */
    @Input()
    public message: string;

    /**
     * Message type. Can be 'success' or 'error'
     */
    @Input()
    public type = 'success';

    /*
    *Check it's CL or not 
    */
    @Input()
    public isMylearning;
}
