import { Component, OnInit } from '@angular/core';
import { LabService } from '../../../services/lab.service';
import { TranslateService } from '@ngx-translate/core';
import { NotificationService } from '@utility';
import { BsModalRef } from 'ngx-bootstrap/modal';
import {
  ExtendCreditStatus,
  ExtendRequestStatusObject,
} from '../../../modals/lab.model';

@Component({
  selector: 'cloudlabs-extend-credit',
  templateUrl: './extend-credit.component.html',
  styleUrls: ['./extend-credit.component.scss'],
})
export class ExtendCreditComponent implements OnInit {
  public title: string;
  public data: any;
  public loading: boolean;
  public extendCreditStauts = ExtendCreditStatus;
  public statusDetails: ExtendRequestStatusObject;

  constructor(
    public modalRef: BsModalRef,
    public labSrv: LabService,
    public translateSrv: TranslateService,
    private notificationService: NotificationService
  ) {
    this.loading = false;
  }

  ngOnInit(): void {
    if (this.data?.approvalRequired) {
      this.loading = true;
      this.labSrv
        .getRequestStatus({
          voucherCode: this.data?.v_code,
          requestType: 'credit',
        })
        .subscribe(
          (result) => {
            this.statusDetails = result;
            this.loading = false;
          },
          () => {
            this.loading = false;
          }
        );
    }
  }

  public onConfirm() {
    this.modalRef.hide();
    this.labSrv.extendCredit({ voucherCode: this.data?.v_code }).subscribe(
      (result) => {
        if (this.data?.approvalRequired) {
          if (result.Status === 'Error') {
            if (result.ErrorKey === 'MAX_LIMIT_REACHED') {
              this.notificationService.error(
                this.translateSrv.instant('MAX_LIMIT_REACHED', {
                  type: this.translateSrv.instant('CREDIT'),
                  unit: this.data?.maxUnit,
                }),
                ''
              );
            } else {
              this.notificationService.error(result.ErrorMessage, '');
            }
          } else {
            this.notificationService.success(
              this.translateSrv.instant('EXT_CREDIT_SUCC', {
                type: this.translateSrv.instant('CREDIT'),
              }),
              ''
            );
          }
        } else {
          if (result.Status === 'Error') {
            if (result.ErrorKey === 'MAX_LIMIT_REACHED') {
              this.notificationService.error(
                this.translateSrv.instant('MAX_LIMIT_REACHED', {
                  type: this.translateSrv.instant('CREDIT'),
                  unit: this.data?.maxUnit,
                }),
                ''
              );
            } else {
              this.notificationService.error(result.ErrorMessage, '');
            }
          } else {
            this.notificationService.success(
              this.translateSrv.instant('EXTEND_LAB_DURATION_SUCCESS_MESSAGE', {
                type: this.translateSrv.instant('CREDIT'),
              }),
              ''
            );
          }
        }
      },
      () => {
        if (this.data?.approvalRequired) {
          this.notificationService.error(
            this.translateSrv.instant('EXT_CREDIT_FAIL', {
              type: this.translateSrv.instant('CREDIT'),
            }),
            ''
          );
        } else {
          this.notificationService.error(
            this.translateSrv.instant('EXTEND_LAB_DURATION_FAILURE_MESSAGE', {
              type: this.translateSrv.instant('CREDIT'),
            }),
            ''
          );
        }
      }
    );
  }
}
