import { Component, ElementRef, Input, OnInit } from '@angular/core';
import { GithubDataService } from '../services/github-data.service';
declare var $: any;
@Component({
  selector: 'cloudlabs-markdown',
  templateUrl: './mark-down.component.html'
})
export class MarkDownComponent implements OnInit {

  @Input() public readMeData: any;
  constructor( ) { }

  ngOnInit(): void {
    
  }

  ngAfterViewInit() {
    $('.selfnavigation').on('click', function (this: any) {
      const id = $(this).data('id');
      const elementID = id.replace('#', '');
      const el = document.getElementById(elementID);
      el.scrollIntoView();
    })
  }

  ngOnChanges(): void {
  }
}
