import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ButtonLoadingService {
  private loadButton = false;
  private loadText = '';
  public indexValue: string | number = '';

  public setLoding(val, text) {
    this.loadButton = val;
    this.loadText = text;
  }

  public getLoding() {
    return this.loadButton;
  }

  public getButtonText(){
    return this.loadText;
  }

  public buttonLodingOff() {
    this.loadButton = false;
    this.loadText = '';
    this.indexValue = '';
  }
}
