<div>
        <!--No question title for number type questions | No question and answer headings | Sheikh-->
        <ul class="singlequestion-list">
        <li>
            <div class="sql-ques case-study-sql-ques">
                <span *ngIf="question?.note !== null"><strong>{{ 'exam.questions.note' | translate }}: </strong> <span [innerHTML]="question?.note"></span></span>
                <span *ngIf="question?.instruction !== null"><strong>{{ 'exam.questions.instruction' | translate }}:</strong> <span
            [innerHTML]="question?.instruction"></span></span>
            </div>
            <div>
                <div [innerHTML]="numericInput"></div>

            </div>
        </li>
    </ul>
</div>