import { Directive, ElementRef, Input, OnInit } from '@angular/core';

@Directive({
  selector: '[cloudlabsProgressBarArialabel]'
})
export class ProgressBarArialabelDirective implements OnInit {
  @Input("cloudlabsProgressBarArialabel") entityName: any;

  constructor(
    private el: ElementRef
  ) { 
  }

  ngOnInit() {
    this.setAttribute()
  }

  setAttribute(): void {
    setTimeout(()=> {
      if (this.entityName !== undefined) {
        (this.el?.nativeElement?.children[0] as HTMLElement)?.setAttribute('aria-label', this.entityName)
      }
    }, 1000)
  }

}
