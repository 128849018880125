import {AfterViewInit, Directive, ElementRef} from '@angular/core';

@Directive({
  // eslint-disable-next-line @angular-eslint/directive-selector
  selector: '[appAutoFocusModal]'
})
export class AutoFocusModalDirective implements AfterViewInit{
  private static readonly FOCUS_MODAL_AFTER_MILLISECONDS = 250;


  constructor(private el: ElementRef) { }

  ngAfterViewInit() {
    let elements = (this.el.nativeElement as HTMLElement).querySelectorAll('.modal-input');
    if (elements.length > 0) {
      this.focusFirstElement(elements);
    } else {
      elements = (this.el.nativeElement as HTMLElement).querySelectorAll('.modal-ok-button');
      if (elements.length > 0) {
        this.focusFirstElement(elements);
      } else {
        elements = (this.el.nativeElement as HTMLElement).querySelectorAll('.modal-close-button');
        if (elements.length > 0) {
          this.focusFirstElement(elements);
        }
      }
    }
  }

  focusFirstElement(elements: NodeListOf<Element>) {
    setTimeout(() => {
      (elements[0] as HTMLElement).focus();
    }, AutoFocusModalDirective.FOCUS_MODAL_AFTER_MILLISECONDS);
  }

}
