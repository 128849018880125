import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-result',
  templateUrl: './result.component.html',
})
export class ResultComponent implements OnInit {
  @Input() questions;
  @Input() index = 0;
  constructor(
  ) { }

  ngOnInit(): void {
  }
}
