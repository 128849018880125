<app-dialoge-prompt [dialogClass]="dialogClass" [isDialog]="viewInstruction" (popupResponse)="closeHelpResponse($event)">
    <div header class="subheading" id="dialog-title">
        {{'common.popup.helpSection' | translate}}
    </div>
    <div content *ngIf="getIsSectionInstruction()">
        <ng-container *ngFor="let in of sectionList; let i = index">
            <div *ngIf="
            sectionList[i].instruction !== null && leftSectionCount === i + 1
          ">
                <div class="mt-3" [innerHTML]="sectionList[i].instruction"></div>
            </div>
        </ng-container>
    </div>
    <div content *ngIf="!getIsSectionInstruction()">
        <ng-container>
            <div *ngIf="testDetails?.instruction !== null">
                <div class="mt-3" [innerHTML]="testDetails?.instruction"></div>
            </div>
        </ng-container>
    </div>
</app-dialoge-prompt>