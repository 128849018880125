import { Component, Input, OnInit } from '@angular/core';

import { ResultHelperService } from '../../../services/result-helper.service';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'build-tree-result',
  templateUrl: './build-tree-result.component.html',
})
export class BuildTreeResultComponent implements OnInit {
  @Input() public question;
  @Input() public index;
  public options: Array<any> = [];
  public questionLabels: any = [];
  constructor(private resultHelper: ResultHelperService) { }

  ngOnInit(): void {
    this.onLoadData();
  }
  /**
   * @description: Load Data First Time
   */
  private onLoadData(): void {
    if (this.question !== undefined) {
      this.question.labels.forEach((element) => {
        element.option = [];
        element.answer = 0;
      });
      this.options = this.question.options;
      this.question.options.forEach((element) => {
        element.disabled = false;
        element.keys = element.key;
      });
      this.questionLabels = this.question.labels.map((i) => ({
        key: i.key,
        value: i.value,
        option: i.option,
      }));
      this.questionLabels.forEach((element) => {
        element.options = [];
        element.attempt = [];
      });
    }
    if (this.question.userAnswers.length === 0) {
      this.loadAnswers();
    }
    if (this.question.userAnswers.length > 0) {
      this.userSelectedAnswers();
    }
  }
  /**
   * @description: Load Answers
   */
  private loadAnswers(): any {
    this.questionLabels.forEach((element) => {
      const labelValue = this.question.answers.filter(
        (i) => i.key === element.key
      );
      if (labelValue.length > 0) {
        labelValue.forEach((e) => {
          const optionValue = this.options.find((i) => i.key === e.value);
          element.attempt.push(optionValue);
        });
      }
    });
  }
  /**
   * @description: Load User Selected Answers
   */
  private userSelectedAnswers(): any {
    this.resultHelper.userSelectedBuildTreeAnswers(this.questionLabels, this.question, this.options);
  }
}
