import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DialogModule } from 'primeng/dialog';
import { RatingModule } from 'primeng/rating';
import { ProgressSpinnerModule } from 'primeng/progressspinner';
import { TabMenuModule } from 'primeng/tabmenu';
import { TabViewModule } from 'primeng/tabview';
import { PaginatorModule } from 'primeng/paginator';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ChartModule } from 'primeng/chart';
import { ProgressBarModule } from 'primeng/progressbar';

import { SingleChoiceQuestionComponent } from './components/question/single-choice-question/single-choice-question.component';
import { MultipleChoiceQuestionComponent } from './components/question/multiple-choice-question/multiple-choice-question.component';
import { NumericInputQuestionComponent } from './components/question/numeric-input-question/numeric-input-question.component';
import { MultipleSingleChoiceQuestionComponent } from './components/question/multiple-single-choice-question/multiple-single-choice-question.component';
import { BuildListQuestionComponent } from './components/question/build-list-question/build-list-question.component';
import { BuildTreeQuestionComponent } from './components/question/build-tree-question/build-tree-question.component';
import { MultipleChoiceResultComponent } from './components/result/multiple-choice-result/multiple-choice-result.component';
import { NumericInputResultComponent } from './components/result/numeric-input-result/numeric-input-result.component';
import { MultipleSingleChoiceResultComponent } from './components/result/multiple-single-choice-result/multiple-single-choice-result.component';
import { BuildListResultComponent } from './components/result/build-list-result/build-list-result.component';
import { BuildTreeResultComponent } from './components/result/build-tree-result/build-tree-result.component';
import { SingleChoiceResultComponent } from './components/result/single-choice-result/single-choice-result.component';
import { DropDownQuestionComponent } from './components/question/drop-down-question/drop-down-question.component';
import { MatchOptionsQuestionComponent } from './components/question/match-options-question/match-options-question.component';
import { DropDownResultComponent } from './components/result/drop-down-result/drop-down-result.component';
import { MatchOptionsResultComponent } from './components/result/match-options-result/match-options-result.component';
import { QuestionComponent } from './components/question/question.component';
import { ResultComponent } from './components/result/result.component';
import { HeaderComponent } from './components/header/header.component';
import { FooterComponent } from './components/footer/footer.component';
import { HelpComponent } from './components/help/help.component';
import { SectionQuestionComponent } from './components/section-question.component';
import { DialogePromptComponent } from './components/dialoge-prompt/dialoge-prompt.component';
import { TestResultComponent } from '../lib/components/result/test-result/test-result/test-result.component';
import { TestSectionReviewComponent } from '../lib/components/section-review/test-section-review/test-section-review/test-section-review.component'
import { FeedbackComponent } from '../lib/components/feedback/feedback.component';
import { ComprehensiveReportComponent } from './components/comprehensive-report/comprehensive-report.component';
import { PerformanceQuizComponent } from './components/performance-quiz/performance-quiz.component';
import { MenuComponent } from './components/menu/menu.component'

import { QuestionHelperService } from './services/question/question-helper.service';
import { ResultHelperService } from './services/result-helper.service';
import { TestSectionReviewHelperService } from './services/test-section-review/test-section-review-helper.service';
import { TestResultHelperService } from './services/test-result/test-result-helper.service';
import { TestResultService } from './services/test-result/test-result.service';
import { TestSectionReviewService } from './services/test-section-review/test-section-review.service';
import { LocalSessionStorageService } from './services/local-session-storage.service';

import { TranslateModule } from '@ngx-translate/core';
// import { FormsModule } from '@angular/forms';
import { NgDragDropModule } from 'ng-drag-drop';

import { TeamsUiModule } from '@teams-ui-angular';
import { EventEmitterService, TeamsAuthModule } from '@teams-auth';
import { CaseStudyComponent } from './components/case-study/case-study.component';
import { CasestudyOverviewComponent } from './components/casestudy-overview/casestudy-overview.component';
import { CasestudySideMenuComponent } from './components/casestudy-side-menu/casestudy-side-menu.component';
// import { MarkdownModule, MarkdownService } from 'ngx-markdown';
import { UtilityModule } from '@utility';
import { CaseStudyHelperService } from './services/case-study-helper.service';
import { PerformanceTaskComponent } from './components/performance-task/performance-task.component';
import { CredentialComponent } from './components/credential/credential.component';
import { TaskListComponent } from './components/task-list/task-list.component';
import { PerformancTaskResultComponent } from './components/result/performanc-task-result/performanc-task-result.component';
import { ProgressBarArialabelDirective } from './directives/progress-bar-arialabel.directive';
import { NgbDropdownModule } from '@ng-bootstrap/ng-bootstrap';
import { VersionDropdownComponent } from './components/version-dropdown/version-dropdown.component';
import { TextInputComponent } from './components/question/text-input/text-input-question.component';

@NgModule({
  declarations: [
    BuildListQuestionComponent,
    BuildTreeQuestionComponent,
    DropDownQuestionComponent,
    MatchOptionsQuestionComponent,
    MultipleChoiceQuestionComponent,
    NumericInputQuestionComponent,
    MultipleSingleChoiceQuestionComponent,
    SingleChoiceQuestionComponent,
    BuildListResultComponent,
    BuildTreeResultComponent,
    DropDownResultComponent,
    MatchOptionsResultComponent,
    MultipleChoiceResultComponent,
    NumericInputResultComponent,
    MultipleSingleChoiceResultComponent,
    SingleChoiceResultComponent,
    QuestionComponent,
    SectionQuestionComponent,
    ResultComponent,
    HeaderComponent,
    FooterComponent,
    HelpComponent,
    ComprehensiveReportComponent,
    PerformanceQuizComponent,
    MenuComponent,
    DialogePromptComponent,
    TestResultComponent,
    TestSectionReviewComponent,
    FeedbackComponent,
    // PerformanceQuizComponent,
    MenuComponent,
    CaseStudyComponent,
    CasestudyOverviewComponent,
    CasestudySideMenuComponent,
    PerformanceTaskComponent,
    CredentialComponent,
    TaskListComponent,
    PerformancTaskResultComponent,
    ProgressBarArialabelDirective,
    VersionDropdownComponent,
    TextInputComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    TranslateModule,
    TeamsUiModule,
    DialogModule,
    NgDragDropModule.forRoot(),
    RatingModule,
    ProgressSpinnerModule,
    TabMenuModule,
    TabViewModule,
    PaginatorModule,
    ReactiveFormsModule,
    ChartModule,
    ProgressBarModule,
    TeamsAuthModule,
    UtilityModule,
    NgbDropdownModule

  ],
  exports: [
    BuildListQuestionComponent,
    BuildTreeQuestionComponent,
    DropDownQuestionComponent,
    MatchOptionsQuestionComponent,
    MultipleChoiceQuestionComponent,
    NumericInputQuestionComponent,
    MultipleSingleChoiceQuestionComponent,
    SingleChoiceQuestionComponent,
    BuildListResultComponent,
    BuildTreeResultComponent,
    DropDownResultComponent,
    MatchOptionsResultComponent,
    MultipleChoiceResultComponent,
    NumericInputResultComponent,
    MultipleSingleChoiceResultComponent,
    SingleChoiceResultComponent,
    NgDragDropModule,
    QuestionComponent,
    SectionQuestionComponent,
    ResultComponent,
    HeaderComponent,
    FooterComponent,
    HelpComponent,
    DialogePromptComponent,
    ComprehensiveReportComponent,
    PerformanceQuizComponent,
    MenuComponent,
    TestSectionReviewComponent,
    DialogModule,
    TranslateModule,
    TestResultComponent,
    RatingModule,
    ProgressSpinnerModule,
    TabMenuModule,
    TabViewModule,
    PaginatorModule,
    FeedbackComponent,
    ReactiveFormsModule,
    // PerformanceQuizComponent,
    MenuComponent,
    CaseStudyComponent,
    CasestudyOverviewComponent,
    CasestudySideMenuComponent,
    PerformanceTaskComponent,
    PerformancTaskResultComponent,
  ],
  providers: [
    QuestionHelperService,
    ResultHelperService,
    TestSectionReviewHelperService,
    TestResultHelperService,
    TestResultService,
    TestSectionReviewService,
    CaseStudyHelperService,
    EventEmitterService,
    LocalSessionStorageService,
  ],
})
export class QuestionsUiModule {}
