import { ChangeDetectorRef, Component, OnInit } from '@angular/core';

@Component({
  selector: 'cloudlabs-vm-spinner',
  templateUrl: './vm-spinner.component.html',
  styleUrls: ['./vm-spinner.component.scss']
})
export class VmSpinnerComponent implements OnInit {

  constructor(
    private ref: ChangeDetectorRef
  ) {
   }

  ngOnInit(): void {
    this.ref.markForCheck();
  }

}
