import { Directive, ElementRef, OnInit, AfterViewInit, ViewContainerRef, Renderer2 } from '@angular/core';
import { Router } from '@angular/router';

@Directive({
  selector: '[badgeDirective]'
})
export class BadgeDirective implements AfterViewInit {

  constructor(
    private el: ElementRef,
    private renderer: Renderer2,
    private viewContainerRef: ViewContainerRef,
    private router: Router,
  ) {
  }

  public ngAfterViewInit() {
    // const checkCatalog = this.router.url.includes('catalog');
    const element = this.el.nativeElement;
    const modifyText = element?.textContent?.replace(' ', '')?.trim()?.toLowerCase();
    if (modifyText) {
      const getStyle = this.setCardBadgeColor(modifyText);
      this.renderer.setStyle(element, 'background-color', getStyle.background);
      this.renderer.setStyle(element, 'color', getStyle.color);
    } else {
      this.renderer.setStyle(element, 'display', 'none');
      this.viewContainerRef.clear();
    }
  }

  private setCardBadgeColor(text: string) {
    const styleObj = {
      background: '#EE9B00',
      color: 'white',
    };
    switch (text) {
      case 'new':
        styleObj.background = '#0072C9';
        return styleObj;
      case 'updated':
        styleObj.background = '#2A9D8F';
        return styleObj;
      case 'trending':
        styleObj.background = '#494B74';
        return styleObj;
      case 'endingsoon':
        styleObj.background = '#FC3C56';
        return styleObj;
      default:
        return styleObj;
    }
  }
}
