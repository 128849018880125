import { CustomerType,AppType } from "@libs/common-lab/src/modals/lab.model";
export const environment = {
  appType: AppType.LAB,
  production: false,
  version: '0.0.115',
  APIURL: 'https://api-qa.cloudlabs.ai/api/',
  BASEURL: 'https://experience-qa.cloudlabs.ai',
  partnerNameNarratorDialog:"Public Contoso",
  pollIntervalInMinutes: 1,
  extendingLabDurationInMinutes: 15,
  SIDEBAR_MIN_WIDTH: 500,
  VM_MIN_WIDTH: 768,
  EMAIL_PATTERN: '^[a-zA-Z0-9.!#$%&\'*+/=?^_’`"{|}()~-]+@((?:[\\w-]+\\.)*\\w[\\w-]{0,66})\\.([A-Za-z]{2,40}(?:\\.[A-Za-z]{2,40})?)$',
  GTMID: '', /* ToDo: need to update */
  appInsightConfig: {
    instrumentationKey: 'd48e5386-ed58-4b49-990e-640e124abd18',
    enableAutoRouteTracking: true,
    loggingLevelConsole: 1,
    autoTrackPageVisitTime: true,
    isBrowserLinkTrackingEnabled: true,
    enableRequestHeaderTracking: true,
    enableAjaxErrorStatusText: true,
  },
  customer:CustomerType.DATABRICKS,
  privateRepoAccessToken:"665KtaNHcpUntucn5xJmciKe"
};
