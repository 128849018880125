import {
  Component,
  OnInit,
  Input,
  Output,
  EventEmitter,
  OnDestroy,
  NgZone,
} from '@angular/core';
import { LabService } from '../../../services/lab.service';
import { TranslateService } from '@ngx-translate/core';
import { BsModalService } from 'ngx-bootstrap/modal';
import { BsModalRef } from 'ngx-bootstrap/modal/bs-modal-ref.service';
import { VmDialogComponent } from '../vm-dialog/vm-dialog.component';
import { EventService } from '../../../services/event.services';
import { ToastrService } from 'ngx-toastr';
import { Deployment, Lab } from '../../../modals/lab.model';
import { ActivatedRoute, Router } from '@angular/router';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { NotificationService } from '../../../services/notification.service';
import { EventEmitterService } from '@teams-auth';

@Component({
  selector: 'cloudlabs-virtual-machine',
  templateUrl: './virtual-machine.component.html',
  styleUrls: ['./virtual-machine.component.scss'],
})
export class VirtualMachineComponent implements OnInit, OnDestroy {
  private notifier = new Subject();
  @Input() labDetail: Lab;
  @Input() voucherCode: string;
  @Input() splitKey: string;
  @Input() attendeLabDetails: Deployment;
  @Output() actionPerformedOnVM = new EventEmitter();
  public docActions = false;
  public v_machine: any;
  public modalRef: BsModalRef;
  public vmType: number;
  public isVMLoading = false;
  public showVMLaunchButton = true;
  public upTimeLimit = '';
  public showUpTimeLimit = false;
  public isVmUpTimeLimitReached = false;
  public showLaunchButton = false;
  public internalId = '';

  public dataRow;
  public modelShow = false;
  public modalData: any;
  showalert = false;
  constructor(
    private modalService: BsModalService,
    private LabSrv: LabService,
    private translateSrv: TranslateService,
    private eventSrv: EventService,
    private toastr: ToastrService,
    private notificationService: NotificationService,
    private route: Router,
    private aroute: ActivatedRoute,
    private ngZone: NgZone,
    private eventEmitter: EventEmitterService
  ) {
    this.LabSrv.reloadVMs.pipe(takeUntil(this.notifier)).subscribe(() => {
      this.loadVirtualMachines(false);
    });
  }

  async ngOnInit() {
    const url = this.route.url;
    this.internalId = this.aroute.snapshot.params.vmurl || '';
    // get internal id by query param
    const { intId } = this.aroute.snapshot.queryParams;
    if (intId && intId !== '') {
      this.internalId = intId;
    }
    if (url.startsWith('/odl/environment') || url.startsWith('/odl/labguide')) {
      this.showVMLaunchButton = false;
    }
    this.eventSrv.onCloseDialog.subscribe((res) => {
      this.onDialogConfirmation();
    });
    this.loadVirtualMachines(false);
  }

  async loadVirtualMachines(trigger = true) {
    this.isVMLoading = true;
    this.v_machine = await this.LabSrv.GetVirtualMachine(
      this.voucherCode
      ).toPromise();
    if (trigger && this.v_machine.length > 0) {
      this.attendeLabDetails = await this.LabSrv.getLabEnvironment(this.labDetail.UniqueName, this.voucherCode).toPromise() as Deployment;
      const split = localStorage.getItem(this.splitKey);
      if (split === 'true') {
        parent.postMessage(JSON.stringify(this.v_machine), location.origin);
      } else {
        this.LabSrv.vmStateUpdate.next(this.v_machine);
      }
      this.refreshAlert();
    }
    this.upTimeLimit = this.getUpTimeLimit();
    this.isVMLoading = false;
    const { VMLaunchURL } = this.attendeLabDetails;
    this.showLaunchButton =
      VMLaunchURL && VMLaunchURL.length > 1 ? true : false;

    // merge vmUrl parameters wit vMachines
    if (VMLaunchURL && VMLaunchURL.length > 0) {
      (VMLaunchURL as any).forEach((element) => {
        const index = this.v_machine.findIndex(
          (item) =>
            element.VMName === item.ResourceName &&
            element.ResourceGroupName === item.ResourceGroupName &&
            element.SubscriptionId === item.SubscriptionId &&
            element.VMDNSName === item.DNSName
        );
        if (index > -1) {
          this.v_machine[index] = { ...this.v_machine[index], ...element };
        }
      });
    }
  }

  refreshAlert() {
    this.ngZone.run(() => {
      const div = document.createElement('div');
      div.innerHTML = "loading in progress";
      div.setAttribute('tabindex','0');
      div.style.opacity = "0";
      div.style.fontSize = "0";
      const elem = document.getElementById('refresh-all-btn')
      if (elem){
        elem.appendChild(div);
        div.focus();
        setTimeout(()=>{
          elem.removeChild(div);
        }, 2000);
      }
    });
  }

  /**
   * Diallog confirmation
   */
  async onDialogConfirmation() {
    if (this.dataRow) {
      this.refreshAlert();
      const data = await this.LabSrv.virtualMachineOperations(
        this.vmType,
        this.attendeLabDetails.CloudPlatformId,
        this.dataRow.SubscriptionId,
        this.dataRow.ResourceGroupName,
        this.dataRow.ResourceName,
        this.voucherCode
      ).toPromise();
      if (data) {
        if (data.IsSuccess === true) {
          switch (this.vmType.toString()) {
            case '7':
              this.notificationService.success(
                this.translateSrv.instant('MESSAGE_VM_START'),
                null
              );
              break;
            case '8':
              this.notificationService.success(
                this.translateSrv.instant('MESSAGE_VM_STOP'),
                null
              );
              break;
            case '9':
              this.notificationService.success(
                this.translateSrv.instant('MESSAGE_VM_RESTART'),
                null
              );
              break;
            case '10':
              this.notificationService.success(
                this.translateSrv.instant('MESSAGE_VM_BACKUP'),
                null
              );
              break;
            default:
              break;
          }

          const split = localStorage.getItem(this.splitKey);
          if (split === 'true') {
            parent.postMessage(JSON.stringify([this.dataRow]), location.origin);
          }
          this.LabSrv.reloadVMs.next();
        } else {
          if (
            data.ErrorMessage ===
            'Sorry! User has reached the limit of run time for the Virtual Machine. Please contact instructor/support team for additional information.'
          ) {
            this.modalData = {
              title: 'OOPS',
              description: 'VM_START_UPTIME_LIMIT_REACHED_MODEL_DESCRIPTION',
            };
            this.modelShow = true;
          }
          const code = 'Error - 70031';
          this.eventEmitter.debugAlert(code, data.ErrorMessage);
        }
        // setTimeout(() => {
        //   window.location.reload();
        // }, 3000);
      } else {
        // ModalService.showModal({
        //     templateUrl: '../app/views/shared/successModal.html',
        //     controller: "ModalController",
        //     inputs: {
        //         title: $filter('translate')("FAILED"),
        //         description: response.data.ErrorMessage,
        //         failureButtonText: "",
        //         successButtonText: $filter('translate')("OK")
        //     }
        // }).then(function (modal) {
        //     modal.element.modal();
        //     modal.close.then(function (result) {
        //     });
        // });
        // parent.focus();
      }
    }
  }

  virtualMachineOperations(dataRow, type) {
    this.dataRow = dataRow;
    this.vmType = type;
    let action = '';
    switch (type) {
      case '7':
        action = this.translateSrv.instant('START');
        break;
      case '8':
        action = this.translateSrv.instant('DEALLOCATE');
        break;
      case '9':
        action = this.translateSrv.instant('RESTART');
        break;
      case '10':
        action = this.translateSrv.instant('BACKUP');
        break;
    }
    this.modalRef = this.modalService.show(VmDialogComponent, {
      initialState: {
        title: this.translateSrv.instant('VIRTUAL_MACHINE_OPERATION_TITLE'),
        data: {
          description:
            this.translateSrv.instant('DESCRIPTION_VM_OPERATION') +
            ' ' +
            action +
            ' ' +
            this.translateSrv.instant('DESCRIPTION_VMACHINE_OPERATION') +
            ' ' +
            dataRow.ResourceName +
            this.translateSrv.instant('DESCRIPTION_VM_OPERATION_QUESTION'),
          failureButtonText: this.translateSrv.instant('CANCEL'),
          successButtonText: this.translateSrv.instant('OK'),
        },
      },
    });
  }

  /**
   * launch vm
   * @param vm
   */
  async openVMLaunchURL(vm) {
    try {
      const containerCurrentStatus = await this.LabSrv.getContainerStatus(
        vm.CloudDeploymentId,
        vm.ContainerStatus
      ).toPromise();
      const vmData = await this.LabSrv.getVirtualMachineStatus(
        vm.SubscriptionId,
        vm.ResourceGroupName,
        vm.VMName,
        this.voucherCode
      ).toPromise();
      if (
        vmData.Status === 'VM running' &&
        containerCurrentStatus === 'Running'
      ) {
        window.open(vm.VMLaunchURL, '_blank');
      } else {
        this.modalData = {
          title: 'VM_NOT_READY_MODAL_TITLE',
          description: 'VM_NOT_READY_MODAL_DESCRIPTION',
        };
        this.modelShow = true;
      }
    } catch (e) {
      if (
        e?.error?.ErrorDetail ===
        'Sorry! User has reached the limit of run time for the Virtual Machine. Please contact instructor/support team for additional information.'
      ) {
        this.modalData = {
          title: 'OOPS',
          description: 'VM_START_UPTIME_LIMIT_REACHED_MODEL_DESCRIPTION',
        };
        this.modelShow = true;
      }
      const code = 'Error - 70032';
      this.eventEmitter.debugAlert(code, e?.error?.ErrorDetail);
    }
  }
  getUpTime(vm) {
    return this.transformTime(vm.VMRunningHours, vm.VMRunningMinutes);
  }

  getUpTimeLimit() {
    if (this.v_machine?.length > 0) {
      const VMUpTimeLimitHours = this.v_machine[0].VMUpTimeLimitHours;
      const VMUpTimeLimitMinutes = this.v_machine[0].VMUpTimeLimitMinutes;
      if (VMUpTimeLimitHours > 0 || VMUpTimeLimitMinutes > 0) {
        this.showUpTimeLimit = true;
        return this.transformTime(VMUpTimeLimitHours, VMUpTimeLimitMinutes);
      } else if (VMUpTimeLimitHours === 0 && VMUpTimeLimitMinutes === 0) {
        this.showUpTimeLimit = true;
        return 'Not Configured';
      }
      return '';
    } else {
      return '';
    }
  }
  transformTime(hoursIn: number, minutesIn: number) {
    let hours = '';
    let minutes = '';
    if (hoursIn !== null) {
      if (hoursIn < 10) {
        hours = '0' + hoursIn;
      } else {
        hours = '' + hoursIn;
      }
    } else {
      hours = '00';
    }
    if (minutesIn !== null) {
      if (minutesIn < 10) {
        minutes = '0' + minutesIn;
      } else {
        minutes = '' + minutesIn;
      }
    } else {
      minutes = '00';
    }
    return hours + ':' + minutes;
  }

  /**
   * run vm in new tab
   * @param item
   */
  async openVMandGitDoc(item) {
    try {
      const containerCurrentStatus = await this.LabSrv.getContainerStatus(
        item.CloudDeploymentId,
        item.ContainerStatus
      ).toPromise();
      const vmData = await this.LabSrv.getVirtualMachineStatus(
        item.SubscriptionId,
        item.ResourceGroupName,
        item.VMName,
        this.voucherCode
      ).toPromise();
      if (
        (containerCurrentStatus === 'Stopped' ||
          vmData.Status === 'VM deallocated' ||
          vmData.Status === 'VM stopped') &&
        vmData.Status !== 'VM deallocating'
      ) {
        if (
          (vmData.Status === 'VM deallocated' ||
            vmData.Status === 'VM stopped') &&
          containerCurrentStatus !== 'Stopped'
        ) {
          this.startVM(vmData);
        } else if (
          (vmData.Status === 'VM deallocated' ||
            vmData.Status === 'VM stopped') &&
          containerCurrentStatus === 'Stopped'
        ) {
          this.startVM(vmData);
          this.startContainer(item.CloudDeploymentId);
        } else if (
          (vmData.Status !== 'VM deallocated' ||
            vmData.Status !== 'VM stopped') &&
          containerCurrentStatus === 'Stopped'
        ) {
          this.startContainer(item.CloudDeploymentId);
        }
        this.modalData = {
          title: 'STARTED_VM_MODAL_TITLE',
          description: 'STARTED_VM_MODAL_DESCRIPTION',
        };
        this.modelShow = true;
      } else if (
        vmData.Status === 'VM running' &&
        containerCurrentStatus === 'Running'
      ) {
        const url = this.route.serializeUrl(
          this.route.createUrlTree([
            `#/odl/environment/${this.labDetail.UniqueName}/${this.voucherCode}/${item.InternalId}`,
          ])
        );
        window.open(decodeURIComponent(url), '_blank');
      } else {
        this.modalData = {
          title: 'VM_NOT_READY_MODAL_TITLE',
          description: 'VM_NOT_READY_MODAL_DESCRIPTION',
        };
        this.modelShow = true;
      }
    } catch (e) {
      if (
        e?.error?.ErrorDetail ===
        'Sorry! User has reached the limit of run time for the Virtual Machine. Please contact instructor/support team for additional information.'
      ) {
        this.modalData = {
          title: 'OOPS',
          description: 'VM_START_UPTIME_LIMIT_REACHED_MODEL_DESCRIPTION',
        };
        this.modelShow = true;
      }
      const code = 'Error - 70033';
      this.eventEmitter.debugAlert(code, e?.error?.ErrorDetail);
    }
  }

  private async startVM(vmData: any) {
    const data = await this.LabSrv.virtualMachineOperations(
      7,
      this.attendeLabDetails.CloudPlatformId,
      vmData.SubscriptionId,
      vmData.ResourceGroupName,
      vmData.ResourceName,
      this.voucherCode
    ).toPromise();
    if (data.IsSuccess) {
      this.notificationService.success(
        this.translateSrv.instant('MESSAGE_VM_START'),
        null
      );
    } else {
      if (
        data.ErrorMessage ===
        'Sorry! User has reached the limit of run time for the Virtual Machine. Please contact instructor/support team for additional information.'
      ) {
        this.modalData = {
          title: 'OOPS',
          description: 'VM_START_UPTIME_LIMIT_REACHED_MODEL_DESCRIPTION',
        };
        this.modelShow = true;
      }
      const code = 'Error - 70034';
      this.eventEmitter.debugAlert(code, data.ErrorMessage);
    }
  }

  private startContainer(cloudDeploymentId: string) {
    this.LabSrv.controlContainerAction(cloudDeploymentId, 'start');
  }

  controlResources(e) {}

  getVmContainerDetail() {}

  ngOnDestroy() {
    this.notifier.next();
    this.notifier.complete();
  }
}
