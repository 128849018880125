import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { LabService } from '../../../services/lab.service';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { TranslateService } from '@ngx-translate/core';
import { SurveyDialogComponent } from './survey-dialog/survey-dialog.component';
import { ActivatedRoute, Router } from '@angular/router';
import { EventService } from '../../../services/event.services';

@Component({
  selector: 'cloudlabs-survey-form',
  templateUrl: './survey-form.component.html',
  styleUrls: ['./survey-form.component.scss'],
})
export class SurveyFormComponent implements OnInit {
  public uid: any;
  checkVal = [];
  public surveyDetails: any;
  public option: any;
  public checkID = [];
  public modalRef: BsModalRef;
  form: FormGroup;
  public bannerImageURL =
    'https://cloudlabsqa.blob.core.windows.net/website-images/MSFT_Azure_Immersion_Workshop_Hero_Banners_1920x300_Right.png';
  public themeId = 1;

  constructor(
    private labSrv: LabService,
    private fb: FormBuilder,
    private modalService: BsModalService,
    private translateSrv: TranslateService,
    private route: ActivatedRoute,
    private router: Router,
    private eventSrv: EventService
  ) {}

  async ngOnInit() {
    this.eventSrv.setThemeId(this.themeId);
    this.route.queryParams.subscribe((params) => {
      this.uid = Object.keys(params)[0];
    });
    this.surveyDetails = await this.labSrv.getSurvetForm(this.uid).toPromise();
  }
  reverseVal(val: any) {
    val.reverse();
    return val;
  }
  onItemChange(name: string, isCheck: any, qid: any) {
    this.checkID.push(qid);
    if (isCheck === true) {
      this.checkVal.push({
        QuestionId: qid,
        Answer: name,
      });
    } else {
      const index = this.checkVal.findIndex((x) => x.value === name);
      this.checkVal.splice(index);
    }
  }
  getQuestionData = (textMsg: any, quesId: any) => {
    this.surveyDetails.Questions.forEach((item) => {
      if (item.Id === quesId) {
        item.textLen = textMsg.length.toString();
      }
    });
  };
  onSubmit(form: any) {
    let finalResponse = [];
    for (const key of Object.keys(form.value)) {
      if (!this.checkID.includes(parseInt(key))) {
        finalResponse.push({
          QuestionId: parseInt(key),
          Answer: form.value[key],
        });
      }
    }
    finalResponse = finalResponse.concat(this.checkVal);
    const responseObj = {
      PollId: this.surveyDetails.Id,
      Responses: finalResponse,
    };
    this.modalRef = this.modalService.show(SurveyDialogComponent, {
      initialState: {
        title: this.translateSrv.instant('SUBMIT_RESPONSE'),
        data: {
          description: this.translateSrv.instant('SUBMIT_RESPONSE_DESCRIPTION'),
          failureButtonText: this.translateSrv.instant('NO'),
          successButtonText: this.translateSrv.instant('BUTTON_SUBMIT'),
        },
        res: responseObj,
        id: this.surveyDetails.Id,
      },
    });
  }
}
