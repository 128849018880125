
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

import { miscellaneousConst } from '@utility';
import { DeviceDetectorService } from 'ngx-device-detector';

import { SectionModel, TestDetailsModel } from '../../model/common.model';

@Component({
  selector: 'app-help',
  templateUrl: './help.component.html',
})
export class HelpComponent implements OnInit {
  @Input() sectionList: SectionModel[] = [];
  @Input() testDetails: TestDetailsModel;
  @Input() leftSectionCount: number;
  @Input() viewInstruction;

  @Output() helpResponse = new EventEmitter();

  public miscellaneousConst = miscellaneousConst;
  public dialogClass: string;

  constructor(public deviceDetectorService: DeviceDetectorService) { }

  ngOnInit(): void {
    this.dialogClass = 'instruction-popup ts-dialog-fluent-instruction';
  }
  /**
   * @description: Check Is Section Instruction or not
   */
  public getIsSectionInstruction(): any {
    if (this.sectionList.length > 0) {
      const sectionInstruction = this.sectionList.filter(
        (i) => i.instruction !== null
      );
      if (sectionInstruction.length > 0) {
        return true;
      } else {
        return false;
      }
    } else {
      return false;
    }
  }
  /**
   * @description: Close View Instruction Dialog
   */
  public cancel(): void {
    this.viewInstruction.action = false;
  }

  /**
   * @description: Close Help
   */
   public closeHelpResponse(event): any {
    this.viewInstruction = event.help;
    this.helpResponse.emit(this.viewInstruction);
  }
}
