import {
  Resolve,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  Router,
} from '@angular/router';
import { Observable, of } from 'rxjs';
import { Injectable } from '@angular/core';
import { catchError } from 'rxjs/internal/operators';
import { Lab } from '../modals/lab.model';
import { LabService } from '../services/lab.service';
import { LabClusterService } from '../services/lab-cluster.service';
import { LabCluster } from '../modals/lab-cluster.modal';
import { EventEmitterService } from '@teams-auth';
import { NotificationService } from '../services/notification.service';
import { TranslateService } from '@ngx-translate/core';

@Injectable({ providedIn: 'root' })
export class LabResolver implements Resolve<any> {
  constructor(
    private labSrv: LabService,
    private labClusterService: LabClusterService,
    private eventEmitter: EventEmitterService,
    private notification: NotificationService,
    private translateSrv: TranslateService
  ) {}

  async resolve(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Promise<Lab | LabCluster | null> {
    const url = state.url;
    const urlParts = url.split('/');
    if (urlParts.includes('baselineevent') || urlParts.includes('trials') && !route.params.id ) {
      return await this.labClusterService
        .getLabClusterDetails(route.params.uniqueIdentifier)
        .pipe(
          catchError((error) => {
            const code = 'Error - 70004';
            this.eventEmitter.debugAlert(code, error.error);
            return of(null);
          })
        ).toPromise();
    }
    const lang = localStorage.getItem('language') || 'en';
    const data = await this.labSrv.getDetails(route.params.id, lang).pipe(
      catchError((error) => {
        const code = 'Error - 70005';
        this.eventEmitter.debugAlert(code, error.error);
        return of(null);
      })
    ).toPromise();
    if(!data) {
      this.notification.error(this.translateSrv.instant('INVALID_LAB_ID'), null);
      return null;
    } else {
      return data;
    }
  }
}
