import { Injectable } from '@angular/core';


@Injectable({
  providedIn: 'root'
})
export class TestResultHelperService {
  testDetails: any = [];
  constructor() { }
  /**
   * @description: Change Timer Minute Display
   */
  public changeMinute(value: any): number {
    let totalMinutes = 0;
    if (value !== undefined && value !== null) {
      const elapsedTime = value.split(':');
      const minutes = elapsedTime[0] * 60;
      const secondsSums = minutes + parseInt(elapsedTime[1], 10);
      totalMinutes = (secondsSums * 60 + parseInt(elapsedTime[2], 10)) / 60;
      return Math.ceil(totalMinutes);
    }else{
      return Math.ceil(totalMinutes);
    }
  }
  /**
   * @description: Display Pagination
   */
  public paginate(event): any {
    return {
      pageStartingIndex: event.first,
      pageEndingIndex: event.first + event.rows,
      activePage: event.page
    };
  }

}

