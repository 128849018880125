import { Component, Input, OnChanges, OnInit } from '@angular/core';

import { ResultHelperService } from '../../../services/result-helper.service';

@Component({
  selector: 'drop-down-result',
  templateUrl: './drop-down-result.component.html'
})
export class DropDownResultComponent implements OnInit, OnChanges {
  @Input() question;
  @Input() examId;
  @Input() index;
  dropdownStrList: any;
  dropdownStrList1: any;
  correctDropDownList: any;
  optionList: any = [];
  constructor(
    private resultHelper: ResultHelperService,
  ) { }

  ngOnInit(): void {
  }
  /**
   * @description: Change DropDown Value
   */
  ngOnChanges(): void {
    this.correctDropDownList = this.question.question;
    this.optionList = [...this.question.options];
    this.onLoadData();
  }

  /**
   * @description: Load Data First Time
   */
  private onLoadData(): void {
    if (this.question !== undefined) {
      const question = Object.assign({}, this.question);
      const dropdownData = this.resultHelper.onDropDownLoadData
        (question, this.index, this.optionList,
          this.correctDropDownList, this.dropdownStrList1, this.dropdownStrList);
      this.dropdownStrList = dropdownData.dropdownStrListValue;
      this.dropdownStrList1 = dropdownData.dropdownStrList1Value;
    }
  }
  /**
   * @description: Check DropDown KeyValue Match
   */
  public checkValue(data): any {
    const correctDropDown = this.question.userAnswers.filter(o => data.some(i => o.value === i.value && o.key === i.key));
    if (correctDropDown.length > 0 && correctDropDown.length === this.question.answers.length) {
      return true;
    } else {
      return false;
    }
  }
}
