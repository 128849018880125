import { Component, Input, OnInit, Output, EventEmitter, OnDestroy} from '@angular/core';
import { EventEmitterService, UserSessionService } from '@teams-auth';
import { formatter, miscellaneousConst, unsubscribeCollection } from '@utility';
import { Subscription } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { CommonService } from '../../services/common-service.service';
import { TaskListService } from '../../services/task-list.service';

@Component({
  selector: 'cloudlabs-task-list',
  templateUrl: './task-list.component.html',
})
export class TaskListComponent implements OnInit, OnDestroy {
  @Input() questionList;

  public show = [];
  public activeIndex;
  public taskLists;
  isComplete  = [];
  private progressChangeValue;
  public miscellaneousConst = miscellaneousConst;
  private subscriptions: Subscription[] = [];
  isLoaded = false;
  // @Input() activeIndex;
  @Output() activeIndexVal  = new EventEmitter();

  constructor(
    private commonSrv: CommonService,
    private taskListSrv: TaskListService,
    private userSessionService: UserSessionService,
    private eventEmitter: EventEmitterService,
    private commonService: CommonService,
     ) {
      }

  ngOnInit(): void {
    this.subscriptions[this.subscriptions.length] = this.userSessionService.questionApi$.pipe(debounceTime(2500)).subscribe((res) => {
      this.isLoaded = res;
      if (this.isLoaded === true) {
        this.questionList.forEach((element, key) => {
          this.isComplete[key] = element.reviewLater
          if (element.reviewLater !== null && element.reviewLater) {
            this.commonSrv.OntaskCount(key);
          }
        });
      }
    })
    this.taskLists = [
      { 'title': 'abc'}
    ]
    const data = this.taskListSrv.getTask()
    if(data.length > 0){
      data.forEach(item=>{
        this.isComplete[item] = true
      })
    }
  }

  /**
   * @description Toggle a View more/less button
   */

   toggle(val, key): void {
    this.show = val;
    this.commonSrv.OntaskCount(key);
    const totalQuestion = 100 / this.questionList.length;
    this.progressChangeValue = (key+1) * totalQuestion;
    this.commonSrv.OnprogressValue(this.progressChangeValue)
  }
  
  activeClick(val){
    this.activeIndex = val
  }

  isChecked(i, question):void{
    this.userSelectAnswerOption(question, i)  
    this.isComplete[i] = true
    this.taskListSrv.addTask(i)
  }

  /**
   * @description: Update Exam Question Instance
   */
   public userSelectAnswerOption(question, id): void {
    const model = {
      elapsedTime: formatter(this.userSessionService.stopWatch),
      ReviewLater: true,
      UserAnswer: null,

// {key: "Label 1", value: "Option 2"}
    };
    const shouldApiNotGetCalled = false;
    // if (question.questionType === 'Multiple Single Choice') {
    //   for ( const answer of model.UserAnswer) {
    //     if (answer.value === undefined ) {
    //       shouldApiNotGetCalled = true;
    //       break;
    //     }
    //   }
    // }
    if (!shouldApiNotGetCalled) {
      this.updateExamAnswer(model, question);
    }
  }

  private updateExamAnswer(model, question): void {
    this.subscriptions[this.subscriptions.length] = this.commonService
      .updateExamQuestionInstance(question.questionUniqueName, model)
      .subscribe(
        (res) => { /* TODO document why this arrow function is empty */ },
        (error) => {
          const errorMessage: string = error.error.message;

          if (!errorMessage.startsWith(miscellaneousConst.elapsedTime)) {
            const code = 'Error - 30033';
            this.eventEmitter.debugAlert(code, error.error.message);
          }
        }
      );
  }

  /**
   * @description Component lifecycle method, gets called when component destroys
   */
  ngOnDestroy(): void {
    unsubscribeCollection(this.subscriptions);
  }

}
