<div role="dialog" [attr.aria-label]="title | dialogRole">
  <div class="modal-content" appTrapFocus appRestartDialog appAutoFocusModal appRefocusPreviousEl>
    <div class="modal-header">
      <h4 class="modal-title" id="vm-dialog-head"> {{ title }} </h4>
      <!--          <button aria-label="Close" type="button" class="close modal-close-button" (click)="modulemodelShow = false">&times;</button>-->
    </div>
    <div class="modal-body">
      {{data.description}}
    </div>
    <div class="modal-footer">
      <teams-button [classList]="'btn btn-secondary ts-btn-fluent-white modal-close-button'" (buttonClick)="modalRef.hide(); onCancel()">
        {{data.failureButtonText}}</teams-button>
      <teams-button appRemoveFocusOkButton type="button" aria-hidden="true"
        [classList]="'btn btn-primary ts-btn-fluent-primary modal-ok-button'" (buttonClick)=" onConfirm()">
        {{data.successButtonText}}</teams-button>
    </div>
  </div>
</div>