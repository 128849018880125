import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'teams-single-select',
  templateUrl: './single-select.component.html',
  styleUrls: ['./single-select.component.scss'],
})
export class SingleSelectComponent {
  @Input() optionArray: any = [];
  @Input() selectedValue: any;
  @Input() cssWidth = false;
  @Input() classList = '';
  @Input() outerDivClass = 'dropdown-div mt-3';
  @Input() listItemClass = '';
  @Output() selectedValueEmit = new EventEmitter();

  public selectedItem;

  constructor() {
    /* TODO document why this constructor is empty */
  }

  public dropdownSelection(val: string, label: string): void {
    this.selectedValueEmit.emit({ val, label });
  }
}
