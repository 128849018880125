import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'pascalCase'
})
export class PascalCasePipe implements PipeTransform {

  transform(value: string): string {
    const abbrevationSet = ['SP', 'VM', 'DNS', 'CCSE', 'CCSA', 'SQL', 'VSX', 'CDH', 'AML', 'SSH', 'ADV'];

    const splitString = value.split(' ');
    let word = splitString[0];

    if (/[A-Z]/.test(word)) {
      const temp = [];
      let position = 0;
      for (let i = 0; i < word.length; i++) {
        if (/[A-Z]/.test(word[i]) && /[a-z]/.test(word[i - 1])) {
          temp.push(word.slice(position, i));
          position = i;
        }
      }

      temp.push(word.slice(position, word.length));

      for (let i = 0; i < temp.length; i++) {
        if (abbrevationSet.indexOf(temp[i].toUpperCase()) !== -1) {
          temp[i] = temp[i].toUpperCase();
        }
      }

      temp[0] = temp[0].charAt(0).toUpperCase() + temp[0].slice(1);

      word = temp.join('');
    }
    else {
      if (abbrevationSet.indexOf(word.toUpperCase()) !== -1) {
        word = word.toUpperCase();
      }
      word = word.charAt(0).toUpperCase() + word.slice(1);
    }

    splitString[0] = word;
    return splitString.join(' ');
  }

}
