import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class LocalSessionStorageService {

  public courseFlow: string;
  public product: string;

  public setCourseStorage(isCourse: string): void {
    sessionStorage.setItem('courseFlow', isCourse);
  }

  public getCourseStorage(): string {
    this.courseFlow = sessionStorage.getItem('courseFlow');
    return this.courseFlow;
  }

  public setPTDetailsPageEmpty() {
    localStorage.removeItem('current-pt-details-page-path')
  }

  public setCurrentPTDetailsPagePath(pathName: string) {
    localStorage.setItem('current-pt-details-page-path', pathName);
  }

  public getCurrentPTDetailsPagePath(): string {
    return localStorage.getItem('current-pt-details-page-path');
  }

  public setCourseDetailsYPosition(value: number) {
    sessionStorage.setItem('courseScrollY', value + '');
  }

  public getCourseDetailsYPosition(): number {
    if (sessionStorage.getItem('courseScrollY')) {
      return +sessionStorage.getItem('courseScrollY');
    } else {
      return 0;
    }
  }

}

