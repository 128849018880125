import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import * as moment from 'moment';

@Component({
  selector: 'cloudlabs-trial-details',
  templateUrl: './trial-details.component.html',
  styleUrls: ['./trial-details.component.scss'],
})
export class TrialDetailsComponent implements OnInit, OnChanges {
  // @Input() EndDate: Date;
  // days: number;
  hours: number;
  minutes: number;
  seconds: number;

  @Input() creditRemaining = null;
  @Input() cloudProviderId = 1;
  @Input() cloudSubscription = 'Managed Cloud';
  @Input() labExpiryDuration;
  @Input() isResourceStopped: boolean;
  @Input() supportEmail;
  @Output() countdown = new EventEmitter();
  @Input() vmUsage;

  cloudProvider: string;
  interval;
  endDate: string;
  expiryReached: boolean;

  constructor() {}

  ngOnInit(): void {
    this.cloudProvider = CloudProviderEnum[this.cloudProviderId];
    this.setRemainingTime();
    if (this.vmUsage && this.vmUsage?.RemainingUptime) {
      this.manageTimestamp(this.vmUsage.RemainingUptime);
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (this.vmUsage && this.vmUsage.RemainingUptime && this.vmUsage.RemainingUptime !== '00:00:00') {
      if (this.isResourceStopped) clearInterval(this.interval);
      else this.manageTimestamp(this.vmUsage.RemainingUptime);
    }
  }

  setRemainingTime() {
    const curDate: any = moment();
    this.endDate = moment(curDate.add(this.labExpiryDuration))
      .format('MMMM Do YYYY hh:mm A')
      .replace(/(\d)(st|nd|rd|th)/g, '$1<sup>$2</sup>');
    setTimeout(() => {
      this.expiryReached = true;
    }, this.labExpiryDuration.valueOf());
  }

  manageTimestamp(value: any) {
    const dur: any = moment.duration(value);
    // this.days = dur._data.days;
    this.hours = dur._data.days * 24 + dur._data.hours;
    this.minutes = dur._data.minutes;
    this.seconds = dur._data.seconds;
    if (!this.isResourceStopped) {
      this.countdownTimer();
    }
  }

  countdownTimer() {
    this.interval && clearInterval(this.interval);
    this.interval = setInterval(() => {
      if (this.seconds !== 0) {
        this.seconds--;
      } else if (this.minutes !== 0) {
        this.minutes--;
        this.seconds = 59;
      } else if (this.hours !== 0) {
        this.hours--;
        this.minutes = 59;
      } else {
        /* TODO: emit event*/
        this.countdown.emit();
        clearInterval(this.interval);
      }
    }, 1000);
  }
}

enum CloudProviderEnum {
  'Microsoft Azure' = 1,
  'Amazon Web Services' = 2,
  'Google Cloud Platform' = 3,
}
