<div appTrapFocus appAutoFocusModal appRefocusPreviousEl role="dialog" aria-labelledby="extend-duration-head"
  aria-describedby="extend-duration-desc">
  <div class="modal-header" id="extend-duration-head">
    <h4 class="modal-title pull-left"> {{ title }} </h4>
  </div>
  <div class="modal-body" id="extend-duration-desc">
    <p>{{data.description}}</p>
    <cloudlabs-lab-validation *ngIf="data.isLabValidation" [voucherCode]="data.vCode" [labDetail]="data.lab" [templateValidation]="data.templateValidation" [isDialog]="true"></cloudlabs-lab-validation>
  </div>
  <div class="modal-footer">
    <teams-button [classList]="'btn ts-btn-fluent-red modal-close-button'" (buttonClick)="onCancel()">
      {{data.failureButtonText}}</teams-button>
    <teams-button [classList]="'btn ts-btn-fluent-primary modal-ok-button'" (buttonClick)="onConfirm()">
      {{data.successButtonText}}</teams-button>
  </div>
</div>