<div id='report' *ngIf="comprehensiveReport">
    <!-- alert for Module Proficiency-->
    <div class="alert-report alert-custom-report alert-outline-primary-report module-proficiency-alert"
        [ngClass]="{'notice-pt': (context === 'PracticeTest'), 'notice-cl': (context === 'CloudLabs')}" role="alert"
        *ngIf="moduleResults && moduleResults.length > 0">
        <div class="alert-icon-report"><i class="fa fa-info-circle" aria-hidden="true"></i></div>
        <div class="alert-text-report">{{'message.ModuleProficiencyAlert' | translate}}</div>
    </div>

    <div class="row mt-3">
        <div class="col-md-12 pl-0 pr-0 mb-5">
            <div class="col-md-12">
                <h4 role="heading" aria-level="2" class="mb-4">{{'exam.result.report.overallperformance' | translate}}
                </h4>
            </div>
            <div class="col-lg-6 col-md-12">
                <div class="card">
                    <div class="card-body">
                        <p-chart type="doughnut" width="400" height="400" [data]="data" [options]="options.options"
                            [style]="{'width': '40%'}">
                        </p-chart>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="row pl-4 mr-5">

        <div class="col-lg-6 col-md-12  warning-msg"
            [ngClass]="{'warning-pt': (context === 'PracticeTest'), 'warning-cl': (context === 'CloudLabs')}">
            <span class="material-icons mr-4 warning-msg-icon">error</span>
            <p class="pt-2 mb-2">{{'message.reportWarningChartMessage' | translate}}</p>
        </div>
    </div>
    <ng-container *ngIf="moduleResults && moduleResults.length > 0">
        <div class="row mt-1">
            <div class="col-md-8 mt-3">
                <h4 role="heading" aria-level="3" class="mb-4">{{'exam.result.report.moduleProficiency' | translate}}
                    ({{moduleResults.length}})</h4>
            </div>
            <div class="col-md-4 pl-0 mt-3 text-right pt-result-percentage">
                <div class="mb-2">
                    <span class="dots dot-green mr-2"></span>
                    <span>{{'exam.result.report.topResults' | translate}}</span>
                </div>
                <div class="mb-2">
                    <span class="dots dot-purple mr-2"></span>
                    <span>{{'exam.result.report.goodResults' | translate}}</span>
                </div>
                <div class="mb-2">
                    <span class="dots dot-red mr-2"></span>
                    <span>{{'exam.result.report.notGoodResults' | translate}}</span>
                </div>
            </div>
        </div>
        <div class="row mt-1 view-more-accordian">
            <div class="col-md-6 mt-1">
                <ng-container *ngFor="let moduleResult of moduleResults; let i = index">
                    <div class="card view-more-card" *ngIf="i % 2 === 0">
                        <div class="card-header">
                            <div class="row">
                                <div class="col-md-8 float-left mt-3">{{moduleResult.moduleName}}</div>
                                <div class="col-md-4 text-right">
                                    <teams-button data-html2canvas-ignore *ngIf="!show[i]" (click)="toggle(i)"
                                        [classList]="'btn ts-btn-fluent-white ts-btn-fluent button-height-1 collapsed'"
                                        data-toggle="collapse" [attr.data-target]="'#collapseDiv' + i"
                                        [title]="'actions.viewMore' | translate">
                                    </teams-button>
                                    <teams-button data-html2canvas-ignore *ngIf="show[i]" (click)="toggle(i)"
                                        [classList]="'btn ts-btn-fluent-white ts-btn-fluent button-height-1 collapsed'"
                                        data-toggle="collapse" [attr.data-target]="'#collapseDiv' + i"
                                        [title]="'actions.viewLess'  | translate">
                                    </teams-button>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-md-12">
                                    <div class="col-md-10 float-left mt-3 pl-0">
                                        {{moduleResult.correctQuestionsCount}} of {{moduleResult.totalQuestions}}
                                        {{'exam.result.report.rightAnswers' | translate}}
                                    </div>
                                    <div class="col-md-2 float-right mt-3 text-right pr-0">
                                        <span>{{moduleResult.modulePercentage}} %</span>
                                    </div>
                                </div>
                            </div>
                            <div class="row mt-3">
                                <div class="col-md-12">
                                    <p-progressBar
                                        [cloudlabsProgressBarArialabel]="moduleResult.moduleName + ' ' + moduleResult.modulePercentage + '%'"
                                        class="no-value-bar"
                                        [ngClass]="{'progressBar-green': moduleResult.modulePercentage <= 100 && moduleResult.modulePercentage >= 70,
                                                    'progresbar-purple': moduleResult.modulePercentage <= 69 && moduleResult.modulePercentage >= 40,
                                                    'progresbar-red': moduleResult.modulePercentage <= 39 && moduleResult.modulePercentage >= 1}"
                                        [value]="moduleResult.modulePercentage" [style]="{ height: '6px' }">
                                    </p-progressBar>
                                </div>
                            </div>
                        </div>
                        <div class="card-body collapse" [ngClass]="{'show': isPdf === false}" id="collapseDiv{{i}}">
                            <div class="row"
                                *ngFor="let submodulesResult of moduleResult.submodulesResult; let j = index">
                                <div class="col-md-6 float-left" data-toggle="collapse"
                                    [attr.data-target]="'#collapse' +i  + j">
                                    {{submodulesResult.subModuleName}}
                                </div>
                                <div class="col-md-4 float-right mt-2" data-toggle="collapse"
                                    [attr.data-target]="'#collapse' + i + j">
                                    <p-progressBar
                                        [cloudlabsProgressBarArialabel]="submodulesResult.subModuleName + ' ' + submodulesResult.subModulePercentage + '%'"
                                        class="no-value-bar"
                                        [ngClass]="{'progressBar-green': submodulesResult.subModulePercentage <= 100 && submodulesResult.subModulePercentage >= 70,
                                                    'progresbar-purple': submodulesResult.subModulePercentage <= 69 && submodulesResult.subModulePercentage >= 40,
                                                    'progresbar-red': submodulesResult.subModulePercentage <= 39 && submodulesResult.subModulePercentage >= 1}"
                                        [value]="submodulesResult.subModulePercentage" [style]="{ height: '6px' }">
                                    </p-progressBar>
                                </div>
                                <div class="col-md-2 pl-0 text-right" data-toggle="collapse"
                                    [attr.data-target]="'#collapse' + i + j">
                                    <span>{{submodulesResult.subModulePercentage}} %</span>
                                </div>
                                <hr class="mt-2">
                            </div>
                            <div>
                                <div class="col-md-12 ms-bgColor-gray10 mt-3">
                                    <div class="col-md-12 pl-0 pb-0 pt-2">
                                        <p>{{'exam.result.report.ctaLink' | translate}}</p>
                                    </div>
                                    <div class="col-md-12 pl-0 pt-0 pb-2"
                                        *ngFor="let link of moduleResult.recommendedLinks;">
                                        <a class="ts-text-primary" href='{{link.link}}'
                                            target="_blank">{{getLinkTitle(link)}}</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </ng-container>
            </div>
            <div class="col-md-6 mt-1">
                <ng-container *ngFor="let moduleResult of moduleResults; let i = index">
                    <div class="card view-more-card" *ngIf="i % 2 !== 0">
                        <div class="card-header">
                            <div class="row">
                                <div class="col-md-8 float-left mt-3">{{moduleResult.moduleName}}</div>
                                <div class="col-md-4 text-right">
                                    <teams-button data-html2canvas-ignore *ngIf="!show[i]" (click)="toggle(i)"
                                        [classList]="'btn ts-btn-fluent-white ts-btn-fluent button-height-1 collapsed'"
                                        data-toggle="collapse" [attr.data-target]="'#collapseDiv' + i"
                                        [title]="'actions.viewMore' | translate">
                                    </teams-button>
                                    <teams-button data-html2canvas-ignore *ngIf="show[i]" (click)="toggle(i)"
                                        [classList]="'btn ts-btn-fluent-white ts-btn-fluent button-height-1 collapsed'"
                                        data-toggle="collapse" [attr.data-target]="'#collapseDiv' + i"
                                        [title]="'actions.viewLess'  | translate">
                                    </teams-button>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-md-12">
                                    <div class="col-md-10 float-left mt-3 pl-0">
                                        {{moduleResult.correctQuestionsCount}} of {{moduleResult.totalQuestions}}
                                        {{'exam.result.report.rightAnswers' | translate}}
                                    </div>
                                    <div class="col-md-2 float-right mt-3 text-right pr-0">
                                        <span>{{moduleResult.modulePercentage}} %</span>
                                    </div>
                                </div>
                            </div>
                            <div class="row mt-3">
                                <div class="col-md-12">
                                    <p-progressBar
                                        [cloudlabsProgressBarArialabel]="moduleResult.moduleName + ' ' + moduleResult.modulePercentage + '%'"
                                        class="no-value-bar"
                                        [ngClass]="{'progressBar-green': moduleResult.modulePercentage <= 100 && moduleResult.modulePercentage >= 70,
                                                    'progresbar-purple': moduleResult.modulePercentage <= 69 && moduleResult.modulePercentage >= 40,
                                                    'progresbar-red': moduleResult.modulePercentage <= 39 && moduleResult.modulePercentage >= 1}"
                                        [value]="moduleResult.modulePercentage" [style]="{ height: '6px' }">
                                    </p-progressBar>
                                </div>
                            </div>
                        </div>
                        <div class="card-body collapse" [ngClass]="{'show': isPdf === false}" id="collapseDiv{{i}}">
                            <div class="row"
                                *ngFor="let submodulesResult of moduleResult.submodulesResult; let j = index">
                                <div class="col-md-6 float-left" data-toggle="collapse"
                                    [attr.data-target]="'#collapse' +i  + j">
                                    {{submodulesResult.subModuleName}}
                                </div>
                                <div class="col-md-4 float-right mt-2" data-toggle="collapse"
                                    [attr.data-target]="'#collapse' + i + j">
                                    <p-progressBar
                                        [cloudlabsProgressBarArialabel]="submodulesResult.subModuleName + ' ' + submodulesResult.subModulePercentage + '%'"
                                        class="no-value-bar"
                                        [ngClass]="{'progressBar-green': submodulesResult.subModulePercentage <= 100 && submodulesResult.subModulePercentage >= 70,
                                                    'progresbar-purple': submodulesResult.subModulePercentage <= 69 && submodulesResult.subModulePercentage >= 40,
                                                    'progresbar-red': submodulesResult.subModulePercentage <= 39 && submodulesResult.subModulePercentage >= 1}"
                                        [value]="submodulesResult.subModulePercentage" [style]="{ height: '6px' }">
                                    </p-progressBar>
                                </div>
                                <div class="col-md-2 pl-0 text-right" data-toggle="collapse"
                                    [attr.data-target]="'#collapse' + i + j">
                                    <span>{{submodulesResult.subModulePercentage}} %</span>
                                </div>
                                <hr class="mt-2">
                            </div>
                            <div>
                                <div class="col-md-12 ms-bgColor-gray10 mt-3">
                                    <div class="col-md-12 pl-0 pb-0 pt-2">
                                        <p>{{'exam.result.report.ctaLink' | translate}}</p>
                                    </div>
                                    <div class="col-md-12 pl-0 pt-0 pb-2"
                                        *ngFor="let link of moduleResult.recommendedLinks">
                                        <a href='{{link.link}}' target="_blank">{{getLinkTitle(link)}}</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </ng-container>
            </div>
        </div>
    </ng-container>

    <ng-container *ngIf="productResults && productResults.length > 0">
        <div class="row mt-1">
            <div class="col-md-8 mt-3">
                <h4 role="heading" aria-level="4" class="mb-4">{{'exam.result.report.productProficiency' | translate}}
                    ({{productResults.length}})
                </h4>
            </div>
            <div class="col-md-4 pl-0 mt-3 text-right pt-result-percentage">
                <div class="mb-2">
                    <span class="dots dot-green mr-2"></span>
                    <span>{{'exam.result.report.topResults' | translate}}</span>
                </div>
                <div class="mb-2">
                    <span class="dots dot-purple mr-2"></span>
                    <span>{{'exam.result.report.goodResults' | translate}}</span>
                </div>
                <div class="mb-2">
                    <span class="dots dot-red mr-2"></span>
                    <span>{{'exam.result.report.notGoodResults' | translate}}</span>
                </div>
            </div>
        </div>
        <div class="row mt-1 view-more-accordian">
            <div class="col-md-6 mt-1" *ngFor="let productResult of productResults; let i = index">
                <div class="card">
                    <div class="card-body">
                        <div class="row">
                            <div class="col-md-6">{{productResult.productName}}
                            </div>
                            <div class="col-md-4 mt-2">
                                <p-progressBar
                                    [cloudlabsProgressBarArialabel]="productResult.productName + ' ' + productResult.correctPercentage + '%'"
                                    class="no-value-bar"
                                    [ngClass]="{'progressBar-green': productResult.correctPercentage <= 100 && productResult.correctPercentage >= 70,
                                        'progresbar-purple': productResult.correctPercentage <= 69 && productResult.correctPercentage >= 40,
                                        'progresbar-red': productResult.correctPercentage <= 39 && productResult.correctPercentage >= 1}"
                                    [value]="productResult.correctPercentage" [style]="{ height: '6px' }">
                                </p-progressBar>
                            </div>
                            <div class="col-md-2 text-right">
                                {{productResult.correctPercentage}} %
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </ng-container>

</div>