<ng-container *ngIf="isLoaded; else loader" >
    <ng-container *ngFor="let question of questionList; let i = index">
        <div class="card-new-drop card view-more-card performance-task">
            <div class="card-header question-card">
                <div class="row list-title">
                    <div class="col-md-8 float-left list-content">
                        <i class="fas fa-check-circle mr-4 check-circle green-icon" [ngClass]="{'check-icon' : isComplete[i]}" ></i>
                        {{'exam.section.performanceTask.task' | translate}} {{i+1}}</div>
                    <div class="col-md-4 text-right">
                        <cloudlabs-toggle-dropdown [i]="i" (toggleClick)="toggle($event,i)" [show_val]="show" [activeIndex]="activeIndex" (activeClick)="activeClick($event)"></cloudlabs-toggle-dropdown>
                    </div>
                </div>
            </div>
            <div class="card-body performance-task-body collapse" *ngIf="show[i]"  id="collapseDiv{{i}}">
                <div class="row">
                    <div class="col-md-12">
                        <p [innerHTML]="question.question | targetBlank"></p>
                    </div>
                    <div class="col-md-12 mt-4" *ngIf="!isComplete[i]">
                        <teams-button [classList]="'mark-btn'" (buttonClick)="isChecked(i, question)">
                            {{'exam.section.performanceTask.markAsComplete' | translate}}
                        </teams-button>
                    </div>
                    <span class="ml-2 pl-2 text-primary primary-color-darken" *ngIf="isComplete[i]">
                        <i class="fa fa-check text-primary primary-color-darken" aria-hidden="true"></i>
                        {{ 'MY_LEARNING.COMMON.COMPLETED' | translate}}
                    </span>
                </div>
            </div>
        </div>
    </ng-container>
</ng-container>
<ng-template #loader>
    <div class="text-center">
        <div class="lds-ring">
            <div></div>
            <div></div>
            <div></div>
            <div></div>
        </div>
    </div>
</ng-template>