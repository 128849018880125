import { Inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { FormField } from '../modals/form-field.model';
import { Country } from '../modals/common.model';

@Injectable({
  providedIn: 'root',
})
export class RegistrationService {
  apiUrl: string;

  constructor(
    private httpClient: HttpClient,
    @Inject('environment')
    environment
  ) {
    if (environment.apiUrl) {
      this.apiUrl = environment.apiUrl;
    } else this.apiUrl = environment.APIURL;
  }

  /**
   * Get all form fields
   */

  getOrgSuggestionList(country: string, query: string) {
    return this.httpClient.post<string[]>(
      `${this.apiUrl}AttendeePublic/GetOrganizations`,
      {
        Country: country,
        Organization: query,
      }
    );
  }
  getSignUpField(id): Observable<FormField[]> {
    return this.httpClient.get<FormField[]>(
      `${this.apiUrl}Customization/GetSignUpFieldsByEventId?eventId=0&InternalId=${id}`
    );
  }

  getLabClusterSignUpField(id): Observable<FormField[]> {
    return this.httpClient.get<FormField[]>(
      `${this.apiUrl}Customization/GetSignUpFieldsByLabClusterUniqueIdentifier?uniqueIdentifier=${id}`
    );
  }

  /**
   * Make a call to sign up a new user
   * @param id
   * @param params
   */
  signup(id: string, params: any): Observable<any> {
    return this.httpClient.post<any>(
      `${this.apiUrl}OnDemandLabUser/Register?internalId=${id}`,
      params
    );
  }

  /**
   * Get list of countries
   */
  getCountries(): Observable<Country[]> {
    return this.httpClient.get<Country[]>(`./assets/json/countries.json`);
  }
  signUpForLabCluster(uniqueIdentifier: string, params: any): Observable<any> {
    return this.httpClient.post<any>(
      `${this.apiUrl}LabCluster/Register?uniqueIdentifier=${uniqueIdentifier}`,
      params
    );
  }
  addSubscriptionForAzurePass(body, internalId, voucherCode) {
    return this.httpClient.post<any>(
      `${
        this.apiUrl
      }AttendeeTestDrive/CreateAzurePassSubscription/${encodeURIComponent(
        internalId
      )}${voucherCode ? `/${voucherCode}` : ''}`,
      body
    );
  }
}
