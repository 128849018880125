import { Component, EventEmitter, Input, OnChanges, Output } from '@angular/core';

import { miscellaneousConst } from '@utility';

import { QuestionHelperService } from '../../../services/question/question-helper.service';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'build-list-question',
  templateUrl: './build-list-question.component.html',
})
export class BuildListQuestionComponent implements OnChanges {
  @Input() public question;
  public options: Array<any> = [];
  public dropOptions: any = [];
  public selectedLeftValue: any = '';
  public selectedRightValue: any = '';
  @Input() versionKey: string;
  @Output() UserSelectAnswerOption = new EventEmitter();
  @Input() userAnswer = [];
  public miscellaneousConst = miscellaneousConst;

  constructor(
    private questionHelper: QuestionHelperService
  ) { }

  ngOnChanges(): void {
    this.onLoadData();
  }
  /**
   * @description: Load Data First time
   */
  private onLoadData(): void {
    if (this.question !== undefined) {
      this.question.labels.forEach((element) => {
        element.option = [];
      });
      this.options = this.question.options;
      this.question.options.forEach((element) => {
        element.disabled = false;
      });
    }
    if (this.question.userAnswers.length > 0) {
      this.dropOptions = this.question.userAnswers;
      this.options
        .filter((i) => this.question.userAnswers.some((o) => i.key === o.key))
        .forEach((element) => {
          element.disabled = true;
        });
    }
    this.dropOptions = this.userAnswer;
  }
  /**
   * @description: Drop List Data
   */
  public onListValueDrop(e: any): void {
    this.questionHelper.onBuildListValueDrop(this.question, e, this.dropOptions, this.options);
    this.saveBuildList();
  }
  /**
   * @description: Move Left  to Right
   */
  public leftToRightMove(): void {
    if (this.selectedLeftValue !== '') {
      this.questionHelper.leftToRightMoveBuildList(this.selectedLeftValue, this.options, this.dropOptions);
      this.saveBuildList();
      this.selectedLeftValue = '';
    }
  }
  /**
   * @description: Move Right to Left
   */
  public rightToLeftMove(): void {
    if (this.selectedRightValue !== '') {
      this.dropOptions = this.questionHelper
        .rightToLeftMoveBuildList(this.selectedRightValue, this.dropOptions, this.options).dropOptionsValue;
      this.saveBuildList();
      this.selectedRightValue = '';
      this.selectedLeftValue = '';
    }
  }
  /**
   * @description: Selected Left Options
   */
  public selectedLeftOptions(value: any): void {
    this.selectedLeftValue = value;
  }
  /**
   * @description: Selected Right Options
   */
  public selectedRightOptions(value: any): void {
    this.selectedRightValue = value;
  }
  /**
   * @description: Move Data to Up
   */
  public upListValue(): void {
    if (this.selectedRightValue !== '') {
      this.questionHelper.upListValueBuildList(this.selectedRightValue, this.dropOptions);
      this.saveBuildList();
      this.selectedRightValue = '';
    }
  }
  /**
   * @description: Move Data to Down
   */
  public downListValue(): void {
    if (this.selectedRightValue !== '') {
      this.questionHelper.downListValueBuildList(this.selectedRightValue, this.dropOptions);
      this.saveBuildList();
      this.selectedRightValue = '';
    }
  }
  /**
   * @description: Save Build List
   */
  private saveBuildList(): void {
    const userAnswers = [];
    this.dropOptions.forEach(e => {
      userAnswers.push({
        key: e.key,
        value: e.value
      });
    });
    if(this.question?.isVersioned) {
      this.question?.questionVersions.forEach((element, val) => {
        
        if(element.versionKey === this.versionKey) {
          element.userAnswers = userAnswers
          this.question.userAnswers = userAnswers;
        }
      });
    } else {
      this.question.userAnswers = userAnswers;
    }
    this.UserSelectAnswerOption.emit({ answer: this.question });
  }
}
