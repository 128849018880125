<div class="navbar navbar-expand-lg py-0 headerBg" role="banner">
  <teams-button
    *ngIf="menu && IsEdge"
    [classList]="'hamburgerButton'"
    [style]="'background: none; border: none; width: 50px'"
    data-title="Hamburger Menu"
    [titleText]="'Hamburger Menu'"
    [ariaLabelledby]="'sideBarDescription'"
    (buttonClick)="openSideNav()"
  >
    <i class="fa fa-bars menus" aria-hidden="true"></i>
  </teams-button>
  <teams-button
    *ngIf="menu && !IsEdge"
    [classList]="'hamburgerButton'"
    [style]="'background: none; border: none; width: 50px'"
    data-title="Hamburger Menu"
    [titleText]="'Hamburger Menu'"
    [ariaLabelledby]="'sideBarDescription'"
    (buttonClick)="openSideNav()"
  >
    <i class="fa fa-bars menus" aria-hidden="true"></i>
  </teams-button>
  <div class="logo-m">
    <a
      *ngIf="customLogo"
      class="navbar-brand"
      href="javascript:void(0)"
      style="margin-left: 4px"
      aria-label="Microsoft logo"
      tabindex="-1"
    >
      <img
        alt="Microsoft logo"
        class="mr-1"
        [src]="customLogo"
        style="height: 28px; width: auto; background: transparent"
      />
    </a>
    <a
      *ngIf="!customLogo"
      class="navbar-brand"
      href="javascript:void(0)"
      style="margin-left: 4px"
      aria-label="Microsoft logo"
      tabindex="-1"
    >
      <img
        alt="Microsoft logo"
        class="mr-1"
        src="/assets/images/CloudLabs_Fullwhite.png"
        style="height: 28px; width: auto; background: transparent"
      />
    </a>
  </div>
  <div class="right-block ml-auto">
    <!-- <a *ngIf="labuser"class="email" href="javascript:void()">{{labuser.Email}}</a> | -->
    <div class="dropdown lang text-right mt-4">
      <!-- Language dropdown-->
      <teams-button
        [classList]="'dropdown-toggle'"
        attr.aria-label="change Language {{ languageAriaLabel }} selected"
        data-toggle="dropdown"
        aria-haspopup="listbox"
      >
        <i role="option" class="pr-1 fa fa-globe" aria-hidden="true"></i
        >{{ showLanguage }}
      </teams-button>
      <div id="langDropdown" tabindex="-1" class="dropdown-menu" role="listbox">
        <a
          role="option"
          *ngFor="let language of languages"
          style="text-decoration: none"
          class="dropdown-item"
          attr.aria-label="{{ language.Name }}"
          href="javascript:void(0)"
          (click)="switchLanguage(language.Code)"
          >{{ language.Description }}</a
        >
        <!--       <a class="dropdown-item" href="javascript:void(0)" (click)="switchLanguage('es')">Spanish (Española)</a>-->
        <!--<a class="dropdown-item" href="javascript:void(0)" (click)="switchLanguage('fr')">French (Française)</a>
          <a class="dropdown-item" href="javascript:void(0)" (click)="switchLanguage('ja')">Japanese (日本人)</a>-->
      </div>
    </div>
  </div>
</div>
<div class="header-pad"></div>
