import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FooterComponent } from './footer/footer.component';
import { HeaderComponent } from './header/header.component';
import { RefinedHeaderComponent } from './refined-header/refined-header.component';
import { TranslateModule } from '@ngx-translate/core';
import { TooltipConfig, TooltipModule } from 'ngx-bootstrap/tooltip';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ModalModule } from 'ngx-bootstrap/modal';
import { CountdownModule } from 'ngx-countdown';
import { ClipboardModule } from 'ngx-clipboard';

import { EnvironmentDeleteDialogComponent } from './environment-delete-dialog/environment-delete-dialog.component';
import { ResourceActionDialogComponent } from './resource-action-dialog/resource-action-dialog.component';
import { CompleteTestdriveDialogComponent } from './complete-testdrive-dialog/complete-testdrive-dialog.component';
import { ActivityTrackingComponent } from './activity-tracking/activity-tracking.component';
import { DurationDialogComponent } from './duration-dialog/duration-dialog.component';
import { ExtendDurationComponent } from './extend-duration/extend-duration.component';
import { ToggleHeaderComponent } from './toggle-header/toggle-header.component';
import { LabGuideComponent } from './lab-guide/lab-guide.component';
import { LabEnvironmentComponent } from './lab-environment/lab-environment.component';
import { ThemeComponent } from './theme/theme.component';
import { LessionComponent } from './lession/lession.component';
import { LabValidationComponent } from './lab-validation/lab-validation.component';
import { VirtualMachineComponent } from './virtual-machine/virtual-machine.component';
import { VmDialogComponent } from './vm-dialog/vm-dialog.component';
import { DocGuideComponent } from './doc-guide/doc-guide.component';
import { AutoCompleteComponent } from './auto-complete/auto-complete.component';
import { NotesComponent } from './notes/notes.component';
import { NoteComponent } from './notes/note/note.component';
import { LabValidationDialogComponent } from './lab-validation-dialog/lab-validation-dialog.component';
import { RdpDialogComponent } from '../labenvironment/components/environment/rdp-dialog/rdp-dialog.component';
import { ResourceComponent } from './resource/resource.component';
import { SurveyFormComponent } from './survey-form/survey-form.component';
import { SurveyDialogComponent } from './survey-form/survey-dialog/survey-dialog.component';
import { SurveyThankyouDialogComponent } from './survey-form/survey-thankyou-dialog/survey-thankyou-dialog.component';

import { ArrowKeysAccessibleDirective } from '../../directives/arrow-keys-accessible.directive';

import { DialogRolePipe } from '../../pipes/dialog-role.pipe';
import { HighlightPipe } from './auto-complete/highlight.pipe';
import { CalculateDurationPipe } from '../../pipes/calculate-duration.pipe';
import { FormatTimePipe } from '../../pipes/format-time.pipe';
import { PascalCasePipe } from '../../pipes/pascal-case.pipe';
import { HtmlSanitizerPipe } from '../../pipes/sanitize-html.pipe';
import { RemoveHttpPipe } from '../../pipes/remove-http.pipe';
import { TimeTransformPipe } from '../../pipes/time-transform.pipe';

import { PaginationModule } from './pagination/pagination.module';

import { NotificationService } from '../../services/notification.service';
import { UtilityService } from '../../services/utility.service';
import { NotesService } from '../../services/notes.service';
import { ProgressTimeDirective } from '../../directives/progress-time.directive';
import { LabService } from '../../services/lab.service';
import { LabClusterService } from '../../services/lab-cluster.service';
import { LabResolver } from '../../resolver/lab-resolver';
import { DebounceClickDirective } from '../../directives/debouce-click.directive';

import { TeamsUiModule } from '@teams-ui-angular';
import { UtilityModule } from '@utility';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { TrialEnvironmentComponent } from './trial-environment/trial-environment.component';
import { TrialResourceComponent } from './trial-resource/trial-resource.component';
import { FocusInvalidInputDirective } from '../../directives/focus-invalid-input.directive';
import { AutoFocusModalDirective } from '../../directives/auto-focus-modal.directive';
import { LabFocusDirective } from '../../directives/lab-focus.directive';
import { RefocusPreviousElDirective } from '../../directives/refocus-previous-el.directive';
import { RemoveFocusOkButtonDirective } from '../../directives/remove-focus-ok-button.directive';
import { RemoveFocusOnPressDirective } from '../../directives/remove-focus-on-press.directive';
import { RestartDialogDirective } from '../../directives/restart-dialog.directive';
import { RoleDialogDirective } from '../../directives/role-dialog.directive';
import { VmRoleDirective } from '../../directives/vm-role.directive';
import { WhiteSpaceValidatorDirective } from '../../directives/white-space-validator.directive';
import { TrapFocusDirective } from '../../directives/trap-focus.directive';
import { EnvironmentDeallocateDialogComponent } from './environment-deallocate-dialog/environment-deallocate-dialog.component';
import { QuestionsUiModule } from '@questions-ui';
import { InlineQuestionComponent } from './inline-question/inline-question.component';
import { DecimalNotAllowedDirective } from '../../directives/decimal-not-allowed.directive';
import { ProgressBarModule } from 'primeng/progressbar';
import { ExtendCreditComponent } from './extend-credit/extend-credit.component';
import { ProgressSpinnerModule } from 'primeng/progressspinner';
import { VideoComponent } from './video/video.component';

export function getTooltipConfig(): TooltipConfig {
  return Object.assign(new TooltipConfig(), {
    placement: 'auto',
    container: 'body',
    triggers: 'hover focus'
  });
}
@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    TranslateModule,
    ClipboardModule,
    PaginationModule,
    ModalModule.forRoot(),
    CountdownModule,
    TooltipModule,
    TeamsUiModule,
    UtilityModule,
    NgbModule,
    QuestionsUiModule,
    ProgressBarModule,
    ProgressSpinnerModule
  ],
  declarations: [
    HeaderComponent,
    RefinedHeaderComponent,
    FooterComponent,
    ThemeComponent,
    LessionComponent,
    LabGuideComponent,
    CalculateDurationPipe,
    FormatTimePipe,
    LabEnvironmentComponent,
    LabValidationComponent,
    VirtualMachineComponent,
    VmDialogComponent,
    DurationDialogComponent,
    DocGuideComponent,
    ExtendDurationComponent,
    AutoCompleteComponent,
    HighlightPipe,
    AutoCompleteComponent,
    ActivityTrackingComponent,
    CompleteTestdriveDialogComponent,
    PascalCasePipe,
    HtmlSanitizerPipe,
    RemoveHttpPipe,
    ResourceActionDialogComponent,
    NotesComponent,
    NoteComponent,
    TimeTransformPipe,
    LabValidationDialogComponent,
    DialogRolePipe,
    RdpDialogComponent,
    ResourceComponent,
    ToggleHeaderComponent,
    EnvironmentDeleteDialogComponent,
    SurveyFormComponent,
    SurveyDialogComponent,
    SurveyThankyouDialogComponent,
    ArrowKeysAccessibleDirective,
    DebounceClickDirective,
    ProgressTimeDirective,
    TrialEnvironmentComponent,
    TrialResourceComponent,
    AutoFocusModalDirective,
    FocusInvalidInputDirective,
    LabFocusDirective,
    RefocusPreviousElDirective,
    RemoveFocusOkButtonDirective,
    RemoveFocusOnPressDirective,
    RestartDialogDirective,
    RoleDialogDirective,
    TrapFocusDirective,
    VmRoleDirective,
    WhiteSpaceValidatorDirective,
    EnvironmentDeallocateDialogComponent,
    InlineQuestionComponent,
    DecimalNotAllowedDirective,
    ExtendCreditComponent,
    VideoComponent
  ],
  exports: [
    TranslateModule,
    FooterComponent,
    ThemeComponent,
    HeaderComponent,
    RefinedHeaderComponent,
    LessionComponent,
    LabGuideComponent,
    LabEnvironmentComponent,
    LabValidationComponent,
    CalculateDurationPipe,
    FormatTimePipe,
    VirtualMachineComponent,
    DocGuideComponent,
    AutoCompleteComponent,
    HtmlSanitizerPipe,
    PaginationModule,
    NotesComponent,
    TimeTransformPipe,
    ToggleHeaderComponent,
    SurveyFormComponent,
    VmDialogComponent,
    ArrowKeysAccessibleDirective,
    DebounceClickDirective,
    ProgressTimeDirective,
    TrialEnvironmentComponent,
    TrialResourceComponent,
    AutoFocusModalDirective,
    FocusInvalidInputDirective,
    LabFocusDirective,
    RefocusPreviousElDirective,
    RemoveFocusOkButtonDirective,
    RemoveFocusOnPressDirective,
    RestartDialogDirective,
    RoleDialogDirective,
    TrapFocusDirective,
    VmRoleDirective,
    WhiteSpaceValidatorDirective,
    EnvironmentDeallocateDialogComponent,
    DialogRolePipe,
    InlineQuestionComponent,
    DecimalNotAllowedDirective
  ],
  providers: [
    LabResolver,
    LabClusterService,
    LabService,
    NotificationService,
    UtilityService,
    NotesService,
    TooltipModule,
    [{ provide: TooltipConfig, useFactory: getTooltipConfig }],
  ],
})
export class SharedModule {}
