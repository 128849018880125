import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
} from '@angular/core';

import { miscellaneousConst } from '@utility';

import {
  TabModel,
  SubTabModel,
} from '../../model/common.model';

import { CaseStudyHelperService } from '../../services/case-study-helper.service';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'app-casestudy-side-menu',
  templateUrl: './casestudy-side-menu.component.html',
})
export class CasestudySideMenuComponent implements OnChanges {
  @Input() public tabs: TabModel[] = [];
  public subTabs: SubTabModel[] = [];
  public showQuestions = true;
  @Output() sideMenuOptionChange = new EventEmitter();
  @Output() tabIndexEmit = new EventEmitter();
  @Input() subtab = 0;
  public index: number;
  public miscellaneousConst = miscellaneousConst;
  constructor(private caseStudyHelperService: CaseStudyHelperService) {}

  /**
   * @description: Call when change data
   */
  ngOnChanges(): void {
    this.loadTabs();
  }
  /**
   * @description: Load First time Tabs
   */
  private loadTabs(): void {
    if (this.tabs?.length > 0) {
      this.tabs.forEach((e) => {
        e.open = false;
        e.active = false;
        this.subTabs = e.subTabs;
        if (this.subTabs?.length > 0) {
          this.subTabs.forEach((ele) => {
            ele.active = false;
            ele.open = false;
          });
        }
      });
    }
  }

  /**
   * @description: Open Sub Tab
   */
  public openSubTab(tab): void {
    if (this.tabs.length > 0) {
      this.tabs.find((i, ind) => i.title === tab.title).open = !tab.open;
    }
  }
  /**
   * @description: Close Sub Tab
   */
  public closeSubTab(tab): void {
    if (this.tabs.length > 0) {
      const tabs = this.caseStudyHelperService.closeSubTab(
        this.tabs,
        tab,
        this.subTabs
      );
      this.tabs = tabs.tabsValue;
      this.subTabs = tabs.subTabsValue;
    }
  }
  /**
   * @description: Currently Active Tab
   */
  public activeTabs(tab, ind): void {
    const activeTab = this.caseStudyHelperService.activeTabs(
      this.tabs,
      tab,
      this.showQuestions
    );
    const tabValue = activeTab.tabsValue;
    this.showQuestions = activeTab.showQuestionsValue;
    this.sideMenuOptionChange.emit({
      que: this.showQuestions,
      title: tab.title,
    });
    this.tabIndexEmit.emit({ index: ind, tabs: tabValue });
  }
  /**
   * @description: View Question Data
   */
  public showQuestionData(): void {
    this.loadTabs();
    this.showQuestions = true;
    this.sideMenuOptionChange.emit({ que: this.showQuestions, title: null });
  }
  public getTabIndex(event): void {
    this.index = event.index;
    const tabValue = event.tabs;
    this.tabIndexEmit.emit({ index: this.index, tabs: tabValue });
  }
}
