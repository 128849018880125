import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'cloudlabs-performance-task',
  templateUrl: './performance-task.component.html',
  styleUrls: ['./performance-task.component.scss']
})
export class PerformanceTaskComponent implements OnInit {
  public showCredentialsInformation = false;
  @Output() showQuestionEmit = new EventEmitter<boolean>();
  @Input() questionList;
  @Input() sectionType;
  public selectedMenu = 'credIntruct';
  public showQuestionsDeatils = false;
  public menuList = [
    { name: 'exam.section.performanceQuiz.credIntruct', id: 'credIntruct' },
    { name: 'exam.section.performanceTask.allTask', id: 'alltask'}
];

public collapseCards: any = {
  credCard: 'expand_more',
  challengCard:'chevron_right'
}


  constructor( 
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    ) {
    }

  ngOnInit(): void {
    this.showCredentialsInfo();
  }

  public onMenuSelect(index: number): void {
    switch (this.menuList[index].id) {
      case 'credIntruct':
        this.showCredentialsInfo();
        break;
      case 'alltask':
        this.showAllTask();
        this.showQuestions();
        break;
    }
  }

  public showQuestions(): void {
    this.showQuestionsDeatils = true;
    this.showQuestionEmit.emit(this.showQuestionsDeatils);
    this.showCredentialsInformation = false;
  }

  /**
   * @description: Show Credentials Information
   */
   public showCredentialsInfo(): void {
    this.showCredentialsInformation = true;
    this.showQuestionsDeatils = false;
    this.showQuestionEmit.emit(this.showQuestionsDeatils);
  }

  /**
   * @description: Show Question Data
   */
  public showAllTask(): void {
    this.showQuestionsDeatils = true;
    this.showCredentialsInformation = false;
  }
  
  toggle(value: string) {
    this.collapseCards[value] = this.collapseCards[value] == 'chevron_right'?'expand_more':'chevron_right'
    if(value === 'credCard'){
      this.showCredentialsInfo();
      this.collapseCards.challengCard = 'chevron_right'
    }else{
      this.showAllTask();
      this.showQuestions();
      this.collapseCards.credCard = 'chevron_right'
    }
  }
  

}
