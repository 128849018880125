import { Component } from '@angular/core';
import { detect } from 'detect-browser';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { Subject } from 'rxjs';
import { EventService } from '../../../../../services/event.services';

@Component({
  selector: 'cloudlabs-rdp-dialog',
  templateUrl: './rdp-dialog.component.html',
  styleUrls: ['./rdp-dialog.component.scss'],
})
export class RdpDialogComponent {
  public title: string;
  public browser = detect();
  public data: any;
  public edgePopUpHeading: boolean;
  public onClose: Subject<boolean>;
  constructor(public modalRef: BsModalRef, public eventSrv: EventService) {}

  public onConfirm(): void {
    this.eventSrv.emmitRDPDialogConfirmClosed();
    this.modalRef.hide();
  }

  public onCancel(): void {
    this.eventSrv.emmitRDPDialogClosed();
    this.modalRef.hide();
  }
}
