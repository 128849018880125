import {
  Component,
  OnInit,
  ViewChild,
  ElementRef,
  HostListener,
  OnDestroy,
  NgZone,
  ViewEncapsulation,
  Renderer2,
  AfterViewInit,
  Inject,
  ChangeDetectorRef,
} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ClipboardService, IClipboardResponse } from 'ngx-clipboard';
import * as moment from 'moment';
import { interval, Subject, Subscription } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { detect } from 'detect-browser';
import { Message, MultiWindowService } from 'ngx-multi-window';
import { Title } from '@angular/platform-browser';

import {
  Lab,
  Deployment,
  EnvironmentLayout,
  ActionListItem,
  ActionItems,
  AppType,
  CustomerType,
} from '../../../../modals/lab.model';

import { DurationDialogComponent } from '../../../shared/duration-dialog/duration-dialog.component';
import { ExtendDurationComponent } from '../../../shared/extend-duration/extend-duration.component';
import { ActivityTrackingComponent } from '../../../shared/activity-tracking/activity-tracking.component';
import { CompleteTestdriveDialogComponent } from '../../../shared/complete-testdrive-dialog/complete-testdrive-dialog.component';
import { ResourceActionDialogComponent } from '../../../shared/resource-action-dialog/resource-action-dialog.component';
import { EnvironmentDeleteDialogComponent } from '../../../shared/environment-delete-dialog/environment-delete-dialog.component';

import { NotificationService } from '../../../../services/notification.service';
import { EventEmitterService, UserSessionService } from '@teams-auth';
import { LabService } from '../../../../services/lab.service';
import { RemoteDesktopManager } from '../../../remote/services/remote-desktop-manager.service';
import { EventService } from '../../../../services/event.services';
import {
  copyToClipboard,
  emptySelection,
  getEntity,
  getEntityData,
  getPopupWidthHeightBasedOnImage,
  miscellaneousConst,
  unsubscribeCollection,
  checkSingularPlural,
  getTimeZone,
} from '@utility';
import { UtilityService } from '../../../../services/utility.service';
import { EventTypeEnum } from '../../../../modals/eventTypeEnum';
import { NotesService } from '../../../../services/notes.service';
import { Location } from '@angular/common';
import {
  AppInsightEvents,
  RDPOperation,
  PropertyName,
} from '@libs/teams-auth/src/lib/_consts/app-insight.const';
import { ExtendCreditComponent } from '../../../shared/extend-credit/extend-credit.component';
declare let $: any;

@Component({
  selector: 'cloudlabs-environment',
  templateUrl: './environment.component.html',
  styleUrls: ['./environment.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class EnvironmentComponent implements OnInit, OnDestroy, AfterViewInit {
  private subscription = new Subscription();
  public environment;
  public minimumRemainingTimeForLabExtension;
  public isSidenav = false;
  public browser = detect();
  public edgePopUpHeading: boolean;
  public mywindow: any;
  public isDraggable = false;
  public loading = true;
  public loader = true;
  public lab: Lab;
  public vCode: string;
  public id: any;
  public manager: RemoteDesktopManager;
  public remoteConnectInProgress = false;
  public showRefreshButton = false;
  public inProgressIndicator = false;
  public remoteConnectConnectionClose = false;
  public remoteConnectProcessStatus = '';
  public currentRemoteConnect: string;
  public guacamoleServerUrl: string;
  public rdpUri: string;
  public token: string;
  public Vmurl: any;
  public guideData: Deployment;
  public isTokenValid = true;
  public minutes: number;
  public hours: number;
  public day: number;
  public countDownTimer: any;
  public stopTimer: boolean;
  public enableHeader = false;
  public vmName: string;
  public isComplete = false;
  public labComplete = false;
  public sizeChanged: Subject<boolean>;
  public sizeChangedDebounced;
  public sizeChanged1: Subject<boolean>;
  public sizeChangedDebounced1;
  public modalRef: BsModalRef;
  public enableduration = false;
  public vmStatus: any;
  public isAttendeeActivityLogPolling = false;
  public attendeeActivityLogPolling;
  public resourceStartPolling;
  public modeOfOperation;
  public openLabInactivityPopup = false;
  public deleteEnvironmentPopup = false;
  public resourceStopPopup = false;
  public idleCounter = 0;
  public currentTestDriveUserData: any;
  public IsHotInstanceAvailable: boolean;
  public DeploymentDuration: any;
  public isDeploying = false;
  public idleTimeout: any;
  public WarningWaitTimeout: any;
  public initialDeployment: any;
  public error: any;
  public vmstate = false;
  public sidebarHeaderHeight = 0;
  public labTitle = '';
  public lang: string;
  public internalId: string;
  public remoteDesktopStates = RemoteDesktopManager.STATE;
  public stoppedByIdleTimeout: any;
  public readAlertMessage = false;
  public docguidescreen = true;
  public hidingAlertMessages = false;
  public isMylearning = false;
  public hidingRdpAriaLabel =
    'Press enter to focus in to Remote Desktop. You can focus out of VM by pressing Escape key.';

  prevSidebarState = true;
  public canExtendFurther = true;
  public splitKey: string;
  resourceStatus: string;
  private isSwitchVm = false;
  public showFileTransferPopUp = false;
  isPageRefresh = false;

  showImagePopup = false;
  imagePopupElement: string;
  imagePopupStyle: { width: string; height: string };

  private subscriptions: Subscription[] = [];
  // Making loadGitDoc varible value always TRUE as we don't want to hide doc guide component | Sheikh
  public loadGitDoc = true;
  public environmentLayout = EnvironmentLayout;
  public layoutType: string = null;
  public counter = 0;
  public actionList: Array<ActionListItem> = [];
  public modelShow = false;
  public modalData: any;
  public endDateData = null;
  public notes;
  public isMobile = true;
  public cloudPlateFormId: number;
  public showResourcesTab;
  public toggleFullScreenforCLPortal: boolean;
  public showFullScreen: boolean;
  public templateValidation: any;
  public isLabValidation = false;
  steps = [
    { status: null, state: 'active', description: null, statusLabel: null },
    { status: null, state: 'pending', description: null, statusLabel: null },
    { status: null, state: 'pending', description: null, statusLabel: null },
  ];
  currentVMdetail: any;
  resourcesOperation = false;
  vmErrorMessage: string;
  containerInterval = null;
  vmInterval = null;
  isSplitWindow = false;
  public osInfo: string;

  @ViewChild('sidebarHeader') sidebarHeader: ElementRef;
  @ViewChild('docGuide') el: ElementRef;
  @ViewChild('expand') expand: ElementRef;
  @ViewChild('collapse') collapse: ElementRef;

  @HostListener('document:click', ['$event'])
  async handleClick($event) {
    if (($event.target as HTMLElement)?.classList.contains('img-click')) {
      this.processImageBeforePopup($event);
    }
  }

  @HostListener('window:keydown', ['$event'])
  async handleKeyPress($event: any) {
    if ($event.key == 'Enter' && $event.keyCode == 13) {
      if (($event.target as HTMLElement)?.classList.contains('img-click')) {
        this.processImageBeforePopup($event);
      }
    }
  }

  /**
   * On close current window
   * @param event
   */

  @HostListener('window:beforeunload', ['$event'])
  onWindowClose(event: any): void {
    localStorage.removeItem(this.splitKey);
    const vmTabKey = `vmTab_${this.lab.UniqueName}_${this.vCode}`;
    localStorage.removeItem(vmTabKey);
    this.isPageRefresh = true;
    this.JoinWindow();
  }
  /**
   * Track activity on mouse move too
   */
  @HostListener('window:mousemove', ['$event'])
  async onMouseMove(e) {
    this.idleCounter = 0;
  }

  @HostListener('copy', ['$event'])
  async onCopy(e: ClipboardEvent) {
    this.eventSrv.onCopy();
  }

  /**
   * Manage enter key event
   * @param $event
   */
  async onkeyPress($event: any) {
    this.idleCounter = 0;
    if ($event.key == 'Enter' && $event.keyCode == 13) {
      if (
        $event.target.id === 'copyelement' ||
        ($event.target as HTMLElement).classList.contains('copyelement')
      ) {
        emptySelection();
        // eslint-disable-next-line no-useless-escape
        const brRegex = /<br\s*[\/]?>/gi;
        const copyData =
          $event.target.innerText || $event.target.firstChild.innerText;
        const txtdata = copyData.replace(brRegex, '\r\n');
        if (this.manager) {
          this.manager.sendRemoteClipboardData(txtdata);
        }
        await copyToClipboard(txtdata);
        this.notificationService.success(
          this.translateSrv.instant('MESSAGE_COPIED'),
          null
        );
        (document.activeElement as any).blur();
      }
    }
  }
  /**
   * On resize window
   * @param event
   */
  @HostListener('window:resize', ['$event'])
  onResize(event?) {
    this.idleCounter = 0;
    // this.sizeChanged.next(true);
    this.manageSibebarOnResponsive();
  }

  /**End */
  constructor(
    private aroute: ActivatedRoute,
    private labService: LabService,
    private clipboardService: ClipboardService,
    private router: Router,
    private translateSrv: TranslateService,
    private modalService: BsModalService,
    private notificationService: NotificationService,
    private userSessionService: UserSessionService,
    private eventSrv: EventService,
    private ngZone: NgZone,
    private renderer: Renderer2,
    private titleService: Title,
    private util: UtilityService,
    private multiWindowService: MultiWindowService,
    private cdr: ChangeDetectorRef,
    private eventEmitter: EventEmitterService,
    private notesService: NotesService,
    private location: Location,
    @Inject('environment')
    environment
  ) {
    if (localStorage.getItem('context') === miscellaneousConst.cloudlab) {
      this.isMylearning = true;
    }
    this.environment = environment;
    // force route reload whenever params change;
    // this.router.routeReuseStrategy.shouldReuseRoute = () => false;
    // this.titleService.setTitle('CloudLabs On Demand Lab');

    this.Vmurl = this.aroute.snapshot.data.Vmurl;
    if (!this.Vmurl) {
      this.layoutType = EnvironmentLayout.DATABRICKS;
    } else {
      this.layoutType = EnvironmentLayout.VM;
    }
    this.toggleFullScreenforCLPortal = false;
  }

  private async setupVM() {
    if (!this.isMylearning) {
      this.titleService.setTitle('CloudLabs On Demand lab');
    } else {
      this.titleService.setTitle(
        this.lab.Title +
          ' | ' +
          this.translateSrv.instant('HOME.CLOUDLABS_PORTAL')
      );
    }
    this.currentTestDriveUserData = this.aroute.snapshot.data.labattendee;
    this.internalId = this.aroute.snapshot.params.vmurl;
    this.subscriptions[this.subscriptions.length] =
      this.labService.vmStateUpdate
        .pipe(debounceTime(500))
        .subscribe((vms: any) => {
          if (vms && vms.length > 0) {
            const { VMName, VMResourceGroup } = this.Vmurl;
            const currentVM = vms.find(
              (item) =>
                item.ResourceName === VMName &&
                item.ResourceGroupName === VMResourceGroup
            );
            const isSplit = localStorage.getItem(this.splitKey);
            if (
              currentVM &&
              currentVM.Status &&
              ['vm running', 'running'].includes(currentVM.Status.toLowerCase())
            ) {
              if (
                (this.manager &&
                  ['CLIENT_ERROR', 'TUNNEL_ERROR', 'DISCONNECTED'].includes(
                    this.manager.getState()
                  )) ||
                this.manager === undefined
              ) {
                this.loader = true;
                this.vmstate = true;
                this.resetSteps();
                this.updateStepsData(0, 'completed', 'running', null);
                this.loadContainerStatus();
                // this.remoteConnect();
              }
            } else if (
              isSplit &&
              ((this.manager &&
                ['CLIENT_ERROR', 'TUNNEL_ERROR', 'DISCONNECTED'].includes(
                  this.manager.getState()
                )) ||
                this.manager === undefined)
            ) {
              this.resetSteps();
              this.getVMLaunchURL();
            } else if (
              currentVM &&
              currentVM.Status &&
              [
                'vm deallocated- runtime limit exhausted',
                'deallocated- runtime limit exhausted',
                'deallocating- runtime limit exhausted',
                'vm deallocating- runtime limit exhausted',
                'stopping- runtime limit exhausted',
                'stopped- runtime limit exhausted',
              ].includes(currentVM.Status.toLowerCase())
            ) {
              this.resetSteps();
              this.updateStepsData(0, null, 'failed', null, 'UNAVAILABLE');
              this.vmErrorMessage = 'VM_QUOTA_EXHAUSTED';
            } else if (
              [
                'vm deallocated',
                'vm deallocating',
                'vm stopped',
                'deallocated',
                'deallocating',
                'stopping',
                'stopped',
                'terminated',
              ].includes(currentVM.Status.toLowerCase())
            ) {
              this.resetSteps();
              if (this.resourcesOperation) {
                this.updateStepsData(
                  0,
                  null,
                  'stop',
                  'VM_NOT_RUNNING_DESCRIPTION'
                );
              } else {
                if(this.guideData.CloudPlatformId === 2 || this.guideData.CloudPlatformId === 3) {
                  this.updateStepsData(0, null, 'failed', null, 'STOPPED');
                } else {
                  this.updateStepsData(0, null, 'failed', null, 'DEALLOCATED');
                }
                this.vmErrorMessage = 'VM_DEALLOCATED_PERMISSION';
              }
            }
            this.cdr.detectChanges();
          }
          //when token authentication get error
          if (vms === false) {
            this.vmstate = false;
          }
        });
    if (
      this.currentTestDriveUserData?.ADSetupStatus &&
      this.currentTestDriveUserData?.IsDeploymentActive
    ) {
      this.deployOnDemandLabForUser();
      this.modeOfOperation = 'Initiation';
    }
    if (
      this.currentTestDriveUserData.DeploymentStatus === 'Deleted' ||
      this.currentTestDriveUserData.DeploymentStatus === 'Deleting'
    ) {
      this.modeOfOperation = 'Deployed';
      this.isComplete = true;
      this.loader = false;
      this.loading = false;
    }
    this.subscriptions[this.subscriptions.length] =
      this.eventSrv.onExtendDurationCancel.subscribe(() => {
        this.updateLatestLabEnvironment();
      });
    this.subscriptions[this.subscriptions.length] =
      this.eventSrv.onExtendDurationError.subscribe((errorObject) => {
        if (
          errorObject?.error?.ErrorDetail.includes(
            'You can extend this lab only upto'
          )
        ) {
          this.showExtensionErrorPopUp();
        }
        this.canExtendFurther = false;
        if (
          this.actionList[0].action === ActionItems.EXTEND &&
          !this.lab.SkipExtendLimitValidation
        ) {
          this.actionList.splice(0, 1);
        }
        this.updateLatestLabEnvironment();
      });
    this.subscriptions[this.subscriptions.length] =
      this.eventSrv.activityDialog.subscribe((res) => {
        this.onCloseActivity(res);
      });
    this.subscriptions[this.subscriptions.length] =
      this.eventSrv.completeDialog.subscribe((res) => {
        this.onDeleteAction(res);
      });

    this.subscriptions[this.subscriptions.length] =
      this.eventSrv.resourceDialog.subscribe((res) => {
        this.onResourceAction(res);
      });
  }

  async ngOnInit() {
    this.userSessionService.vmDetails$.next(null); // reset the VM details
    this.lang = localStorage.getItem('language') || 'en';
    this.lab = this.aroute.snapshot.data.lab;
    this.minimumRemainingTimeForLabExtension =
      this.lab.AllowedExtendDurationByAttendee ||
      this.environment.extendingLabDurationInMinutes;
    this.lab.Title = this.lab.DisplayName
      ? this.lab.DisplayName
      : this.lab.Title;

    this.eventSrv.setThemeId(this.lab.ThemeId);
    this.detectOS();
    this.guideData = this.aroute.snapshot.data.environment;
    this.cloudPlateFormId = this.guideData.CloudPlatformId;

    this.vCode = this.aroute.snapshot.params.voucherCode;
    this.id = this.aroute.snapshot.params.id;

    if (this.lab?.EnableCustomLabDetailsPage === false) {
      this.enableHeader = true;
      if (!this.isDeviceSmall()) {
        this.isSidenav = true;
        this.docguidescreen = null;
      }
    }

    if (this.browser.name.includes('edge')) {
      this.edgePopUpHeading = true;
    } else {
      this.edgePopUpHeading = false;
    }

    this.subscriptions[this.subscriptions.length] =
      this.labService.guideTitle.subscribe((title) => {
        if (title !== '') {
          this.labTitle = title;
        }
      });

    this.subscriptions[this.subscriptions.length] =
      this.labService.labDetailsInES.subscribe((labDetails) => {
        if (labDetails) {
          this.lab = labDetails;
        }
      });

    this.initialDeployment = await this.labService
      .initialDeployment(
        this.lab.InternalId,
        this.vCode === 'anonymous' ? null : this.vCode,
        this.lab.UserSelectedRegion
      )
      .toPromise();
    this.showResourcesTab = this.initialDeployment.ShowResourcesTab;
    if (
      this.guideData.EnableResourceOperations &&
      this.showResourcesTab &&
      ((!this.guideData?.IsSharedTestDrive &&
        this.guideData.CloudPlatformId === 1) ||
        this.guideData.CloudPlatformId == 1 ||
        this.guideData.CloudPlatformId == 2 ||
        this.guideData.CloudPlatformId == 3 ||
        (!this.guideData?.IsSharedTestDrive &&
          (this.guideData.CloudPlatformId === 1 ||
            this.guideData.CloudPlatformId === 2)))
    ) {
      this.resourcesOperation = true;
    }
    if (this.environment.appType === AppType.ATTENDEE) {
      this.showFullScreen = Boolean(
        this.initialDeployment.DeploymentStatus === 'Succeeded'
      );
    }
    if (
      this.initialDeployment.EnableAttendeeEndTime &&
      this.initialDeployment.EndDate != null
    ) {
      if (this.initialDeployment.EndDate.includes('Z')) {
        this.initialDeployment.EndDate = this.initialDeployment.EndDate.slice(
          0,
          -1
        );
      }
      const checkDate = new Date(this.initialDeployment.EndDate);
      const endDate = moment(
        new Date(
          checkDate.getTime() - checkDate.getTimezoneOffset() * 60 * 1000
        )
      ).format('YYYY-MM-DD hh:mm:ss A Z');
      this.endDateData = { endDate: endDate, timeZone: getTimeZone() };
    }

    this.subscriptions[this.subscriptions.length] =
      this.eventSrv.onExtendduration.subscribe((res: any) => {
        this.subscriptions[this.subscriptions.length] = this.labService
          .getLabEnvironment(this.id, this.vCode)
          .subscribe((res: any) => {
            clearTimeout(this.countDownTimer);
            if (
              this.actionList[0].action === ActionItems.EXTEND &&
              !this.lab.SkipExtendLimitValidation
            ) {
              this.enableduration = false;
              this.actionList.splice(0, 1);
            }
            setTimeout(() => {
              this.findRemainingDuration(res.StartTime, res.Duration);
            }, 6000);
          });
      });

    const data = localStorage.getItem(this.splitKey);
    if (data && data === 'true') {
      this.isDraggable = true;
    }
    this.subscriptions[this.subscriptions.length] =
      this.clipboardService.copyResponse$.subscribe(
        (res: IClipboardResponse) => {
          if (res.isSuccess && this.manager) {
            this.manager.sendRemoteClipboardData(res.content);
          }
        }
      );

    this.findRemainingDuration(
      this.guideData.StartTime,
      this.guideData.Duration
    );

    this.sizeChanged = new Subject<boolean>();
    this.sizeChangedDebounced = this.sizeChanged.pipe(debounceTime(1000));
    this.subscriptions[this.subscriptions.length] =
      this.sizeChangedDebounced.subscribe(() => {
        this.onResizeWindow();
      });
    this.sizeChanged1 = new Subject<boolean>();
    this.sizeChangedDebounced1 = this.sizeChanged1.pipe(debounceTime(1000));
    this.subscriptions[this.subscriptions.length] =
      this.sizeChangedDebounced1.subscribe((res) => {
        window.dispatchEvent(new Event('resize-tabs'));
        if (res) {
          this.onResizeWindow(res);
        }
      });
    this.subscriptions[this.subscriptions.length] =
      this.labService.labDetailsInES.subscribe((labDetails) => {
        if (labDetails) {
          this.lab = labDetails;
        }
      });
    setTimeout(() => {
      this.loading = false;
      if (this.el) {
        this.renderer.listen(this.el.nativeElement, 'keydown', (event) => {
          this.onkeyPress(event);
        });
      }
    }, 3000);

    const cancelduration = sessionStorage.getItem('cancelduration');
    if (cancelduration) {
      sessionStorage.removeItem('cancelduration');
    }

    this.subscriptions[this.subscriptions.length] =
      this.eventSrv.tokenNotfound.subscribe((res) => {
        this.loader = false;
      });
    this.manageSibebarOnResponsive();
    this.subscriptions[this.subscriptions.length] =
      this.eventSrv.languageChanged.subscribe((lang) => {
        this.changedLanguage(lang);
      });
    this.subscriptions[this.subscriptions.length] =
      this.eventSrv.toggleHeader.subscribe(() => {
        if (this.isDeviceSmall()) {
          this.labService.resizeEvent.next(true);
        }
        this.enableHeader = !this.enableHeader;
        const key = `toggleScreen_${this.vCode}`;
        localStorage.setItem(key, JSON.stringify(this.enableHeader));
        this.loader = true;
        const value =
          window.innerWidth < this.environment.VM_MIN_WIDTH ? false : true;
        this.sizeChanged1.next(value);
        this.toggleFullScreenforCLPortal = !this.toggleFullScreenforCLPortal;
      });
    this.subscriptions[this.subscriptions.length] = this.multiWindowService
      .onMessage()
      .subscribe((message: Message) => {
        if (message.senderId !== this.multiWindowService.id) {
          this.handleMultiWindowMessage(message);
        }
      });
    const key = `toggleScreen_${this.vCode}`;
    window.addEventListener('beforeunload', () => localStorage.removeItem(key));
    this.subscriptions[this.subscriptions.length] =
      this.userSessionService.sideNavigation$.subscribe((res) => {
        if (this.prevSidebarState !== res) {
          window.dispatchEvent(new Event('resize'));
          this.prevSidebarState = res;
        }
      });
    if (this.layoutType === EnvironmentLayout.VM) {
      this.setupVM();
    }
    this.createActionList();
    this.notesService.notesObserver.subscribe((data) => {
      this.notes = data;
    });
    this.eventSrv.copyMode.subscribe((res) => {
      if (res) {
        this.showFileTransferPopUp = true;
      }
    });
    this.subscriptions[this.subscriptions.length] =
      this.labService.vmOperation.subscribe((res) => {
        this.resetSteps();
        this.getVMLaunchURL();
      });
    this.subscriptions[this.subscriptions.length] =
      this.eventSrv.changeTab.subscribe((res) => {
        if (!this.isSidenav) {
          this.openSidenav();
        }
      });
  }

  private detectOS():void {
    const userAgent = window.navigator.userAgent;
    if (/Windows/.test(userAgent)) {
      this.osInfo = 'Windows';
    } else if (/Macintosh/.test(userAgent)) {
      this.osInfo = 'Mac OS';
    } else if (/Android/.test(userAgent)) {
      this.osInfo = 'Android';
    } else if (/Linux/.test(userAgent)) {
      this.osInfo = 'Linux';
    } else if (/iOS/.test(userAgent)) {
      this.osInfo = 'iOS';
    } else {
      this.osInfo = 'Unknown';
    }
  }

  ngAfterViewInit(): void {
    this.eventEmitter.logEventInAppInsight(
      AppInsightEvents.RDP_OVER_HTTPS_SCREEN_VISIT,
      {
        [PropertyName.ENTITY_ID]: this.lab.UniqueName,
        [PropertyName.USER_ID]: this.vCode,
        [PropertyName.URL]: window.location.href,
      }
    );
    this.util.switchLanguageToDefault(this.lab);
    if (this.layoutType === EnvironmentLayout.DATABRICKS) {
      this.splitKey = `windowsplit-${this.vCode}`;
    }
  }

  private showExtensionErrorPopUp() {
    this.modalData = {
      title: 'EXT_NOT_ALLOWED',
      description: 'REACHOUT_TO_SUPPORT',
      type: 'EXTEND_LAB_ERROR',
      showCancel: false,
    };
    this.modelShow = true;
  }

  private createActionList() {
    this.actionList = [
      {
        name: 'RELOAD_INSTANCE',
        icon: 'fa fa-refresh',
        action: ActionItems.RELOAD,
      },
    ];
    if (
      this.lab.AllowUserToDeleteODL &&
      !['Deallocated', 'Deployed', 'Deallocating'].includes(
        this.modeOfOperation
      )
    ) {
      this.actionList.push({
        name: 'DELETE_LAB',
        icon: 'fa fa-trash',
        action: ActionItems.DEALLOCATE,
      });
    }
    if (
      this.lab.AllowUserToExtendCredit &&
      !['Deallocated', 'Deployed', 'Deallocating'].includes(
        this.modeOfOperation
      )
    ) {
      this.actionList.push({
        name: 'EXT_CREDIT',
        icon: 'fas fa-dollar-sign',
        action: ActionItems.EXT_CREDIT,
      });
    }
  }

  actionClickEvent(action) {
    switch (action) {
      case ActionItems.DEALLOCATE: {
        this.showDeallocateLabConfirmationPopUp();
        break;
      }
      case ActionItems.RELOAD: {
        location.reload();
        break;
      }
      case ActionItems.EXTEND: {
        this.extendLabDuration();
        break;
      }
      case ActionItems.EXT_CREDIT: {
        this.extendCredit();
        break;
      }
    }
  }

  public deallocateLab() {
    this.modelShow = false;
    this.counter = 0;
    this.completeTheLab(this.initialDeployment.InternalId);
    this.createActionList();
  }

  public closeImagePopup(event): void {
    this.showImagePopup = event;
  }

  public async vmSwitch(vmData) {
    if (this.manager && this.manager.getClient()) {
      this.manager.getClient().disconnect();
    }
    this.resetSteps();
    this.isSwitchVm = true;
    const vmGuid =
      vmData.Type === 'virtualMachines/hyperVm' ? vmData.VMGuid : '';
    this.internalId = vmData.InternalId;
    this.sendMessageToMultiWindow();
    if (localStorage.getItem('context') === miscellaneousConst.cloudlab) {
      const vcode = this.aroute.snapshot.params.clVcode;
      const clid = this.aroute.snapshot.params.clid;
      const id = clid ? `/${clid}/${vcode}/` : '/';
      this.location.replaceState(
        getEntity() +
          `/` +
          getEntityData() +
          `${id}${this.id}/${this.vCode}/labenvironment/${this.internalId}${
            vmGuid ? `?vmguid=${vmGuid}` : ''
          }`
      );
    } else {
      this.location.replaceState(
        `/odl/environment/${this.id}/${this.vCode}/${vmData.InternalId}${
          vmGuid ? `?vmguid=${vmGuid}` : ''
        }`
      );
    }
    this.labService.updateInternalId.next(vmData.InternalId);
    this.loader = true;
    this.subscriptions[this.subscriptions.length] = this.labService
      .vmlaunchUrl(vmData.InternalId, vmGuid)
      .subscribe(
        (res) => {
          if (res) {
            this.Vmurl = res;
            this.getVMLaunchURL();
          }
        },
        (err) => {
          const code = 'Error - 50042';
          this.eventEmitter.debugAlert(code, err.error);
        }
      );
  }

  private sendMessageToMultiWindow() {
    let recipientsId: any;
    const windows = this.multiWindowService.getKnownWindows();
    for (const i of windows) {
      if (!i.self) {
        recipientsId = i.id;
      }
    }
    this.multiWindowService.sendMessage(
      recipientsId,
      EventTypeEnum.UpdateURL,
      this.internalId
    );
  }

  private processImageBeforePopup($event) {
    if ($event.shiftKey) {
      // If Shift key is pressed, open the image in a new tab
      const imageSrc = ($event.target as HTMLElement).getAttribute('src');
      if (imageSrc) {
        const newTabAnchor = document.createElement('a');
        newTabAnchor.href = imageSrc;
        newTabAnchor.target = '_blank';
        newTabAnchor.click();
      }
    } else {
      this.openImagePopup($event.target as HTMLElement);
    }
  }

  private openImagePopup(imageElement: HTMLElement) {
    const image = imageElement.outerHTML.replace(/(width|height)="[^"]*"/g, '');
    this.imagePopupElement = image;

    // Set the calculated width and height as inline styles for the popup
    const calculatedWidthHeight = getPopupWidthHeightBasedOnImage(imageElement);
    this.imagePopupStyle = {
      width: `${calculatedWidthHeight.width}px`,
      height: `${calculatedWidthHeight.height}px`,
    };

    this.showImagePopup = true;
  }

  goBackPage() {
    if (
      getEntityData() === miscellaneousConst.navigation.learningPath ||
      getEntityData() === miscellaneousConst.navigation.courses
    ) {
      const vcode = this.aroute.snapshot.params.clVcode;

      const id = this.aroute.snapshot.params.clid;
      this.router.navigate([
        getEntity() + `/` + getEntityData() + `/details/${id}/${vcode}`,
      ]);
    } else {
      const vcode = this.aroute.snapshot.params.voucherCode;
      const id = this.aroute.snapshot.params.id;
      this.router.navigate([
        getEntity() + `/` + getEntityData() + `/details/${id}/${vcode}`,
      ]);
    }
  }

  calculateHeight() {
    if (this.sidebarHeader && this.sidebarHeader.nativeElement) {
      this.sidebarHeaderHeight = this.sidebarHeader.nativeElement.offsetHeight;
      this.renderer.setProperty(
        this.el.nativeElement,
        'style',
        `--sidebar-header: ${this.sidebarHeaderHeight}px`
      );
    }
  }

  /**
   * deploy on demand lab for user
   */
  async deployOnDemandLabForUser() {
    this.getRDPLabEnvironmentStatus();
    const response: any = await this.labService
      .initialDeployment(
        this.lab.InternalId,
        this.vCode === 'anonymous' ? null : this.vCode,
        this.lab.Regions
      )
      .toPromise();
    const environmentIdleTimeout: any = response.EnvironmentIdleTimeout;
    const WarningWaitTimeout: any = response.WarningWaitTimeout;
    if (
      response.EnableAttendeeTracking &&
      this.canEnableBrowserTracking(response) &&
      environmentIdleTimeout !== null &&
      environmentIdleTimeout !== undefined &&
      environmentIdleTimeout > 0
    ) {
      this.idleTimeout = environmentIdleTimeout;
      this.WarningWaitTimeout = WarningWaitTimeout;
      this.startAttendeeLogPolling();
    }
    this.currentTestDriveUserData.status = response;

    this.getVMLaunchURL();
  }

  /**  Check whether to enable Browser tracking or not
   * If the IdleTrackingSourceId is set to 1 disable the Browser Tracking.
   */
  canEnableBrowserTracking(response: any): boolean {
    const idleTrackingSourceId = response.IdleTrackingSourceId;
    if (idleTrackingSourceId === 1) return false;
    return true;
  }

  getRDPLabEnvironmentStatus() {
    this.subscriptions[this.subscriptions.length] = this.labService
      .getRDPLabEnvironmentStatus(this.lab.UniqueName, this.vCode)
      .subscribe((res: any) => {
        this.IsHotInstanceAvailable = res.IsHotInstanceAvailable;
        this.DeploymentDuration = res.DeploymentDuration;
      });
  }
  /**
   * Find remaining duration for lab
   * @param startTime
   * @param actualDuration
   */
  findRemainingDuration(startTime, actualDuration) {
    const start = moment.utc(startTime, 'YYYY-MM-DD HH:mm:ss');
    const current = moment.utc(new Date(), 'HH:mm');
    const duration = moment.duration(current.diff(start));
    actualDuration = moment.duration(actualDuration, 'minutes');
    const remainingDuration = actualDuration.subtract(duration);
    this.counter = parseInt(remainingDuration.asSeconds());
    this.day = parseInt(remainingDuration.asDays());
    this.hours = remainingDuration.get('hours');
    if (this.day > 0) {
      const daysHours = this.day * 24;
      this.hours = daysHours + this.hours;
    }
    this.minutes = remainingDuration.get('minutes');
    if ((this.minutes == 0 && this.hours == 0) || this.minutes < 0) {
      this.isComplete = true;
      clearTimeout(this.countDownTimer);
      this.modeOfOperation = 'Deployed';
    } else {
      this.countdown();
    }
  }

  /**
   * Countdown timer interval
   */
  countdown() {
    if (this.countDownTimer) {
      clearTimeout(this.countDownTimer);
    }
    this.countDownTimer = setTimeout(async () => {
      const cancelduration = sessionStorage.getItem('cancelduration');
      this.minutes--;
      if (this.minutes < 0) {
        this.minutes = 59;
        this.hours--;
        if (this.hours === 0 && this.minutes < 0) {
          this.stopTimerFunc();
        }
      }
      if ((this.minutes == 0 && this.hours == 0) || this.minutes < 0) {
        const isCompleted = await this.isLabCompleted();
        if (!isCompleted) {
          this.updateLatestLabEnvironment();
        } else {
          this.modelShow = false;
          this.actionList = this.actionList.filter(
            (item) => item.action === ActionItems.RELOAD
          );
          clearTimeout(this.countDownTimer);
          this.isComplete = true;
          this.modeOfOperation = 'Deallocated';
          localStorage.removeItem(this.splitKey);
          sessionStorage.removeItem('cancelduration');
          this.eventSrv.onCloaseDuration();
          if (!this.labComplete) {
            this.modalRef = this.modalService.show(DurationDialogComponent, {
              initialState: {
                title: this.translateSrv.instant('ON_DEMAND_LAB_NOTIFICATION'),
                data: {
                  description: this.translateSrv.instant(
                    'ERROR_TIMEOUT_DURATION'
                  ),
                  successButtonText: this.translateSrv.instant('OK'),
                },
              },
            });
          }
        }
      }

      const canExtendLab =
        this.lab.AllowUserToExtendLab &&
        !this.isComplete &&
        this.canExtendFurther;
      const meetsMinimumTimeForExtension =
        this.hours === 0 &&
        this.minutes < this.minimumRemainingTimeForLabExtension;
      const shouldExtend = canExtendLab && meetsMinimumTimeForExtension;

      if (!this.lab.SkipExtendLimitValidation) {
        if (cancelduration === null && shouldExtend) {
          this.enableAndExtendLab(shouldExtend && cancelduration === null);
        }
      } else {
        if (canExtendLab) {
          this.enableAndExtendLab(
            meetsMinimumTimeForExtension && cancelduration === null
          );
        }
      }

      this.countdown();
    }, 60000);
    if (
      this.initialDeployment?.AllowEnvironmentDeleteNotification === true &&
      this.hours === 0 &&
      this.minutes === 30 &&
      this.aroute.snapshot.params.voucherCode
    ) {
      this.modalRef = this.modalService.show(EnvironmentDeleteDialogComponent, {
        initialState: {
          title: this.translateSrv.instant('ALERT'),
          data: {
            description: 'LAB_DELETE_WARNING_MESSAGE',
            successButtonText: this.translateSrv.instant('OK'),
          },
        },
      });
    }
  }

  private enableAndExtendLab(shouldExtendFurther) {
    this.enableduration = true;
    if (this.actionList[0].action !== ActionItems.EXTEND) {
      this.actionList.splice(0, 0, {
        name: 'EXTEND_LAB_DURATION',
        icon: 'fa fa-hourglass-start',
        action: ActionItems.EXTEND,
      });
    }
    this.eventSrv.onCloaseDuration();
    if (shouldExtendFurther) {
      this.extendLabDuration();
    }
  }

  /**
   * stop timer interval
   */
  stopTimerFunc() {
    // if (this.stopTimer) {
    clearTimeout(this.countDownTimer);
    // }
  }

  /**
   * Close side bar
   */
  closeSidenav() {
    this.hidingAlertMessages = true;
    this.readAlertMessage = true;
    this.isSidenav = false;
    this.loader = true;
    this.docguidescreen = true;
    const value =
      window.innerWidth < this.environment.VM_MIN_WIDTH ? false : true;
    this.sizeChanged1.next(value);
    setTimeout(() => {
      this.hidingAlertMessages = false;
      this.expand?.nativeElement.focus();
    }, 1000);
  }

  /**
   * open sidebar
   */
  openSidenav() {
    this.hidingAlertMessages = true;
    this.readAlertMessage = true;
    this.isSidenav = true;
    this.docguidescreen = null;
    // setTimeout(() => {
    //   this.docguidescreen = null;
    // }, 8000);
    const value =
      window.innerWidth < this.environment.VM_MIN_WIDTH ? false : true;
    this.sizeChanged1.next(value);
    setTimeout(() => {
      this.hidingAlertMessages = false;
      this.collapse?.nativeElement.focus();
    }, 4000);
  }

  /**
   * Extend lab duration
   */
  extendLabDuration() {
    if (!this.labComplete) {
      this.modalRef = this.modalService.show(ExtendDurationComponent, {
        initialState: {
          title: this.translateSrv.instant('EXTEND_LAB_DURATION'),
          data: {
            description:
              this.translateSrv.instant('EXTEND_LAB_DURATION_DESCRIPTION') +
              ' ' +
              this.minimumRemainingTimeForLabExtension +
              ' ' +
              this.translateSrv.instant('MINUTES', {
                singularPlural: checkSingularPlural(
                  this.minimumRemainingTimeForLabExtension
                ),
              }),
            successButtonText: this.translateSrv.instant('OK'),
            failureButtonText: this.translateSrv.instant('CANCEL'),
            v_code: this.vCode,
            duration: this.minimumRemainingTimeForLabExtension,
            approvalRequired: this.lab.DisableDurationAutoApprove,
            unit:
              this.minimumRemainingTimeForLabExtension +
              ' ' +
              this.translateSrv.instant('MINUTES', {
                singularPlural: checkSingularPlural(
                  this.minimumRemainingTimeForLabExtension
                ),
              }),
            maxUnit:
              this.lab.MaxUserDurationExtension +
              ' ' +
              this.translateSrv.instant('MINUTES', {
                singularPlural: checkSingularPlural(
                  this.lab.MaxUserDurationExtension
                ),
              }),
          },
        },
      });
    }
  }

  /**
   * recevie message from child window
   * @param evt
   */
  receivemessage(evt: any) {
    if (evt.data !== undefined) {
      if (evt.data === 'true') {
        this.JoinWindow();
      } else if (evt.data.toString().indexOf('ResourceName') > -1) {
        const params = JSON.parse(evt.data);
        this.labService.vmStateUpdate.next(params);
      } else {
        // if (this.manager) {
        //   this.manager.sendRemoteClipboardData(evt?.data);
        // }
        this.eventSrv.splitWindowCopy.next(evt?.data);
      }
    }
  }

  async completeTheLab(event: string) {
    this.modeOfOperation = 'Deallocating';
    this.isComplete = true;
    if (event) {
      try {
        const res = await this.labService.completeTestDrive(event).toPromise();
        if (res === true) {
          this.labComplete = true;
          this.modeOfOperation = 'Deallocated';
        } else {
          this.isComplete = false;
        }
      } catch (e) {
        const code = 'Error - 70008';
        this.eventEmitter.debugAlert(code, 'Vm Deallocated');
        this.isComplete = false;
      }
    }
    this.cdr.detectChanges();
  }
  /**
   * split window
   */
  splitWindow() {
    this.isSplitWindow = true;
    this.loader = true;
    this.isDraggable = true;
    localStorage.setItem(this.splitKey, 'true');
    this.sizeChanged1.next(true);
    let url;
    if (
      localStorage.getItem('context') === miscellaneousConst.cloudlab &&
      this.layoutType === EnvironmentLayout.VM
    ) {
      const clid = this.aroute.snapshot.params.clid;
      const vcode = this.aroute.snapshot.params.clVcode;
      const id = clid ? `/${clid}/${vcode}/` : '/';
      url =
        getEntity() +
        `/` +
        getEntityData() +
        `${id}${this.lab.UniqueName}/${this.vCode}/0?intId=${
          this.internalId ? this.internalId : ''
        }`;
    } else if (this.layoutType === EnvironmentLayout.DATABRICKS) {
      if (this.environment.appType === AppType.ATTENDEE) {
        const clid = this.aroute.snapshot.params.clid;
        const vcode = this.aroute.snapshot.params.clVcode;
        const id = clid ? `/${clid}/${vcode}/` : '/';
        url =
          getEntity() +
          `/` +
          getEntityData() +
          `${id}${this.lab.UniqueName}/${this.vCode}/0?intId=${
            this.internalId ? this.internalId : ''
          }`;
      } else {
        url = `/#/odl/labguide/${this.lab.UniqueName}/${this.vCode}/0`;
      }
    } else {
      url = `/#/odl/labguide/${this.lab.UniqueName}/${this.vCode}/0?intId=${
        this.internalId ? this.internalId : ''
      }`;
    }
    const dec = decodeURIComponent(url);
    if (this.mywindow) {
      this.mywindow.close();
    }
    this.mywindow = window.open(
      dec,
      '_blank',
      'resize=no,  toolbar=no, scrollbars=no, menubar=no, status=no, directories=no, location=no, width=500, height=620, right=' +
        0 +
        ' top=100 '
    );
    // handle onbeforeUnload issue with lab guide slip window
    this.subscription = interval(500).subscribe((x) => {
      if (this.mywindow && this.mywindow.closed) {
        localStorage.removeItem(this.splitKey);
        this.receivemessage({ data: 'true' });
        this.mywindow = undefined;
        if (this.subscription) {
          this.subscription.unsubscribe();
        }
      }
    });

    this.mywindow.addEventListener(
      'message',
      this.receivemessage.bind(this),
      false
    );

    this.mywindow.onresize = () => {
      setTimeout(() => {
        if (this.mywindow.innerWidth < this.environment.SIDEBAR_MIN_WIDTH) {
          this.mywindow.resizeTo(this.environment.SIDEBAR_MIN_WIDTH, 620);
        }
      }, 1000);
    };
  }

  /**
   * Join window
   */
  JoinWindow() {
    this.isSplitWindow = false;
    this.eventSrv.splitWindowCopy.next(null);
    const keyId = localStorage.getItem(`delete-lab-${this.vCode}`);
    if (keyId) {
      this.completeTheLab(keyId);
      localStorage.removeItem(`delete-lab-${this.vCode}`);
    }
    this.enableHeader = !this.enableHeader;
    setTimeout(() => {
      this.enableHeader = !this.enableHeader;
    }, 1);
    this.eventSrv.emmitWinodwJoin();
    this.ngZone.run(() => {
      if (this.mywindow) {
        this.mywindow.close();
      }
      this.isDraggable = false;
      this.sizeChanged1.next(true);
      if (this.lab?.EnableCustomLabDetailsPage === true) {
        this.isSidenav = false;
        this.docguidescreen = true;
      } else {
        this.isSidenav = true;
        this.docguidescreen = null;
      }
      this.labService.labValidationRefresh.next(true);
      if (!this.isPageRefresh) {
        this.subscriptions[this.subscriptions.length] = this.labService
          .getLabEnvironment(this.id, this.vCode)
          .subscribe((res: any) => {
            setTimeout(() => {
              this.findRemainingDuration(res.StartTime, res.Duration);
            }, 500);
          });
      }
    });
  }

  /**
   * get vm urls
   */
  async getVMLaunchURL() {
    this.currentRemoteConnect = this.Vmurl;
    this.guacamoleServerUrl = this.Vmurl.ServerUrl;
    this.rdpUri = this.Vmurl.ConnectTypeUrl;
    // this.userSessionService.vmDetails$.next({
    //   guacamoleServerUrl: this.guacamoleServerUrl,
    //   rdpUri: this.rdpUri,
    // });
    this.vmName = this.Vmurl.VMName;
    const vmachine: any[] = (await this.labService
      .GetVirtualMachine(this.vCode)
      .toPromise()) as any[];
    const { VMLaunchURL } = this.guideData;

    // merge VMLaunch URL details with VM's
    if (VMLaunchURL && VMLaunchURL.length > 0) {
      VMLaunchURL.forEach((element: any) => {
        const index = vmachine.findIndex(
          (item) =>
            element.VMName === item.ResourceName &&
            element.ResourceGroupName === item.ResourceGroupName &&
            element.SubscriptionId === item.SubscriptionId
          // && element.VMDNSName === item.DNSName
        );
        if (index > -1) {
          vmachine[index] = { ...vmachine[index], ...element };
        }
      });
    }

    this.splitKey = `windowsplit-${this.vCode}`;
    // get the current vm and show on page
    if (vmachine && vmachine.length > 0) {
      // if this is multi vm
      this.splitKey = `windowsplit-${this.vCode}-${this.internalId}`;
      const vmItem: any = vmachine.find(
        (item) =>
          item.InternalId && item.InternalId.toString() === this.internalId
      );
      this.currentVMdetail = vmItem;
      this.currentVMdetail.vCode = this.vCode;
      if (vmItem) {
        this.vmStatus = vmItem.Status;
        if (
          vmItem.Status == null ||
          ['vm starting', 'starting', 'staging'].includes(
            vmItem.Status.toLowerCase()
          )
        ) {
          this.vmInterval = setInterval(() => {
            this.getVMLaunchURL();
          }, 30000);
        } else if (
          [
            'vm deallocated',
            'vm deallocating',
            'vm stopped',
            'deallocated',
            'deallocating',
            'stopping',
            'stopped',
            'terminated',
          ].includes(vmItem.Status.toLowerCase())
        ) {
          this.ngZone.run(() => {
            this.vmstate = false;
            this.loader = false;
            this.showRefreshButton = true;
            if (this.resourcesOperation) {
              this.updateStepsData(
                0,
                null,
                'stop',
                'VM_NOT_RUNNING_DESCRIPTION'
              );
            } else {
              if (
                this.guideData.CloudPlatformId === 2 ||
                this.guideData.CloudPlatformId === 3
              ) {
                this.updateStepsData(0, null, 'failed', null, 'STOPPED');
              } else {
                this.updateStepsData(0, null, 'failed', null, 'DEALLOCATED');
              }
              this.vmErrorMessage = 'VM_DEALLOCATED_PERMISSION';
            }
            this.setAppInsight(
              AppInsightEvents.RDP_OVER_HTTPS_FAILED,
              false,
              RDPOperation.VM_DEALLOCATED_OR_STOPPED,
              vmItem.Status
            );
          });
          clearInterval(this.vmInterval);
        } else if (
          [
            'vm deallocated- runtime limit exhausted',
            'deallocated- runtime limit exhausted',
            'deallocating- runtime limit exhausted',
            'vm deallocating- runtime limit exhausted',
            'stopping- runtime limit exhausted',
            'stopped- runtime limit exhausted',
          ].includes(vmItem.Status.toLowerCase())
        ) {
          this.updateStepsData(0, null, 'failed', null, 'UNAVAILABLE');
          this.vmErrorMessage = 'VM_QUOTA_EXHAUSTED';
          this.setAppInsight(
            AppInsightEvents.RDP_OVER_HTTPS_FAILED,
            false,
            RDPOperation.VM_QUOTA_EXHAUSTED,
            vmItem.Status
          );
          clearInterval(this.vmInterval);
        } else {
          this.updateStepsData(0, 'completed', 'running', null);
          this.loadContainerStatus();
          clearInterval(this.vmInterval);
        }
      }
    } else if (
      this.isComplete !== true &&
      this.modeOfOperation !== 'Deployed'
    ) {
      this.updateStepsData(0, 'completed', 'running', null);
      this.updateStepsData(1, 'completed', 'running', null);
      this.updateStepsData(2, 'active');
      this.remoteConnect();
    }
  }

  /**
   * remote connect
   */
  async remoteConnect() {
    this.remoteConnectConnectionClose = false;
    this.showRefreshButton = false;
    this.inProgressIndicator = true;
    this.remoteConnectInProgress = true;
    await this.checkResourcesStoppedByIdleTimeout();
    if (this.stoppedByIdleTimeout === true) {
      this.remoteConnectProcessStatus = this.translateSrv.instant(
        'RESTARTING_RESOURCE'
      );
      this.restartResources();
    } else {
      this.remoteConnectProcessStatus =
        this.translateSrv.instant('AUTHENTICATING_RDP');
      this.labService.reloadVMs.next();
      if (
        !this.manager ||
        (this.manager &&
          this.manager.getState() !== this.remoteDesktopStates.CONNECTED)
      ) {
        if (this.resourceStatus === 'Running') {
          this.vmstate = true;
        }
        this.userSessionService.vmDetails$.next({
          guacamoleServerUrl: this.guacamoleServerUrl,
          rdpUri: this.rdpUri,
          isSwitchVm: this.isSwitchVm,
        });
      }
    }
  }

  updateManager(value) {
    this.manager = value;
  }

  stepAction(action) {
    if (action === 'remoteConnect') {
      this.getVmUrl();
      this.remoteConnect();
    } else if (action === 'container') {
      this.loadContainerStatus();
    } else {
      this.resetSteps();
      this.getVMLaunchURL();
    }
  }

  resetSteps() {
    this.steps = [
      { status: null, state: 'active', description: null, statusLabel: null },
      { status: null, state: 'pending', description: null, statusLabel: null },
      { status: null, state: 'pending', description: null, statusLabel: null },
    ];
  }

  loadContainerStatus() {
    this.updateStepsData(1, 'active');
    // this.labService.containerStatus(this.vCode, this.currentVMdetail.InternalId).subscribe(res => {
    this.labService
      .containerStatus(this.vCode, this.currentVMdetail.InternalId)
      .subscribe(
        (res: any) => {
          if (res.toLowerCase() === 'running') {
            this.updateStepsData(1, 'completed', 'running');
            this.updateStepsData(2, 'active');
            clearInterval(this.containerInterval);
            this.containerInterval = null;
            this.getVmUrl();
            // this.remoteConnect();
          } else if (
            res.toLowerCase() === 'stopped' ||
            res.toLowerCase() === 'terminated'
          ) {
            this.updateStepsData(1, null, 'stop');
            this.setAppInsight(
              AppInsightEvents.CONTAINER_STATUS,
              null,
              'Container Status Check',
              res
            );
            this.setAppInsight(
              AppInsightEvents.RDP_OVER_HTTPS_FAILED,
              res,
              RDPOperation.CONTAINER_FAILURE
            );
            clearInterval(this.containerInterval);
            this.containerInterval = null;
          } else if (
            res.toLowerCase() === 'pending' ||
            res.toLowerCase() === 'starting' ||
            res.toLowerCase() === 'stopping'
          ) {
            if (!this.containerInterval) {
              this.containerInterval = setInterval(() => {
                this.loadContainerStatus();
              }, 30000);
            }
          } else {
            this.updateStepsData(1, null, 'failed', null, 'FAILED_LABEL');
            clearInterval(this.containerInterval);
            this.containerInterval = null;
            this.setAppInsight(
              AppInsightEvents.RDP_OVER_HTTPS_FAILED,
              res,
              RDPOperation.CONTAINER_FAILURE
            );
          }
        },
        (err) => {
          this.updateStepsData(1, null, 'failed', null, 'FAILED_LABEL');
          const code = 'Error - 50079';
          clearInterval(this.containerInterval);
          this.containerInterval = null;
          this.eventEmitter.debugAlert(code, err.error);
          this.setAppInsight(AppInsightEvents.CONTAINER_FAILED, err);
          this.setAppInsight(
            AppInsightEvents.RDP_OVER_HTTPS_FAILED,
            err,
            RDPOperation.CONTAINER_FAILURE
          );
        }
      );
  }

  getVmUrl() {
    if (
      this.guideData.CloudPlatformId === 2 ||
      this.guideData.CloudPlatformId === 3
    ) {
      const vmGuid =
        this.currentVMdetail.Type === 'virtualMachines/hyperVm'
          ? this.currentVMdetail.VMGuid
          : '';
      this.labService
        .vmlaunchUrl(this.currentVMdetail.InternalId, vmGuid)
        .subscribe(
          (res) => {
            if (res) {
              this.Vmurl = res;
              this.currentRemoteConnect = this.Vmurl;
              this.guacamoleServerUrl = this.Vmurl.ServerUrl;
              this.rdpUri = this.Vmurl.ConnectTypeUrl;
            }
            this.remoteConnect();
          },
          (err) => {
            const code = 'Error - 50083';
            this.eventEmitter.debugAlert(code, err.error);
          }
        );
    } else {
      this.remoteConnect();
    }
  }

  setAppInsight(key, error?, operation?, status?) {
    this.eventEmitter.logEventInAppInsight(key, {
      [PropertyName.ENTITY_ID]: this.lab.UniqueName,
      [PropertyName.USER_ID]: this.vCode,
      [PropertyName.URL]: window.location.href,
      ...(operation ? { [PropertyName.OPERATION]: operation } : {}),
      ...(status ? { [PropertyName.OPERATION_STATUS]: status } : {}),
      ...(error ? { [PropertyName.ERROR_DATA]: error } : {}),
    });
  }

  updateStepsData(
    stepIndex: number,
    state?: string,
    status?: string,
    description?: string,
    statusLabel?: string
  ) {
    if (state) {
      this.steps[stepIndex].state = state;
    }
    if (status) {
      this.steps[stepIndex].status = status;
    }
    if (description) {
      this.steps[stepIndex].description = description;
    }
    this.steps[stepIndex].statusLabel = statusLabel;
    this.cdr.detectChanges();
  }

  async checkVmStatus() {
    const response: any = await this.labService
      .checkRemoteConnectResourceStatus(this.currentRemoteConnect)
      .toPromise();
    this.resourceStatus = response;
    if (response === 'Stopped') {
      this.remoteConnectProcessStatus = this.translateSrv.instant('RDP_STATE', {
        state: response,
      });
    } else {
      this.remoteConnectProcessStatus =
        this.translateSrv.instant('RDP_STATE', {
          state: response || 'updating',
        }) +
        ' ' +
        this.translateSrv.instant('RDP_REFRESH');
      this.showRefreshButton = true;
    }
    this.inProgressIndicator = false;
  }

  /**
   * check resources stopped by idle Timeout
   */
  async checkResourcesStoppedByIdleTimeout() {
    this.stoppedByIdleTimeout = await this.labService
      .checkResourcesStoppedByIdleTimeout(
        this.currentTestDriveUserData.status.VoucherCode
      )
      .toPromise();
  }

  /**
   * Restart Idle time out resources
   */
  restartResources() {
    this.subscriptions[this.subscriptions.length] = this.labService
      .restartResources(this.currentTestDriveUserData.status.VoucherCode)
      .subscribe((res) => {
        this.resourceStartPolling = setInterval(
          () => this.checkCurrentResourceStatus(),
          60000
        );
        this.idleCounter = 0;
      });
  }

  /**
   * check resource status
   */
  checkCurrentResourceStatus() {
    this.subscriptions[this.subscriptions.length] = this.labService
      .checkResourcesCurrentStatus(
        this.currentTestDriveUserData.status.VoucherCode
      )
      .subscribe((res) => {
        if (res == 'RUNNING') {
          clearInterval(this.resourceStartPolling);
          this.deployOnDemandLabForUser();
        }
      });
  }

  /**
   * redirect to home
   */
  redirectHome() {
    this.router.navigate(['/']);
  }

  RemoveProgressInformation() {
    this.remoteConnectInProgress = false;
    this.showRefreshButton = false;
    this.inProgressIndicator = false;
    this.remoteConnectConnectionClose = false;
    this.remoteConnectProcessStatus = '';
  }

  /**
   * create activity logs
   */
  async createAttendeeActivityLog() {
    await this.labService.createAttendeeActivityLog(this.vCode).toPromise();
    await this.checkIdleTime();
    this.cdr.detectChanges();
  }

  /**
   * start attendee log polling interval
   */
  startAttendeeLogPolling() {
    if (!this.isAttendeeActivityLogPolling) {
      this.isAttendeeActivityLogPolling = true;
      this.attendeeActivityLogPolling = setInterval(
        () => this.createAttendeeActivityLog(),
        60000
      );
    }
  }

  /**
   * stop attendee log polling ineterval
   */
  stopAttendeeLogPolling() {
    if (this.isAttendeeActivityLogPolling) {
      clearInterval(this.attendeeActivityLogPolling);
      this.isAttendeeActivityLogPolling = false;
    }
  }

  /**
   * Action on complete exam-question drive
   */
  async onCompleteTestDrive() {
    this.modeOfOperation = 'Deallocating';
    const res = await this.labService
      .completeTestDrive(this.currentTestDriveUserData.status.InternalId)
      .toPromise();
    if (res) {
      this.ngZone.run(() => {
        this.modeOfOperation = 'Deallocated';
        this.isComplete = true;
      });
    } else {
      this.ngZone.run(() => {
        this.error =
          'We ran into some problem deallocating your On Demand Lab. However, your On Demand Lab has ended.';
        this.modeOfOperation = 'Failed';
        this.isComplete = true;
      });
    }
  }

  /**
   * complete exam-question drive
   */
  completeTestDrive() {
    this.modalRef = this.modalService.show(CompleteTestdriveDialogComponent, {
      initialState: {
        title: this.translateSrv.instant('DANGER'),
        data: {
          description: this.translateSrv.instant('MESSAGE_DEALLOCATE'),
          successButtonText: this.translateSrv.instant('OK'),
          failureButtonText: this.translateSrv.instant('CANCEL'),
        },
        WarningWaitTimeout: this.WarningWaitTimeout,
      },
    });
    setTimeout(() => {
      if (this.deleteEnvironmentPopup) {
        this.completeOndemandLab();
      }
    }, this.WarningWaitTimeout * 60000);
  }

  async completeOndemandLab() {
    this.subscriptions[this.subscriptions.length] = this.labService
      .completeTestDrive(this.currentTestDriveUserData.status.InternalId)
      .subscribe(
        (res) => {
          if (res) {
            this.ngZone.run(() => {
              this.stopAttendeeLogPolling();
              clearTimeout(this.countDownTimer);
              this.modeOfOperation = 'Deallocated';
              this.isComplete = true;
              this.deleteEnvironmentPopup = false;
            });
          }
        },
        (err) => {
          const code = 'Error - 70009';
          this.eventEmitter.debugAlert(code, err.error);
        }
      );
  }

  stopResources() {
    this.subscriptions[this.subscriptions.length] = this.labService
      .stopResourceOnIdleTimeOut(
        this.currentTestDriveUserData.status.VoucherCode
      )
      .subscribe(() => {
        console.log('Resources Stop progress....');
      });
  }

  stopResourcesOnTimeOut() {
    this.modalRef = this.modalService.show(ResourceActionDialogComponent, {
      initialState: {
        title: this.translateSrv.instant('DANGER'),
        data: {
          description: this.translateSrv.instant('RESOURCE_STOP_MESSAGE'),
          successButtonText: this.translateSrv.instant('YES'),
          failureButtonText: this.translateSrv.instant('NO'),
        },
        WarningWaitTimeout: this.WarningWaitTimeout,
      },
    });
    setTimeout(() => {
      if (this.resourceStopPopup) {
        this.stopResources();
        this.resourceStopPopup = false;
      }
    }, this.WarningWaitTimeout * 60000);
  }

  /**
   * Emit on close resource model
   * @param result
   */
  onResourceAction(result) {
    if (result === 'Yes') {
      this.stopResources();
    } else {
      this.idleCounter = 0;
      this.startAttendeeLogPolling();
    }
    this.resourceStopPopup = false;
  }

  /**
   * Perform Delete Environment operation if Emitted result is yes
   * @param result
   */
  onDeleteAction(result) {
    if (result === 'Yes') {
      this.onCompleteTestDrive();
    } else {
      this.idleCounter = 0;
      this.startAttendeeLogPolling();
    }
    this.deleteEnvironmentPopup = false;
  }

  /**
   * Emit on close activity model
   * @param result
   */
  onCloseActivity(result) {
    if (result === 'Yes') {
      this.idleCounter = 0;
    } else if (result === 'No') {
      if (
        this.currentTestDriveUserData.status.TimeoutOperation == 'Resource Stop'
      ) {
        this.resourceStopPopup = true;
        this.stopResourcesOnTimeOut();
      } else {
        this.deleteEnvironmentPopup = true;
        this.completeTestDrive();
      }
      this.stopAttendeeLogPolling();
      clearTimeout(this.countDownTimer);
    }
    this.openLabInactivityPopup = false;
  }

  /**
   * checkidle time
   */
  async checkIdleTime() {
    this.idleCounter++;
    if (
      this.idleCounter >= this.idleTimeout &&
      this.openLabInactivityPopup === false &&
      this.aroute.snapshot.params.vmurl
    ) {
      this.openLabInactivityPopup = true;
      this.modalRef = this.modalService.show(ActivityTrackingComponent, {
        initialState: {
          title:
            this.currentTestDriveUserData.status.TimeoutOperation ==
            'Resource Stop'
              ? this.translateSrv.instant('LAB_INACTIVITY_RESOURCES_STOP')
              : this.translateSrv.instant('LAB_INACTIVITY_TRACKER_TITLE'),
          data: {
            description:
              this.currentTestDriveUserData.status.TimeoutOperation ==
              'Resource Stop'
                ? this.translateSrv.instant(
                    'LAB_INACTIVITY_RESOURCES_STOP_DESCRIPTION'
                  )
                : this.translateSrv.instant(
                    'LAB_INACTIVITY_TRACKER_DESCRIPTION'
                  ),
            successButtonText: this.translateSrv.instant('YES'),
            failureButtonText: this.translateSrv.instant('NO'),
          },
          WarningWaitTimeout: this.WarningWaitTimeout,
        },
      });
    }

    if (
      this.idleCounter >= this.idleTimeout + this.WarningWaitTimeout &&
      this.openLabInactivityPopup === true
    ) {
      if (
        this.currentTestDriveUserData.status.TimeoutOperation == 'Resource Stop'
      ) {
        this.stopResources();
        this.stopAttendeeLogPolling();
        clearTimeout(this.countDownTimer);
      } else {
        this.modeOfOperation = 'Deallocating';
        this.completeOndemandLab();
      }
    }
  }

  manageSibebarOnResponsive() {
    this.calculateHeight();
    if (window.innerWidth < this.environment.VM_MIN_WIDTH) {
      this.closeSidenav();
    }
  }

  changedLanguage(lang) {
    this.lang = lang;
    this.labService.getDetailsInLang(this.aroute.snapshot.params.id, this.lang);
  }

  ngOnDestroy() {
    clearInterval(this.resourceStartPolling);
    window.clearInterval(this.attendeeActivityLogPolling);
    clearInterval(this.containerInterval);
    clearInterval(this.vmInterval);
    if (this.countDownTimer) {
      clearTimeout(this.countDownTimer);
    }
    if (this.modalRef) {
      this.modalRef.hide();
    }
    unsubscribeCollection(this.subscriptions);
    this.onWindowClose(null);
  }

  updateLatestLabEnvironment() {
    this.subscriptions[this.subscriptions.length] = this.labService
      .getLabEnvironment(this.id, this.vCode)

      .subscribe((res: any) => {
        clearTimeout(this.countDownTimer);
        this.enableduration = true;
        if (this.actionList[0].action !== ActionItems.EXTEND) {
          this.actionList.splice(0, 0, {
            name: 'EXTEND_LAB_DURATION',
            icon: 'fa fa-hourglass-start',
            action: ActionItems.EXTEND,
          });
        }
        setTimeout(() => {
          this.findRemainingDuration(res.StartTime, res.Duration);
        }, 6000);
      });
  }

  isLabCompleted(): Promise<boolean> {
    return new Promise<boolean>((resolve, reject) => {
      this.subscriptions[this.subscriptions.length] = this.labService
        .getLabEnvironment(this.id, this.vCode)
        .subscribe((res: any) => {
          const start = moment.utc(res.StartTime, 'YYYY-MM-DD HH:mm:ss');
          const current = moment.utc(new Date(), 'HH:mm');
          const duration = moment.duration(current.diff(start));
          res.Duration = moment.duration(res.Duration, 'minutes');
          const remainingDuration = res.Duration.subtract(duration);
          // tslint:disable-next-line:radix
          const day = parseInt(remainingDuration.asDays());
          let hours = remainingDuration.get('hours');
          if (day > 0) {
            const daysHours = day * 24;
            hours = daysHours + hours;
          }
          const minutes = remainingDuration.get('minutes');
          if (hours <= 0 && minutes <= 0) {
            resolve(true);
          } else {
            resolve(false);
          }
        });
    });
  }

  private handleMultiWindowMessage(message: Message) {
    if (message.event === EventTypeEnum.ToggleHeader) {
      this.handleMultiWindowEventToogleHeader(message.data);
    } else if (message.event === EventTypeEnum.PasteInVM) {
      this.eventSrv.pasteInsideVm.next(message.data);
    }
  }

  private handleMultiWindowEventToogleHeader(value: boolean) {
    this.enableHeader = value;
    this.loader = true;
    const val =
      window.innerWidth < this.environment.VM_MIN_WIDTH ? false : true;
    this.sizeChanged1.next(val);
  }

  /**
   * remote desktop size
   * on window resize
   */
  async onResizeWindow(sidebarCheck: boolean = false) {
    // manage the window size
    this.loader = true;
    this.manageSibebarOnResponsive();
    if (this.isDeviceSmall()) {
      this.labService.resizeEvent.next(false);
    } else this.labService.resizeEvent.next(true);
  }

  loadValidations() {
    if (this.isLabValidation) {
      this.eventSrv.onLoadValidation.next();
    }
  }

  private async showDeallocateLabConfirmationPopUp() {
    if (
      this.lab.EnableLabValidation === true &&
      !this.lab?.DisableValidationTab &&
      !this.lab?.IsSupportQuest
    ) {
      const data: any = await this.labService
        .getValidationStatus(this.vCode)
        .toPromise();
      this.templateValidation = data.length > 0 ? data[0] : null;
      this.isLabValidation =
        this.templateValidation?.TemplateValidationStatus?.length > 0
          ? this.templateValidation.TemplateValidationStatus.some(
              (item) => item.ConsolidatedStatus !== 'Succeeded'
            )
          : false;
      if (this.isLabValidation) {
        this.modalData = {
          title: 'DANGER',
          description: 'DELETE_VALIDATION_DESCRIPTION',
          type: 'DEALLOCATE_LAB',
          showCancel: true,
        };
      } else {
        this.showDeleteLabPopup();
      }
    } else {
      this.showDeleteLabPopup();
    }
    if (!this.modelShow) {
      this.modelShow = true;
    }
  }

  showDeleteLabPopup() {
    this.modalData = {
      title: 'DANGER',
      description: 'MESSAGE_DEALLOCATE',
      type: 'DEALLOCATE_LAB',
      showCancel: true,
    };
  }

  private extendCredit() {
    this.modalRef = this.modalService.show(ExtendCreditComponent, {
      initialState: {
        title: this.translateSrv.instant('EXT_CREDIT_TITLE'),
        data: {
          successButtonText: this.translateSrv.instant('OK'),
          failureButtonText: this.translateSrv.instant('CANCEL'),
          v_code: this.vCode,
          approvalRequired: this.lab.DisableCreditAutoApprove,
          unit: `${this.lab.AllowedCreditLimit}$`,
          maxUnit: `${this.lab.MaxCreditLimit}$`,
        },
      },
    });
  }

  isDeviceSmall = (): boolean =>
    window.innerHeight >= 660 && window.innerWidth <= 912;
}
