import { Component, OnInit, Input, OnDestroy, Output, EventEmitter } from '@angular/core';
import { LabService } from '../../../services/lab.service';
import { Lab } from '../../../modals/lab.model';
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { NotificationService } from '../../../services/notification.service';
import { RemoteDesktopManager } from '../../remote/services/remote-desktop-manager.service';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { LabValidationDialogComponent } from '../lab-validation-dialog/lab-validation-dialog.component';
import {
  EventService,
  ValidationDialogStatus,
} from '../../../services/event.services';
import { EventEmitterService } from '@teams-auth';
import { miscellaneousConst } from '@utility';
import { ButtonLoadingService } from '@teams-ui-angular';

@Component({
  selector: 'cloudlabs-lab-validation',
  templateUrl: './lab-validation.component.html',
  styleUrls: ['./lab-validation.component.scss'],
})
export class LabValidationComponent implements OnInit, OnDestroy {
  private notifier = new Subject();
  @Output() updatePageLock = new EventEmitter();;
  @Input() labDetail: Lab;
  @Input() voucherCode: string;
  @Input() manager: RemoteDesktopManager;
  @Input() isDialog = false;
  @Input() isInlineValidation = false;
  @Input() stepUniqueId: string;
  @Input() templateValidation: any;
  @Input() isLabPreview = false;
  public overallStatus: any;
  public loading = false;
  public docActions = false;
  public expandModule = false;
  public modelShow = false;
  public modulemodelShow = false;
  public selectedModule: any;
  public manualStep: boolean;
  public CurrentStepIdCollection: Array<any> = [];
  public attendeeParameters: any;
  public steps: any;
  public AttendeeValues: any;
  public validationModule: any;
  public isRefreshing = false;
  public inlineValData: any;
  public validationModel = false;
  public modelData: any;
  public showValidationError: any;
  public modalRef: BsModalRef;
  isMylearning = false;
  private progressInterval: any;
  private inlineProgressInterval: any;
  refreshInlineValidation = false;
  constructor(
    private LabSrv: LabService,
    private toastr: ToastrService,
    private notificationService: NotificationService,
    private router: Router,
    private translateSrv: TranslateService,
    private modalService: BsModalService,
    private eventSrv: EventService,
    private eventEmitter: EventEmitterService,
    public btnLoadingService: ButtonLoadingService
  ) {
    this.LabSrv.labValidationRefresh
      .pipe(takeUntil(this.notifier))
      .subscribe(() => {
        this.getEnvironment();
      });
      const context = localStorage.getItem('context');
      if (context && context === miscellaneousConst.cloudlab) {
        this.isMylearning = true;
      }
  }

  async ngOnInit() {
    if(!this.isDialog && !this.isInlineValidation) {
      await this.getEnvironment();
      this.progressInterval = setInterval(() => { this.checkInprogressValidationStatus(); }, 60000);
    } else if(this.stepUniqueId && !this.isLabPreview) {
      const regex = /<validation step="([^"]+)" \/>/;
      this.stepUniqueId = this.stepUniqueId.match(regex)[1];
      this.getInlineValidationData();
      this.pollingInprogress();
    } else if(this.isLabPreview){
      const regex = /<validation step="([^"]+)" \/>/;
      this.stepUniqueId = this.stepUniqueId.match(regex)[1];
      this.loadValidationByStepId();
    }

    this.eventSrv.validationDialogStatus.subscribe((status) => {
      if (status === ValidationDialogStatus.CONFIRM_CLOSED) {
        this.validateLabModule();
      }
    });
    if(this.isDialog) {
      this.loadValidation();
    }
    this.eventSrv.onLoadValidation.subscribe(res => {
      this.getEnvironment();
    });
  }

  loadValidationByStepId() {
    this.LabSrv.getValidationByStepId(this.stepUniqueId).subscribe(res => {
      this.inlineValData = res;
    });
  }

  getInlineValidationData() {
    this.isRefreshing = true;
    this.LabSrv.getInlineValidation(this.voucherCode, this.stepUniqueId).subscribe(res => {
      this.inlineValData = res;
      this.isRefreshing = false;
      this.btnLoadingService.buttonLodingOff();
      if(this.refreshInlineValidation) {
        this.updatePageLock.emit();
        this.refreshInlineValidation = false
      }
      if(this.inlineValData.Status !== "In Progress" && this.inlineProgressInterval) {
        clearInterval(this.inlineProgressInterval);
      } 
      
    },(error) => {
      this.isRefreshing = false;
      const code = 'Error - 50044';
      this.btnLoadingService.buttonLodingOff();
      this.eventEmitter.debugAlert(code, error);
    }); 
  }

  refreshvalidation() {
    this.refreshInlineValidation = true;
    this.btnLoadingService.indexValue = this.inlineValData.StepId;
    this.btnLoadingService.setLoding(true, this.translateSrv.instant('LOADING'));
    this.getInlineValidationData();
  }

  pollingInprogress() {
    this.inlineProgressInterval = setInterval(() => {
      this.getInlineValidationData();
    }, 60000);
  }

  openVlidationModel() {
    this.modelData = this.inlineValData;
    this.validationModel = true;
  }

  loadValidation() {
    let notValidatedCount = 0;
    let pendingCount = 0;
    let successCount = 0;
    let failedCount = 0;

    this.templateValidation.TemplateValidationStatus.forEach(template => {
      template.Exercises.forEach(exercise => {
        exercise.Steps.forEach(step => {
          if (step.Status === 'Not Validated') {
            notValidatedCount++;
          } else if (step.Status === 'Validation Pending') {
            pendingCount++;
          } else if (step.Status === 'Succeeded') {
            successCount++;
          } else if (step.Status === 'Failed') {
            failedCount++;
          }
        });
      });
    });
    this.overallStatus = { notValidatedCount, pendingCount, successCount, failedCount };
  }

  async getEnvironment() {
    this.loading = true;
    const data: any = await this.LabSrv.getValidationStatus(
      this.voucherCode
    ).toPromise();
    this.templateValidation = data.length > 0 ? data[0] : null;
    this.loading = false;
    if(this.isDialog) {
      this.loadValidation();
    }
  }

  async validateManualStep(validationModule, step) {
    this.btnLoadingService.indexValue = step.StepId;
    this.btnLoadingService.setLoding(true, this.translateSrv.instant('LOADING'));
    this.validationModule = validationModule;
    this.steps = step;
    this.selectedModule = validationModule.ModuleId;
    if (step.ValidationType === 'manual') {
      this.manualStep = true;
    }
    this.CurrentStepIdCollection.push(step.StepId);

    if (
      step.ValidationType === 'custom' ||
      step.ValidationType === 'cloudlabs-azure' ||
      step.ValidationType === 'cloudlabs-vm'
    ) {
      this.LabSrv.getAttendeeParameters(step.StepId, this.voucherCode)
        .toPromise()
        .then((res: any) => {
          if (res) {
            this.attendeeParameters = res;
            if (this.attendeeParameters.length > 0) {
              this.buildShowValidationError(this.attendeeParameters);
              this.modelShow = true;
              if(this.manager) {
                this.manager.setFocused(false);
              }
              //this.manager.setFocused(false)
            } else {
              this.validateModule(validationModule);
            }
          }
        });
    } else {
      this.validateModule(validationModule);
    }
  }

  closeDialog(event: KeyboardEvent) {
    if (event.key === 'Escape') {
      this.modulemodelShow = false;
    }
  }

  buildShowValidationError(validationData: any[]) {
    this.showValidationError = {};
    for (const field of validationData) {
      this.showValidationError[field.Name] = false;
    }
  }

  validateSubmit() {
    let isFormValid = true;
    for (const field of this.attendeeParameters) {
      if (field.Value === null || field.Value === '') {
        this.showValidationError[field.Name] = true;
        isFormValid = false;
      }
    }
    if (!isFormValid) {
      return;
    }
    this.AttendeeValues = {};
    this.AttendeeValues.Parameters = this.attendeeParameters;
    this.AttendeeValues.InviteLink = this.voucherCode;
    this.AttendeeValues.StepId = this.steps.StepId;
    this.LabSrv.validateCustomTemplateStep(this.AttendeeValues).subscribe(
      (res: any) => {
        if(this.isInlineValidation) {
          this.getInlineValidationData();
          if(res.Status === "Succeeded") {
            this.updatePageLock.emit();
          }
        } else {
          this.getLabValidationData();
          this.loadValidation();
          this.btnLoadingService.buttonLodingOff();
        }
        this.notificationService.success(
          this.translateSrv.instant('MESSAGE_VALIDATED'),
          null
        );
        this.CurrentStepIdCollection = [];
      }, (error) => {
        const code = 'Error - 50027';
        this.eventEmitter.debugAlert(code, error);
        this.btnLoadingService.buttonLodingOff();
      }
    );
    this.modelShow = false;
  }

  validateModule(validationModule, source?: string) {
    this.validationModule = validationModule;
    if (source === 'validateAll') {
      //this.modulemodelShow = true;
      this.modalRef = this.modalService.show(LabValidationDialogComponent, {
        initialState: {
          title: this.translateSrv.instant('SUBMIT_ALERT_MODEL_TITLE'),
          data: {
            description: this.translateSrv.instant('LAB_VALIDATION_ALERT'),
            failureButtonText: this.translateSrv.instant('CANCEL'),
            successButtonText: this.translateSrv.instant('OK'),
          },
        },
      });
    } else {
      this.validateLabModule();
    }
  }

  validateLabModule() {
    const exercises: any = this.validationModule?.Exercises;
    if (this.CurrentStepIdCollection.length === 0) {
      exercises.forEach((exercise) => {
        exercise.Steps.forEach((step) => {
          if (
            step.ValidationType !== 'manual' ||
            step.HasAttendeeParameter === false
          ) {
            this.CurrentStepIdCollection.push(step.StepId);
          }
        });
      });
    }
    this.LabSrv.validateTemplateModule(
      this.voucherCode,
      this.CurrentStepIdCollection
    ).subscribe((res: any) => {
      if(this.isInlineValidation) {
        this.getInlineValidationData();
        if(res[0].Status === "Succeeded") {
          this.updatePageLock.emit();
        }
        if(res[0].Status === "In Progress") {
          this.pollingInprogress();
        } else {
          clearInterval(this.inlineProgressInterval);
        }
      } else {
        this.getLabValidationData();
        this.btnLoadingService.buttonLodingOff();
      }
      if (this.manualStep === true && res[0].Status === "Validation Pending") {
        this.notificationService.success(
          this.translateSrv.instant('PENDING_STATUS_MESSAGE'),
          null
        );
        this.manualStep = false;
      } else if(res && res.length === 1 && res[0].Status === "In Progress") {
        this.notificationService.success(
          this.translateSrv.instant('INPROGRESS_STATUS_MESSAGE'),
          null
        );
      } else if(res && res.length > 1) {
        this.notificationService.success(
          this.translateSrv.instant('MESSAGE_VALIDATION_INITIATED'),
          null
        );
      } else {
        this.notificationService.success(
          this.translateSrv.instant('VALIDATION_EXECUTED'),
          null
        );
      }
      this.CurrentStepIdCollection = [];
      // this.loadValidation();
    }, (error) => {
      const code = 'Error - 50028';
      this.eventEmitter.debugAlert(code, error);
      this.btnLoadingService.buttonLodingOff();
    });
    this.modulemodelShow = false;
  }

  async getLabValidationData() {
    this.isRefreshing = true;
    await this.getEnvironment();
    this.isRefreshing = false;
  }

  onChange(event, field: any) {
    field.Value = (event.target as HTMLInputElement).value;
  }
  keyPressed(event: KeyboardEvent, field: any) {
    const urlParts = this.router.url.split('/');
    if (urlParts.includes('environment')) {
      const inputKeys =
        'abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ1234567890! @#$%^&*()-_=+[]{};:\'",.<>?/|\\';
      if (inputKeys.includes(event.key)) {
        field.Value = field.Value + event.key;
      } else if (event.key === 'Backspace') {
        if (field.Value.length > 0) {
          field.Value = field.Value.slice(0, -1);
        }
      }
    } else {
      field.Value = (event.target as HTMLInputElement).value;
    }
    if (field.Value !== null || field.Value !== '') {
      this.showValidationError[field.Name] = false;
    }
  }

  ngOnDestroy() {
    this.notifier.next();
    this.notifier.complete();
    clearInterval(this.progressInterval);
    if(this.inlineProgressInterval) {
      clearInterval(this.inlineProgressInterval);
    }
  }
  
  private checkInprogressValidationStatus(): void {
    let checkStatus = false;
    this.templateValidation?.TemplateValidationStatus.forEach((element: any) => {
      element?.Exercises.forEach((element2: any) => {
        if (element2?.Type === 'lab') {
          element2?.Steps.forEach((element3: any) => {
            if (element3?.Status === 'In Progress') {
              checkStatus = true;
            }
          });
        }
      });
    });

    if (checkStatus) {
      this.getEnvironment();
    } else {
      clearInterval(this.progressInterval);
    }
  }
}
