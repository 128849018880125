import { AfterViewInit, Component, EventEmitter, Input, Output } from '@angular/core';
import { DeviceDetectorService } from 'ngx-device-detector';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'app-dialoge-prompt',
  templateUrl: './dialoge-prompt.component.html'
})
export class DialogePromptComponent implements AfterViewInit {
@Input() public headerText: any = '';
@Input() public isDialog: boolean;
@Input() public dialogClass: string;
@Input() public closable = true;
@Output() private popupResponse = new EventEmitter();

public openDialog = false;
public headerValue: any;
public headerClass = '';
constructor(
  public deviceDetectorService: DeviceDetectorService
) { }


ngAfterViewInit(): void {
  const crossIcon = document.querySelector('.p-dialog-header-close');
  if(crossIcon) {
    crossIcon.removeAttribute('tabindex');
    crossIcon.setAttribute('aria-label','Close')
  }
  const list = document.getElementsByClassName("p-dialog p-component p-dialog-resizable");
  if(list.length > 0) {
    list[0].setAttribute('aria-describedby','dialog-title')
  }
}

ngOnChanges(): void {
  this.openPopup();
}
/**
 * @description: open and change dialog configuration
 */
private openPopup(): void {
  this.headerValue = this.headerText;
  this.openDialog = this.isDialog;
  this.headerClass = this.dialogClass;
}

/**
 * @description: close Popup
 */
public closePopup(): void {
  this.isDialog = false;
  this.popupResponse.emit(this.isDialog);
}

}
