import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
} from '@angular/core';
import { miscellaneousConst } from '@utility';

import { QuestionHelperService } from '../../../services/question/question-helper.service';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'match-options-question',
  templateUrl: './match-options-question.component.html',
})
export class MatchOptionsQuestionComponent implements OnChanges {
  @Input() public question;
  public options: Array<any> = [];
  public selectedValue: any = '';
  public selectedKey: any = '';
  public selectedOption: any = '';
  public dropOptions: any = [];
  public miscellaneousConst = miscellaneousConst;
  @Input() versionKey: string;

  @Output() UserSelectAnswerOption = new EventEmitter();
  @Input() userAnswer = [];

  constructor(
    private questionHelper: QuestionHelperService
  ) { }

  ngOnChanges(): void {
    this.onLoadData();
  }
  private onLoadData(): void {
    if (this.question !== undefined) {
      this.question.labels.forEach((element) => {
        element.option = null;
      });
      this.options = this.question.options;
      this.options.forEach((element) => {
        element.disabled = false;
      });
    }
    if (this.question.userAnswers.length > 0) {
      this.selectedUserAnswer();
    }
  }
  /**
   * @description: Load Selected User Answers
   */
  private selectedUserAnswer(): any {
    this.questionHelper.selectedUserAnswerMatchOption(this.question);
  }

  public onListValueDrop(e: any, key: any): void {
    this.questionHelper.onMachOptionListValueDrop(this.selectedValue, this.question, this.options, e, key);
    this.saveMatchOption();
  }

  
  /**
   * @description: Move Left  to Right
   */
  public leftToRightMove(): void {
    this.questionHelper.leftToRightMoveMatchOption(this.question, this.options, this.selectedValue);
    this.saveMatchOption();
    this.selectedValue = '';
  }
  
    /*** @description: Selected Right Options
   */
  public selectedRightOptions(value: any): void {
    this.selectedValue = value;
  }

  public rightToLeftMove(): void {
    if (this.selectedKey !== '') {
      this.questionHelper.rightToLeftMoveMatchOption(this.question, this.options, this.selectedKey);
      this.saveMatchOption();
      this.selectedKey = '';
    }
  }
  /**
   * @description: Selected Options
   */
  public selectedOptions(value: any): void {
    this.selectedValue = value;
  }
  /**
   * @description: Selected Options Labels
   */
  public selectedOptionsByLabels(key): void {
    this.selectedKey = key;
  }

  public upListValue(): void {
    if (this.selectedKey !== '') {
      this.questionHelper.upListValueMatchOption(this.question, this.selectedKey);
      this.saveMatchOption();
      this.selectedKey = '';
    }
  }

  public downListValue(): void {
    if (this.selectedKey !== '') {
      this.questionHelper.downListValueMatchOption(this.question, this.selectedKey);
      this.saveMatchOption();
      this.selectedKey = '';
    }
  }

  private saveMatchOption(): void {
    const userAnswer = this.question.labels.map((i) => ({
      key: i.option ? i.key : null,
      value: i.option ? i.option.key : null,
    }));
    if(this.question?.isVersioned) {
      this.question?.questionVersions.forEach((element) => {
        if(element.versionKey === this.versionKey) {
          element.userAnswers = userAnswer.filter(i => i.key !== null && i.value !== null);
          this.question.userAnswers = userAnswer.filter(i => i.key !== null && i.value !== null);
        }
      });
    } else {
      this.question.userAnswers = userAnswer.filter(i => i.key !== null && i.value !== null);
    }
    this.UserSelectAnswerOption.emit(this.question);
  }
}
