<span *ngIf="showExpand">
    <a role="button" href="javascript:void(0)" aria-label="Toggle full-screen view" placement="left" [title] = "'TOGGLE_SCREEN' | translate" (click)="hideShow()">
        <!-- <img aria-hidden="true" class="toggle-icon" aria-label="Toogle option" src="../../../../assets/images/Icon awesome-expand-arrows-alt.svg" /> -->
            <svg *ngIf="appType !== 'attendee'" aria-hidden="true" class="toggle-icon" xmlns="http://www.w3.org/2000/svg" width="14.968" height="14.968" viewBox="0 0 14.968 14.968"><defs><style></style></defs><path class="a" d="M14.968,12.674v3.742a.8.8,0,0,1-.8.8H10.424a.8.8,0,0,1-.568-1.37l1.209-1.209L7.484,11.057,3.9,14.642l1.21,1.206a.8.8,0,0,1-.568,1.37H.8a.8.8,0,0,1-.8-.8V12.674a.8.8,0,0,1,1.37-.568l1.209,1.209L6.163,9.734,2.579,6.149,1.37,7.362A.8.8,0,0,1,0,6.794V3.052a.8.8,0,0,1,.8-.8H4.544a.8.8,0,0,1,.568,1.37L3.9,4.829,7.484,8.411l3.583-3.585L9.856,3.62a.8.8,0,0,1,.568-1.37h3.742a.8.8,0,0,1,.8.8V6.794a.8.8,0,0,1-1.37.568L12.389,6.152,8.8,9.734l3.584,3.585L13.6,12.109A.8.8,0,0,1,14.968,12.674Z" transform="translate(0 -2.25)"/></svg>
            <i *ngIf="appType === 'attendee'  && toggle" class="fas fa-expand-alt"></i>
            <i *ngIf="appType === 'attendee' && !toggle" class="fas fa-compress-alt"></i>
    </a>
</span>
<div role="alert" style="height: 0px; font-size: 0px;" *ngIf="hidingAlertMessages">
    <p *ngIf="isFullScreen" [attr.aria-hidden]='!hidingAlertMessages'>Applied</p>
    <p *ngIf="!isFullScreen" [attr.aria-hidden]='!hidingAlertMessages'>Removed</p>
</div>
