<div [innerHTML]="labDescription | sanitizeHtml"></div>
<div class="row">
  <div class="col-md-8 subTitle mt-4">
    <ul>
      <li><i alt="" role="img" aria-label="img" aria-hidden="true" class="fa fa-clock-o "></i>
        {{hours}} {{ 'HOURS' | translate:{singularPlural: hours | pluralize} }} and {{minutes}} {{ 'MINUTES' | translate:{singularPlural: minutes | pluralize} }}</li>
    </ul>
  </div>
  <div class="col-md-4 subTitle mt-4 tags">
    <ul>
      <li style="word-break: break-all;">
        <i class="pr-1 fa fa-globe" aria-hidden="true"></i>{{ lab.Tags }}
      </li>
    </ul>
  </div>
</div>
<div style="padding-bottom: 100px"></div>