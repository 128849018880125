import { ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import * as moment from 'moment';

@Component({
  selector: 'cloudlabs-counter',
  templateUrl: './counter.component.html',
  styleUrls: ['./counter.component.scss']
})
export class CounterComponent implements OnInit {

  @Input() date: Date;

  day: number;
  hour: number;
  minutes: number;
  timer: any;

  constructor(private cdr: ChangeDetectorRef) {
    this.timer = setInterval(() => {
      this.formatDuration()
      this.cdr.detectChanges();
    }, 60000);
   }

  ngOnInit(): void {
    this.formatDuration();
  }

  formatDuration() {
    const remainingTime = this.getRemainingTimeInDaysMinutesHours(this.date);
    this.day = remainingTime.days;
    this.minutes = remainingTime.minutes;
    this.hour = remainingTime.hours;
    if(this.day == 0 && this.hour == 0 && this.minutes == 0) {
      clearInterval(this.timer);
    }
  }

  transform(value: number | string): string {
    const number = value as number;
    return number > 1 ? 's' : '';
  }

   /**
   * TODO - Right now it is creating circular dependency if we move this to common-utilities. Need to fix this
   * @param utcDate
   * @returns
   */
   private getRemainingTimeInDaysMinutesHours(utcDate) {
    // const endDateTime = moment.utc(utcDate); // takes date in UTC format
    // const now = moment.utc(); // takes date in UTC format
    // const duration = moment.duration(endDateTime.diff(now));
    // let remainingTime;
    // if (duration.asDays() > 0) {
    //   remainingTime = {
    //     days: Math.abs(Math.trunc(duration.asDays())),
    //     hours: duration.hours(),
    //     minutes: duration.minutes()
    //   }
    // } else {
    //   if(endDateTime.hours() > 0 || endDateTime.minutes() > 0) {
    //     remainingTime = {
    //       days: Math.abs(Math.trunc(duration.asDays())),
    //       hours: endDateTime.hours(),
    //       minutes: endDateTime.minutes()
    //     }
    //   } else {
    //     remainingTime = {
    //       days: Math.abs(Math.trunc(duration.asDays())),
    //       hours: 23 - Math.abs(duration.hours()),
    //       minutes: 59 - Math.abs(duration.minutes())
    //     }
    //   }
    // }

    // New convert logic | Sheikh & Aakash
      const now = moment.utc();

      const backendDate =moment.utc(utcDate)
      const splitDate = utcDate.split('T')
      if (splitDate[1].includes('00:00:00')) {
        backendDate.set({hour: 23, minute: 59, second: 59});
      }
      const diffMs = backendDate.diff(now);
      const diffDuration = moment.duration(diffMs);
      const days = Math.floor(diffDuration.asDays());
      const hours = diffDuration.hours();

      const minutes = diffDuration.minutes();
      const remainingTime = {
              days: days,
              hours: hours,
              minutes: minutes
            }
      return remainingTime;
  }

  padLeft(str: string, padChar: string, length: number): string {
    while (str.length < length) {
      str = padChar + str;
    }
    return str;
  }
}

