import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  forwardRef,
  Input,
  OnInit,
  Output,
} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { Router } from '@angular/router';
import { ButtonLoadingService } from '../button-loading.service';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'teams-button',
  templateUrl: './button.component.html',
  styleUrls: ['./button.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => ButtonComponent),
      multi: true,
    },
  ],
})
export class ButtonComponent implements OnInit, ControlValueAccessor {
  @Input() id = '';
  @Input() title = '';
  @Input() title2 = '';
  @Input() processingTitle = '';
  @Input() disabled = false;
  @Output() buttonClick = new EventEmitter();
  @Input() classList =
    'btn ts-btn ts-btn-fluent-primary ts-btn-fluent button-height-1 ts-btn-width-default';
  @Input() ngClass = '';
  // Using 'ngClasses' because 'ngClass' creating a confilct | Sheikh
  @Input() ngClasses = '';
  @Input() isIcon = false;
  @Input() iconClass = '';
  @Input() iconNgClass = '';
  @Input() iconTitle = '';
  @Input() isImg = false;
  @Input() imgWidth = 25;
  @Input() imgSrc = '';
  @Input() imgAlt = '';
  @Input() imgClass = 'mr-1 mb-1';
  @Input() routerButtonLink = '';
  @Input() newWindow: false;
  @Input() value: string | boolean = '';
  @Input() titleText = '';
  @Input() style = '';
  @Input() showLoading: boolean;
  @Input() index: string | number = '';
  @Input() ariaLabel: string;
  @Input() ariaLabelledby: string;
  @Input() ariaDescribedby: string;
  constructor(private router: Router, public btnLoadingService: ButtonLoadingService, private cdr: ChangeDetectorRef) {}

  ngOnInit(): void {
    this.classList = this.classList.concat(' ', this.ngClass);
    this.classList = this.classList.concat(' ', this.ngClasses);
    this.iconClass = this.iconClass.concat(' ', this.iconNgClass);
  }

  onButtonClick(): void {
    this.buttonClick.emit();
  }
  routeTo() {
    if (this.newWindow) {
      window.open(this.routerButtonLink, '_blank');
    } else {
      this.router.navigate([this.routerButtonLink]);
    }
  }

  /**
   * Invoked when the model has been changed
   */
  // eslint-disable-next-line @typescript-eslint/no-empty-function, @typescript-eslint/no-explicit-any, @typescript-eslint/no-unused-vars
  onChange: (_: any) => void = (_: any) => {};

  /**
   * Invoked when the model has been touched
   */
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  onTouched: () => void = () => {};

  /**
   * Method that is invoked on an update of a model.
   */
  updateChanges(value: string) {
    this.onChange(value);
  }

  ///////////////
  // OVERRIDES //
  ///////////////

  /**
   * Writes a new item to the element.
   * @param value the value
   */
  writeValue(value: string): void {
    this.updateChanges(value);
  }

  /**
   * Registers a callback function that should be called when the control's value changes in the UI.
   * @param fn
   */
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  /**
   * Registers a callback function that should be called when the control receives a blur event.
   * @param fn
   */
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }
}
