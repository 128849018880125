import { Injectable } from '@angular/core';
import { NavigationExtras, Router } from '@angular/router';

import { getEntity, getEntityData, miscellaneousConst } from '@utility';

import * as _ from 'lodash';
import { LocalSessionStorageService } from '../local-session-storage.service';

@Injectable({
  providedIn: 'root'
})
export class TestSectionReviewHelperService {

  constructor(private router: Router, private localStorageService: LocalSessionStorageService) { }

  /**
   * @description: Count ElapseTime Continue Exam
   */
  public countElapseTime(examInstanceDetails, stopWatch): any {
    const examTimeInMinutes = examInstanceDetails
      .minutesToComplete;
    const examTimeInSecond = examTimeInMinutes * 60;
    let timer = examTimeInSecond * 1000;
    timer = timer - stopWatch * 1000;
    return timer;
  }
  /**
   * @description: Navigate Exam Question After Section Complete
   */
  public navigateExamQuestion(regUniqueName: any, sectionIndex,
    examId, examInstanceId, sectionId, questionId, sectionList, questions): any {
    if (regUniqueName !== undefined && regUniqueName !== '') {
      const navigationExtras: NavigationExtras = {
        queryParams: { regUniqueName: regUniqueName },
      };
      if (localStorage.getItem('context') === 'CloudLabs' ) {
        if (this.localStorageService.getCourseStorage() === 'false') {
        this.router.navigate(
          [
            `/${getEntity()}/${miscellaneousConst.navigation.practiceTests}/${miscellaneousConst.navigation.tests}/${examId}/${miscellaneousConst.navigation.testInstance}/${examInstanceId}/${miscellaneousConst.navigation.sections}/${sectionId}/${miscellaneousConst.navigation.questions}/${questionId}`,
          ],
          navigationExtras
        );
        }
        else if (this.localStorageService.getCourseStorage() === 'true') {
          this.router.navigate(
            [
              `/${getEntity()}/${getEntityData()}/${miscellaneousConst.navigation.test}/${miscellaneousConst.navigation.practiceTest}/${miscellaneousConst.navigation.tests}/${examId}/${miscellaneousConst.navigation.testInstance}/${examInstanceId}/${miscellaneousConst.navigation.sections}/${sectionId}/${miscellaneousConst.navigation.questions}/${questionId}`,
            ],
            navigationExtras
          );
        }
      } else if (localStorage.getItem('context') === 'PracticeTest') {
        this.router.navigate(
          [
            `/${miscellaneousConst.navigation.tests}/${examId}/${miscellaneousConst.navigation.testInstance}/${examInstanceId}/${miscellaneousConst.navigation.sections}/${sectionId}/${miscellaneousConst.navigation.questions}/${questionId}`,
          ],
          navigationExtras
        );
      }
    } else if (
      sectionList.length > 1 &&
      sectionIndex !== sectionList.length &&
      questions.length > 0
    ) {
      if (localStorage.getItem('context') === 'CloudLabs') {
        if (this.localStorageService.getCourseStorage() === 'false') {
        this.router.navigate([
          `/${getEntity()}/${miscellaneousConst.navigation.practiceTests}/${miscellaneousConst.navigation.tests}/${examId}/${miscellaneousConst.navigation.testInstance}/${examInstanceId}/${miscellaneousConst.navigation.sections}/${sectionId}/${miscellaneousConst.navigation.questions}/${questionId}`,
        ]);
        }
        else if (this.localStorageService.getCourseStorage() === 'true') {
          this.router.navigate([
            `/${getEntity()}/${getEntityData()}/${miscellaneousConst.navigation.test}/${miscellaneousConst.navigation.practiceTest}/${miscellaneousConst.navigation.tests}/${examId}/${miscellaneousConst.navigation.testInstance}/${examInstanceId}/${miscellaneousConst.navigation.sections}/${sectionId}/${miscellaneousConst.navigation.questions}/${questionId}`,
          ]);
        }
      } else if (localStorage.getItem('context') === 'PracticeTest') {  
        this.router.navigate([
          `/${miscellaneousConst.navigation.tests}/${examId}/${miscellaneousConst.navigation.testInstance}/${examInstanceId}/${miscellaneousConst.navigation.sections}/${sectionId}/${miscellaneousConst.navigation.questions}/${questionId}`,
        ]);
      }
    }
  }
  /**
   * @description: Navigate Exam Result
   */

  public navigateExamResult(regUniqueName, examId, examInstanceId): any {
    if (regUniqueName !== undefined && regUniqueName !== '') {
      const navigationExtras: NavigationExtras = {
        queryParams: { regUniqueName: regUniqueName },
      };
      if (localStorage.getItem('context') === 'CloudLabs') {
        if (this.localStorageService.getCourseStorage() === 'false') {
        this.router.navigate(
          [
            `/${getEntity()}/${miscellaneousConst.navigation.practiceTests}/${miscellaneousConst.navigation.tests}/${examId}/${miscellaneousConst.navigation.testInstance}/${examInstanceId}/${miscellaneousConst.navigation.results}`,
          ],
          navigationExtras
        );
        }
        else if (this.localStorageService.getCourseStorage() === 'true') {
          this.router.navigate(
            [
              `/${getEntity()}/${getEntityData()}/${miscellaneousConst.navigation.test}/${miscellaneousConst.navigation.practiceTest}/${miscellaneousConst.navigation.tests}/${examId}/${miscellaneousConst.navigation.testInstance}/${examInstanceId}/${miscellaneousConst.navigation.results}`,
            ],
            navigationExtras
          );
        }
      } else if (localStorage.getItem('context') === 'PracticeTest') {  
        this.router.navigate(
          [
            `/${miscellaneousConst.navigation.tests}/${examId}/${miscellaneousConst.navigation.testInstance}/${examInstanceId}/${miscellaneousConst.navigation.results}`,
          ],
          navigationExtras
        );
      }
    } else {
      if (localStorage.getItem('context') === 'CloudLabs') {
        if (this.localStorageService.getCourseStorage() === 'false') {
          this.router.navigate([
            `/${getEntity()}/${miscellaneousConst.navigation.practiceTests}/${miscellaneousConst.navigation.tests}/${examId}/${miscellaneousConst.navigation.testInstance}/${examInstanceId}/${miscellaneousConst.navigation.results}`,
          ]);
        }
        else if (this.localStorageService.getCourseStorage() === 'true') {
          this.router.navigate([
            `/${getEntity()}/${getEntityData()}/${miscellaneousConst.navigation.test}/${miscellaneousConst.navigation.practiceTest}/${miscellaneousConst.navigation.tests}/${examId}/${miscellaneousConst.navigation.testInstance}/${examInstanceId}/${miscellaneousConst.navigation.results}`,
          ]);
        }
      } else if (localStorage.getItem('context') === 'PracticeTest') { 
        this.router.navigate([
          `/${miscellaneousConst.navigation.tests}/${examId}/${miscellaneousConst.navigation.testInstance}/${examInstanceId}/${miscellaneousConst.navigation.results}`,
        ]);
      }
    }
  }
  /**
   * @description: Is Section Review Allowed or not
   */
  public getIsSectionReview(sectionList, sectionId): any {
    if (sectionList.length > 0) {
      const sectionReviewAllowed = sectionList.find(
        (i) =>
          i.examSectionUniqueName === sectionId && i.reviewAllowed === true
      );
      return !_.isNil(sectionReviewAllowed);
    } else {
      return false;
    }
  }

  /**
   * @description: check Any Section Instruction
   */
  public getIsSectionInstruction(sectionList, sectionId): any {
    if (sectionList.length > 0) {
      const sectionInstruction = sectionList.find(
        (i) => i.instruction !== null && i.examSectionUniqueName === sectionId
      );
      return !_.isNil(sectionInstruction);
    } else {
      return false;
    }
  }
  /**
   * @description: Replace Dropdown and NumericInput with ----
   */
  public replaceToDash(questions): any {
    questions.forEach(element => {
      if (element.questionType === 'Dropdown') {
        const DropDowns = element.options.reduce((ind, jnd) => {
          ind[jnd.key] = [...(ind[jnd.key] || []), jnd];
          return ind;
        }, {});
        const result = Object.keys(DropDowns).map((key) => {
          return [key, DropDowns[key]];
        });
        _.each(result, (sub, i) => {
          if (!_.isUndefined(sub)) {
            element.question = element.question.replace(
              '[[' + result[i][0] + ']]',
              '-------'
            );
          }
        });
      }
      if (element.questionType === 'NumericInput') {
        if (element.question.includes('[[')) {
          const splitKey = element.question.split('[[')[1];
          const splitkey2 = splitKey.split(']]');
          const keyName = splitkey2[0];
          element.question = element.question.replace(
            '[[' + keyName + ']]',
            '-------'
          );
        }
      }
    });
  }
}
