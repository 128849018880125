import {
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  OnChanges,
  Output,
  Renderer2,
  ViewChild,
} from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import * as _ from 'lodash';

import { QuestionHelperService } from '../../../services/question/question-helper.service';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'drop-down-question',
  templateUrl: './drop-down-question.component.html',
})
export class DropDownQuestionComponent implements OnChanges {
  @Input() question;
  @ViewChild('dropDown') dropDownSelectorElement: ElementRef;
  dropdownStrList: any;
  @Input() userAnswer = [];
  @Input() versionKey: string;
  @Output() UserSelectAnswerOption = new EventEmitter();

  constructor(
    private sanitizer: DomSanitizer,
    private questionHelper: QuestionHelperService,
    private ref: ChangeDetectorRef,
    private renderer: Renderer2
  ) {}

  ngOnChanges(): void {
    this.onDropDownLoadData();
  }

  /**
   * @description: DropDown Bind Value
   */
  private onDropDownLoadData(): any {
    if (this.question !== undefined) {
      this.question = this.questionHelper.onDropDownLoadData(
        this.question
      ).questions;
      const result = this.questionHelper.onDropDownLoadData(
        this.question
      ).results;
      this.dropdownStrList = this.sanitizer.bypassSecurityTrustHtml(
        this.question.question
      );
      setTimeout(() => {
        _.each(result, (sub, index) => {
          if (!_.isUndefined(sub)) {
            const v = result[index];
            if (this.question.userAnswers.length > 0) {
              const selectedOption = this.question.userAnswers.find(
                (o) => o.key === v[0]
              );
              const button = document.getElementById(result[index][0]);
              if (button !== null && selectedOption) {
                const dropdown = button.getElementsByClassName(
                  'fabric-icon-text-pos-1'
                )[0];
                dropdown.innerHTML = selectedOption.value;
              }
            }
          }
        });
      }, 1000);
    }
  }

  @HostListener('document:click', ['$event']) onClick(event: Event, id): any {
    // tslint:disable-next-line:no-string-literal
    this.onOptionSelect(event, id);
  }

  @HostListener('keydown.enter', ['$event']) onEnter(event: Event, id): any {
    // tslint:disable-next-line:no-string-literal
    this.onOptionSelect(event, id);
  }

  private onOptionSelect(event, id) {
    if (
      event.target['attributes']['value']?.value !== undefined &&
      // tslint:disable-next-line:no-string-literal
      event.target['parentNode'].class !== '' &&
      event.target['id'] !== ' '
    ) {
        const userAnswers = this.question.userAnswers
        const btnElementId = event.target['id']
        const btnElement = (<HTMLInputElement>document.getElementById(btnElementId))
        const span = this.renderer.createElement('span')
        span.innerHTML = event.target['attributes']['value']?.value
        btnElement.innerHTML = ''
        this.renderer.appendChild(btnElement, span);
      // tslint:disable-next-line:no-string-literal
      const answer = {
        key: event.target['id'],
        value: event.target['attributes']['value']?.value,
      };
      if(!userAnswers.some((e)=>e.key==answer))
      {
        userAnswers.push(answer)
      }
      let findDuplicateKey = this.question.userAnswers.find(
        (i) => i.key === answer.key
      );
      if (findDuplicateKey === undefined) {
        if(this.question?.isVersioned) {
          this.question?.questionVersions.forEach((element) => {
            if(element.versionKey === this.versionKey) {
              element.userAnswers = userAnswers
              this.question.userAnswers = userAnswers
            }
          });
        } else {
          this.question.userAnswers = userAnswers
        }
      } else {
          const findIndexOfDuplicateValue = this.question.userAnswers.findIndex(
            (i) => i.key === answer.key
          );
          const newObject: any = Object.assign({}, findDuplicateKey);
          newObject.key = answer.key;
          newObject.value = answer.value;
          findDuplicateKey = newObject;
          if(this.question?.isVersioned) {
            this.question?.questionVersions.forEach((element) => {
              if(element.versionKey === this.versionKey) {
                element.userAnswers.splice(findIndexOfDuplicateValue, 1);
                element.userAnswers.splice(
                  findIndexOfDuplicateValue,
                  0,
                  findDuplicateKey
                );
                this.question.userAnswers.splice(findIndexOfDuplicateValue, 1);
                this.question.userAnswers.splice(
                  findIndexOfDuplicateValue,
                  0,
                  findDuplicateKey
                );
              }
            });
          } else {
            this.question.userAnswers.splice(findIndexOfDuplicateValue, 1);
            this.question.userAnswers.splice(
              findIndexOfDuplicateValue,
              0,
              findDuplicateKey
            );
          }
        }
      this.UserSelectAnswerOption.emit(this.question);
   }
  }
}
