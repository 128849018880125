import { Injectable } from '@angular/core';
import {detect} from 'detect-browser';
import {ToastrService} from 'ngx-toastr';
import {IndividualConfig} from 'ngx-toastr/toastr/toastr-config';

@Injectable({
  providedIn: 'root'
})
export class NotificationService {
  private browser = detect();

  constructor(
    private toastr: ToastrService
  ) { }

  showNotification(
    text: string,
    title: string | null,
    notificationType: 'error' | 'success' | 'warning',
    notificationConfig: Partial<IndividualConfig> = {
      enableHtml: true,
      timeOut: 3000
    }
  ) {
    const message = this.constructMessageAccordingToBrowser(text);
    this.showFinalNotification(message, title, notificationType, notificationConfig);
  }

  success(
    text: string,
    title: string | null,
    notificationConfig: Partial<IndividualConfig> = {
      enableHtml: true,
      timeOut: 3000
    }
  ) {
    const message = this.constructMessageAccordingToBrowser(text);
    this.toastr.success(message, title, notificationConfig);
  }

  error(
    text: string,
    title: string | null,
    notificationConfig: Partial<IndividualConfig> = {
      enableHtml: true,
      timeOut: 3000
    }
  ) {
    const message = this.constructMessageAccordingToBrowser(text);
    this.toastr.error(message, title, notificationConfig)
  }

  warning(
    text: string,
    title: string | null,
    notificationConfig: Partial<IndividualConfig> = {
      enableHtml: true,
      timeOut: 3000
    }
  ) {
    const message = this.constructMessageAccordingToBrowser(text);
    this.toastr.warning(message, title, notificationConfig)
  }

  private constructMessageAccordingToBrowser(message: string) {
    if (this.browser.name === 'edge' || this.browser.name === 'edge-chromium') {
      return  '<p role="alert" aria-live="assertive">' + message + '</p>';
    } else {
      return  '<p role="alert" aria-live="assertive">' + message + '</p>';
    }
  }

  private showFinalNotification(
    message: string,
    title: string | null,
    notificationType: 'error' | 'success' | 'warning',
    notificationConfig: Partial<IndividualConfig>
  ) {
    switch (notificationType) {
      case 'error':
        this.toastr.error(message, title, notificationConfig);
        break;
      case 'success':
        this.toastr.success(message, title, notificationConfig);
        break;
      case 'warning':
        this.toastr.warning(message, title, notificationConfig);
        break;
    }
  }
}
