<ng-container *ngIf="context === 'tile'">
    <div class="pl-2"  *ngIf="days !== '' || hours !== '' || minutes !== ''">
        <span class="text-dark" *ngIf="days !== ''">
            {{days}}&nbsp;{{ 'DAYS' | translate:{singularPlural: days | pluralize} }}
        </span>
        <span class="text-dark" *ngIf="hours !== ''">
            {{hours}}&nbsp;{{ 'MY_LEARNING.COMMON.HOURS' | translate:{singularPlural: hours | pluralize} }}
        </span>
        <span class="text-dark" *ngIf="minutes !== ''">
            {{minutes}}&nbsp;{{ 'MY_LEARNING.COMMON.MINUTES' | translate:{singularPlural: minutes | pluralize} }}
        </span>
    </div>
</ng-container>
<ng-container *ngIf="context === 'details'">
    <span class=""  *ngIf="days !== '' || hours !== '' || minutes !== ''">
        <span class="text-dark">
            <span *ngIf="days !== ''">
                {{days}} {{ 'DAYS' | translate:{singularPlural: days | pluralize} }}
            </span>
            <span *ngIf="hours !== ''">
                {{hours}} {{ 'MY_LEARNING.COMMON.HOURS' | translate:{singularPlural: hours | pluralize} }}
            </span>
            <span *ngIf="minutes !== ''">
                {{minutes}} {{ 'MY_LEARNING.COMMON.MINUTES' | translate:{singularPlural: minutes | pluralize} }}
            </span>
        </span>
    </span>
</ng-container>
<ng-container *ngIf="context === 'labpopup'">
    <span class="d-flex flex-column mt-4 mb-0" *ngIf="days !== '' || hours !== '' || minutes !== ''">
        <span class="headingSpan" *ngIf="days !== ''">
            {{days}} {{ 'DAYS' | translate:{singularPlural: days | pluralize} }}
        </span>
        <span class="headingSpan" *ngIf="hours !== ''" >
            {{hours}} {{ 'MY_LEARNING.COMMON.HOURS' | translate:{singularPlural: hours | pluralize} }}
        </span>
        <span class="headingSpan" *ngIf="minutes !== ''">
            {{minutes}} {{ 'MY_LEARNING.COMMON.MINUTES' | translate:{singularPlural: minutes | pluralize} }}
        </span>
    </span>
</ng-container>
<ng-container *ngIf="context === 'labtile'">
    <small class="font-weight-normal text-dark">
        <span *ngIf="days !== ''">
            {{days}} {{ 'DAYS' | translate:{singularPlural: days | pluralize} }}
        </span>
        <span *ngIf="hours !== ''">
            {{hours}} {{ 'MY_LEARNING.COMMON.HOURS' | translate:{singularPlural: hours | pluralize} }}
        </span>
        <span *ngIf="minutes !== ''">
            {{minutes}} {{ 'MY_LEARNING.COMMON.MINUTES' | translate:{singularPlural: minutes | pluralize} }}
        </span>
    </small>
</ng-container>