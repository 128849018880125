<div class="w-100">
    <ul class="singleresult-list singlequestion-list-result">
        <li>
            <!-- <span class="sql-title">Question Section</span> -->
            <div class="ans-question-box case-study-sql-ques">
                <strong class="sql-number">{{index}}. </strong><strong class="sql-ques"
                    [innerHTML]="question?.question | targetBlank"></strong>
            </div>
            <!-- <span><b>Note:</b>  Select 2 right answer</span>
                    <span class="sql-title">Answer Section</span> -->
            <div class="sql-ans-box" *ngIf="question.userAnswers.length>0">
                <div class="sql-option-box" *ngFor="let option of question?.options"
                    [attr.aria-label]="(option.checked ? 'Checked ' : 'Not checked ') + option.value + (option.answer===1 ? ' Correct Answer' : '') + (option.answer===2 ? ' Incorrect Answer' : '')"
                    [ngClass]="option.answer===1?'sql-option-box-success':option.answer===2?'sql-option-box-failure':''">
                    <teams-checkbox [attr.inert]="true" class="pe-none" [value]='option.key' [(ngModel)]="option.checked"
                        [checked]="option.checked" [inputClass]="'ts-checkbox-fluent'" [label]="option.value"
                        [labelClass]="'ts-checkbox-fluent-text icon-cursor'"
                        [ngClassInput]="option.answer===1 ? 'ts-checkbox-fluent-success' : option.answer===2 ? 'ts-checkbox-fluent-failure' : '' ">
                    </teams-checkbox>
                </div>
            </div>
            <div class="sql-ans-box" *ngIf="question.userAnswers.length===0">
                <div class="sql-option-box" *ngFor="let option of question?.options"
                    [attr.aria-label]="(option.checked ? 'Checked ' : 'Not checked ') + option.value + (option.attempt===1 ? ' Correct Answer' : '')"
                    [ngClass]="option.attempt===1?'notAttempt':''">
                    <teams-checkbox [attr.inert]="true" class="pe-none" [value]='option.key' [(ngModel)]="option.checked"
                        [checked]="option.checked" [inputClass]="'ts-checkbox-fluent'" [label]="option.value"
                        [labelClass]="'ts-checkbox-fluent-text icon-cursor'"></teams-checkbox>
                </div>
            </div>
        </li>
    </ul>
    {{question.userScore}}/{{question.questionScore}} {{ 'exam.result.points' | translate }}
</div>