<div>
    <ul class="singlequestion-list">
        <li>
            <span class="sql-title">{{ 'exam.questions.questionSection' | translate }}</span>
            <div class="sql-ques case-study-sql-ques">
                <h4 role="heading" aria-level="2" class="" [innerHTML]="question?.question | targetBlank"></h4>
                <span *ngIf="question?.note !== null"><strong>{{ 'exam.questions.note' | translate }}: </strong> <span [innerHTML]="question?.note"></span></span>
            </div>
            <span *ngIf="question?.instruction !== null"><strong>{{ 'exam.questions.instruction' | translate }}:</strong>
        <span [innerHTML]="question?.instruction"></span></span>
            <span class="sql-title">{{ 'exam.questions.answerSection' | translate }}</span>
            <div class="ans-swap-box row">
                <!--Left section-->
                <div class="col-md-4 col-12">
                    <div class="row">
                        <div class="col-md-10">
                            <ul class="swap-left-list-box swap-middle-list-box">
        
                                <li draggable [dragEnabled]="!item.disabled" *ngFor="let item of options; let i = index;" [dragData]="item" [ngClass]="{'disableItem':item.disabled, 'selectedLeftSideItem': item.key === selectedValue.key }" (click)="selectedOptions(item)">
                                    {{item.value}}
                                </li>
            
                            </ul>
                        </div>
                        <!--Middle section-->
                        <div class="swap-arrow-box col-md-2">
                            <div class="sab-round-box" (click)="leftToRightMove()"><a class="icon-cursor"><i
                        class="ms-Icon ms-Icon--ChevronRight" title="ChevronRight" aria-hidden="true"></i></a></div>
                            <div class="sab-round-box" (click)="rightToLeftMove()"><a class="icon-cursor"><i
                        class="ms-Icon ms-Icon--ChevronLeft" title="ChevronLeft" aria-hidden="true"></i></a></div>
                        </div>
                    </div>
                </div>
                

                <!--Right Section-->
                <ng-container>
                    <div class="col-md-5 d-flex text-break pl-0">
                        <ul class="swap-left-list-box swap-middle-list-box w-100 ml-5 pl-5">
                            <ng-container *ngFor="let item of question.labels;let i=index;">

                                
                                        
                            <div class="row align-items-center mb-3">
                                <div class="col-md-8 col-12">
                                    <li *ngIf="item.option !== null" [dropEnabled]="item.option === null" droppable (onDrop)="onListValueDrop($event,item)" [ngClass]="{'selectedRightSideItem': item.key===selectedKey.key }" (click)="selectedOptionsByLabels(item)">
                                        {{item.option.value}}
                                        
                                    </li>
                                    <li *ngIf="item.option === null" droppable (onDrop)="onListValueDrop($event,item)"> {{ 'exam.questions.dropAnswer' | translate }}
                                    </li>
                                </div>
                                <div class="col-md-4 col-12">
                                    {{item.value}}
                                </div>
                            </div>
                            </ng-container>
                        </ul>
                    </div>
                </ng-container>
                <div class="swap-updown-arrow-box col-md-1">
                    <div class="swap-updown-arrow" (click)="upListValue()"><a class="icon-cursor"><i
                class="ms-Icon ms-Icon--ChevronUp" title="ChevronUp" aria-hidden="true"></i></a></div>
                    <div class="swap-updown-arrow" (click)="downListValue()"><a class="icon-cursor"><i
                class="ms-Icon ms-Icon--ChevronDown" title="ChevronDown" aria-hidden="true"></i></a></div>
                </div>

            </div>
        </li>
    </ul>
</div>