<!-- Loading -->
<div id="vex-splash-screen" *ngIf="onpageLoad && index !== 0">
  <div class="wrapper">
    <div class="ball-scale-multiple">
      <div></div>
      <div></div>
      <div></div>
    </div>
  </div>
</div>
<cloudlabs-skip-to-main-content *ngIf="!isMylearning" [mainDiv]="'main-landmark'"></cloudlabs-skip-to-main-content>

<cloudlabs-refined-header [customLogo]="lab.PartnerheaderLogo ? lab.PartnerheaderLogo : lab?.PartnerLogo" [lab]="lab"
  [menu]="false" [counter]="isSplitWindow ? 0 : counter" [actionList]="actionList" [showTitle]="!isSplitWindow" [labLaunchedState]="showFullScreen"
  (actionClickEvent)="actionClickEvent($event)" [modeOfOperation]="operationModes" [attendeeEndDate]="endDateData" [isSplitWindow]="isSplitWindow">
</cloudlabs-refined-header>

<div #docGuide *ngIf="index == 0 && !isComplete" [class.fullwidth]="!enableHeader || isSplitWindow"
  class="guideBlock guideBlock_2 fixed-top one" role="main">
  <div class="col-md-12 flex-col" [class.contentHeight]="index === 0 && enableHeader">
    <div *ngIf="!isSplitWindow" #sidebarHeader class="sidebar-Header pt-0 pb-1">
    </div>
    <cloudlabs-notes [ngClass]="isMylearning ? 'note-section' : 'mt-4'" *ngIf="guideData.CustomInstruction || workspaceURL" [customInstruction]="guideData.CustomInstruction"></cloudlabs-notes>
    <cloudlabs-doc-guide [labDetail]="lab" [voucherCode]="vCode" [guideData]="guideData" [lang]="lang"
      [mode]="operationModes" [splitKey]="splitKey" (onLoadingComplete)="calculateHeight()" [initialDeployment]="initialDeployment"
      (spliLabIsCompleted)="deleteLab()" (joinScreen)="joinWIndow()" [showResourcesTab]="showResourcesTab" [isSplitScreen]="true" [isMobile]="isMobile"></cloudlabs-doc-guide>
  </div>
</div>
<div #docGuide class="container-fluid position-fixed"
  [ngClass]="{ 'bottom-space-off': index !== 0, 'lab-guide-container': index !== 0, 'doc-content': !toggleFullScreenforCLPortal, 'readme-container':!toggleFullScreenforCLPortal, 'content-fullscreen':toggleFullScreenforCLPortal }"
  *ngIf="(index == 1 && !isComplete) || (index == 2 && !isComplete)">
  <div class="row">
    <div id="lab-guide-content" class="col-md-12 toggle-view mt-2">
      <div #sidebarHeader class="sidebar-Header">
      </div>
      <cloudlabs-notes *ngIf="!isSplitWindow && (guideData.CustomInstruction || workspaceURL)" [htmlContent]="workspaceURL" [customInstruction]="guideData.CustomInstruction"></cloudlabs-notes>
      <cloudlabs-lab-guide [labDetail]="lab" [voucherCode]="vCode" [guideData]="guideData" [type]="'full'" [lang]="lang"
        [isComplete]="isComplete" [operationMode]="operationModes" [mode]="operationModes" [initialDeployment]="initialDeployment"
        (labIsCompleted)="completeTheLab($event)" [showResourcesTab]="showResourcesTab" [isMobile]="isMobile"></cloudlabs-lab-guide>
    </div>
  </div>
</div>

<!-- App footer -->
<cloudlabs-footer *ngIf="enableFooter && !isMylearning && !isSplitWindow" class="appfooter" [labData]="lab"
  [class.lab-guide-page]="index !== 0" [class.guidefooter]="index === 0"></cloudlabs-footer>

<ng-container>
  <div *ngIf="operationModes === 'Deallocating'" class="row flex-center w-100">
    <div class="col-md-8 deallocating-lab">
      <div class="alert alert-info">
        <h4 role="alert" role="alert" [attr.aria-label]="'MESSAGE_DEALLOCATING' | translate">
          <i role="img" alt="" aria-label="img" aria-hidden="true"
            class="font-green-jungle font-lg fa fa-spinner fa-2x fa-spin"></i>
          <span aria-hidden="true">
            &nbsp; {{ "MESSAGE_DEALLOCATING" | translate }}
          </span>
        </h4>
      </div>
    </div>
  </div>
  <div class="doc-content" [ngClass]="{'card-min-height': ['Deallocated','Deployed'].includes(operationModes)}">
    <!-- <div class="course-header-title" *ngIf="isMylearning && ([
      'Deallocated',
      'Deployed'].includes(operationModes) && isComplete)">

      <span role="button" aria-label="go to back" tabindex="0" class="back-arrow cursor-pointer ml-3"
        (click)="goBack()"><i class="fas fa-arrow-left"></i></span>
      <h3>{{lab.Title}}</h3>

    </div> -->
    <div class="container-fluid" role="alert" *ngIf="isComplete && operationModes === 'Deallocated' && index !== 2">
      <div class="row h-100 " [ngClass]="{'mt-4': !isMylearning}">
        <div class="col-md-6 mx-auto mt-5" [ngClass]="{'pt-4': isMylearning}">
          <div class="card">
            <div class="alert mb-0" [ngClass]="{
              'alert-custom alert-light-warning' : isMylearning,
              'alert-warning' : !isMylearning
            }">
              <h4 class="block alert-text">
                <i aria-hidden="true" aria-label="img" class="fa fa-exclamation-triangle" role="img"></i>
                &nbsp; {{ "ALERT_ODL_END" | translate }}
              </h4>
              <p class="alert-text" style="margin-left: 30px">
                {{ "ALERT_ODL_END_THANKS1" | translate }}
              </p>
              <p class="alert-text" style="margin-left: 30px">
                {{ "ALERT_THANKS" | translate }}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="container-fluid" role="alert" *ngIf="isComplete && operationModes === 'Deployed'">
      <div class="row" [ngClass]="{'mt-4': !isMylearning}">
        <div class="col-md-6 mx-auto mt-5" [ngClass]="{'pt-4': isMylearning}">
          <div class="card">
            <div class="alert mb-0" [ngClass]="{
            'alert-custom alert-light-warning' : isMylearning,
            'alert-warning' : !isMylearning
          }">
              <h4 class="block alert-text">
                <i aria-hidden="true" aria-label="img" class="fa fa-exclamation-triangle" role="img"></i>
                &nbsp; {{ "ALERT_ALREADY_COMPLETED" | translate }}
              </h4>
              <p class="alert-text" style="margin-left: 30px">
                {{ "ALERT_THANKS" | translate }}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</ng-container>

<ng-container *ngIf="showImagePopup">
  <cloudlabs-dialoge-prompt appTrapFocus appAutoFocusModal appRefocusPreviousEl *ngIf="showImagePopup"
    [popupWidth]="imagePopupStyle.width" [popupHeight]="imagePopupStyle.height" [baseZIndex]="9999902"
    [isDialog]="showImagePopup" (popupResponse)="closeImagePopup($event)">
    <div header class="subheading">
      {{ 'PREVIEW_BUTTON'| translate }}
    </div>
    <div content class="">
      <div class="text-center" [innerHTML]="imagePopupElement">
      </div>
    </div>
    <div footer class="col-md-12">
      <teams-button [ngClasses]="environment?.appType === 'lab'? 'ts-btn-fluent-primary': 'ts-btn-fluent-white pt-2 pb-2'" class="" [classList]="' mt-2 btn ts-btn-fluent ts-btn-grey text-nowrap'"
        [title]="'CLOSE' | translate" (click)="showImagePopup = false">
      </teams-button>
    </div>
  </cloudlabs-dialoge-prompt>
</ng-container>

<div class="modal" role="dialog" appTrapFocus appAutoFocusModal aria-labelledby="modal-title"
  aria-describedby="modal-desc" *ngIf="modelShow" [class.validation]="modelShow" id="validation">
  <div class="modal-dialog" [class.validation-model]="isLabValidation && modalData.type === 'DEALLOCATE_LAB'">
    <div class="modal-content">
      <div class="modal-header">
        <h4 id="modal-title" class="modal-title">{{modalData.title | translate}}</h4>
      </div>
      <div class="modal-body delete-body" aria-label="modal description">
        <p *ngIf="modalData.type === 'EXTEND_LAB_ERROR'"
          [innerHTML]="'MAXIMUM_TIME_REACHED' | translate:{allowedMin: lab.MaxUserDurationExtension}"></p>
        <p id="modal-desc" [innerHTML]="modalData.description | translate">
        </p>
        <cloudlabs-lab-validation *ngIf="isLabValidation && modalData.type === 'DEALLOCATE_LAB'" [voucherCode]="vCode" [labDetail]="lab" [templateValidation]="templateValidation" [isDialog]="true"></cloudlabs-lab-validation>
      </div>
      <div class="modal-footer">
        <teams-button *ngIf="modalData.showCancel === true"
          [classList]="'btn ts-btn-fluent-red modal-close-button btn-danger'" (buttonClick)="modelShow=false; loadValidations()">{{'CANCEL'
          | translate}}
        </teams-button>
        <teams-button *ngIf="modalData.type === 'DEALLOCATE_LAB'"
          [classList]="'btn ts-btn-fluent-primary modal-ok-button'" (buttonClick)="deallocateLab()">
          {{'PROCEED_TO_DELETE' |
          translate}}</teams-button>
        <teams-button *ngIf="modalData.type === 'EXTEND_LAB_ERROR'"
          [classList]="'btn ts-btn-fluent-primary modal-ok-button'" (buttonClick)="modelShow=false">
          {{'OK' |
          translate}}</teams-button>
      </div>
    </div>
  </div>
</div>