export interface Country {
  name: string;
  abbreviation: string;
}

export interface CountryList {
  CountryName: string;
  Id: string;
  PrivacyPolicy: string;
}

export enum VMStatus {
  'VM running' = 'Running',
  'running' = 'Running',
  'VM starting' = 'Starting',
  'Starting' = 'Starting',
  'VM deallocating' = 'Deallocating',
  'deallocating' = 'Deallocating',
  'Deallocating' = 'Deallocating',
  'VM deallocated' = 'Deallocated',
  'deallocated' = 'Deallocated',
  'deallocated- Runtime Limit Exhausted' = 'Deallocated- Runtime Limit Exhausted',
  'VM deallocated- Runtime Limit Exhausted' = 'Deallocated- Runtime Limit Exhausted',
  'VM deallocating- Runtime Limit Exhausted' = 'Deallocating- Runtime Limit Exhausted',
  'deallocating- Runtime Limit Exhausted' = 'Deallocating- Runtime Limit Exhausted',
  'stopped' = "Stopped",
  'Stopped' = "Stopped",
  'VM stopped' = "Stopped",
  'Stopping' = "Stopping",
  'stopping' = "Stopping",
  'pending' = "Pending",
  'terminated' = "Terminated",
  'Terminated' = "Terminated",
  'VMDelete' = "Delete",
  'Delete Virtual Machine' ='Delete',
  'Deleted' = "Deleted",
  'deleted' = "Deleted",
}
