<!--For Practice test only-->

<ng-container *ngIf="isPracticeTest">
    <label [for]="randomValue" class=" pt">
        <input type="radio" [id]="randomValue" [class]="classlist" [value]="value" [name]="name" [checked]="checked"
            (change)="updateChanges()" [disabled]="disabled" />
        <span [class]="labelClass">{{ label }}</span>
    </label>
</ng-container>

<!--For other place-->

<ng-container *ngIf="!isPracticeTest">
    <!-- Changed name value to randomValue as radio button needs unique name to differentiate | Sheikh | Inline Question | Bug-3213-->
    <input type="radio" class=" no-pt" [id]="randomValue" [class]="classlist" [value]="value" [name]="randomValue" [checked]="checked"
        (change)="updateChanges()" [disabled]="disabled" />
    <label [for]="randomValue" [class]="labelClass">{{ label }}
        <ng-content></ng-content>
    </label>
</ng-container>