import { Component, ElementRef, HostListener, Inject, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { EventService } from '../../../services/event.services';
import { TranslateService } from '@ngx-translate/core';
import { NotificationService } from '../../../services/notification.service';
import { miscellaneousConst, toggleScreen } from '@utility';
import { AppType } from '../../../modals/lab.model';

@Component({
  selector: 'cloudlabs-toggle-header',
  templateUrl: './toggle-header.component.html',
  styleUrls: ['./toggle-header.component.scss'],
})
export class ToggleHeaderComponent implements OnInit, OnDestroy{
  toggle = true;
  appType;
  showExpand = true;
  @ViewChild('toggle') toggleContent:ElementRef
  isFullScreen = false;
  hidingAlertMessages = false;

  constructor(
    private eventService: EventService,
    private notificationService: NotificationService,
    private translateSrv: TranslateService,
    @Inject('environment') environment
  ) {
    this.appType = environment.appType;
    const isTeamsApp = JSON.parse(localStorage.getItem('isTeamapp'));
    if (
      isTeamsApp &&
      isTeamsApp !== undefined &&
      isTeamsApp !== null &&
      isTeamsApp !== false
    ) {
      this.showExpand = false;
    }
  }

  ngOnInit(): void {
    if(this.appType ===  AppType.ATTENDEE){
      this.hideShow();
    }
  }

  hideShow() {
    this.hidingAlertMessages = true;
    this.isFullScreen = !this.isFullScreen;
    setTimeout(()=>{
      this.hidingAlertMessages = false;
      //this.toggleContent.nativeElement.focus();
    },500)
    this.eventService.emmitToggleHeader();
    if (localStorage.getItem('context') === miscellaneousConst.cloudlab) {
      if (this.toggle) {
        toggleScreen(this.toggle);
        this.toggle = !this.toggle;
      } else {
        toggleScreen(this.toggle);
        this.toggle = !this.toggle;
      }
    }
  }

  copyToClipBoard(text: string) {
    const selBox = document.createElement('textarea');
    selBox.style.position = 'fixed';
    selBox.style.left = '0';
    selBox.style.top = '0';
    selBox.style.opacity = '0';
    selBox.value = text;
    document.body.appendChild(selBox);
    selBox.focus();
    selBox.select();
    selBox.setSelectionRange(0, 99999);
    document.execCommand('copy');
    document.body.removeChild(selBox);
    this.notificationService.success(
      this.translateSrv.instant('MESSAGE_COPIED'),
      null
    );
  }

  ngOnDestroy(): void {
    if (!this.toggle) {
      this.hideShow();
    }
  }
}
