import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { BehaviorSubject } from 'rxjs';

import { languageConst } from '../_consts/language.const';
import { miscellaneousConst } from '@utility';

import { KeyPairModel } from '../_modals/keypair.model';
import { LanguageModel } from '../_modals/language.model';
import { VMModel } from '../_modals/vm.model';

@Injectable({
  providedIn: 'root',
})
export class UserSessionService {
  private static instance: UserSessionService;

  private isTeamUser = false;
  private theme = 'blue';
  private isCloudUser = false;
  private isDevUser = false;
  public languageConst = languageConst.lang;

  private isProd = false;

  vmDetails$: BehaviorSubject<VMModel> = new BehaviorSubject<VMModel>(null);
  vmRenderOnLeftPanelChange$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(null);
  coursePt$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  practiceTest$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(null);
  sideNavigation$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);
  isReset$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  isDetailTable$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  curruntSection$: BehaviorSubject<number> = new BehaviorSubject<number>(0);
  questionApi$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  sideNavOpen$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);
  isQuestionLoaded$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  isLangClicked: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  private originalKeyPair: KeyPairModel;
  private keyPair: KeyPairModel;

  private modifiedKey = '';

  public defaultLanguage: LanguageModel;

  public languageOptions: LanguageModel[] = [];

  public sectionLength: number;

  public questionList = [];

  public timer;

  public stopWatch;

  public showLearningEnitity;
  public coursesNGBDropdownFilter = undefined;

  constructor(private readonly translateService: TranslateService) {
    // Singleton class logic
    if (UserSessionService.instance) {
      return UserSessionService.instance;
    }

    UserSessionService.instance = this;

    this.defaultLanguage = {
      value: miscellaneousConst.language.defaulLang.value,
      label: miscellaneousConst.language.defaulLang.label,
    };
  }

  public setDefaultLanguage(lang: LanguageModel): void {
    this.defaultLanguage = lang;
  }

  public setLearningEnitity(entity): void {
    this.showLearningEnitity = entity;
  }

  public getLearningEnitity(): string {
    return this.showLearningEnitity;
  }

  public getSectionLength(): number {
    return this.sectionLength;
  }

  public getDefaultLanguage(): LanguageModel {
    return this.defaultLanguage;
  }

  public setLanguageOptions(): void {
    this.languageConst.forEach((element) => {
      const el = element.split('-');
      this.languageOptions.push({
        value: el[0],
        label: el[1],
      });
    });
  }

  public translateLanguage(langSelection: any): void {
    if (localStorage.getItem('context') === 'CloudLabs') {
      this.translateService.use(langSelection);
      this.defaultLanguage.label = langSelection;
    } else if (localStorage.getItem('context') === 'PracticeTest') {
      this.translateService.use(langSelection.val);
      this.defaultLanguage.label = langSelection.label;
    }
  }

  /**
   * @description To set original key pair
   */
  public setOriginalKeyPair(publicKey: string, privateKey: string): void {
    this.originalKeyPair = new KeyPairModel();
    this.originalKeyPair.public = publicKey;
    this.originalKeyPair.private = privateKey;
  }

  /**
   * @description To get original key pair
   */
  public getOriginalKeyPair(): KeyPairModel {
    return this.originalKeyPair;
  }

  /**
   * @description To set modified key pair
   */
  public setKeyPair(publicKey: string, privateKey: string): void {
    this.keyPair = new KeyPairModel();
    this.keyPair.public = publicKey;
    this.keyPair.private = privateKey;
  }

  /**
   * @description To get modified key pair
   */
  public getKeyPair(): KeyPairModel {
    return this.keyPair;
  }

  public setModifiedKey(key: string): void {
    this.modifiedKey = key;
  }

  public getModifiedKey(): string {
    return this.modifiedKey;
  }

  get isProdUser(): boolean {
    return this.isProd;
  }
  set isProdUser(val) {
    this.isProd = val;
  }

  get currentTheme(): string {
    return this.theme;
  }

  set currentTheme(val) {
    this.theme = val;
  }

  get isTeamsAppUser(): boolean {
    return this.isTeamUser;
  }
  set isTeamsAppUser(val) {
    this.isTeamUser = val;
  }
  get isClouldLabsUser(): boolean {
    return this.isCloudUser;
  }
  set isClouldLabsUser(val) {
    this.isCloudUser = val;
  }
  get isLocalDevUser(): boolean {
    return this.isDevUser;
  }
  set isLocalDevUser(val) {
    this.isDevUser = val;
  }
}
