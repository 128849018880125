import { AfterViewInit, Component, Inject, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { CustomerType } from '@libs/common-lab/src/modals/lab.model';
import { setting } from '@libs/common-lab/src/modals/setting.model';
@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss'],
})
export class HomeComponent implements OnInit, AfterViewInit {
  public appSetting: setting;
  public year: string;
  copyRight: string;
  contactEmail: any;
  poweredByURLText: string;
  poweredByURL: string;
  supportEmail: string;
  policyLink: string;
  terms: string;
  public customerType = CustomerType;
  public logosrc: string = '';
  public contactus: string = '';
  constructor(
    public router: Router,
    @Inject('environment') public environment
  ) {
    if (this.environment.customer === CustomerType.DATABRICKS) {
      this.logosrc = 'databricks-logo_black.png';
    } else {
      this.logosrc = 'CloudLabs_Logo_Dark.png';
    }
  }

  ngOnInit(): void {
    this.getSetting();
  }
  ngAfterViewInit() {
    this.changeText();
  }
  getSetting() {
    let haveSetting = localStorage.getItem('setting');
    this.year = new Date().getFullYear().toString();
    if (haveSetting) {
      this.appSetting = JSON.parse(haveSetting);
      this.getValues();
    }
    this.setHeaderforDatabricks();
  }

  private setHeaderforDatabricks(): void {
    if (this.environment.customer === CustomerType.DATABRICKS) {
      this.supportEmail = 'cloudlabs@databricks.com';
      this.contactus = 'https://databricks.com/company/contact';
    } else {
      this.contactus = `mailto:${this.contactEmail}`;
    }
  }
  /**
   * Change the text of hero section
   */
  changeText() {
    let text = document.getElementById('changing-text');
    if (text) {
      const text1 = `Welcome To ${
        this.environment.customer === CustomerType.DATABRICKS
          ? 'Databricks Labs'
          : 'CloudLabs'
      }`;
      const text2 = 'Impactful learning experiences with Hands-on Labs';
      const text3 = 'Built for Scale';
      text.style.opacity = '0';
      if (text.innerHTML == text1) text.innerHTML = text2;
      else if (text.innerHTML == text2) text.innerHTML = text3;
      else if (text.innerHTML == text3 || text.innerHTML == '')
        text.innerHTML = text1;
      text.style.opacity = '1';
      setTimeout(() => {
        this.changeText();
      }, 5000);
    }
  }
  getValues() {
    if (this.appSetting) {
      this.copyRight = this.appSetting.Data.Copyright;
      this.contactEmail = this.appSetting.Data.ContactEmail;
      this.poweredByURLText = this.appSetting.Data.PoweredByURLText;
      this.poweredByURL = this.appSetting.Data.PoweredByURL;
      this.supportEmail = this.appSetting.Data.SupportEmail;
      this.policyLink = this.appSetting.Data.PrivacyPolicyLink;
      this.terms = this.appSetting.Data.TermsOfServiceLink;
    } else return '';
  }
}
