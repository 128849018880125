import { Component, EventEmitter, Input, OnChanges, OnInit, Output } from '@angular/core';

@Component({
  selector: 'text-input-question',
  templateUrl: './text-input-question.component.html',
  styleUrls: ['./text-input-question.component.scss']
})
export class TextInputComponent implements OnInit, OnChanges {
  public textVal: string;
  @Input() public question;
  @Input() sectionType = '';
  @Output() UserSelectAnswerOption = new EventEmitter();
  @Input() userAnswer = [];
  @Input() versionKey: string;
  @Input() groupQuestRemainingRetries
  @Input() isRetryClicked;
  @Input() isSubmitClicked;
  public isCorrect;
  @Input() set isCorrectAnswerTextInput (value){
    if (value?.type === 'ungrouped') {
          if (value?.remainingRetries === 0 && value?.questionUniqueName === this.question?.questionUniqueName) {
            this.textVal = value.correctAnswers[0].key
            this.isCorrect = true
          } else if(this.question !== undefined) {
            this.isCorrect = value?.isCorrect
            this.question.isCorrect = value?.isCorrect
          }
    } else if (value?.type === 'grouped') {
        value?.mergedArrayGroupe.forEach(element => {
          if (value?.remainingRetries === 0 && element?.questionUniqueName === this.question?.questionUniqueName) {
            this.textVal = element.correctAnswers[0].key
            this.isCorrect = true
          } else if(this.question !== undefined && element?.questionUniqueName === this.question?.questionUniqueName) {
            this.isCorrect = element?.isCorrect
            this.question.isCorrect = element?.isCorrect
          }
        });
    }
  };

  constructor() { }

  ngOnInit(): void {
    if (this.question.userAnswers.length > 0) {
      this.textVal =  this.question?.userAnswers[0].key
      this.isCorrect = this.question?.isCorrect
    }
  }

  ngOnChanges(): void {
    if (this.question.userAnswers.length > 0) {
      this.question?.userAnswers.forEach(userAns => {
        this.textVal = userAns.key
      });
    } else {
      this.textVal = ''
    }
    if (this.isRetryClicked) {
      this.isCorrect = ''
    }
    
  }
  getValue(val): void {
    if (val === '') {
      this.question.userAnswers = []
    } else {
      this.question.userAnswers = [{
        'key': val,
        'value': ''
      }]
      this.UserSelectAnswerOption.emit(val)
    }
  }

}
