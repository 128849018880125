import { CommonModule, TitleCasePipe } from '@angular/common';
import { NgModule } from '@angular/core';
import { ButtonComponent } from './button/button.component';
import { SingleSelectComponent } from './single-select/single-select.component';
import { CheckboxComponent } from './checkbox/checkbox.component';
import { RadioComponent } from './radio/radio.component';
import { ToggleComponent } from './toggle/toggle.component';
import { AlertComponent } from './alert/alert.component';
import { LoaderComponent } from './loader/loader.component';
import { ConfirmDialogComponent } from './confirm-dialog/confirm-dialog.component';
import { ProgressBarComponent } from './progress-bar/progress-bar.component';
import { TabsPanelComponent } from './tabs-panel/tabs-panel.component';
import { RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { StarRatingComponent } from './star-rating/star-rating.component';
import { InputComponent } from './input/input.component';
import { ToggleDropdownComponent } from './toggle-dropdown/toggle-dropdown.component';
import { SingleSelectOptionComponent } from './single-select-option/single-select-option.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

/* prime elements */
import { DropdownModule } from 'primeng/dropdown';
import { CaptchaComponent } from './captcha/captcha.component';
import { CaptchaService } from './captcha.service';
import { NumberOnlyDirective } from './directives/number-only.directive';
import { ButtonLoadingService } from './button-loading.service';
import { CounterComponent } from './counter/counter.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { TextAreaComponent } from './text-area/text-area.component';
@NgModule({
  declarations: [
    ButtonComponent,
    SingleSelectComponent,
    CheckboxComponent,
    RadioComponent,
    ToggleComponent,
    AlertComponent,
    LoaderComponent,
    ConfirmDialogComponent,
    ProgressBarComponent,
    TabsPanelComponent,
    StarRatingComponent,
    InputComponent,
    ToggleDropdownComponent,
    SingleSelectOptionComponent,
    CaptchaComponent,
    NumberOnlyDirective,
    CounterComponent,
    TextAreaComponent ,
  ],
  imports: [
    CommonModule,
    RouterModule,
    TranslateModule,
    FormsModule,
    DropdownModule,
    ReactiveFormsModule,
    NgbModule
  ],
  exports: [
    ButtonComponent,
    SingleSelectComponent,
    CheckboxComponent,
    RadioComponent,
    ToggleComponent,
    AlertComponent,
    LoaderComponent,
    ConfirmDialogComponent,
    ProgressBarComponent,
    TabsPanelComponent,
    StarRatingComponent,
    InputComponent,
    ToggleDropdownComponent,
    SingleSelectOptionComponent,
    DropdownModule,
    CaptchaComponent,
    CounterComponent,
    TextAreaComponent
  ],
  providers: [TitleCasePipe, CaptchaService, ButtonLoadingService],
})
export class TeamsUiModule {}
