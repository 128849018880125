import { Component, EventEmitter, Input, OnChanges, Output } from '@angular/core';

import { miscellaneousConst } from '@utility';

import { QuestionHelperService } from '../../../services/question/question-helper.service';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'build-tree-question',
  templateUrl: './build-tree-question.component.html',
})
export class BuildTreeQuestionComponent implements OnChanges {
  @Input() public question;
  public options: Array<any> = [];
  @Output() UserSelectAnswerOption = new EventEmitter();
  @Input() public userAnswer = [];
  @Input() versionKey: string;
  public miscellaneousConst = miscellaneousConst;

  constructor(
    private questionHelper: QuestionHelperService
  ) { }

  ngOnChanges(): void {
    this.onLoadData();
  }
  /**
   * @description: Load Data First Time
   */
  private onLoadData(): void {
    if (this.question !== undefined) {
      this.question.labels.forEach((element) => {
        element.option = [];
      });
      this.options = this.question.options;
      this.question.options.forEach((element) => {
        element.disabled = false;
        element.keys = element.key;
      });
    }
    if (this.question.userAnswers.length > 0) {
      this.selectedUserAnswers();
    }
  }
  /**
   * @description: Load Selected User Answers
   */
  private selectedUserAnswers(): any {
    this.questionHelper.selectedUserAnswers(this.question);
  }
  /**
   * @description: Drop List Value
   */
  public onListValueDrop(e: any, key: any): void {
    this.questionHelper.onBuildTreeValueDrop(this.question, this.options, e, key);
    this.saveBuildTree();
  }
  /**
   * @description: Save Build Tree data
   */
  private saveBuildTree(): void {
    const userAnswer = this.question.labels.flatMap(({ key, option }) =>
      option.map(({ keys }) => ({ key, value: keys }))
    );
    if(this.question?.isVersioned) {
      this.question?.questionVersions.forEach((element) => {
        if(element.versionKey === this.versionKey) {
          element.userAnswers = userAnswer
          this.question.userAnswers = userAnswer;
        }
      });
    } else {
      this.question.userAnswers = userAnswer;
    }
    this.UserSelectAnswerOption.emit(this.question);
  }
}
