<div appTrapFocus appAutoFocusModal appRefocusPreviousEl role="dialog"
     aria-labelledby="activity-tracking-head" aria-describedby="activity-tracking-desc">
  <div class="modal-header" id="activity-tracking-head">
    <h4 class="modal-title pull-left"> {{ title }} </h4>
<!--    <button type="button" class="close pull-right" aria-label="Close" (click)="onClose()">-->
<!--      <span aria-hidden="true">&times;</span>-->
<!--    </button>-->
  </div>
  <div id="activity-tracking-desc" class="modal-body">
    <p>{{data.description}}</p>
  </div>
  <div class="modal-footer">
    <teams-button [classList]="'btn btn-secondary modal-close-button'" (buttonClick)="onClose()">{{data.failureButtonText}}</teams-button>
    <teams-button [classList]="'btn ts-btn-fluent-primary modal-ok-button'" (buttonClick)="onConfirm()">{{data.successButtonText}}</teams-button>
  </div>
</div>
