<div class="single-question-page">
    <div class="sqp-title-time-lang-but-row" *ngIf="noPdf">
        <div class="d-flex align-items-center" *ngIf="!noPdfTab || detailReport === 'true'">
            <!-- <span class="back-arrow cursor-pointer" (click)="goToPage()"><i class="fas fa-arrow-left"></i></span> -->
            <div class="back-arrow cursor-pointer">
                <span role="button" aria-label="go to back" tabindex="0" (click)="goToPage()">
                  <i class="fas fa-arrow-left"></i>
                </span>
            </div>
            <span class="subheading">{{ 'exam.result.title' | translate }}</span>
        </div>
        <div class="sqptt-title" *ngIf="!userSessionService.isTeamsAppUser && context === 'PracticeTest' && (loadingService.loading$ | async) === false">
            <img class="team-icon" src="../../../../assets/images/cloudlogo.png" alt="logo">
            <h2 class="ts-title-bar position-relative">{{ 'branding.cloudlabs' | translate }}</h2>
        </div>
        <div class="sqptt-right-box">
            <div class="sqpttr-language" *ngIf="!deviceDetectorService.isMobile()">
                <strong>{{ 'common.language' | translate }}: </strong>
                <span>{{resultDetails?.language}}</span>
            </div>
            <ng-container *ngIf="noPdfTab && detailReport !== 'true'">
                <teams-button *ngIf="!userSessionService.isClouldLabsUser" [title]="'actions.cancelTest' | translate" [classList]="'btn ts-btn-fluent-red ts-btn-fluent button-height-1 ts-btn-width-default ml-4'" (buttonClick)="completeExam()">
                </teams-button>
            </ng-container>
        </div>
    </div>
</div>
<div class="certificate-exam-result col-md-12" *ngIf="productType === miscellaneousConst.productType.certificationExam">
    <div class="mt-5 pt-5 text-center" >
        <span class="result-icon"><i class="ms-Icon ms-Icon--CheckMark check-icon" title="CheckMark" aria-hidden="true"></i></span>
        <span class="ml-2 big-heading">{{ 'message.certificateExamMessage' | translate}}</span>
    </div>
</div>
<div class="test-description-box" id="test-description-box" *ngIf="productType !== miscellaneousConst.productType.certificationExam">
    <div class="sqp-title-time-lang-but-row pl-0 mb-2" *ngIf="!noPdf">
        <div class="sqptt-title">
            <img class="team-icon" src="../../../../assets/images/cloudlogo.png" alt="logo">
            <h2 class="ts-title-bar position-relative">{{ 'branding.cloudlabs' | translate }}</h2>
        </div>
    </div>
    <div class="title-time-row isCol mt-5" *ngIf="productType !== miscellaneousConst.productType.certificationExam">
        <div class="ttr-title-tag-butn-box isInnerTab">
            <h3 role="heading" aria-level="1">{{resultDetails?.title}} - {{ 'exam.result.title' | translate }}</h3>
            <ul class="tag-list">
                <li class="caption" *ngFor="let t of testDetails?.tags">{{t}}</li>
            </ul>
        </div>
        <ul class="ttr-time-list isInnerTab isFlexiable">
            <li class="failure isTab" *ngIf="resultDetails?.isPassed===false">
                <strong class="big-heading">{{ 'exam.result.failed' | translate }}</strong>
                <span>{{ 'exam.result.title' | translate }}</span>
            </li>
            <li class="success isTab" *ngIf="resultDetails?.isPassed===true">
                <strong class="big-heading">{{ 'exam.result.passed' | translate }}</strong>
                <span>{{ 'exam.result.title' | translate }}</span>
            </li>
            <li class="isTab" [ngClass]="{'success': resultDetails?.isPassed===true, 'failure': resultDetails?.isPassed===false}">
                <strong class="big-heading">{{resultDetails?.userScore}}/{{resultDetails?.totalScore}}</strong>
                <span>{{ 'exam.result.marks' | translate }}</span>
            </li>
            <li class="primary isTab">
                <strong class="big-heading">{{changeMinute(resultDetails?.elapsedTime)}} {{ 'exam.result.minutes' | translate:{singularPlural: changeMinute(resultDetails?.elapsedTime) | pluralize} }} </strong>
                <span>{{ 'exam.result.timeSpent' | translate }}</span>
            </li>
        </ul>
    </div>
    <ng-container *ngIf="productType !== miscellaneousConst.productType.certificationExam">

    <!-- Single Section Result-->

    <div class="primengTab" tabindex="-1" id="main-landmark" *ngIf="resultDetails?.sections !== undefined && resultDetails?.sections.length === miscellaneousConst?.arrayOneValue">
        <p-tabView>
            <ng-container>
                <div *ngIf="!noPdf">
                    <cloudlabs-comprehensive-report *ngIf="examInstanceId" [examInstanceId]='examInstanceId' [isPdf]='noPdf'></cloudlabs-comprehensive-report>
                </div>
            </ng-container>
            <ng-container *ngIf="productType === miscellaneousConst.productType.actualTest || productType === miscellaneousConst.productType.practiceTest">
                <p-tabPanel *ngIf="noPdf">
                    <ng-template pTemplate="header">
                        {{ 'exam.result.report.tabName' | translate }}
                    </ng-template>
                    <cloudlabs-comprehensive-report *ngIf="examInstanceId" [examInstanceId]='examInstanceId' [isPdf]='noPdf'></cloudlabs-comprehensive-report>
                </p-tabPanel>
            </ng-container>
        <ng-container *ngIf="productType !== miscellaneousConst.productType.actualTest">
            <p-tabPanel *ngIf="noPdf && (noPdfTab && detailReport !== 'true')" data-html2canvas-ignore header="{{ 'exam.result.tabNameAnswer' | translate }}">
                <div class="singleresult-list">
                    <div *ngFor="let question of resultDetails?.sections[0].questions|slice :pageStartingIndex:pageEndingIndex; let i = index;">
                        <app-result [questions]="question" [index]="(i + (activePage * miscellaneousConst?.arrayGreaterValue))">
                        </app-result>
                        <br>
                        <div class="explanation_bottom_border_referenceLinks" *ngIf="question.explanation!== null || question?.referenceLinks!== null && question?.referenceLinks.length > miscellaneousConst?.answeredArrayMinLength ">
                            <span *ngIf="question.explanation!== null" class="explanation_bottom_border_referenceLinks_title">{{
                      'exam.result.explanation' | translate}}</span>
                            <div [innerHTML]="question?.explanation" *ngIf="question.explanation !== null">
                            </div>
                            <br><span *ngIf="question?.referenceLinks!== null && question?.referenceLinks.length > miscellaneousConst?.answeredArrayMinLength" class="explanation_bottom_border_referenceLinks_title">{{ 'exam.result.refLink' | translate }}</span>
                            <div *ngFor="let ref of question.referenceLinks"> <a href="{{ref}}" target="_blank">{{ref}}</a>
                            </div>
                        </div>
                        <hr>
                    </div>
                    <div class="row" *ngIf="resultDetails?.sections[0].questions.length > miscellaneousConst?.arrayGreaterValue">
                        <div class="ts-pagination-fluent">
                            <div class="float-right">
                                <p-paginator [rows]="10" [totalRecords]="resultDetails?.sections[0].questions.length" (onPageChange)="paginate($event)">
                                </p-paginator>
                            </div>
                        </div>
                    </div>
                </div>
            </p-tabPanel>
        </ng-container>
        </p-tabView>
    </div>

    <!-- Multi Section Result-->
    <div class="primengTab" tabindex="-1" id="main-landmark" *ngIf="resultDetails?.sections !== undefined && resultDetails?.sections.length > miscellaneousConst?.arrayOneValue">
        <div *ngIf="!noPdf">
            <cloudlabs-comprehensive-report *ngIf="examInstanceId" [examInstanceId]='examInstanceId' [isPdf]='noPdf'></cloudlabs-comprehensive-report>
        </div>
        <p-tabView *ngIf="noPdf">
            <ng-container *ngIf="productType === miscellaneousConst.productType.actualTest || productType === miscellaneousConst.productType.practiceTest">
                <p-tabPanel *ngIf="noPdf">
                    <ng-template pTemplate="header">
                        {{ 'exam.result.report.tabName' | translate }}
                    </ng-template>
                    <cloudlabs-comprehensive-report *ngIf="examInstanceId" [examInstanceId]='examInstanceId' [isPdf]='noPdf'></cloudlabs-comprehensive-report>
                </p-tabPanel>
            </ng-container>
            <ng-container *ngIf="productType !== miscellaneousConst.productType.actualTest || productType === miscellaneousConst.productType.practiceTest">
            <ng-container *ngIf="noPdf && (noPdfTab && detailReport !== 'true')">
                <p-tabPanel header="{{ 'common.section' | translate }} {{tabIndex + miscellaneousConst?.arrayOneValue}} {{ 'common.answers' | translate }}" *ngFor="let section of resultDetails?.sections; let tabIndex = index">
                    <div class="singleresult-list">
                        <div *ngFor="let question of section.questions; let i = index;">
                            <app-result [questions]="question" [index]="i"></app-result>
                            <br>
                            <div class="explanation_bottom_border_referenceLinks" *ngIf="question.explanation!== null || question?.referenceLinks!== null && question?.referenceLinks.length > miscellaneousConst?.answeredArrayMinLength">
                                <span *ngIf="question.explanation!== null" class="explanation_bottom_border_referenceLinks_title">{{
                    'exam.result.explanation' | translate}}</span>
                                <div [innerHTML]="question?.explanation" *ngIf="question.explanation!== null">
                                </div>
                                <br><span *ngIf="question?.referenceLinks!== null && question?.referenceLinks.length > miscellaneousConst?.answeredArrayMinLength" class="explanation_bottom_border_referenceLinks_title">{{ 'exam.result.refLink' | translate }}</span>
                                <div *ngFor="let ref of question.referenceLinks"> <a href="{{ref}}" target="_blank">{{ref}}</a>
                                </div>
                            </div>
                        </div>
                        <div class="row" *ngIf="resultDetails?.sections[tabIndex].questions.length > miscellaneousConst?.arrayGreaterValue">
                            <div class="col-lg-8 col-md-10 ml-auto mr-auto">
                                <div class="float-right">
                                    <p-paginator [rows]="10" [totalRecords]="resultDetails?.sections[tabIndex].questions.length" (onPageChange)="paginate($event)">
                                    </p-paginator>
                                </div>
                            </div>
                        </div>
                    </div>
                </p-tabPanel>
            </ng-container>
        </ng-container>
        </p-tabView>
    </div>
</ng-container>
</div>

<app-feedback [isExamFeedback]="isExamFeedback" [examId]="examId" [examInstanceId]="examInstanceId" (feedbackResponse)="getFeedback($event)"></app-feedback>